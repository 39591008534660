export const MODAL_TYPES = {
    CHECK_IN: 'checkin',
    CHECK_OUT: 'checkout',
    STATUS: 'status',
    SYSTEM_STATUS: 'systemStatus',
    UNPAID_SALE: 'unpaidSale',
    PENDING: 'pending',
    ERROR: 'error',
};

export const COUPON_STATUSES = {
    WON: 'won',
    onHOLD: 'onHold',
    LOST: 'lost',
    UNKNOWN: 'unknown',
    ROLLBACKED: 'rollbacked',
    PENDING: 'pending',
    UNCONFIRMED: 'unconfirmed',
    CANCELLED: 'canceled',
};

export const SYSTEM_NAMES = [
    'null',
    'singler',
    'doubler',
    'tripler',
    'firlinger',
    'femlinger',
    '6-ling',
    '7-ling',
    '8-ling',
    '9-ling',
    '10-ling',
    '11-ling',
    '12-ling',
    '13-ling',
    '14-ling',
    '15-ling',
];
