import RequestHandler from './RequestHandler';
import { PRODUCTS_BET_NAMES, PRODUCTS_SYS_NAMES } from 'configs/products';
import qs from 'qs';

const { get, post } = RequestHandler();

const allProductsEndpoints = Object.keys(PRODUCTS_SYS_NAMES).map(
    (productId) => `fetch${PRODUCTS_SYS_NAMES[productId]}RacingCard`
);

const AISREST = () => ({
    fetchRaceDayCalendar: (options) =>
        post('fetchRaceDayCalendar', {}, options),
    fetchRacingCard: (productId, date, trackId, options) => {
        if (!PRODUCTS_SYS_NAMES[productId]) {
            console.error(
                `[AISREST.fetchRacingCard] Wrong product id: ${productId}.`
            );
        }
        return post(
            `fetch${PRODUCTS_SYS_NAMES[productId]}RacingCard`,
            {
                date,
                trackId,
            },
            options
        );
    },
    fetchTrackBetInfo: (date, trackId) => {
        return post('fetchTrackBetInfo', { date, trackId });
    },
    fetchMarkingBetPoolInfo: (date, trackId, productId, options) =>
        post(
            'fetchMarkingBetPoolInfo',
            {
                date,
                trackId,
                betType: PRODUCTS_BET_NAMES[productId],
            },
            options
        ),
    // fetchTrackPool: (date, trackId, options) =>
    //     Promise.resolve({
    //         data: {},
    //         success: true,
    //         error: '',
    //     }),
    fetchTrackPool: (date, trackId, options) => {
        if (date === '2022-02-17' && trackId === 12) {
            return Promise.resolve({
                data: {},
                success: true,
                error: '',
            });
        }
        return post(
            `fetchTrackPoolInformation`,
            {
                date,
                trackId,
            },
            options
        );
    },
    fetchAllPoolInformation: (options) =>
        get('fetchAllPoolInformation', {}, options),

    fetchServerTime: (options) => get('getTime', {}, options),

    getRacingCardEndpoints: () => allProductsEndpoints,

    fetchRaceDayResult: (date, trackId) =>
        get('fetchRaceDayResult', { date, trackId }),

    fetchAvailablePools: (date, trackId) =>
        get('fetchAvailablePools?' + qs.stringify({ date, trackId }), {}),

    fetchProductTrackPoolData: (date, trackId, productName) =>
        get('fetchProductTrackPoolData', { date, trackId, productName }),

    fetchTrioOdds: (date, trackId, raceNumber, horse1, horse2, horse3) =>
        post('fetchTrioOdds', {
            date,
            trackId,
            raceNumber,
            horse1,
            horse2,
            horse3,
        }),

    fetchMergedPools: (date, trackId) =>
        post(`fetchRaceDayPoolInfo`, { date, trackId }),

    fetchRaces: (date, trackId, productId) => {
        return Promise.all([
            post(`fetch${PRODUCTS_SYS_NAMES[productId]}RacingCard`, {
                date,
                trackId,
            }),
            post(`fetchTrackBetInfo`, { date, trackId }),
            post(`fetchTrackPoolInformation`, {
                date,
                trackId,
            }),
            post(`fetchRaceDayPoolInfo`, { date, trackId }),
        ]);
    },
    getTrackCountry: (trackCode) =>
        post('getTrackCountry?' + qs.stringify({ trackCode })),

    getTrack: (trackId) => get('getTrack?' + qs.stringify({ trackId })),
});

export default AISREST();
