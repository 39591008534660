import styled from 'styled-components';
import Wrapper from 'ui/Wrapper';
import { COLORS } from 'themes';

const PopupWrapper = styled(Wrapper)`
    background: ${COLORS.white};
    height: 100%;
    width: 100%;
    overflow: scroll;
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

export default PopupWrapper;
