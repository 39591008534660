import React from 'react';
import Icon from 'ui/Icon';
import { TriggerButton } from './styled';
import { useSelector } from 'react-redux';
import productSelector from 'common/selectors/productSelector';
import getTexts from 'utils/localization';
import { PlayTogether } from 'features/Spilklub/Mobile/styled';
import useTrigger from 'features/Spilklub/hooks/useTrigger.ts';

const t = getTexts();

const Trigger = () => {
    const product = useSelector(productSelector);
    const trigger = useTrigger();

    return (
        <TriggerButton onClick={trigger} hide={!product.isVProduct()}>
            <PlayTogether uppercase white condensed bold flex={'unset'}>
                {t.bb.spilklub}
            </PlayTogether>{' '}
            <Icon ispilklub size="x1" color="white" />
        </TriggerButton>
    );
};

export default Trigger;
