import useAuth from 'common/hooks/useAuth.ts';
import { useEffect, useState } from 'react';

const useAuthEffect = (onAuth, { enabled }) => {
    const auth = useAuth();

    const [complete, setComplete] = useState(false);

    useEffect(
        () => {
            if (auth.user && !complete && enabled) {
                // logged in
                onAuth();
                setComplete(true);
            }
        },
        [auth.user, complete, enabled]
    );
};

export default useAuthEffect;
