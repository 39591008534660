import { newUnpaidSale } from 'common/api/TerminalAPI';
import UserSessionAdapter from 'common/adapters/UserSession';

export const postPlayAndPaySale = (coupon, cb) => {
    const data = {
        hostname: UserSessionAdapter.getPrinterHost(),
        type: 'derby25',
        payload: coupon,
    };

    return newUnpaidSale(data)
        .then((res) => {
            //hide receipt
            cb(res);
            console.log('new-sale', res);
            return res;
        })
        .catch((error) => {
            console.error(error);
            return error;
        });
};
