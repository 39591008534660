import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SlickSlider } from './styled';
import MobileBet from './MobileBet';
import { useData, deleteMobileBet } from './hooks/useData';
import { hideModal, showModal } from 'common/actions/uiActions';
import getTexts from 'utils/localization';
import LocalMobileBet from './LocalMobileBet';
import { subtractMobileBetCount, resetMobileBetCount } from './state/actions';

const t = getTexts();
const MobileBetList = (props) => {
    const dispatch = useDispatch();
    const betData = { ...props };
    betData.user = useSelector((state) => state.auth.user);
    const betQuantity = LocalMobileBet.count();
    const ids = LocalMobileBet.getIds();
    const [data, isPending, setData] = useData(betData, true, ids);

    if (ids.toRemove.length > 0) {
        LocalMobileBet.cleanIds(ids);
        dispatch(resetMobileBetCount());
    }

    const renderMobileBets = () => {
        const mobileBetCommonProps = {
            isPending,
            listMode: true,
            yellowButtonText: t.Terminal.mobileBet.deleteCoupon,
            showShopList: () => {
                dispatch(showModal('SHOP_LIST', 103));
            },
        };

        let content = (
            <MobileBet
                {...mobileBetCommonProps}
                onClose={() => {
                    closeModal();
                }}
            />
        );

        if (betQuantity >= 1) {
            if (!isPending && Array.isArray(data)) {
                const sliderSettings = {
                    dots: true,
                    vertical: false,
                    infinite: false,
                    speed: 300,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    lazyLoad: true,
                };

                const mobileBetsView =
                    data.length === 1 ? (
                        <MobileBet
                            {...mobileBetCommonProps}
                            onClose={() => {
                                closeModal();
                            }}
                            onYellowButtonClick={(id) => {
                                filterBetFromList(id, data, setData);
                                deleteBet(id);
                            }}
                            data={data[0]}
                        />
                    ) : (
                        <SlickSlider {...sliderSettings}>
                            {data.map((mobileBet) => (
                                <MobileBet
                                    {...mobileBetCommonProps}
                                    key={mobileBet.id}
                                    onClose={() => {
                                        closeModal();
                                    }}
                                    onYellowButtonClick={(id) => {
                                        filterBetFromList(id, data, setData);
                                        deleteBet(id);
                                    }}
                                    data={mobileBet}
                                />
                            ))}
                        </SlickSlider>
                    );

                content = mobileBetsView;
            }
        }

        return content;
    };

    const filterBetFromList = (id, data, setData) => {
        if (Array.isArray(data)) {
            setData(data.filter((bet) => bet.id !== id));
        } else {
            setData([]);
        }
    };

    const deleteBet = (id) => {
        dispatch(subtractMobileBetCount());
        LocalMobileBet.delete(id);
        deleteMobileBet(id);
    };

    const closeModal = () => {
        dispatch(hideModal('SAVED_BET_LIST'));
    };

    return renderMobileBets();
};

export default MobileBetList;
