import { useMemo } from 'react';
import { create } from 'zustand';
import moment, { Moment } from 'moment';

import { addDays, SERVER_DATE_FORMAT, subDays, today, toServerDate } from 'utils/date';

interface UseCalendarDate {
    date: string;
    setDate: (date: string) => void;
    isPreviousDateAvailable: boolean;
    isNextDateAvailable: boolean;
    mDate: Moment;
    selectNextDate: () => void;
    selectPrevDate: () => void;
    isToday: boolean;
}

interface CalendarDateStore {
    date: string;
    setDate: (date: string) => void;
}

const useCalendarDateState = create<CalendarDateStore>()(set => ({
    date: toServerDate(today()),
    setDate: date => set({ date }),
}));

const useCalendarDate = (): UseCalendarDate => {
    const { date, setDate } = useCalendarDateState();

    const mDate = useMemo(() => moment(date, SERVER_DATE_FORMAT), [date]);

    const isPreviousDateAvailable = true;

    const isNextDateAvailable = mDate.isBefore(moment(), 'day');

    const selectNextDate = () => {
        setDate(addDays(date, 1, SERVER_DATE_FORMAT));
    };
    const selectPrevDate = () => {
        setDate(subDays(date, 1, SERVER_DATE_FORMAT));
    };

    const isToday = toServerDate(today()) === date;

    return {
        date,
        setDate,
        isPreviousDateAvailable,
        isNextDateAvailable,
        mDate,
        selectNextDate,
        selectPrevDate,
        isToday,
    };
};

export default useCalendarDate;
