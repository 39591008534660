import React from 'react';
import styled from 'styled-components';
import { COLORS } from 'themes';
import Switcher from 'ui/controls/Switcher';
import { byPlatform } from 'utils/platforms';
import { emailRegex } from 'utils/regex';
import { ErrorUnderInput } from 'ui/controls/Input';

import getTexts from 'utils/localization';

const t = getTexts();

const ForcedPermissionWrapper = styled.div`
    padding: 0 ${byPlatform('12px', '22px')};
    margin: 0 auto;
    color: ${COLORS.grey};
`;

const Input = styled.input`
    border-radius: 7px;
    display: block;
    padding: 5px 10px;
    width: 100%;
    box-sizing: border-box;
    font-size: ${byPlatform('16px', '14px')};
    border: 1px solid #dddddd;
    font-weight: bold;
    line-height: 22px;
    color: #9198aa;
`;

const Error = styled(ErrorUnderInput)`
    margin: 5px 10px;
    padding: 0;
`;

const Tooltip = styled.div`
    font-size: ${byPlatform('14px', '12px')};
    padding: 15px 10px;
    text-align: justify;
`;

const ForcedPermissionForm = ({
    forcedPermissionStatus,
    forcedPermissionEmail,
    setForcedPermissionStatus,
    setForcedPermissionEmail,
}) => {
    const emailValid = emailRegex.test(forcedPermissionEmail);

    return (
        <ForcedPermissionWrapper>
            <Input
                value={forcedPermissionEmail}
                onChange={(e) => setForcedPermissionEmail(e.target.value)}
            />

            {!emailValid && <Error>{t.validation.email}</Error>}

            <Tooltip>
                <Switcher
                    checked={forcedPermissionStatus}
                    onChange={(checked) => setForcedPermissionStatus(checked)}
                    size={'17px'}
                    display="inline-block"
                    margin="7px 15px 0 0"
                    width={'40px'}
                    style={{ float: 'left' }}
                />
                Hvis ikke du ønsker at modtage tilbud eller nyheder om lignende
                produkter (væddemål og online casino fra 25Syv A/S, herunder
                Bet25 Sport, Bet25 Casino, Bet25 Heste og Casino Go) via din
                oplyste e-mailadresse, skal du swipe til venstre. Du kan til
                enhver framelde dig at modtage tilbud og nyheder fra 25Syv A/S
                igen. Læs mere om regler for beskyttelse af personlige
                oplysninger her: Vores persondatapolitik.
            </Tooltip>
        </ForcedPermissionWrapper>
    );
};

export default ForcedPermissionForm;
