import { useDidMount } from 'rooks';
import persistentStorage from 'common/storage';

const ClearBetslipCookie = () => {
    useDidMount(() => {
        persistentStorage.remove('BetSlip');
        window.location.pathname = '/heste';
    });

    return null;
};

export default ClearBetslipCookie;
