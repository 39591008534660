import React from 'react';
import Text from 'ui/Text';
import styled from 'styled-components';
import { COLORS } from 'themes';
import { ReactComponent as Arrow } from 'images/icons/arrow-sharp.svg';

const OddsWrapper = styled.div`
    display: flex;
    position: relative;
    height: 100%;
    flex-flow: column;
    justify-content: center;
    background: ${({ vPercentTrend }) =>
        vPercentTrend === 'FALLING_TREND'
            ? '#EBDFE4'
            : vPercentTrend === 'GROWING_TREND'
                ? '#E9F7F0'
                : 'none'};
    border-radius: 7px;
`;

const ArrowIcon = styled.span`
    padding-left: 3px;
    & svg {
        transform: ${({ vPercentTrend }) =>
            vPercentTrend === 'FALLING_TREND'
                ? 'rotate(90deg)'
                : vPercentTrend === 'GROWING_TREND'
                    ? 'rotate(-90deg)'
                    : 'none'};
    }
    & svg path {
        fill: ${({ vPercentTrend }) =>
            vPercentTrend === 'FALLING_TREND'
                ? '#E54C4C'
                : vPercentTrend === 'GROWING_TREND'
                    ? '#2CB16F'
                    : 'none'};
    }
`;

const defaultPlaceholder = '1.00';

const OddsIndicator = (props) => {
    const odds =
        typeof props.odds === 'number'
            ? props.vPercentage
                ? props.odds + '%'
                : props.odds.toFixed(2)
            : props.odds && props.odds.length > 0
                ? props.odds
                : defaultPlaceholder;
    return (
        <OddsWrapper vPercentTrend={props.vPercentTrend}>
            <Text
                bold
                color={COLORS.black}
                size={16}
                className="text-center"
                padding="7px 0"
            >
                {props.scratched ? '-' : odds || defaultPlaceholder}

                {props.vPercentTrend === 'FALLING_TREND' ? (
                    <ArrowIcon vPercentTrend={props.vPercentTrend}>
                        <Arrow />
                    </ArrowIcon>
                ) : props.vPercentTrend === 'GROWING_TREND' ? (
                    <ArrowIcon vPercentTrend={props.vPercentTrend}>
                        <Arrow />
                    </ArrowIcon>
                ) : null}
            </Text>
        </OddsWrapper>
    );
};

export default OddsIndicator;
