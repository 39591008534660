import { useEffect, useState } from 'react';
import { normalize } from 'utils/object-helper';
import useHorseVideosQuery from './useHorseVideosQuery';

const useHorseVideos = (params = {}) => {
    const [videos, setVideos] = useState([]);
    const { status, data } = useHorseVideosQuery(params);

    useEffect(() => {
        if (status === 'success') {
            setVideos(normalize(data?.races || []));
        }
    }, [data, status]);

    return {
        videos,
    };
};

export default useHorseVideos;
