import React from 'react';
import Wrapper from 'ui/Wrapper';
import Text from 'ui/Text';
import ScanGif from './assets/money-in.gif';
import styled from 'styled-components';
import getTexts from 'utils/localization';

const t = getTexts();

const ScanImg = styled.img`
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
    transform: scale(1.7);
`;

export default class Checkin extends React.Component {
    render() {
        return (
            <Wrapper overflow="hidden">
                <Text align="center" size="x3">
                    {t.Terminal.Checkin.modalTitle}
                </Text>
                <ScanImg src={ScanGif} alt="scan coupon" />
            </Wrapper>
        );
    }
}
