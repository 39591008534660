import { create } from 'zustand';
import { useDidUpdate } from 'rooks';

import useTracksResultsQuery, {
    TrackResult,
} from 'features/ResultsNew/hooks/useTracksResultsQuery';
import useURLParameters from 'features/ResultsNew/hooks/useURLParameters';

import { generateResultPath, history } from 'utils/navigation';

interface ActiveTrackStore {
    activeTrack: TrackResult | null;
    setActiveTrack(track: TrackResult | null): void;
}

const useActiveTrackState = create<ActiveTrackStore>()(set => ({
    activeTrack: null,
    setActiveTrack: track => set({ activeTrack: track }),
}));

const useActiveTrack = () => {
    const { date, trackId, product } = useURLParameters();
    const { tracks, isLoading } = useTracksResultsQuery();
    const { activeTrack, setActiveTrack } = useActiveTrackState();

    useDidUpdate(() => {
        if (tracks) {
            const selectedTrack = tracks
                ? tracks.find(track => track.track.trackId === parseInt(trackId)) || null
                : null;
            setActiveTrack(selectedTrack);
        }
    }, [trackId, tracks]);

    const onSelectRace = (raceNumber: number, idx: number) => {
        history.push(generateResultPath({ date, product, trackId, race: raceNumber }));
    };

    return {
        activeTrack,
        onSelectRace,
        isLoading,
    };
};
export default useActiveTrack;
