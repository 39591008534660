import styled from 'styled-components';
import { getBackgroundByProps } from '../utils/theme-helpers';

const Divider = styled.hr`
    margin: ${(props) => (props.margin ? props.margin : '0px')};
    padding: 0;
    height: 1px;
    background: ${({ color, ...props }) => color || getBackgroundByProps(props)};
    border: 0;
`;

export default Divider;
