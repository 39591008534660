import React from 'react';
import { InputControl, InputWrapper } from 'ui/controls/Input';
import { bottomBarStyles, COLORS } from './index';
import styled from 'styled-components';

const Wrapper = styled(InputWrapper)`
    input {
        text-align: center;
        font-size: 21px;
        color: ${COLORS.primaryText};
    }
    ${bottomBarStyles};
    ${props =>
        props.top &&
        `
         top: ${props.top};
    `};
`;

const Input = props => (
    <Wrapper {...props}>
        <InputControl ref={props.inputRef} {...props} />
    </Wrapper>
);

export default Input;
