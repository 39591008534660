import getTexts from 'utils/localization';
const t = getTexts();

export function getTranslatedErrorMessage(response) {
    const error = extractErrorMessageFromResponse(response);

    return bettingErrorsMap[error] ? bettingErrorsMap[error] : error;
}

const bettingErrorsMap = {
    NONEXISTING_POOL: t.Terminal.notifications.bettingServiceError,
};

export function extractErrorMessageFromResponse(response) {
    const errors =
        response.data &&
        response.data.errors &&
        response.data.errors.error_message &&
        Array.isArray(response.data.errors.error_message)
            ? response.data.errors.error_message
            : [];

    let errorMessage = '';

    if (errors.length) {
        errorMessage = errors[0];
    } else {
        errorMessage = response.errorMessage
            ? response.errorMessage
            : response.msg;
    }

    return errorMessage;
}
