import React from 'react';
import { NavLink } from 'react-router-dom';

import Popup, { PopupWrapper as PopupWrapperDef } from 'ui/Popup';
import Text from 'ui/Text';
import styled from 'styled-components';
import Button from 'ui/controls/Button';
import { COLORS } from 'themes';
import { getRouterState } from 'utils/navigation';
import { byPlatform, isMobile } from 'utils/platforms';

const PopupWrapper = styled(PopupWrapperDef)`
    width: 300px;
    border-radius: 7px;
    height: auto;
    min-height: 0;
    padding: 25px;
    box-sizing: border-box;
`;

const BoldText = styled.span`
    font-weight: bold;
`;

const Title = styled(Text)`
    font-weight: bold;
    font-size: 20px;
    color: ${COLORS.black};
`;

const Txt = styled(Text)`
    line-height: 16px;
    font-size: 13px;
    color: ${COLORS.black};
`;

const Btn = styled(Button)`
    display: block;
    width: 90%;
    margin: 10px auto;
    font-weight: bold;
    padding: 0;
    height: 30px;
    line-height: 30px;
    & a {
        width: 100%;
        display: inline-block;
    }
`;

const LimitExceed = ({ modalData, hideModal, showModal }) => {
    const hideThis = () => hideModal('DEPOSIT_LIMIT_EXCEED');

    const goToDepositLimits = () => {
        if (isMobile) {
            hideModal('DEPOSIT');
            showModal('DEPOSIT_LIMITS', 3);
        }

        hideThis();
    };

    return (
        <Popup PopupWrapperComponent={PopupWrapper} disableCloseButton>
            <Title>Det’ lige over grænsen!</Title>
            <Txt>
                Din indbetalingsgrænse er nået og du kan derfor i øjeblikket kun indbetale{' '}
                <BoldText>{modalData.maxDeposit} Kr. </BoldText> Du kan altid hæve din grænse men
                ændringen træder første i kraft efter 24 timer.
            </Txt>
            <Btn onClick={goToDepositLimits} accentAlt>
                {byPlatform(
                    'Ændre grænse',
                    <NavLink to={{ pathname: '/konto/depositlimits', state: getRouterState() }}>
                        Ændre grænse
                    </NavLink>
                )}
            </Btn>
            <Btn onClick={hideThis}>OK</Btn>
        </Popup>
    );
};

export default LimitExceed;
