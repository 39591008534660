import moment, { Moment } from 'moment';

export enum SelfExclusionType {
    // punter.excludeUserDays
    day = 'ex_24h',
    // punter.excludeUserByPeriod, suits for 1 day also
    datePeriod = 'ex_dt',
    // punter.excludeUserPermanently
    month = 'ex_1m',
}

export interface UserStatus {
    time: Moment;
    status: SelfExclusionType;
    cashout: number;
}

interface UserStatusResponse {
    tm: number;
    status: SelfExclusionType;
    cashout: number;
}

export const deserializeUserStatus = (
    data: UserStatusResponse
): UserStatus => ({
    time: moment(data.tm),
    status: data.status,
    cashout: data.cashout,
});
