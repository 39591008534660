import {
    PoolTemplate as PoolTemplateDTO,
    PoolSettings as PoolSettingsDTO,
    GroupInfoOutput,
    Pool as PoolDTO,
    Bet as BetDTO,
    ExternalPoolInfo as ExternalPoolDTO,
    Leg as LegDTO,
    CouponDraftInfoOutput as CouponDraftDTO,
    PoolRace,
    Horse as HorseDTO,
    LegResult as LegResultDTO,
    Track,
    UpdateCouponInput,
    Payout as PayoutDTO,
} from './server';
import Group, { GroupMember } from './model/Group';
import Pool, {
    createNullPayout,
    ExternalPool,
    InternalPool,
    Payout,
    PoolSettings,
} from './model/Pool';
import VxyCoupon from 'features/BetSlip2/BettingREST/DataObjects/VxyCoupon';
import VxyLeg from 'features/BetSlip2/BettingREST/DataObjects/VxyLeg';
import {
    BetSlipSelections,
    ReserveSelections,
    SelectionsByDates,
    SelectionsByStartNumbers,
} from 'features/BetSlip2/state/state';
import { CouponDraft, Leg } from './model/CouponDraft';
import moment from 'moment';
import Bet from 'common/DataObjects/Bet';
import Results from './model/Results';
import { BET_API_MARKS_STRING_LENGTH, PRODUCTS_BETTING_RULES } from 'configs/products';
import Product from 'common/DataObjects/Product';
import { range } from 'utils/array';
import { uniq } from 'lodash';
import { VRace } from 'features/TrackPage/model/Race';
import { UIHorseObject, UIRaceObject } from './components/BetSlip';

export const deserializeGroups = (groups: PoolTemplateDTO[], pageNumber: number = 1): Group[] =>
    groups.map((group, idx) => deserializeGroup(group, idx, pageNumber));

export const deserializeGroup = (
    group: PoolTemplateDTO,
    idx?: number,
    sourcePage?: number
): Group => ({
    id: group.poolTemplateId,
    idx,
    sourcePage,
    ownerId: group.groupOwnerId,
    name: group.name,
    avatarUrl: group.avatarUrl,
    created: new Date(group.created.replace(/ /g, 'T')),
    description: group.description,
    smsEnabled: group.smsEnabled,
    membersCount: group.membersCount,
    groupClosed: group.groupClosed,
    publicGroup: group.publicGroup,
    groupMember: group.groupMember,
    boughtSharesNumber: group.boughtSharesNumber,
    activePools: group.activePools,
    poolSettings: deserializePoolSettings(group.settings),
});

export const deserializeInternalPool = (pool: PoolDTO): InternalPool => ({
    id: pool.poolId,
    externalId: pool.externalPoolId,
    poolSettings: deserializePoolSettings(pool.poolSettings),
    shares: pool.shares, // same structure
    externalCouponId: pool.externalCouponId,
    hash: pool.poolHash,
    created: new Date(pool.created.replace(/ /g, 'T')),
    status: pool.status,
    totalAmount: pool.totalAmount,
    winAmount: pool.winAmount || 0,
    totalSpend: pool.placedCouponsAmount,
    poolCommissionPercent: pool.poolCommissionPercent,
    shareCommissionAmount: pool.shareCommissionAmount,
});

export const deserializePayout = (payout: PayoutDTO): Payout => ({
    poolLeftoverAmount: payout.poolLeftOverAmount,
    poolSpentAmount: payout.poolSpentAmount,
    poolTotalWin: payout.poolTotalWin,
    totalPoolAmount: payout.totalPoolAmount,
    userWin: payout.userWin,
    userId: payout.userId,
});

export const deserializePool = (pool: BetDTO, poolTemplate?: PoolTemplateDTO): Pool => ({
    groupId:
        poolTemplate !== undefined
            ? poolTemplate.poolTemplateId
            : pool.pool.poolTemplate.poolTemplateId,
    internalPool: deserializeInternalPool(pool.pool),
    externalPool: deserializeExternalPool(pool.externalPoolInfo),
    payout: pool.pool.payout ? deserializePayout(pool.pool.payout) : createNullPayout(),
    postTime: pool.postTime,
    postTimeUtc: pool.postTimeUtc,
});

export const deserializePools = (pools: BetDTO[], poolTemplate: PoolTemplateDTO): Pool[] =>
    pools.map(pool => deserializePool(pool, poolTemplate));

export const deserializeExternalPool = (externalPool: ExternalPoolDTO): ExternalPool => ({
    id: externalPool ? externalPool.externalPoolId : 0,
    raceDate: externalPool ? new Date(externalPool.poolInfo.raceDate) : new Date(),
    trackId: externalPool ? externalPool.poolInfo.trackId : 0,
    trackName: externalPool ? externalPool.poolInfo.trackName : 'Uknown track',
    productId: externalPool ? externalPool.poolInfo.product : 'Uknown product',
    raceNumber: externalPool ? externalPool.poolInfo.raceNumber : 0,
});

export type DeserializedGroupInfo = [Group, Pool[], Pool[]];

export const deserializeGroupInfo = (groupInfoOutput: GroupInfoOutput): DeserializedGroupInfo => [
    deserializeGroup(groupInfoOutput.poolTemplate),
    deserializePools(groupInfoOutput.activeBets, groupInfoOutput.poolTemplate),
    deserializePools(groupInfoOutput.finishedBets, groupInfoOutput.poolTemplate),
];

export const deserializeGroupMembers = (members: GroupMember[]): GroupMember[] =>
    members.map(member => deserializeGroupMember(member));

export const deserializeGroupMember = (groupMember: GroupMember): GroupMember => ({
    userId: groupMember.userId,
    firstName: groupMember.firstName,
    lastName: groupMember.lastName,
    subscribedToGroup: groupMember.subscribedToGroup !== undefined,
});

export const deserializePoolSettings = (pool: PoolSettingsDTO): PoolSettings => pool;

const removeExtraHorseMarks = (marks: string, legNr: number, horseCountsInRaces: number[]) =>
    marks
        .split('')
        .map((m: string, i: number) => {
            const startsLength = horseCountsInRaces[legNr - 1];
            return i + 1 > startsLength ? '0' : m;
        })
        .join('');

export const serializeCouponLegs = (
    coupon: VxyCoupon,
    product: Product,
    horseCountsInRaces: number[]
): LegDTO[] => {
    const bettingRules = PRODUCTS_BETTING_RULES[product.id || ''];
    const requiredLength = bettingRules ? bettingRules.races : 0;
    //@ts-ignore
    const marksStringLength = BET_API_MARKS_STRING_LENGTH[product.id];

    const legsWithMarks = coupon.legs.map((leg: VxyLeg) => ({
        legNumber: leg.legNumber,
        marks: removeExtraHorseMarks(
            leg.selected15Horses || leg.selected20Horses,
            leg.legNumber,
            horseCountsInRaces
        ),
        firstReserve: leg.firstReserve,
        secondReserve: leg.secondReserve,
        open: false,
    }));

    if (legsWithMarks.length < requiredLength) {
        const legs: LegDTO[] = [];

        range(requiredLength, true).map((legNumber: number) => {
            const filledLeg = legsWithMarks.find(leg => leg.legNumber === legNumber);

            legs.push(
                filledLeg || {
                    legNumber,
                    marks: range(marksStringLength)
                        .map(() => '0')
                        .join(''),
                    firstReserve: '',
                    secondReserve: '',
                    open: false,
                }
            );
        });

        return legs;
    }

    return legsWithMarks;
};

export const serializeSelectionsToCouponDraft = (
    selections: any,
    draftSelections: any,
    combinationPrice: number,
    isSelectionsMode: boolean,
    pool: Pool,
    coupon: CouponDraft
): UpdateCouponInput => {
    const combinationsCount = isSelectionsMode
        ? selections.combinationsCount
        : draftSelections.combinationsCount;

    return {
        combinationNumber: combinationsCount,
        combinationPrice,
        couponAmount: combinationsCount * combinationPrice,
        poolId: pool.internalPool.id,
        externalPoolId: pool.externalPool.id,
        // coupon id doesn't exist if creating a coupon
        couponId: coupon.couponId,
        externalCouponID: coupon.externalCouponId === 0 ? undefined : coupon.externalCouponId,
        strictBet: selections.strictBet,
        quickPick: false,
        smartLyn: false,
        //@ts-ignore
        legs: serializeSelectionsToLegs(
            isSelectionsMode ? selections : draftSelections,
            !isSelectionsMode,
            coupon
        ),
    };
};

export const serializeSelectionsToLegs = (
    selections: any,
    draft: boolean,
    coupon: CouponDraft
): LegDTO[] => {
    const requiredMarksStringLength = BET_API_MARKS_STRING_LENGTH[selections.product.id];

    return draft
        ? coupon.legs.map((leg: Leg) => ({
              legNumber: leg.legNumber,
              firstReserve: leg.firstReserve ? leg.firstReserve : undefined,
              secondReserve: leg.secondReserve ? leg.secondReserve : undefined,
              marks: leg.marks,
          }))
        : selections.betPicksWithRaces.map(([race, bet]: [VRace, Bet], i: number) => {
              const reserves = selections.reserves[race.index];
              const marks = new Array(requiredMarksStringLength)
                  .fill('0')
                  .map((value, index) => {
                      const startNr = index + 1;
                      return bet.isStartMarked(startNr) ? '1' : '0';
                  })
                  .join('');
              return {
                  legNumber: i + 1,
                  marks,
                  firstReserve: reserves ? reserves[0] : undefined,
                  secondReserve: reserves ? reserves[1] : undefined,
              };
          });
};

export const deserializeCouponDraft = (couponDraft: CouponDraftDTO): CouponDraft => ({
    ...couponDraft,
    couponId: couponDraft.couponId,
    externalCouponId: couponDraft.externalCouponID,
    betPlaced: couponDraft.externalCouponID > 0,
    legs: couponDraft.legs
        .sort((l1, l2) => (l1.legNumber > l2.legNumber ? 1 : -1))
        .map(l => ({
            ...l,
            firstReserve: parseInt(l.firstReserve, 10),
            secondReserve: parseInt(l.secondReserve, 10),
        })),
});

export const deserializeCoupons = (coupons: CouponDraftDTO[]): CouponDraft[] =>
    coupons.map(coupon => deserializeCouponDraft(coupon));

export const deserializeResults = (
    resultsByLegs: LegResultDTO[],
    externalCouponId: number
): Results => ({
    couponId: externalCouponId,
    results: resultsByLegs,
});

export const deserializeRaces = (races: PoolRace[], tracks: Track[] = []): UIRaceObject[] => {
    const trackCodes = Object.fromEntries(tracks.map(track => [track.trackId, track.track.code]));

    return races.map(race => ({
        postTime: deserializeArrayTime(race.postTime),
        postTimeUTC: deserializeArrayTime(race.postTimeUTC),
        index: race.leg - 1,
        horses: deserializeHorses(race.starts),
        trackCode: trackCodes[race.trackId],
        hostTrack: { code: trackCodes[race.trackId], id: race.trackId },
    }));
};

export const deserializeHorses = (horses: HorseDTO[]): UIHorseObject[] =>
    horses.map(horse => ({
        startNr: horse.startNr,
        horse: {
            horseNameAndNationality: horse.horseNameAndNationality,
        },
        scratched: horse.scratched,
    }));

export const deserializeArrayTime = (time: string[]) => {
    let [hours, minutes] = time || [];
    return moment()
        .set({ hour: parseInt(hours), minute: parseInt(minutes), second: 0 })
        .format('HH:mm:ss');
};

export const couponDraftToBetSlip = (
    externalPool: ExternalPool,
    legs: Leg[],
    trackCode: string
): BetSlipSelections => {
    const date = moment(externalPool.raceDate).format('YYYY-MM-DD');
    const productId = externalPool.productId;

    const selections: SelectionsByDates<SelectionsByStartNumbers> = {
        [date]: {},
    };

    const reserves: SelectionsByDates<ReserveSelections> = {
        [date]: {},
    };

    legs.forEach((leg: Leg, index: number) => {
        if (!selections[date][trackCode]) {
            selections[date][trackCode] = {};
        }

        if (!reserves[date][trackCode]) {
            reserves[date][trackCode] = {};
        }

        if (!selections[date][trackCode][productId]) {
            selections[date][trackCode][productId] = {};
        }

        if (!reserves[date][trackCode][productId]) {
            reserves[date][trackCode][productId] = {};
        }

        selections[date][trackCode][productId][index] = legMarksToSelectionsByStartNumbers(
            leg.marks
        );

        const legReserves: number[] = [];

        leg.firstReserve && legReserves.push(leg.firstReserve);
        leg.secondReserve && legReserves.push(leg.secondReserve);

        reserves[date][trackCode][productId][index] = uniq(legReserves);
    });

    return {
        betsByDates: selections,
        reservesByDates: reserves,
    };
};

const legMarksToSelectionsByStartNumbers = (marks: string): SelectionsByStartNumbers => {
    const entries = marks
        .split('')
        .map((indicator, startIndex) => {
            return indicator === '1' ? [startIndex + 1, [startIndex + 1]] : [startIndex + 1, null];
        })
        .filter(([k, v]) => !!v);

    return Object.fromEntries(entries);
};
