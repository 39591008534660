import React, { ComponentType } from 'react';

interface PlayerProps extends IframeProps {
    videoId?: string;
    videoLink?: string;
    IframeComponent?: ComponentType<IframeProps>;
}

export interface IframeProps {
    width?: string;
    height?: string;
    allowFullScreen?: boolean;
    title?: string;
}

const Player = ({
    videoId,
    title,
    width,
    height,
    IframeComponent,
    allowFullScreen = true,
    videoLink = '',
}: PlayerProps) => {
    const iframeProps = {
        title,
        width,
        height,
        allowFullScreen,
        src: videoLink || `https://www.youtube-nocookie.com/embed/${videoId}`,
        frameBorder: 0,
    };

    return (
        <>
            {IframeComponent ? (
                <IframeComponent {...iframeProps} />
            ) : (
                <iframe {...iframeProps} />
            )}
        </>
    );
};

export default Player;
