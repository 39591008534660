import { useEffect, useState } from 'react';
import { hasURLParameters } from 'utils/navigation';
import { useSelector } from 'react-redux';
import productSelector from 'common/selectors/productSelector';
import { betBuddyShown } from 'common/selectors/uiSelector';

const URL_LEGS_REGEX = /^(d,)*\d_?(d,)*\d$/g;

const useBetslipVisibility = () => {
    const selectedProduct = useSelector(productSelector);
    const isBetBuddyShown = useSelector(betBuddyShown);

    const [visible, setVisibility] = useState(
        selectedProduct.isVProduct() &&
            hasURLParameters(
                [
                    'leg1',
                    'leg2',
                    'leg3',
                    'leg4',
                    'leg5',
                    'leg6',
                    'leg7',
                    'leg8',
                ],
                (key, value) => URL_LEGS_REGEX.test(value)
            )
    );

    const toggle = () => {
        setVisibility(!visible);
    };

    useEffect(
        () => {
            if (isBetBuddyShown) {
                setVisibility(true);
            }
        },
        [isBetBuddyShown]
    );

    return [visible, setVisibility, toggle];
};

export default useBetslipVisibility;
