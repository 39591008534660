export function evenOddMap(arr, oddFn, evenFn = () => {}) {
    let oddIterationNumber = 0;
    let evenIterationNumber = 0;
    return arr.map((v, i) => {
        const isOddIteration = (i + 1) % 2 !== 0;
        const result = isOddIteration
            ? oddFn(v, i, oddIterationNumber)
            : evenFn(v, i, evenIterationNumber);
        isOddIteration ? oddIterationNumber++ : evenIterationNumber++;
        return result;
    });
}

export function range(n, humanMode = false) {
    return Array.apply(null, { length: n }).map(
        (n, i) => (humanMode ? i + 1 : i),
        Number
    );
}

export function rangeLoop(n, handler) {
    range(n).forEach(handler);
}
