import Notification from 'features/BetSlip/components/BetslipNotifier/Notification';
import VNotification from 'features/BetSlip/components/BetslipNotifier/VNotification';

export const BETSLIP_NOTIFICATION_VIEWS = {
    V75: VNotification,
    V86: VNotification,
    GS75: VNotification,
    V65: VNotification,
    V64: VNotification,
    V5: VNotification,
    V4: VNotification,
    V3: VNotification,
    V: Notification,
    VP: Notification,
    DD: VNotification,
    LD: VNotification,
    TV: Notification,
    T: Notification,
    P: Notification,
    K: Notification,
    LK: VNotification,
    SK: VNotification,
    SOK: VNotification,
};
