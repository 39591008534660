import useAuth, { Auth } from './useAuth';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import usePrevious from './usePrevious';
import { openLogin } from 'utils/navigation';

export interface LoginModalData {
    redirectOnAuth?: string | null;
    pushOnAuth?: string;
}

export function isAuthorized(auth: Auth) {
    const userId = auth.user ? auth.user.id : 0;
    return !!(auth.user && userId !== 0);
}

const useAuthCallback = (
    callback: (...args: any[]) => any,
    redirectOnAuth?: string | null,
    onLoginSuccess?: () => void,
    loginModalPriority?: number,
    successLoginRedirectPath?: string
) => {
    const dispatch = useDispatch();

    const auth = useAuth();

    const userId = auth.user ? auth.user.id : 0;

    const authorized = isAuthorized(auth);

    const moveToLogin = () => {
        dispatch(
            openLogin({
                //@ts-ignore
                priority: loginModalPriority,
                //@ts-ignore
                redirectOnAuth,
                pushOnAuth: successLoginRedirectPath,
            })
        );
    };

    const prevUserState = usePrevious(auth.user);

    useEffect(() => {
        if (auth.user && !prevUserState) {
            // logged in
            onLoginSuccess && onLoginSuccess();
        }
    }, [userId, auth.pending]);

    return authorized ? callback : moveToLogin;
};

export default useAuthCallback;
