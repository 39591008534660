import React from 'react';
import { NEW_HOMEPAGE_UI } from 'configs/main';
import { byPlatform, omitMobile } from 'utils/platforms';
import * as RaceNavigation from 'ui/RaceNavigation';

import { HomepageRaceCalendar } from './RaceCalendar';
import TracksBySelectedDay from './TracksBySelectedDay';
import ProductsContainer from './Products';

const Navigation = ({ showLabels, gotEventError }) => {
    return (
        <RaceNavigation.Grid>
            {!NEW_HOMEPAGE_UI && (
                <>
                    {omitMobile(
                        <RaceNavigation.RaceNavigationLabel>
                            Dato
                        </RaceNavigation.RaceNavigationLabel>
                    )}
                    <HomepageRaceCalendar />
                </>
            )}
            <>
                {showLabels && (
                    <RaceNavigation.RaceNavigationLabel
                        style={byPlatform({}, { marginTop: '15px' })}
                    >
                        Bane
                    </RaceNavigation.RaceNavigationLabel>
                )}
                <TracksBySelectedDay />
            </>
            {!gotEventError && (
                <>
                    {showLabels && (
                        <RaceNavigation.RaceNavigationLabel
                            style={byPlatform({}, { marginTop: '-7px' })}
                        >
                            Spiltype
                        </RaceNavigation.RaceNavigationLabel>
                    )}
                    <ProductsContainer />
                </>
            )}
        </RaceNavigation.Grid>
    );
};

export default Navigation;
