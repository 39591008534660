import React from 'react';
import storage from 'common/storage';
import { Login as LoginMobile, WithClearSafariPageCache } from '@it25syv/25syv-ui';

const ANIMATED_BUTTON_WARNING =
    'MobileLogin: you must assign a value for resetAnimatedButtonHandler';

class MobileLogin extends React.Component {
    resetAnimatedButtonHandler = null;

    state = {
        userName: storage.get('userName', null, ''),
        password: '',
    };

    newLoginHandler = (
        userName,
        password,
        setLoading,
        onSuccess = () => {},
        onError = () => {}
    ) => {
        if (userName && password) {
            this.props.onLoginClick(userName, password, setLoading, onSuccess, onError);
            storage.saveLS({ userName: userName });
        } else {
            console.log('Handle error'); //todo handle error
        }
    };

    forgotPassHandler = () => {
        this.props.showForgotPassword();
    };

    resetAnimatedButton() {
        try {
            this.resetAnimatedButtonHandler();
        } catch (error) {
            console.info(ANIMATED_BUTTON_WARNING);
        }
    }

    componentDidUpdate() {
        if (this.props.error) {
            this.resetAnimatedButton();
        }
    }

    render() {
        const { close } = this.props;

        return (
            <LoginMobile
                closeAction={close}
                loginHandler={this.newLoginHandler}
                forgotPassHandler={this.forgotPassHandler}
                site={'heste'}
                showResetPassword
            />
        );
    }
}

export default WithClearSafariPageCache(MobileLogin);
