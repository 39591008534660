import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';
import Text from 'ui/Text';
import { getTextColorByProps } from 'utils/theme-helpers';

export const SelectionScreenContainer = styled.div`
    display: flex;
    gap: 20px;
    flex-direction: column;
    margin: 20px 0;
    ${isMobile &&
    css`
        padding: 0 15px;
    `};
`;

export const RaceInfoWrapper = styled.div`
    display: flex;
    justify-content: ${isMobile ? 'start' : 'end'};
    flex: 1 0 20%;
`;

export const RacesDetailsText = styled(Text)`
    ${isMobile &&
    css`
        padding: 0;
    `};

    ${!isMobile &&
    css`
        text-align: right;
    `}

    span {
        color: ${() => getTextColorByProps({ primary: true }) || 'black'};
        font-weight: 900;
    }
`;

export const TrackSelectionContainer = styled.div`
    display: flex;
    flex-direction: ${isMobile ? 'column' : 'row'};
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    padding: 15px 20px;
    cursor: pointer;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;
    border: 1px solid transparent;

    ${isMobile &&
    css`
        & > div {
            width: 100%;
        }
    `};

    &:hover {
        box-shadow: 0 5px 10px #dfe6fa;
        border: 1px solid #c6ccdb;
    }
`;

export const TrackMiddleSection = styled.div`
    display: flex;
    justify-content: start;
    gap: 25px;
    flex: 1 0 45%;
`;

export const Divider = styled.div`
    height: 1px;
    width: 100%;
    background-color: #dfe3ed;
    margin: 15px auto;
`;

// RacesOverLabel

export const RacesOverWrapper = styled.div`
    border-radius: 3px;
    background-color: rgba(229, 76, 76, 0.1);
`;
