import {
    SET_AIS_DATA_PROVIDER_INITIALIZING,
    SET_RACE_DAY_SELECTED,
    FEATCH_RACE_DAY_PENDING,
    FEATCH_RACE_DAY_SUCCESS,
    FEATCH_RACE_DAY_ERROR,
    FEATCH_RACING_CARD_PENDING,
    FEATCH_RACING_CARD_SUCCESS,
    FEATCH_RACING_CARD_ERROR,
    FETCH_COUPLE_RACING_CARD_PENDING,
    FETCH_COUPLE_RACING_CARD_SUCCESS,
    FETCH_COUPLE_RACING_CARD_ERROR,
    SELECT_PRODUCT,
    RESET_PRODUCT,
    SELECT_PRODUCT_SHORTCUT,
    SELECT_RACE_DAY,
    SET_DEFAULT_RACE_DAY,
    SELECT_RACE,
    RESET_RACE,
    FETCH_RACING_CARD_WITH_POOL_PENDING,
    FETCH_RACING_CARD_WITH_POOL_SUCCESS,
    FETCH_RACING_CARD_WITH_POOL_ERROR,
    SERVER_TIME,
    KEEP_RACE_NUMBER,
    SET_INPUT_ERROR_REASON,
    SET_EDIT_SELECTIONS_INITIALIZING,
    FETCH_POOL_LIST_PENDING,
    FETCH_POOL_LIST_SUCCESS,
    FETCH_POOL_LIST_ERROR,
    RESET_RACING_CARD_WITH_POOL_DATA,
} from './actions';
import { DEFAULT_SELECTED_PRODUCT } from 'configs/products';
import Product from 'common/DataObjects/Product';
import { NEW_HOMEPAGE_UI } from 'configs/main';

const raceDayInitialState = {
    raceDayPending: false,
    raceDayFetched: false,
    raceDayData: null,
    raceDayError: null,
};

const racingCardInitialState = {
    racingCardPending: false,
    racingCardFetched: false,
    racingCardData: {
        races: [],
        trackCode: null,
        date: null,
    },
    racingCardError: null,
};

const coupleRacingCardInitialState = {
    coupleRacingCardPending: false,
    coupleRacingCardFetched: false,
    coupleRacingCardData: {
        races: [],
        trackCode: null,
        date: null,
    },
    coupleRacingCardError: null,
};

const betInfoData = {
    defaultRace: {},
    driverChanged: {
        driverChanged: [],
    },
    scratched: {
        scratched: [],
    },
    shoeInfoRace: {
        shoeInfoRace: [],
    },
    trackInfo: {},
    weightChanged: {
        weightChanged: [],
    },
};

const betInfoInitialState = {
    betInfoPending: false,
    betInfoFetched: false,
    betInfoData,
    betInfoError: null,
};

const coupleBetInfoInitialState = {
    coupleBetInfoData: { ...betInfoData },
    coupleBetInfoError: null,
};

const poolInitialState = {
    trackPoolPending: false,
    trackPoolFetched: false,
    trackPoolError: null,
    trackPool: {},
};

// fetchAllPoolInformation
const poolListInitialState = {
    poolListPending: false,
    poolListFetched: false,
    poolListError: null,
    poolList: [],
};

const mergedPoolsInitialState = {
    mergedPoolsPending: false,
    mergedPoolsFetched: false,
    mergedPoolsError: null,
    mergedPools: [],
};

const couplePoolInitialState = {
    coupleTrackPoolFetched: false,
    coupleTrackPoolError: null,
    coupleTrackPool: {},
};

const selectedProductInitialState = {
    selectedProduct: Product.fill(DEFAULT_SELECTED_PRODUCT),
    defaultProduct: true,
    productShortcut: false,
};

const selectedRaceDayInitialState = {
    selectedRaceDay: null,
};

const tracksInitialState = {
    tracks: { byCode: {}, list: [] },
};

const initialState = {
    initializing: !NEW_HOMEPAGE_UI,
    raceDaySelected: false,
    editSelectionsInitializing: false,
    ...raceDayInitialState,
    ...racingCardInitialState,
    ...coupleRacingCardInitialState,
    ...selectedProductInitialState,
    ...selectedRaceDayInitialState,
    ...betInfoInitialState,
    ...poolInitialState,
    ...poolListInitialState,
    ...mergedPoolsInitialState,
    ...couplePoolInitialState,
    ...tracksInitialState,
    selectedRaceNumber: 1,
    raceIndex: 0,
    serverTime: null,
    keepRaceNumber: 0,
    keepRaceNumberWithDisabledRaces: 0,
    defaultRaceDay: true,
    inputErrorReason: null,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_AIS_DATA_PROVIDER_INITIALIZING:
            return {
                ...state,
                initializing: action.payload,
            };
        case SET_RACE_DAY_SELECTED:
            return {
                ...state,
                raceDaySelected: action.payload,
            };
        case SET_EDIT_SELECTIONS_INITIALIZING:
            return {
                ...state,
                editSelectionsInitializing: action.payload,
            };
        case FEATCH_RACE_DAY_PENDING:
            return {
                ...state,
                ...raceDayInitialState,
                raceDayPending: action.payload,
            };
        case FEATCH_RACE_DAY_SUCCESS:
            return {
                ...state,
                ...raceDayInitialState,
                raceDayFetched: true,
                raceDayData: action.payload.raceDayData,
                tracks: action.payload.tracks,
                raceInfos: action.payload.raceInfos,
            };
        case FEATCH_RACE_DAY_ERROR:
            return {
                ...state,
                ...raceDayInitialState,
                raceDayError: action.payload,
            };
        case FEATCH_RACING_CARD_PENDING:
            return {
                ...state,
                ...racingCardInitialState,
                racingCardPending: action.payload,
            };
        case FEATCH_RACING_CARD_SUCCESS:
            return {
                ...state,
                ...racingCardInitialState,
                racingCardFetched: true,
                racingCardData: action.payload,
                inputErrorReason: null,
            };
        case FEATCH_RACING_CARD_ERROR:
            return {
                ...state,
                ...racingCardInitialState,
                racingCardError: action.payload,
            };
        case FETCH_COUPLE_RACING_CARD_PENDING:
            return {
                ...state,
                ...coupleRacingCardInitialState,
                coupleRacingCardPending: true,
            };
        case FETCH_COUPLE_RACING_CARD_SUCCESS:
            const [coupleRacingCardData, coupleTrackPool, coupleBetInfoData] = action.payload;
            return {
                ...state,
                ...coupleRacingCardInitialState,
                ...couplePoolInitialState,
                ...coupleBetInfoInitialState,
                coupleRacingCardFetched: true,
                coupleRacingCardData,
                coupleTrackPool,
                coupleBetInfoData,
            };
        case FETCH_COUPLE_RACING_CARD_ERROR:
            const [coupleRacingCardError, coupleTrackPoolError, coupleBetInfoError] =
                action.payload;
            return {
                ...state,
                ...coupleRacingCardInitialState,
                ...couplePoolInitialState,
                ...coupleBetInfoInitialState,
                coupleRacingCardError,
                coupleTrackPoolError,
                coupleBetInfoError,
            };
        case FETCH_RACING_CARD_WITH_POOL_PENDING:
            return {
                ...state,
                trackPool: {},
                betInfoData: betInfoInitialState.betInfoData,
                mergedPools: {},
                racingCardPending: true,
                betInfoPending: true,
                trackPoolPending: true,
                mergedPoolsPending: true,
            };
        case FETCH_RACING_CARD_WITH_POOL_SUCCESS:
            const [racingCardData, trackPool, betInfoData, mergedPools] = action.payload;

            return {
                ...state,
                ...racingCardInitialState,
                ...poolInitialState,
                ...betInfoInitialState,
                ...mergedPoolsInitialState,
                racingCardFetched: true,
                trackPoolFetched: true,
                betInfoFetched: true,
                mergedPoolsFetched: true,
                racingCardData,
                trackPool,
                betInfoData: betInfoData || state.betInfoData,
                mergedPools: mergedPools || state.mergedPools,
                inputErrorReason: null,
            };
        case RESET_RACING_CARD_WITH_POOL_DATA:
            return {
                ...state,
                ...racingCardInitialState,
                ...poolInitialState,
                ...betInfoInitialState,
                ...mergedPoolsInitialState,
            };
        case FETCH_RACING_CARD_WITH_POOL_ERROR:
            const [racingCardError, trackPoolError, betInfoError, mergedPoolsError] =
                action.payload;
            return {
                ...state,
                ...racingCardInitialState,
                ...poolInitialState,
                ...betInfoInitialState,
                ...mergedPoolsInitialState,
                racingCardError,
                trackPoolError,
                betInfoError,
                mergedPoolsError,
            };
        case FETCH_POOL_LIST_PENDING:
            return {
                ...state,
                ...{
                    ...poolListInitialState,
                    poolListPending: true,
                },
            };
        case FETCH_POOL_LIST_SUCCESS:
            return {
                ...state,
                ...{
                    ...poolListInitialState,
                    poolListFetched: true,
                    poolList: action.payload,
                },
            };
        case FETCH_POOL_LIST_ERROR:
            return {
                ...state,
                ...{
                    ...poolListInitialState,
                    poolListError: action.payload,
                },
            };
        case SELECT_PRODUCT:
            return {
                ...state,
                selectedProduct: action.payload,
                defaultProduct: false,
                productShortcut: false,
                // selectedRaceNumber: initialState.selectedRaceNumber,
            };
        case SELECT_PRODUCT_SHORTCUT:
            return {
                ...state,
                selectedProduct: action.payload,
                defaultProduct: false,
                productShortcut: true,
                defaultRaceDay: initialState.defaultRaceDay,
                selectedRaceNumber: initialState.selectedRaceNumber,
            };
        case RESET_PRODUCT:
            return {
                ...state,
                selectedProduct: DEFAULT_SELECTED_PRODUCT,
                defaultProduct: true,
                productShortcut: false,
            };
        case SELECT_RACE_DAY:
            return {
                ...state,
                selectedRaceDay: action.payload,
                defaultRaceDay: initialState.defaultRaceDay,
            };
        case SET_DEFAULT_RACE_DAY:
            //@see https://ecosys.atlassian.net/browse/DER-1003
            return {
                ...state,
                defaultRaceDay: action.payload,
            };
        case SELECT_RACE:
            return {
                ...state,
                selectedRaceNumber: action.payload.raceNumber,
                raceIndex: action.payload.raceIndex,
            };
        case RESET_RACE:
            return {
                ...state,
                selectedRaceNumber: action.payload.raceNumber || 1,
                raceIndex: action.payload.raceIndex || 0,
                keepRaceNumber: 0,
            };
        case KEEP_RACE_NUMBER:
            return {
                ...state,
                keepRaceNumber: action.payload,
                keepRaceNumberWithDisabledRaces: action.payload,
            };
        case SERVER_TIME:
            return {
                ...state,
                serverTime: action.payload,
            };
        case SET_INPUT_ERROR_REASON:
            return {
                ...state,
                inputErrorReason: action.payload,
            };
        default:
            return { ...state };
    }
};

export default reducer;

export { initialState };
