import styled from 'styled-components';
import { DGABar, priority } from 'configs/layout';
import { hidePopup } from 'utils/navigation';
import useFade from '../common/hooks/useFade';

const getZIndex = (props) => {
    return `${props.priority ? priority.modalWrapper + props.priority : priority.modalWrapper}`;
};

const ModalContent = styled.div`
    position: fixed;
    top: ${(props) => {
        return props.topBarShown ? `${DGABar.mobile.height}px` : 0;
    }};
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: ${getZIndex};
    background: ${(props) => (props.color ? props.color : props.background)};
`;

export const useModalAnimation = ({ id }) => {
    const destroy = () => hidePopup(id);
    const { animateHide, onAnimationEnd, hiding } = useFade({ destroy });
    return { animateHide, onAnimationEnd, hiding };
};

export default ModalContent;
