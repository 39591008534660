import React from 'react';
import { withRouter } from 'react-router';
import { get } from 'lodash';
import {
    Wrapper,
    BarcodeContainer,
    OutdatedBarcodeContainer,
    BarCodeText,
    OutdatedText,
    OutdatedSubText,
    TitlePad,
    Title,
    ButtonStyled,
    ButtonText,
    Image,
    StyledIcon,
    ButtonContainer,
    Spinner,
} from './styled';
import SelectionCard from './SelectionCard';
import editIcon from 'images/icons/edit_white.png';
import Text from 'ui/Text';
import { DOMAIN_PATH } from 'configs/main';
// import Barcode from 'react-barcode';
import getTexts from 'utils/localization';
import LocalMobileBet from './LocalMobileBet';

const t = getTexts();
const MobileBet = (props) => {
    const { data, isPending } = props;
    const mobileBetId = get(data, 'id', null);
    const barcodeLink = get(data, 'barcode_link', null);
    const date = get(data, 'date', '');
    const time = get(data, 'race.postTime', '');
    const startDateTime = `${date} ${time}`;

    const renderBarcodeSection = () => {
        let section = isPending ? <Spinner /> : null;

        if (barcodeLink) {
            const outdated = LocalMobileBet.isOutdated(startDateTime, 30);

            section = outdated ? (
                <OutdatedBarcodeContainer>
                    <OutdatedText>
                        {t.Terminal.mobileBet.outdatedBarcodeText}
                    </OutdatedText>
                    <OutdatedSubText>
                        {t.Terminal.mobileBet.outdatedBarcodeSubText}
                    </OutdatedSubText>
                </OutdatedBarcodeContainer>
            ) : (
                <BarcodeContainer>
                    {barcodeLink && (
                        <Image src={DOMAIN_PATH + '/' + barcodeLink} />
                    )}
                    {/* <Barcode
                        value={mobileBetId}
                        width={3}
                        displayValue={false}
                    /> */}
                    <BarCodeText>
                        {t.Terminal.mobileBet.barcodeText}
                    </BarCodeText>
                </BarcodeContainer>
            );
        }

        return section;
    };

    return (
        <Wrapper>
            <TitlePad>
                <StyledIcon
                    src={editIcon}
                    size="x07"
                    onClick={props.onClose.bind(this, mobileBetId)}
                />
                <Title>{t.Terminal.mobileBet.title}</Title>
            </TitlePad>

            {renderBarcodeSection()}

            {data ? (
                <SelectionCard {...data} />
            ) : isPending ? null : (
                <Text>{t.Terminal.mobileBet.emptyList}</Text>
            )}

            {!isPending && (
                <ButtonContainer>
                    {mobileBetId && (
                        <ButtonStyled
                            accent
                            onClick={props.onYellowButtonClick.bind(
                                this,
                                mobileBetId,
                                startDateTime
                            )}
                        >
                            <ButtonText>{props.yellowButtonText}</ButtonText>
                        </ButtonStyled>
                    )}
                    <ButtonStyled white onClick={props.showShopList}>
                        <ButtonText>
                            {t.Terminal.mobileBet.listOfStores}
                        </ButtonText>
                    </ButtonStyled>
                </ButtonContainer>
            )}
        </Wrapper>
    );
};

export default withRouter(MobileBet);
