import React from 'react';

import { Label } from 'features/TrackPage/components/styled';
import RaceButtons from 'features/ResultsNew/components/ResultPage/RaceButtons';

import useResultPage from 'features/ResultsNew/hooks/useResultPage';

import { byPlatform } from 'utils/platforms';

import withFixedOnScroll, { WithFixedOnScrollProps } from 'ui/hoc/withFixedOnScroll';

const RaceButtonsWithFixedOnScroll = withFixedOnScroll(RaceButtons);

const settings: WithFixedOnScrollProps = {
    backgroundColor: '#f0f2f8',
    padding: '10px 0',
    offsetTopCorrection: 0,
    zIndex: 3,
    additionalStyles: { overflow: 'auto', width: '100%' },
};

interface RacesRowProps {
    showLabels?: boolean;
    onSelect: (race: number, idx: number) => void;
}

const RacesRow = ({ showLabels = true, onSelect }: RacesRowProps) => {
    const { raceNumber: selectedRace, raceLineNumbers } = useResultPage();

    return (
        <>
            {showLabels && <Label>Løb/afd.</Label>}
            {byPlatform(
                <RaceButtonsWithFixedOnScroll
                    {...settings}
                    // @ts-ignore
                    raceNumbers={raceLineNumbers}
                    onSelectRace={onSelect}
                    selectedRace={selectedRace}
                />,
                <RaceButtons
                    raceNumbers={raceLineNumbers}
                    onSelectRace={onSelect}
                    selectedRace={selectedRace}
                />
            )}
        </>
    );
};

export default RacesRow;
