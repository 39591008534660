import moment, { Moment } from 'moment';

export const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const COMMON_TIMESTAMP_FORMAT = 'YYYY-MM-DD hh:mm:ss';

export const dateFromString = (stringDate: string, dateFormat?: string) =>
    moment(stringDate, dateFormat || DEFAULT_DATE_FORMAT);

/**
 * Add zero to month or day if the essential format does not have zeros
 */
function addZeroToDateEntity(dateEntity: string | number) {
    return Number(dateEntity) < 10 ? '0' + dateEntity : dateEntity;
}

export const stringDateFromArray = (date: string[]): string => {
    const [year, month, day] = date;
    return `${year}-${addZeroToDateEntity(month)}-${addZeroToDateEntity(day)}`;
};

export const toDateTime = (date: Moment, dateSeparator: string, utcToLocal = true) => {
    let mDate = utcToLocal ? date.local() : date;

    return [mDate.format(`DD${dateSeparator}MM${dateSeparator}YYYY`), mDate.format('HH:mm')];
};

export const dateStringFromDateTimeString = (stringDate: string) => stringDate.slice(0, 10);

export const today = () => moment().format(DEFAULT_DATE_FORMAT);

export const todayNative = () => new Date(moment().format());

export const toNativeDate = (date: string | Moment, format?: string): Date => {
    if (moment.isMoment(date)) {
        return new Date(date.format());
    }

    return new Date(moment(date, format || DEFAULT_DATE_FORMAT).format());
};

export const toServerDate = (date: Date | Moment | string, format?: string) => {
    format = format || DEFAULT_DATE_FORMAT;

    if (moment.isMoment(date)) {
        return date.format(SERVER_DATE_FORMAT);
    }

    if (date instanceof Date) {
        return moment(date).format(SERVER_DATE_FORMAT);
    }

    return moment(date, format || DEFAULT_DATE_FORMAT).format(SERVER_DATE_FORMAT);
};

export const fromServerDate = (date: string) => {
    return moment(date, SERVER_DATE_FORMAT).format(DEFAULT_DATE_FORMAT);
};

export const addDays = (date: string, days: number, format: string = DEFAULT_DATE_FORMAT) => {
    return moment(date, format).add(days, 'days').format(format);
};

export const subDays = (date: string, days: number, format?: string) => {
    return moment(date, format).subtract(days, 'days').format(format);
};

export const arrayToDateFormat = (date: string | Array<number>) => {
    if (Array.isArray(date)) {
        const [Y, M, D, hh, mm] = date;
        // subtract 1 month because months in moment.js are started from 0 like array indices
        return moment([Y, M - 1, D, hh, mm]).format(COMMON_TIMESTAMP_FORMAT);
    }
    return date;
};
