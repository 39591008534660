import React from 'react';
import { Section, SplitScreen } from 'ui/layouts/SplitScreen';
import Wrapper from 'ui/Wrapper.tsx';
import Icon from 'ui/Icon';
import successIcon from 'icons/check.png';
import errorIcon from 'icons/wrong.png';
import Headline from 'ui/Headline';
import Text from 'ui/Text';
import Button from 'ui/controls/Button';
import getTexts from 'utils/localization';
const t = getTexts();

const Status = ({
    success = true,
    title = '',
    text = '',
    onClose = () => {},
}) => {
    const startChat = () => {
        window.Intercom('show');
    };

    return (
        <SplitScreen>
            <Section white height="100">
                <Wrapper align="center" padding="50px 10px 10px">
                    <Icon src={success ? successIcon : errorIcon} size="x6" />
                    <Headline size="x5" align="center">
                        {title}
                    </Headline>

                    <Text align="center">{text}</Text>

                    {!success ? (
                        <Button
                            accent
                            size="x2"
                            rounded
                            width="200px"
                            onClick={startChat}
                        >
                            {t.ForgotPassword.startChat}
                        </Button>
                    ) : null}
                    <br />
                    <Button
                        secondary
                        size="x2"
                        rounded
                        width="200px"
                        onClick={onClose}
                    >
                        Luk vinduet
                    </Button>
                </Wrapper>
            </Section>
        </SplitScreen>
    );
};

export default Status;
