import React from 'react';
import * as RaceNavigation from 'ui/RaceNavigation';
import { byPlatform } from 'utils/platforms';
import { PRODUCTS_NAMES } from 'configs/products';

class Product extends React.Component {
    render() {
        const { product, active, legacyMode } = this.props;
        const testId = active ? 'active' : 'inactive';

        return (
            <RaceNavigation.NavigationButton
                {...this.props}
                ref={this.props.innerRef}
                active={active}
                onClick={e => (!product.disabled ? this.props.onSelect(e, product) : null)}
                disabled={product.disabled}
                data-test-id={testId}
                style={{
                    padding: byPlatform('0 14px', '0 13px', '0 18px'),
                    minWidth: 'auto',
                }}
            >
                {!legacyMode ? PRODUCTS_NAMES[product] : product.name}
            </RaceNavigation.NavigationButton>
        );
    }
}

export default Product;
