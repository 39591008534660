import React from 'react';
import moment from 'moment';
import Wrapper from 'ui/Wrapper';
import UIButton from 'ui/controls/Button';
import Text from 'ui/Text';
import { TopWinIcon } from './uiComponents';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { applyCoupon, checkinByCoupon } from 'features/ScanLogicTerminal/actions';
import Product from 'common/DataObjects/Product';
import { PRODUCT_IDS, PRODUCTS_FOR_SPLITTING_BY_LEGS } from 'configs/products';
import SelectionRow from 'features/History/SelectionRow';
import { COUPON_STATUSES } from './contstants';
import { getFormattedCouponDate, getMappedData, getPositions } from './common';
import { hideScanModal } from './actions';
import { Col, Row } from 'react-grid-system';
import { saveJSONparsing } from 'utils/formatters';
// import { Reserve, ReserveNumber } from 'features/BetPick/styled';

import getTexts from 'utils/localization';
const t = getTexts();

const MainWrapper = styled(Wrapper)`
    padding-top: 80px;
`;

const ControlsWrapper = styled(Wrapper)`
    padding: 0 20px;
    display: flex;
    justify-content: center;
`;

const Button = styled(UIButton)`
    border-radius: 20px;
    padding: 10px 20px;
    height: 40px;
    width: 450px;
    margin: 0 0 0 10px;
`;

class Status extends React.Component {
    constructor() {
        super();

        this.state = {
            disableApplyButton: false,
        };
    }

    isCouponStatusWin() {
        return this.props.data.coupon.status === COUPON_STATUSES.WON;
    }

    isCouponWinAmountOverflowed() {
        const maxPossibleWin = 1500000; /* 15k kr*/
        const { winAmount } = this.getCoupon();

        return maxPossibleWin <= winAmount;
    }

    isCouponStatusLost() {
        return this.props.data.coupon.status === COUPON_STATUSES.LOST;
    }

    isCouponPending() {
        return this.props.data.coupon.status === COUPON_STATUSES.PENDING;
    }

    isCouponStatusUnknown() {
        return this.props.data.coupon.status === COUPON_STATUSES.UNKNOWN;
    }

    isCouponRollbacked() {
        return this.props.data.coupon.status === COUPON_STATUSES.ROLLBACKED;
    }

    isCouponStatusUnconfirmed() {
        return this.props.data.coupon.status === COUPON_STATUSES.UNCONFIRMED;
    }

    isCouponStatusCancelled() {
        return this.props.data.coupon.status === COUPON_STATUSES.CANCELLED;
    }

    isDerbyCoupon() {
        const { couponInfo, resultInfo, trackInfo, receipt } = this.getCoupon();
        return !!(couponInfo && resultInfo && trackInfo && receipt);
    }

    isBet25Coupon() {
        const { slipData } = this.getCoupon();
        return !this.isDerbyCoupon() && slipData;
    }

    isCouponPayouted() {
        const { paidout } = this.getCoupon();
        return String(paidout).toLowerCase() === 'yes';
    }

    lostClickHandler = () => {
        this.props.hideScanModal();
    };

    doneClickHandler = () => {
        this.props.hideScanModal();
    };

    applyHandler = () => {
        if (this.props.auth.user) {
            this.setState(
                {
                    disableApplyButton: true,
                },
                () => {
                    this.props.applyCoupon(
                        this.props.userSessionAdapter,
                        this.props.auth.user.id,
                        this.props.data.coupon.hash
                    );
                }
            );
        } else {
            this.setState(
                {
                    disableApplyButton: true,
                },
                () => {
                    this.props.checkinByCoupon(
                        this.props.userSessionAdapter,
                        this.props.data.coupon.hash
                    );
                }
            );
        }
    };

    getCoupon() {
        const coupon = this.props.data.coupon;
        const mappedData = getMappedData(coupon);

        mappedData.couponInfo = saveJSONparsing(mappedData.couponInfo);
        mappedData.receipt = saveJSONparsing(mappedData.receipt, {});
        mappedData.coupon = saveJSONparsing(mappedData.coupon, {});
        mappedData.resultInfo = saveJSONparsing(mappedData.resultInfo);

        return mappedData;
    }

    getBet25CouponStatusLabel() {
        const { status } = this.getCoupon();
        return status === COUPON_STATUSES.WON
            ? t.Terminal.CouponStatus.bet25CouponTitles.won
            : status === COUPON_STATUSES.LOST
            ? t.Terminal.CouponStatus.bet25CouponTitles.lost
            : t.Terminal.CouponStatus.bet25CouponTitles.pending;
    }

    renderWarningAboutOverflow() {
        const transferableCoupon = this.isCouponStatusWin() && !this.isCouponPayouted(),
            overflowedWinAmount = this.isCouponWinAmountOverflowed();

        return transferableCoupon && overflowedWinAmount ? (
            <Text align="center">{t.Terminal.CouponStatus.overflowedWinAmount}</Text>
        ) : null;
    }

    renderCommonControls() {
        const transferableCoupon = this.isCouponStatusWin() && !this.isCouponPayouted(),
            overflowedWinAmount = this.isCouponWinAmountOverflowed();
        return (
            <ControlsWrapper>
                {transferableCoupon && !overflowedWinAmount && (
                    <Button
                        accent
                        bold
                        onClick={this.applyHandler}
                        disabled={this.state.disableApplyButton}
                        className="uppercase"
                    >
                        {t.Terminal.CouponStatus.transferToTerminal}
                    </Button>
                )}
                {(this.isCouponStatusWin() ||
                    this.isCouponPending() ||
                    this.isCouponStatusCancelled() ||
                    this.isCouponStatusUnconfirmed() ||
                    this.isCouponStatusUnknown() ||
                    this.isCouponRollbacked()) && (
                    <Button grey bold onClick={this.doneClickHandler} className="uppercase">
                        {t.Terminal.CouponStatus.close}
                    </Button>
                )}
                {this.isCouponStatusLost() && (
                    <Button
                        red
                        bold
                        onClick={this.lostClickHandler}
                        style={{
                            color: '#fff',
                        }}
                        className="uppercase"
                    >
                        {t.Terminal.CouponStatus.lost}
                    </Button>
                )}
            </ControlsWrapper>
        );
    }

    renderDerbyCoupon() {
        const { coupon, couponId, couponInfo, winAmount, resultInfo, receipt } = this.getCoupon();

        const groupedResults = {};
        const groupedCouponsMapList = {};
        // const reservedCouponInfo = {};

        couponInfo.forEach((info) => {
            if (info.reserve === 0) {
                const productSpByLegs = PRODUCTS_FOR_SPLITTING_BY_LEGS.includes(coupon.product)
                    ? info.legNumber
                    : info.raceNumber;

                if (!groupedCouponsMapList[productSpByLegs]) {
                    groupedCouponsMapList[productSpByLegs] = [];
                }

                groupedCouponsMapList[productSpByLegs].push(info);
            } else {
                // if (!reservedCouponInfo[info.raceNumber]) {
                //     reservedCouponInfo[info.raceNumber] = [];
                // }
                // reservedCouponInfo[info.raceNumber].push(info);
                // reservedCouponInfo[info.raceNumber] = reservedCouponInfo[
                //     info.raceNumber
                // ].sort((a, b) => (a.reserve > b.reserve ? 1 : -1));
            }
        });

        const product = new Product().setAll(coupon.product);
        resultInfo.forEach((result) => {
            if (product.id === PRODUCT_IDS.K && result.place) {
                if (!groupedResults[result.place]) {
                    groupedResults[result.place] = [];
                }

                groupedResults[result.place].push(result);
            } else if (product.id !== PRODUCT_IDS.T) {
                if (!groupedResults[result.legNumber]) {
                    groupedResults[result.legNumber] = [];
                }

                groupedResults[result.legNumber].push(result);
            } else {
                if (!groupedResults[result.place]) {
                    groupedResults[result.place] = [];
                }

                groupedResults[result.place].push(result);
            }
        });

        const raceNumber = coupon.raceNumber
            ? coupon.raceNumber
            : couponInfo[0]
            ? couponInfo[0].raceNumber
            : null;

        let header = raceNumber ? ` - ${t.RacingCard.race} ${raceNumber}` : '';

        const derbyLynBet = coupon.quickPick ? ` - ${t.QuickPick.label}` : null;
        const smartLynBet = coupon.smartLyn ? ` - ${t.Terminal.PlayAndPay.smartLyn}` : null;

        if (derbyLynBet) {
            header = derbyLynBet;
        }

        if (smartLynBet) {
            header = smartLynBet;
        }

        const formattedTransactionDate = moment(receipt.transactionDate).format('DD/MM/YY HH:mm');

        const getResultInfo = (groupEl) => {
            if (product.id === PRODUCT_IDS.TV) {
                return resultInfo;
            } else if (!groupedResults[groupEl.legNumber]) {
                return false;
            }
            return [PRODUCT_IDS.TV, PRODUCT_IDS.T, PRODUCT_IDS.P].includes(product.id)
                ? groupedResults[groupEl.legNumber]
                : groupedResults[groupEl.legNumber].slice(0, 1);
        };

        return (
            <MainWrapper>
                {this.isCouponStatusWin() && <TopWinIcon />}
                <Text align="center" size="x3">
                    {couponId}: {product.name}
                    {header}{' '}
                </Text>
                <Text align="center" size="x2">
                    {formattedTransactionDate}
                </Text>
                <Wrapper>
                    <Wrapper padding="0px">
                        <Wrapper padding="10px">
                            <Row>
                                <Col
                                    xs={2}
                                    style={{
                                        margin: '0px',
                                        padding: '0px',
                                    }}
                                >
                                    <Text
                                        grey
                                        bold="500"
                                        padding="8px 0px"
                                        align="center"
                                        size="x05"
                                    >
                                        {product.isTrio() || product.isTvilling()
                                            ? t.betSlip.place
                                            : product.isVProduct()
                                            ? t.betSlip.department
                                            : t.betSlip.track}
                                    </Text>
                                </Col>
                                <Col
                                    xs={6}
                                    style={{
                                        margin: '0px',
                                        padding: '0px',
                                    }}
                                >
                                    <Text grey bold="500" padding="8px 0px" align="left" size="x05">
                                        {t.betSlip.nrAndHorse}
                                    </Text>
                                </Col>

                                <Col
                                    xs={4}
                                    style={{
                                        margin: '0px',
                                        padding: '0px',
                                    }}
                                >
                                    <Text
                                        grey
                                        bold="500"
                                        padding="8px 0px"
                                        align="center"
                                        size="x05"
                                    >
                                        {t.betSlip.winner}
                                    </Text>
                                </Col>
                            </Row>
                            {Object.values(groupedCouponsMapList).map((group, index) => {
                                const groupEl = group[0];
                                return (
                                    <SelectionRow
                                        key={index}
                                        index={index}
                                        group={group}
                                        product={coupon.product}
                                        productDTO={product}
                                        resultInfo={getResultInfo(groupEl)}
                                        isReserv={false}
                                        // reserv={reservHorses}
                                        winAmount={(winAmount / 100).toFixed(2)}
                                    />
                                );
                            })}
                            <Row
                                style={{
                                    margin: '0px',
                                    padding: '0px 10px',
                                }}
                            >
                                <Col
                                    xs={7}
                                    style={{
                                        margin: '0px',
                                        padding: '0px',
                                    }}
                                >
                                    <Text bold={900} size="x1" secondary padding="10px">
                                        {t.betSlip.sum}: {(receipt.totalCost / 100).toFixed(2)}{' '}
                                        {t.currency}
                                    </Text>
                                    {this.isCouponStatusWin() && (
                                        <Text bold={900} size="x1" secondary padding="10px">
                                            {t.History.winAmount}: {(winAmount / 100).toFixed(2)}{' '}
                                            {t.currency}
                                        </Text>
                                    )}
                                </Col>
                            </Row>
                        </Wrapper>
                    </Wrapper>
                    {this.renderCommonControls()}
                    {this.renderWarningAboutOverflow()}
                </Wrapper>
            </MainWrapper>
        );
    }

    renderBetCoupon() {
        const { saleId, winAmount, stake, slipData, createdAt } = this.getCoupon();

        const formattedCouponDate = getFormattedCouponDate(createdAt);

        return (
            <MainWrapper>
                {this.isCouponStatusWin() && <TopWinIcon />}
                <Text align="center" size="x3">
                    {this.getBet25CouponStatusLabel()}
                </Text>
                <Text align="center">
                    {saleId}
                    {' - '}
                    {formattedCouponDate}
                </Text>
                <Wrapper>
                    <Wrapper>
                        {getPositions(slipData)}
                        <Row>
                            <Col
                                xs={7}
                                style={{
                                    margin: '0px',
                                    padding: '0px',
                                }}
                            >
                                <Text bold={900} size="x1" secondary padding="10px">
                                    {t.betSlip.sum}: {(stake / 100).toFixed(2)} {t.currency}
                                </Text>
                                {this.isCouponStatusWin() && (
                                    <Text bold={900} size="x1" secondary padding="10px">
                                        {t.History.winAmount}: {(winAmount / 100).toFixed(2)}{' '}
                                        {t.currency}
                                    </Text>
                                )}
                            </Col>
                        </Row>
                    </Wrapper>
                    {this.renderCommonControls()}
                    {this.renderWarningAboutOverflow()}
                </Wrapper>
            </MainWrapper>
        );
    }

    render() {
        if (this.isDerbyCoupon()) {
            return this.renderDerbyCoupon();
        }
        if (this.isBet25Coupon()) {
            return this.renderBetCoupon();
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        scanModal: state.ScanModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        applyCoupon: (userSessionAdapter, sessionId, couponHash) => {
            dispatch(applyCoupon(userSessionAdapter, sessionId, couponHash));
        },
        checkinByCoupon: (userSessionAdapter, couponHash) => {
            dispatch(checkinByCoupon(userSessionAdapter, couponHash));
        },
        hideScanModal: () => {
            dispatch(hideScanModal());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Status);
