import React from 'react';
import { connect } from 'react-redux';
import { isUserLoggedIn } from 'common/actions/authActions';
import QuickPickReceipt from './QuickPickReceipt';
import { Fillable, FullScreen } from 'ui/FullScreen';
import { CloseButton, Popup, SuccessIcon } from 'features/BetSlip/components/Receipt/styled';
import Icon from 'ui/Icon';
import { COLORS } from 'themes';
import Hr from 'ui/Hr';
import PopupWrapper from 'ui/PopupWrapper';
import Modal from 'ui/ModalContent';

class SmartLynReceiptModal extends React.Component {
    componentDidMount() {
        this.props.enableCloseButton();
        this.props.isUserLoggedIn();
    }

    render() {
        return (
            <Modal topBarShown style={{ height: 'auto' }}>
                <PopupWrapper style={{ padding: 0 }}>
                    <FullScreen>
                        <Fillable>
                            <Popup
                                recipeMode={true}
                                style={{
                                    paddingTop: '5px',
                                    marginBottom: '10px',
                                }}
                            >
                                <SuccessIcon />

                                <CloseButton
                                    onClick={() => this.props.hideModal('SMART_LYN_RECEIPT')}
                                    style={{
                                        top: '-15px',
                                    }}
                                >
                                    <Icon
                                        iclose
                                        color="white"
                                        style={{
                                            width: '12px',
                                            height: '12px',
                                        }}
                                    />
                                </CloseButton>
                                <h1
                                    style={{
                                        fontSize: '30px',
                                        color: COLORS.grey,
                                        marginTop: '0px',
                                        marginBottom: '0px',
                                    }}
                                />

                                <Hr
                                    style={{
                                        marginBottom: '0px',
                                        marginTop: '10px',
                                    }}
                                />
                                <QuickPickReceipt {...this.props.modalData} />
                                <Hr style={{ marginTop: '15px' }} />
                            </Popup>
                        </Fillable>
                    </FullScreen>
                </PopupWrapper>
            </Modal>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    isUserLoggedIn: () => dispatch(isUserLoggedIn()),
});

export default connect(null, mapDispatchToProps)(SmartLynReceiptModal);
