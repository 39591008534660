import User from 'common/DataObjects/User';
import { getSession, checkoutCms } from 'common/api/TerminalAPI';
import { IS_TERMINAL } from 'utils/platforms';
import getTexts from 'utils/localization';
import { history } from 'utils/navigation';
const t = getTexts();

class UserSessionAdapter {
    /** */
    terminalId = null;

    /** */
    sessionId = null;

    /** */
    printerHost = null;

    /** */
    error = null;

    /** */
    authData = {
        error: null,
        user: null,
    };

    resetAuthData() {
        this.authData = {
            error: null,
            user: null,
        };
    }

    extractSessionData() {
        var params = history.location.search.substr(1);
        const urlParams = this.parse(params, '&'); //this.parse(window.document.cookie, ';');

        if (typeof urlParams.sessionId === 'string' && urlParams.sessionId !== '') {
            this.sessionId = urlParams.sessionId;
            window.localStorage.setItem('sessionId', this.sessionId);
        } else {
            this.error = t.Terminal.notifications.startSession;
        }

        if (urlParams.terminalId) {
            this.terminalId = urlParams.terminalId;
            window.localStorage.setItem('terminalId', this.terminalId);
        }

        if (urlParams.printerHost) {
            this.printerHost = urlParams.printerHost;
            window.localStorage.setItem('printerHost', this.printerHost);
        }
    }

    getSessionId() {
        if (!this.sessionId) {
            const sessionId = window.localStorage.getItem('sessionId');
            this.sessionId = sessionId;
        }

        return this.sessionId;
    }

    getTerminalId() {
        return this.terminalId ? this.terminalId : window.localStorage.getItem('terminalId');
    }

    getPrinterHost() {
        return this.printerHost ? this.printerHost : window.localStorage.getItem('printerHost');
    }

    parseFromParams() {}

    parse(data, spliter) {
        return data.split(spliter).reduce((object, str) => {
            let splitStr = str.split('=').map((cookiePart) => {
                return cookiePart.trim();
            });

            try {
                object[splitStr[0]] = JSON.parse(splitStr[1]);
            } catch (error) {
                object[splitStr[0]] = splitStr[1];
            }

            return object;
        }, {});
    }

    getUser() {
        return this.getAuthData().then((authData) => {
            return {
                error: authData.error,
                user: authData.error ? null : authData.user,
            };
        });
    }

    getAuthData() {
        return getSession(this.getSessionId()).then((res) => {
            return this.extractAuthData(res);
        });
    }

    extractAuthData(response) {
        if (response.success && response.data) {
            this.resetAuthData();

            const session = response.data.session;
            if (session) {
                const data = {
                    userId: session.id,
                    userName: t.Terminal.amount,
                    email: 'terminal-support@bet25.dk',
                    balance: session.amount / 100,
                    status: session.status,
                    hasNewsletterSubscription: false,
                    lastLogin: session.created_at,
                };

                this.authData.user = User.unserialize(data);

                if (data.userId) {
                    window.localStorage.setItem('sessionId', data.userId);
                }
            } else {
                this.authData.error = t.Terminal.notifications.noSession;
            }
        } else {
            this.authData.error = response.error_message
                ? response.error_message
                : t.Terminal.notifications.getSessionFail;

            if (this.authData.error === 'SESSION IKKE FUNDET') {
                this.eraseSession();
            }

            // if (this.authData.error) {
            //     //Der opstod en timeout på vores server. Reload terminalen om 30 sekunder og forsøg igen. Opstår dette igen, så kontakt vores support.
            // }
        }

        return this.authData;
    }

    getPerformSaleData(data, sessionId, postTime) {
        if (IS_TERMINAL) {
            data.postTime = postTime;

            return {
                session_id: sessionId || this.getSessionId(),
                terminal_id: this.getTerminalId(),
                hostname: this.getPrinterHost(),
                payload: JSON.stringify(data),
            };
        } else {
            return data;
        }
    }

    getPostTime(racingCardData, raceIndex) {
        const raceDate = racingCardData.date;
        const race = racingCardData.races[raceIndex];

        return raceDate && race ? `${raceDate} ${race.postTime}` : '-';
    }

    performUserUpdate(isUserLoggedIn, updateUserInfo, response) {
        if (IS_TERMINAL) {
            const authData = this.extractAuthData(response);
            updateUserInfo(authData);
        } else {
            isUserLoggedIn();
        }
    }

    eraseSession() {
        this.sessionId = null;
        window.localStorage.setItem('sessionId', '');
        document.cookie = 'sessionId= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
    }

    checkout = () => {
        checkoutCms(this.getTerminalId(), this.getSessionId());
    };

    isManagerEntrance() {
        return window.location.hostname === 'derby.cafe';
    }

    isLowAmount() {
        // const amount = auth && auth.user ? auth.user.balance : 0;
        // || amount <= 1 /** check amount */
        return !this.getSessionId();
    }
}

export default new UserSessionAdapter();
