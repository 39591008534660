import React, { useEffect } from 'react';
import Wrapper from 'ui/Wrapper';
import Toolbar from 'ui/Toolbar';
import FlexWrapper from 'ui/FlexWrapper';
import Arrow from 'ui/Arrow';
import Headline from 'ui/Headline';
import Spinner from 'ui/Spinner';
import getTexts from 'utils/localization';
import { Col, Row } from 'react-grid-system';
import { Avatar } from 'features/Spilklub2/Mobile/styled.tsx';
import { useDispatch } from 'react-redux';
import { setUIProps } from 'features/Spilklub2/actions.ts';

const t = getTexts();

const groupAvatars = [];
const fillAvatars = () => {
    for (let i = 1; i < 35; i++) {
        groupAvatars.push(`/img/group_avatars/${i}.png`);
    }
};

const GroupAvatarScreen = ({ onClose }) => {
    const dispatch = useDispatch();

    const onGroupAvatarSelected = avatar => {
        onClose();
        // setTimeout lets the animation to be finished first
        setTimeout(() => dispatch(setUIProps({ createGroupAvatar: avatar })), 300);
    };
    useEffect(() => {
        fillAvatars();
    }, []);

    if (groupAvatars) {
        return (
            <Wrapper padding="0" white>
                <Toolbar
                    secondary
                    left={
                        <FlexWrapper padding="0px">
                            <Arrow onClick={onClose} size="10" direction="left" />
                        </FlexWrapper>
                    }
                    leftSize={3}
                    centerSize={6}
                    rightSize={2}
                    alignLeft="center"
                    center={
                        <Headline white size="x2" align="center">
                            {t.Shortcuts.selectAvatar}
                        </Headline>
                    }
                    style={{ zIndex: 1003 }}
                />
                <Wrapper style={{ overflow: 'hidden' }}>
                    <Row>
                        {groupAvatars.map((avatar, id) => (
                            <Col key={id} xs={3}>
                                <Avatar bg={avatar} onClick={() => onGroupAvatarSelected(avatar)} />
                            </Col>
                        ))}
                    </Row>
                </Wrapper>
            </Wrapper>
        );
    } else {
        return <Spinner size="x2" dark />;
    }
};

export default GroupAvatarScreen;
