import Bet from 'common/DataObjects/Bet';
import {
    BET_API_MARKS_PROPERTY_NAMES,
    BET_API_MARKS_STRING_LENGTH,
    PRODUCT_IDS,
} from 'configs/products';
import { fillObjectByConstructor } from 'utils/object-helper';
import WinShowCoupon from 'features/BetSlip2/BettingREST/DataObjects/WinShowCoupon';
import TrifectaCoupon from 'features/BetSlip2/BettingREST/DataObjects/TrifectaCoupon';
import DoubleCoupon from 'features/BetSlip2/BettingREST/DataObjects/DoubleCoupon';
import Coupon from 'features/BetSlip2/BettingREST/DataObjects/Coupon';
import Track from 'common/DataObjects/Track';
import QuinellaCoupon from 'features/BetSlip2/BettingREST/DataObjects/QuinellaCoupon';
import VxyLeg from 'features/BetSlip2/BettingREST/DataObjects/VxyLeg';
import VxyCoupon from 'features/BetSlip2/BettingREST/DataObjects/VxyCoupon';
import KombCoupon from 'features/BetSlip2/BettingREST/DataObjects/KombCoupon';

class CouponFactory {
    /**
     * @type {number}
     */
    programNumber = 0;
    /**
     * @type {string}
     */
    date = '';
    /**
     * @type {string}
     */
    productId = '';
    /**
     * @type {Track}
     */
    track = new Track();
    /**
     * @type {number}
     */
    raceNumber = 0;
    /**
     * @type {number}
     */
    combinationNumber = 0;
    /**
     * @type {Bet}
     */
    bet = new Bet();
    /**
     * Starts for the selected race.
     * Useful for single leg coupons only.
     * @type {Pick<Starts, 'startNr'>[]}
     */
    starts = [];

    /**
     * The list of race picks.
     * Useful for multileg coupons only.
     * The keys are the backend race numbers
     * and the values are the bet picks
     * @type {Object}
     */
    racePicks = [];

    /**
     * This property sets Strict Mode for V64 and V65 products
     * @type {boolean}
     */
    strictMode = false;

    /**
     * Determines if the coupon needed for multitrack pool setup.
     * Suits for DD and V86 only which may contain races from 2 different tracks.
     * @type {boolean}
     */
    isMultitrack = false;

    /**
     * @type {Object}
     */
    multipleTrackSetups = null;

    /**
     * The keys are the race number
     * The values are the arrays of the start numbers
     * @type {Object}
     */
    reserves = {};

    constructor(attributes) {
        return Object.assign(this, attributes);
    }

    /**
     * @param  {number}         combinationPrice
     * @return {Coupon}
     */
    create = (combinationPrice) => {
        const { productId } = this;

        switch (productId) {
            case PRODUCT_IDS.V:
                return this.createWinShowCoupon(combinationPrice);
            case PRODUCT_IDS.P:
                return this.createWinShowCoupon(combinationPrice, true);
            case PRODUCT_IDS.VP:
                return this.createVPCoupon(combinationPrice);
            case PRODUCT_IDS.TV:
                return this.createQuinellaCoupon(combinationPrice);
            case PRODUCT_IDS.T:
                return this.createTrifectaCoupon(combinationPrice);
            case PRODUCT_IDS.K:
                return this.createKombCoupon(combinationPrice);
            case PRODUCT_IDS.V5:
            case PRODUCT_IDS.V65:
            case PRODUCT_IDS.V75:
            case PRODUCT_IDS.GS75:
            case PRODUCT_IDS.V4:
            case PRODUCT_IDS.V64:
            case PRODUCT_IDS.V86:
                return this.createVxyCoupon(combinationPrice);
            case PRODUCT_IDS.LD:
            case PRODUCT_IDS.DD:
                return this.createDoubleCoupon(combinationPrice);
            default:
                throw Error(`There is no coupon that suits product ${productId}.`);
        }
    };
    /**
     * @param  {number}         combinationPrice
     * @return {Coupon}
     * @throws {Error}
     */
    createCommonCoupon = (combinationPrice) => {
        if (process.env.NODE_ENV === 'production' && this.programNumber === 0) {
            throw Error(`Program number must be set.`);
        }

        return fillObjectByConstructor(
            {
                combinationNumber: this.combinationNumber,
                raceDay: this.date,
                raceNumber: this.raceNumber,
                trackCode: this.track.id,
                programNumber: this.programNumber,
                combinationPrice: parseFloat(combinationPrice),
            },
            Coupon
        );
    };

    /**
     * @param  {number}                 combinationPrice
     * @param  {boolean}       show     Determines whether the coupon is being created
     *                                  for SHOW product or not.
     *                                  true means PRODUCT_IDS.S
     *                                  false means PRODUCT_IDS.V
     * @return {WinShowCoupon}
     */
    createWinShowCoupon = (combinationPrice, show = false) => {
        const { bet, starts } = this;

        let marks = starts.map((start) => (bet.isStartMarked(start.startNr) ? '1' : '0'));

        const couponProperties = {
            marks: this.convertMarksToString(marks),
            showBet: show,
            winBet: !show,
            ...this.createCommonCoupon(combinationPrice),
        };

        return fillObjectByConstructor(couponProperties, WinShowCoupon);
    };

    createVPCoupon = (combinationPrice) => {
        const winShowCoupon = this.createWinShowCoupon(combinationPrice);
        return { ...winShowCoupon, showBet: true, winBet: true };
    };

    /**
     * @param  {number}                 combinationPrice
     * @return {KombCoupon}
     */
    createKombCoupon = (combinationPrice) => {
        const { bet, starts } = this;

        let marks1St = starts.map((start) => (bet.satisfies(start.startNr, 1) ? '1' : '0'));

        let marks2Nd = starts.map((start) => (bet.satisfies(start.startNr, 2) ? '1' : '0'));

        const couponProperties = {
            marks1St: this.convertMarksToString(marks1St),
            marks2Nd: this.convertMarksToString(marks2Nd),
            ...this.createCommonCoupon(combinationPrice),
        };

        return fillObjectByConstructor(couponProperties, KombCoupon);
    };

    /**
     * @param  {number}         combinationPrice
     * @return {QuinellaCoupon}
     */
    createQuinellaCoupon = (combinationPrice) => {
        const { bet, starts } = this;
        let standoutMarks = []; // U-horses

        let marks = starts.map((start) => {
            standoutMarks.push(bet.satisfies(start.startNr, 'U') ? '1' : '0');
            return bet.satisfies(start.startNr, start.startNr) ? '1' : '0';
        });

        return fillObjectByConstructor(
            {
                marks: this.convertMarksToString(marks),
                standoutMarks: this.convertMarksToString(standoutMarks),
                ...this.createCommonCoupon(combinationPrice),
            },
            QuinellaCoupon
        );
    };

    /**
     * @param  {number}         combinationPrice
     * @return {TrifectaCoupon}
     */
    createTrifectaCoupon = (combinationPrice) => {
        const { bet, starts } = this;
        let marks1 = [],
            marks2 = [],
            marks3 = [];

        starts.forEach((start) => {
            marks1.push(bet.satisfies(start.startNr, 1) ? '1' : '0');
            marks2.push(bet.satisfies(start.startNr, 2) ? '1' : '0');
            marks3.push(bet.satisfies(start.startNr, 3) ? '1' : '0');
        });

        return fillObjectByConstructor(
            {
                marks1St: this.convertMarksToString(marks1),
                marks2Nd: this.convertMarksToString(marks2),
                marks3Rd: this.convertMarksToString(marks3),
                ...this.createCommonCoupon(combinationPrice),
            },
            TrifectaCoupon
        );
    };

    /**
     * @param  {number}         combinationPrice
     * @return {VxyCoupon}
     */
    createVxyCoupon = (combinationPrice) => {
        const coupon = this.createCommonCoupon(combinationPrice);
        const requiredMarksStringLength = BET_API_MARKS_STRING_LENGTH[this.productId];

        // leg = { legNumber, firstReserve = '000000000000000', secondReserve = '000000000000000', selected15Horses = '000000000000000' };
        // Legs are numbered from 1 up to the number of races in the pool.
        // @see VxyLeg class
        const legs = Object.keys(this.racePicks).map((raceIndex) => {
            raceIndex = Number(raceIndex);
            const race = this.racePicks[raceIndex];
            const pickedHorseNumbers = new Array(requiredMarksStringLength)
                .fill('0')
                .map((value, index) => {
                    const startNr = index + 1;
                    return race.hasOwnProperty(startNr) ? '1' : '0';
                })
                .join('');

            return this.createVxyLeg(raceIndex + 1, pickedHorseNumbers, raceIndex);
        });

        if (this.isMultitrack) {
            coupon.programNumber = this.multipleTrackSetups.programNumber;
            coupon.trackCode = this.multipleTrackSetups.trackId;
        }

        return fillObjectByConstructor(
            {
                legs,
                strictBet: this.strictMode, // suits for V86 or V75 products
                // where the count of winners varies
                // for others like V4 it doesn't matter true or false
                ...coupon,
                raceNumber: undefined, // here are several races aka legs
            },
            VxyCoupon
        );
    };

    /**
     * @param  {number}         combinationPrice
     * @return {DoubleCoupon}
     */
    createDoubleCoupon = (combinationPrice) => {
        const { races, racePicks, isMultitrack, multipleTrackSetups } = this;

        let marks1 = [],
            marks2 = [];

        races.forEach(({ index: raceIndex, starts }, index) => {
            let marks = starts.map((start) => {
                return racePicks[raceIndex] && racePicks[raceIndex][start.startNr] ? '1' : '0';
            });

            if (index === 0) {
                marks1 = marks;
            } else {
                marks2 = marks;
            }
        });

        const result = fillObjectByConstructor(
            {
                marks1: this.convertMarksToString(marks1),
                marks2: this.convertMarksToString(marks2),
                ...this.createCommonCoupon(combinationPrice),
                raceNumber: isMultitrack
                    ? multipleTrackSetups.bettingRaceNumbers[0]
                    : races[0].raceNumber,
            },
            DoubleCoupon
        );

        if (isMultitrack) {
            result['trackCode'] = multipleTrackSetups.trackId;
            result['programNumber'] = multipleTrackSetups.programNumber;
        }

        return result;
    };
    /**
     * @param  {Array<string>}  marks
     * @return {string}
     */
    convertMarksToString = (marks) => {
        const requiredMarksStringLength = BET_API_MARKS_STRING_LENGTH[this.productId];
        let extraZeros = '';

        if (marks.length < requiredMarksStringLength) {
            extraZeros = new Array(
                requiredMarksStringLength - marks.length + 1
                // 1 is added because join will create string with array.length - 1
                // characters so [null,null,null,null].join('0') will give '000'
            ).join('0');
        }

        return marks.join('') + extraZeros;
    };

    /**
     *
     * @param  {number}           legNumber
     * @param  {string}           horseMarks
     * @param  {number}           raceIndex
     * @return {VxyLeg}
     */
    createVxyLeg = (legNumber, horseMarks, raceIndex) =>
        fillObjectByConstructor(
            {
                legNumber,
                [BET_API_MARKS_PROPERTY_NAMES[this.productId]]: horseMarks,
                firstReserve: this.reserves[raceIndex] ? this.reserves[raceIndex][0] : 0,
                secondReserve: this.reserves[raceIndex] ? this.reserves[raceIndex][1] : 0,
            },
            VxyLeg
        );
}

export default CouponFactory;
