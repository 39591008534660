import { useDispatch } from 'react-redux';
import { keepRaceIndexInState, setProduct } from 'features/AISDataProvider/actions';
import { addBet, setPrevAmount } from 'features/BetSlip2/state/actions';
import useCreateDispatch from 'common/hooks/useCreateDispatch';
import { PRODUCT_IDS } from 'configs/products';
import { ProductList } from 'common/DataObjects/Product';

/**
 * Used in receipt for placing same Vinder or Plads bet
 * (either P after V or V after P accordingly)
 *
 * @param reset {function}
 * @return {function(...[*]=)}
 */
const usePlaceSameVPBet = ({ reset = () => {} }) => {
    // dispatch
    const dispatch = useDispatch();
    const addBetD = useCreateDispatch(addBet);
    const setProductD = useCreateDispatch(setProduct);
    const keepRaceIndexInStateD = useCreateDispatch(keepRaceIndexInState);

    function placeVinderPladsBetFromRecipt(addBet, betSlip, date, trackCode, product, raceIndex) {
        try {
            let betForProduct = PRODUCT_IDS.P;

            if (product.id === PRODUCT_IDS.P) {
                betForProduct = PRODUCT_IDS.V;
            }

            if (
                betSlip.betsByDates &&
                betSlip.betsByDates[date] &&
                betSlip.betsByDates[date][trackCode]
            ) {
                Object.keys(betSlip.betsByDates[date][trackCode][product.id][raceIndex]).forEach(
                    (startNr) => {
                        addBet(
                            date,
                            trackCode,
                            betForProduct,
                            parseInt(raceIndex, 10),
                            parseInt(startNr, 10),
                            parseInt(startNr, 10)
                        );
                    }
                );

                setProduct(ProductList.getAll().find((p) => p.id === betForProduct));
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (betSlip, date, trackCode, product, raceIndex, prevAmount) => {
        reset();

        placeVinderPladsBetFromRecipt(
            addBetD,
            setProductD,
            keepRaceIndexInStateD,
            betSlip,
            date,
            trackCode,
            product,
            raceIndex
        );

        dispatch(setPrevAmount(prevAmount));
    };
};

export default usePlaceSameVPBet;
