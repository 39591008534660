import React from 'react';
import { byPlatform, forMobile, omitMobile } from 'utils/platforms';
import { PreloaderButtons, RaceNavigationLabel } from 'ui/RaceNavigation';
import styled from 'styled-components';
import { BACKGROUNDS } from 'themes';
import Wrapper from 'ui/Wrapper';
import { Card } from 'ui/Card';
import { range } from 'utils/array';
import { terminal } from 'configs/layout';

const RaceCardPreloaderWrapper = styled(Card)`
    border-radius: 10px;
    margin: ${byPlatform('0', '20px 0')};
`;

const RaceCardHeader = styled.div`
    height: 50px;
    background: ${BACKGROUNDS.primaryFlat};
    display: flex;
    align-items: center;
`;

const ButtonsRow = styled.div`
    height: 50px;
    align-items: center;
    display: flex;
`;

const SubHeader = styled.div`
    background: #9198aa;
    height: 20px;
`;

const Row = styled.div`
    display: grid;
    grid-template-columns: 70px 300px 1fr;
    align-items: center;
    padding: 10px 0;
    background: ${BACKGROUNDS.lightGrey};
    div {
        background: #b9bdc9;
    }
    &:nth-child(2n) {
        background: ${BACKGROUNDS.white};
    }
    &:nth-child(2n) div {
        background: ${BACKGROUNDS.lightGrey};
    }
`;

const SmallTextPlaceholder = styled.div`
    height: 20px;
    border-radius: 3px;
`;

const HeaderTextPlaceholder = styled(SmallTextPlaceholder)`
    background: #eceff6;
    width: 200px;
    opacity: 0.6;
    margin-left: 20px;
`;

const ButtonsBlockTextPlaceholder = styled(SmallTextPlaceholder)`
    background: #b9bdc9;
    width: 130px;
    margin-left: 20px;
`;

const TextWithSubHeader = styled.div`
    display: flex;
    flex-direction: column;
`;

const ButtonPlaceholder = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 5px;
    margin: 0 20px;
`;

const Preloader = () => {
    return (
        <>
            <Wrapper
                position="relative"
                display={omitMobile('grid')}
                style={byPlatform(
                    {},
                    {
                        gridTemplateColumns: '90px 1fr',
                    }
                )}
                padding={byPlatform('0', '19.5px 25px 0 5px')}
                data-test-id="races"
                width={byPlatform('', '', terminal.homepage.wrapperWidth)}
            >
                {omitMobile(<RaceNavigationLabel>Løb/afd.</RaceNavigationLabel>)}
                <PreloaderButtons
                    count={byPlatform(6, 9)}
                    width={byPlatform('45px', '45px', '60px')}
                    height={'58px'}
                />
            </Wrapper>
            {forMobile(
                <SmallTextPlaceholder
                    style={{
                        background: BACKGROUNDS.lightGrey,
                        width: '80%',
                        margin: '25px auto 40px auto',
                    }}
                />
            )}
            <Wrapper
                margin="0 20px 20px"
                padding="0"
                width={byPlatform('', '', terminal.homepage.wrapperWidthWithMargin)}
            >
                <RaceCardPreloader />
            </Wrapper>
        </>
    );
};

const RaceCardHorseRow = () => (
    <Row>
        <ButtonPlaceholder />
        <TextWithSubHeader style={{ background: 'none' }}>
            <SmallTextPlaceholder
                style={{
                    width: '190px',
                    marginBottom: '10px',
                }}
            />
            <SmallTextPlaceholder style={{ height: '15px', width: '130px' }} />
        </TextWithSubHeader>
        <SmallTextPlaceholder style={{ marginRight: '40px' }} />
    </Row>
);

export const RaceCardPreloader = ({ rows = 7 }) => {
    return (
        <RaceCardPreloaderWrapper>
            <RaceCardHeader>
                <HeaderTextPlaceholder />
            </RaceCardHeader>
            <ButtonsRow>
                <ButtonsBlockTextPlaceholder />
            </ButtonsRow>
            <SubHeader />
            {range(rows).map((n, i) => (
                <RaceCardHorseRow key={n + i} />
            ))}
        </RaceCardPreloaderWrapper>
    );
};

export default Preloader;
