import React, { Component, Fragment } from 'react';
import Button from 'ui/controls/Button';
import { COLORS } from 'themes';
import styled from 'styled-components';
import { ExpandableArrow as ToggleIcon } from 'ui/Arrow';

const borderRadius = 8;
const headHeight = 50;
const spacing = 20;
const arrowSize = 8;

const bottomBorderRadius = (props) => {
    return props.expanded ? 0 : `${borderRadius}px`;
};

const shadow = `0 0 20px #b5b5b5`;

const HeadWrapper = styled.div`
    display: flex;
    cursor: pointer;
`;

const Head = styled.div`
    height: ${headHeight}px;
    color: ${COLORS.primaryText};
    background: ${COLORS.white};
    box-shadow: ${shadow};
    flex-grow: 2;
    display: flex;
    align-items: center;
    padding-left: ${spacing}px;
    z-index: 1;
    box-sizing: border-box;
`;

const StyledHead = styled(Head)`
    border-radius: ${borderRadius}px 0 0 ${bottomBorderRadius};
`;

const ToggleButton = styled(Button)`
    margin: 0;
    border-radius: 0 ${borderRadius}px ${bottomBorderRadius} 0;
    font-size: 18px;
    padding: 0 ${spacing}px;
    box-shadow: ${shadow};
    z-index: 1;
`;

const BodyWrapper = styled.div`
    transition: max-height ${(props) => props.transitionDuration || '500ms'}
            ${(props) => props.transitionFunction || 'ease-in-out'},
        opacity ${(props) => props.transitionDuration || '500ms'}
            ${(props) => props.transitionFunction || 'ease-in-out'};
    min-height: 0;
    height: auto;
    opacity: ${(props) => (props.expanded ? 1 : 0.4)};
    max-height: ${(props) =>
        props.expanded ? props.maxHeight || `9999px` : 0};
    z-index: 2;
    //overflow: hidden;
    overflow: ${(props) => (props.expanded ? 'initial' : 'hidden')};
    display: block;
`;

const StyledBodyWrapper = styled(BodyWrapper)`
    background: ${(props) => props.bg || '#eaeff5'};
    border-radius: 0 0 ${borderRadius}px ${borderRadius}px;
    box-shadow: ${shadow};
`;

const Body = styled.div``;

class Accordion extends Component {
    constructor() {
        super();
        this.state = {
            expanded: false,
        };
    }

    toggle = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    renderDefault() {
        const { head, children, transitionFunction } = this.props;
        const { expanded } = this.state;

        return (
            <Fragment>
                <HeadWrapper onClick={this.toggle}>
                    <StyledHead expanded={expanded}>{head}</StyledHead>
                    <ToggleButton expanded={expanded} primary>
                        <ToggleIcon
                            expanded={expanded}
                            color={COLORS.white}
                            size={arrowSize}
                        />
                    </ToggleButton>
                </HeadWrapper>

                <StyledBodyWrapper
                    expanded={expanded}
                    transitionFunction={transitionFunction}
                    bg={this.props.bg}
                >
                    <Body>{children}</Body>
                </StyledBodyWrapper>
            </Fragment>
        );
    }

    render() {
        const { children } = this.props;
        const { expanded } = this.state;
        return typeof children === 'function' ? (
            <Fragment>{children(this.toggle, expanded)}</Fragment>
        ) : (
            this.renderDefault()
        );
    }
}

export default Accordion;

export { HeadWrapper, Head, ToggleButton, BodyWrapper, Body, ToggleIcon };
