import { SET_DATE } from './actions';

const initialState = {
    date: new Date(),
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DATE:
            return {
                ...state,
                date: action.payload,
            };
        default:
            return {
                ...state,
            };
    }
};

export default reducer;
