import React, { useState } from 'react';

import {
    RaceCardWrapper,
    TableHeader,
    TableHeaderCol,
    TableRow,
    TableCol,
    JackpotRow,
} from './styled';

import { AnyRace, VHorse } from 'features/TrackPage/model/Race';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import useReservesMode from 'features/TrackPage/hooks/useReservesMode';

import PickButtons from '../common/PickButtons';
import { PRODUCT_IDS } from 'configs/products';
import RaceCardHeader from './RaceCardHeader';
import ShortStatistic from './ShortStatistic';
import useModals from 'common/hooks/useModals';
import { thousandsDots } from 'utils/formatters';
import { extractJackpot } from 'features/TrackPage/server/pool';
import PostPositionDelimiter from '../Desktop/PostPositionDelimiter';
import YouthWarning from '../common/YouthWarning';
import { extractRaceShoeInfo } from 'features/TrackPage/server/trackBetInfo';
import { extractShoeInfo } from '../Desktop/WeightOrShoeCol';
import { shouldHideNrCol } from '../utils';
import HorseName from '../common/HorseName';
import TrioPickColumnButtons from '../common/TrioPickColumnButtons';
import KombPickColumnButtons from '../common/KombPickColumnButtons';

const { T, TV, K } = PRODUCT_IDS;

interface RaceCardProps {
    race: AnyRace;
}

const nrColText = { [T]: 'Placering', [TV]: 'Tvilling' };

const RaceCard = ({ race }: RaceCardProps) => {
    const {
        raceDay,
        isVStrictProduct: isVProduct,
        productId,
        product,
        productPool,
        trackNames,
        isGallop,
        isTrot,
        trackBetInfo,
    } = useTrackPage();

    const { reservesMode, toggleReservesMode } = useReservesMode();

    const [statsExpanded, setStatsVisibility] = useState(false);
    const toggleStats = () => setStatsVisibility(!statsExpanded);

    const raceShoeInfo = extractRaceShoeInfo(trackBetInfo, race.raceNumber);

    const { show: showModal } = useModals();

    const jackpot = extractJackpot(productPool);
    const showJackpot = isVProduct && jackpot > 0;

    const hideNrCol = shouldHideNrCol(race, productId);

    const isTrio = productId === T;
    const isKomb = productId === K;
    const productHasSelectColumnFunctionality = isTrio || isKomb;

    if (race.youthRace) {
        return (
            <RaceCardWrapper>
                <YouthWarning race={race} track={{ name: trackNames[0] }} />
            </RaceCardWrapper>
        );
    }

    return (
        <RaceCardWrapper>
            <RaceCardHeader
                raceDay={raceDay}
                race={race}
                reservesMode={reservesMode}
                toggleReservesMode={toggleReservesMode}
                isVProduct={isVProduct}
                statsExpanded={statsExpanded}
                toggleStats={toggleStats}
            />

            {showJackpot && <JackpotRow>Jackpot overført: {thousandsDots(jackpot)} kr.</JackpotRow>}

            <>
                {isTrio && <TrioPickColumnButtons />}
                {isKomb && <KombPickColumnButtons />}

                {/* Table Header */}
                <TableHeader
                    hideNrCol={hideNrCol}
                    isVProduct={isVProduct}
                    productId={productId}
                    productHasSelectColumnFunctionality={productHasSelectColumnFunctionality}
                >
                    <TableHeaderCol align={'center'}>
                        {nrColText[productId] ?? 'Nr.'}
                    </TableHeaderCol>
                    <TableHeaderCol>Hest/Kusk</TableHeaderCol>
                    {isVProduct && <TableHeaderCol>{product.name}%</TableHeaderCol>}
                    <TableHeaderCol>V-Odds</TableHeaderCol>
                </TableHeader>

                {/* Content */}
                {race.horses.map((horse, idx) => {
                    const scratched = horse.vpPool.scratched;
                    const allowed = !scratched;
                    const VPercentage = (horse as VHorse).VPool?.stakeDistributionPercent;
                    const shoeInfoStart = raceShoeInfo.shoeInfoStart.shoeInfoStart[idx];
                    const shoeInfo = extractShoeInfo(shoeInfoStart);

                    return (
                        <>
                            <PostPositionDelimiter
                                isTrot={isTrot}
                                start={horse}
                                starts={race.horses}
                                productId={productId}
                            />
                            <TableRow
                                key={idx}
                                hideNrCol={hideNrCol}
                                isVProduct={isVProduct}
                                productId={productId}
                                data-test-id={horse.vpPool.scratched ? 'scratched' : 'outright'}
                                onClick={() =>
                                    showModal('GAME_STATISTIC', 1, { race, start: horse })
                                }
                            >
                                <TableCol align={'center'} bold>
                                    <PickButtons
                                        horse={horse}
                                        race={race}
                                        reservesMode={reservesMode}
                                    />
                                </TableCol>
                                <TableCol capitalize bold short strike={scratched}>
                                    <HorseName horse={horse} hideNrCol={hideNrCol} />
                                </TableCol>
                                {isVProduct && (
                                    <TableCol bold>
                                        {allowed && VPercentage ? VPercentage + '%' : '-'}
                                    </TableCol>
                                )}
                                <TableCol bold>
                                    {allowed ? horse.vpPool.vinnarOdds.odds : '-'}
                                </TableCol>
                            </TableRow>

                            {statsExpanded && (
                                <ShortStatistic
                                    start={horse}
                                    gallop={isGallop}
                                    shoeInfo={shoeInfo}
                                />
                            )}
                        </>
                    );
                })}
            </>
        </RaceCardWrapper>
    );
};

export default RaceCard;
