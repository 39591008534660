import React from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface ProductTipsVisibilityStore {
    productTipsVisibility: boolean;
    setProductTipsVisibility(tipsAppearance: boolean): void;
}

const useProductTipsVisibility = create<ProductTipsVisibilityStore>()(
    persist(
        set => ({
            productTipsVisibility: true,
            setProductTipsVisibility: productTipsVisibility => set({ productTipsVisibility }),
        }),
        { name: 'ui.productTipsVisibility' }
    )
);

export const withProductTipsVisibilityStore = (BaseComponent: any) => (props: any) => {
    const store = useProductTipsVisibility();
    return <BaseComponent {...props} {...store} />;
};

export default useProductTipsVisibility;
