import styled from 'styled-components';
import { BACKGROUNDS, COLORS } from 'themes';
import { byPlatform } from 'utils/platforms';

export const NotifierWrapper = styled.div`
    min-width: 150px;
    height: 50px;
    border-radius: 10px;
    background: ${BACKGROUNDS.primaryFlat};
    z-index: 6;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-flow: column;
    color: ${COLORS.white};
    margin-right: 10px;
`;

export const NotifierCounter = styled.span`
    display: inline-block;
    background: ${COLORS.white};
    color: ${byPlatform(COLORS.secondary, '#34b978')};
    text-align: center;
    width: 30px;
    height: 30px;
    line-height: 30px;
    border-radius: 3px;
    font-weight: 900;
    font-size: ${({ combinationsCount }) => {
        if (combinationsCount > 99 && combinationsCount < 1000) {
            return '14px';
        } else if (combinationsCount > 999 && combinationsCount < 9999) {
            return '12px';
        } else if (combinationsCount > 9999 && combinationsCount < 99999) {
            return '10px';
        } else if (combinationsCount > 99999) {
            return '7px';
        }
        return '18px';
    }};
`;
