import React from 'react';
import styled from 'styled-components';
import { FadedBackground } from 'features/SpilID/styled';
import { ReactComponent as CloseSvg } from 'icons/close.2.svg';

const TooltipPopupWrapper = styled.div`
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    left: 0;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background: #fff;
    padding: 25px;
    box-sizing: border-box;
    z-index: 6;
    transform: ${({ shown, topIndent }) =>
        `translateY(${shown ? topIndent : '100%'})`};
    transition: 400ms ease transform;
`;

const TooltipPopupContent = styled.div`
    position: static;
`;

const CloseIcon = styled(CloseSvg)`
    position: absolute;
    left: 25px;
    top: 25px;
    & path {
        fill: #c6ccdb;
    }
`;

const TooltipPopup = ({
    shown,
    hide,
    children = '',
    topIndent = '0',
    styles = {},
}) => {
    return (
        <>
            <FadedBackground shown={shown} opacity={0.7} />
            <TooltipPopupWrapper
                shown={shown}
                topIndent={topIndent}
                style={styles || {}}
            >
                <CloseIcon onClick={hide} />
                <TooltipPopupContent>{children}</TooltipPopupContent>
            </TooltipPopupWrapper>
        </>
    );
};

export default TooltipPopup;
