import RacingCard from 'common/DataObjects/RacingCard';
import productSelector from 'common/selectors/productSelector';
import raceDaySelector, { getRaceDay } from 'common/selectors/raceDaySelector';
import { getTrack } from 'common/selectors/trackSelector';
import Leg from 'common/DataObjects/Leg';
import moment from 'moment';
import { POOL_OBJECT_KEYS, PRODUCT_IDS } from 'configs/products';
import Starts from 'common/DataObjects/Starts';
import { isMultitrackProduct } from 'common/selectors/multipleTrackSetupsSelector';
import get from 'lodash/get';
import { hasResultByRaceNr } from 'features/EventBoard/server/calendar';

const getRaceIndex = state => state.AISDataProvider.raceIndex;

const getRace = state => {
    return racesSelector(state)[getRaceIndex(state)];
};

const getRaceByIndex = (state, raceIndex) => {
    return racesSelector(state)[raceIndex];
};

const getFirstRace = state => {
    return racesSelector(state)[0];
};

const getDefaultRace = state => {
    const product = productSelector(state);
    if (product.isVProduct()) {
        return getFirstRace(state);
    }
    const races = racesSelector(state);

    const firstOngoing = races.find(r => r.saleOpen === true);

    return firstOngoing || getFirstRace(state);
};

export const getRacingCardDTO = state => RacingCard.fill(state.AISDataProvider);

const racesSelector = (state, raceDay, product) => {
    const racingCard = getRacingCardDTO(state);
    let races = racingCard.getRacesWithIndexes();
    product = product || productSelector(state);
    raceDay = raceDay || raceDaySelector(state);
    // races = filterMissingRaces(races, product, raceDay);

    if (product.isMultitrack) {
        if (!state.AISDataProvider.raceDayFetched) {
            // raceDay.multipleTrackSetups is not available yet
            return [];
        }
        if (!raceDay.multipleTrackSetups[product.id]) {
            return [];
        }
        return raceDay.multipleTrackSetups[product.id].legs.map((leg, idx) =>
            getMultitrackRace(leg, state, races[idx])
        );
    }
    return races;
};

export const getLegNumbers = state => {
    return racesSelector(state).map(r => (isMultitrackProduct(state) ? r.legNr : r.raceNumber));
};

export const racesFetchedSelector = state => state.AISDataProvider.racingCardFetched;

export const racesPendingSelector = state => {
    return state.AISDataProvider.racingCardPending;
};

export const racesNotEmpty = state => !getRacingCardDTO(state).isNull();

export const allRacesSalesClosed = state => {
    return getRacesOpenForSale(state).length === 0;
};

const getRacesOpenForSale = state => {
    return racesSelector(state).filter(race => race.saleOpen);
};

const getRacesClosedForSale = state => {
    return racesSelector(state).filter(race => !race.saleOpen);
};

const getDisabledRaceNumbers = state => {
    const product = state.AISDataProvider.selectedProduct;
    const trackPool = state.AISDataProvider.trackPool;
    const disabledRaces = [];
    // raceNumber = 0 is a trio case (Back-End BUG)
    // VP checking is needed since it's not in the pool information, so we check Vinder
    let productId =
        product.id === PRODUCT_IDS.T || product.id === PRODUCT_IDS.VP ? PRODUCT_IDS.V : product.id;

    if (trackPool && trackPool[productId] && trackPool[productId][POOL_OBJECT_KEYS[productId]]) {
        trackPool[productId][POOL_OBJECT_KEYS[productId]].forEach(item => {
            // @TODO raceNumber = 0 is a trio case, remove when fixed on backend
            if (item.raceNumber !== 0 && !item.saleOpen) {
                disabledRaces.push(item.raceNumber);
            }
        });
    }

    return disabledRaces;
};

/**
 * @param  {Object} leg
 * @param  {Object} state
 * @return {Leg}
 */
const getMultitrackRace = (leg, state, race) => {
    if (!race) {
        return leg;
    }

    if (productSelector(state).id !== PRODUCT_IDS.DD) {
        // DD doesn't have multileg pool data in fetchPoolInformation
        // thus we need this check. Starts.js contains code responsible
        // for scratched data for DD multitrack
        race.starts = race.starts.map(start => {
            return new Starts()
                .fill(start)
                .addVPercentage(
                    state.AISDataProvider.trackPool,
                    state.AISDataProvider.selectedProduct.id,
                    leg.legNr - 1
                )
                .addScratched(
                    state.AISDataProvider.trackPool,
                    state.AISDataProvider.selectedProduct.id,
                    leg.legNr - 1
                );
        });
    }

    return new Leg({
        ...race,
        trackId: leg.trackId,
        legNr: leg.legNr,
    });
};

const getOngoingRace = state => {
    const races = racesSelector(state);
    return races.find(r => {
        return moment.utc(r.postTimeUTC, 'HH:mm:ss').isBefore(moment.utc()) && r.saleOpen;
    });
};

const getForecastRace = state => {
    const races = racesSelector(state);
    return RacingCard.getForecastRace(races);
};

const getFirstUpcomingRace = state => {
    const races = racesSelector(state);
    return races.find(r => {
        return moment.utc(r.postTimeUTC, 'HH:mm:ss').isAfter(moment.utc());
    });
};

const getFirstRaceOpenForSale = state => {
    return racesSelector(state).find(r => r.saleOpen);
};

/**
 * @param  {Object} state
 * @return {number}
 */
const getLastRaceNumber = state => {
    const races = racesSelector(state);
    if (!races.length) {
        return 0;
    }
    return races[races.length - 1].raceNumber;
};

/**
 * Displayed track in context of multi-track setup means
 * the track for currently displayed / selected race in UI
 * This is a predicate function can be used eg in Array.filter, Array.find etc
 * @param state
 * @return {Function.<string>}
 */
const byDisplayedTrack = state => {
    if (!isMultitrackProduct(state)) {
        return () => true;
    }
    const product = productSelector(state);
    const raceDay = raceDaySelector(state);
    const track = getTrack(state);
    const legs = raceDay.multipleTrackSetups[product.id].legs;

    return raceIndex => {
        return legs[raceIndex].trackId === track.code;
    };
};

const isAnyRaceStarted = state => {
    const races = racesSelector(state);
    return races.some(r => r.saleOpen === false);
};

const getHorseCountsInRaces = state => {
    const races = racesSelector(state);
    return races.map(race => get(race, 'starts.length', 0));
};

const isCurrentRaceResulted = state => {
    const raceDay = getRaceDay(state);
    const race = getRace(state);
    return hasResultByRaceNr(raceDay, race?.raceNumber || 0);
};

export default racesSelector;

export {
    getRaceIndex,
    getRace,
    getRaceByIndex,
    getFirstRace,
    getOngoingRace,
    getForecastRace,
    getDefaultRace,
    getFirstUpcomingRace,
    getRacesOpenForSale,
    getRacesClosedForSale,
    getDisabledRaceNumbers,
    getLastRaceNumber,
    getFirstRaceOpenForSale,
    byDisplayedTrack,
    isAnyRaceStarted,
    getHorseCountsInRaces,
    isCurrentRaceResulted,
};
