import React, { Fragment, lazy, Suspense, useEffect, useRef } from 'react';
import Screens from './Screens';
import useScreens from '../hooks/useScreens';
import Spinner from 'ui/Spinner';
import { SCREENS } from '../config';
import { useDispatch } from 'react-redux';
import useAuth from 'common/hooks/useAuth';
import { BetBuddyEntryProps } from './index';
import useJoinGroup from 'common/hooks/useJoinGroup';
import usePrevious from 'common/hooks/usePrevious';
import { SET_BET_BUDDY_SELECTIONS_MODE } from '../../BetSlip/state/actions';
import { betBuddySelectionsModeInitialState } from '../../BetSlip/state/reducer';
import { setUIProps } from '../actions';
import useSharedLink from '../hooks/useSharedLink';

const GroupScreen = lazy(() => import(`./GroupScreen`));

const EntryPage = ({ sharedLink }: BetBuddyEntryProps) => {
    const [displayScreen, closeScreen, screenShown, screens, closeAll] = useScreens([]);

    const dispatch = useDispatch();
    const auth = useAuth();
    const userId = auth.user ? auth.user.id : 0;
    const prevUser = usePrevious(auth.user) || { id: 0 };
    const prevUserId = prevUser && prevUser.id ? prevUser.id : 0;

    useSharedLink(sharedLink ? sharedLink : false);

    useJoinGroup({ userId, prevUserId, sharedLink, displayScreen });

    useEffect(() => {
        !sharedLink &&
            dispatch({
                type: SET_BET_BUDDY_SELECTIONS_MODE,
                payload: betBuddySelectionsModeInitialState.betBuddySelectionsMode,
            });
        dispatch(setUIProps({ isPersonalGroup: true }));
    }, []);

    return (
        <Fragment>
            <Suspense fallback={<Spinner />}>
                <GroupScreen
                    close={() => {
                        displayScreen(SCREENS.GROUPS);
                    }}
                    showPoolDetails={() => {
                        displayScreen(SCREENS.POOL_DASHBOARD);
                    }}
                />
            </Suspense>
            <Screens
                screens={screens}
                displayScreen={displayScreen}
                closeScreen={closeScreen}
                closeAll={closeAll}
                screenShown={screenShown}
            />
        </Fragment>
    );
};

export default EntryPage;
