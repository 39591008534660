import Icon from 'ui/Icon';
import styled from 'styled-components';

const CloseBtn = styled(Icon)`
    position: absolute;
    z-index: ${(props) => props.zIndex || 99999};
    top: 16px;
    left: 16px;
    cursor: pointer;
`;

export default CloseBtn;
