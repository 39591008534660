import React from 'react';
import styled from 'styled-components';

import DraggablePopup from 'ui/DraggablePopup';
import Button from 'ui/controls/Button';
import Text, { Strong } from 'ui/Text';
import { BACKGROUNDS } from 'themes';
import successIcon from 'images/success.svg';
import Wrapper from 'ui/Wrapper';

import { closeLastPopup } from 'utils/navigation';
import { formatMoney } from 'utils/formatters';

const SuccessIcon = styled.img`
    width: 80px;
    height: 80px;
    margin: 0 auto 30px;
`;

const Delimiter = styled.div`
    border-bottom: 1px solid #e0e0e0;
    width: 100%;
`;

const PayoutSuccessPopup = ({ winAmount }) => {
    const hideSelf = () => {
        closeLastPopup('RETAIL_PAYOUT_SUCCESS');
    };

    return (
        <DraggablePopup
            padding="0"
            height={'370px'}
            closeHandler={hideSelf}
            withoutDragIndicator
            radius="12px"
            background={BACKGROUNDS.lightGrey}
            containerStyles={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            {({ close }) => (
                <>
                    <Wrapper className="flex flex-col" padding="40px 34px 0 34px">
                        <SuccessIcon src={successIcon} alt={'success'} />

                        <Text size="x1.5" center bold>
                            Gevinst overført
                        </Text>

                        <Text>
                            Din gevinst på <Strong>{formatMoney(winAmount)} kr.</Strong> er blevet
                            overført til din online spillekonto.
                        </Text>
                    </Wrapper>

                    <Delimiter />

                    <Wrapper padding="0 34px 24px 34px" className="flex">
                        <Button
                            accentAlt
                            size="x2"
                            textsize="14px"
                            bold
                            onClick={close}
                            className="flex-grow"
                        >
                            OK
                        </Button>
                    </Wrapper>
                </>
            )}
        </DraggablePopup>
    );
};

export default PayoutSuccessPopup;
