import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Slick from 'react-slick';
import styled, { css } from 'styled-components';
import { boxShadowMixin } from 'ui/Card';
import Icon from 'ui/Icon';
import { localStor } from 'common/storage';
import arrowIcon from 'layouts/sections/Highlights/assets/arrow-thick.svg';
import ReactTooltip from 'react-tooltip';
import getTexts from 'utils/localization';

const t = getTexts();

const backDropStyle = css`
    background: rgba(0, 0, 0, 0.45);
    backdrop-filter: blur(30px);
`;

const HighlightTooltip = styled(ReactTooltip)`
    font-weight: bold;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 14px;
    color: #505050;
`;

const Slider = styled(Slick)`
    cursor: pointer;
    ${boxShadowMixin};

    .slick-list {
        height: auto;
    }

    .slick-dots {
        width: auto;
        margin: 0 auto;
        padding: 8px 15px;
        bottom: 10px;
        top: auto;
        height: auto;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 8px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 10px;
    }

    .slick-dots li {
        width: 8px;
        height: auto;
        margin: 0;
    }

    .slick-dots li > div {
        opacity: 0.65;
        transition: width 0.3s ease;
    }

    .slick-dots li.slick-active {
        width: 28px;

        & > div {
            width: 28px;
        }
    }

    .slick-active > div {
        opacity: 1 !important;
    }
`;

const Slide = styled.div`
    //min-height: 240px;
    position: relative;
    display: flex;
    justify-content: center;
`;

const BottomSubText = styled.div`
    background-color: #333333;
    display: flex;
    align-items: center;
    padding: 15px;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    justify-content: center;

    color: #ffffff;
    font-family: 'Roboto Condensed';

    > * {
        margin: 0;
        font-family: Roboto Condensed;
        font-size: 10px;
        text-align: center;
    }
`;

const IconArea = styled.span`
    ${backDropStyle};
    transition: all 300ms ease;
    position: absolute;
    padding: 8px;
    border-radius: 5px;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 199;

    &:hover {
        background: rgba(1, 1, 1, 0.2);
    }
`;

const Img = styled.img`
    width: 100%;
    height: 100%;
    ${({ mobile, isLandscapeImage }) =>
        mobile &&
        !isLandscapeImage &&
        css`
            min-height: calc(100vw / ${MOBILE_ASPECT_RATIO});
            object-fit: cover;
        `};
`;

const Dot = styled.div`
    background: #fff;
    height: 8px;
    width: 8px;
    border-radius: 8px;
    margin: 0;
`;

const NextArrowWrapper = styled.div`
    ${backDropStyle};
    display: ${({ disabled }) => (disabled ? 'none' : 'flex')};
    justify-content: center;
    align-content: center;
    flex-wrap: wrap;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    position: absolute;
    z-index: 2;

    transition: background-color 0.3s ease;

    ${({ right }) =>
        right
            ? css`
                  right: 10px;
                  transform: translateY(-50%) rotateZ(180deg);
                  top: calc(50% - 5px);
              `
            : css`
                  left: 10px;
                  transform: translateY(-50%);
                  top: 50%;
              `};

    &:hover {
        background: rgba(1, 1, 1, 0.2);
    }
`;

const NextArrow = props => {
    const { onClick, right, key, disabled } = props;
    return (
        <NextArrowWrapper
            key={key}
            onClick={onClick}
            right={right}
            data-tip={right ? t.next : 'Forrige'}
            data-place={right ? 'left' : 'right'}
            data-type={'light'}
            data-effect={'solid'}
            data-for={'highlight-tooltip'}
            disabled={disabled}
        >
            <Icon
                color="white"
                src={arrowIcon}
                size="x05"
                style={{
                    width: '20px',
                    height: '20px',
                }}
            />
        </NextArrowWrapper>
    );
};

const MOBILE_ASPECT_RATIO = 7 / 4;

const Highlights = ({ data, mobile = false }) => {
    const [initialized, setInitialized] = useState(false);
    const [activeSlide, setActiveSlide] = useState(0);

    const filteredHighlights = useMemo(() => data.filter(item => item.image), [data]);

    const latestId = filteredHighlights[0]?.id;
    const closedHighlightId = Number(localStor.getLS('highlightClosed'));
    const [closed, setClosed] = useState(
        latestId && closedHighlightId && latestId === closedHighlightId
    );

    useEffect(() => {
        if (!initialized) {
            setInitialized(true);
        }
    }, [initialized]);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true,
        // autoplay: true,
        // autoplaySpeed: 5000,
        customPaging: i => <Dot />,
        prevArrow: mobile ? null : (
            <NextArrow key={'prev'} disabled={filteredHighlights?.length === 1} />
        ),
        nextArrow: mobile ? null : (
            <NextArrow key={'next'} right disabled={filteredHighlights?.length === 1} />
        ),
        beforeChange: (current, next) => setActiveSlide(next),
    };

    if (closed) {
        return null;
    }

    return (
        <Fragment>
            <div>
                <Slider {...settings}>
                    {filteredHighlights.map(({ id, image, landscapeImage, clickHandler }) => (
                        <React.Fragment key={id}>
                            <Slide onClick={clickHandler}>
                                {!mobile && (
                                    <IconArea>
                                        <Icon
                                            color="white"
                                            iclose
                                            size="x05"
                                            style={{
                                                width: '10px',
                                                height: '10px',
                                            }}
                                            onClick={e => {
                                                e.stopPropagation();
                                                if (latestId) {
                                                    setClosed(true);
                                                    localStor.saveLS({
                                                        highlightClosed: latestId,
                                                    });
                                                }
                                            }}
                                        />
                                    </IconArea>
                                )}
                                <Img
                                    mobile={mobile}
                                    isLandscapeImage={!!landscapeImage}
                                    src={mobile ? landscapeImage || image : image}
                                />
                            </Slide>
                        </React.Fragment>
                    ))}
                </Slider>
                {filteredHighlights[activeSlide].terms ? (
                    <BottomSubText
                        dangerouslySetInnerHTML={{
                            __html: filteredHighlights[activeSlide].terms,
                        }}
                    />
                ) : null}
                <HighlightTooltip id={'highlight-tooltip'} />
            </div>
        </Fragment>
    );
};

export default Highlights;
