import React, { Fragment } from 'react';
import { RaceSummaryLine } from 'features/BetSlip2/components/Receipt/styled';
import moment from 'moment';

import getTexts from 'utils/localization';
const t = getTexts();

const RaceSummary = (props) => {
    const { product, combinedTrackName, date, totalCost } = props;

    const startDate = moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY');

    return (
        <Fragment>
            <RaceSummaryLine className="text-bold">
                {combinedTrackName}: {product.name}
            </RaceSummaryLine>
            {/* @TODO Add time */}
            <RaceSummaryLine>Start: {startDate}</RaceSummaryLine>
            <RaceSummaryLine>
                {t.betSlip.sum}: {(totalCost / 100).toFixed(2)} {t.currency}
            </RaceSummaryLine>
        </Fragment>
    );
};

export default RaceSummary;
