import { useQuery } from '@tanstack/react-query';
import noop from 'lodash/noop';

import { DOMAIN_PATH } from 'configs/main';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import { request } from 'utils/server';

declare type PDFDocumentType = 'play-list' | 'channel-overview' | 'tips';

interface Parameters {
    type: PDFDocumentType;
}

interface ResponseData {
    time: string;
}

interface RequestParams {
    trackCode: string;
    date: string;
    test: boolean;
    type: PDFDocumentType;
}

const checkPDFAvailability = async ({ trackCode, date, test, type }: RequestParams) =>
    await request<ResponseData, RequestParams>('CMS', 'GET', '/derby/rest/common/download-pdf', {
        trackCode,
        date,
        test,
        type,
    });

const usePdfDownload = ({ type }: Parameters) => {
    const { raceDay, calendarFetched } = useTrackPage();

    const { data, isSuccess } = useQuery({
        queryKey: ['pdfDownload', type],
        queryFn: () =>
            checkPDFAvailability({
                trackCode: raceDay.track.code,
                date: raceDay.raceDayDate,
                test: true,
                type,
            }),
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
        enabled: calendarFetched,
    });

    const fileAvailable = isSuccess && data?.success;

    const downloadPdf = () => {
        window.open(
            `${DOMAIN_PATH}/derby/rest/common/download-pdf?type=${type}&date=${raceDay.raceDayDate}&trackCode=${raceDay.track.code}`,
            '_self'
        );
    };

    return { fileAvailable, downloadPdf: fileAvailable ? downloadPdf : noop };
};

export default usePdfDownload;
