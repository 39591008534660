import { useSelector, useDispatch } from 'react-redux';
import { getUnwrappedModalStatuses } from '../selectors/uiSelector';
import { showUnwrappedModal, hideUnwrappedModal } from '../actions/uiActions';

const useUnwrappedModals = () => {
    const dispatch = useDispatch();
    const modals = useSelector(getUnwrappedModalStatuses);

    const isShown = (modalId: string) =>
        modals[modalId] && modals[modalId].visible;
    const show = (modalId: string, data?: Object) =>
        dispatch(showUnwrappedModal(modalId, data));
    const hide = (modalId: string) => dispatch(hideUnwrappedModal(modalId));
    const getData = (modalId: string) =>
        modals[modalId] ? modals[modalId].data : {};

    return { modals, isShown, show, hide, getData };
};

export default useUnwrappedModals;
