import {
    CHECK_IN,
    CHECK_OUT,
    FETCH_ERROR,
    FETCH_PENDING,
    HIDE_SCAN_MODAL,
    STATUS_CHECK,
    SYSTEM_STATUS_CHECK,
    UNPAID_SALE_CHECK,
    CLEAN_ERROR_MESSAGE,
    CHECKIN_PENDING,
} from './actions';
import { MODAL_TYPES } from './contstants';

const initialState = {
    checkInPending: false,
    modalVisible: false,
    type: '',
    data: {},
    error: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CLEAN_ERROR_MESSAGE:
            return {
                ...state,
                error: null,
            };
        case CHECK_IN:
            return {
                ...state,
                modalVisible: true,
                type: MODAL_TYPES.CHECK_IN,
            };
        case CHECK_OUT:
            return {
                ...state,
                modalVisible: true,
                type: MODAL_TYPES.CHECK_OUT,
                checkInPending: true,
            };
        case STATUS_CHECK:
            return {
                ...state,
                modalVisible: true,
                type: MODAL_TYPES.STATUS,
                data: action.payload,
            };
        case SYSTEM_STATUS_CHECK:
            return {
                ...state,
                modalVisible: true,
                type: MODAL_TYPES.SYSTEM_STATUS,
                data: action.payload,
            };
        case UNPAID_SALE_CHECK:
            return {
                ...state,
                modalVisible: true,
                type: MODAL_TYPES.UNPAID_SALE,
                data: action.payload,
            };
        case FETCH_PENDING:
            return {
                ...state,
                modalVisible: true,
                type: MODAL_TYPES.PENDING,
            };
        case FETCH_ERROR:
            return {
                ...state,
                modalVisible: true,
                type: MODAL_TYPES.ERROR,
            };
        case HIDE_SCAN_MODAL:
            const { error, checkInPending } = action.payload;

            const newState = {
                ...state,
                modalVisible: false,
                type: '',
                error: error ? error : {},
            };

            if (action.payload.hasOwnProperty('checkInPending')) {
                newState.checkInPending = checkInPending;
            }

            return newState;
        case CHECKIN_PENDING:
            return {
                ...state,
                checkInPending: action.payload,
            };
        default:
            return { ...state };
    }
};
