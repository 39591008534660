import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { BACKGROUNDS } from 'themes';
import homeIcon from 'icons/bottom-menu/home.svg';
import LiveCasinoIcon from 'icons/bottom-menu/live-casino.svg';
import GemteKuponerIcon from 'icons/bottom-menu/qr-code.svg';
import QuestionIcon from 'icons/bottom-menu/riddler.svg';
import DerbyLynIcon from 'images/icons/desktop-sidebar/derby-lyn.svg';
import EkspertSpilIcon from 'images/icons/desktop-sidebar/ekspertspil.svg';
import MineSpilIcon from 'images/icons/desktop-sidebar/minespil.svg';
import ResultsIcon from 'images/icons/desktop-sidebar/results.svg';
import SpilKlubIcon from 'images/icons/desktop-sidebar/spilklub.svg';
import ReplaysIcon from 'images/icons/desktop-sidebar/replays.svg';
import SpilIdIcon from 'features/SpilID/assets/customer.svg';
import LiveStreamIcon from 'images/icons/desktop-sidebar/player.svg';
import useAuth from 'common/hooks/useAuth';
import useModals from 'common/hooks/useModals.ts';
import { isLiveStreamAllowed } from 'common/selectors/auth';
import { history } from 'utils/navigation';
import getTexts from 'utils/localization';
import NotificationConductor from 'common/conductors/NotificationConductor';
const t = getTexts();

const SavedCouponsItem = styled.span`
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const CounterContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background-color: #f4393c;
    margin-left: 10px;
`;

const Counter = styled.span`
    margin: 0;
    font-size: 14px;
    font-weight: bold;
    text-align-center;
`;

export default initialMenuPosition => {
    const uniqItems = ['/25-nyt']; // important for highlight "More" item
    const auth = useAuth();
    const { show } = useModals();
    const dispatch = useDispatch();

    const liveStreamAllowed = useSelector(isLiveStreamAllowed);
    const liveStreamHandler = () => {
        if (auth?.user && liveStreamAllowed) {
            dispatch({ type: 'SHOW_STREAM' });
        }
        if (auth?.user && !liveStreamAllowed) {
            NotificationConductor.warning(t.Live.restrictionMessage, '', 5000);
        }
        if (!auth?.user) {
            show('LOGIN');
        }
    };

    const myCouponsHandler = () => {
        show('SAVED_BET_LIST', 102);
    };
    const liveStreamShown = useSelector(({ StreamVideo }) => StreamVideo.isFloatingStreamShown);
    const mobileBetsQuantity = useSelector(state => state.MobileBet.count);

    const activeItem = window.location.pathname;

    const isActiveItem = pathname => {
        return activeItem === pathname && !liveStreamShown;
    };

    return {
        backgroundColor: BACKGROUNDS.navigation,
        initialMenuPosition,
        initiallyFullTop: true,
        menuItems: [
            {
                title: 'Hjem',
                icon: homeIcon,
                action: () => history.push('/'),
                active: isActiveItem('/'),
            },
            {
                title: 'Mine spil',
                icon: MineSpilIcon,
                action: () => history.push('/mine-spil'),
                active: isActiveItem('/mine-spil'),
            },
            {
                title: 'Live stream',
                icon: LiveStreamIcon,
                action: liveStreamHandler,
                active: liveStreamShown,
            },
            {
                title: 'Resultater',
                icon: ResultsIcon,
                action: () => history.push('/resultat'),
                active: isActiveItem('/resultat'),
            },
        ],
        dropDownMenuItems: {
            campaignAction: () => history.push('/25-nyt'),
            intercomAction: () => window.Intercom('show'),
            uniqItemsActive: uniqItems.some(item => activeItem === item),
            menuItems: [
                // [
                //     {
                //         logo: SpilIDIcon,
                //         title: 'Mit spil ID',
                //         action: () => {},
                //     },
                // ],
                [
                    {
                        logo: SpilKlubIcon,
                        title: 'Spilklub25',
                        action: () => history.push('/spil-klub25'),
                    },
                    {
                        logo: DerbyLynIcon,
                        title: 'Lynspil',
                        action: () => history.push('/derby-lyn'),
                    },
                    {
                        logo: EkspertSpilIcon,
                        title: 'Ekspertspil',
                        action: () => history.push('/spilklub25-køb-andele-til-eksperternes'),
                    },
                    {
                        logo: LiveCasinoIcon,
                        title: 'Live casino',
                        action: () => history.push('/live-casino'),
                    },
                ],
                [
                    {
                        logo: SpilIdIcon,
                        title: 'Spil ID',
                        action: () => show('SPIL_ID'),
                    },
                ],
                [
                    {
                        logo: ReplaysIcon,
                        title: 'Replays',
                        action: () => history.push('/replays'),
                    },
                    {
                        logo: GemteKuponerIcon,
                        title: (
                            <SavedCouponsItem>
                                {t.sidebar.saved_coupons}
                                {mobileBetsQuantity > 0 && (
                                    <CounterContainer>
                                        <Counter>{mobileBetsQuantity}</Counter>
                                    </CounterContainer>
                                )}
                            </SavedCouponsItem>
                        ),
                        action: myCouponsHandler,
                    },
                ],
                [
                    {
                        logo: QuestionIcon,
                        title: 'Sådan spiller du',
                        action: () => window.open('https://bet25.dk/heste/spilguide', '_blank'),
                    },
                    {
                        logo: QuestionIcon,
                        title: 'Spilklub guide',
                        action: () => history.push('/25-nyt/saadan-fungerer-spilklub25'),
                    },
                ],
            ],
        },
    };
};
