import CMSAPI from 'common/api/CMSAPI';

enum Link {
    list = 'LIST',
    details = 'DETAILS',
}

export interface CMSResponse<T> {
    success: boolean;
    msg: string;
    data: T;
}

interface ExpertsDataShape<T> {
    id: number;
    banner: string;
    link: Link;
    content: T;
}

export interface HowSpilklubWorksPage {
    section1: HowSpilklubWorksSection;
    section2: HowSpilklubWorksSection;
    section3: HowSpilklubWorksSection;
}

interface HowSpilklubWorksSection {
    title: string;
    text: string;
    buttonText: string;
    buttonLink: string;
    videoLink: string;
}

export interface Expert {
    id: number;
    photo: string;
    name: string;
    description: string;
    groups: Group[];
}

export interface Group {
    id: number;
    image: string;
    name: string;
    info: string;
}

export const getExperts = (): Promise<CMSResponse<ExpertsDataShape<Expert[]>>> =>
    CMSAPI.getExperts({ link: 'list' });

export const getFAQContent = (): Promise<CMSResponse<ExpertsDataShape<HowSpilklubWorksPage>>> =>
    CMSAPI.getExperts({ link: 'details' });
