import Starts from 'common/DataObjects/Starts';
import { POOL_OBJECT_KEYS, PRODUCT_IDS } from 'configs/products';
import Product from 'common/DataObjects/Product';

export default class Race {
    cancelled = false;
    coupledHorsesInPool = false;
    distance = null;
    entriesReleased = false;
    entryDate = null;
    postTime = null;
    postTimeUTC = null;
    raceNumber = null;
    reservOrder = null;
    resultReleased = false;
    swedish = false;
    starts = [];
    trotRaceInfo = null;
    gallopRaceInfo = null;
    trackCode = 0;
    saleOpen = false;
    poolClosed = false;
    youthRace = false;
    index = 0;

    constructor(attributes) {
        return Object.assign(this, attributes);
    }

    static unserialize(
        data,
        trackPool,
        productId,
        raceIndex,
        trackCode,
        hostTrack
    ) {
        const race = new Race();

        try {
            race.cancelled = data.cancelled;
            race.coupledHorsesInPool = data.coupledHorsesInPool;
            race.distance = data.distance;
            race.entriesReleased = data.entriesReleased;
            race.entryDate = data.entryDate;
            race.postTime = data.postTime;
            race.postTimeUTC = data.postTimeUTC;
            race.raceNumber = data.raceNumber;
            race.reservOrder = data.reservOrder;
            race.resultReleased = data.resultReleased;
            race.swedish = data.swedish;
            race.trotRaceInfo = data.trotRaceInfo;
            race.gallopRaceInfo = data.gallopRaceInfo;
            race.trackCode = trackCode;
            race.index = raceIndex;
            race.youthRace = data.youthRace;
            race.starts = data.starts.starts.map((item) =>
                Starts.unserialize(
                    item,
                    trackPool,
                    race.raceNumber,
                    raceIndex,
                    productId,
                    hostTrack
                )
            );
            race.hostTrack = hostTrack;

            if (Product.IsVProduct(productId)) {
                race.saleOpen = trackPool[productId].saleOpen;
                race.poolClosed = trackPool[productId].poolClosed;
            } else {
                const raceNumberProp =
                    productId === PRODUCT_IDS.T ? 'raceNr' : 'raceNumber';

                if (
                    trackPool[productId] &&
                    trackPool[productId][POOL_OBJECT_KEYS[productId]]
                ) {
                    const racePoolData = trackPool[productId][
                        POOL_OBJECT_KEYS[productId]
                    ].find((item) => item[raceNumberProp] === race.raceNumber);

                    if (racePoolData) {
                        race.saleOpen = racePoolData.saleOpen;
                        race.poolClosed = racePoolData.poolClosed;
                    }
                }
            }
        } catch (error) {
            //@TODO Logger of errors
            console.log(error);
        }

        return race;
    }
}
