import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { isMobile } from 'utils/platforms';

import { fetchServerTime } from 'features/AISDataProvider/actions';
import FlexWrapper from 'ui/FlexWrapper';
import Headline from 'ui/Headline';
import QuickPickCard, { Preloader } from 'features/QuickPick/QuickPickCard';
import { PRODUCT_COLORS, PRODUCT_IDS } from 'configs/products';
import AISREST from 'features/AISDataProvider/AISREST';
import QuickPickDO from 'common/DataObjects/QuickPick';
import NotificationConductor from 'common/conductors/NotificationConductor';
import { byPlatform } from 'utils/platforms';

import iGS75 from './assets/atg-style/GS75.png';
import iV4 from './assets/atg-style/V4-5.png';
import iV5 from './assets/atg-style/V4-5.png';
import iV64 from './assets/atg-style/V64.png';
import iV65 from './assets/atg-style/V65.png';
import iV75 from './assets/atg-style/V75.png';
import iV86 from './assets/atg-style/V86.png';

import getTexts from 'utils/localization';
import FadeIn from 'ui/animations/FadeIn';
const t = getTexts();

const PRODUCT_BACKGROUNDS = {
    GS75: iGS75,
    V4: iV4,
    V5: iV5,
    V64: iV64,
    V65: iV65,
    V75: iV75,
    V86: iV86,
};

const _DATE_FORMAT = 'YYYY-MM-DD';

const flexWrapperProps = {
    direction: 'row',
    wrap: 'wrap',
    align: 'left',
    padding: '0',
    style: {
        gap: isMobile ? '10px' : '20px',
    },
};

class QuickPick extends React.Component {
    constructor() {
        super();

        this.state = {
            data: null,
        };
    }

    componentDidMount() {
        this.getData();
    }

    getData = () => {
        this.props.fetchServerTime();

        AISREST.fetchAllPoolInformation()
            .then(res => {
                if (res.success) {
                    setTimeout(() => {
                        this.setState({
                            data: res.data.map(item => QuickPickDO.unserialize(item)),
                        });
                    }, 200);
                }
            })
            .catch(error => {
                NotificationConductor.error(error.toString());
            });
    };

    sortData = data => {
        let sortedData = data ? data.sort((a, b) => (a.date >= b.date ? 1 : -1)) : null;

        return sortedData;
    };

    getGroupedData = () => {
        let popular = [],
            today = [],
            nextDays = [];

        const data = this.state.data;

        if (data) {
            popular = this.sortData(
                data.filter(
                    item =>
                        item.product.id === PRODUCT_IDS.V75 || item.product.id === PRODUCT_IDS.V86
                )
            );

            today = this.sortData(
                data.filter(
                    item =>
                        moment(item.date).format(_DATE_FORMAT) === moment().format(_DATE_FORMAT) &&
                        item.product.id !== PRODUCT_IDS.V75 &&
                        item.product.id !== PRODUCT_IDS.V86
                )
            );

            nextDays = this.sortData(
                data.filter(
                    item =>
                        moment(item.date).format(_DATE_FORMAT) !== moment().format(_DATE_FORMAT) &&
                        item.product.id !== PRODUCT_IDS.V75 &&
                        item.product.id !== PRODUCT_IDS.V86
                )
            );
        }

        return {
            popular,
            today,
            nextDays,
        };
    };

    renderPreloaders() {
        const props = { grow: byPlatform(true, false) };
        return (
            <FlexWrapper {...flexWrapperProps}>
                <Preloader color={PRODUCT_COLORS.V65} {...props} />
                <Preloader color={PRODUCT_COLORS.V4} {...props} />
                <Preloader color={PRODUCT_COLORS.V86} {...props} />
            </FlexWrapper>
        );
    }

    renderCards(cards, oneRowView = false) {
        return (
            <FadeIn>
                <FlexWrapper {...flexWrapperProps}>
                    {cards.map((item, index) => (
                        <QuickPickCard
                            small={byPlatform(1, 3)}
                            key={index}
                            item={item}
                            serverTime={this.props.serverTime}
                            singleItem={oneRowView}
                            grow={byPlatform(true, false)}
                        />
                    ))}
                </FlexWrapper>
                <div style={{ clear: 'both' }} />
            </FadeIn>
        );
    }

    render() {
        const { popular, today, nextDays } = this.getGroupedData();

        const headlineProps = {
            as: 'h2',
            size: '20px',
            bolder: true,
            condensed: true,
            padding: byPlatform('24px 0 16px', '48px 0 24px'),
        };

        return (
            <>
                <Headline {...headlineProps}>{t.QuickPick.popular}</Headline>
                {popular.length > 0 ? this.renderCards(popular) : this.renderPreloaders()}

                <Headline {...headlineProps}>{t.QuickPick.today}</Headline>
                {today.length > 0
                    ? this.renderCards(today, byPlatform(true, false))
                    : this.renderPreloaders()}

                <Headline {...headlineProps}>{t.QuickPick.nextDays}</Headline>
                {nextDays.length > 0
                    ? this.renderCards(nextDays, byPlatform(true, false))
                    : this.renderPreloaders()}
            </>
        );
    }
}

export { PRODUCT_BACKGROUNDS };

const mapStateToProps = state => ({
    serverTime: state.AISDataProvider.serverTime,
});

const mapDispatchToProps = dispatch => {
    return {
        fetchServerTime: () => {
            dispatch(fetchServerTime());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickPick);
