class Coupon {
    combinationNumber = 0;
    combinationPrice = 0;
    raceDay = '';
    raceNumber = 0;
    trackCode = 0;
    programNumber = 0;
}

export default Coupon;
