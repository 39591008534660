import React from 'react';
import { Delimiter, styles } from '../Mobile/styled';
import BetPick from 'features/BetSlip2/components/BetPick';
import BetForm from 'features/BetSlip2/components/BetForm';
import { PRODUCT_IDS } from 'configs/products';
import BetSlipCard2 from 'features/BetSlip2/components/BetSlipCard2';
import StrictBet from 'features/BetSlip2/components/StrictBet';
import { Horse, VRace } from 'features/TrackPage/model/Race';
import Product from 'features/TrackPage/model/Product';
import { ShortenRaceDay } from 'features/EventBoard/server/calendar';
import Bet from 'common/DataObjects/Bet';
import Betslip from 'common/DataObjects/Betslip';

export declare type UIHorseObject = Pick<Horse, 'startNr' | 'horse'> & { scratched: boolean };

export declare type UIRaceObject = Pick<VRace, 'postTime' | 'postTimeUTC' | 'index'> & {
    horses: UIHorseObject[];
    trackCode: string;
    hostTrack: { id: number; code: string };
};

interface BetSlipProps {
    product: Partial<Product>;
    raceDay: ShortenRaceDay;
    trackCode: string;
    isVProduct: boolean;
    date: string;
    firstVRace?: UIRaceObject;
    races: UIRaceObject[];
    singleRaceBetPicks: Bet;
    betSlip: Betslip;
    reserves: any; //@TODO Define real type
    serverTime?: string;
    isMultitrack: boolean;
    combinedTrackName: string | null;
    combinationsCount: number;
    combinationPrice: number;
    applyCombinationPrice(combinationPrice: number): void;
    isCombinationPriceValid: boolean;
    isCouponAmountExceed: boolean;
    errorMessage: string;
    minimumPicksValid: boolean;
    maximumPicksValid: boolean;
    withoutDeleteButton?: boolean;
    useSeparateMultitrackCodesForBetslip?: boolean;
}

const BetSlip = ({
    product,
    raceDay,
    trackCode,
    isVProduct,
    firstVRace,
    races,
    singleRaceBetPicks,
    betSlip,
    reserves,
    serverTime,
    combinedTrackName,
    minimumPicksValid,
    maximumPicksValid,
    isMultitrack,
    combinationsCount,
    combinationPrice,
    applyCombinationPrice,
    isCombinationPriceValid,
    isCouponAmountExceed,
    errorMessage,
    withoutDeleteButton = false,
    useSeparateMultitrackCodesForBetslip = false,
}: BetSlipProps) => {
    return (
        <BetSlipCard2
            withoutDeleteButton={withoutDeleteButton}
            combinedTrackName={combinedTrackName}
            product={product}
            race={firstVRace}
            serverTime={serverTime}
            date={raceDay.raceDayDate}
            styles={styles.BetSlipCard}
        >
            <BetPick
                raceDay={raceDay}
                trackCode={trackCode}
                isVProduct={isVProduct}
                product={product}
                combinationsCount={combinationsCount}
                race={firstVRace}
                races={races}
                reserves={reserves}
                singleRaceBetPicks={singleRaceBetPicks}
                betSlip={betSlip}
                isMultitrackProduct={isMultitrack}
                useSeparateMultitrackCodesForBetslip={useSeparateMultitrackCodesForBetslip}
                placeholders
            />
            <Delimiter />
            <BetForm
                combinationsCount={combinationsCount}
                product={product}
                amount={combinationPrice}
                applyCombinationPrice={applyCombinationPrice}
                isCombinationPriceValid={isCombinationPriceValid}
                isCouponAmountExceed={isCouponAmountExceed}
                combinationPriceError={errorMessage}
                minimumPicksValid={minimumPicksValid}
                maximumPicksValid={maximumPicksValid}
                spilKlub
            />

            {product.id === PRODUCT_IDS.V65 ||
            product.id === PRODUCT_IDS.V64 ||
            product.id === PRODUCT_IDS.GS75 ? (
                <StrictBet product={product.id} />
            ) : null}
        </BetSlipCard2>
    );
};

export default BetSlip;
