import Wrapper from 'ui/Wrapper';
import FlexWrapper from 'ui/FlexWrapper';
import styled from 'styled-components';
import Icon from 'ui/Icon';
import { BACKGROUNDS, COLORS } from 'themes';
import StopSpilletImg from './assets/stop_spillet.png';

const StopSpillet = styled.img.attrs({
    src: StopSpilletImg,
})`
    width: 40px;
`;

const FilledWrapper = styled(Wrapper)`
    background: ${BACKGROUNDS.primaryFlat};
    padding: 0;
    height: 53px;
`;

const StyledFlexWrapper = styled(FlexWrapper)`
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 15px;
`;

const StyledHomeButtonContainer = styled.div`
    height: 38px;
    width: 38px;
    background: ${BACKGROUNDS.white};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    path {
        fill: ${BACKGROUNDS.primaryFlat};
    }
`;

const IconsContainer = styled(Wrapper)`
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    right: 10px;
    padding: 0;
    margin: 0 10px;
`;

const IconWrapper = styled(Wrapper)`
    text-align: center;
    padding: 0;
    margin-right: 10px;
`;

const ModifiedIcon = styled(Icon)`
    height: 35px;
    width: auto;
`;

const RightArea = styled.div`
    align-self: right;
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-basis: 250px;
    flex-grow: 2;
    justify-content: flex-end;
    gap: 10px;
`;

const Label = styled.div`
    padding: 0;
    font-size: 16px;
    margin: 0 0 0 8px;
    color: ${COLORS.white};
    font-weight: 900;
`;

const Value = styled(Label)`
    font-weight: normal;
    margin: 0;
`;

const Divider = styled.span`
    margin: 2px;
`;
const ClockWrapper = styled(Wrapper)`
    display: flex;
    align-items: center;
    border-radius: 6px;
    background: rgba(1, 1, 1, 0.1);
    box-sizing: border-box;
    padding: 2px 10px;
    height: 34px;
    width: 88px;
    justify-content: space-evenly;
`;

const Time = styled(Wrapper)`
    color: ${COLORS.white};
    font-size: 16px;
    font-weight: 900;
    text-transform: uppercase;
    padding: 0;
`;

const ModifiedClockIcon = styled(Icon)`
    height: 24px;
    width: auto;
`;

const BuildText = styled(Label)`
    position: absolute;
    color: ${COLORS.white};
    right: 370px;
    top: 97px;
    width: 100px;
`;

export {
    StopSpillet,
    FilledWrapper,
    StyledFlexWrapper,
    StyledHomeButtonContainer,
    IconsContainer,
    IconWrapper,
    ModifiedIcon,
    Label,
    Value,
    ClockWrapper,
    Time,
    ModifiedClockIcon,
    BuildText,
    Divider,
    RightArea,
};
