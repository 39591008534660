import React from 'react';
import { ChooseDate } from '@it25syv/25syv-ui';
import { Overlay } from 'ui/Popup';
import Modal from 'ui/ModalContent';
import { hidePopup } from 'utils/navigation';
import { DATEPICKER_THEME_CONFIG } from 'utils/theme-helpers';
import useTransactionStateStore from './hooks/useTransactionStateStore';

const DatePicker = () => {
    const { setTransactionState } = useTransactionStateStore();

    const hideSelf = () => {
        hidePopup('CHOOSE_DATE_POPUP');
    };
    return (
        <Modal>
            <Overlay>
                <ChooseDate
                    closeChooseDate={hideSelf}
                    datePickerTheme={DATEPICKER_THEME_CONFIG}
                    sendChosenDate={date => {
                        setTransactionState({ dateFrom: date.dateFrom, dateTo: date.dateTo });
                        hideSelf();
                    }}
                />
            </Overlay>
        </Modal>
    );
};

export default DatePicker;
