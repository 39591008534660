import React, { Fragment } from 'react';

const getShoe = (frontShoe, backShoe) => {
    const shoeMap = {
        true: 'C',
        false: 'Ȼ',
        undefined: '-',
        null: '-',
    };

    return shoeMap[frontShoe] + shoeMap[backShoe];
};

const ShoeInfo = ({ frontShoe, backShoe }) => {
    return <Fragment>{getShoe(frontShoe, backShoe)}</Fragment>;
};

export default ShoeInfo;
