const SET_DATE = 'SET_DATE';

export const setDate = (date, meta = {}) => {
    return (dispatch) => {
        dispatch({
            type: SET_DATE,
            payload: date,
            meta,
        });
    };
};

export { SET_DATE };
