import React, { useState } from 'react';
import classNames from 'classnames';

import UserConductor from 'common/conductors/UserConductor';
import NotificationConductor from 'common/conductors/NotificationConductor';

import DraggablePopup, { DraggablePopupTitle } from 'ui/DraggablePopup';
import Button from 'ui/controls/Button';
import Text, { Strong } from 'ui/Text';
import Checkbox from 'ui/Checkbox';
import Modal from 'ui/ModalContent';
import { Overlay, PopupWrapper } from 'ui/Popup';
import Spinner from 'ui/Spinner';
import usePayoutConfirmationPopupVisibility from './usePayoutConfirmationPopupVisibility';

import { hidePopup, openNextPopup } from 'utils/navigation';
import { formatMoney } from 'utils/formatters';
import { BACKGROUNDS, COLORS } from 'themes';
import { byPlatform, isDesktop, isMobile } from 'utils/platforms';

const PopupContent = ({ close, winAmount, handleClickConfirm, loading }) => {
    const btnExtra = {
        className: 'flex-grow',
        bold: true,
        size: byPlatform('x15', 'x2'),
        style: { margin: 0, width: '50%' },
    };

    const { doNotShowAgain, setPayoutConfirmationPopupVisibility } =
        usePayoutConfirmationPopupVisibility();

    return (
        <>
            <>
                <DraggablePopupTitle>Bekræft din overførsel</DraggablePopupTitle>

                <Text muted size="x1.5">
                    Du er ved at overføre din gevinst på{' '}
                    <Strong>{formatMoney(winAmount)} kr.</Strong> til din spillekonto. Du kan
                    efterfølgende udbetale beløbet eller bruge det på nye væddemål.
                </Text>

                <Text muted size="x1.5">
                    <Strong>Bemærk:</Strong> Denne handling kan ikke fortrydes.
                </Text>

                <Checkbox
                    label="Vis ikke igen"
                    labelPosition="right"
                    styles={{ justifyContent: 'flex-start', float: 'none' }}
                    labelStyles={{ fontWeight: 600, color: COLORS.mutedText }}
                    checked={doNotShowAgain}
                    changeHandler={() => {
                        setPayoutConfirmationPopupVisibility(!doNotShowAgain);
                    }}
                />

                <div className="flex" style={{ gap: '10px' }}>
                    <Button grey {...btnExtra} onClick={close}>
                        Annuller
                    </Button>
                    <Button accentAlt {...btnExtra} onClick={handleClickConfirm}>
                        {loading ? <Spinner size={16} black /> : 'Overfør'}
                    </Button>
                </div>
            </>
        </>
    );
};

export const confirmPayout = ({ couponId, saleId, winAmount }) => {
    return UserConductor.payoutRetailCoupon({ couponId, saleId })
        .then(() => {
            UserConductor.isUserLoggedIn(); // update user balance

            if (isMobile) {
                return openNextPopup('RETAIL_PAYOUT_SUCCESS', 'RETAIL_PAYOUT_CONFIRMATION', {
                    winAmount,
                });
            }

            NotificationConductor.success(
                <p>
                    <Strong>{formatMoney(winAmount)} kr. </Strong> er blevet overført til din
                    spillekonto.
                </p>
            );
        })
        .catch(() => {
            if (isMobile) {
                return openNextPopup('RETAIL_PAYOUT_FAILED', 'RETAIL_PAYOUT_CONFIRMATION');
            }

            NotificationConductor.error('Overførsel afbrudt, prøv igen');
        });
};

const PayoutConfirmationPopup = ({ couponId, saleId, winAmount }) => {
    const [loading, setLoading] = useState(false);
    const [hiding, setHiding] = useState(false);

    const hideSelf = () => {
        if ((hiding && isDesktop) || isMobile) {
            hidePopup('RETAIL_PAYOUT_CONFIRMATION');
        }
    };

    const animateHidingDesktop = () => {
        setHiding(true);
    };

    const handleClickConfirm = () => {
        setLoading(true);

        confirmPayout({ couponId, saleId, winAmount }).finally(() => {
            setLoading(false);

            if (isDesktop) {
                animateHidingDesktop();
            }
        });
    };

    return byPlatform(
        // mobile
        <DraggablePopup
            padding="10px 34px 24px 34px"
            height={'45%'}
            closeHandler={hideSelf}
            withoutDragIndicator
            radius="12px"
            background={BACKGROUNDS.white}
            containerStyles={{ display: 'flex', flexDirection: 'column' }}
        >
            {({ close }) => (
                <PopupContent
                    close={close}
                    winAmount={winAmount}
                    handleClickConfirm={handleClickConfirm}
                    loading={loading}
                />
            )}
        </DraggablePopup>,
        // desktop
        <Modal
            priority={3}
            className={classNames('modal-fade', { 'modal-fade-out': hiding })}
            onAnimationEnd={hideSelf}
        >
            <Overlay>
                <PopupWrapper
                    radius="12px"
                    padding="10px 35px 30px"
                    width="400px"
                    minHeight="unset"
                    className="border-box"
                >
                    <PopupContent
                        close={animateHidingDesktop}
                        winAmount={winAmount}
                        handleClickConfirm={handleClickConfirm}
                        loading={loading}
                    />
                </PopupWrapper>
            </Overlay>
        </Modal>
    );
};

export default PayoutConfirmationPopup;
