import React from 'react';
import ProductTips from './ProductTips';
import useProductTipsVisibility from 'features/TrackPage/hooks/useProductTipsVisibility';
import { forMobile } from 'utils/platforms';
import { Row } from '../styled';

const ProductTipsRow = () => {
    const { productTipsVisibility } = useProductTipsVisibility();
    return (
        productTipsVisibility &&
        forMobile(
            <Row>
                <ProductTips />
            </Row>
        )
    );
};

export default ProductTipsRow;
