import React from 'react';
import styled from 'styled-components';
import Popup, {
    PopupWrapper as PopupWrapperDef,
    Overlay as TransparentBackgroundDef,
} from 'ui/Popup';
import useModals from 'common/hooks/useModals';
import Text from 'ui/Text';
import logoIcon from 'images/icons/android-icon.png';
import persistentStorage from 'common/storage';

const PopupWrapper = styled(PopupWrapperDef)`
    display: flex;
    flex-direction: column;
    width: 90%;
    height: auto;
    min-height: 0;
    border-radius: 8px;
    padding: 10px 20px;
    box-sizing: border-box;
    background: #edf1f8;
    position: absolute;
    bottom: 40px;
    left: 50%;
    transform: translateX(-50%);
`;

const TransparentBackground = styled(TransparentBackgroundDef)`
    background: transparent !important;
`;

const LogoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px auto 5px;
`;

const Buttons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin: 0 auto;
    width: 100%;
`;

const InstallPWAModal = ({ modalData }) => {
    const { deferredPrompt } = modalData;
    const { hide } = useModals();

    const handleInstall = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then(choiceResult => {
                handleClose();
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
            });
        }
    };

    const handleClose = () => {
        persistentStorage.save({ hidePWAPrompt: true });
        hide('INSTALL_PWA_MODAL');
    };

    return (
        <Popup
            PopupWrapperComponent={PopupWrapper}
            TransparentBackgroundComponent={TransparentBackground}
            onClose={() => {
                hide('INSTALL_PWA_MODAL');
            }}
            disableCloseButton
        >
            <Text bold size={'x1.5'} padding={'5px 0'}>
                Installer app?
            </Text>
            <Text size={'x1'} padding={'5px 0'} weight={'700'}>
                Tryk på tilføj og installer Bet25 Heste som app.
            </Text>
            <LogoWrapper>
                <img src={logoIcon} alt="logoIcon" style={{ width: '70px' }} />
                <Text size={'x1'} padding={'5px 0'}>
                    Bet25 Heste
                </Text>
            </LogoWrapper>
            <Buttons>
                <Text
                    size={'x1.5'}
                    padding={'0'}
                    style={{ flex: '1 0 45%' }}
                    bold
                    onClick={handleClose}
                    align={'center'}
                >
                    Annuller
                </Text>
                <Text align={'center'} color={'#C5C9D2'}>
                    |
                </Text>
                <Text
                    size={'x1.5'}
                    padding={'0'}
                    style={{ flex: '1 0 45%' }}
                    bold
                    onClick={handleInstall}
                    align={'center'}
                >
                    Installer
                </Text>
            </Buttons>
        </Popup>
    );
};

export default InstallPWAModal;
