import React, { useEffect, useState } from 'react';
import { PopupWrapper, PopupNoteWrapper } from './styled';
import Text from 'ui/Text';
import Button from 'ui/controls/Button';
import { useDispatch } from 'react-redux';
import { dropSubscriptionStatuses, fetchSubscriptionsAsync } from '../actions';
import NotificationConductor from '../../../common/conductors/NotificationConductor';
import { CloseButton } from '../components/styled';
import { Subscription } from '../model/Subscription';
import { TransparentDesktopModalLayer } from 'ui/DesktopModalWrapper';
import FlexWrapper from 'ui/FlexWrapper';
import { byPlatform } from '../../../utils/platforms';
import Spinner from 'ui/Spinner';
import { deleteSubscriptions } from '../server';
interface EditSubscriptionsPopupProps {
    setBackground(bg: string): void;
    modalData: {
        subscriptionsData: {
            userId: number;
            groupId: number;
            subscriptions: Subscription[];
        };
        onClose(): void;
        onConfirm(subscriptionsData: {
            userId: number;
            groupId: number;
            subscriptions: Subscription[];
        }): void;
    };
}
const EditSubscriptionsPopup = ({
    modalData,
    setBackground,
}: EditSubscriptionsPopupProps) => {
    const { subscriptionsData, onClose, onConfirm } = modalData;
    const dispatch = useDispatch();
    const [pending, setPending] = useState(false);
    const deleteAllSubscriptions = () => {
        setPending(true);
        deleteSubscriptions(subscriptionsData.groupId, subscriptionsData.userId)
            .then(() => {
                setPending(false);
                dispatch(
                    fetchSubscriptionsAsync.request({
                        groupId: subscriptionsData.groupId,
                        userId: subscriptionsData.userId,
                    })
                );
                dispatch(dropSubscriptionStatuses());
                NotificationConductor.success('Dit abonnement er nu frameldt');
                onClose();
            })
            .catch((err) => {
                NotificationConductor.error(err);
            });
    };
    const showSuscriptions = () => {
        onConfirm(subscriptionsData);
    };
    useEffect(() => {
        dispatch(dropSubscriptionStatuses());
        setBackground('transparent');
    }, []);

    return (
        <TransparentDesktopModalLayer>
            <FlexWrapper padding="0">
                <PopupWrapper>
                    <CloseButton
                        style={{
                            position: 'absolute',
                            top: byPlatform(0, '45px'),
                            left: 0,
                        }}
                        onClick={() => {
                            onClose();
                        }}
                    />

                    <PopupNoteWrapper>
                        <Text size="x3" color="black" bold center>
                            Tilpas dit abonnement
                        </Text>
                        <Text size="x2" color="secondary" center>
                            Vælg hvilke spiltyper og beløb du vil abonnere på
                            via dette hold
                        </Text>
                        <Button
                            block
                            margin="20px auto"
                            rounded="20px"
                            accent
                            size="x2"
                            width="90%"
                            padding="20px"
                            onClick={showSuscriptions}
                        >
                            TILPAS ABONNEMENT
                        </Button>
                        <Button
                            width="90%"
                            block
                            red
                            rounded="20px"
                            size="x2"
                            padding="20px"
                            onClick={deleteAllSubscriptions}
                        >
                            {pending ? <Spinner grey /> : 'AFMELD ABONNEMENT'}
                        </Button>
                    </PopupNoteWrapper>
                </PopupWrapper>
            </FlexWrapper>
        </TransparentDesktopModalLayer>
    );
};

export default EditSubscriptionsPopup;
