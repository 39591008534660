import {
    SHOW_MODAL,
    HIDE_MODAL,
    ANIMATE_MODAL_BEFORE_CLOSE,
    SHOW_UNWRAPPED_MODAL,
    HIDE_UNWRAPPED_MODAL,
    TRACK_HISTORY,
    SET_LAST_VISITED_RACE_URL,
    SET_LAST_URL_BEFORE_LOGIN,
    SET_PREVIOUS_URL,
    SET_OVERLAY,
    SET_COMPONENTS_OPTIONS,
    SHOW_HIGHLIGHT,
} from '../actions/uiActions';
import { setIn, updateIn, merge, hasIn } from 'immutable';
import persistentStorage from 'common/storage';
import { getExpirationTimeMinutes } from 'common/storage/cookieStorage';
import { history } from 'utils/navigation';

const initialState = {
    modals: {},
    unwrappedModals: {},
    history: {},
    signupBarVisibility: false,
    initialURL: history.location.pathname,
    lastVisitedRaceURL: persistentStorage.get('lastVisitedRaceURL'),
    lastURLBeforeLogin: persistentStorage.get('lastURLBeforeLogin'),
    previousURL: persistentStorage.get('previousURL'),
    overlay: false,
    components: {},
    showHighlight: false,
};

const initialModalState = {
    id: 0,
    data: {},
    visible: false,
    priority: 0,
    cameFrom: '',
    animatingBeforeClose: false,
    closingAnimationDuration: 0,
};

const system = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_MODAL:
            return setIn(
                state,
                ['modals', action.payload.id],
                merge(initialModalState, action.payload)
            );
        case ANIMATE_MODAL_BEFORE_CLOSE:
            return updateIn(state, ['modals', action.payload.id], (modal) =>
                merge(modal || {}, {
                    animatingBeforeClose: true,
                    closingAnimationDuration: action.payload.duration,
                })
            );
        case HIDE_MODAL:
            return updateIn(state, ['modals', action.payload.id], (modal) =>
                merge(modal || {}, {
                    visible: false,
                    animatingBeforeClose: false,
                })
            );
        case SHOW_UNWRAPPED_MODAL:
            return setIn(
                state,
                ['unwrappedModals', action.payload.id],
                merge(initialModalState, {
                    ...action.payload,
                    data: action.payload.data,
                    visible: true,
                })
            );
        case HIDE_UNWRAPPED_MODAL:
            if (!hasIn(state, ['unwrappedModals', action.payload.id])) {
                return state;
            }
            return updateIn(state, ['unwrappedModals', action.payload.id], (modal) =>
                merge(modal, { visible: false })
            );
        case TRACK_HISTORY:
            return {
                ...state,
                history: {
                    currentItem: action.payload.currentItem,
                    prevItem: state.currentItem,
                },
            };
        case SET_LAST_VISITED_RACE_URL:
            persistentStorage.save(
                { lastVisitedRaceURL: action.payload },
                { expires: getExpirationTimeMinutes(30) }
            );
            return {
                ...state,
                lastVisitedRaceURL: action.payload,
            };
        case SET_LAST_URL_BEFORE_LOGIN:
            persistentStorage.save({ lastURLBeforeLogin: action.payload });
            return {
                ...state,
                lastURLBeforeLogin: action.payload,
            };
        case SET_PREVIOUS_URL:
            persistentStorage.save({ previousURL: action.payload });
            return {
                ...state,
                previousURL: action.payload,
            };
        case SET_OVERLAY:
            return {
                ...state,
                overlay: action.payload,
            };
        case SET_COMPONENTS_OPTIONS:
            return {
                ...state,
                components: {
                    ...state.components,
                    ...action.payload,
                },
            };
        case SHOW_HIGHLIGHT:
            return {
                ...state,
                showHighlight: action.payload,
            };
        default:
            return { ...state };
    }
};

export default system;
