import React, { useState, useEffect } from 'react';
import { setRetailPin } from 'common/actions/authActions';
import { isMobile } from 'react-device-detect';
import { COLORS } from 'themes';

import Flex from 'ui/Flex';
import Wrapper from 'ui/Wrapper';

import {
    NavigationBar,
    DescriptionText,
    BACKGROUND,
    BulletComponent,
    PinKeyboardBtn,
} from 'features/SpilID/styled';

import withPopupWrapper from './withPopupWrapper';

import Bullets from 'features/Login/Pin/Bullets';
import Keyboard from 'features/Login/Pin/Keyboard';

import UserConductor from 'common/conductors/UserConductor';
import NotificationConductor from 'common/conductors/NotificationConductor';
import getTexts from 'utils/localization';
import useAuth from 'common/hooks/useAuth';
import { useDispatch } from 'react-redux';

const t = getTexts();

const PIN_LENGTH = 4;

const SpilIDPinCode = ({ hideModalAnimate }) => {
    const [pinCode, setPinCode] = useState('');
    const [loading, setLoading] = useState(false);

    const dispatch = useDispatch();

    const auth = useAuth();
    const user = auth?.user;
    const isRetailPinSet = user?.isRetailPinSet;

    const addPinNumber = number => {
        if (pinCode.length <= PIN_LENGTH) {
            setPinCode(pinCode + number);
        }
    };

    const goBack = () => {
        if (!isRetailPinSet) {
            hideModalAnimate('SPIL_ID');
        }

        hideModalAnimate('SPIL_ID_PIN_CODE');
    };

    const removePinNumber = () => setPinCode(pinCode.slice(0, -1));

    useEffect(() => {
        if (pinCode.length === PIN_LENGTH) {
            setLoading(true);
            const pinWillBeSetFirstTime = !isRetailPinSet;

            setTimeout(() => {
                UserConductor.setRetailPin({ pin: pinCode })
                    .then(() => {
                        hideModalAnimate('SPIL_ID_PIN_CODE');
                        dispatch(setRetailPin(pinCode));

                        NotificationConductor.success(
                            pinWillBeSetFirstTime ? t.pin.success : t.pin.successChanged
                        );
                    })
                    .catch(() => {
                        setPinCode('');
                        NotificationConductor.error(t.pin.error);
                    })
                    .finally(() => {
                        setLoading(false);
                        hideModalAnimate('SPIL_ID_PIN_CODE');
                    });
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pinCode, hideModalAnimate]);

    return (
        <>
            <NavigationBar
                title={'Vælg ny kode'}
                isAbove
                clickHandler={goBack}
                iconColor={'#C6CCDB'}
                titleColor={COLORS.black}
                background={BACKGROUND}
                hideQuestionMark
                underline
                padding={'15px 24px'}
            />

            <Flex
                padding={'100px 35px 0'}
                boxSizing={'border-box'}
                direction={'column'}
                justify={'space-between'}
                background={BACKGROUND}
            >
                <DescriptionText>
                    Din midlertidige pinkode til dit spil-id er udløbet. Vælg venligst en ny
                    pinkode.
                </DescriptionText>

                <Flex
                    boxSizing={'border-box'}
                    align={'flex-start'}
                    justify={'space-between'}
                    padding={'30px 0 0 0'}
                >
                    <Bullets
                        pinLength={PIN_LENGTH}
                        processingLength={pinCode.length}
                        BulletComponent={BulletComponent}
                        complete={loading}
                    />
                </Flex>

                <Wrapper padding="0 30px 15px" width="100%">
                    <Keyboard
                        onAddValue={addPinNumber}
                        onRemove={removePinNumber}
                        ButtonComponent={PinKeyboardBtn}
                        borderRadius={'10px'}
                    />
                </Wrapper>
            </Flex>
        </>
    );
};

export default isMobile ? SpilIDPinCode : withPopupWrapper(SpilIDPinCode);
