import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import BetPick from 'features/BetSlip/components/BetPick';
import { CloseButton, Popup, SuccessIcon } from './styled';
import PopupWrapper from 'ui/PopupWrapper';
import { Fillable, FullScreen } from 'ui/FullScreen';
import PickHeader from './PickHeader';
import Hr from 'ui/Hr';
import Text from 'ui/Text';
import Headline from 'ui/Headline';
import Wrapper from 'ui/Wrapper';
import RaceSummary from './RaceSummary';
import { byPlatform, forMobile } from 'utils/platforms';
import Icon from 'ui/Icon';
import Button from 'ui/controls/Button';
import { COLORS } from 'themes';
import { PRODUCT_IDS } from 'configs/products';
import { ProductList } from 'common/DataObjects/Product';

import getTexts from 'utils/localization';
import { generatePopupLink } from 'utils/navigation';
const t = getTexts();

export function placeVinderPladsBetFromRecipt(
    addBet,
    setProduct,
    keepRaceIndexInState,
    betSlip,
    date,
    trackCode,
    product,
    raceIndex
) {
    try {
        let betForProduct = PRODUCT_IDS.P;

        if (product.id === PRODUCT_IDS.P) {
            betForProduct = PRODUCT_IDS.V;
        }

        if (
            betSlip.betsByDates &&
            betSlip.betsByDates[date] &&
            betSlip.betsByDates[date][trackCode]
        ) {
            Object.keys(betSlip.betsByDates[date][trackCode][product.id][raceIndex]).forEach(
                (startNr) => {
                    addBet(
                        date,
                        trackCode,
                        betForProduct,
                        parseInt(raceIndex, 10),
                        parseInt(startNr, 10),
                        parseInt(startNr, 10)
                    );
                }
            );

            setProduct(ProductList.getAll().find((p) => p.id === betForProduct));

            keepRaceIndexInState(raceIndex);
        }
    } catch (error) {
        console.log(error);
    }
}

class Receipt extends Component {
    placeTheSameVinderPladsBet = () => {
        const { betSlip, date, trackCode, product, raceIndex, combinationPrice } = this.props;

        this.props.placeBetHandler(
            betSlip,
            date,
            trackCode,
            product,
            raceIndex,
            parseInt(combinationPrice, 10)
        );
    };

    placeLynBet = () => {
        const { date, trackCode, product } = this.props;
        this.props.placeLynBetHandler(date, trackCode, product);
    };

    renderBetButton = () => {
        const { product } = this.props;

        if (product.id === PRODUCT_IDS.V || product.id === PRODUCT_IDS.P) {
            const btnLabel = product.id === PRODUCT_IDS.V ? 'SPIL PLADS' : 'SPIL VINDER';
            const textLabel = product.id === PRODUCT_IDS.V ? 'SPIL SOM PLADS' : 'SPIL SOM VINDER';

            return (
                <Wrapper padding="0" align="center">
                    <Headline align="center" black size="x1">
                        {textLabel}
                    </Headline>

                    <Button
                        margin="0px auto 10px"
                        size="x2"
                        secondary
                        width="240px"
                        onClick={this.placeTheSameVinderPladsBet}
                        rounded="25px"
                        style={{ display: 'block' }}
                    >
                        {btnLabel}
                    </Button>
                </Wrapper>
            );
        } else if (product.isAvalibleForDerbyLyn()) {
            return (
                <Wrapper padding="0" align="center">
                    <Headline align="center" black size="x1">
                        Prøv som Lyn
                    </Headline>

                    <Button
                        margin="0px auto 10px"
                        size="x2"
                        secondary
                        width="240px"
                        onClick={this.placeLynBet}
                        rounded="25px"
                        style={{ display: 'block' }}
                    >
                        Spil Lyn
                    </Button>
                </Wrapper>
            );
        }

        return null;
    };
    renderSecondPopup = () => {
        return (
            (this.props.product.isVProduct() ||
                this.props.product.id === PRODUCT_IDS.P ||
                this.props.product.id === PRODUCT_IDS.V) &&
            this.props.product.id !== PRODUCT_IDS.DD &&
            this.props.product.id !== PRODUCT_IDS.LD
        );
    };

    render() {
        const styles = this.props.styles || {};

        return (
            <PopupWrapper
                style={{
                    padding: 0,
                    ...styles.PopupWrapper,
                    top: byPlatform(0, '50px'),
                }}
            >
                <FullScreen style={styles.FullScreen}>
                    <Fillable>
                        <Popup
                            recipeMode={this.props.recipeMode}
                            style={{
                                paddingTop: '5px',
                                ...styles.Popup,
                                marginBottom: '10px',
                            }}
                            data-test-id="receipt-main"
                        >
                            <SuccessIcon
                                style={byPlatform(
                                    {},
                                    {
                                        position: 'relative',
                                    },
                                    {
                                        position: 'relative',
                                    }
                                )}
                            />
                            {forMobile(
                                <CloseButton
                                    onClick={() => this.props.hideModal('RECEIPT')}
                                    style={{
                                        top: '-15px',
                                    }}
                                >
                                    <Icon
                                        iclose
                                        color="white"
                                        style={{
                                            width: '12px',
                                            height: '12px',
                                        }}
                                    />
                                </CloseButton>
                            )}
                            <h1
                                style={{
                                    fontSize: byPlatform('30px', '20px'),
                                    color: byPlatform(COLORS.grey, 'inherit'),
                                    marginTop: '0px',
                                    marginBottom: '0px',
                                }}
                            >
                                {t.receipt.message}
                            </h1>
                            <RaceSummary {...this.props} />
                            <Hr
                                style={{
                                    marginBottom: '0px',
                                    marginTop: '10px',
                                }}
                            />

                            <Fragment>
                                <PickHeader product={this.props.product} />
                                <BetPick {...this.props} />
                            </Fragment>

                            <Hr style={{ marginTop: '15px' }} />

                            <Text size="x1" className="text-center">
                                {t.receipt.myGamesText}{' '}
                                <NavLink
                                    to={byPlatform(
                                        '/mine-spil',
                                        generatePopupLink('/konto/activebets', 'USER_AREA_DESKTOP')
                                    )}
                                >
                                    {t.receipt.myGamesLink}
                                </NavLink>
                            </Text>
                        </Popup>
                        {this.renderSecondPopup() ? (
                            <Popup
                                recipeMode={true}
                                style={{
                                    paddingTop: '5px',
                                    ...styles.Popup,
                                }}
                                data-test-id="receipt-second"
                            >
                                {this.renderBetButton()}
                            </Popup>
                        ) : null}
                    </Fillable>
                </FullScreen>
            </PopupWrapper>
        );
    }
}

export default Receipt;
