import React from 'react';
import { ContentHeight, FullScreen } from 'ui/FullScreen';
import TopBar from 'ui/topbar';
import TopBarText from 'ui/topbar/Text';
import classNames from 'classnames';
import TopBarNav from 'ui/topbar/Nav';
import ideposit from 'images/icons/deposit.png';
import ipayout from 'images/icons/payout.png';
import { StyledFillable } from 'features/UserArea/UserAreaMenu/styled';
import MenuList from 'features/UserArea/UserAreaMenu/UserAreaMenuMobile/MenuList';
import { formatMoney } from 'utils/formatters';

import getTexts from 'utils/localization';
const t = getTexts();

const Menu = (props) => {
    return (
        <FullScreen height="100vh">
            <ContentHeight>
                <TopBar>
                    <TopBarText className="sm-top">
                        {`${t.userArea.top.lastLogin}: ${props.user.lastLogin}`}
                    </TopBarText>
                    <TopBarText className="sm">{props.user.userName}</TopBarText>
                    <TopBarText className={classNames(['lg', 'text-bold'])}>
                        {formatMoney(props.user.balance)} {t.currency}
                    </TopBarText>
                </TopBar>
            </ContentHeight>

            <StyledFillable>
                <TopBarNav
                    items={[
                        {
                            title: t.userArea.top.deposit,
                            icon: ideposit,
                            onClick: () => {
                                props.showModal('DEPOSIT', 1);
                            },
                        },
                        {
                            title: t.userArea.top.payout,
                            icon: ipayout,
                            onClick: () => {
                                props.showModal('WITHDRAW', 1);
                            },
                        },
                    ]}
                />
                <MenuList
                    user={props.user}
                    isPinSet={props.isPinSet}
                    togglePin={props.togglePin}
                    showModal={props.showModal}
                    logout={props.logout}
                    toggleNewsletterSubscription={props.toggleNewsletterSubscription}
                />
            </StyledFillable>
        </FullScreen>
    );
};

export default Menu;
