import { get } from './RequestHandler';
import { byWebEnv } from 'utils/env';

export function getFAQ() {
    return get('derby25/api.php/', { r: 'Faq' });
}

export function getText(textId) {
    return get('derby25/api.php/', { r: 'Texts', id: textId }, null, false);
}

export function getAuthUIConfig() {
    return get(byWebEnv('derby25/api.php/', 'derby25/test.php/'), {
        r: 'UserHighlight',
        ch: 'mob',
    });
}

export function toggleMailChimpSubscription(data = {}) {
    return get('bet25/newsletter/api.php', { ...data });
}
