import betSlipSelector from './betSlipSelector';
import productSelector from './productSelector';
import { getHorseCountsInRaces } from 'common/selectors/racesSelector';
import get from 'lodash/get';

/**
 * @param  {Object}  state
 * @return {number}          Count of combinations
 */
export default function combinationsCountSelector(state) {
    const betSlip = betSlipSelector(state);
    const product = productSelector(state);
    const trackCode = state.AISDataProvider.racingCardData.trackCode;
    const date = state.AISDataProvider.racingCardData.date;
    const raceIndex = state.AISDataProvider.raceIndex;
    const horseCounts = getHorseCountsInRaces(state);

    return betSlip.countCombinations({
        productId: product.id,
        trackCode,
        date,
        raceIndex,
        horseCounts,
    });
}

/**
 * @param  {Object}  state
 * @param  {{ date: string, trackCode: string, productId: ProductId, raceIndex: number, races: AnyRace[] }}
 * @return {number}          Count of combinations
 */
export function combinationsCountBy(state, { productId, trackCode, date, raceIndex, races }) {
    const betSlip = betSlipSelector(state);
    const horseCounts = races.map((race) => get(race, 'horses.length', 0));

    return betSlip.countCombinations({
        productId,
        trackCode,
        date,
        raceIndex,
        horseCounts,
    });
}
