import React from 'react';
import styled from 'styled-components';
import { BACKGROUNDS, COLORS } from 'themes';
import NotificationConductor from 'common/conductors/NotificationConductor';
import Bet from 'common/DataObjects/Bet';
import { useSelector } from 'react-redux';
import { getHorseCountsInRaces } from 'common/selectors/racesSelector';

const BetBtn = styled.div`
    box-sizing: border-box;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    caret-color: transparent;
    font: 900 14px 'Roboto Condensed';
    opacity: ${({ scratched }) => (scratched ? 0.4 : 1)};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    border: ${({ active, reserved }) => (active || reserved ? 0 : '0.5px solid #C6CCDB')};
    color: ${({ active, reserved }) => (active || reserved ? COLORS.white : '#333333')};
    background: ${({ active, reserved, disabled }) => {
        if (active && reserved) {
            return `linear-gradient(45deg, #04a358 0%,#36b575 51%,#207cca 51%,#2989d8 51%,#0091d6 100%);`;
        }
        if (active) {
            return BACKGROUNDS.primary;
        }
        if (disabled) {
            return BACKGROUNDS.lightGrey;
        }
        if (reserved) {
            return BACKGROUNDS.blue;
        }

        return BACKGROUNDS.lightGrey;
    }};
    text-align: center;
    line-height: 30px;
    margin-right: ${({ compact }) => (compact ? '4px' : '12px')};
    transition: background 300ms ease;
    &:hover {
        background: ${({ reserveMode, active }) =>
            reserveMode ? BACKGROUNDS.blue : active ? BACKGROUNDS.primary : '#c6ccdb'};
    }
`;

const ReserveNumber = styled.span`
    display: block;
    background: ${BACKGROUNDS.accentAlt};
    height: 12px;
    width: 12px;
    line-height: 13px;
    border-radius: 10px;
    position: absolute;
    font-size: 8px;
    color: ${COLORS.black};
    transform: translate(21px, -36px);
`;

const BetButton = (props) => {
    const {
        date,
        betSlip,
        raceReserves,
        betValue,
        start,
        product,
        reserveModeEnabled,
        race,
        race: { trackCode, index: raceIndex },
        maximumRaceLength,
    } = props;

    const horseCounts = useSelector(getHorseCountsInRaces);

    const betSlipInput = {
        date,
        trackCode,
        productId: product.id,
        raceIndex,
        horseCounts,
    };
    const betTable = betSlip.getRaceSelection(betSlipInput);
    const bet = new Bet(betTable);
    const reserved = raceReserves.includes(start.startNr);
    const isActive = () => {
        const startNumber = start.startNr;
        return bet.satisfies(startNumber, betValue);
    };

    const toggle = (e) => {
        e.stopPropagation();

        const startNumber = start.startNr;
        const reserved = raceReserves.includes(startNumber);

        if (reserveModeEnabled) {
            !reserved
                ? props.addReserve && props.addReserve(startNumber, raceIndex, trackCode)
                : props.removeReserve && props.removeReserve(startNumber, raceIndex, trackCode);
        } else {
            isActive() ? onDisactivate(betValue) : onActivate(betValue);
        }
    };

    const showNotificationScratchedHorse = (e) => {
        e.stopPropagation();
        NotificationConductor.info('Hesten er udgået');
    };

    const showNotificationRaceStarted = (e) => {
        e.stopPropagation();
        NotificationConductor.info(
            'Det er ikke muligt at spille på dette løb, da det er gået igang'
        );
    };

    const clickHandler = (e) => {
        if (props.raceStarted) {
            return showNotificationRaceStarted(e);
        }

        if (props.scratched && !isActive()) {
            return showNotificationScratchedHorse(e);
        }

        toggle(e);
    };

    const onActivate = (betValue) =>
        props.onBetAdded && props.onBetAdded(race, start.startNr, betValue, trackCode);

    const onDisactivate = (betValue) =>
        props.onBetRemoved && props.onBetRemoved(race, start.startNr, betValue, race.trackCode);
    return (
        <BetBtn
            {...props}
            onClick={clickHandler}
            disabled={props.scratched}
            active={isActive()}
            reserved={reserved}
            compact={maximumRaceLength > 15}
        >
            {start.startNr}
            {reserved && <ReserveNumber>{props.reserveNumber}</ReserveNumber>}
        </BetBtn>
    );
};

export default BetButton;
