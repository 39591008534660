import Coupon from 'features/BetSlip/BettingREST/DataObjects/Coupon';

class WinShowCoupon extends Coupon {
    marks = '';
    programNumber = 0;
    showBet = false;
    winBet = true;
}

export default WinShowCoupon;
