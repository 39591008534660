import { BACKGROUNDS, COLORS as BASE_COLORS } from 'themes';
import React from 'react';
import styled from 'styled-components';

const borderRadius = 27;

const COLORS = {
    ...BASE_COLORS,
    ...{
        accent: '#F6C900',
    },
};

const widths = {
    bottomBar: 59,
};

const bottomBarStyles = `
    box-sizing: border-box;
    box-shadow: 0 0 40px rgba(0,0,0,0.4);
    background: ${COLORS.white};
    height: ${widths.bottomBar}px;
    line-height: ${widths.bottomBar}px;
    border-radius: ${borderRadius}px;
    margin: 0 auto 12px auto;
    top: -101px;
    position: relative;
    width: 90%;

`;

const Wrapper = styled.div`
    background: white;
    height: 100%;
    min-height: ${(props) => {
        const defaultHeight = '250px';
        const height = props.height || defaultHeight;
        return props.containsBorderBar ? height : `calc(${height} - ${widths.bottomBar}px)`;
    }};
`;

const StyledHeader = styled.div`
    width: 100%;
    height: ${(props) => `calc(${props.height || '250px'} - ${widths.bottomBar}px)`};
    border-radius: 0 0 ${borderRadius}px ${borderRadius}px;
    position: relative;
    color: ${COLORS.white};
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background: ${BACKGROUNDS.primary};
`;

const Header = (props) => {
    return (
        <Wrapper {...props}>
            <StyledHeader {...props}>{props.children}</StyledHeader>
        </Wrapper>
    );
};

export default Header;

export { borderRadius, COLORS, bottomBarStyles };
