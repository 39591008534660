import React from 'react';
import { isMobile } from 'react-device-detect';

import RaceButtons from 'features/TrackPage/components/RaceLine/RaceButtons';
import { Label } from 'features/TrackPage/components/styled';
import OverviewCheckbox from './OverviewCheckbox';

import withFixedOnScroll, { WithFixedOnScrollProps } from 'ui/hoc/withFixedOnScroll';
import { RaceButtonsScrollbar } from 'ui/RaceNavigation';

import { byPlatform } from 'utils/platforms';

const RaceButtonsWithFixedOnScroll = withFixedOnScroll(RaceButtons);

const settings: WithFixedOnScrollProps = {
    backgroundColor: '#f0f2f8',
    padding: '10px 0',
    offsetTopCorrection: 0,
    zIndex: 3,
    additionalStyles: { overflow: 'auto', width: '100%' },
};

const RacesRow = ({
    raceLineItems = [],
    legacyRacesData = [],
    legacySetRace = () => {},
    legacySelectedRace = 0,
}) => {
    const showLabels = !isMobile;

    return (
        <>
            {showLabels && <Label>Løb/afd.</Label>}
            {byPlatform(
                <RaceButtonsWithFixedOnScroll
                    {...settings}
                    // @ts-ignore
                    raceLineItems={raceLineItems}
                    legacyRacesData={legacyRacesData}
                    legacySetRace={legacySetRace}
                    legacySelectedRace={legacySelectedRace}
                />,
                <RaceButtonsScrollbar>
                    <RaceButtons
                        raceLineItems={raceLineItems}
                        legacyRacesData={legacyRacesData}
                        legacySetRace={legacySetRace}
                        legacySelectedRace={legacySelectedRace}
                    />
                    <OverviewCheckbox />
                </RaceButtonsScrollbar>
            )}
        </>
    );
};

export default RacesRow;
