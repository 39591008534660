import styled from 'styled-components';
import React from 'react';
import Input from 'ui/controls/Input';
import Button from 'ui/controls/Button';

import getTexts from 'utils/localization';
const t = getTexts();

const Form = styled.div`
    display: flex;
    height: 80px;
    align-items: center;
    flex-wrap: nowrap;
`;

const InputWrapper = styled.div`
    flex-grow: 2;
    > div {
        border-radius: ${(props) => props.borderRadius};
        margin-left: 5px;
        margin-right: 5px;
    }
`;

const SetLimitBtn = styled(Button)`
    border-radius: ${(props) => props.borderRadius};
    font-size: 13px;
    height: 50px;
    width: 90px;
    padding: 0;
`;

const BoldInput = styled(Input)`
    font-weight: bold;
`;

const LimitForm = (props) => (
    <Form>
        <InputWrapper borderRadius={props.borderRadius}>
            <BoldInput
                onChange={props.onChange}
                placeholder={t.depositLimits.setLimitPlaceholder}
                defaultValue={props.initialValue}
                value={props.value}
                type="text"
                pattern="\d*"
            />
        </InputWrapper>
        <SetLimitBtn
            size="x2"
            className="uppercase"
            borderRadius={props.borderRadius}
            onClick={props.submit}
        >
            {t.depositLimits.submit}
        </SetLimitBtn>
    </Form>
);

export default LimitForm;
