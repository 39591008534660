import { ServerResponse } from '../types';

export const handleUnauthorizedRequest = async <O>(
    response: Promise<ServerResponse<O>>,
    silent: Boolean
) => {
    return response.then((responseObject: ServerResponse<O>) => {
        if (responseObject?.statusCode === 401 && !silent) {
            window.dispatchEvent(
                new CustomEvent('SessionExpired', {
                    detail: response,
                })
            );
        }
        return responseObject;
    });
};

export const castErrorToString = (error: string | Error): string => {
    return typeof error === 'string' ? error : Error.toString();
};
