import { ofType } from 'redux-observable';
import { HIDE_MODAL, HIDE_UNWRAPPED_MODAL, SHOW_MODAL } from '../actions/uiActions';
import { ModalLocationSettings as LocationSettingsMobile } from '../../configs/modals';
import { ModalLocationSettings as LocationSettingsDesktop } from '../../configs/desktop-modals';
import { filter, mapTo, withLatestFrom, tap } from 'rxjs/operators';
import { byPlatform, isMobile } from 'utils/platforms';
import { isDev } from 'utils/env';
import { navigateToMitID } from '../../features/Login/helpers';
import persistentStorage from '../storage';

export const createShowModalEpic = (action$, state$, { history }) =>
    action$.pipe(
        ofType(SHOW_MODAL),
        tap((action) => {
            const locationSettings = getLocationSettings(action);

            if (shouldPushOnModalOpened(action, history)) {
                history.push(locationSettings.pathname);
                logHistoryPushOnModalOpened(action);
            }

            if (isFirstTimeLogin(action) && isMobile) {
                navigateToMitID();
            }
        }),
        mapTo({ type: 'NULL_ACTION' })
    );

export const createHideModalEpic = (action$, state$, { history }) =>
    action$.pipe(
        filter((action) => [HIDE_MODAL, HIDE_UNWRAPPED_MODAL].includes(action.type)),
        withLatestFrom(state$),
        tap(([action, state]) => {
            const modalData = getModalData(action, state);

            if (
                isModalOpenedByInitialURL(action, state) &&
                !wasInitialURLEverChanged(state, history)
            ) {
                history.push('/');
                logRedirectToDefaultPageDueToInitialURL(action);
                return;
            }

            if (shouldRedirectOnClose(action, state, history)) {
                history.push(modalData.redirectOnCloseTo);
                logRedirectAfterClose(action);
            }

            if (shouldGoBackOnClose(action, state, history)) {
                history.goBack();
                logWentBack(action);
            }
        }),
        mapTo({ type: 'NULL_ACTION' })
    );

const getLocationSettings = (action) =>
    byPlatform(
        LocationSettingsMobile[action.payload.id],
        LocationSettingsDesktop[action.payload.id]
    ) || {};

const getModalState = (action, state) =>
    state.ui.modals[action.payload.id] || state.ui.unwrappedModals[action.payload.id] || {};

const getModalData = (action, state) => getModalState(action, state).data || {};

const shouldPushOnModalOpened = (action, history) => {
    const locationSettings = getLocationSettings(action);
    return (
        locationSettings &&
        locationSettings.pathname &&
        history.location.pathname !== locationSettings.pathname
    );
};

const shouldRedirectOnClose = (action, state, history) => {
    const modalData = getModalData(action, state);

    return (
        modalData &&
        modalData.redirectOnCloseTo &&
        history.location.pathname !== action.payload.meta.redirectOnCloseTo
    );
};

const cantGoBackDueToBlacklistRule = (action, state) => {
    return (
        getLocationSettings(action).goBackBlacklist &&
        getLocationSettings(action).goBackBlacklist.some(
            ({ pathname }) => pathname === getModalState(action, state).cameFrom
        )
    );
};

const shouldGoBackOnClose = (action, state, history) => {
    const cantGoBackDueToBlacklistRules = cantGoBackDueToBlacklistRule(action, state);

    const modalState = getModalState(action, state);

    const modalInstantlyOpenedByLink =
        modalState &&
        modalState.cameFrom !== undefined &&
        modalState.cameFrom === history.location.pathname;

    if (cantGoBackDueToBlacklistRules && modalState.cameFrom !== undefined) {
        logCantGoBack(action);
    }

    return (
        action.payload.meta &&
        !action.payload.meta.redirectOnCloseTo &&
        !action.payload.meta.preventGoingBack &&
        getLocationSettings(action).goBackOnClose &&
        !cantGoBackDueToBlacklistRules &&
        !modalInstantlyOpenedByLink
    );
};

const isModalOpenedByInitialURL = (action, state) => {
    const modalData = getModalData(action, state);

    return modalData.openedByInitialURL;
};

const wasInitialURLEverChanged = (state, history) =>
    state.ui.initialURL !== history.location.pathname;

// logging functions

const logHistoryPushOnModalOpened = (action) => {
    isDev() &&
        getLocationSettings(action) &&
        console.log(
            `showModal(${action.payload.id}) -> history.push(${
                getLocationSettings(action).pathname
            })`
        );
};

const logRedirectToDefaultPageDueToInitialURL = (action) =>
    isDev() &&
    console.log(
        `closeModal(${action.payload.id}) -> history.push(/) because this modal was opened by initial URL.`
    );

const logRedirectAfterClose = (action) =>
    isDev() &&
    console.log(
        `closeModal(${action.payload.id}) -> history.push(${action.payload.meta.redirectOnCloseTo}) using [redirectOnCloseTo] parameter.`
    );

const logWentBack = (action) =>
    isDev() && console.log(`Went back: closeModal(${action.payload.id}) -> history.goBack()`);

const logCantGoBack = (action) =>
    isDev() &&
    console.log(
        `Won't go back: modal ${action.payload.id} is not supposed to have a redirect back after closing.`
    );

const isFirstTimeLogin = (action) =>
    action?.type === 'SHOW_MODAL' &&
    action?.payload?.id === 'LOGIN' &&
    !persistentStorage.get('isItInitialLogin');
