import { ofType } from 'redux-observable';
import { SESSION_SUCCESS } from '../actions/authActions';
import { mapTo } from 'rxjs/operators';

export const actualizePinHashEpic = (action$) =>
    action$.pipe(
        ofType(SESSION_SUCCESS),
        // tap(() => console.log(api.punter)),
        mapTo({ type: 'NULL_ACTION' })
    );
