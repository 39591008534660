import { useEffect, useState } from 'react';
import useChange from 'common/hooks/useChange';
import { useDispatch, useSelector } from 'react-redux';
import { setSmartLynMode } from 'features/BetSlip/state/actions';
import combinationsCountSelector from 'common/selectors/combinationsCountSelector';

/**
 * @param activateSmartLyn - function from @withSmartLynManager HOC
 */
const useSmartLyn = ({ activateSmartLyn, onSmartLynBetPerformed = () => {} }) => {
    const smartLynMode = useSelector((state) => state.BetSlip.smartLynMode);
    const combinationsCount = useSelector(combinationsCountSelector);

    const dispatch = useDispatch();

    // we need this state to render the receipt. Once the bet is performed
    // we have to reset the smart lyn mode for UI, but the receipt still needs
    // some marker determining that the smart lyn kind of receipt must be shown
    const [isSmartLynBetPerformed, setSmartLynBetPerformed] = useState(false);

    const [smartLynReceipt, setSmartLynReceipt] = useState({});

    const toggleSmartLynMode = () => {
        if (smartLynMode) {
            dispatch(setSmartLynMode(false));
        } else {
            activateSmartLyn();
        }
    };

    useChange(() => {
        if (combinationsCount === 0) {
            setSmartLynMode(false);
        }
    }, [combinationsCount]);

    useEffect(() => {
        isSmartLynBetPerformed && onSmartLynBetPerformed();
    }, [isSmartLynBetPerformed]);

    return {
        smartLynMode,
        setSmartLynReceipt,
        setSmartLynBetPerformed,
        toggleSmartLynMode,
        isSmartLynBetPerformed,
        smartLynReceipt,
    };
};

export default useSmartLyn;
