import betTableSelector from 'common/selectors/betTableSelector';
import raceStartsSelector from 'common/selectors/raceStartsSelector';
import { getTrackFromRacingCard } from 'common/selectors/trackSelector';
import combinationsCountSelector from 'common/selectors/combinationsCountSelector';
import CouponFactory from 'features/BetSlip/BettingREST/DataObjects/CouponFactory';
import productSelector from 'common/selectors/productSelector';
import racesSelector from 'common/selectors/racesSelector';
import betSlipSelector, { getReserves } from 'common/selectors/betSlipSelector';
import { isMultitrackProduct } from 'common/selectors/multipleTrackSetupsSelector';
import { PRODUCT_IDS } from 'configs/products';
import raceDaySelector from 'common/selectors/raceDaySelector';
import { get } from 'lodash';

/**
 * @param  {Object}        state
 * @return {CouponFactory}
 */
export function getCouponFactory(state) {
    if (!state.AISDataProvider.racingCardFetched) {
        console.error(`
            Logic error: getCouponFactory selector must 
            not be used until racingCard is not fetched.
            Ensure your component is not being rendered
            before the racing card data is ready.
        `);
        return new CouponFactory({});
    }
    const date = state.AISDataProvider.racingCardData.date;
    const isMultitrack = isMultitrackProduct(state);
    const track = getTrackFromRacingCard(state);
    const productId = productSelector(state).id;
    const raceDay = raceDaySelector(state);

    return new CouponFactory({
        programNumber: raceDay.programNumber,
        date,
        productId,
        track,
        raceNumber: state.AISDataProvider.selectedRaceNumber,
        combinationNumber: combinationsCountSelector(state),
        bet: betTableSelector(state),
        starts: raceStartsSelector(state),
        racePicks: get(state, ['BetSlip', 'betsByDates', date, track.code, productId], {}),
        races: racesSelector(state),
        betSlip: betSlipSelector(state),
        strictMode:
            productId === PRODUCT_IDS.V64 ||
            productId === PRODUCT_IDS.V65 ||
            productId === PRODUCT_IDS.GS75
                ? state.BetSlip.strictMode
                : false,
        isMultitrack,
        multipleTrackSetups: isMultitrackProduct(state)
            ? raceDay.multipleTrackSetups[
                  Object.keys(raceDay.multipleTrackSetups).find((pId) => pId === productId)
              ]
            : null,
        reserves: getReserves(state),
    });
}
