import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import Bet from 'common/DataObjects/Bet';
import BetPickRow from './BetPickRow';
import BetPickTrio from './BetPickTrio';
import UHorsePick from './UHorsePick';
import { PRODUCT_IDS } from 'configs/products';
import Betslip from 'common/DataObjects/Betslip';
import EmptyBetPickRow from 'features/BetSlip2/components/BetPick/EmptyBetPickRow';

const BetPick = ({
    raceDay,
    trackCode,
    product,
    singleRaceBetPicks,
    isVProduct,
    races,
    race,
    betSlip,
    isMultitrackProduct,
    reserves,
    placeholders = false,
    renderItem,
    ignoreHorseCounts,
}) => {
    const horseCounts = races.map(race => get(race, 'horses.length', 0));

    const isTwin = product.id === PRODUCT_IDS.TV;

    const renderMultilegPickedStarts = () => {
        return races.map((race, raceIndex) => {
            const betSlipInput = {
                date: raceDay.raceDayDate,
                trackCode,
                productId: product.id,
                raceIndex: race.index,
                // horse counts are not useful in some cases, where AISDataProvider is not used, e.g. Spilklub coupons
                horseCounts: ignoreHorseCounts ? undefined : horseCounts,
            };

            const rowLabel = isMultitrackProduct
                ? `${race.hostTrack?.code} / ${raceIndex + 1}`
                : isVProduct
                ? raceIndex + 1
                : race.raceNumber;

            if (betSlip.hasCombinations(betSlipInput)) {
                const betTable = betSlip.getRaceSelection(betSlipInput);
                const singleRaceBetPicks = new Bet(betTable);
                const rowReserves = isMultitrackProduct
                    ? reserves[raceIndex]
                    : reserves[race.index];

                return (
                    <BetPickRow
                        key={`${raceDay.track.code}${raceIndex}`}
                        rowLabel={rowLabel}
                        singleRaceBetPicks={singleRaceBetPicks}
                        isMultitrackProduct={isMultitrackProduct}
                        pickedStarts={retrievePickedStartsVProduct(race, singleRaceBetPicks)}
                        reserves={rowReserves}
                        product={product}
                        isVProduct={isVProduct}
                        race={race}
                        placeholders={placeholders}
                        renderItem={renderItem}
                    />
                );
            }
            return placeholders ? (
                <EmptyBetPickRow
                    key={raceIndex}
                    raceIndex={raceIndex}
                    isMultitrackProduct={isMultitrackProduct}
                    race={race}
                    product={product}
                    isVProduct={isVProduct}
                />
            ) : null;
        });
    };

    const renderSingleRowView = () => (
        <BetPickRow
            rowLabel={isTwin ? '1./2' : race.raceNumber}
            singleRaceBetPicks={singleRaceBetPicks}
            pickedStarts={excludeUHorses(retrievePickedStarts(race))}
            product={product}
            placeholders={placeholders}
        />
    );

    const retrievePickedStartsVProduct = (race, singleRaceBetPicks) => {
        return race.horses.filter(horse => {
            return singleRaceBetPicks.isStartMarked(horse.startNr);
        });
    };
    const retrievePickedStarts = () => {
        return race.horses.filter(horse => {
            return singleRaceBetPicks.isStartMarked(horse.startNr);
        });
    };

    const retrievePickedStartsKomb = place => {
        return race.horses.filter(horse => {
            return singleRaceBetPicks.satisfies(horse.startNr, place);
        });
    };

    const excludeUHorses = starts => {
        if (!isTwin) {
            return starts;
        }

        return starts.filter(start => {
            return (
                singleRaceBetPicks.getPickedStartNumbers().indexOf(start.startNr) !== -1 &&
                singleRaceBetPicks.betTable[start.startNr].indexOf(start.startNr) !== -1
            );
        });
    };

    if (isVProduct) {
        return renderMultilegPickedStarts();
    }

    switch (product.id) {
        case PRODUCT_IDS.T:
            return (
                <BetPickTrio
                    singleRaceBetPicks={singleRaceBetPicks}
                    pickedStarts={retrievePickedStarts(race)}
                    placeholders={placeholders}
                />
            );
        case PRODUCT_IDS.TV:
            return [
                renderSingleRowView(),
                <UHorsePick race={race} singleRaceBetPicks={singleRaceBetPicks} />,
            ];
        case PRODUCT_IDS.K:
            return [
                <BetPickRow
                    key={1}
                    rowLabel={1}
                    singleRaceBetPicks={singleRaceBetPicks}
                    pickedStarts={retrievePickedStartsKomb(1)}
                    product={product}
                    placeholders={placeholders}
                />,
                <BetPickRow
                    key={2}
                    rowLabel={2}
                    singleRaceBetPicks={singleRaceBetPicks}
                    pickedStarts={retrievePickedStartsKomb(2)}
                    product={product}
                />,
            ];
        default:
            return renderSingleRowView();
    }
};

BetPick.propTypes = {
    product: PropTypes.shape({ id: PropTypes.string, name: PropTypes.string }).isRequired,
    trackCode: PropTypes.string,
    races: PropTypes.array.isRequired,
    race: PropTypes.any,
    singleRaceBetPicks: PropTypes.instanceOf(Bet).isRequired,
    betSlip: PropTypes.instanceOf(Betslip).isRequired,
    isMultitrackProduct: PropTypes.bool,
    reserves: PropTypes.any,
    combinationsCount: PropTypes.number,
    placeholders: PropTypes.bool,
    useSeparateMultitrackCodesForBetslip: PropTypes.bool,
    renderItem: PropTypes.func,
    ignoreHorseCounts: PropTypes.bool,
};

export default BetPick;
