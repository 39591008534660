import styled from 'styled-components';
import COLORS from 'themes/dk/colors';

export const DownloadButton = styled.button`
    display: flex;
    flex-direction: row;
    height: 32px;
    line-height: 31px;
    margin: 0 2px;
    cursor: pointer;
    border: 0;
    background: #e3fcf0 0 0 no-repeat padding-box;
    color: ${COLORS.primary};
    border-radius: 5px;
    font-weight: 900;
    text-transform: capitalize;
    font-size: 10px;
    padding-right: 0;
    padding-left: 12px;
`;

export const DownloadButtonLabel = styled.span`
    padding-right: 12px;
`;

export const DownloadButtonIcon = styled.span`
    padding: 0 10px;
    background: #c0efd9 0 0 no-repeat padding-box;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
`;
