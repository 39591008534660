import {
    ADD_ALL_BETS,
    ADD_BET,
    ADD_RESERVE,
    MARK_TRIO_COLUMN,
    PERFORM_BET_ERROR,
    PERFORM_BET_PENDING,
    PERFORM_BET_SUCCESS,
    PREV_AMOUNT,
    REMOVE_BET,
    REMOVE_LAST_RESERVE,
    REMOVE_RESERVE,
    RESET_BET,
    RESET_DERBY_LYN_BET,
    RESET_IRRELEVANT_BETS,
    RESET_RACE_BET,
    SET_DERBY_LYN_BET,
    SMART_LYN_BET,
    SMART_LYN_MODE,
    MERGE_BETSLIP,
    SET_BET_BUDDY_SELECTIONS_MODE,
    RESET_BET_BUDDY_SELECTIONS_MODE,
    STRICT_MODE,
    UNMARK_TRIO_COLUMN,
    MARK_KOMB_COLUMN,
    UNMARK_KOMB_COLUMN,
    RESET_BET_BUDDY_SELECTIONS,
} from './actions';
import { Set, Map, updateIn, getIn, removeIn, setIn, fromJS } from 'immutable';
import moment from 'moment';
import persistentStorage from 'common/storage';
import { PRODUCT_IDS } from 'configs/products';
import { generateRaceKey } from 'features/TrackPage/components/RaceCard/Desktop/VRaceOverview/utils';

export const betsInitialState = {
    betsByDates: {
        [moment().format('YYYY-MM-DD')]: {},
    },
};

export const reservesInitialState = {
    reservesByDates: {
        [moment().format('YYYY-MM-DD')]: {},
    },
};

const betBuddySelectionsInitialState = {
    betBuddySelections: {
        [moment().format('YYYY-MM-DD')]: {},
    },
};

const betBuddyReservesInitialState = {
    betBuddyReserves: {
        [moment().format('YYYY-MM-DD')]: {},
    },
};

export const betBuddySelectionsModeInitialState = {
    betBuddySelectionsMode: {
        status: false,
        externalPool: {},
        target: 'DISABLED',
    },
};

const cookieSettings = { expires: 0.5 }; // setting half a day cookie lifetime

const stateFromStorage = persistentStorage.get('BetSlip', null, betsInitialState);

const initialState = {
    ...betBuddySelectionsInitialState,
    ...betBuddyReservesInitialState,
    performBetPending: false,
    performBetError: null,
    performBetData: {},
    strictMode: false,
    selectedBets: {},
    prevAmount: 0,
    derbyLynBet: null,
    smartLynBet: null,
    smartLynMode: false,
    ...stateFromStorage,
    ...betBuddySelectionsModeInitialState, // selections mode must be reset after reloading
};

const path_ = ({ date, trackId, productId, raceNr }) => {
    return ['betsByDates', date, trackId, productId, raceNr];
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_ALL_BETS: {
            const { date, trackId, productId, raceIndex, betsByDates } = action.payload;

            const key = generateRaceKey(date, trackId, productId, raceIndex);
            const previousSelectionState = state.selectedBets[key]
                ? !state.selectedBets[key]
                : true;
            const newSelectionState = {
                ...state.selectedBets,
                [key]: previousSelectionState,
            };
            let newBetsByDates = updateIn(
                state.betsByDates,
                [date, trackId, productId],
                betValues => {
                    if (betValues) {
                        betValues[raceIndex] = betsByDates[raceIndex];
                    }

                    return betValues;
                }
            );

            return {
                ...state,
                selectedBets: newSelectionState,
                betsByDates: newBetsByDates,
            };
        }
        case MERGE_BETSLIP: {
            const { picks, reserves, betBuddy, betBuddyReserves } = action.payload;

            const update = fromJS(state)
                .mergeDeep({
                    betsByDates: picks || state.betsByDates,
                    reservesByDates: reserves || state.reservesByDates,
                    betBuddySelections: betBuddy || state.betBuddySelections,
                    betBuddyReserves: betBuddyReserves || state.betBuddyReserves,
                })
                .toJS();

            persistentStorage.save({ BetSlip: update }, cookieSettings);

            return update;
        }
        case ADD_BET: {
            const {
                date,
                trackId,
                productId,
                raceNr,
                startNr,
                betValue,
                betBuddy = false,
            } = action.payload;

            const selectionsKey = betBuddy ? 'betBuddySelections' : 'betsByDates';

            const update = {
                ...state,
                [selectionsKey]: updateIn(
                    state[selectionsKey],
                    [date, trackId, productId, raceNr, startNr],
                    betValues => (betValues ? [...new Set([...betValues, betValue])] : [betValue])
                ),
            };

            persistentStorage.save({ BetSlip: update }, cookieSettings);
            return update;
        }
        case REMOVE_BET: {
            const {
                date,
                trackId,
                productId,
                raceNr,
                startNr,
                betValue,
                betBuddy = false,
            } = action.payload;
            const selectionsKey = betBuddy ? 'betBuddySelections' : 'betsByDates';

            const path = [date, trackId, productId, raceNr, startNr];
            let update = updateIn(state[selectionsKey], path, betValues => {
                const betValuesSet = Set(betValues);

                return betValuesSet.contains(betValue)
                    ? betValuesSet.delete(betValue).toArray()
                    : betValuesSet.toArray();
            });

            if (getIn(update, path, []).length === 0) {
                update = removeIn(update, path); // clean up empty row in bet table
            }
            update = { ...state, [selectionsKey]: update };

            persistentStorage.save({ BetSlip: update }, cookieSettings);
            return update;
        }
        case ADD_RESERVE: {
            const { date, trackId, productId, raceNr, startNr, betBuddy = false } = action.payload;

            const reservesKey = betBuddy ? 'betBuddyReserves' : 'reservesByDates';

            const update = updateIn(
                state,
                [reservesKey, date, trackId, productId, raceNr],
                reserves => (reserves ? [...reserves, startNr] : [startNr])
            );

            persistentStorage.save({ BetSlip: update }, cookieSettings);
            return update;
        }
        case REMOVE_RESERVE: {
            const { date, trackId, productId, raceNr, startNr, betBuddy = false } = action.payload;

            const reservesKey = betBuddy ? 'betBuddyReserves' : 'reservesByDates';

            const update = updateIn(
                state,
                [reservesKey, date, trackId, productId, raceNr],
                reserves => Set(reserves).delete(startNr).toArray()
            );

            persistentStorage.save({ BetSlip: update }, cookieSettings);
            return update;
        }
        case REMOVE_LAST_RESERVE: {
            const { date, trackId, productId, raceNr, betBuddy = false } = action.payload;

            const reservesKey = betBuddy ? 'betBuddyReserves' : 'reservesByDates';

            const update = updateIn(
                state,
                [reservesKey, date, trackId, productId, raceNr],
                reserves => reserves.slice(0, reserves.length - 1)
            );

            persistentStorage.save({ BetSlip: update }, cookieSettings);
            return update;
        }
        case MARK_TRIO_COLUMN: {
            const { column, startNumbers } = action.payload;
            const path = path_({ ...action.payload, productId: PRODUCT_IDS.T });
            const currentMarks = Map(getIn(state, path, {}));
            const wholeRowEntries = startNumbers.map(nr => [nr, [column]]);

            const marks = currentMarks.mergeDeepWith(
                (o, n) => [...new Set([...n, ...o, ...n])],
                Object.fromEntries(wholeRowEntries)
            );
            const update = setIn(state, path, marks.toObject());
            persistentStorage.save({ BetSlip: update }, cookieSettings);
            return update;
        }
        case UNMARK_TRIO_COLUMN: {
            const { column } = action.payload;
            const path = path_({ ...action.payload, productId: PRODUCT_IDS.T });
            const currentMarks = Map(getIn(state, path, {}));

            const marks = currentMarks.update(currentMarks => {
                return currentMarks.map(m => Set(m).remove(column).toArray());
            });
            const update = setIn(state, path, marks.toObject());
            persistentStorage.save({ BetSlip: update }, cookieSettings);
            return update;
        }
        case MARK_KOMB_COLUMN: {
            const { column, startNumbers } = action.payload;
            const path = path_({ ...action.payload, productId: PRODUCT_IDS.K });
            const currentMarks = Map(getIn(state, path, {}));
            const wholeRowEntries = startNumbers.map(nr => [nr, [column]]);

            const marks = currentMarks.mergeDeepWith(
                (o, n) => [...new Set([...n, ...o, ...n])],
                Object.fromEntries(wholeRowEntries)
            );
            const update = setIn(state, path, marks.toObject());
            persistentStorage.save({ BetSlip: update }, cookieSettings);
            return update;
        }
        case UNMARK_KOMB_COLUMN: {
            const { column } = action.payload;
            const path = path_({ ...action.payload, productId: PRODUCT_IDS.K });
            const currentMarks = Map(getIn(state, path, {}));

            const marks = currentMarks.update(currentMarks => {
                return currentMarks.map(m => Set(m).remove(column).toArray());
            });
            const update = setIn(state, path, marks.toObject());
            persistentStorage.save({ BetSlip: update }, cookieSettings);
            return update;
        }
        case RESET_IRRELEVANT_BETS: {
            const { date, trackId, productId, pastRaceNumbers } = action.payload;
            let update = { ...state };

            pastRaceNumbers.forEach(pastRaceNumber => {
                const path = [date, trackId, productId, pastRaceNumber];
                if (getIn(state.betsByDates, path, null)) {
                    update = removeIn(update, ['betsByDates', ...path]);
                }
            });
            return update;
        }
        case RESET_RACE_BET: {
            const { date, trackId, productId, raceIndex, betBuddy = false } = action.payload;
            const path = [date, trackId, productId, raceIndex];
            const reservesKey = betBuddy ? 'betBuddySelections' : 'betsByDates';

            let update = { ...state };
            if (getIn(state.betsByDates, path, null)) {
                update = removeIn(update, [reservesKey, ...path]);
            }

            const key = generateRaceKey(date, trackId, productId, raceIndex);
            update.selectedBets = {
                ...update.selectedBets,
                [key]: !update.selectedBets[key],
            };

            persistentStorage.save({ BetSlip: update }, cookieSettings);
            return update;
        }
        case RESET_BET: {
            persistentStorage.remove('BetSlip');
            return {
                ...initialState,
                ...betsInitialState,
                ...reservesInitialState,
                derbyLynBet: state.derbyLynBet,
                smartLynMode: state.smartLynMode, // RESET_BET must not affect smart lyn state
            };
        }
        case RESET_BET_BUDDY_SELECTIONS: {
            return {
                ...state,
                betBuddySelections: {},
                betBuddyReserves: {},
            };
        }
        case PERFORM_BET_PENDING: {
            return { ...state, performBetPending: true };
        }

        case PERFORM_BET_SUCCESS: {
            // const update = removeIn(state, [
            //     'betsByDates',
            //     ...action.payload.path,
            // ]);
            // persistentStorage.save({ BetSlip: update });
            // return update;
            return {
                ...state,
                performBetPending: false,
                performBetData: action.payload,
                strictMode: false,
            };
        }
        case PERFORM_BET_ERROR: {
            return {
                ...state,
                performBetPending: false,
                performBetError: action.payload,
            };
        }
        case STRICT_MODE: {
            return {
                ...state,
                strictMode: action.payload,
            };
        }
        case PREV_AMOUNT: {
            return {
                ...state,
                prevAmount: action.payload,
            };
        }
        case SET_DERBY_LYN_BET: {
            return {
                ...state,
                derbyLynBet: action.payload,
            };
        }
        case RESET_DERBY_LYN_BET: {
            return {
                ...state,
                derbyLynBet: null,
            };
        }
        case SMART_LYN_BET: {
            return {
                ...state,
                smartLynBet: action.payload,
            };
        }
        case SMART_LYN_MODE: {
            return {
                ...state,
                smartLynMode: !!action.payload,
                smartLynBet: !action.payload ? null : state.smartLynBet,
            };
        }
        case SET_BET_BUDDY_SELECTIONS_MODE: {
            const update = {
                ...state,
                betBuddySelectionsMode: action.payload || betBuddySelectionsModeInitialState,
            };

            persistentStorage.save({ BetSlip: update }, cookieSettings);

            return update;
        }
        case RESET_BET_BUDDY_SELECTIONS_MODE: {
            const update = {
                ...state,
                betBuddySelectionsMode: betBuddySelectionsModeInitialState,
            };

            persistentStorage.save({ BetSlip: update }, cookieSettings);

            return update;
        }
        default:
            return { ...state };
    }
};

export default reducer;
