import styled from 'styled-components';
import UIButton from 'ui/controls/Button';
import Text from 'ui/Text';
import Wrapper from 'ui/Wrapper';
import WinIcon from 'features/ScanLogicTerminal/assets/win.png';

const Title = styled(Text)`
    font-size: 32px;
    margin: 20px auto 15px;
`;

const Terms = styled(Text)`
    width: 270px;
    text-align: center;
    font: normal 16px 'Roboto Condensed';
    letter-spacing: 0;
    margin: 0 auto;
    color: #333333;
`;

const Button = styled(UIButton)`
    padding: 10px 20px;
    margin: 0 0 0 10px;
    background: #f5d410;
    text-transform: uppercase;
    width: 247px;
    height: 60px;
    border-radius: 10px;
    box-shadow: 0 0 25px #00000034;
    font: bold 22px/25px 'Roboto Condensed';
    letter-spacing: 0;
    text-align: center;
    color: #333333;
`;

const Row = styled.div`
    display: flex;
    justify-content: center;
    margin: 10px auto;
`;

const Divider = styled.div`
    margin: 5px 0;
    border-bottom: 1px solid #dcdcdc;
    padding: 5px;
`;

const CloseButton = styled.div`
    background: #efefef;
    width: 35px;
    height: 35px;
    border-radius: 35px;
    line-height: 30px;
    text-align: center;
    font-weight: bold;
    position: absolute;
    top: -10px;
    right: -10px;
    font-size: 23px;
    cursor: pointer;
`;

const DerbyBetslipContainer = styled.div`
    min-height: 340px;
    padding: 0 30px;
    overflow: hidden auto;
    max-height: 600px;

    h3 {
        font: bold 22px/14px 'Roboto Condensed';
    }

    .general-race-info p {
        margin: 5px 0 0;
        font: normal 14px/14px 'Roboto Condensed';
        letter-spacing: 0;
        color: #9198aa;

        &:first-child {
            margin-bottom: 10px;
            font: bold 22px/14px 'Roboto Condensed';
            letter-spacing: 0;
            color: #333333;
        }
    }
`;

const GeneralRaceInfo = styled.div`
    p {
        margin: 5px 0 0;
        font: normal 14px/14px 'Roboto Condensed';
        letter-spacing: 0;
        color: #9198aa;

        &:first-child {
            margin-bottom: 10px;
            font: bold 22px/14px 'Roboto Condensed';
            letter-spacing: 0;
            color: #333333;
        }
    }
`;

const DerbyBetsTable = styled.div`
    display: flex;
    flex-direction: column;

    .head {
    }
    .body {
    }
`;

const BetTableHead = styled.div`
    display: flex;
    margin: 5px 0;
    span {
        font: normal 12px/14px 'Roboto Condensed';
        letter-spacing: 0;
        color: #9198aa;
        text-transform: uppercase;
        &:first-child {
            flex-basis: 10%;
        }
        &:not(:first-child) {
            flex-basis: 75%;
        }
        &:last-child {
            flex-basis: 15%;
        }
    }
`;

const BetTableBody = styled.ol`
    margin: 0;
    list-style-position: inside;
    padding: 0 3px;
    .circle {
        display: inline-block;
        height: 25px;
        width: 25px;
        border-radius: 25px;
        line-height: 25px;
        text-align: center;
        margin-left: 5px;
        font-size: 14px;
        background: #eaeaea;
        color: #9198aa;
        margin-top: 3px;
        &.green {
            background: #18aa63;
            color: #fff;
        }
        &.red-background {
            background: #d62c2c;
            color: #fff;
        }
        &.tvilling {
            text-indent: 0;
        }
    }

    li {
        font-size: 16px;
        position: relative;
        p {
            display: inline-block;
            margin: 5px 0;
            &:first-child {
                margin-left: 10px;
                max-width: 80%;
                vertical-align: unset;
            }
            &:last-child {
                width: 30px;
                text-align: center;
                position: absolute;
                right: 20px;
            }
        }
    }
`;

const BetslipList = styled.ol`
    display: flex;
    flex-direction: column;
    max-height: 460px;
    overflow: hidden auto;
    padding: 0 5px;

    &::-webkit-scrollbar-track {
        border-radius: 5px;
    }
    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 5px;
        background-color: #d9d9d9;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: rgba(85, 85, 85, 0.6);
    }

    li {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        .event-info-group p {
            letter-spacing: 0;
            text-align: left;
            margin: 4px 0;

            &:first-child {
                font: bold 20px/16px 'Roboto Condensed';
            }

            &:not(:first-child) {
                font: normal 14px/16px 'Roboto Condensed';
                color: #747474;
            }
        }

        .event-odds-info {
            font: bold 20px/24px 'Roboto Condensed';
            letter-spacing: 0;
        }
    }
`;

const BetInfo = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    p {
        font: bold 16px 'Roboto Condensed';
        letter-spacing: 0;
        flex-basis: 49%;

        &:nth-child(even) {
            text-align: right;
        }
    }
`;

const SystemInfo = styled.div`
    margin: 0 auto;
    color: #747474;
    text-align: center;
    font: normal 14px 'Roboto Condensed';
    letter-spacing: 0;
`;

const ModalContainer = styled(Wrapper)`
    top: 0;
    z-index: 99;
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ visibility }) => `visibility: ${visibility}`};
`;

const TopWinIcon = styled.div`
    background-image: url(${WinIcon});
    background-position: center;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px;
    height: 200px;
`;

export {
    CloseButton,
    Divider,
    Button,
    Row,
    Title,
    Terms,
    SystemInfo,
    BetslipList,
    BetInfo,
    DerbyBetslipContainer,
    GeneralRaceInfo,
    DerbyBetsTable,
    BetTableHead,
    BetTableBody,
    ModalContainer,
    TopWinIcon,
};
