import styled from 'styled-components';
import Text from 'ui/Text';
import Button from 'ui/controls/Button';
import { COLORS } from 'themes';

export const IconWrapper = styled.span`
    width: 24px;
    text-align: center;
    display: inline-block;
`;

export const BatchBettingHistoryItemColText = styled(Text)`
    padding: 5px 0;
    font-size: 12px;
    font-weight: bold;
    a {
        color: ${COLORS.black};
    }
`;

export const StatusBar = styled(Button)`
    width: 100%;
    display: block;
    margin: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    font-size: 14px;
    font-weight: 900;
`;
