import { APP_MODE_DESKTOP, APP_MODE_MOBILE, APP_MODE_TERMINAL } from 'configs/main';
import { isBrowser, isMobile as isMob, isTablet as isTabl, isIPad13 } from 'react-device-detect';

export const IS_TERMINAL = window.APP_MODE === APP_MODE_TERMINAL;
export const isTerminal = IS_TERMINAL;
export const isMobile = isMob;
export const isDesktop = isBrowser && !isTerminal;
export const isTablet = isTabl || isIPad13;

export const byPlatform = (mobileCase, desktopCase, terminalCase, tabletCase) => {
    if (isTablet || isIPad13) {
        // from some of the latest versions IPad Safari is not detected correctly
        // by react-device-detect and needs additional isIPad13 checking
        return tabletCase || mobileCase;
    }

    let result = window.APP_MODE === APP_MODE_MOBILE ? mobileCase : desktopCase;

    if (IS_TERMINAL && terminalCase) {
        result = terminalCase;
    }

    return result;
};

export const forMobile = (content) => (window.APP_MODE === APP_MODE_MOBILE ? content : null);

export const forDesktop = (content) => (window.APP_MODE === APP_MODE_DESKTOP ? content : null);

export const forTerminal = (content) => (IS_TERMINAL ? content : null);

export const omitMobile = (content) => {
    return window.APP_MODE === APP_MODE_DESKTOP || IS_TERMINAL ? content : null;
};

// * CHANNEL_WEB = 0;
// * CHANNEL_TABLET = 7;
// * CHANNEL_MOBILE = 8;
// * CHANNEL_SHOP = 9;
// * CHANNEL_SELF_SERVICE = 6;
// * CHANNEL_APP = 5;
/**
 * for channelLogin
 * @return {number}
 */
export const getChannel = () => {
    if (isTablet) {
        return 7;
    }
    if (isMobile) {
        return 8;
    }
    if (isDesktop) {
        return 0;
    }

    return 0;
};

export const getPlatform = () => {
    if (isTerminal) {
        return 'terminal';
    }
    if (isMobile) {
        return 'mobile';
    }
    if (isTablet) {
        return 'tablet';
    }
    if (isDesktop) {
        return 'desktop';
    }

    return 'unknown';
};
