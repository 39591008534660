import React from 'react';
import useHorseVideos from './useHorseVideos';
import HorseVideosSlider from './HorseVideosSlider';
import { byPlatform } from 'utils/platforms';
import useCalendarDate from '../EventBoard/hooks/useCalendarDate';
import Divider from 'ui/Divider';

const HorseVideos = () => {
    const { date } = useCalendarDate();
    const { videos } = useHorseVideos({ race_date: date });

    return videos?.length ? (
        <>
            <Divider color={'#DFE3ED'} />
            <div style={{ padding: byPlatform('10px 20px', '30px 10px 30px') }}>
                <HorseVideosSlider videos={videos} />
            </div>
        </>
    ) : null;
};

export default HorseVideos;
