import React from 'react';
import { ForgotPassword as ForgotPasswordComp, WithClearSafariPageCache } from '@it25syv/25syv-ui';
import useModals from 'common/hooks/useModals';
import productIcon from 'features/Login/assets/heste-forgot-pass.png';

const ForgotPassword = () => {
    const { hide } = useModals();

    return (
        <ForgotPasswordComp
            closeAction={() => {
                hide('FORGOT_PIN');
            }}
            site={'heste'}
            primaryColor={'#18AA63'}
            productIcon={productIcon}
            isPinLogin
        />
    );
};

export default WithClearSafariPageCache(ForgotPassword);
