import React, { Fragment } from 'react';
import styled from 'styled-components';

import getTexts from 'utils/localization';
const t = getTexts();

const TableRow = styled.div`
    display: flex;
    border-bottom: 1px solid black;
    margin: 10px 28px 5px 10px;
    padding: 0;
    padding-bottom: 8px;
    font-size: 13px;
    text-indent: 10px;
`;

const Col = styled.div`
    width: ${(props) => (props.small ? '35%' : '65%')};
`;

const Next = styled(TableRow)`
    border: 0;
`;

const LimitTable = (props) => {
    const { amount, date, amountChange } = props;

    const formatExpiration = (expiresAt) => {
        return expiresAt || '-';
    };

    return (
        <Fragment>
            <TableRow>
                <Col small>{t.depositLimits.presentAmount}:</Col>
                <Col>{amount}</Col>
            </TableRow>
            <TableRow>
                <Col small>{t.depositLimits.expires}</Col>
                <Col>{formatExpiration(date)}</Col>
            </TableRow>
            <Next>
                <Col small>{t.depositLimits.nextAmount}:</Col>
                <Col>{amountChange > 0 ? amountChange : '-'}</Col>
            </Next>
        </Fragment>
    );
};

export default LimitTable;
