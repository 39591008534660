import React from 'react';
import Wrapper from 'ui/Wrapper';
import RaceRow from 'features/TrackPage/components/RaceCard/Desktop/VRaceOverview/RaceRow';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';

const VGameCard = () => {
    const { races } = useTrackPage();

    return (
        <Wrapper padding="0">
            {races
                ? races.map((race, index) => {
                      return (
                          <RaceRow
                              key={index}
                              race={race}
                              index={index}
                              //raceStarted={props.raceStarted}
                          />
                      );
                  })
                : null}
        </Wrapper>
    );
};

export default VGameCard;
