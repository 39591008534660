import { isActionOf } from 'typesafe-actions';
import moment from 'moment';
import {
    filter,
    switchMap,
    catchError,
    map,
    mapTo,
    takeUntil,
    withLatestFrom,
    tap,
    mergeMap,
    take,
} from 'rxjs/operators';
import { from, of, timer, concat } from 'rxjs';
import { RootEpic } from 'common/epic';
import { ofType } from 'redux-observable';

import {
    fetchGroupsAsync,
    fetchGroupsCreatedByUserAsync,
    getPoolAsync,
    getGroupAsync,
    savePools,
    getSubscriptionAsync,
    fetchSubscriptionsAsync,
    deleteSubscriptions,
    startPoolDataPolling,
    stopPoolDataPolling,
    getResultsAsync,
    getGroupMembersAsync,
    placeBetAsync,
    getPoolDetailsAsync,
    enableSmsNotification,
    setUIProps,
    fetchPublicGroupsAsync,
    updateCouponAsync,
    createCouponDraftAsync,
    addCouponAsync,
    createServerError,
    fetchFilteredGroupsAsync,
} from './actions';
import { deserializePool, deserializeGroupInfo, deserializeGroups } from './serializing';
import {
    SELECT_PRODUCT,
    SELECT_PRODUCT_SHORTCUT,
    SELECT_RACE_DAY,
    SET_AIS_DATA_PROVIDER_INITIALIZING,
    setEditSelectionsInitializing,
    setProduct,
    setRaceDay,
} from '../AISDataProvider/actions';
import { isSpilklubSelectionsMode } from '../../common/selectors/betSlipSelector';
import { SET_BET_BUDDY_SELECTIONS_MODE } from '../BetSlip/state/actions';
import {
    getActiveGroupId,
    getActivePoolId,
    getProductByPool,
    getRaceDayByPool,
    getSelectedPool,
} from './selectors';
import { betBuddySelectionsModeInitialState } from '../BetSlip/state/reducer';
import { setDate } from '../../ui/DatePicker/actions';
import { isMultitrackProduct } from '../../common/selectors/multipleTrackSetupsSelector';
import NotificationConductor from '../../common/conductors/NotificationConductor';

export const createCouponDraftEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(createCouponDraftAsync.request)),
        switchMap(action =>
            from(api.betBuddy.createCouponDraft(action.payload)).pipe(
                switchMap(response => {
                    if (!response.success) {
                        throw createServerError(response);
                    }
                    return concat(
                        of(
                            createCouponDraftAsync.success(response.data),
                            setUIProps({
                                createdPoolHash: response.data.poolHash,
                                activePoolId: response.data.poolId,
                                isPersonalGroup: true,
                            }),
                            getPoolAsync.request(response.data.poolId)
                        )
                    );
                }),
                catchError(serverError =>
                    of(createCouponDraftAsync.failure(serverError)).pipe(
                        tap(() => {
                            NotificationConductor.error(serverError.message);
                        })
                    )
                )
            )
        )
    );

export const fetchGroupsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(fetchGroupsAsync.request)),
        switchMap(action =>
            from(api.betBuddy.getUserGroups(action.payload)).pipe(
                map(response => {
                    if (!response.success) {
                        throw createServerError(response);
                    }
                    return [
                        fetchGroupsAsync.success(
                            deserializeGroups(response.data.groups, action.payload.pageNumber)
                        ),
                        setUIProps({
                            groupsPageNumber: response.data.pageNumber,
                            groupsPageSize: response.data.pageSize,
                            totalGroupsNumber: response.data.totalGroupsNumber,
                        }),
                    ];
                }),
                catchError(message => [of(fetchGroupsAsync.failure(message))])
            )
        ),
        mergeMap(arr => {
            // @ts-ignore
            return of(...arr);
        }),
        catchError(message => of(fetchGroupsAsync.failure(message)))
    );

export const fetchPublicGroupsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(fetchPublicGroupsAsync.request)),
        switchMap(action =>
            from(api.betBuddy.getPublicGroups(action.payload)).pipe(
                switchMap(response => {
                    if (!response.success) {
                        throw createServerError(response);
                    }
                    return of(
                        fetchPublicGroupsAsync.success(
                            deserializeGroups(response.data.groups, action.payload.pageNumber)
                        ),
                        setUIProps({
                            publicGroupsPageNumber: response.data.pageNumber,
                            publicGroupsPageSize: response.data.pageSize,
                            totalPublicGroupsNumber: response.data.totalGroupsNumber,
                        })
                    );
                }),
                catchError(message => of(fetchPublicGroupsAsync.failure(message)))
            )
        )
    );

export const fetchFilteredGroupsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(fetchFilteredGroupsAsync.request)),
        switchMap(action =>
            from(api.betBuddy.getFilteredGroups(action.payload)).pipe(
                switchMap(response => {
                    if (!response.success) {
                        throw createServerError(response);
                    }
                    return of(
                        fetchFilteredGroupsAsync.success(
                            deserializeGroups(response.data.groups, response.data.pageNumber)
                        ),
                        setUIProps({
                            filteredGroupsPageNumber: response.data.pageNumber,
                            filteredGroupsPageSize: response.data.pageSize,
                            totalFilteredGroupsNumber: response.data.totalGroupsNumber,
                        })
                    );
                }),
                catchError(message =>
                    of(fetchFilteredGroupsAsync.failure(message)).pipe(
                        tap(() => console.log(message))
                    )
                )
            )
        )
    );

export const fetchGroupsCreatedByUserEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(fetchGroupsCreatedByUserAsync.request)),
        switchMap(action =>
            from(api.betBuddy.getCaptainGroups(action.payload)).pipe(
                map(response => {
                    if (!response.success) {
                        throw createServerError(response);
                    }
                    return fetchGroupsCreatedByUserAsync.success(
                        deserializeGroups(response.data.groups, action.payload.pageNumber)
                    );
                }),
                catchError(message => of(fetchGroupsCreatedByUserAsync.failure(message)))
            )
        )
    );

export const getGroupEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getGroupAsync.request)),
        switchMap(action =>
            from(api.betBuddy.getGroupInfo(action.payload)).pipe(
                map(response => {
                    if (!response.success) {
                        throw createServerError(response);
                    }
                    return getGroupAsync.success(deserializeGroupInfo(response.data));
                }),
                catchError(error => of(getGroupAsync.failure(error.toString())))
            )
        )
    );

export const getPoolByIdEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getPoolAsync.request)),
        switchMap(action =>
            from(api.betBuddy.getBetInfoById(action.payload)).pipe(
                map(response => {
                    if (!response.success) {
                        throw createServerError(response);
                    }
                    return getPoolAsync.success(deserializePool(response.data));
                }),
                catchError(message => of(getPoolAsync.failure(message)))
            )
        )
    );

export const poolPollingEpic: RootEpic = action$ =>
    action$.pipe(
        filter(isActionOf(startPoolDataPolling)),
        switchMap(action =>
            timer(0, 30 * 1000).pipe(
                takeUntil(action$.pipe(ofType(stopPoolDataPolling))),
                mapTo(getPoolAsync.request(action.payload))
            )
        )
    );

export const savePoolsEpic: RootEpic = action$ =>
    action$.pipe(
        filter(isActionOf(getGroupAsync.success)),
        map(action => {
            const [, activeBets, finishedBets] = action.payload;
            return savePools([...activeBets, ...finishedBets]);
        })
    );

export const saveSubscriptionsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getSubscriptionAsync.request)),
        switchMap(action =>
            from(api.betBuddy.getSubscriptions(action.payload)).pipe(
                map(response => {
                    if (!response.success) {
                        throw createServerError(response);
                    }
                    return getSubscriptionAsync.success(response.data);
                }),
                catchError(message => of(getSubscriptionAsync.failure(message)))
            )
        )
    );

export const getSubscriptionsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(fetchSubscriptionsAsync.request)),
        switchMap(action =>
            from(
                api.betBuddy.fetchSubscriptions(action.payload.groupId, action.payload.userId)
            ).pipe(
                map(response => {
                    if (!response.success) {
                        throw createServerError(response);
                    }
                    return fetchSubscriptionsAsync.success(response.data);
                }),
                catchError(message => of(fetchSubscriptionsAsync.failure(message)))
            )
        )
    );

export const deleteSubscriptionsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(deleteSubscriptions.request)),
        switchMap(action =>
            from(
                api.betBuddy.deleteSubscriptions(
                    action.payload.groupId,
                    action.payload.userId,
                    action.payload.product || ''
                )
            ).pipe(
                map(response => {
                    if (!response.success) {
                        throw createServerError(response);
                    }
                    return deleteSubscriptions.success(response.data);
                }),
                catchError(message => of(deleteSubscriptions.failure(message)))
            )
        )
    );

export const getResultsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getResultsAsync.request)),
        switchMap(action =>
            from(api.betBuddy.getCouponResults(action.payload)).pipe(
                map(response => {
                    if (!response.success) {
                        throw createServerError(response);
                    }
                    return getResultsAsync.success(response.data);
                }),
                catchError(message => of(getResultsAsync.failure(message)))
            )
        )
    );

export const getGroupMembersEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getGroupMembersAsync.request)),
        switchMap(action =>
            from(api.betBuddy.getGroupMembers(action.payload)).pipe(
                map(response => {
                    if (!response.success) {
                        throw createServerError(response);
                    }
                    return getGroupMembersAsync.success(response.data);
                }),
                catchError(message => of(getGroupMembersAsync.failure(message)))
            )
        )
    );

export const updateCouponEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(updateCouponAsync.request)),
        switchMap(action =>
            from(api.betBuddy.updateCouponDraft(action.payload)).pipe(
                map(response => {
                    if (!response.success) {
                        throw createServerError(response);
                    }
                    return updateCouponAsync.success(response.data);
                }),
                catchError(message => of(updateCouponAsync.failure(message)))
            )
        )
    );

export const addCouponEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(addCouponAsync.request)),
        switchMap(action =>
            from(api.betBuddy.addCouponDraft(action.payload)).pipe(
                map(response => {
                    if (!response.success) {
                        throw createServerError(response);
                    }
                    return addCouponAsync.success(response.data);
                }),
                catchError(message => of(addCouponAsync.failure(message)))
            )
        )
    );

export const syncCouponUpdateEpic: RootEpic = (action$, state$) =>
    action$.pipe(
        filter(isActionOf(updateCouponAsync.success)),
        withLatestFrom(state$),
        map(([, state]) => {
            const poolId = getActivePoolId(state);
            return getPoolDetailsAsync.request(poolId);
        })
    );

export const placeBetEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(placeBetAsync.request)),
        switchMap(action =>
            from(api.betBuddy.makeBet(action.payload)).pipe(
                map(response => {
                    if (!response.success) {
                        throw createServerError(response);
                    }
                    return placeBetAsync.success(response.data);
                }),
                catchError(message => of(placeBetAsync.failure(message)))
            )
        )
    );

export const getPoolDetailsEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(getPoolDetailsAsync.request)),
        switchMap(action =>
            from(api.betBuddy.getPoolExtendedInfo(action.payload)).pipe(
                map(response => {
                    if (!response.success) {
                        throw createServerError(response);
                    }
                    return getPoolDetailsAsync.success(response.data);
                }),
                catchError(message => of(getPoolDetailsAsync.failure(message)))
            )
        )
    );

export const saveSmsNotification: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(enableSmsNotification.request)),
        switchMap(action =>
            from(
                api.betBuddy.enableSmsNotification(
                    action.payload.active,
                    action.payload.groupId,
                    action.payload.userId
                )
            ).pipe(
                map(response => {
                    if (!response.success) {
                        throw createServerError(response);
                    }
                    return enableSmsNotification.success(response.data);
                }),
                catchError(message => of(enableSmsNotification.failure(message)))
            )
        )
    );

export const editSelectionsEpic: RootEpic = (action$, state$) =>
    //@ts-ignore
    action$.pipe(
        filter(
            (action: any) => action.type === SET_BET_BUDDY_SELECTIONS_MODE && action.payload.status
        ),
        tap(v => console.log(v)),
        withLatestFrom(state$),
        mergeMap(([, state]) => {
            const poolId = getActivePoolId(state);
            const raceDay = getRaceDayByPool(state, poolId);
            const product = getProductByPool(state, poolId);

            return of(
                setDate(new Date(raceDay.date), {
                    editSelections: true,
                }),
                setRaceDay(raceDay, { editSelections: true }),
                setProduct(product, { editSelections: true })
            );
        })
    );

export const leaveEditSelectionsEpic: RootEpic = (action$, state$) =>
    action$.pipe(
        withLatestFrom(state$),
        filter(
            ([action, state]: [any, any]) =>
                isSpilklubSelectionsMode(state) &&
                [SELECT_PRODUCT, SELECT_RACE_DAY, SELECT_PRODUCT_SHORTCUT].includes(action.type) &&
                (action.meta ? !action.meta.editSelections : true) &&
                !state.AISDataProvider.initializing &&
                !isMultitrackProduct(state)
        ),
        withLatestFrom(state$),
        filter(
            ([, state]) =>
                isSpilklubSelectionsMode(state) &&
                (state.AISDataProvider.selectedProduct.id !==
                    state.BetSlip.betBuddySelectionsMode.externalPool.productId ||
                    state.AISDataProvider.selectedRaceDay.date !==
                        moment(state.BetSlip.betBuddySelectionsMode.externalPool.raceDate).format(
                            'YYYY-MM-DD'
                        ) ||
                    state.AISDataProvider.selectedRaceDay.trackId !==
                        state.BetSlip.betBuddySelectionsMode.externalPool.trackId)
        ),
        mergeMap(() =>
            of(
                {
                    type: SET_BET_BUDDY_SELECTIONS_MODE,
                    payload: betBuddySelectionsModeInitialState.betBuddySelectionsMode,
                },
                setEditSelectionsInitializing(false)
            )
        )
    );

/**
 * Sets appropriate group id into Spilklub ui state in case if
 * some pool id was set in ui state, but group id wasn't.
 * So it synchronizes shown pool in UI with appropriate group.
 * If the pool data is not in the state it will fetch it from API.
 *
 * @param action$
 * @param state$
 * @param api
 */
//@TODO Combine with getPoolByIdEpic to avoid duplicating code and extra request to getBetInfoById
export const syncUIGroupWithPoolEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(
            action =>
                //@ts-ignore
                isActionOf(setUIProps)(action) && action.payload.activePoolId
        ),
        withLatestFrom(state$),
        filter(([, state]) => getActiveGroupId(state) === 0),
        map(([action, state]) => [action, state, getSelectedPool(state)]),
        switchMap(([action, , pool]) =>
            pool
                ? of(pool)
                : from(
                      api.betBuddy.getBetInfoById(
                          //@ts-ignore
                          action.payload.activePoolId
                      )
                  ).pipe(
                      map(response => {
                          if (!response.success) {
                              throw response.errorMessage;
                          }
                          return deserializePool(response.data);
                      }),
                      catchError(message => of(getPoolAsync.failure(message)))
                  )
        ),
        //@ts-ignore
        map(pool => setUIProps({ activeGroupId: pool.groupId }))
    );

export const setProductShortcutEpic: RootEpic = action$ =>
    action$.pipe(
        filter(action => action.type === SELECT_PRODUCT_SHORTCUT),
        switchMap(action =>
            action$.pipe(
                filter(
                    anotherStreamAction =>
                        anotherStreamAction.type === SET_AIS_DATA_PROVIDER_INITIALIZING &&
                        //@ts-ignore
                        anotherStreamAction.payload === true
                ),
                take(1),
                map(() => action)
            )
        )
    );
