import { post } from './RequestHandler';
import {
    PERFORM_BET_API_ENDPOINTS,
    PRODUCTS_BET_NAMES,
    PRODUCTS_SYS_NAMES,
} from 'configs/products';

class BettingREST {
    static performQuickPickBet = (data, product) =>
        post(`perform${PRODUCTS_SYS_NAMES[PRODUCTS_BET_NAMES[product.id]]}QuickPickBet`, data);

    /**
     *
     * @param  {string}           productId
     * @param  {Object}           coupon
     * @return {Promise<Object>}
     */
    static performBet = (productId, coupon) => {
        const method = PERFORM_BET_API_ENDPOINTS[productId];

        if (!method) {
            throw Error(
                `PERFORM_BET_API_ENDPOINTS config [config/products.js] must contain method name for product ${productId}.`
            );
        }

        if (!BettingREST[method]) {
            throw Error(`Implement method in [features/BettingREST] called ${method}.`);
        }

        return BettingREST[method](coupon);
    };

    static performWinShowBet = (winShowCoupon) => post(`performWinShowBet`, winShowCoupon);
    static performQuinellaBet = (quinellaCoupon) => post(`performQuinellaBet`, quinellaCoupon);
    static performTrifectaBet = (trifectaCoupon) => post(`performTrifectaBet`, trifectaCoupon);
    static performKombBet = (kombCoupon) => post(`performKombBet`, kombCoupon);
    static performV4Bet = (vxyCoupon) => post(`performV4Bet`, vxyCoupon);
    static performV5Bet = (vxyCoupon) => post(`performV5Bet`, vxyCoupon);
    static performV64Bet = (vxyCoupon) => post(`performV64Bet`, vxyCoupon);
    static performV65Bet = (vxyCoupon) => post(`performV65Bet`, vxyCoupon);
    static performV75Bet = (vxyCoupon) => post(`performV75Bet`, vxyCoupon);
    static performGS75Bet = (vxyCoupon) => post(`performGS75Bet`, vxyCoupon);
    static performV86Bet = (vxyCoupon) => post(`performV86Bet`, vxyCoupon);
    static performDoubleBet = (doubleCoupon) => post(`performDoubleBet`, doubleCoupon);
}

export default BettingREST;
