import { saveJSONparsing } from 'utils/formatters';
import { get, cloneDeep } from 'lodash';
import {
    PRODUCTS_SYS_NAMES,
    PRODUCTS_BET_NAMES,
    PERFORM_BET_API_ENDPOINTS,
} from 'configs/products';

const getRaceTimeFromReceipt = (receipt: any) => {
    const raceTime = get(receipt, 'coupon.raceTime', null);

    return raceTime;
};

const getAmountFromReceipt = (receipt: any) => {
    let amount = null;
    const terminalAmount = get(receipt, 'coupon.maxTotalBetAmount', null);
    if (typeof terminalAmount === 'number') {
        const isPNPcoupon =
            get(receipt, 'coupon.postTime', false) && get(receipt, 'coupon.trackInfo', false);
        amount = isPNPcoupon ? terminalAmount : terminalAmount / 100;
    }

    return amount;
};

const adjustCouponToSmartLyn = (coupon: any, createSmartLynLegs: () => any) => {
    const adjustedCoupon = cloneDeep(coupon);
    adjustedCoupon.lyn = true;
    adjustedCoupon.legs = createSmartLynLegs();
    adjustedCoupon.maxTotalBetAmount = coupon.combinationPrice;

    /** these field should be removed to have proper amount on managerPC */
    delete adjustedCoupon.combinationPrice;
    delete adjustedCoupon.combinationNumber;

    return JSON.stringify(adjustedCoupon);
};

const getMethodName = (product: any) => {
    const productId = get(product, 'id', '');
    const method = productId ? PERFORM_BET_API_ENDPOINTS[productId] : '';

    return method;
};

const getQuickPickMethodName = (product: any) => {
    const productId = get(product, 'id', '');
    // @ts-ignore
    const productName = productId ? PRODUCTS_SYS_NAMES[PRODUCTS_BET_NAMES[productId]] : '';
    const method = productName ? `perform${productName}QuickPickBet` : '';

    return method;
};

const handleRetailResponse = (
    response: any,
    setPlayAndPayWorkflow: (playAndPayWorkflow: boolean) => void
) => {
    const encodedCoupon = get(response, 'data.coupon.coupon', '');
    let coupon = saveJSONparsing(encodedCoupon, {});
    coupon.raceTime = get(response, 'data.coupon.race_time', null);

    /* in case with PlayAndPay on terminals, we don't have coupon/receipt in response */
    if (response.unpaid_sale) {
        if (typeof setPlayAndPayWorkflow === 'function') {
            setPlayAndPayWorkflow(true);
        }

        coupon = saveJSONparsing(response.unpaid_sale.payload, {});
        coupon.raceTime = coupon.postTime;
    }

    return { coupon };
};

//@TODO: Remove when AISDataProvider is not used anymore
const getPostTimeFromMutitrackLegacy = (data: any) => {
    const { selectedRaceDay, selectedProduct, racingCardData, coupleRacingCardData } = data;

    let time = '-';

    try {
        const firstLeg = selectedRaceDay.multipleTrackSetups[selectedProduct.id].legs[0];

        let races = [];

        if (racingCardData.trackCode === firstLeg.trackId) {
            races = racingCardData.races;
        } else if (coupleRacingCardData.trackCode === firstLeg.trackId) {
            races = coupleRacingCardData.races;
        }

        const firstRace = races.find((race: any) => race.raceNumber === firstLeg.raceNr);

        time = firstRace ? `${selectedRaceDay.date} ${firstRace.postTime}` : '-';
    } catch (e) {
        console.log('getPostTimeFromMutitrack', e);
    }

    return time;
};

export {
    getRaceTimeFromReceipt,
    getAmountFromReceipt,
    adjustCouponToSmartLyn,
    getMethodName,
    getQuickPickMethodName,
    handleRetailResponse,
    getPostTimeFromMutitrackLegacy,
};
