import { useQuery } from '@tanstack/react-query';
import CMSAPI from '../../common/api/CMSAPI';

interface UseHorseVideosProps {
    race_date: string;
    enabled?: boolean;
}

interface QueryParameters {
    race_date: string;
}

interface HorseVideosData {
    races: Array<any>;
}

const fetchHorseVideos = async ({ race_date }: QueryParameters) => {
    const response = await CMSAPI.getHorseVideos({ race_date });

    if (!response?.success) {
        throw new Error(response.msg);
    }

    return response?.data;
};

const initialData = {
    races: [],
} as HorseVideosData;

const useHorseVideosQuery = ({ race_date, enabled = true }: UseHorseVideosProps) => {
    const { status, data, error, isSuccess, isLoading } = useQuery<unknown, Error, HorseVideosData>(
        {
            queryKey: ['horseVideos', race_date],
            queryFn: () => fetchHorseVideos({ race_date }),
            staleTime: 60 * 10 * 1000,
            enabled,
        }
    );
    return { status, data: data ?? initialData, error, isSuccess, isLoading };
};

export default useHorseVideosQuery;
