import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import dankortCredit from 'images/dk/footer/credits/dankort.svg';
import dinersclubCredit from 'images/dk/footer/credits/dinersclub.svg';
import maestroCredit from 'images/dk/footer/credits/maestro.svg';
import mastercardCredit from 'images/dk/footer/credits/mastercard.svg';
import visaCredit from 'images/dk/footer/credits/visa.svg';
import visaECredit from 'images/dk/footer/credits/visa-e.svg';

import logo from 'images/dk/logo-white.png';
import stopSpilled from 'images/dk/footer/stopspillet.svg';
import centerLudomani from 'images/dk/footer/center_ludomani.png';
import rofus from 'images/dk/footer/rofus.png';
import atgIcon from 'images/dk/footer/atg.png';
import footerSpl from 'images/icons/spillemyndigheden.png';

import styled from 'styled-components';
import { COLORS } from 'themes';
import { sidebar } from 'configs/layout';
import { Img } from '@it25syv/25syv-ui';
import { DigitalClock } from '@it25syv/25syv-ui';
import { WithClock } from '@it25syv/25syv-ui';
import { useDispatch } from 'react-redux';
import { showStream } from 'features/StreamVideo/actions';
import { byPlatform } from 'utils/platforms';
import { getUrl } from 'utils/navigation';
const Clock = WithClock(DigitalClock);

const breakpoint = {
    S: `(max-width: 475px)`,
    M: `(max-width: 768px)`,
    L: `(max-width: 1024px)`,
};

const FooterWrapper = styled.footer`
    transition: all 0.4s linear;
    @media screen and (min-width: 1025px) {
        margin-left: ${({ isOpen }) =>
            isOpen ? sidebar.width + 'px' : sidebar.widthClosed + 'px'};
    }
`;

const FooterTop = styled.div`
    width: 100%;
    background: #404040;
    min-height: 220px;
    box-sizing: border-box;
`;

const FooterCentered = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;

    @media screen and ${breakpoint.L} {
        padding-left: 18px;
        padding-right: 18px;
    }
`;

const FooterTopContent = styled(FooterCentered)`
    color: #fff;
    padding-top: 24px;
    padding-bottom: 60px;

    @media screen and ${breakpoint.L} {
        text-align: center;
        padding-bottom: 30px;
    }
`;

const FooterTopMenuWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media screen and ${breakpoint.L} {
        flex-direction: column;
        align-items: center;
    }
`;

const FooterTopMenu = styled.div`
    width: ${props => (props.width ? props.width : 'auto')};
    @media screen and ${breakpoint.L} {
        &:not(.show-on-mobile) {
            display: none;
        }

        width: 100%;
    }
`;

const FooterTopMenuText = styled.p`
    font-size: 15px;
    color: rgba(255, 255, 255, 0.6);
    padding: 0 0 10px;
    margin: 0;

    @media screen and ${breakpoint.L} {
        font-size: 12px;
    }
`;

const FooterTopMenuHeader = styled.h4`
    color: ${COLORS.white};
    font-size: 18px;
    padding: 12px 0;
    margin: 0;
`;

const FooterTopMenuList = styled.ul`
    list-style: disc;
    padding: 0 0 0 14px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;

    @media screen and ${breakpoint.L} {
        list-style: none;
    }
`;

const FooterTopMenuItem = styled.a`
    color: rgba(255, 255, 255, 0.6);
    font-size: 15px;
    text-decoration: none;
    cursor: pointer;
    margin-left: -5px;
    line-height: 22px;
`;

const FooterPaymentWrapper = styled.div`
    background: #333333;
`;

const FooterPaymentCentered = styled(FooterCentered)`
    display: flex;
    min-height: 220px;
    align-items: center;
    justify-content: center;

    @media screen and ${breakpoint.L} {
        padding: 20px 0;
        min-height: 260px;
        max-width: 500px;
        flex-wrap: wrap;
    }

    @media screen and ${breakpoint.M} {
        min-height: 200px;
    }

    @media screen and ${breakpoint.S} {
        min-height: 260px;
    }
`;

const FooterPaymentItem = styled.div`
    width: 157px;
    height: 90px;
    background: #ffffff;
    box-shadow: 0 0 10px #00000033;
    border-radius: 5px;
    margin: 0 20px;
    text-align: center;

    img {
        height: 100%;
    }

    @media screen and ${breakpoint.L} {
        width: 118px;
        height: 68px;
        flex-wrap: wrap;
    }
`;

const FooterProviders = styled.div`
    background: #0e0e0e;
    min-height: 180px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and ${breakpoint.L} {
        display: none;
    }
`;

const FooterProvidersItem = styled.div`
    margin: 0 34px;
`;

const FooterTextBlock = styled.div`
    background: #212121;
    min-height: 180px;
`;

const FooterText = styled(FooterCentered)`
    column-count: 2;
    column-gap: 145px;
    font-size: 16px;
    padding-top: 40px;
    padding-bottom: 40px;
    color: #a6a6a6;

    h3 {
        padding: 0;
        margin: 0 0 20px;
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
    }

    p {
        margin: 0 0 40px;
        display: inline-block;
    }

    a {
        color: #a6a6a6;
        text-decoration: underline;
    }

    @media screen and ${breakpoint.L} {
        column-count: 1;
    }
`;

const FooterDGA = styled.div`
    background: #404040;
    min-height: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and ${breakpoint.L} {
        min-height: 112px;
    }
`;

const FooterDGABlock = styled.div`
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;

    img {
        height: 80px;
        @media screen and ${breakpoint.L} {
            height: 60px;
        }
    }
`;

const FooterDGATitle = styled.div`
    width: 200px;
    margin-left: 10px;
    font-size: 18px;
    color: #fff;
    @media screen and ${breakpoint.L} {
        font-size: 16px;
    }
    & a {
        color: #fff;
        text-decoration: none;
    }
`;

const FooterBottom = styled.div`
    background: #333333;
    padding: 48px 0 60px;

    @media screen and ${breakpoint.L} {
        padding: 28px 0 48px;
    }
`;

const FooterBottomSupport = styled.div`
    display: flex;
    justify-content: space-around;
    @media screen and ${breakpoint.L} {
        flex-direction: column;
    }
`;

const FooterBottomSupportItem = styled.div`
    border-radius: 5px;
    background: #585858;
    width: 33%;
    height: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    img {
        height: 35px;
    }

    @media screen and ${breakpoint.L} {
        width: 100%;
        margin: 0 0 17px;
        justify-content: space-between;
        padding: 0 20px;
    }
`;

const FooterBottomSupportBtn = styled.a`
    padding: 0 15px;
    height: 35px;
    line-height: 35px;
    background: rgba(256, 256, 256, 0.25);
    color: #ffffff;
    text-decoration: none;
    border-radius: 5px;
    margin: 0 0 0 15px;
    font-size: 15px;
    font-weight: bold;

    @media screen and ${breakpoint.L} {
        width: 165px;
        text-align: center;
        font-size: 14px;
        box-sizing: border-box;
    }
`;

const FooterBottomCopyright = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0 0;
    @media screen and ${breakpoint.L} {
        flex-direction: column;
        text-align: center;
    }
`;

const FooterBottomCopyrightItem = styled.div`
    width: 220px;
    color: #adadad;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and ${breakpoint.L} {
        width: 100%;
        margin: 6px 0 0;
        font-size: 12px;
    }
`;

const FooterBottomAgeRestrictItem = styled(FooterBottomCopyrightItem)`
    text-align: right;
    @media screen and ${breakpoint.L} {
        font-size: 14px;
        text-align: left;
        margin: 20px 0 0;
    }
`;

const FooterBottomAgeRestrict = styled.div`
    min-width: 35px;
    width: 35px;
    height: 35px;
    background: #fff;
    font-size: 15px;
    line-height: 35px;
    font-weight: bold;
    text-align: center;
    color: #333;
    border-radius: 50%;
    margin: 0 8px 0 0;
`;

const FooterClockDesktop = styled.div`
    color: #fff;
    font-size: 24px;
    @media screen and ${breakpoint.L} {
        display: none;
    }
`;

const FooterClockMobile = styled(FooterClockDesktop)`
    display: none;
    @media screen and ${breakpoint.L} {
        display: block;
    }
`;

const getMenuItemsConfig = (handlers = {}) => [
    // Information
    {
        section: 'Information',
        showOnMobile: true,
        items: [
            {
                title: 'Vilkår og betingelser',
                path: 'https://intercom.help/25syv/da/articles/3493016-vilkar-og-betingelser',
                blank: true,
            },
            {
                title: 'Cookiepolitik',
                onClick: handlers.cookiesHandler,
            },
            {
                title: 'Ansvarligt spil',
                path: 'https://intercom.help/25syv/da/articles/3493600-ansvarligt-spil-25syv ', //add link
                blank: true,
            },
            {
                title: 'Spilleregler',
                path: 'https://intercom.help/25syv/da/articles/3493018-spilleregler-for-derby25', //add link
                blank: true,
            },
            {
                title: 'Persondata politik',
                path: 'https://intercom.help/25syv/da/articles/3870767-persondatapolitik-for-25syv-a-s ', //add link
                blank: true,
            },
            {
                title: 'Kalaallisut',
                path: 'https://intercom.help/25syv/da/articles/4425281-kalaallisut',
                blank: true,
            },
        ],
    },
    {
        section: 'Vigtige punkter',
        showOnMobile: false,
        items: [
            {
                title: 'Lynspil',
                path: '/derby-lyn',
            },
            {
                title: 'Resultat',
                path: '/resultat',
            },
            {
                title: '25-nyt',
                path: '/25-nyt',
            },
            {
                title: 'Stream',
                path: '/live-stream',
            },
            {
                title: 'Sådan spiller du',
                path: 'https://bet25.dk/heste/spilguide',
                blank: true,
            },
        ],
    },
    {
        section: 'Quick links',
        showOnMobile: false,
        items: [
            {
                title: 'Bet 25 Casino',
                path: 'https://bet25.dk/casino',
            },
            {
                title: 'Bet25 Sport',
                path: 'https://bet25.dk/sport',
            },
        ],
    },
    {
        section: 'Kundeservice',
        showOnMobile: false,
        items: [
            {
                title: 'Udbetaling',
                path: 'https://intercom.help/25syv/da/collections/559324-indbetaling-udbetaling',
                blank: true,
            },
            {
                title: 'Konto verificering',
                path: 'https://intercom.help/25syv/da/collections/559314-min-25syv-konto-rod25-bet25-derby25',
                blank: true,
            },
        ],
    },
];

const Footer = props => {
    const dispatch = useDispatch();

    const showLiveStream = () => {
        dispatch(showStream());
    };

    const cookiesHandler = () => {
        return window.CookieConsent ? window.CookieConsent.renew() : null;
    };

    const menuConfig = getMenuItemsConfig({ cookiesHandler, showLiveStream });

    return (
        <FooterWrapper isOpen={props.isOpen}>
            <FooterTop>
                <FooterTopContent>
                    <Img
                        style={{
                            height: '35px',
                        }}
                        src={logo}
                    />
                    <FooterTopMenuWrapper>
                        <FooterTopMenu width={'265px'} className={'show-on-mobile'}>
                            <FooterTopMenuHeader>Udbydes af 25syv</FooterTopMenuHeader>
                            <FooterTopMenuText>
                                Bet25 heste drives af 25syv A/S. Mariane Thomsens Gade, 8000 Aarhus
                                C.
                            </FooterTopMenuText>
                        </FooterTopMenu>
                        {menuConfig.map((section, index) => (
                            <FooterTopMenu
                                className={section.showOnMobile && 'show-on-mobile'}
                                key={index}
                            >
                                <FooterTopMenuHeader>
                                    {menuConfig[index].section}
                                </FooterTopMenuHeader>
                                <FooterTopMenuList>
                                    {menuConfig[index].items.map((item, ind) => (
                                        <li key={ind}>
                                            <FooterTopMenuItem
                                                href={item.path ? getUrl(item.path) || null : null}
                                                target={item.blank ? '_blank' : ''}
                                                onClick={item.onClick || null}
                                                {...item.props}
                                            >
                                                {item.title}
                                            </FooterTopMenuItem>
                                        </li>
                                    ))}
                                </FooterTopMenuList>
                            </FooterTopMenu>
                        ))}
                    </FooterTopMenuWrapper>
                </FooterTopContent>
            </FooterTop>
            <FooterPaymentWrapper>
                <FooterPaymentCentered>
                    <FooterPaymentItem>
                        <img src={dankortCredit} alt="Dankort" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={visaCredit} alt="Visa" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={visaECredit} alt="Visa Electron" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={mastercardCredit} alt="Master Card" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={maestroCredit} alt="Maestro" />
                    </FooterPaymentItem>
                    <FooterPaymentItem>
                        <img src={dinersclubCredit} alt="Dinersclub" />
                    </FooterPaymentItem>
                </FooterPaymentCentered>
            </FooterPaymentWrapper>
            <FooterProviders>
                <FooterProvidersItem>
                    <img src={atgIcon} height={'48px'} alt="ATG" />
                </FooterProvidersItem>
            </FooterProviders>
            <FooterTextBlock>
                <FooterText>
                    <h3>Spil på hestevæddeløb</h3>
                    <p>
                        Bet25 heste er hele Danmarks millionspil på heste. Her kan du spille på både
                        trav og galop. Det er muligt at spille på alle de populære spilleformer som
                        vinder, plads, tvilling, trio, dagens dubbel, samt alle V-løb, som
                        eksempelvis V75 og V86 og der kan spilles ind til løb fra lande som Danmark,
                        Sverige, Norge og Australien - og flere kommer løbende til. Så hvis du kan
                        lide at spille på heste og allerede er en uddannet spiller, eller hvis du
                        blot er interesseret i at prøve det af, så er du i den grad kommet til det
                        rette sted.
                    </p>
                    <h3>Både for garvede og nye spillere</h3>
                    <p style={{ breakInside: 'avoid' }}>
                        Spil på hestevæddeløb kan ved første øjekast fremstå både svært og
                        komplekst, men der er faktisk flere muligheder for novicer, der stifter
                        bekendtskab med spillet for første gang.{' '}
                        <Link to={'/derby-lyn'}>En af dem er Lynspil.</Link> Med har det aldrig
                        været nemmere, at spille på trav og galop. Med bare tre klik kan du placere
                        dit lynspil og være med i kampen om store millionpuljer på blandt andet V75.
                        Når du spiller Lynspil vælges der automatisk heste for dig, og du kan blot
                        læne dig tilbage og nyde spændingen. Indsatsen starter helt nede ved 48 kr.
                        Ønsker du at øge dine vinderchancer, så er Bet25 hestes koncept for
                        andelsspil en mulighed.{' '}
                        <Link to="/spil-klub25">Konceptet kaldes SpilKlub25</Link> og er i ordets
                        bedste forstand en spilklub, hvor spilleren får mulighed for at følge venner
                        og bekendte eller en af de udvalgte eksperter og tage andel i deres spil.
                        For når man spiller sammen med andre bliver chancen for gevinst større.
                        Skulle du mangle inspiration til dit spil eller vil du have indblik i
                        aktuelle kampagner, kan du kaste din opmærksomhed over{' '}
                        <Link to="/25-nyt">‘25-nyt’</Link>, hvor der løbende publiceres spiltips fra
                        nogle af de skarpeste i branchen, samt relevante nyheder og tilbud.
                    </p>
                    <h3>Livestreaming af trav og galop - også på appen</h3>
                    <p>
                        Som spiller hos Bet25 heste har du adgang til utallige timers{' '}
                        {byPlatform(
                            'livestreaming',
                            <Link to={'/live-stream'}>livestreaming</Link>
                        )}{' '}
                        hver eneste dag. Der er gratis adgang til både danske løb, via Bet25 Live,
                        samt de svenske løb på ATG Nordic-kanalen. Begge kanaler leverer desuden
                        kyndige spiltips og grundige analyser. Skulle du gå glip af hvem der vandt
                        et løb eller vil du se hestens form an inden du sætter dit spil har du
                        mulighed for at se eller gense et løb i vores{' '}
                        <Link to="/replays">løbsarkiv</Link>. Livestream og replays finder du i
                        øvrigt også på <Link to="/25-nyt/derby25-appen">Bet25 heste-appen</Link>,
                        der er den første danske app til spil på hestevæddeløb. Med appen har du
                        altid spillet lige ved hånden eller i lommen og du kan hurtigt og simpelt
                        placere dine spil.
                    </p>
                    <h3>Kyndig dansk kundeservice</h3>
                    <p>
                        Skulle du have spørgsmål eller brug for hjælp har Bet25 heste selvfølgelig
                        dansk kundeservice, hvor vi altid yder vores ypperste for at hjælpe dig så
                        godt som muligt, og vi vil med glæde hjælpe og assistere dig på bedst mulig
                        vis.
                    </p>
                </FooterText>
            </FooterTextBlock>
            <FooterDGA>
                <FooterDGABlock>
                    <a
                        href="https://www.spillemyndigheden.dk/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={footerSpl}
                            alt="Tilladelse fra og under tilsyn af Spillemyndigheden."
                        />
                    </a>
                    <FooterDGATitle>
                        <a
                            href="https://www.spillemyndigheden.dk/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Tilladelse fra og under tilsyn af Spillemyndigheden.
                        </a>
                    </FooterDGATitle>
                </FooterDGABlock>
            </FooterDGA>

            <FooterBottom>
                <FooterCentered>
                    <FooterBottomSupport>
                        <FooterBottomSupportItem>
                            <a href="https://www.stopspillet.dk/" target={'_blank'}>
                                <img src={stopSpilled} alt="" />
                            </a>
                            <FooterBottomSupportBtn
                                href="tel:70-22-28-25"
                                className="footerBottomSupportBtn"
                            >
                                Ring til 70 22 28 25
                            </FooterBottomSupportBtn>
                        </FooterBottomSupportItem>
                        <FooterBottomSupportItem>
                            <a href="https://ludomani.dk/" target={'_blank'}>
                                <img src={centerLudomani} alt="" />
                            </a>
                            <FooterBottomSupportBtn href="https://ludomani.dk/" target={'_blank'}>
                                Test dig selv
                            </FooterBottomSupportBtn>
                        </FooterBottomSupportItem>
                        <FooterBottomSupportItem>
                            <a href="https://www.rofus.nu/" target={'_blank'}>
                                <img src={rofus} alt="" />
                            </a>
                            <FooterBottomSupportBtn href="https://www.rofus.nu/" target={'_blank'}>
                                Opret registrering
                            </FooterBottomSupportBtn>
                        </FooterBottomSupportItem>
                    </FooterBottomSupport>
                    <FooterBottomCopyright>
                        <FooterClockMobile>{<Clock />}</FooterClockMobile>
                        <FooterBottomCopyrightItem>
                            © {moment().format('YYYY')} 25syv A/S. <br />
                            Alle rettigheder forbeholdes.
                        </FooterBottomCopyrightItem>
                        <FooterClockDesktop>{<Clock />}</FooterClockDesktop>
                        <FooterBottomAgeRestrictItem>
                            <FooterBottomAgeRestrict>18+</FooterBottomAgeRestrict>
                            Spil kan være vanedannende, spil altid med omtanke.
                        </FooterBottomAgeRestrictItem>
                    </FooterBottomCopyright>
                </FooterCentered>
            </FooterBottom>
        </FooterWrapper>
    );
};
export default Footer;
