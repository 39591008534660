import React from 'react';
import { isMobile } from 'react-device-detect';

import useURLParameters from 'features/TrackPage/hooks/useURLParameters';
import { TrackLabel } from 'features/TrackPage/components/styled';
import {
    findCoupleTrack,
    ProductId,
    ProductName,
    RaceDay,
} from 'features/EventBoard/server/calendar';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';

import TrackButtons from './TrackButtons';

import { generateEventPath, history } from 'utils/navigation';
import { PRODUCT_IDS } from 'configs/products';

const ProductsRow = () => {
    const { date, race: currentRace, product } = useURLParameters();
    const { isMultitrack, raceDay: currentRaceDay, racesLoading } = useTrackPage();

    const showLabels = !isMobile;

    const handleSelectTrack = (raceDay: RaceDay) => {
        const productId = PRODUCT_IDS[product as ProductName] as ProductId;
        const coupleTrack = findCoupleTrack(currentRaceDay, productId);

        const isSameTrack = currentRaceDay.trackId === raceDay.trackId;

        if (isSameTrack || racesLoading) {
            return;
        }

        const isSameMultitrack = isMultitrack && raceDay.trackId === coupleTrack?.trackId;

        const productToNavigate = isSameMultitrack ? productId : PRODUCT_IDS.V;

        const race = isSameMultitrack ? currentRace : 1;

        history.push(
            generateEventPath({
                productId: productToNavigate,
                date,
                track: raceDay.trackName,
                race,
            })
        );
    };

    return (
        <>
            {showLabels && <TrackLabel>Bane</TrackLabel>}

            <TrackButtons handleSelectTrack={handleSelectTrack} />
        </>
    );
};

export default ProductsRow;
