import { CMS_API_FEATURE_FLAGS, get, post } from './RequestHandler';

export default {
    createFeedback: (mobileFeedback, desktopFeedback) =>
        post(
            '/derby/rest/feedback/create-feedback',
            mobileFeedback ? { ...mobileFeedback } : { ...desktopFeedback }
        ),
    wasFeedbackSent: (userId) =>
        get('/derby/rest/feedback/was-feedback-sent', {
            user_id: userId,
        }),
    getArticle: (params) =>
        get('/derby/rest/article/get-article', { ...params }),
    getArticles: (type) => get('/derby/rest/article/get-articles', { type }),
    addCampaignSubscriber: (params) =>
        post('/derby/rest/article/add-campaign-subscriber', params),
    getHighlights: (area, platform) =>
        get('/derby/rest/highlight/get-all', { area, platform }),
    addSubscriber: (email) =>
        post('/derby/rest/common/add-subscriber', { email }),
    checkPdfAvailability: (params) =>
        get('/derby/rest/common/download-pdf', params),
    checkUserCampaignSubscribeStatus: (params) =>
        get('/derby/rest/article/check-subscriber-status', params),
    getHorseTips: (params) => get('/derby/rest/tracks/get-tracks-tips', params),
    getHorseVideos: (params) =>
        get('/derby/rest/tracks/get-tracks-videos', params),
    newMobileBet: (params) => post('/cafe/rest/mobile-bet/new-bet', params),
    getMobileBets: (params) => post('/cafe/rest/mobile-bet/get-bets', params),
    deleteMobileBet: (params) =>
        get('/cafe/rest/mobile-bet/delete-bet', params),

    registerDevice: (params) => post('/rest/register-device', params),
    attachUser: (params) => post('/rest/attach-user', params),
    lastLogin: (params) => post('/rest/last-login', params),
    createHash: (params) => post('/rest/create-hash', params),
    getHash: (params) => get('/rest/get-hash', params),
    getExperts: (params) => get('/derby/rest/page/get-experts-page', params),
    getSpilId: (params) =>
        post(
            '/cafe/rest/game-card/get-card',
            params,
            CMS_API_FEATURE_FLAGS.SPIL_ID
        ),
    createSpilId: (params) =>
        post(
            '/cafe/rest/game-card/create-card',
            params,
            CMS_API_FEATURE_FLAGS.SPIL_ID
        ),
    orderSpilIdPhysicalCard: (params) =>
        post(
            '/cafe/rest/game-card/physical-card-request',
            params,
            CMS_API_FEATURE_FLAGS.SPIL_ID
        ),
};
