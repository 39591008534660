import React from 'react';
import {
    SuccessPopupContainer,
    SuccessPopupFaded,
    SuccessPopupText,
    Title,
} from 'features/SpilID/styled';
import Button from 'ui/controls/Button';

import { ReactComponent as CompleteImage } from './assets/checkmark.circle.svg';

const SuccessPopup = ({ shown, backToSpilIdRoot }) => {
    return (
        <SuccessPopupFaded shown={shown}>
            <SuccessPopupContainer shown={shown} className="flex flex-col space-between">
                <Title className="text-center">Spil ID kort bestilt</Title>

                <div className="flex justify-evenly fade-in">
                    <CompleteImage />
                    <SuccessPopupText>
                        Dit Spil ID kort er på vej og leveres indenfor 7 dage.
                    </SuccessPopupText>
                </div>

                <Button
                    onClick={backToSpilIdRoot}
                    size="x2"
                    className="full-width text-bold"
                    accentAlt
                >
                    OK
                </Button>
            </SuccessPopupContainer>
        </SuccessPopupFaded>
    );
};

export default SuccessPopup;
