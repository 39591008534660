import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row, Row as StatsRow } from 'react-grid-system';
import styled from 'styled-components';
import { COLORS } from 'themes';
import ShoeInfo from 'features/TrackPage/components/RaceCard/Desktop/ShoeInfo.jsx';
import moment from 'moment';
import playIcon from 'images/icons/play.png';
import Icon from 'ui/Icon';
import DerbyVisionAPI from 'common/api/DerbyVisionAPI';
import { byPlatform } from 'utils/platforms';
import { ShoeCol } from 'features/Game/styled';

const Head = styled(Row)`
    background: ${COLORS.black};
    color: ${COLORS.white};
    text-transform: uppercase;
    height: 11px;
    line-height: 13px;
    padding-top: 6px;
    padding-bottom: 6px;
`;

const StyledCol = styled(Col)`
    font-size: 9px;
    padding-left: 10px !important;
    padding-right: 10px !important;
`;

const ColCentered = styled(StyledCol)`
    text-align: center;
`;

const HeadCol = styled(ColCentered)`
    text-align: left;
    font-size: 11px;
    font-weight: 600;
    padding-left: 7px !important;
    padding-right: 7px !important;
`;

const StatsCol = styled(Col)`
    font-size: 14px;
    text-align: left;
    font-weight: 600;
    color: ${COLORS.black};
    padding: 8px 7px !important;
`;

const StatsColCentered = styled(StyledCol)`
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const StatsWrapper = styled.div`
    max-height: ${({ isShown, heightRatio, noData }) =>
        isShown ? `calc(${noData ? 1 : heightRatio} * 70px)` : 0};
    opacity: ${({ isShown }) => (isShown ? 1 : 0)};
    // 70px is an approximate
    // height of a single row
    height: auto;
    transition: all 0.5s ease-in-out;
    transform-origin: top;
    visibility: ${props => (props.isShown ? 'visible' : 'hidden')};
`;

const Placeholder = styled.div`
    text-align: center;
    height: 70px;
    line-height: 70px;
    color: ${COLORS.primaryText};
`;

const StatsTableHeader = ({ isGallop }) => (
    <Head style={{ margin: 0, paddingLeft: '45px', paddingRight: '45px' }}>
        <HeadCol xs={2}>Dato/bane</HeadCol>
        <HeadCol xs={1}>Start nr.</HeadCol>
        <HeadCol xs={1}>Distance</HeadCol>
        <HeadCol xs={2}>Kusk</HeadCol>
        <HeadCol xs={1}>Plac</HeadCol>
        <HeadCol xs={1}>{isGallop ? 'Underlag' : 'Tid'}</HeadCol>
        <HeadCol xs={1}>{isGallop ? 'Vægt' : 'Sko'}</HeadCol>
        <HeadCol xs={1}>ODDS</HeadCol>
        <HeadCol xs={1}>1.præmie</HeadCol>
        {byPlatform(
            <HeadCol xs={1}>Gense løb</HeadCol>,
            <HeadCol xs={1}>Gense løb</HeadCol>,
            <div />
        )}
    </Head>
);

const Stats = ({ start, isGallop, replayLinks, showReplayPopup }) =>
    start.horseStat.pastPerformances?.resultRows
        ? start.horseStat.pastPerformances.resultRows.map((stats, index) => (
              <StatsData
                  key={index}
                  stats={stats}
                  isGallop={isGallop}
                  replayLink={replayLinks && replayLinks[index] ? replayLinks[index] : null}
                  showReplayPopup={
                      replayLinks && replayLinks[index]
                          ? () =>
                                showReplayPopup({
                                    replayLink: replayLinks[index],
                                })
                          : undefined
                  }
              />
          ))
        : null;

const StatsData = ({ stats, isGallop, replayLink, showReplayPopup }) => {
    const replayColumn = (
        <StatsColCentered xs={1}>
            <Icon
                src={playIcon}
                color="black"
                style={{
                    width: '17px',
                    height: '17px',
                    cursor: 'pointer',
                    opacity: replayLink ? 0.7 : 0.3,
                    transition: 'opacity 500ms ease-in',
                }}
                onClick={showReplayPopup}
            />
        </StatsColCentered>
    );
    return (
        <StatsRow style={{ margin: 0 }}>
            {/* Date / track */}
            <StatsCol xs={2}>
                {moment(stats.raceKey.date).format('DD.MM.Y')} {stats.raceKey.track.domesticText}
            </StatsCol>
            {/*  Start number */}
            <StatsCol xs={1}>Nr. {stats.startNr}</StatsCol>
            {/* Distance */}
            <StatsCol xs={1}>{stats.circumstances.distance} m</StatsCol>
            {/* Jockey */}
            <StatsCol xs={2}>{stats.driver.name + ' ' + stats.driver.surName}</StatsCol>
            {/* Place taken in the race */}
            <StatsCol xs={1}>
                {stats.placeInfo.place > 0
                    ? 'Nr. ' + stats.placeInfo.place
                    : stats.placeInfo.disqualified
                    ? 'd'
                    : 0}
            </StatsCol>
            {/* Formatted time OR Surface */}
            <StatsCol xs={1}>
                {!isGallop
                    ? stats.formattedTime
                    : stats.gallopInfo.trackSurface
                    ? stats.gallopInfo.trackSurface.domesticText
                    : '-'}
            </StatsCol>
            {/* Weight OR Shoe */}
            <StatsCol xs={1}>
                {isGallop ? (
                    stats.gallopInfo ? (
                        stats.gallopInfo.weight
                    ) : (
                        '-'
                    )
                ) : stats.circumstances && stats.circumstances.shoeInfo ? (
                    <ShoeCol>
                        <ShoeInfo
                            frontShoe={stats.circumstances.shoeInfo.foreShoes}
                            backShoe={stats.circumstances.shoeInfo.hindShoes}
                        />
                    </ShoeCol>
                ) : (
                    <span>--</span>
                )}
            </StatsCol>
            <StatsCol xs={1}>
                {stats.odds > 0 ? parseFloat(stats.odds / 10).toFixed(2) : <span>--</span>}
            </StatsCol>
            <StatsCol xs={1}>{stats.firstPrize.sum} kr.</StatsCol>
            {byPlatform(replayColumn, replayColumn, <div />)}
        </StatsRow>
    );
};

const DetailedStatisticTable = ({
    start,
    gallop: isGallop,
    isShown,
    onAnimationFinished = () => {},
    showReplayPopup,
    useExpandedProp = false,
    expanded = false,
}) => {
    const noData = start.horseStat.pastPerformances?.resultRows?.length <= 0;

    const [replayLinks, setReplayLinks] = useState([]);

    useEffect(() => {
        if (useExpandedProp && !expanded) {
            return;
        }

        DerbyVisionAPI.getUrl(start.horseStat.pastPerformances?.resultRows).then(replayLinks =>
            setReplayLinks(replayLinks)
        );
    }, [useExpandedProp, expanded]);

    return (
        <StatsWrapper
            isShown={isShown}
            onTransitionEnd={onAnimationFinished}
            heightRatio={start.horseStat.pastPerformances?.resultRows.length}
            noData={noData}
        >
            {!noData ? (
                <Fragment>
                    <StatsTableHeader start={start} isGallop={isGallop} />
                    <div
                        style={{
                            paddingBottom: '15px',
                            paddingLeft: '45px',
                            paddingRight: '45px',
                        }}
                    >
                        <Stats
                            start={start}
                            isGallop={isGallop}
                            replayLinks={replayLinks}
                            showReplayPopup={showReplayPopup}
                        />
                    </div>
                </Fragment>
            ) : (
                <Placeholder>No data</Placeholder>
            )}
        </StatsWrapper>
    );
};

export default DetailedStatisticTable;
