import React, { lazy } from 'react';
import FlexWrapper from 'ui/FlexWrapper';
import Wrapper from 'ui/Wrapper';
import Button from 'ui/controls/Button';
import ActiveBets from './ActiveBets';
import FinishedBets from './FinishedBets';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import getTexts from 'utils/localization';
import InfoMessage from './InfoMessageBage/InfoMessage';
import RetailBets from './RetailBets';
const t = getTexts();

export const HISTORY_TYPE_ACTIVE = 'aktive';

export const HISTORY_TYPE_FINISHED = 'afsluttede';

export const HISTORY_TYPE_RETAIL = 'butik';

class History extends React.Component {
    navigateToTab(tab) {
        this.props.history.push(`/mine-spil/${tab}`);
    }

    render() {
        const btnProps = {
            style: {
                borderRadius: '8px',
                width: '110px',
                height: '40px',
                fontWeight: 'bold',
                margin: 0,
                fontSize: '12px',
            },
        };

        const activeTab = this.props.match?.params?.type ?? HISTORY_TYPE_ACTIVE;

        if (this.props.auth.user) {
            return (
                <div>
                    <FlexWrapper direction="row" align="flex-start" padding="0px" gap="8px">
                        <Button
                            {...btnProps}
                            onClick={this.navigateToTab.bind(this, HISTORY_TYPE_ACTIVE)}
                            {...(activeTab === HISTORY_TYPE_ACTIVE
                                ? { primary: 'primary', 'data-test-id': 'active' }
                                : { grey: true, 'data-test-id': 'inactive' })}
                        >
                            {t.History.active}
                        </Button>
                        <Button
                            {...btnProps}
                            onClick={this.navigateToTab.bind(this, HISTORY_TYPE_FINISHED)}
                            {...(activeTab === HISTORY_TYPE_FINISHED
                                ? { primary: 'primary', 'data-test-id': 'active' }
                                : { grey: true, 'data-test-id': 'inactive' })}
                        >
                            {t.History.finished}
                        </Button>
                        <Button
                            {...btnProps}
                            onClick={this.navigateToTab.bind(this, HISTORY_TYPE_RETAIL)}
                            {...(activeTab === HISTORY_TYPE_RETAIL
                                ? { primary: 'primary', 'data-test-id': 'active' }
                                : { grey: true, 'data-test-id': 'inactive' })}
                        >
                            {t.History.retail}
                        </Button>
                    </FlexWrapper>

                    {activeTab === HISTORY_TYPE_ACTIVE ? (
                        <ActiveBets auth={this.props.auth} />
                    ) : activeTab === HISTORY_TYPE_FINISHED ? (
                        <FinishedBets auth={this.props.auth} />
                    ) : (
                        <RetailBets auth={this.props.auth} />
                    )}
                </div>
            );
        } else {
            return (
                <Wrapper padding={'0'} height={'70vh'}>
                    <InfoMessage text={t.LOGINFIRST_HISTORY} />
                </Wrapper>
            );
        }
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
});

export default withRouter(connect(mapStateToProps)(History));
