import React, { lazy, Suspense, useEffect, useRef, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

import { AnimateMoving } from 'ui/Transition';
import Spinner from 'ui/Spinner';
import BetSlipScreen from '../Mobile/BetSlipScreen';
import { SCREEN_MOVING_DURATION, SCREENS } from '../config';
import { TransparentDesktopModalLayer } from 'ui/DesktopModalWrapper';
import FlexWrapper from 'ui/FlexWrapper';
import { ModalWrapper, Screen } from './styled';
import { CloseButton, BackButton, ScreenWrapper } from '../components/styled';
import useScreens from '../hooks/useScreens';
import useAuth from 'common/hooks/useAuth';
import usePrevious from 'common/hooks/usePrevious';
import useJoinGroup from 'common/hooks/useJoinGroup';
import GroupMember from '../model/Group';
import GroupInfoScreen from '../Mobile/GroupInfoScreen';
import Pool from '../model/Pool';
import {
    getSpilklubSelectionsModeTarget,
    isSpilklubSelectionsMode,
} from 'common/selectors/betSlipSelector';
import {
    RESET_BET_BUDDY_SELECTIONS,
    RESET_BET_BUDDY_SELECTIONS_MODE,
    SET_BET_BUDDY_SELECTIONS_MODE,
} from 'features/BetSlip/state/actions';
import { betBuddySelectionsModeInitialState } from '../../BetSlip/state/reducer';
import ProductsShortcuts from '../components/ProductShortcuts';
import Toolbar from '../components/Toolbar';
import GroupAvatarScreen from '../Mobile/GroupAvatarScreen';
import CouponPreviewScreen from '../Mobile/CouponPreviewScreen';
import useSharedLink from '../hooks/useSharedLink';
import Modal from 'ui/ModalContent';
import { hidePopup } from 'utils/navigation';
import { setUIProps } from '../actions';

const CriteriaFormScreen = lazy(() => import(`features/Spilklub/Mobile/GroupFormScreen`));
const PoolDashboardScreen = lazy(() => import(`../Mobile/PoolDashboardScreen`));
const ShareLinkScreen = lazy(() => import(`../Mobile/ShareLinkScreen`));
const GroupScreen = lazy(() => import(`../Mobile/GroupScreen`));
const SubscribeScreen = lazy(() => import(`../Mobile/SubscribeScreen`));
const SubscribeConfirmScreen = lazy(() => import(`../Mobile/SubscribeConfirmScreen`));
const FinalSelectionsScreen = lazy(() => import(`../Mobile/FinalSelectionsScreen`));

interface BetBuddyEntryProps {
    showScreens: string[];
    sharedLink?: boolean;
    groupId: number;
}

const BetBuddyEntry = ({ showScreens, sharedLink = false, groupId }: BetBuddyEntryProps) => {
    const dispatch = useDispatch();
    const modalRef = useRef<HTMLDivElement>(null);

    const isSelectionsMode = useSelector(isSpilklubSelectionsMode);

    const [displayScreen, closeScreen, screenShown, screens] = useScreens([]);

    const auth = useAuth();
    const userId = auth.user ? auth.user.id : 0;
    const prevUser = usePrevious(auth.user) || { id: 0 };
    const prevUserId = prevUser ? prevUser.id : 0;
    const closeModal = () => {
        hidePopup('SPILKLUB_DESKTOP');
        dispatch(setUIProps({ activeGroupId: 0 }));
    };
    const groupListScreenShown = screenShown(SCREENS.GROUPS);
    const selectionsModeTarget = useSelector(getSpilklubSelectionsModeTarget);

    useJoinGroup({
        userId,
        prevUserId,
        sharedLink,
        displayScreen,
    });

    useSharedLink(sharedLink);

    // useEffect(() => {
    //     if (
    //         !auth.user &&
    //         !auth.pending &&
    //         sharedLink &&
    //         !isSignupModalShown &&
    //         !isLoginModalShown
    //     ) {
    //         saveLS({ redirectAfterReg: history.location.pathname });
    //
    //         dispatch(openLogin({ priority: 1 }));
    //     }
    // }, [userId, auth.pending]);

    useEffect(() => {
        groupListScreenShown &&
            selectionsModeTarget === 'UPDATE' &&
            dispatch({
                type: SET_BET_BUDDY_SELECTIONS_MODE,
                payload: betBuddySelectionsModeInitialState.betBuddySelectionsMode,
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupListScreenShown]);

    useEffect(() => {
        displayScreen(showScreens);

        disableBodyScroll(modalRef.current as HTMLElement);

        return () => clearAllBodyScrollLocks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Modal>
            <TransparentDesktopModalLayer>
                <FlexWrapper padding="0">
                    <ModalWrapper ref={modalRef}>
                        {screenShown(SCREENS.BET_SLIP) && (
                            <Screen data-test-id="betslip">
                                <AnimateMoving
                                    duration={SCREEN_MOVING_DURATION}
                                    destroy={() => closeScreen(SCREENS.BET_SLIP)}
                                >
                                    {() => (
                                        <>
                                            <CloseButton
                                                onClick={closeModal}
                                                priority={1}
                                                absolute
                                            />
                                            <BetSlipScreen
                                                onConfirm={(
                                                    combinationPrice,
                                                    combinationNumber
                                                ) => {
                                                    displayScreen(SCREENS.CREATE_GROUP, {
                                                        combinationPrice,
                                                        combinationNumber,
                                                    });
                                                }}
                                            />
                                        </>
                                    )}
                                </AnimateMoving>
                            </Screen>
                        )}
                        {screenShown(SCREENS.CREATE_GROUP) && (
                            <Screen data-test-id="create-group">
                                <AnimateMoving
                                    duration={SCREEN_MOVING_DURATION}
                                    destroy={() => closeScreen(SCREENS.CREATE_GROUP)}
                                >
                                    {disappear => (
                                        <Fragment>
                                            <BackButton onClick={disappear} absolute />
                                            <CloseButton onClick={closeModal} absolute rightSide />
                                            <Suspense fallback={<Spinner />}>
                                                <CriteriaFormScreen
                                                    {...screens[SCREENS.CREATE_GROUP]}
                                                    hideScreen={disappear}
                                                    onConfirm={() =>
                                                        displayScreen(SCREENS.SHARE_LINK)
                                                    }
                                                    showAvatarsScreen={() =>
                                                        displayScreen(SCREENS.AVATARS)
                                                    }
                                                />
                                            </Suspense>
                                        </Fragment>
                                    )}
                                </AnimateMoving>
                            </Screen>
                        )}
                        {screenShown(SCREENS.AVATARS) && (
                            <Screen>
                                <AnimateMoving
                                    duration={200}
                                    destroy={() => closeScreen(SCREENS.AVATARS)}
                                >
                                    {disappear => <GroupAvatarScreen onClose={disappear} />}
                                </AnimateMoving>
                            </Screen>
                        )}
                        {screenShown(SCREENS.SHARE_LINK) && (
                            <Screen data-test-id="share-link">
                                <AnimateMoving
                                    duration={SCREEN_MOVING_DURATION}
                                    destroy={() => closeScreen(SCREENS.SHARE_LINK)}
                                >
                                    {disappear => (
                                        <Fragment>
                                            <CloseButton onClick={closeModal} absolute rightSide />
                                            <Suspense fallback={<Spinner />}>
                                                <ShareLinkScreen
                                                    {...screens[SCREENS.SHARE_LINK]}
                                                    hideScreen={disappear}
                                                    showGroupList={(showPool: boolean) =>
                                                        displayScreen(SCREENS.GROUPS, {
                                                            showPool,
                                                        })
                                                    }
                                                    userId={userId}
                                                />
                                            </Suspense>
                                        </Fragment>
                                    )}
                                </AnimateMoving>
                            </Screen>
                        )}
                        {screenShown(SCREENS.GROUPS) && (
                            <Screen data-test-id="groups">
                                <AnimateMoving
                                    duration={SCREEN_MOVING_DURATION}
                                    destroy={() => closeScreen(SCREENS.GROUPS)}
                                >
                                    {disappear => (
                                        <Suspense fallback={<Spinner />}>
                                            <GroupScreen
                                                {...screens[SCREENS.GROUPS]}
                                                showPoolDetails={() =>
                                                    displayScreen(SCREENS.POOL_DASHBOARD)
                                                }
                                                showProductShortcuts={() =>
                                                    displayScreen(SCREENS.PRODUCTS_SHORTCUTS)
                                                }
                                                sharedLink={sharedLink}
                                                close={closeModal}
                                                onConfirm={groupId => {
                                                    displayScreen(SCREENS.SUBSCRIBE, {
                                                        groupId,
                                                    });
                                                }}
                                                groupId={groupId}
                                            />
                                        </Suspense>
                                    )}
                                </AnimateMoving>
                            </Screen>
                        )}
                        {screenShown(SCREENS.SUBSCRIBE) && (
                            <Screen data-test-id="subscribe">
                                <AnimateMoving
                                    duration={SCREEN_MOVING_DURATION}
                                    destroy={() => closeScreen(SCREENS.SUBSCRIBE)}
                                >
                                    {disappear => (
                                        <Suspense fallback={<Spinner />}>
                                            <SubscribeScreen
                                                {...screens[SCREENS.SUBSCRIBE]}
                                                onBack={disappear}
                                                onConfirm={subscriptions => {
                                                    displayScreen(SCREENS.SUBMIT_SUBSCRIBE, {
                                                        subscriptions,
                                                    });
                                                }}
                                            />
                                        </Suspense>
                                    )}
                                </AnimateMoving>
                            </Screen>
                        )}
                        {screenShown(SCREENS.SUBMIT_SUBSCRIBE) && (
                            <ScreenWrapper data-test-id="submit-subscribe">
                                <AnimateMoving
                                    duration={SCREEN_MOVING_DURATION}
                                    destroy={() => closeScreen(SCREENS.SUBMIT_SUBSCRIBE)}
                                >
                                    {disappear => (
                                        <Suspense fallback={<Spinner />}>
                                            <SubscribeConfirmScreen
                                                onBack={disappear}
                                                {...screens[SCREENS.SUBMIT_SUBSCRIBE]}
                                                onConfirm={() => {
                                                    displayScreen(SCREENS.GROUPS);
                                                }}
                                            />
                                        </Suspense>
                                    )}
                                </AnimateMoving>
                            </ScreenWrapper>
                        )}
                        {screenShown(SCREENS.POOL_DASHBOARD) && (
                            <Screen data-test-id="pool-dashboard">
                                <AnimateMoving
                                    duration={SCREEN_MOVING_DURATION}
                                    destroy={() => closeScreen(SCREENS.POOL_DASHBOARD)}
                                >
                                    {disappear => (
                                        <Fragment>
                                            <BackButton onClick={disappear} absolute />
                                            <CloseButton onClick={closeModal} absolute rightSide />
                                            <Suspense fallback={<Spinner />}>
                                                <PoolDashboardScreen
                                                    {...screens[SCREENS.POOL_DASHBOARD]}
                                                    showFinalSelections={() =>
                                                        displayScreen(SCREENS.FINAL_SELECTIONS)
                                                    }
                                                    showGroupInfo={(
                                                        pool: Pool,
                                                        groupMembers: GroupMember[]
                                                    ) =>
                                                        displayScreen(SCREENS.GROUP_INFO, {
                                                            pool,
                                                            groupMembers,
                                                        })
                                                    }
                                                />
                                            </Suspense>
                                        </Fragment>
                                    )}
                                </AnimateMoving>
                            </Screen>
                        )}
                        {screenShown(SCREENS.GROUP_INFO) && (
                            <Screen data-test-id="group-info">
                                <AnimateMoving
                                    duration={SCREEN_MOVING_DURATION}
                                    destroy={() => closeScreen(SCREENS.GROUP_INFO)}
                                >
                                    {disappear => (
                                        <Fragment>
                                            <BackButton onClick={disappear} absolute />
                                            <CloseButton onClick={closeModal} absolute rightSide />
                                            <Suspense fallback={<Spinner />}>
                                                <GroupInfoScreen
                                                    {...screens[SCREENS.GROUP_INFO]}
                                                    showFinalSelections={() =>
                                                        displayScreen(SCREENS.FINAL_SELECTIONS)
                                                    }
                                                />
                                            </Suspense>
                                        </Fragment>
                                    )}
                                </AnimateMoving>
                            </Screen>
                        )}
                        {screenShown(SCREENS.FINAL_SELECTIONS) && (
                            <Screen data-test-id="final-selections">
                                <AnimateMoving
                                    duration={SCREEN_MOVING_DURATION}
                                    destroy={() => closeScreen(SCREENS.FINAL_SELECTIONS)}
                                >
                                    {disappear => (
                                        <Fragment>
                                            <BackButton
                                                onClick={() => {
                                                    disappear();
                                                    isSelectionsMode && closeModal();
                                                }}
                                                absolute
                                            />
                                            <CloseButton onClick={closeModal} absolute rightSide />
                                            <Suspense fallback={<Spinner />}>
                                                <FinalSelectionsScreen
                                                    hideFinalSelectionsScreen={() => {
                                                        closeScreen(SCREENS.FINAL_SELECTIONS);
                                                    }}
                                                />
                                            </Suspense>
                                        </Fragment>
                                    )}
                                </AnimateMoving>
                            </Screen>
                        )}
                        {screenShown(SCREENS.PRODUCTS_SHORTCUTS) && (
                            <Screen>
                                <AnimateMoving
                                    duration={SCREEN_MOVING_DURATION}
                                    destroy={() => closeScreen(SCREENS.PRODUCTS_SHORTCUTS)}
                                >
                                    {disappear => (
                                        <Fragment>
                                            <Toolbar
                                                expanded={true}
                                                goBack={disappear}
                                                close={closeModal}
                                                backButton={true}
                                            />
                                            <Suspense fallback={<Spinner />}>
                                                {/* @ts-ignore */}
                                                <ProductsShortcuts noTopbar />
                                            </Suspense>
                                        </Fragment>
                                    )}
                                </AnimateMoving>
                            </Screen>
                        )}
                        {screenShown(SCREENS.COUPON_PREVIEW) && (
                            <Screen>
                                <AnimateMoving
                                    duration={SCREEN_MOVING_DURATION}
                                    destroy={() => {
                                        closeScreen(SCREENS.COUPON_PREVIEW);
                                        dispatch({
                                            type: RESET_BET_BUDDY_SELECTIONS_MODE,
                                        });
                                        dispatch({
                                            type: RESET_BET_BUDDY_SELECTIONS,
                                        });
                                    }}
                                >
                                    {disappear => (
                                        <Fragment>
                                            <BackButton onClick={disappear} absolute />
                                            <Suspense fallback={<Spinner />}>
                                                <CouponPreviewScreen
                                                    hideCouponPreviewScreen={() => {
                                                        closeScreen(SCREENS.COUPON_PREVIEW);
                                                    }}
                                                />
                                            </Suspense>
                                        </Fragment>
                                    )}
                                </AnimateMoving>
                            </Screen>
                        )}
                    </ModalWrapper>
                </FlexWrapper>
            </TransparentDesktopModalLayer>
        </Modal>
    );
};

export default BetBuddyEntry;
