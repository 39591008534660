import React from 'react';
import ExpertItem from './ExpertItem';
import Hr from 'ui/Hr';
import { Expert } from './server';

interface ExpertsProps {
    expertsList: Expert[];
}

const Experts = ({ expertsList }: ExpertsProps) => {
    return (
        <>
            {expertsList.map((expertData) => (
                <>
                    <ExpertItem
                        title={expertData.name}
                        text={expertData.description}
                        photoSrc={expertData.photo}
                        groups={expertData.groups}
                    />
                    <Hr />
                </>
            ))}
        </>
    );
};

export default Experts;
