import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import Button from 'ui/controls/Button';
import Flex from 'ui/Flex';
import {
    NavigationBar,
    CardsImage,
    Title,
    PreviewButtons,
    SpilIDTooltip,
    DescriptionText,
} from 'features/SpilID/styled';
import withPopupWrapper from './withPopupWrapper';

const PhysicalCardPreview = ({ hideModalAnimate, showModal, modalData }) => {
    const [tooltipPopupShown, setTooltipPopupShown] = useState(false);
    const showTooltipPopup = (e) => {
        e.stopPropagation();
        setTooltipPopupShown(true);
    };
    const hideTooltipPopup = () => {
        setTooltipPopupShown(false);
    };

    return (
        <>
            <NavigationBar
                title={'Bestil Spil ID kort'}
                clickHandler={() => hideModalAnimate('SPIL_ID_PC_PREVIEW')}
                onQuestionMarkClick={showTooltipPopup}
                isAbove
                iconColor={'#fff'}
                titleColor={'#fff'}
            />

            <Flex direction={'column'}>
                <CardsImage />

                <Flex
                    padding={'33px 22px 24px'}
                    boxSizing={'border-box'}
                    direction={'column'}
                    justify={'space-between'}
                >
                    <Flex
                        boxSizing={'border-box'}
                        direction={'column'}
                        align={'flex-start'}
                        justify={'flex-start'}
                    >
                        <Title>Bestil fysisk Spil ID</Title>

                        <DescriptionText>
                            Få et fysisk Spil ID kort, i stedet for eller som
                            supplement til dit digitale kort på telefonen, til
                            vores terminaler.
                        </DescriptionText>

                        <DescriptionText>
                            Vi sender dit Spil ID kort - kvit og frit - med
                            posten.
                        </DescriptionText>
                    </Flex>
                    <PreviewButtons>
                        <Button
                            onClick={() =>
                                hideModalAnimate('SPIL_ID_PC_PREVIEW')
                            }
                            size="x2"
                            grey
                        >
                            Annuller
                        </Button>
                        <Button
                            onClick={() =>
                                showModal('SPIL_ID_PC_FORM', 3, modalData)
                            }
                            size="x2"
                            accentAlt
                            bold
                        >
                            Bestil
                        </Button>
                    </PreviewButtons>
                    <SpilIDTooltip
                        hideTooltipPopup={hideTooltipPopup}
                        tooltipPopupShown={tooltipPopupShown}
                    />
                </Flex>
            </Flex>
        </>
    );
};

export default (isMobile
    ? PhysicalCardPreview
    : withPopupWrapper(PhysicalCardPreview));
