import { PoolSettings } from './Pool';

export default interface Group {
    id: number;
    idx?: number;
    sourcePage?: number;
    ownerId: number;
    name: string;
    avatarUrl?: string;
    created: Date;
    description?: string;
    smsEnabled: boolean;
    membersCount: number;
    groupClosed: boolean;
    publicGroup: boolean;
    groupMember: boolean;
    poolSettings: PoolSettings;
    boughtSharesNumber?: number;
    activePools?: String[];
}

export interface GroupMember {
    userId: number;
    firstName: string;
    lastName: string;
    subscribedToGroup?: boolean; //optional until BE is not ready
}

export const createNullGroup = (): Group => ({
    id: 0,
    ownerId: 0,
    name: '',
    created: new Date(),
    avatarUrl: '/img/group_avatars/1.png',
    smsEnabled: true,
    membersCount: 0,
    groupClosed: false,
    publicGroup: false,
    groupMember: false,
    boughtSharesNumber: 0,
    activePools: [],
    poolSettings: {
        sharePrice: 100,
        sharesLimit: 100,
        deadlineTime: 30,
    },
});

export const createNullGroupMember = (): GroupMember => ({
    userId: 0,
    firstName: '',
    lastName: '',
});
