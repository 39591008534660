import { useEffect, useState } from 'react';

declare type UseBodyScrollLockOutput = [() => void, () => void, boolean];

const useBodyScrollLock = (initiallyLocked = true, timeout = 0): UseBodyScrollLockOutput => {
    //@see ModalContainer.jsx

    const appRoot = document.getElementById('root');

    const [locked, setLocked] = useState(initiallyLocked);

    const lock = () => {
        if (timeout > 0) {
            setTimeout(() => {
                setLocked(true);
            }, timeout);
        } else {
            setLocked(true);
        }
    };
    const unlock = () => setLocked(false);

    const lockScroll = () => {
        if (timeout > 0) {
            setTimeout(() => {
                window.document.body.style.position = 'fixed';
                window.document.body.style.overflow = 'hidden';
            }, timeout);
        } else {
            window.document.body.style.position = 'fixed';
            window.document.body.style.overflow = 'hidden';
        }
        appRoot && appRoot.classList.add('without-scroll');
    };

    const unlockScroll = () => {
        window.document.body.style.position = 'static';
        window.document.body.style.overflow = 'auto';
        appRoot && appRoot.classList.remove('without-scroll');
    };

    useEffect(() => {
        locked ? lockScroll() : unlockScroll();
        return unlockScroll;
    }, [locked]);

    return [lock, unlock, locked];
};

export default useBodyScrollLock;
