import { combineReducers } from 'redux';
import { createReducer } from 'typesafe-actions';
import { cleanSystemBet, placeSystemBetAsync } from './actions';
import { BetStatus } from './types';

interface BatchBettingState {
    bet: BetState;
}

interface BetState {
    pending: boolean;
    success: boolean;
    error: string;
    status: BetStatus;
}

export const initialState: BatchBettingState = {
    bet: {
        pending: false,
        success: false,
        error: '',
        status: BetStatus.null,
    },
};

const reducer = combineReducers({
    bet: createReducer(initialState.bet)
        .handleAction(placeSystemBetAsync.request, () => ({
            pending: true,
            success: false,
            error: '',
            status: BetStatus.pending,
        }))
        .handleAction(placeSystemBetAsync.success, (state: BetState, action) => ({
            pending: false,
            success: true,
            error: '',
            status: action.payload,
        }))
        .handleAction(placeSystemBetAsync.failure, (state: BetState, action) => ({
            pending: false,
            success: false,
            error: action.payload,
            status: BetStatus.failed,
        }))
        .handleAction(cleanSystemBet, () => ({
            pending: false,
            success: false,
            error: '',
            status: BetStatus.null,
        })),
});

export default reducer;
