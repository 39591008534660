import React from 'react';
import { useDispatch } from 'react-redux';

import DatePicker2 from 'ui/DatePicker2';
import { CalendarControls, CalendarLabel } from 'ui/RaceNavigation';
import { ModalContainer, ModalTransparency } from 'ui/Modal';
import { FadeInSpan } from 'ui/animations/FadeIn2';

import useCalendarDate from 'features/EventBoard/hooks/useCalendarDate';
import { setEditSelectionsInitializing } from 'features/AISDataProvider/actions';

import useModals from 'common/hooks/useModals';
import {
    addDays,
    SERVER_DATE_FORMAT,
    today,
    todayNative,
    toNativeDate,
    toServerDate,
} from 'utils/date';
import { byPlatform, isMobile } from 'utils/platforms';

const DateSelection = () => {
    const { isNextDateAvailable, isPreviousDateAvailable, mDate, selectNextDate, selectPrevDate } =
        useCalendarDate();

    const { show: showModal } = useModals();

    const showDatePickerModal = () => showModal('EVENT_BOARD_DATE_PICKER');

    return (
        <div className="flex space-between">
            <CalendarLabel
                bold
                size={byPlatform('14', '32')}
                padding={byPlatform('10px 0 10px 10px', '10px 0 10px 30px')}
                className={byPlatform('flex align-center')}
            >
                <FadeInSpan>Løbskalender</FadeInSpan>
            </CalendarLabel>

            <CalendarControls
                mCurrentDate={mDate}
                showDatePickerModal={showDatePickerModal}
                selectNextDate={selectNextDate}
                selectPrevDate={selectPrevDate}
                isNextDateAvailable={isNextDateAvailable}
                isPrevDateAvailable={isPreviousDateAvailable}
            />
        </div>
    );
};

export const CalendarDatePicker = () => {
    const dispatch = useDispatch();

    const { date, setDate } = useCalendarDate();

    const { hide: hideModal } = useModals();

    const maxDate = toNativeDate(addDays(today(), 6));

    const minDate = todayNative();

    const onConfirm = (date: Date) => {
        setDate(toServerDate(date));
        dispatch(setEditSelectionsInitializing(false));
        hideModal('EVENT_BOARD_DATE_PICKER');
    };

    if (isMobile) {
        return (
            <DatePicker2
                date={toNativeDate(date, SERVER_DATE_FORMAT)}
                onConfirm={onConfirm}
                minDate={minDate}
                maxDate={maxDate}
            />
        );
    }

    return (
        <ModalTransparency>
            <ModalContainer>
                <DatePicker2
                    date={toNativeDate(date, SERVER_DATE_FORMAT)}
                    onConfirm={onConfirm}
                    height={450}
                    width={500}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            </ModalContainer>
        </ModalTransparency>
    );
};

export default DateSelection;
