import styled, { css } from 'styled-components';
import Button from 'ui/controls/Button';
import { BACKGROUNDS } from 'themes';
import ReactTooltip from 'react-tooltip';
import {
    Table as TableDef,
    TableColumn as TableColumnDef,
    TableContent as TableContentDef,
    TableHeader as TableHeaderDef,
    TableSubHeader as TableSubHeaderDef,
    TableRowCheckered,
} from 'ui/Table';
import { COLORS } from 'ui/topbar';
import { byPlatform } from 'utils/platforms';

// position and zindex fixes overlapping the betslip notifier component
export const Table = styled(TableDef)`
    position: relative;
    z-index: 1;
`;

export const HorseNrBtn = styled(Button)`
    font-weight: 900;
    background: ${BACKGROUNDS.lightGrey};
    color: ${COLORS.black};
    border: 0.5px solid #c6ccdb;
    width: 30px;
    text-align: center;
`;

export const TableCell = styled.div`
    font-weight: bold;
    color: ${COLORS.primaryText};
    font-size: 14px;
    padding: 0;
    text-transform: capitalize;
`;

export const gridTemplateColumnsCSS = css`
    grid-template-columns: ${byPlatform('15%', '10%')} 45% 45%;
`;

export const rowPaddingCSS = css`
    padding: 5px 3px;
`;

export const TableHeader = styled(TableHeaderDef)`
    font-weight: bold;
    border-bottom-left-radius: ${({ shown }) => (shown ? 0 : '10px')};
    border-bottom-right-radius: ${({ shown }) => (shown ? 0 : '10px')};
    cursor: pointer;
`;

export const TableContent = styled(TableContentDef)`
    display: block;
    max-height: ${({ shown }) => {
        return shown ? `2000px` : '0';
    }};
    transition: all 300ms ease-in-out;
`;

export const TableRow = styled(TableRowCheckered)`
    padding: 0;
    ${rowPaddingCSS};
    display: grid;
    ${gridTemplateColumnsCSS};
    align-items: center;
`;

export const SubHeaderCell = styled(TableColumnDef)`
    padding: 0;
`;

export const TableSubHeader = styled(TableSubHeaderDef)`
    display: grid;
    ${rowPaddingCSS};
    ${gridTemplateColumnsCSS};
    grid-column: 1/4;
    font-size: ${byPlatform('14px', '10px')};
`;

export const ExpandBtn = styled(Button)`
    background: #00904c;
    display: flex !important;
`;

export const ForecastTooltip = styled(ReactTooltip)`
    font-weight: bold;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 12px;
    opacity: 1 !important;
`;

export const QuestionMark = styled.button`
    border: 0;
    background: #333333;
    width: 19px;
    height: 19px;
    border-radius: 50%;
    margin: 0;
    padding: 3px;
    box-sizing: border-box;
    color: ${COLORS.white};
    text-align: center;
    font-weight: 700;
    font-size: 12px;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translate(5px, -50%);
`;
