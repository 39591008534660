import styled from 'styled-components';
import CommonButton from 'ui/controls/Button';
import { priority } from 'configs/layout';
import { CommonDesktopModalWrapper } from 'ui/DesktopModalWrapper';
import { zIndexMixin } from '../components/styled';
import { ComponentType } from 'ui/types';

// Common

const TriggerButton = styled<ComponentType<{ hide: boolean }>>(CommonButton)`
    height: 51px;
    width: auto;
    border-radius: 15px 15px 0 0;
    position: fixed;
    right: 50px;
    box-shadow: 0 0 20px rgba(61, 61, 61, 0.6);
    bottom: 0;
    margin: 0;
    z-index: ${priority.betBuddyButtonDesktop};
    transform: ${({ hide }) => (hide ? `translateY(55px)` : `translateY(0)`)};
    transition: transform 400ms ease-in-out;
    transition-delay: 100ms;
    padding: 8px 20px;
`;

const ModalWrapper = styled(CommonDesktopModalWrapper)`
    padding: 0;
    overflow: hidden;
`;

const Screen = styled.div<{ priority?: number }>`
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    ${zIndexMixin};
`;

export { TriggerButton, ModalWrapper, Screen };
