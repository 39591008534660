import productSelector from 'common/selectors/productSelector';
import { useSelector } from 'react-redux';
import {
    getCombinedTrackName,
    getMultitrackCodes,
    getTrackFromRacingCard,
} from 'common/selectors/trackSelector';
import raceDaySelector from 'common/selectors/raceDaySelector';
import racesSelector, { getFirstRace } from 'common/selectors/racesSelector';
import betSlipSelector, {
    checkMaximumPicks,
    checkMinimumPicks,
    getReserves,
} from 'common/selectors/betSlipSelector';
import combinationsCountSelector from 'common/selectors/combinationsCountSelector';
import betTableSelector, {
    betPickSelectorArray,
} from 'common/selectors/betTableSelector';
import { isMultitrackProduct } from 'common/selectors/multipleTrackSetupsSelector';

const useSelections = () => {
    const product = useSelector(
        productSelector,
        (prev, curr) => prev.id === curr.id
    );

    const track = useSelector(
        getTrackFromRacingCard,
        (prev, curr) => prev.id === curr.id && prev.code === curr.code
    );

    const combinedTrackName = useSelector(getCombinedTrackName);

    const serverTime = useSelector(
        (state) => state.AISDataProvider.serverTime,
        () => true
    );

    const raceDay = useSelector(
        raceDaySelector,
        (prev, curr) =>
            prev.date === curr.date &&
            prev.track.id === curr.track.id &&
            prev.track.code === curr.track.code
    );

    const firstVRace = useSelector(getFirstRace);

    const races = useSelector(racesSelector);

    const reserves = useSelector(getReserves);

    const combinationsCount = useSelector(combinationsCountSelector);

    const bet = useSelector(betTableSelector);

    const betSlip = useSelector(betSlipSelector);

    const selections = useSelector(betPickSelectorArray);

    const minimumPicksValid = useSelector(checkMinimumPicks);
    const maximumPicksValid = useSelector(checkMaximumPicks);

    const isMultitrack = useSelector(isMultitrackProduct);
    const multitrackCodes = useSelector(getMultitrackCodes);

    const strictBet = useSelector((state) => state.BetSlip.strictMode);

    return {
        product,
        track,
        serverTime,
        raceDay,
        date: raceDay.date,
        firstVRace,
        races,
        reserves,
        combinationsCount,
        combinedTrackName,
        bet,
        betSlip,
        minimumPicksValid,
        maximumPicksValid,
        isMultitrack,
        multitrackCodes,
        selections,
        strictBet,
    };
};

export default useSelections;
