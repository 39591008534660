import styled from 'styled-components';
import { BACKGROUNDS, COLORS } from 'themes';
import { getBackgroundByProps } from 'utils/theme-helpers';
import TopBarText from 'ui/topbar/Text';
import Button from 'ui/controls/Button';
import { FullScreen } from 'ui/FullScreen';
import { InputControl } from 'ui/controls/Input';
import { byPlatform } from 'utils/platforms';
import getTexts from 'utils/localization';

const t = getTexts();

const SearchBarWrapper = styled.section`
    border: 1px solid ${COLORS.lightGrey};
    border-radius: 7px;
    width: auto;
    height: 32px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    line-height: 32px;
`;

const SearchInput = styled(InputControl)`
    font-size: 12px;
    width: 100%;
    margin: 0px;
    padding: 0px;
    &::placeholder {
        opacity: 1;
        transition: opacity 0.3s ease-in-out;

        &:focus {
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
        }

        &:bluer {
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }
    }
    &:focus::placeholder {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }
    &:bluer::placeholder {
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
    }
`;

const Background = styled.div`
    overflow: hidden;
    background: ${byPlatform(COLORS.white, COLORS.white, 'none')};
    min-height: 100%;
    height: auto;
    margin: 0;
    padding: 0;
`;

const StyledFullScreen = styled(FullScreen)`
    height: auto;
`;

const Timer = styled.time`
    font-weight: 700;
    text-transform: uppercase;
`;

const StyledTopBarText = styled(TopBarText).attrs({ small: true })`
    margin-top: 0;
    margin-bottom: 0;
`;

const MoveUpper = styled.div`
    position: relative;
    top: ${byPlatform('0', '-40')}px;
`;

const InputWrapper = styled(SearchBarWrapper)`
    justify-content: flex-end;
`;

const Input = styled(SearchInput).attrs({
    type: 'text',
    pattern: '[0-9]*',
    inputMode: 'decimal',
})`
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
    text-transform: uppercase;
    font-weight: 800;
    text-align: right;
    font-size: 14px;
    padding-right: ${(props) => (props.paddingRight ? props.paddingRight : '5px')};
    padding-left: ${(props) => (props.paddingLeft ? props.paddingLeft : '12px')};
    color: ${COLORS.primaryText};
    box-sizing: border-box;
    width: ${(props) => (props.width ? props.width : '62%')};
    &::placeholder {
        text-align: center;
        overflow: visible;
    }
    &:placeholder-shown + span {
        width: 0;
        visibility: hidden;
    }
    &:not(:placeholder-shown) + span {
        width: auto;
        visibility: visible;
    }
    &:focus + span {
        width: auto;
        visibility: visible;
    }
    && + span::after {
        font-weight: 800;
        font-size: 14px;
        color: ${COLORS.primaryText};
        padding-right: 15px;
        content: ' ${t.currency}';
    }
`;

const AmountBtn = styled(Button)`
    background: ${(props) => (props.secondary ? BACKGROUNDS.grey : BACKGROUNDS.green)};
    color: ${(props) => (props.secondary ? COLORS.primaryText : COLORS.white)};
    font-weight: 800;
    margin: 3px 0;
    padding: 0;
    height: 45px;
    flex: 0 0 32%;
`;

const Error = styled.mark`
    color: ${COLORS.red};
    background: none;
    width: 90%;
    margin: 20px auto 0 auto;
    text-align: center;
`;

const IconWrapper = styled.span`
    background: ${(props) => getBackgroundByProps(props)};
    color: ${COLORS.white};
    border-radius: 50%;
    font-size: 14px;
    text-align: center;
    padding: 0px;
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-weight: 900;
    cursor: pointer;
`;
const RydCouponButton = styled(Button)`
    margin: 0 10px 0 0;
    border-radius: 5px;
    text-align: center;
    padding: 5px 3px;
    font-size: 14px;
    width: 50px;
    display: inline-block;
    border: none;
    color: ${COLORS.white};
    background: ${COLORS.red};
`;
const DraftWrapper = styled.div`
    width: 81px;
    position: absolute;
    right: 15px;
    display: flex;
    top: 20px;
    align-items: center;
    justify-content: space-between;
`;
const DraftLabel = styled.span`
    background: ${BACKGROUNDS.accentAlt};
    border-radius: 15px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    padding: 8px;
    color: ${COLORS.black};
`;

const QRContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 99999;
    width: 33px;
    top: -175px;
    right: 10px;
    cursor: pointer;
`;

const MobileBetText = styled.p`
    margin: 3px;
    text-transform: uppercase;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
`;

export {
    Background,
    StyledFullScreen,
    Timer,
    StyledTopBarText as TopBarText,
    MoveUpper,
    InputWrapper,
    Input,
    AmountBtn,
    Error,
    IconWrapper,
    QRContainer,
    MobileBetText,
    RydCouponButton,
    DraftWrapper,
    DraftLabel,
    SearchInput,
    SearchBarWrapper,
};
