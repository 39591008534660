import React from 'react';
import { Card, CardContent, CardHeader } from 'ui/Card';
import { Timer } from 'features/BetSlip/styled';
import Countdown from 'ui/Countdown';
import { Col, Container, Row } from 'react-grid-system';
import Icon from 'ui/Icon';
import SubHeader from './SubHeader';
import BetPick from 'features/BetSlip/components/BetPick';
import Hr from 'ui/Hr';
import Spinner from 'ui/Spinner';
import BetForm from 'features/BetSlip/components/BetForm';
import StrictBet from 'features/BetSlip/components/StrictBet';
import { COLORS } from 'themes';
import { PRODUCT_IDS } from 'configs/products';
import Button from 'ui/controls/Button';
import smartLynActiveIcon from 'features/BetSlip/components/SmartLyn/assets/smart_lyn_a.png';
import SmartLyn from 'features/BetSlip/components/SmartLyn';
import Checkbox from 'ui/Checkbox';
import { byPlatform, IS_TERMINAL } from 'utils/platforms';

import getTexts from 'utils/localization';

const t = getTexts();

const BetSlipCard = (props) => {
    const {
        combinedTrackName,
        product,
        race,
        combinationsCount,
        combinationPrice,
        applyCombinationPrice,
        serverTime,
        isCombinationPriceValid,
        isCouponAmountExceed,
        combinationPriceError,
        styles,
        minimumPicksValid,
        maximumPicksValid,
        date,
        toggleSmartLynMode,
        smartLynMode,
        smartLynBet,
        lockedLegs,
        allLegsLocked,
        toggleLegStatus,
        user,
        playAndPayWorkflow,
    } = props;

    const bs = '0 0 30px rgba(0, 0, 0, 0.3)';
    const CardStyles = {
        marginTop: byPlatform('-50', '-80', '0') + 'px',
        boxShadow: byPlatform(bs, bs, `0 0 15px ${COLORS.blockShadow}`),
        paddingBottom: '30px',

        ...styles.Card,
    };

    return !product || !race ? null : (
        <Card style={CardStyles}>
            <CardHeader>
                <Container>
                    <Row>
                        <Col xs={6} style={{ paddingRight: 0, paddingLeft: '5px' }}>
                            <Icon itrack size="x05" color="white" />{' '}
                            <span className="text-bold uppercase">
                                {!product.isVProduct() && `${t.betSlip.track} ${race.raceNumber}, `}{' '}
                                {combinedTrackName}
                            </span>
                        </Col>
                        <Col xs={6} align="right" style={{ paddingLeft: 0, paddingRight: '5px' }}>
                            <Icon
                                itime
                                color="white"
                                size="x05"
                                style={{
                                    verticalAlign: 'sub',
                                    marginRight: '5px',
                                }}
                            />{' '}
                            <Timer>
                                <Countdown
                                    time={date + ' ' + race.postTime}
                                    timeUTC={date + ' ' + race.postTimeUTC}
                                    serverTime={serverTime}
                                    withDay
                                    key={serverTime}
                                />
                            </Timer>
                        </Col>
                    </Row>
                </Container>
            </CardHeader>
            <SubHeader product={product} />
            <CardContent style={{ background: COLORS.white, padding: '0px 15px' }}>
                {Object.keys(props.rawPool).length > 0 ? (
                    smartLynMode && (user || playAndPayWorkflow) && product.isVButNotDouble() ? (
                        <SmartLyn
                            data={smartLynBet}
                            lockedLegs={lockedLegs}
                            toggleLegStatus={toggleLegStatus}
                        />
                    ) : (
                        <BetPick {...props} placeholders={IS_TERMINAL} />
                    )
                ) : (
                    <Spinner />
                )}
                <Hr
                    style={{
                        padding: '0px 10px 10px',
                        boxSizing: 'border-box',
                    }}
                />
                <BetForm
                    combinationsCount={combinationsCount}
                    product={product}
                    amount={combinationPrice}
                    applyCombinationPrice={applyCombinationPrice}
                    isCombinationPriceValid={isCombinationPriceValid}
                    isCouponAmountExceed={isCouponAmountExceed}
                    combinationPriceError={combinationPriceError}
                    styles={styles}
                    minimumPicksValid={minimumPicksValid}
                    maximumPicksValid={maximumPicksValid}
                    smartLynMode={smartLynMode}
                    allLegsLocked={allLegsLocked}
                />
                {(product.id === PRODUCT_IDS.V65 ||
                    product.id === PRODUCT_IDS.V64 ||
                    product.id === PRODUCT_IDS.GS75) &&
                !smartLynMode ? (
                    <StrictBet product={product.id} />
                ) : null}
                {product.isVButNotDouble() ? (
                    <Button
                        size="x2"
                        condensed
                        secondary
                        block
                        rounded={20}
                        width="170px"
                        margin="10px auto"
                        uppercase
                        onClick={toggleSmartLynMode}
                    >
                        Smart Lyn{' '}
                        <img
                            src={smartLynActiveIcon}
                            alt="Smart Lyn"
                            height="16px"
                            style={{ marginLeft: '5px' }}
                        />
                        <Checkbox
                            checked={smartLynMode}
                            styles={{
                                padding: 0,
                                height: 'auto',
                                width: 'auto',
                            }}
                        />
                    </Button>
                ) : null}
            </CardContent>
        </Card>
    );
};

export default BetSlipCard;
