import styled from 'styled-components';
import { AdjustableComponent } from './types';

interface ScrollBarProps extends AdjustableComponent {
    orientation?: string;
}

const ScrollBar = styled.section<ScrollBarProps>`
  margin: 0;
  padding: 0;
  ${(props) => (props.height ? `height: ${props.height};` : '')}
  ${(props) => (props.width ? `width: ${props.width};` : '')}
  ${(props) =>
      props.orientation === 'horizontal' ? `white-space: nowrap;` : ''}
  overflow-x: ${(props) =>
      props.orientation === 'vertical' || props.orientation === 'hidden'
          ? 'hidden'
          : 'auto'};
  overflow-y: ${(props) =>
      props.orientation === 'horizontal' || props.orientation === 'hidden'
          ? 'hidden'
          : 'auto'};
   ${(props) => (props.padding ? `padding: ${props.padding};` : '')}
   -webkit-overflow-scrolling: touch;
`;

export default ScrollBar;
