import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ModalProps } from 'ui/types';
import Player, { IframeProps } from './Player';
import { COLORS } from '../../themes';

interface PlayerPopupModalData {
    videoId: string;
    videoTitle: string;
}

interface PlayerPopupProps extends ModalProps<PlayerPopupModalData> {}

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
`;

const IFrame = styled.iframe<IframeProps>`
    width: 100%;
`;

const PlayerPopup = ({
    modalData,
    setBackground = () => {},
    enableCloseButton = () => {},
}: PlayerPopupProps) => {
    useEffect(() => {
        setBackground(COLORS.black);
        enableCloseButton();
    }, []);

    return (
        <Wrapper>
            <Player
                title={modalData.videoTitle}
                height={'339'}
                videoId={modalData.videoId}
                allowFullScreen={true}
                IframeComponent={IFrame}
            />
        </Wrapper>
    );
};

export default PlayerPopup;
