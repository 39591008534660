import styled from 'styled-components';
import Text from 'ui/Text';
import { BACKGROUNDS, COLORS } from 'themes';
import Fa from 'ui/Fa';
import { priority } from 'configs/layout';

const PageTitle = styled(Text)`
    text-align: center;
    font-weight: 600;
    color: ${COLORS.white};
    font-size: 32px;
    position: relative;
    z-index: ${priority.pageTitleBurgerMenu};
`;

const TitlePad = styled.div`
    background: ${BACKGROUNDS.primary};
    position: absolute;
    top: -649px;
    height: 800px;
    width: 800px;
    border-radius: 400px;
    left: 50%;
    margin-left: -400px;
    z-index: ${priority.titlePadBurgerMenu};
`;

const PageContent = styled.div`
    padding: 60px 15px 30px 15px;
    p {
        font-size: 14px;
        color: ${COLORS.primaryText};
    }
`;

const BackButton = styled(Fa)`
    position: absolute;
    top: -27px;
    left: -9px;
`;

export { PageTitle, TitlePad, PageContent, BackButton };
