export default class Track {
    id = null;
    name = null;
    code = null;
    hostDate = ''; // multitrack only

    constructor() {
        return this;
    }

    getSystemName = () => {
        return this.name ? this.name.toLowerCase().split(' ').join('') : '';
    };

    static unserialize(id, data) {
        const track = new Track();

        track.id = id;
        track.name = data.name || data.domesticText || data.code;
        track.code = data.code;
        track.hostDate = data.hostDate;

        return track;
    }

    /**
     * @return {Track}
     */
    static getNullObject() {
        if (!Track.nullObject) {
            Track.nullObject = new Track();
        }

        return Track.nullObject;
    }

    /**
     * Create a track instance from track object without type
     * @param  {Object} data
     * @return {Track}
     */
    static fill(data = {}) {
        const track = new Track();
        return Object.assign(track, data);
    }

    static getSlug(trackName) {
        // Combined multitrack name can not be the part of the URL, so this code extracts it's first track
        if (trackName.indexOf('/') !== -1) {
            trackName = trackName.split('/')[0];
        }

        return trackName
            .replace(/\s/g, '')
            .toLowerCase()
            .replace(/æ/gi, 'ae')
            .replace(/ø/gi, 'o')
            .replace(/ö/gi, 'o')
            .replace(/å/gi, 'a')
            .replace(/ä/gi, 'a');
    }
}
