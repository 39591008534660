import React, { useEffect } from 'react';
import Wrapper from 'ui/Wrapper';
import HistoryFeature from 'features/History';
import { scrollToTop } from 'utils/DOM';

const History = () => {
    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <Wrapper padding="20px">
            <HistoryFeature />
        </Wrapper>
    );
};

export default History;
