import { useQuery } from '@tanstack/react-query';
import { request } from 'utils/server';
import { Label } from 'features/EventBoard/server/calendar';

interface QueryParameters {
    date: string;
    trackId: number;
}

interface UseMergedPoolsQueryProps extends QueryParameters {
    enabled?: boolean;
}

interface MergedPoolsData {
    date: string;
    mergedPools: [];
    track: Label;
    trackId: number;
}

const fetchMergedPools = async ({ date, trackId }: QueryParameters) => {
    const response = await request<MergedPoolsData, QueryParameters>(
        'InfoService',
        'POST',
        '/fetchRaceDayPoolInfo',
        { date, trackId }
    );

    if (!response.success) {
        throw new Error(response.errorMessage);
    }

    return response.data;
};

const initialData = {
    date: '',
    mergedPools: [],
    track: {} as Label,
    trackId: 0,
} as MergedPoolsData;

const useMergedPoolsQuery = ({ date, trackId, enabled = true }: UseMergedPoolsQueryProps) => {
    const { status, data, error, isSuccess, isLoading } = useQuery<unknown, Error, MergedPoolsData>(
        {
            queryKey: ['mergedPools', date, trackId],
            queryFn: () => fetchMergedPools({ date, trackId }),
            staleTime: 5 * 60 * 1000,
            enabled,
        }
    );

    return {
        status,
        data: (data ?? initialData).mergedPools,
        error,
        isSuccess,
        isLoading,
    };
};

export default useMergedPoolsQuery;
