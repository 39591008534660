import React, { MouseEvent } from 'react';
import styled from 'styled-components';
import { BACKGROUNDS, COLORS } from '../../themes';
import Text from 'ui/Text';
import useUnwrappedModals from 'common/hooks/useUnwrappedModals';
import { MODALS, SCREENS } from 'features/Spilklub/config';
import { Group } from './server';
import { getUrl, showPopup } from 'utils/navigation';
import { isMobile } from 'utils/platforms';

const GroupShortcutWrapper = styled.div`
    border-radius: 20px;
    padding: 7px 15px 7px 7px;
    background: ${BACKGROUNDS.lightGrey};
    display: flex;
    box-sizing: border-box;
    border: 1px solid #70707033;
    min-width: 150px;
    min-height: 56px;
    align-items: center;
`;

const GroupShortcutTextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 7px;
`;

const GroupShortcutTitle = styled(Text)`
    font-weight: 900;
    color: ${COLORS.black};
    padding: 0;
`;

const GroupShortcutText = styled(Text)`
    color: ${COLORS.black};
    font-weight: normal;
    padding: 0;
    font-size: 10px;
    min-height: 12px;
`;

const GroupAvatar = styled.img`
    border-radius: 13px;
    width: 40px;
    height: 40px;
`;

const GroupShortcut = ({ group }: { group: Group }) => {
    const { show: showUnwrappedModal } = useUnwrappedModals();

    const goToGroup = (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();

        const modalProps = {
            showScreens: [SCREENS.GROUPS],
            groupId: group.id,
            preventGoingBackOnClose: true,
        };

        if (isMobile) {
            showUnwrappedModal(MODALS.BET_BUDDY_ENTRY, modalProps);
        } else {
            showPopup('SPILKLUB_DESKTOP', modalProps);
        }
    };

    return (
        <GroupShortcutWrapper onClick={goToGroup}>
            <GroupAvatar src={getUrl(group.image)} />
            <GroupShortcutTextWrapper>
                <GroupShortcutTitle>{group.name}</GroupShortcutTitle>
                <GroupShortcutText>{group.info}</GroupShortcutText>
            </GroupShortcutTextWrapper>
        </GroupShortcutWrapper>
    );
};

export default GroupShortcut;
