export const setState = (self, updatedState) => {
    return new Promise((resolve) => {
        return self.setState(updatedState, resolve);
    });
};
export const setTimeoutPromise = (promise, ms = 0) =>
    new Promise((resolve, reject) => {
        setTimeout(
            () =>
                promise
                    .then((promiseValue) => {
                        return resolve(promiseValue);
                    })
                    .catch((e) => reject(e)),
            ms
        );
    });
//@see https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
export const makeCancelable = (promise) => {
    let hasCanceled_ = false;

    const wrappedPromise = new Promise((resolve, reject) => {
        promise.then(
            (val) => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
            (error) => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error))
        );
    });

    return {
        promise: wrappedPromise,
        cancel() {
            hasCanceled_ = true;
        },
    };
};

export const setTimeoutFactory = (promise, ms) => () => setTimeoutPromise(promise, ms);
