import { HIDE_KEYBOARD, SHOW_KEYBOARD } from './actions';

const initialState = {
    visible: false,
    value: '',
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_KEYBOARD:
            return {
                ...state,
                visible: true,
                value: '',
            };
        case HIDE_KEYBOARD:
            return {
                ...state,
                visible: false,
                value: action.payload,
            };
        default:
            return { ...state };
    }
};
