import styled, { css, keyframes } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { sidebar } from 'configs/layout';
import { COLORS, BACKGROUNDS } from 'themes/index.ts';
import { FilledWrapper } from 'ui/Wrapper';
import { priority } from 'configs/layout';

const fadeIn = keyframes`
    0% {
        opacity: 0;
        transform: scale(0);
        position:absolute;

    }
    100% {
        opacity: 1;
        transform: scale(1);
        position:relative;

    }
`;
const fadeOut = keyframes`
    0% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
        opacity: 0;
        transform: scale(0);
        position:absolute;

    }
`;

export const DesktopSidebarNav = styled.nav`
    width: ${sidebar.width}px;
    height: 100%;
    background: ${BACKGROUNDS.navigation};
    z-index: ${priority.leftSidebar};
    position: fixed;
    color: white;
    box-sizing: border-box;
    overflow: hidden;
    scrollbar-width: thin;
    transition: all 0.4s linear;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    &:hover {
        -webkit-overflow-scrolling: auto;
        overflow-y: auto;
        overflow-x: hidden;
    }
    &::-webkit-scrollbar {
        width: 2px;
        border-radius: 5px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        border-radius: 5px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 5px;
    }

    &.closed-sidebar {
        width: ${sidebar.widthClosed}px;
    }

    .closed {
        animation: ${fadeOut} 0.1s linear;
        animation-fill-mode: forwards;
        display: none;
    }
    .open {
        animation: ${fadeIn} 0.1s linear;
        animation-fill-mode: forwards;
    }
`;

export const DesktopSideWrapper = styled.div`
    width: ${sidebar.width};
    transition: all 0.4s linear;
    padding: 0 0 50px 0;
`;

export const DesktopSidebarUL = styled.ul`
    list-style-type: none;
    margin: 0 0 10px 0;
    padding: 0 8px;
`;

const NavbarItemCSS = css`
    padding: 0 20px;
    display: flex;
    cursor: pointer;
    height: ${({ noicon }) => (noicon ? '30px' : '44px')};
    font-size: 14px;
    font-weight: 600;
    color: ${COLORS.white};
    text-decoration: none;
    align-items: center;
    border-radius: 5px;
    transition: all 0.4s linear;
    margin: 6px 0 0 0;
    box-sizing: border-box;
    position: relative;
    &:hover {
        background: #1abb6d;
    }
    &:hover svg,
    &:hover svg path,
    &:hover svg circle,
    &:hover svg g {
        opacity: 1;
    }
    &.active {
        color: ${BACKGROUNDS.white};
    }
    & .arrow-right {
        position: absolute;
        right: 0;
        opacity: 0;
        transition: all 300ms ease;
    }
    &:hover .arrow-right {
        opacity: 0.7;
    }
    p {
        margin: 0 0 0 ${({ noicon }) => (noicon ? '0' : '17px')};
    }
    .closed-sidebar & {
        justify-content: center;
    }
    &.active::before {
        content: ' ';
        position: absolute;
        left: -8px;
        top: 0px;
        height: 46px;
        width: 3px;
        background: rgb(251, 214, 0);
        border-radius: 0px 3px 3px 0px;
    }
`;

export const DesktopSidebarLI = styled(NavLink)`
    ${NavbarItemCSS};
`;

export const DesktopSidebarLink = styled.a`
    ${NavbarItemCSS};
`;

export const SidebarIcon = styled.span`
    min-width: 21px;

    & svg {
        opacity: ${({ active }) => (active ? 1 : '0.8')};
        fill: white;
    }
    & svg path {
        fill: white;
        opacity: ${({ active }) => (active ? 1 : '0.8')};
    }
    & svg g {
        fill: white;
        opacity: ${({ active }) => (active ? 1 : '0.8')};
    }
`;

export const HorizontalDelimiter = styled.div`
    width: 100%;
    height: 1px;
    margin: 0 0 10px;
    background-color: rgba(255, 255, 255, 0.1);
`;

export const ProductCard = styled.div`
    height: 50px;
    position: relative;
    border-radius: 10px;
    margin: 0 8px 25px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    clear: both;
    background-color: ${({ background }) => background || '#EC433D'};
    clip-path: inset(-100vw -100vw 0 -100vw);
    &:last-child {
        margin-bottom: 140px;
    }
    img:first-child {
        height: 23px;
    }
    cursor: pointer;
    .closed-sidebar & {
        justify-content: center;
        padding: 0;
    }
`;
export const ProductImage = styled.img`
    width: 110px;
    float: right;
    bottom: -12px;
    right: 10px;
    position: absolute;
`;
export const ProductPopupWrapper = styled(FilledWrapper)`
    z-index: 16;
    background: rgba(0, 0, 0, 0.45);
    top: 0;
    left: 0;
`;
export const ProductPopupContainer = styled.div`
    height: 415px;
    width: 790px;
    border-radius: 15px;
    background-color: ${({ background }) => background || '#a9a9a9'};
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
export const PopupContent = styled.div`
    height: 100%;
    width: 100%;
    padding: 55px 90px;
    box-sizing: border-box;
    color: white;

    h4 {
        font-size: 28px;
        font-weight: bold;
    }
    p {
        font-size: 14px;
        max-width: 44%;
        font-weight: normal;
    }
    button {
        color: white;
    }
`;
export const PopupMainImage = styled.img`
    position: absolute;
`;
export const PopupButton = styled.a`
    text-decoration: none;
    text-align: center;
    width: 136px;
    height: 35px;
    margin-top: 20px;
    display: block;
    box-sizing: border-box;
    padding: 10px 25px;
    background: #eacc43;
    color: #333333;
    font: Bold 13px/15px 'Roboto Condensed';
    text-transform: uppercase;
    box-shadow: 0 0 25px #00000029;
    border-radius: 15px;
    &:active {
        text-decoration: none;
    }
    &:hover {
        text-decoration: none;
    }
`;
export const CloseButton = styled.div`
    position: absolute;
    top: -7px;
    right: -7px;
    height: 25px;
    width: 25px;
    border-radius: 25px;
    background: #eceff5;
    cursor: pointer;
    img {
        height: 10px;
        width: 10px;
        line-height: 25px;
        text-align: center;
        display: block;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
    }
`;
