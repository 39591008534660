import { useQuery } from '@tanstack/react-query';
import { request } from 'utils/server';

interface QueryParameters {
    date: string;
    raceNumber: number;
    trackId: number;
    product?: string;
}

interface SpilTipsData {
    date: string;
    races: Array<{
        ranking: {
            horseRanks: object;
        };
        tipText: string;
    }>;
}

const fetchSpilTips = async (params: QueryParameters) => {
    const response = await request<SpilTipsData, QueryParameters>(
        'InfoService',
        'GET',
        '/betting/tips',
        params
    );

    return response.data;
};

const useSpilTipsQuery = ({ date, raceNumber, trackId, product }: QueryParameters) => {
    const { status, data, error, isSuccess, isLoading } = useQuery<unknown, Error, SpilTipsData>({
        queryKey: ['spilTips', date, raceNumber, trackId, product],
        queryFn: () =>
            fetchSpilTips({
                date,
                raceNumber,
                trackId,
                ...(product && { product }),
            }),
        staleTime: 60 * 10 * 1000,
        enabled: !!date && !!trackId && !!raceNumber,
    });

    return {
        status,
        data,
        error,
        isLoading,
        isSuccess,
    };
};

const useTipsFromAPI = ({ date, raceNumber, trackId, product }: QueryParameters) => {
    const { data } = useSpilTipsQuery({ date, raceNumber, trackId, product });

    const ranks = data?.races[0]?.ranking?.horseRanks || {};

    const tipText = data?.races[0]?.tipText || '';

    const tipParagraphs = tipText
        .split(/<\/?p>/)
        .filter(text => text.trim() && text.trim() !== '&nbsp;') // Ignore empty strings and &nbsp
        .filter(Boolean);

    return {
        ranks,
        tips: tipParagraphs,
    };
};

export default useTipsFromAPI;
