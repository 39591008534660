import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import Receipt from './Receipt';
import { resetBet, setDerbyLynBet, setPrevAmount } from 'features/BetSlip2/state/actions';
import Modal from 'ui/ModalContent';
import { showPopup } from 'utils/navigation';
import usePlaceSameVPBet from 'features/BetSlip2/hooks/usePlaceSameVPBet';
import { isMobile } from 'utils/platforms';

const ReceiptMobile = ({
    history,
    modalData,
    hideModal,
    resetBet,
    setPrevAmount,
    setDerbyLynBet,
}) => {
    let enabledResettingOfBet = true;

    useEffect(() => {
        history.listen(location => {
            if (location.pathname === '/mine-spil' && isMobile) {
                hideModal('RECEIPT');
            }
        });

        enabledResettingOfBet = true;
        return () => {
            if (enabledResettingOfBet) {
                resetBet();
            }
        };
    }, []);

    const placeSameVPBet = usePlaceSameVPBet({ reset: resetBet });

    const placeBetHandler = (betSlip, date, trackCode, product, raceIndex, prevAmount) => {
        resetBet();
        enabledResettingOfBet = false;

        //if (!raceDay) {
        //@TODO: Navigate user to the first race day
        // extractFirstRaceDay();
        //setRaceDay(getDefaultRaceDay(raceDayData)); <- in the past we used similar approach with AISDataProvider
        //}

        placeSameVPBet(betSlip, date, trackCode, product, raceIndex, prevAmount);

        setPrevAmount(prevAmount);
        hideModal('RECEIPT');
        showPopup('BETSLIP_MOBILE');
    };

    const placeLynBetHandler = (date, trackCode, product) => {
        hideModal('RECEIPT');
        setDerbyLynBet(date, trackCode, product);
        history.push('/derby-lyn');
    };

    return (
        <Modal topBarShown style={{ height: 'auto' }}>
            <Receipt
                {...modalData}
                hideModal={hideModal}
                recipeMode={true}
                placeBetHandler={placeBetHandler}
                placeLynBetHandler={placeLynBetHandler}
            />
        </Modal>
    );
};

const mapDispatchToProps = dispatch => ({
    resetBet: () => {
        dispatch(resetBet());
    },
    setPrevAmount: amount => {
        dispatch(setPrevAmount(amount));
    },
    setDerbyLynBet: (date, trackCode, product) => {
        dispatch(setDerbyLynBet(date, trackCode, product));
    },
});

export default withRouter(connect(undefined, mapDispatchToProps)(ReceiptMobile));
