import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Wrapper from 'ui/Wrapper';
import { pageContainerWidth } from 'configs/layout';
import { scrollToTop } from 'utils/DOM';
import { byPlatform } from 'utils/platforms';

const PageContainer = ({ children, padding, background, maxWidth, boxSizing }) => {
    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <Wrapper
            padding={padding ?? byPlatform('0', '30px')}
            margin="0 auto"
            maxWidth={maxWidth ?? pageContainerWidth}
            background={background}
            boxSizing={boxSizing}
        >
            {children}
        </Wrapper>
    );
};

PageContainer.propTypes = {
    padding: PropTypes.string,
    background: PropTypes.string,
    maxWidth: PropTypes.string,
    boxSizing: PropTypes.string,
};

export default PageContainer;
