import React from 'react';
import { BACKGROUNDS } from 'themes/';
import {
    CloseModalBtn,
    CommonDesktopModalLayer,
    CommonDesktopModalWrapper,
} from 'ui/DesktopModalWrapper';
import FlexWrapper from 'ui/FlexWrapper';
import { priority } from 'configs/layout';

export default class ModalContainer extends React.Component {
    constructor() {
        super();

        this.state = {
            bg: BACKGROUNDS.primary,
            desktopModal: false,
            closeModalHandler: false,
        };

        this.scrollableWrapperRef = React.createRef();
    }

    setBackgroundHandler = (bg) => {
        this.setState({
            bg,
        });
    };

    useDesktopModal = () => {
        this.setState({
            desktopModal: true,
        });
    };

    useCustomCloseModalHandler = (handler) => {
        this.setState({
            closeModalHandler: handler,
        });
    };
    UNSAFE_componentWillMount() {
        if (this.props.modalData && this.props.modalData.useCustomCloseModalHandler) {
            this.useCustomCloseModalHandler(this.props.modalData.useCustomCloseModalHandler);
        }
    }
    componentDidMount() {
        window.document.body.style.overflow = 'hidden';
    }

    componentWillUnmount() {
        window.document.body.style.overflow = 'auto';
    }

    render() {
        const ModalComponent = this.props.ModalComponent;

        return (
            <CommonDesktopModalLayer
                priority={priority.desktopModalBase + this.props.priority}
                bg={this.state.bg}
                innerRef={this.scrollableWrapperRef}
            >
                {!this.state.desktopModal ? (
                    <ModalComponent
                        {...this.props.actions}
                        modalData={this.props.modalData}
                        setBackground={this.setBackgroundHandler}
                        useDesktopModal={this.useDesktopModal}
                        enableCloseButton={() => null}
                        scrollableWrapperRef={this.scrollableWrapperRef}
                        useCustomCloseModalHandler={this.state.closeModalHandler}
                    />
                ) : (
                    <FlexWrapper>
                        <CommonDesktopModalWrapper>
                            <CloseModalBtn
                                onClick={
                                    !this.state.closeModalHandler
                                        ? this.props.actions.hideModal.bind(this, this.props.id)
                                        : this.state.closeModalHandler
                                }
                            />
                            <ModalComponent
                                {...this.props.actions}
                                modalData={this.props.modalData}
                                setBackground={this.setBackgroundHandler}
                                useDesktopModal={this.useDesktopModal}
                                enableCloseButton={() => null}
                                useCustomCloseModalHandler={this.state.closeModalHandler}
                                style={{
                                    maxWidth: '1400px',
                                    margin: '0px auto',
                                }}
                            />
                        </CommonDesktopModalWrapper>
                    </FlexWrapper>
                )}
            </CommonDesktopModalLayer>
        );
    }
}
