import styled from 'styled-components';
import replaysThumb from 'images/stream/replays_thumbnail.jpg';

export const ReplayWrapper = styled.div`
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: black url(${replaysThumb}) no-repeat center center;
    background-size: cover;

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0.8;
        background: linear-gradient(to bottom, transparent, #000);
    }
`;
