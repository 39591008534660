import React from 'react';
import TrackProducts from './TrackProducts';
import {
    getSupportedProducts,
    hasProductOngoingRace,
    isAvailableForBetting,
    ProductId,
    RaceDay,
    Shortcut,
} from '../server/calendar';
import useShortcutState from '../hooks/useShortcutState';
import { getJackpotProducts } from './Shortcuts/helpers';

import { NEW_TRACK_PAGE } from 'configs/main';
import { generateEventPath, history } from 'utils/navigation';

interface TrackProductsContainerProps {
    raceDay: RaceDay;
}

const BettingProducts = ({ raceDay }: TrackProductsContainerProps) => {
    const supportedProducts = getSupportedProducts(raceDay);

    const { setShortcut } = useShortcutState();

    const navigateToProduct = (productId: ProductId) => {
        if (NEW_TRACK_PAGE) {
            return history.push(
                generateEventPath({
                    date: raceDay.raceDayDate,
                    track: raceDay.trackName,
                    productId,
                    race: 1,
                })
            );
        }

        const productShortcut: Shortcut = {
            raceDate: raceDay.raceDayDate,
            trackId: raceDay.trackId,
            product: productId,
            trackName: raceDay.trackName,
            trackCode: raceDay.track.code,
        };
        setShortcut(productShortcut);
    };

    const handleDisable = (productId: ProductId) => {
        return hasProductOngoingRace(raceDay, productId);
    };
    return (
        <>
            {isAvailableForBetting(raceDay) && (
                <TrackProducts
                    handleDisable={handleDisable}
                    onProductClick={navigateToProduct}
                    products={supportedProducts}
                    productsWithJackpot={getJackpotProducts(raceDay.productsWithJackpot)}
                />
            )}
        </>
    );
};

export default BettingProducts;
