import React from 'react';
import { create } from 'zustand';

import { RaceNavigationLabel } from 'ui/RaceNavigation';
import Checkbox2 from 'ui/Checkbox2';
import { COLORS } from 'themes';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import { isAnyRaceStarted } from 'features/TrackPage/model/Race';

export const useVRaceOverview = create(set => ({
    overviewEnabled: true,
    toggleOverviewStatus: () =>
        set(state => ({
            overviewEnabled: !state.overviewEnabled,
        })),
}));

const OverviewCheckbox = () => {
    const { overviewEnabled, toggleOverviewStatus } = useVRaceOverview();

    const { productId, races, isVStrictProduct, racesLoading } = useTrackPage();

    const anyRaceStarted = isAnyRaceStarted(races);

    if (anyRaceStarted || !isVStrictProduct || racesLoading) {
        return null;
    }

    return (
        <div className="flex flex-end center-v">
            <RaceNavigationLabel style={{ color: COLORS.black }}>
                {productId} oversigt
            </RaceNavigationLabel>
            <Checkbox2
                className="flex-end"
                id="raceOverview"
                checked={overviewEnabled}
                onChange={toggleOverviewStatus}
                size={24}
                style={{ minWidth: '24px' }}
            />
        </div>
    );
};

export default OverviewCheckbox;
