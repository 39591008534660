import { useEffect, useState } from 'react';
import usePrevious from 'common/hooks/usePrevious';

const useBuffer = ({
    initial = [],
    onAdd = undefined,
    onRemove = undefined,
    onEmpty = undefined,
}) => {
    const [values, setValues] = useState(initial);

    const add = (key) => {
        if (Array.isArray(key)) {
            setValues([...values, ...key]);
        } else {
            setValues([...values, key]);
        }
    };

    const remove = (key) => {
        setValues(values.filter((si) => si !== key));
    };

    const purge = () => setValues([]);

    const previousCheckMap = usePrevious(values);

    useEffect(() => {
        if (!previousCheckMap) {
            return;
        }

        if (previousCheckMap.length < values.length) {
            onAdd && onAdd();
        }

        if (previousCheckMap.length > values.length) {
            onRemove && onRemove();
        }

        if (previousCheckMap.length > 0 && values.length === 0) {
            onEmpty && onEmpty();
        }
    }, [values]);

    return { values, add, remove, purge, setValues };
};

export default useBuffer;
