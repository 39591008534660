import Track from 'common/DataObjects/Track';
import { chain, get, uniq } from 'lodash';
import { isMultitrackProduct } from 'common/selectors/multipleTrackSetupsSelector';
import raceDaySelector, { getMultitrackLegs } from 'common/selectors/raceDaySelector';
import { memoize } from 'lodash';
import racesSelector from 'common/selectors/racesSelector';
import { getPoolList } from 'common/selectors/poolListSelector';
import { dateStringFromDateTimeString } from 'utils/date.ts';

export function getTrackCode(state) {
    return get(state, 'AISDataProvider.racingCardData.trackCode');
}

/**
 * @param  {Object}        state
 * @param  {number|string} trackIdentity   Track id or track code
 * @param  {boolean}       hostTrack       Matters only for multitrack.
 *                                         If 'true' - will be host Track (suits for UI mostly)
 *                                         If 'false' - will be track with code Xa and id 40 (suits for API usage)
 *
 * @return {Track}                         Track data object
 */
export function getTrackByIdentity(state, trackIdentity, hostTrack = false) {
    if (!trackIdentity) {
        return new Track();
    }
    const trackData = state.AISDataProvider.tracks.byCode[trackIdentity];
    const code = hostTrack ? trackData.code : getTrackCode(state);
    return Track.fill({
        ...trackData,
        code,
        id: code === 'Xa' ? 40 : trackData.id,
        name: code === 'Xa' ? getCombinedTrackName(state) : trackData.name,
    });
}

export function getTracks(state) {
    return state.AISDataProvider.tracks.list;
}

const trackSelector = memoize(getTrackByIdentity, (state, trackIdentity) => trackIdentity);

/**
 * @param  {Object}        state
 * @return {Track}                         Track data object
 */
export function getTrackFromRacingCard(state) {
    const trackCode = getTrackCode(state);
    return getTrackByIdentity(state, trackCode);
}

export function getTrackFromRaceDay(state) {
    const track = state.AISDataProvider.selectedRaceDay?.track;
    return track || new Track();
}

export function getTrackBySelectedMultitrackRace(state) {
    const raceIndex = state.AISDataProvider.raceIndex;
    return getTrackByRaceIndex(state, raceIndex);
}

export function getTrackBySelectedRace(state) {
    if (isMultitrackProduct(state)) {
        return getTrackBySelectedMultitrackRace(state);
    }

    return getTrackFromRacingCard(state);
}

/**
 * The keys are the leg numbers
 * the values are the track codes
 * @param state
 */
export function getLegTrackMap(state) {
    const races = racesSelector(state);
    const map = {};

    races.forEach((r, i) => {
        map[i + 1] = getTrackByRaceIndex(state, i);
    });

    return map;
}

export function getTrackByRaceIndex(state, raceIndex) {
    const trackCode = getTrackCode(state);
    const track = trackSelector(state, trackCode);
    const isMultitrack = isMultitrackProduct(state);
    if (!isMultitrack) {
        return Track.fill(track);
    }
    const multitrackLegs = getMultitrackLegs(state);

    const trackId = multitrackLegs[raceIndex] && multitrackLegs[raceIndex].trackId;

    const trackByLeg = state.AISDataProvider.tracks.list.find((t) => t.code === trackId);

    if (!trackByLeg) {
        console.warn(
            `Multitrack pool track not found: ${trackId}. 
             Racing card track returned by default.`
        );
    }

    const trackAttributes = {
        ...(trackByLeg || track),
        multitrackCode: getTrackCode(state),
    };

    return Track.fill(trackAttributes);
}

export function getTrack(state) {
    return getTrackByRaceIndex(state, state.AISDataProvider.raceIndex);
}

/**
 *
 * @param  {Object}          state
 * @return {Array.<string>}          Array of the track codes including
 *                                   in current multi track pool
 */
export function getMultitrackTracks(state) {
    const productId = state.AISDataProvider.selectedProduct.id;
    let multitrackTracks = [];

    if (isMultitrackProduct(state)) {
        const raceDay = raceDaySelector(state);
        const multipleTrackSetup = raceDay.multipleTrackSetups[productId];
        if (!multipleTrackSetup) {
            return [];
        }
        multitrackTracks = multipleTrackSetup.trackIds.map((trackId) =>
            trackSelector(state, trackId)
        );
    }
    return multitrackTracks;
}

/**
 *
 * @param  {Object}          state
 * @return {Array.<string>}          Array of the track codes including
 *                                   in current multi track pool
 */
export function getMultitrackCodes(state) {
    const isMultitrack = isMultitrackProduct(state);

    if (!isMultitrack) {
        return [];
    }

    const races = racesSelector(state);
    const trackCodes = races.map((race) => race.hostTrack?.code);
    return uniq(trackCodes);
}
/**
 * @param  {Object} state
 * @return {string}
 */
export function getUITrackName(state) {
    const isMultitrack = isMultitrackProduct(state);

    if (!isMultitrack) {
        return state.AISDataProvider?.selectedRaceDay?.track?.name || '';
    }

    const raceIndex = state.AISDataProvider.raceIndex;

    const race = racesSelector(state)[raceIndex];

    if (!race) return '';

    return race.hostTrack?.domesticText;
}

export function getMultitrackTrackNames(state) {
    const isMultitrack = isMultitrackProduct(state);

    if (!isMultitrack) {
        return [];
    }

    const races = racesSelector(state);
    return races.map((race) => race.hostTrack?.domesticText);
}

/**
 * Gets combined track name in case of multitrack product
 * or simple track name if it's a simple product.
 * @param  {Object} state
 * @return {string}
 */
export function getCombinedTrackName(state) {
    const isMultitrack = isMultitrackProduct(state);

    if (!isMultitrack) {
        return state.AISDataProvider?.selectedRaceDay?.track?.name || '';
    }
    const trackNames = getMultitrackTrackNames(state);

    return uniq(trackNames).join(' / ');
}

export function getTracksJackpotAvailabilityMap(state) {
    const poolList = getPoolList(state);
    const selectedDate = raceDaySelector(state)?.date;

    return chain(poolList)
        .filter(({ date }) => {
            return dateStringFromDateTimeString(date) === selectedDate;
        })
        .groupBy('track.id')
        .map((trackPools, trackId) => {
            const hasJackpot = trackPools.some(
                (trackPool) => Number(get(trackPool, 'jackpot.sum')) > 0
            );

            return [Number(trackId), hasJackpot];
        })
        .fromPairs()
        .value();
}

export default trackSelector;
