import React from 'react';
import Text from 'ui/Text';
import styled, { css } from 'styled-components';
import { BACKGROUNDS } from 'themes';
import { isMobile } from 'react-device-detect';
import { ProductId } from '../server/calendar';

const labelStyle = css`
    border-radius: 5px;
    background-color: ${BACKGROUNDS.accent};
`;

const JackpotLabelMob = styled.div`
    ${labelStyle};
    height: 100%;
    padding: 3px 10px;
`;

const JackpotLabelDesk = styled.div`
    ${labelStyle};
    position: absolute;
    left: 50%;
    top: 75%;
    padding: 3px 0;
    width: 90%;
    box-sizing: border-box;
    transform: translateX(-50%);
`;

interface JackpotLabelProps {
    productsId?: Array<ProductId>;
}

const JackpotLabel = ({ productsId }: JackpotLabelProps) =>
    isMobile ? (
        productsId?.length ? (
            <>
                {productsId.map((productId, key) => (
                    <JackpotLabelMob key={key}>
                        <Text bold align={'center'} padding={'0'} size={'x1'}>
                            {productId} - Jackpot
                        </Text>
                    </JackpotLabelMob>
                ))}
            </>
        ) : null
    ) : (
        <JackpotLabelDesk>
            <Text bold align={'center'} padding={'0'} size={'x07'}>
                Jackpot
            </Text>
        </JackpotLabelDesk>
    );

export default JackpotLabel;
