import React, { useEffect } from 'react';
import styled from 'styled-components';
import { priority } from 'configs/layout';
import useBodyScrollLock from 'common/hooks/useBodyScrollLock.ts';
import { createPortal } from 'react-dom';
import Button from 'ui/controls/Button';
import { PlayerIcon } from 'features/RaceCard/RaceCardHeader';
import { setComponentsOptions } from 'common/actions/uiActions';
import { useDispatch } from 'react-redux';
import overlayArrowImage from './overlay-arrow.png';

const TransparentBackground = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background: rgba(0, 0, 0, 0.8);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: ${priority.overlay};
`;

const CloseButton = styled(Button).attrs({ size: 'x2', accentAlt: true })`
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, 0);
    z-index: ${priority.overlapOverlay - 1};
    font-weight: 900;
    width: 130px;
`;

const TooltipText = styled.div`
    color: white;
    font-size: 16px;
    font-weight: 900;
    width: 210px;
    text-align: center;
    margin: 0 auto;
    position: absolute;
    top: -130px;
    left: 0;
`;

const TooltipArrow = styled.img.attrs({ src: overlayArrowImage })`
    position: absolute;
    width: 34px;
    top: -111px;
    left: -27px;
`;

function LiveStreamTooltipOverlayed({ playerBtnRef }) {
    const dispatch = useDispatch();
    // important! fixes IOS bug with scrolling. just lock() not working
    window.document.body.style.position = 'fixed';

    const { top, left } = playerBtnRef.getBoundingClientRect() || {};
    const [lock, unlock] = useBodyScrollLock();

    useEffect(() => {
        lock();
        return () => {
            unlock();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeTooltip = e => {
        e.stopPropagation();
        unlock();
        dispatch(setComponentsOptions({ showLiveStreamTooltip: false }));
    };

    const stopPropagation = e => e.stopPropagation();

    return (
        <div onClick={stopPropagation}>
            {createPortal(
                <TransparentBackground>
                    <div
                        style={{
                            minHeight: '100vh',
                            height: '100%',
                            width: '100%',
                            overflow: 'scroll',
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                position: 'fixed',
                                top,
                                left,
                                height: 'auto',
                                width: 'auto',
                                zIndex: priority.overlapOverlay,
                            }}
                        >
                            <PlayerIcon />
                            <TooltipText>
                                Aben live stream og se løb fra Fast Track Live, ATG Nordic mm.
                            </TooltipText>
                            <TooltipArrow />
                        </div>
                    </div>
                </TransparentBackground>,
                document.body
            )}
            {createPortal(
                <div
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <CloseButton onClick={closeTooltip}>Forstået</CloseButton>
                </div>,
                document.body
            )}
        </div>
    );
}

export default LiveStreamTooltipOverlayed;
