import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import get from 'lodash/get';
import Highlights from 'layouts/sections/Highlights/Highlights';
import Highlight from 'common/DataObjects/Highlight';
import { today, toServerDate } from 'utils/date';
import Spinner from 'ui/Spinner';
import Wrapper from 'ui/Wrapper';
import useShortcutState from 'features/EventBoard/hooks/useShortcutState';
import useCalendarQuery from 'features/EventBoard/hooks/useCalendarQuery';
import { createProductShortcuts } from 'features/EventBoard/components/Shortcuts/helpers';
import useHighlightsQuery from './hook/useHighlights';
import { NEW_TRACK_PAGE } from 'configs/main';
import { generateEventPath } from 'utils/navigation';

const HighlightContainer = ({ mobile }) => {
    const [highlights, setHighlights] = useState(null);
    const history = useHistory();
    const { shortcut, setShortcut } = useShortcutState();

    const date = get(shortcut, 'raceDate', toServerDate(today()));

    const { data, isSuccess } = useCalendarQuery({ date });

    const productShortcuts = useMemo(
        () => (isSuccess ? createProductShortcuts(data?.shortCuts) : []),
        [data, isSuccess]
    );

    const { highlights: highlightsData } = useHighlightsQuery({ area: 'login', platform: 'web' });

    const defineHighlightClickHandler = ({ type, linkTo }) => {
        if (linkTo) {
            return type === 'read_more'
                ? () => history.push('/25-nyt/' + linkTo)
                : () => {
                      const productShortcut = productShortcuts.find(
                          shortcut => shortcut.product === linkTo
                      );

                      if (productShortcut && !NEW_TRACK_PAGE) {
                          setShortcut(productShortcut);
                      }

                      if (productShortcut && NEW_TRACK_PAGE) {
                          const { product, trackName, raceDate } = productShortcut;

                          history.push(
                              generateEventPath({
                                  productId: product,
                                  track: trackName,
                                  date: raceDate,
                              })
                          );
                      }
                  };
        }
    };

    useEffect(() => {
        if (highlightsData) {
            setHighlights(
                highlightsData.data.map(item => {
                    const highlight = Highlight.unserialize(item);
                    highlight.clickHandler = defineHighlightClickHandler(highlight);
                    return highlight;
                })
            );
        }
        //  eslint-disable-next-line
    }, [productShortcuts, highlightsData]); // temp solution

    return !highlights ? (
        <Wrapper padding="0 10px">
            <Spinner />
        </Wrapper>
    ) : highlights.length === 0 ? (
        <Wrapper padding="0 10px" />
    ) : (
        <Highlights data={highlights} mobile={mobile} />
    );
};

export default HighlightContainer;
