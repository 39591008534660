import React from 'react';

import DownloadButton from './DownloadButton';
import getTexts from 'utils/localization';
import { downloadFileByLink } from 'utils/DOM';

import usePdfDownload from './usePdfDownload';
import useATGPdfDownload from './useATGPdfDownload';

const t = getTexts();

const PdfDownload = () => {
    const { fileAvailable: tipsPDFAvailable, downloadPdf: downloadTipsPDF } = usePdfDownload({
        type: 'tips',
    });
    const { fileAvailable: playlistPDFAvailable, downloadPdf: downloadPlaylistPDF } =
        usePdfDownload({
            type: 'play-list',
        });

    const { pdfAvailable: ATGPageWithPDFAvailable, PDFLink: ATGLink } = useATGPdfDownload();

    const downloadPdf = () => {
        downloadFileByLink(ATGLink, 'baneprogram.pdf', 'application/pdf');
    };

    return (
        <div className="flex pr-10">
            {tipsPDFAvailable && (
                <DownloadButton onClick={downloadTipsPDF}>{t.PdfDownload.tips}</DownloadButton>
            )}
            {playlistPDFAvailable && (
                <DownloadButton onClick={downloadPlaylistPDF}>
                    {t.PdfDownload.playList}
                </DownloadButton>
            )}
            {ATGPageWithPDFAvailable && (
                <DownloadButton onClick={downloadPdf}>{t.PdfDownload.courseProgram}</DownloadButton>
            )}
        </div>
    );
};

export default PdfDownload;
