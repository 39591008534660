import { saveJSONparsing } from 'utils/formatters';
import moment from 'moment-timezone';
import { pickBy } from 'lodash';

class LocalMobileBet {
    name = 'mobileBets';
    bets = {};

    constructor() {
        let bets = localStorage.getItem(this.name);
        this.bets = bets ? saveJSONparsing(bets, {}) : {};
    }

    add(id, startDateTime) {
        if (this.bets[id] === undefined) {
            this.bets[id] = { startDateTime };
        }

        this.save();
    }

    delete(id) {
        if (this.bets[id]) {
            delete this.bets[id];

            this.save();
        }
    }

    count() {
        return this.getIds().actual.length;
    }

    getIds() {
        const actual = [];
        const toRemove = [];

        for (let key in this.bets) {
            if (this.bets.hasOwnProperty(key)) {
                const eventTime = this.bets[key].startDateTime;
                const shouldBeRemoved =
                    this.isOutdated(eventTime) &&
                    this.olderThanOneHour(eventTime);

                if (!eventTime || shouldBeRemoved) {
                    toRemove.push(key);
                } else {
                    actual.push(key);
                }
            }
        }

        return {
            actual,
            toRemove,
        };
    }

    getDenmarkLocalTime() {
        const timezone = 'Europe/Copenhagen';
        const format = moment.tz(timezone).format('YYYY-MM-DD HH:mm:ss');

        return moment(format).utc();
    }

    olderThanOneHour(eventDateTime) {
        const currentTime = this.getDenmarkLocalTime();
        const oneHourAfterEvent = moment(eventDateTime).add(90, 'minutes');

        return currentTime.isAfter(oneHourAfterEvent);
    }

    isOutdated(eventDateTime, delay = 1) {
        const currentTime = this.getDenmarkLocalTime();
        const eventTime = moment(eventDateTime).add(delay, 'minutes');

        return currentTime.isAfter(eventTime);
    }

    cleanIds(ids) {
        if (ids && Array.isArray(ids.toRemove)) {
            this.bets = pickBy(
                this.bets,
                (value, key) => ids.toRemove.indexOf(key) === -1
            );

            this.save();
        }
    }

    save() {
        localStorage.setItem(this.name, JSON.stringify(this.bets));
    }
}

export default new LocalMobileBet();
