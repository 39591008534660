import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Wrapper from 'ui/Wrapper';
import Text from 'ui/Text';
import styled from 'styled-components';
import Input from 'ui/controls/Input';
import Button from 'ui/controls/Button';
import { COLORS } from 'themes/index';
import { useHistory } from 'react-router-dom';
import PunterREST from 'common/api/PunterREST';
import NotificationConductor from 'common/conductors/NotificationConductor';
import FadeIn from 'ui/animations/FadeIn';
import { byPlatform, forDesktop } from 'utils/platforms';

const HeaderImage = styled.img`
    width: 100%;
`;

const TextWrapper = styled(Wrapper)`
    max-width: 600px;
    margin: 0 auto;
    padding: ${byPlatform('0 24px 40px 24px', '10px')};
`;

const Li = styled.li`
    font-weight: bold;
`;

const Buttons = styled.div`
    margin-top: 10px;
`;

const Btn = styled(Button)`
    padding: 15px 40px;
`;

const Link = styled(RouterLink)`
    color: ${COLORS.black};
    font-weight: bold;
`;

const animationStyle = {
    opacity: 0,
    transition: '300ms ease all',
    transform: 'translateY(-100%)',
    height: 0,
};

const Unsubscribe = () => {
    const [image, setImage] = useState();
    const [email, setEmail] = useState();
    const [unsubscribed, setUnsubscribed] = useState(false);
    const [animating, setAnimating] = useState(false);

    const history = useHistory();

    useEffect(() => {
        import('images/unsubscribe.png').then(module => {
            setImage(module.default);
        });
    }, []);

    useEffect(() => {
        unsubscribed && setAnimating(true);
    }, [unsubscribed]);

    const unsubscribe = () => {
        PunterREST()
            .disableNewsletterSubscription(email)
            .then(response => {
                if (response.success) {
                    setUnsubscribed(true);
                    NotificationConductor.success(`Du er nu afmeldt fra vores nyhedsbrev.`);
                } else {
                    NotificationConductor.error(
                        `Afmeldingen kunne ikke gennemføres. Prøv igen senere.`
                    );
                }
            });
    };

    const subscribe = () => {
        PunterREST()
            .enableNewsletterSubscription()
            .then(response => {
                if (response.success) {
                    setAnimating(false);
                    setUnsubscribed(false);
                    NotificationConductor.success(`Du er nu tilmeldt vores  nyhedsbrev.`);
                } else {
                    NotificationConductor.error(
                        `Tilmeldingen kunne ikke gennemføres. Prøv igen senere.`
                    );
                }
            });
    };

    return (
        <>
            {image && forDesktop(<HeaderImage src={image} alt="AFMELD BET25 HESTE’S NYHEDSBREV" />)}

            <TextWrapper style={animating ? animationStyle : undefined}>
                <h1>AFMELD BET25 HESTE’S NYHEDSBREV</h1>
                <Text black>
                    Vi er kede af, at du ikke længere ønsker at modtage Bet25 Heste’s nyhedsbrev. Du
                    har ellers en række fordele, når du er tilmeldt Bet25 Heste’s nyhedsbrev. Så er
                    du nemlig sikker på altid at få seneste nyt fra Bet25 Heste direkte i din
                    indbakke, såsom:
                </Text>
                <ul>
                    <Li>Kampagner om diverse tilbud</Li>
                    <Li>Konkurrencer</Li>
                    <Li>Skarpe spilanbefalinger fra René Kjær</Li>
                    <Li>Bet25 Heste Programmet</Li>
                    <Li>Vigtig produktinformation </Li>
                </ul>
                <Text black>
                    Alt dette går du glip af, hvis du ikke længere modtager Bet25 Heste’s
                    nyhedsbrev.
                </Text>
                <Text black bold>
                    Ønsker du fortsat at afmelde dig Bet25 Heste’s nyhedsbrev?
                </Text>

                <Text black bold style={{ paddingTop: '30px' }}>
                    Email adresse
                </Text>
                <Input
                    onChange={value => {
                        setEmail(value);
                    }}
                    type="text"
                    id="email"
                    placeholder={'Email adresse'}
                    margin="0"
                />

                <Buttons>
                    <Btn onClick={unsubscribe} accentAlt size={'x2'} bold>
                        Afmeld
                    </Btn>
                    <Btn
                        onClick={() => history.push('/')}
                        lightGrey
                        size={'x2'}
                        bold
                        style={{ color: COLORS.black }}
                    >
                        Annuller
                    </Btn>
                </Buttons>
            </TextWrapper>

            {unsubscribed && (
                <FadeIn>
                    <TextWrapper>
                        <h1 style={{ color: COLORS.primary }}>DU ER NU AFMELDT</h1>
                        <Text black>
                            Vi sender fra i dag ikke flere nyhedsbreve til tilbud og kampagner til
                            din mailboks.
                        </Text>
                        <Text black>
                            Har du fortrudt?{' '}
                            <span
                                className="text-underline pointer"
                                onClick={subscribe}
                                style={{ fontWeight: 700 }}
                            >
                                Tilmeld igen
                            </span>
                        </Text>
                        <Btn
                            onClick={() => history.push('/')}
                            accentAlt
                            size={'x2'}
                            bold
                            style={{ margin: '30px 0' }}
                        >
                            Tilbage til forsiden
                        </Btn>
                    </TextWrapper>
                </FadeIn>
            )}
        </>
    );
};

export default Unsubscribe;
