import React from 'react';
import LegPicks from './LegPicks';
import AISREST from 'features/AISDataProvider/AISREST';
import QuickPickDO from 'common/DataObjects/QuickPick';
import moment from 'moment';

const SmartLyn = ({ data, lockedLegs, toggleLegStatus }) => {
    const getLegsData = () =>
        data.raceNumbers.map((raceNumber, raceIndex) => ({
            selections: Object.keys(data.selections[raceIndex] || []),
            raceNumber,
        }));

    return getLegsData().map(({ selections, raceNumber }, index) => {
        return (
            <LegPicks
                key={raceNumber}
                raceNumber={raceNumber}
                index={index + 1}
                selections={selections}
                toggleLegStatus={toggleLegStatus}
                lockedLegs={lockedLegs}
            />
        );
    });
};

export default SmartLyn;

export const getDerbyLynBySmartLyn = (smartLynBet) => {
    return AISREST.fetchAllPoolInformation().then((res) => {
        if (res.success) {
            const quickPickJSON = res.data.find((item) => {
                return (
                    item.product.code === smartLynBet.product.id &&
                    (!smartLynBet.multitrack
                        ? item.track.code === smartLynBet.trackCode
                        : item.trackId === smartLynBet.multitrack.trackId) &&
                    moment(item.startDate).format('YYYY-MM-DD') === smartLynBet.date
                );
            });
            console.log(quickPickJSON, smartLynBet.product.id);

            if (quickPickJSON) {
                return QuickPickDO.unserialize(quickPickJSON);
            } else {
                return false;
            }
        }
    });
};
