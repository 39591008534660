import { useEffect } from 'react';
import { setUIProps } from '../actions';
import { useDispatch } from 'react-redux';
import { history } from 'utils/navigation';

const useSharedLink = (sharedLink: boolean) => {
    const poolHash = history.location.pathname.split('/')[2];

    const dispatch = useDispatch();

    useEffect(() => {
        if (sharedLink && poolHash) {
            dispatch(setUIProps({ poolHash }));
        }
    }, []);
};

export default useSharedLink;
