import React from 'react';
import { ContentHeight, FullScreen } from 'ui/FullScreen';
import TopBar from 'ui/topbar';
import Wrapper from 'ui/Wrapper';
import TopBarText from 'ui/topbar/Text';
import getTexts from 'utils/localization';
import Newsletter from './index';
import { Icon } from 'features/UserArea/UserAreaMenu/styled';
import { withRouter } from 'react-router-dom';
import { isMobile } from 'utils/platforms';

const t = getTexts();

class NewsletterModal extends React.Component {
    componentDidMount() {
        isMobile && this.props.enableCloseButton();
    }

    render() {
        return (
            <FullScreen>
                {!this.props.desktop ? (
                    <ContentHeight>
                        <TopBar>
                            <TopBarText className="lg">
                                <Icon
                                    alt={t.userArea.nav.subscriptions}
                                    isubscr
                                    className="text-right"
                                    color="white"
                                    size="x2"
                                />
                                {t.userArea.nav.subscriptions}
                            </TopBarText>
                        </TopBar>
                    </ContentHeight>
                ) : null}
                <Wrapper>
                    <Newsletter />
                </Wrapper>
            </FullScreen>
        );
    }
}

export default withRouter(NewsletterModal);
