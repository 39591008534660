import UserConductor from 'common/conductors/UserConductor';
import { getUrl, getURLParameter, showPopup } from 'utils/navigation';
import NotificationConductor from 'common/conductors/NotificationConductor';
import persistentStorage from 'common/storage';
import { trackEvent } from 'utils/tracking';
import { isMobile } from 'utils/platforms';

export function mitIdSuccessHandler(showModal, history, match, onSuccess, showPinPopup) {
    if (match?.params?.type === 'signup') {
        UserConductor.register()
            .then(res => {
                if (res.success) {
                    // track auto login after sign up
                    UserConductor.isUserLoggedIn().then(res => {
                        const user = res;
                        if (res) {
                            console.log(user, 'user');
                            if (isMobile) {
                                showPinPopup();
                            }
                            trackEvent(
                                {
                                    event: 'derby25.completeRegistration',
                                    userId: user.id,
                                },
                                true
                            );
                            persistentStorage.save({ isItInitialLogin: true });
                        }
                    });
                    NotificationConductor.success('Velkommen til Bet25 Heste');

                    // if (window.dataLayer) {
                    //     window.dataLayer.push({
                    //         event: 'brand.completeRegistration',
                    //     });
                    // }

                    onSuccess();
                } else {
                    mitIdErrorHandler(showModal, history);
                }
            })
            .catch(error => {
                console.log(error);
                mitIdErrorHandler(showModal, history);
            });
    } else {
        // track login after mit id redirect
        UserConductor.isUserLoggedIn().then(res => {
            if (res) {
                const user = res;
                trackEvent({ event: 'Login', userId: user.id }, true);
                persistentStorage.save({ isItInitialLogin: true });
                redirectBack(history);
                console.log(user, 'user');
                if (isMobile) {
                    showPinPopup();
                }
            }
        });
    }
}

export function mitIdErrorHandler(showModal, history) {
    showModal('STATUS_OF_ACTION', 1, {
        success: false,
        title: `Mit ID verification failed`,
        text: getURLParameter('error'),
        history,
    });
}

export function saveMitIdRedirectURL(mitIdRedirectUrl) {
    persistentStorage.saveLS({ mitIdRedirectUrl });
}

export function resetMitIdRedirectURL() {
    persistentStorage.removeLS(['mitIdRedirectUrl']);
}

export function redirectBack(history) {
    // we should rework all redirects to use this single mitIdRedirectUrl local storage value
    const mitIdRedirectUrl = persistentStorage.getLS('mitIdRedirectUrl');
    const spilklubGroupURL = persistentStorage.getLS('spilklubGroupURL');
    const lastRaceURL = persistentStorage.getLS('lastRaceURL');
    const lastURLBeforeLogin = persistentStorage.get('lastURLBeforeLogin');

    persistentStorage.removeLS(['mitIdRedirectUrl', 'spilklubGroupURL', 'lastRaceURL']);

    if (mitIdRedirectUrl) {
        history.push(mitIdRedirectUrl);
    } else if (spilklubGroupURL) {
        // history.push leads to inavailability to push back somewhere after spilklub
        // modal is closed (stays at /SPK/**)
        window.location.pathname = getUrl(spilklubGroupURL);
    } else if (lastURLBeforeLogin && lastRaceURL) {
        history.push(lastRaceURL);
        showPopup('BETSLIP_MOBILE');
    } else if (lastRaceURL) {
        // there is an issue when history.push to race url leads to wrong event
        // this function is called when user is already redirected to "/",
        // so app already calls AIS endpoints and actions so call to history.push
        // occurs somewhere between the requests
        window.location.pathname = getUrl(lastRaceURL);
    } else {
        history.push('/');
    }
}
