import React from 'react';
import { Horse } from 'features/TrackPage/model/Race';

interface StarterColProps {
    horse: Horse;
}

const StarterCol = ({ horse }: StarterColProps) => {
    const stat = horse.horseStat;

    if (!stat?.total) {
        return <>-</>;
    }

    return (
        <>
            {stat
                ? stat.total?.nrOfStarts +
                  ' ' +
                  stat.total?.first +
                  '-' +
                  stat.total?.second +
                  '-' +
                  stat.total?.third
                : 'n/a'}
        </>
    );
};

export default StarterCol;
