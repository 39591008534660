export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const alphabeticRegex = /^[a-zA-Z æøåöÆØÅÖ]+$/;
export const alphaNumeric = /^[0-9a-zA-ZæøåöÆØÅÖ ]*$/;
export const numericRegex = /^\d+$/;
export const hasNumber = /\d/;
export const passwordRegex = /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]))(?=.{6,})/;
export const userNameRegex = /^((?=.*[a-zA-Z]))(?=.{6,})/;

/**
 * Creates a regexp escaping slashes and replacing asterisk '*' with 'w+'
 * WARNING: it does not completely repeats JS native match function.
 *          It's a helper for simple checking partial substrings.
 *          Don't use it for complex regexp's.
 *          It will replace asterisks with w+ expression.
 * @param simplifiedRegex Regexp string like '/spk/*'
 * @param flags           String of regexp flags that will be used for RegExp constructor second argument
 *                        see https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/RegExp
 * @return {RegExp}
 */
export const createSimplifiedRegexp = (simplifiedRegex, flags = 'g') =>
    new RegExp(simplifiedRegex.replace('*', '\\w+').replace('/', '\\/'), flags);

/**
 * Checks if string matched regexp created with createSimplifiedRegexp function.
 * WARNING: it does not completely repeats JS native match function.
 *          It's a helper for simple checking partial substrings.
 *          Don't use it for complex regexp's.
 *          It will replace asterisks with w+ expression.
 * @param str
 * @param simplifiedRegex Regexp string like '/spk/*'
 * @param flags           String of regexp flags that will be used for RegExp constructor second argument
 *                        see https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/RegExp
 * @return Array<string>  Array of matching strings just like JS native match function does
 */
export const simplifiedMatch = (str, simplifiedRegex, flags = 'g') =>
    str.match(createSimplifiedRegexp(simplifiedRegex, flags));
