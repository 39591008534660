import React from 'react';
import styled from 'styled-components';
import { byPlatform } from 'utils/platforms';

const ApplePayBtnWrapper = styled.div`
    border-radius: 10px;
    cursor: pointer;
    margin: 0 auto;
    width: auto;
    min-width: 100%;
    height: 55px;
    border: 3px solid ${({ active }) => (active ? '#0daa13' : '#000')};
    background: #eceff6;

    @supports (-webkit-appearance: -apple-pay-button) {
        .apple-pay-button {
            border-radius: 7px;
            display: inline-block;
            width: 100%;
            height: 100%;
            -webkit-appearance: -apple-pay-button;
        }

        .apple-pay-button > * {
            display: none;
        }

        .apple-pay-button-black {
            -apple-pay-button-style: black;
        }

        .apple-pay-button-white {
            -apple-pay-button-style: white;
        }

        .apple-pay-button-white-with-line {
            -apple-pay-button-style: white-outline;
        }
    }

    @supports not (-webkit-appearance: -apple-pay-button) {
        .apple-pay-button {
            --apple-pay-scale: 1; /* (height / 32) */
            display: inline-flex;
            justify-content: center;
            font-size: 12px;
            border-radius: ${byPlatform('12px', 0)};
            padding: 0;
            box-sizing: border-box;
            min-height: 32px;
            max-height: 64px;
        }

        .apple-pay-button-black {
            background-color: black;
            color: white;
        }

        .apple-pay-button-white {
            background-color: white;
            color: black;
        }

        .apple-pay-button-white-with-line {
            background-color: white;
            color: black;
            border: 0.5px solid black;
        }

        .apple-pay-button.apple-pay-button-black > .logo {
            background-image: -webkit-named-image(apple-pay-logo-white);
            background-color: black;
        }

        .apple-pay-button.apple-pay-button-white > .logo {
            background-image: -webkit-named-image(apple-pay-logo-black);
            background-color: white;
        }

        .apple-pay-button.apple-pay-button-white-with-line > .logo {
            background-image: -webkit-named-image(apple-pay-logo-black);

            background-color: white;
        }

        .apple-pay-button > .text {
            font-family: -apple-system;
            font-size: calc(1em * var(--apple-pay-scale));
            font-weight: 300;
            align-self: center;
            margin-right: calc(2px * var(--apple-pay-scale));
        }

        .apple-pay-button > .logo {
            width: calc(35px * var(--scale));
            height: 100%;
            background-size: 100% 60%;
            background-repeat: no-repeat;
            background-position: 0 50%;
            margin-left: calc(2px * var(--apple-pay-scale));
            border: none;
        }
    }
`;

const ApplePayBtn = ({ active, onClick }) => (
    <ApplePayBtnWrapper active={active} onClick={onClick}>
        <div className="apple-pay-button apple-pay-button-black">
            <span className="logo" />
        </div>
    </ApplePayBtnWrapper>
);

export { ApplePayBtn, ApplePayBtnWrapper };
