import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Col, Row } from 'react-grid-system';

import Wrapper from 'ui/Wrapper';
import PlayableThumbnail from 'ui/YouTubeVideo/PlayableThumbnail';
import Text from 'ui/Text';
import Button from 'ui/controls/Button';
import FadeIn from 'ui/animations/FadeIn';
import Hr from 'ui/Hr';
import { BACKGROUNDS, COLORS } from '../themes';

import useModals from 'common/hooks/useModals';
import { CMSImage } from 'common/api/CMSAPI/utils';
import useUnwrappedModals from 'common/hooks/useUnwrappedModals';
import { MODALS, SCREENS } from 'features/Spilklub/config';
import { clearGroupsReducer } from 'features/Spilklub/actions';
import useExpertsData from 'scenes/Experts/useExpertsData';
import { byPlatform, forMobile, isDesktop } from 'utils/platforms';
import { showPopup } from '../utils/navigation';

const SKGuidePoint = styled(Row)`
    margin-bottom: 20px;
    margin-top: 20px;
`;

const SKGuideTextBlock = styled(Col).attrs({ md: 7, sm: 5 })``;

const SKGuideText = styled(Text)`
    font-size: 14px;
`;

const SKGuideVideoBlock = styled(Col).attrs({ md: 5, sm: 7 })`
    display: flex;
    justify-content: flex-end;
`;

const SKGuideBtn = styled(Button)`
    text-transform: uppercase;
    font-weight: bold;
    padding: 15px 30px;
    background: ${BACKGROUNDS.accentAlt};
    border-radius: 15px;
    color: ${COLORS.black};
    margin: 20px 0;
    width: ${byPlatform('100%', 'auto')};
`;

interface ItemProps {
    title: string;
    text: string;
    videoId: string;
}

const Item = ({ title, text, videoId }: ItemProps) => {
    const { show } = useModals();
    const { show: showUnwrappedModal } = useUnwrappedModals();
    const dispatch = useDispatch();

    const playVideo = () =>
        show('YOUTUBE_PLAYER_POPUP', 0, {
            videoId,
            videoTitle: title,
        });

    const goToSpilKlub = () => {
        //@see https://ecosys.atlassian.net/browse/DER-2198?focusedCommentId=22344
        // fixes the issue described in the issue comment
        dispatch(clearGroupsReducer());

        if (isDesktop) {
            showPopup('SPILKLUB_DESKTOP', { showScreens: [SCREENS.GROUPS] });
        } else {
            showUnwrappedModal(MODALS.BET_BUDDY_ENTRY, {
                showScreens: [SCREENS.GROUPS],
                preventGoingBackOnClose: true,
            });
        }
    };

    return (
        <SKGuidePoint>
            <SKGuideTextBlock>
                <Text black bold size={'x2'} style={forMobile({ lineHeight: '24px', padding: 0 })}>
                    {title}
                </Text>
                <SKGuideText black style={forMobile({ lineHeight: '18px' })}>
                    {text}
                </SKGuideText>
                <SKGuideBtn onClick={goToSpilKlub}>Opret hold</SKGuideBtn>
            </SKGuideTextBlock>
            <SKGuideVideoBlock onClick={playVideo}>
                <PlayableThumbnail videoId={videoId} size={'250px'} playIconSize={'80px'} />
            </SKGuideVideoBlock>
        </SKGuidePoint>
    );
};

const HowSpilKlubWorks = () => {
    const { banner, pageContent } = useExpertsData(false, true);

    return (
        <Wrapper padding="30px" margin={'0 auto'}>
            <FadeIn>
                <img
                    src={CMSImage(banner)}
                    alt={'Bet25 heste Experts'}
                    style={{ maxWidth: '100%', borderRadius: byPlatform('5px', '30px') }}
                />
            </FadeIn>
            <div style={{ padding: byPlatform('0', '0 50px') }}>
                <Item
                    title={pageContent?.section1?.title || ''}
                    text={pageContent?.section1?.text || ''}
                    videoId={pageContent?.section1?.videoLink || ''}
                />
                <Hr />
            </div>

            <div style={{ padding: byPlatform('0', '0 50px') }}>
                <Item
                    title={pageContent?.section2?.title || ''}
                    text={pageContent?.section2?.text || ''}
                    videoId={pageContent?.section2?.videoLink || ''}
                />
                <Hr />
            </div>
        </Wrapper>
    );
};

export default HowSpilKlubWorks;
