import React from 'react';
import styled from 'styled-components';
import { COLORS } from 'themes';
import { Col, Row } from './styled';
import getTexts from 'utils/localization';
import { byPlatform } from 'utils/platforms';

const t = getTexts();

const HeaderRow = styled(Row)`
    background: #333;
    color: ${COLORS.white};
    text-transform: uppercase;
    height: 18px;
    line-height: 18px;
    padding: 2px 0;
`;
const GameHeaderCol = styled(Col)`
    font: normal ${byPlatform('14px/14px', '10px/10px')} 'Roboto Condensed';
    letter-spacing: 0;
    color: ${COLORS.white};
    text-transform: capitalize;
    font-weight: 900;
`;

const GameHeaderColCenter = styled(GameHeaderCol)`
    text-align: center;
`;

const VGameHeader = () => {
    return (
        <HeaderRow>
            <GameHeaderColCenter grow={0.05}>{t.RacingCard.vRaceHeader.nr}</GameHeaderColCenter>
            <GameHeaderCol grow={0.8}>{t.RacingCard.vRaceHeader.horse}</GameHeaderCol>
            <GameHeaderColCenter grow={0.1}>
                {t.RacingCard.vRaceHeader.selectAll}
            </GameHeaderColCenter>
        </HeaderRow>
    );
};

export default VGameHeader;
