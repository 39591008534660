import styled from 'styled-components';
import Text from 'ui/Text';
import { BACKGROUNDS } from 'themes';

const HeaderWrapper = styled.header`
    height: 60px;
    width: 100%;
    background: #0b7b43;
    // background: linear-gradient(to top, rgb(4, 163, 88), rgb(54, 181, 117));
    color: #fff;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
`;

const NavList = styled.ul`
    display: flex;
    height: 100%;
    align-items: center;
    flex-grow: 1;
`;

const NavListItem = styled.li`
    height: 100%;
    list-style-type: none;
`;

const NavListLink = styled.a`
    padding: 0 30px;
    display: flex;
    align-items: center;
    font-size: 20px;
    color: white;
    font-weight: 600;
    text-decoration: none;
    height: 100%;
    &.active {
        // color: #ebcd32;
        background: ${BACKGROUNDS.primaryFlat};
    }
`;

const TerminalBalance = styled.div`
    width: 200px;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    padding: 0 10px;
    border-radius: 10px;
`;

const SaldoText = styled(Text)`
    font-size: 15px;
    padding: 7px 10px;
    height: 25px;
    background: #fff;
    color: gray;
    border-radius: 10px 0 0 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const BalanceText = styled(Text)`
    font-size: 16px;
    padding: 7px 10px;
    height: 25px;
    background: #fff;
    color: #000;
    border-radius: 0 10px 10px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
`;

export {
    HeaderWrapper,
    NavList,
    NavListItem,
    NavListLink,
    TerminalBalance,
    SaldoText,
    BalanceText,
};
