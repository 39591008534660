import React from 'react';
import Articles from '../features/Articles/Articles';
import Wrapper from 'ui/Wrapper';
import { withRouter } from 'react-router-dom';
import { COLORS } from 'themes';

class News extends React.Component {
    onCardClick = (link) => {
        this.props.history.push('/25-nyt/' + link);
    };

    render() {
        const styles = {
            Headline: {
                padding: 0,
                fontWeight: 'bold',
                color: COLORS.primaryText,
                fontSize: '24px',
                fontFamily: 'Roboto Condensed',
            },
            StyledListItem: {
                maxWidth: '46%',
                margin: '25px 10px',
            },
            img: {
                height: '140px',
            },
            textWrapper: {
                padding: '15px',
            },
        };

        return (
            <Wrapper>
                <Articles
                    articleType="news"
                    itemsPerRow={2.5}
                    styles={styles}
                    onCardClick={this.onCardClick}
                />
            </Wrapper>
        );
    }
}

export default withRouter(News);
