import React from 'react';
import styled from 'styled-components';
import Wrapper from 'ui/Wrapper';
import Button from 'ui/controls/Button';
import { CMSPdf, BlobPdf } from 'features/RaceCard/PdfDownload';
import { COLORS } from 'themes';
import { forDesktop } from 'utils/platforms';
import getTexts from 'utils/localization';
import { BACKGROUNDS } from 'themes';

const t = getTexts();

const StyledContainer = styled(Wrapper)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    margin-left: 5px;
    margin-right: 5px;
`;

const StyledButton = styled(Button)`
    border-radius: 5px;
    text-transform: capitalize;
    margin-right: 5px;
    width: 100px;
    font-size: 10px;
    font-weight: 900;
    cursor: pointer;
    line-height: 14px;
    border: ${props =>
        props.specialRed ? `0.5px solid ${COLORS.special.red}` : '0.5px solid #c6ccdb'};
`;

const ReserveButton = styled(StyledButton)`
    transition: background 300ms ease;
    background: ${({ reserveMode }) => (reserveMode ? BACKGROUNDS.blue : BACKGROUNDS.lightGrey)};
    color: ${({ reserveMode }) => (reserveMode ? COLORS.white : COLORS.black)};
    &:hover {
        background: ${({ reserveMode }) => (reserveMode ? BACKGROUNDS.blue : '#c6ccdb')};
    }
`;

const PdfDownloadSection = styled(Wrapper)`
    display: flex;
    padding: 0;
`;

const VRaceSubHeader = props => {
    const { selectedProduct } = props.raceCardData;
    const { toggleReserveMode, reserveModeEnabled } = props;

    const clearStartsSelecting = () => {
        props.raceCardData.resetBet();
    };

    return (
        <StyledContainer>
            <div>
                <StyledButton onClick={clearStartsSelecting} specialRed>
                    {t.RacingCard.controlArea.clearAll}
                </StyledButton>
                {selectedProduct.canManuallyReserve() && (
                    <ReserveButton
                        onClick={toggleReserveMode}
                        reserveMode={reserveModeEnabled}
                        grey
                    >
                        Reserver
                    </ReserveButton>
                )}
            </div>

            {forDesktop(
                <PdfDownloadSection>
                    <CMSPdf type="tips" />
                    <CMSPdf type="play-list" />
                    <BlobPdf />
                </PdfDownloadSection>
            )}
        </StyledContainer>
    );
};

export default VRaceSubHeader;
