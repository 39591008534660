import React from 'react';
import renderHTML from 'react-render-html';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import Spinner from 'ui/Spinner';
import Wrapper from 'ui/Wrapper';
import { DOMAIN_PATH } from 'configs/main';
import CMSApi from 'common/api/CMSAPI';
import facebookIcon from 'images/icons/facebook.png';
import SubscribeButton from './SubscribeButton';
import { makeCancelable } from 'utils/promise';
import { byPlatform, isMobile, isTablet } from 'utils/platforms';

import {
    Author,
    DateText,
    ShareArticleText,
    ShareArticleWrapper,
    StyledArticleWrapper,
    StyledAuthorContainer,
    StyledAuthorImg,
    StyledCard,
    StyledCardImg,
    StyledHeadline,
    StyledIcon,
    TextBlockWithTable,
} from './styled';
import getTexts from 'utils/localization';
import { scrollToTop } from 'utils/DOM.ts';

const t = getTexts();

class Article extends React.Component {
    constructor(props) {
        super();

        this.prepareRequest(props);

        this.state = {
            content: null,
        };
    }

    requestPromiseCancelable = null;

    onRequestFailed = (reason) => {
        if (!reason.isCanceled) {
            this.props.history.push('/404');
            throw reason;
        }
    };

    prepareRequest = (props) => {
        this.requestPromiseCancelable = makeCancelable(
            CMSApi.getArticle({
                ...props.articleIds,
            })
        );
    };

    getData = () => {
        this.requestPromiseCancelable.promise
            .then((res) => {
                if (res.success && res.data) {
                    this.setState({
                        content: res.data,
                    });
                } else {
                    this.onRequestFailed({});
                }
            })
            .catch(this.onRequestFailed);
    };

    componentDidMount() {
        this.getData();
        (isMobile || isTablet) && scrollToTop();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.articleIds.link !== nextProps.articleIds.link) {
            this.prepareRequest(nextProps);
            this.getData();
        }
    }

    componentWillUnmount() {
        this.requestPromiseCancelable.cancel();
    }

    render() {
        if (this.state.content) {
            const styles = this.props.styles || {},
                wrapperStyles = styles.Wrapper,
                cardStyles = styles.Card || {},
                article = this.state.content.article,
                author = this.state.content.author;
            const facebookShareSection = (
                <ShareArticleWrapper style={styles.ShareArticleWrapper}>
                    <ShareArticleText style={styles.ShareArticleText}>
                        {t.Article.shareArticle}
                    </ShareArticleText>
                    <ShareArticleText>| </ShareArticleText>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.facebook.com/Derby25dk"
                    >
                        <StyledIcon src={facebookIcon} />
                    </a>
                </ShareArticleWrapper>
            );

            return (
                <Wrapper style={wrapperStyles}>
                    <StyledCard
                        productId={article.product_id}
                        cardStyles={cardStyles}
                        style={cardStyles}
                    >
                        <StyledCardImg
                            imgUrl={DOMAIN_PATH + article.image}
                            style={styles.StyledCardImg}
                        />
                    </StyledCard>
                    <StyledArticleWrapper style={styles.StyledArticleWrapper}>
                        <StyledHeadline style={styles.StyledHeadLine}>
                            {article.header}
                        </StyledHeadline>

                        {article.subscribe_button !== 'hide' && (
                            <Wrapper>
                                <SubscribeButton
                                    visibility={article.subscribe_button}
                                    article={article}
                                />
                            </Wrapper>
                        )}

                        <TextBlockWithTable
                            size="x2"
                            color="black"
                            style={styles.StyledTextContainer}
                            data-test-id="description"
                        >
                            {renderHTML(article.description)}
                        </TextBlockWithTable>

                        <StyledAuthorContainer>
                            <StyledAuthorImg
                                src={DOMAIN_PATH + author.image}
                                style={styles.StyledAuthorImg}
                            />
                            <Wrapper>
                                <Author style={styles.AuthorData}>
                                    {author.name}, {author.position}
                                </Author>
                                <DateText style={styles.Date}>
                                    {t.Article.createdAt}{' '}
                                    {moment(article.created_at).format('DD.MM.YY, HH:MM')}
                                </DateText>
                            </Wrapper>
                        </StyledAuthorContainer>
                        {byPlatform(facebookShareSection, facebookShareSection, <div />)}
                    </StyledArticleWrapper>
                </Wrapper>
            );
        } else {
            return <Spinner />;
        }
    }
}

export default withRouter(Article);
