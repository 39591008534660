import withTransparentBackground from 'ui/hoc/withTransparentBackground';
import DatePicker from 'ui/DatePicker/Desktop';
import { CalendarDatePicker as EventBoardDatePicker } from 'features/EventBoard/components/DateSelection.tsx';
import { CalendarDatePicker as ResultsDatePicker } from 'features/ResultsNew/components/DateSelection.tsx';
import QuickPickAmount from 'features/QuickPick/QuickPickAmount';
import QuickPickReceipt from 'features/QuickPick/QuickPickReceipt/QuickPickReceiptModal';
import StatusOfAction from 'features/SystemHandler/StatusOfAction';
import Replay from 'features/RaceCard/RaceCardReplays/Replay';
import EditSubscriptionsPopup from 'features/Spilklub/Mobile/EditSubscriptionsPopup.tsx';
import LeaveGroupPopup from 'features/Spilklub/components/LeaveGroupPopup';
import SidebarPopup from '../layouts/sections/DesktopSidebar/SidebarPopup';
import PlayerPopup from 'ui/YouTubeVideo/PlayerPopup.tsx';
import PlayerPopup2 from 'ui/YouTubeVideo/PlayerPopup2.tsx';
import TempUser from 'features/Signup/TempUser';
import SessionExpired from 'features/Login/SessionExpired';
import LimitExceed from 'features/UserArea/Deposit/LimitExceed';
import LoginPrompt from 'features/Login/LoginPrompt';
import Signup from 'features/Signup/Modal';
import UserAlreadyExistPopup from 'features/Signup/UserExists';
import CPRConfirmModal from 'features/Login/CPRConfirmModal';
import RemoveBonusConfirmModal from 'features/UserArea/Withdraw/RemoveBonusConfirmModal';
import SpilIdModal from 'features/SpilID/SpilIdModal';
import PhysicalCardPreview from 'features/SpilID/PhysicalCardPreview';
import PhysicalCardForm from 'features/SpilID/PhysicalCardForm';
import SelfExcludedModal from 'features/UserArea/SelfExclusion/SelfExcludedModal';
import SpilIDPinCode from 'features/SpilID/SpilIDPinCode';
import ForgotPassword from 'features/Login/ForgotPassword';

const MapConfig = {
    // LOGIN: DesktopLogin,
    SIGNUP: Signup,
    DATE_PICKER: DatePicker,
    EVENT_BOARD_DATE_PICKER: withTransparentBackground(EventBoardDatePicker),
    RESULTS_DATE_PICKER: withTransparentBackground(ResultsDatePicker),
    QUICK_PICK_AMOUNT: QuickPickAmount,
    QUICK_PICK_RECEIPT: QuickPickReceipt,
    STATUS_OF_ACTION: StatusOfAction,
    REPLAY: Replay,
    EDIT_SUBSCRIPTIONS: EditSubscriptionsPopup,
    LEAVE_GROUP: LeaveGroupPopup,
    SIDEBAR_POPUP: SidebarPopup,
    YOUTUBE_PLAYER_POPUP: PlayerPopup,
    YOUTUBE_PLAYER_POPUP_2: PlayerPopup2,
    TEMP_USER: withTransparentBackground(TempUser),
    SESSION_EXPIRED: withTransparentBackground(SessionExpired),
    DEPOSIT_LIMIT_EXCEED: withTransparentBackground(LimitExceed),
    LOGIN_PROMPT: withTransparentBackground(LoginPrompt),
    USER_EXISTS: withTransparentBackground(UserAlreadyExistPopup),
    CPR_CONFIRMATION: CPRConfirmModal,
    REMOVE_BONUS_CONFIRM: withTransparentBackground(RemoveBonusConfirmModal),
    SPIL_ID: withTransparentBackground(SpilIdModal),
    SPIL_ID_PC_PREVIEW: withTransparentBackground(PhysicalCardPreview),
    SPIL_ID_PC_FORM: withTransparentBackground(PhysicalCardForm),
    SPIL_ID_PIN_CODE: withTransparentBackground(SpilIDPinCode),
    SELF_EXCLUDED_MODAL: withTransparentBackground(SelfExcludedModal),
    FORGOT_PASSWORD: withTransparentBackground(ForgotPassword),
};

const ModalLocationSettings = {
    LOGIN: {
        pathname: '/log-ind',
        goBackOnClose: true,
        goBackBlacklist: [{ pathname: '/promo/velkommen' }, { pathname: '/live-casino' }],
    },
};

export { MapConfig, ModalLocationSettings };
