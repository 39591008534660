import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ModalProps } from 'ui/types';
import Popup from 'ui/Popup';
import Player, { IframeProps } from './Player';
import useModals from '../../common/hooks/useModals';

interface PlayerPopupModalData {
    videoId: string;
    videoTitle: string;
}

interface PlayerPopupProps extends ModalProps<PlayerPopupModalData> {}

const RoundedIFrame = styled.iframe<IframeProps>`
    border-radius: 10px;
    height: 100%;
    width: 100%;
`;

const PlayerPopup = ({ modalData, setBackground }: PlayerPopupProps) => {
    useEffect(() => setBackground && setBackground('none'), []);

    const { hide } = useModals();

    return (
        <Popup onClose={() => hide('YOUTUBE_PLAYER_POPUP')}>
            <Player
                title={modalData.videoTitle}
                width={'600'}
                height={'339'}
                videoId={modalData.videoId}
                allowFullScreen={true}
                IframeComponent={RoundedIFrame}
            />
        </Popup>
    );
};

export default PlayerPopup;
