import { useState } from 'react';
import moment from 'moment';
import CMSAPI from 'common/api/CMSAPI';

const serializeRequest = ({ cardId, fullName, address, zipCode, birthdate, city }) => ({
    card_id: cardId,
    name: fullName,
    address,
    city_index: zipCode,
    app: 'bet_25',
    dob: moment(birthdate, 'DD-MM-YYYY').format('DDMMYYYY'),
    city,
});

const useOrderPhysicalCard = ({ cardId, fullName, address, zipCode, birthdate, city }) => {
    const [pending, setPending] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState();

    const requestCardOrder = async () => {
        try {
            setPending(true);

            const { success, msg } = await CMSAPI.orderSpilIdPhysicalCard(
                serializeRequest({
                    cardId,
                    fullName,
                    address,
                    zipCode,
                    birthdate,
                    city,
                })
            );

            if (success) {
                setSuccess(true);
            } else {
                setError(msg);
            }
        } catch (e) {
            console.log(e);
            setError('Unexpected error');
        } finally {
            setPending(false);
        }
    };

    return { requestCardOrder, pending, error, success };
};

export default useOrderPhysicalCard;
