import { RootEpic } from 'common/epic';
import { isActionOf } from 'typesafe-actions';
import { filter, switchMap, catchError, map } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { placeSystemBetAsync } from './actions';

export const systemBetEpic: RootEpic = (action$, state$, { api }) =>
    action$.pipe(
        filter(isActionOf(placeSystemBetAsync.request)),
        switchMap(() =>
            from(api.batchBetting.performBatchBet()).pipe(
                map((response) => placeSystemBetAsync.success(response)),
                catchError((error) => of(placeSystemBetAsync.failure(error)))
            )
        )
    );
