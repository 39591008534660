import { request } from 'utils/server';
import { useQuery } from '@tanstack/react-query';
import { Campaign } from './useCampaignsQuery';

interface Author {
    id: number;
    bio: string;
    image: string;
    name: string;
    position: string;
}

interface Response {
    article: Campaign;
    author: Author;
}

const fetchSingleCampaign = async ({ link }: { link: string }) => {
    const response = await request<Response, { link: string }>(
        'CMS',
        'GET',
        '/derby/rest/article/get-article',
        {
            link,
        }
    );

    if (!response.success) {
        throw new Error(response.errorMessage);
    }

    return response.data;
};

const useCampaignsQuery = ({ link }: { link: string }) => {
    const { status, data, error, isSuccess, isLoading } = useQuery({
        queryKey: ['single-campaign', link],
        queryFn: () => fetchSingleCampaign({ link }),
        staleTime: 60 * 60 * 1000,
    });

    return {
        status,
        data,
        error,
        isSuccess,
        isLoading,
    };
};

export default useCampaignsQuery;
