import React, { useEffect } from 'react';
import { CPRConfirmation } from '@it25syv/25syv-ui';
import useModals from 'common/hooks/useModals';
import { redirectBack } from 'features/Signup/helpers';
import UserConductor from 'common/conductors/UserConductor';
import NotificationConductor from 'common/conductors/NotificationConductor';
import { history } from 'utils/navigation';

const CPRConfirmModal = ({ setBackground, modalData }) => {
    const { hide } = useModals();

    useEffect(() => {
        setBackground('rgba(0,0,0,0.5)');
    }, [setBackground]);

    return (
        <CPRConfirmation
            onConfirm={() => {
                UserConductor.isUserLoggedIn().then(() => {
                    redirectBack(history);
                    hide('CPR_CONFIRMATION');
                });
            }}
            onError={(e) => {
                NotificationConductor.error(e.toString());
            }}
            idp={modalData.idp}
            site={'heste'}
        />
    );
};

export default CPRConfirmModal;
