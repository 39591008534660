import React from 'react';
import Wrapper from 'ui/Wrapper.tsx';
import ExpertsComponent from 'scenes/Experts/ExpertsList';
import Spinner from 'ui/Spinner';
import useExpertsData from 'scenes/Experts/useExpertsData.ts';
import Mark from 'ui/Mark';
import { CMSImage } from 'common/api/CMSAPI/utils';
import { byPlatform } from 'utils/platforms';

const Index = () => {
    const { banner, expertsList, expertsListPending, expertsListError } = useExpertsData(true);

    return (
        <Wrapper padding="30px">
            {expertsListPending ? (
                <Spinner size={'x2'} />
            ) : expertsListError ? (
                <Mark red>{expertsListError}</Mark>
            ) : (
                <>
                    <img
                        src={CMSImage(banner)}
                        alt={'Bet25 heste Index'}
                        style={{
                            maxWidth: '100%',
                            borderRadius: byPlatform('5px', '30px'),
                        }}
                    />
                    <ExpertsComponent expertsList={expertsList} />
                </>
            )}
        </Wrapper>
    );
};

export default Index;
