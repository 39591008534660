import React from 'react';
import {
    PopupWrapper as PopupWrapperDef,
    CloseButtonArea as CloseButtonAreaDef,
    CloseButton as CloseButtonDef,
    CloseIcon as CloseIconDef,
} from 'ui/Popup';
import Wrapper from 'ui/Wrapper.tsx';
import Popup from 'ui/Popup';
import Button from 'ui/controls/Button';
import Text from 'ui/Text';
import { byPlatform } from 'utils/platforms';
import InsuranceCardImage from 'images/insurance-card.png';
import useModals from 'common/hooks/useModals.ts';
import styled from 'styled-components';
import { redirectByLocalStorageParameter } from 'utils/navigation';

const PopupWrapper = styled(PopupWrapperDef)`
    width: ${byPlatform('90%', '400px')};
    border-radius: 7px;
    min-height: 400px;
    height: auto;
`;

const CloseButtonArea = styled(CloseButtonAreaDef)`
    top: 0;
    right: ${byPlatform('5px', 0)};
`;

const CloseButton = styled(CloseButtonDef)`
    height: ${byPlatform('30px', '20px')};
    width: ${byPlatform('30px', '20px')};
    background: #c1c1c1;
    padding: 5px;
    box-sizing: border-box;
    box-shadow: none;
    border-radius: 3px;
    &:hover {
        background: #818181;
    }
`;

const CloseIcon = styled(CloseIconDef).attrs({ color: 'white' })`
    width: 10px;
    height: 10px;
`;

const InsuranceCard = styled.img.attrs({ src: InsuranceCardImage })`
    width: calc(100% - 30px);
    margin: 0px 20px;
    image-rendering: crisp-edges;
    image-rendering: -moz-crisp-edges; /* Firefox */
    image-rendering: -o-crisp-edges; /* Opera */
    image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming)*/
    -ms-interpolation-mode: nearest-neighbor;
`;

const TempUser = () => {
    const { hide } = useModals();

    const email = 'dokument@25syv.dk';

    const mailto = () => {
        window.location.href = `mailto:${email}`;
    };

    const closeHandler = () => {
        // This popup is shown after sign up has complete so we have to
        // consider redirect to the spilklub pool if user was redirected to
        // sign up from the spilklub modal
        redirectByLocalStorageParameter('redirectAfterReg');
        hide('TEMP_USER');
    };

    const renderContent = () => (
        <>
            <Wrapper padding={`${byPlatform('50px', '25px')} 45px 0 45px`}>
                <Text bold black size="x2">
                    Verificer din konto
                </Text>
                <Text>
                    Da din konto ikke automatisk er blevet verificeret, beder vi dig indsende et
                    foto af dit sundhedskort til dokument@25syv.dk
                </Text>
            </Wrapper>
            <InsuranceCard alt="Insurance card" />
            <Wrapper padding="0 45px 25px 45px">
                <Text bold fontStyle="italic">
                    Ellers lukkes din konto 30 dage efter oprettelsen.
                </Text>
                <Button
                    accentAlt
                    size="x2"
                    bold
                    width="100%"
                    margin="20px 0 0 0"
                    textsize="14px"
                    onClick={mailto}
                >
                    Verficér konto
                </Button>
            </Wrapper>
        </>
    );

    return (
        <Popup
            onClose={closeHandler}
            PopupWrapperComponent={PopupWrapper}
            CloseButtonAreaComponent={CloseButtonArea}
            CloseButtonComponent={CloseButton}
            CloseIconComponent={CloseIcon}
            closeButtonLocation="inner"
        >
            {renderContent()}
        </Popup>
    );
};

export default TempUser;
