import styled from 'styled-components';
import Text from 'ui/Text';
import { Row, Col } from 'react-grid-system';

export const ExpertPhoto = styled.img`
    width: 140px;
    height: 140px;
`;

export const StyledText = styled(Text)`
    max-width: 600px;
`;

export const ExpertRow = styled(Row)`
    padding: 25px 0;
    cursor: pointer;
`;

export const ExpertPhotoCol = styled(Col).attrs({ xs: 5, sm: 4, md: 3 })`
    min-width: 140px;
    text-align: right;
`;

export const ExpertTextCol = styled(Col).attrs({ xs: 7, sm: 8, md: 9 })``;

export const ExpertGroups = styled.div`
    display: flex;
    justify-content: flex-start;
    margin-bottom: 10px;
    & > * {
        margin-right: 10px;
    }
`;
