import get from 'lodash/get';
import { PoolOverview } from 'common/DataObjects/PoolOverview';
import productSelector from 'common/selectors/productSelector';

export const trackPoolBySelectedProduct = state => {
    const product = productSelector(state);
    const productId = get(product, 'id');
    const trackPool = state.AISDataProvider.trackPool;
    return get(trackPool, productId);
};

export const selectedRaceJackpot = state => {
    return get(trackPoolBySelectedProduct(state), 'jackpot.sum');
};

export default state => {
    if (!state.AISDataProvider.trackPoolFetched || !state.AISDataProvider.mergedPoolsFetched)
        return null;

    return new PoolOverview().unserialize(
        state.AISDataProvider.trackPool,
        state.AISDataProvider.mergedPools,
        state.AISDataProvider.racingCardData.timestamp
    );
};
