import React from 'react';
import { NotifierCounter, NotifierWrapper } from './StyledViews';
import Text from 'ui/Text';

const Notification = ({ combinationsCount, innerRef }) => (
    <NotifierWrapper ref={innerRef}>
        <Text white align="center" bold>
            <NotifierCounter combinationsCount={combinationsCount}>
                {combinationsCount}
            </NotifierCounter>{' '}
            x <b style={{ fontWeight: 900 }}>Indsats</b>
        </Text>
    </NotifierWrapper>
);

export default Notification;
