import styled from 'styled-components';
import { Fillable } from 'ui/FullScreen';
import Button from 'ui/controls/Button';
import { BACKGROUNDS, COLORS } from 'themes';
import DefaultText from 'ui/Text';
import { byPlatform } from 'utils/platforms';

const ButtonGroupContainer = styled.div`
    display: flex;
    padding-left: 0;
    padding-right: 0;
    justify-content: space-between;
    button {
        flex-grow: 1;
        flex-shrink: 0.5;
        font-size: 12px;
        margin: 1px;
        flex-basis: 24%;
    }
    button:last-child {
        flex-grow: 2;
    }
    @media (max-width: 330px) {
        flex-wrap: wrap;
        button {
            font-size: 14px;
        }
    }
`;

const Container = styled(Fillable)`
    padding: 25px;
`;

const BoldText = styled(DefaultText)`
    font-weight: bold;
    cursor: pointer;
`;

const BolderText = styled(DefaultText)`
    font-weight: 900;
`;

const ExcludeBtn = styled(Button)`
    width: calc(100% - (25px * 2));
    margin: auto auto 30px auto;
    border-radius: 15px;
    display: block;
`;

const Pad = styled.div`
    background: ${BACKGROUNDS.accentAlt};
    border-radius: 7px;
    padding: 25px 15px 0 15px;
    max-width: ${({ isModal }) => (isModal ? byPlatform('100%', '330px') : '330px')};
    height: ${({ isModal }) => (isModal ? byPlatform('100vh', '100%') : '100%')};
`;

const TextBlock = styled.div`
    padding-bottom: 15px;
`;

const Text = styled(DefaultText)`
    text-align: center;
    padding: 0;
    color: ${COLORS.black};
    font-weight: bold;
    font-size: 14px;
`;

const Ul = styled(Text).attrs({ as: 'ul' })`
    padding: 0 17px 15px 0;
    list-style-type: none;
`;

const Li = styled.li`
    &:before {
        content: '·';
        vertical-align: middle;
        line-height: 20px;
        font-size: 30px;
        padding-right: 3px;
    }
`;

const Linked = styled.a`
    color: ${COLORS.primary};
    text-decoration: none;
`;

export {
    ButtonGroupContainer,
    Container,
    BoldText,
    BolderText,
    ExcludeBtn,
    Pad,
    Text,
    TextBlock,
    Ul,
    Li,
    Linked,
};
