import { IS_TERMINAL } from 'utils/platforms';
import { DOMAIN_PATH } from 'configs/main';
import env, { byWebEnv, getDevApiHost } from 'utils/env';

export const devUrl = new URL(window.location.href).searchParams.get('devUrl');

export const DERBY_BACKEND = byWebEnv(
    'https://api.bet25.dk',
    getDevApiHost(),
    'https://api.bet25test.dk',
    'https://api.bet25dev.dk'
);

export const DK_AUTH_REST_PATH = DERBY_BACKEND + '/punter/';
export const DK_RESULT_REST_PATH = IS_TERMINAL
    ? devUrl
        ? `${devUrl}/heste/result/`
        : 'https://www.derby25.dk/heste/result/'
    : `${DERBY_BACKEND}/heste/result/`;

// export const CMS_REST_BASE_URL = `${env('CMS_HOST', DOMAIN_PATH)}/rest/`;
export const CMS_API_BASE_URL = `${env('CMS_HOST', DOMAIN_PATH)}`;

export const CONTENT_API_BASE_URL = 'https://api.bet25.dk/';

export const AIS_REST_PATH = `${DERBY_BACKEND}/heste/info/`;
export const BETTING_REST_PATH = `${DERBY_BACKEND}/heste/betting/`;
export const BETTING_HISTORY_REST_PATH = `${DERBY_BACKEND}/heste/betting/history/`;
export const BET_BUDDY_REST_PATH = `${DERBY_BACKEND}/heste/spilklub/`;
export const BATCH_BETTING_REST_PATH = `${DERBY_BACKEND}/heste/betting/batch/`;
export const UTILS_REST_PATH = `${DERBY_BACKEND}/util/api/`;
export const REPLAYS_REST_PATH = `${DERBY_BACKEND}/heste/media/vision/`;

export const RACE_INFO_SERVICE = (trackId, date) =>
    `https://www.atg.se/services/racinginfo/v1/api/tracks/${trackId}/program/${date}/pdf`;

export const MANAGER_HOST_BASE_URL = 'http://operator.cafe:8080'; //'https://cafe.bet25.dk';
/*+ (process.env.REACT_APP_LOCAL_ENV === 'true' ? '' : ':8080');*/

export const TERMINAL_BETTING_REST_PATH = `${MANAGER_HOST_BASE_URL}/cafe/rest/derby/perform-sale/`;
