import React, { Fragment } from 'react';
import { byPlatform } from 'utils/platforms';
import errorIcon from 'icons/wrong.png';
import Icon from 'ui/Icon';
import { Col } from 'react-grid-system';
import styled from 'styled-components';
import { Card } from 'ui/Card';
import Text from 'ui/Text';
import Wrapper from 'ui/Wrapper';

const StyledCard = styled(Card)`
    display: flex;
    margin: 20px auto;
    width: auto;
    box-sizing: border-box;
    max-width: ${props => (props.fullWidth ? '100%' : '680px')};
`;

const CenterizedCol = styled(Col)`
    display: flex;
    justify-content: center;
    padding: 20px 0;
    align-items: center;
`;

const TextWrapper = styled.div`
    max-width: 250px;
`;

const StyledWrapper = styled(Wrapper)`
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

const YouthWarning = ({ race, track, fullWidth = false }) => {
    const renderText = textAlign => (
        <TextWrapper>
            <Text bold black style={{ fontSize: '16px', textAlign }}>
                Løb {race.raceNumber} er desværre lukket for spil
            </Text>
            <Text black style={{ fontSize: '14px', textAlign }}>
                Grundet nye regler i Svensk lovgivning, tilbyder ATG kun spil i løb, hvor alle kuske
                er over 18 år.
            </Text>
            <Text black style={{ fontSize: '14px', textAlign }}>
                Derfor er det ikke muligt at spille på Løb {race.raceNumber} idag på {track.name}
            </Text>
        </TextWrapper>
    );

    const renderMobile = () => {
        return (
            <StyledWrapper>
                <Icon src={errorIcon} size="x5" />
                {renderText('center')}
            </StyledWrapper>
        );
    };
    const renderDesktop = () => {
        return (
            <StyledCard fullWidth={fullWidth}>
                <CenterizedCol sm={5}>
                    <Icon src={errorIcon} size="x5" />
                </CenterizedCol>
                <CenterizedCol sm={7} style={{ justifyContent: 'left' }}>
                    {renderText('left')}
                </CenterizedCol>
            </StyledCard>
        );
    };

    return <Fragment>{byPlatform(renderMobile(), renderDesktop())}</Fragment>;
};

export default YouthWarning;
