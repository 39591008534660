import React from 'react';
import styled, { keyframes } from 'styled-components';
import { getColorByProps } from '../utils/theme-helpers';

function getSizes(size = 32) {
    switch (size) {
        case 'x04':
            return 14;
        case 'x05':
            return 16;
        case 'x07':
            return 24;
        case 'x1':
            return 32;
        case 'x2':
            return 48;
        case 'x3':
            return 64;
        default:
            return size;
    }
}

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
    display: block;
    position: relative;
    ${(props) => (props.align ? `float: ${props.align};` : 'margin: 0px auto;')}
    width: ${(props) => getSizes(props.size)}px;
    height: ${(props) => getSizes(props.size)}px;
    & > div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: ${(props) =>
            parseInt(getSizes(props.size) - getSizes(props.size) / 5, 10)}px;
        height: ${(props) =>
            parseInt(getSizes(props.size) - getSizes(props.size) / 5, 10)}px;
        margin: ${(props) => parseInt(getSizes(props.size) / 10, 10)}px;
        border: ${(props) => parseInt(getSizes(props.size) / 10, 10)}px solid
            ${(props) => getColorByProps(props)};
        border-radius: 50%;
        animation: ${rotate360} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: ${(props) =>
            getColorByProps(props)} transparent transparent transparent;
    }
    & > div:nth-child(1) {
        animation-delay: -0.45s;
    }
    & > div:nth-child(2) {
        animation-delay: -0.3s;
    }
    & > div:nth-child(3) {
        animation-delay: -0.15s;
    }
`;

export default (props) => (
    <Spinner {...props}>
        <div />
        <div />
        <div />
        <div />
    </Spinner>
);
