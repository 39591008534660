import React from 'react';
import styled from 'styled-components';
import { PopupWrapper as PopupWrapperDefault } from 'ui/Popup';
import Button from 'ui/controls/Button';
import CloseBtn from 'layouts/sections/Modals/CloseBtn';

export const PopupWrapper = styled(PopupWrapperDefault)`
    width: 390px;
    max-width: 100%;
    min-height: auto;
    height: auto;
    border-radius: 10px;
    padding: 30px 50px;
    box-sizing: border-box;
`;

export const ButtonsRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: 25px;
`;

export const DefaultButton = styled(Button)`
    width: 140px;
    height: 50px;
    font-weight: 900;
    font-size: 18px;
`;

export const OKBtn = styled(DefaultButton).attrs({ grey: true })``;
export const LogInBtn = styled(DefaultButton).attrs({ accentAlt: true })``;

export const CloseButton = styled((props) => <CloseBtn iclosewhite {...props} />)`
    background: rgba(1, 1, 1, 0.3);
    border-radius: 5px;
    padding: 13px;
    top: 20px;
    width: 12px;
    height: 12px;
    ${({ right }) => (right ? 'right: 20px' : '')};
    ${({ right }) => (right ? 'left: auto' : '')};
`;
