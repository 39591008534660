import React, { useRef, useEffect } from 'react';

import Product from 'features/Homepage/components/Products/Product';
import { ProductId, ProductIds } from 'features/EventBoard/server/calendar';
import { PreloaderButtons } from 'ui/RaceNavigation';

import { ScrollBar } from '../styled';
import { byPlatform } from 'utils/platforms';
import { scrollToElement } from 'utils/DOM';

interface ProductButtonsProps {
    data: ProductIds;
    selectedProductId: ProductId;
    onSelect: (productId: ProductId) => void;
    disabled: boolean;
    loading: boolean;
    legacyMode?: boolean;
}

const ProductButtons = ({
    data,
    selectedProductId,
    onSelect,
    loading,
    disabled = false,
    legacyMode = true,
}: ProductButtonsProps) => {
    const scrollContainerRef = useRef(document.createElement('div'));
    const activeButtonRef = useRef(document.createElement('button'));

    const onSelectHandler = (event: Event, productId: ProductId) => {
        onSelect(productId);
    };

    useEffect(() => {
        scrollToElement(scrollContainerRef, activeButtonRef);
    }, [data]);

    return loading ? (
        <PreloaderButtons count={byPlatform(6, 9)} width={'60px'} />
    ) : (
        <ScrollBar ref={scrollContainerRef} data-test-id="products-section">
            {data.map((productId, index) => {
                const active = selectedProductId === productId;
                return (
                    <Product
                        onSelect={onSelectHandler}
                        active={active}
                        disabled={disabled}
                        key={index}
                        product={productId}
                        last={index + 1 === data.length}
                        legacyMode={legacyMode}
                        {...(active ? { innerRef: activeButtonRef } : {})}
                    />
                );
            })}
        </ScrollBar>
    );
};

export default ProductButtons;
