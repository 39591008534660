import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Location } from 'history';
import { ConfirmButton } from './styled';
import CenteredLogoTopBar from 'features/Spilklub/components/CenteredLogoTopBar';
import { getSelectedCoupon, getSelectedPool, getStatus } from '../selectors';
import useDraftSelections from '../hooks/useDraftSelections';
import useSelections from '../../BetSlip/state/useSelections';
import { isSpilklubSelectionsMode } from 'common/selectors/betSlipSelector';
import BetSlip from '../components/BetSlip';
import useAmount from 'features/BetSlip/state/useAmount';
import useCoupon from '../hooks/useCoupon';
import Spinner from 'ui/Spinner';
import useAuth from 'common/hooks/useAuth';
import NotificationConductor from 'common/conductors/NotificationConductor';
import { getPoolDetailsAsync, resetStatus } from '../actions';
import { history, openLogin } from 'utils/navigation';
import { byPlatform, isMobile } from 'utils/platforms';

const CouponPreviewScreen = ({
    showPoolDetails = () => {},
    hideCouponPreviewScreen = () => {},
}) => {
    const pool = useSelector(getSelectedPool);
    const coupon = useSelector(getSelectedCoupon);
    const [, addCoupon] = useCoupon();
    const [pending, error, success] = useSelector(state => getStatus(state, 'addCoupon'));
    const dispatch = useDispatch();

    const isSelectionsMode = useSelector(isSpilklubSelectionsMode);
    const draftSelections = useDraftSelections(pool, coupon);
    const selections = useSelections();

    const betAmount = useAmount(coupon.combinationPrice, draftSelections);

    const combinationsCount = isSelectionsMode
        ? selections.combinationsCount
        : draftSelections.combinationsCount;
    const auth = useAuth();

    const isNotAuthenticated = !auth.user && !auth.pending;

    const validate = () => betAmount.isCombinationPriceValid && selections.maximumPicksValid;

    useEffect(() => {
        if (!pending && success) {
            setTimeout(() => {
                NotificationConductor.success('Kupon er tilføjet');
                draftSelections.setBetBuddySelectionsMode(false);
                // In case of desktop we should just close coupon preview screen
                // as the pool details screen is already shown
                isMobile ? showPoolDetails() : hideCouponPreviewScreen();
                dispatch(resetStatus('addCoupon'));
                dispatch(getPoolDetailsAsync.request(pool.internalPool.id));
                dispatch({ type: 'RESET_BET' });
            }, 100);
        } else if (!pending && error) {
            NotificationConductor.error(error);
            dispatch(resetStatus('addCoupon'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pending, success]);

    const locationState = history.location?.state as { background: Location };

    return (
        <>
            <CenteredLogoTopBar product={draftSelections.product} />
            <BetSlip
                withoutDeleteButton={false}
                applyCombinationPrice={betAmount.applyCombinationPrice}
                isCombinationPriceValid={betAmount.isCombinationPriceValid}
                isCouponAmountExceed={betAmount.isCouponAmountExceed}
                {...draftSelections}
                combinationPrice={betAmount.combinationPrice}
                combinationsCount={combinationsCount}
                minimumPicksValid={true}
                maximumPicksValid={selections.maximumPicksValid}
                errorMessage={betAmount.errorMessage}
                betSlip={selections.betSlip}
                reserves={selections.reserves}
            />

            <ConfirmButton
                accentAlt
                disabled={!validate()}
                grey={!validate()}
                style={{ position: 'relative', top: '-25px' }}
                onClick={() =>
                    isNotAuthenticated
                        ? dispatch(
                              openLogin(byPlatform({}, { background: locationState?.background }))
                          )
                        : !pending && !success && addCoupon(betAmount.combinationPrice)
                }
            >
                {pending ? <Spinner size={'x05'} black /> : 'TILFØJ til gruppe'}
            </ConfirmButton>
        </>
    );
};

export default CouponPreviewScreen;
