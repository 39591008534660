import { css } from 'styled-components';

export const rowPreloaderAnimation = css`
    @keyframes placeHolderShimmer {
        0% {
            background-position: -468px 0;
        }
        100% {
            background-position: 468px 0;
        }
    }

    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #b2b9c6;
    background: linear-gradient(to right, #9198aa 8%, #f0f0f0 18%, #9198aa 33%);
    background-size: 800px 104px;
    position: relative;
`;
