import React from 'react';
import ReactTooltip from 'react-tooltip';
import Icon from 'ui/Icon';
import Text from 'ui/Text';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { WEATHER_ICONS } from 'configs/weather-code';

export const WeatherContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    min-width: 74px;
`;

const WeatherTooltip = styled(ReactTooltip)``;

interface TrackWeatherProps {
    temperature: number | undefined;
    weatherCode: number;
    weatherDescription: string | undefined;
}

const TrackWeather = ({ temperature, weatherCode, weatherDescription }: TrackWeatherProps) => {
    return (
        <>
            <WeatherContainer
                data-tip={weatherDescription}
                data-place={'bottom'}
                data-effect={'solid'}
                data-for={'weather-tooltip'}
            >
                {weatherCode && <Icon src={WEATHER_ICONS[weatherCode]} />}
                {temperature || temperature === 0 ? (
                    <Text size={isMobile ? 'x2' : 'x3'} bold align={'right'}>
                        {`${temperature}°`}
                    </Text>
                ) : null}
            </WeatherContainer>
            <WeatherTooltip id={'weather-tooltip'} />
        </>
    );
};

export default TrackWeather;
