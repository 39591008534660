import { useQuery } from '@tanstack/react-query';

import { request } from 'utils/server';
import { RaceCardData } from '../server/raceCard';
import { TrackBetInfo } from '../server/trackBetInfo';

interface TrackBetInfoQueryParams {
    date: string;
    trackId: number;
}

interface TrackBetInfoQueryProps extends TrackBetInfoQueryParams {
    enabled?: boolean;
}

const placeholderData: TrackBetInfo = {
    shoeInfoRace: {
        shoeInfoRace: [],
    },
};

const fetchTrackBetInfo = async ({ date, trackId }: TrackBetInfoQueryParams) => {
    const response = await request<RaceCardData, TrackBetInfoQueryParams>(
        'InfoService',
        'POST',
        `fetchTrackBetInfo`,
        { trackId, date }
    );

    if (!response.success || Object.keys(response.data).length === 0) {
        throw new Error(response.errorMessage);
    }

    return response.data;
};

const useTrackBetInfoQuery = ({ date, trackId, enabled = false }: TrackBetInfoQueryProps) => {
    const { status, data, error, isSuccess, isLoading } = useQuery<unknown, Error, TrackBetInfo>({
        queryKey: ['trackBetInfo', date, trackId],
        queryFn: () => fetchTrackBetInfo({ date, trackId }),
        enabled,
        staleTime: 60 * 10 * 1000,
    });
    return { status, data: data ?? placeholderData, error, isSuccess, isLoading };
};

export default useTrackBetInfoQuery;
