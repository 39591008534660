import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Headline from 'ui/Headline';
import Text from 'ui/Text';
import Wrapper from 'ui/Wrapper';
import Divider from 'ui/Divider';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { COLORS } from 'themes';
import SelectionRow from 'features/History/SelectionRow';
import { Col, Row } from 'react-grid-system';
import { byPlatform } from 'utils/platforms';
import { connect } from 'react-redux';
import { isUserLoggedIn } from 'common/actions/authActions';
import { getAmountFromReceipt, getRaceTimeFromReceipt } from 'utils/retail';
import getTexts from 'utils/localization';
import { generatePopupLink } from 'utils/navigation';
import { hideModal } from 'common/actions/uiActions';
import { NEW_TRACK_PAGE } from 'configs/main';
import Product from 'common/DataObjects/Product';

const t = getTexts();

class QuickPickReceipt extends React.Component {
    componentDidMount() {
        this.props.resetSmartLyn && this.props.resetSmartLyn();
    }

    renderRows = (product) => {
        const legs = this.props.legs || [];

        let productDTO = product;

        if (NEW_TRACK_PAGE) {
            productDTO = new Product();
            productDTO.id = product.id;
            productDTO.name = product.name;
            productDTO.isMultitrack = product.isMultitrack;
        }

        return legs.map((leg, index) => (
            <SelectionRow
                key={index}
                index={index}
                data={leg.selected15Horses}
                product={product}
                productDTO={productDTO}
            />
        ));
    };

    render() {
        const { product, raceDay, receipt } = this.props;
        const terminalAmount = getAmountFromReceipt(receipt);
        const terminalRaceTime = getRaceTimeFromReceipt(receipt);

        const trackName = NEW_TRACK_PAGE ? raceDay.track.domesticText : this.props.track.name;
        const date = NEW_TRACK_PAGE ? raceDay.raceDayDate : this.props.date;

        const amount = terminalAmount ? terminalAmount : receipt.totalCostCrowns;
        const raceTime = terminalRaceTime ? terminalRaceTime : moment(date).format('DD/MM, HH:mm');

        return (
            <Fragment>
                <Headline margin="10px 0px 0px" padding="0px 10px" grey size="x3">
                    {t.QuickPick.receiptTitle}
                </Headline>
                <Headline margin="10px 0px 0px" padding="0px 10px" grey size="x1">
                    {product.name} - {trackName}
                </Headline>
                <Text padding="0px 10px">Start: {raceTime}</Text>
                <Text padding="0px 10px 10px">
                    {t.QuickPick.amount}: {amount} {t.currency}
                </Text>

                <Divider grey />

                <Wrapper width="auto" margin="0px" padding="0px" align="center">
                    <Row>
                        <Col
                            xs={2}
                            style={{
                                margin: '0px',
                                padding: '0px',
                            }}
                        >
                            <Text grey bold="500" padding="8px 10px" align="right">
                                {t.betSlip.track}
                            </Text>
                        </Col>
                        <Col
                            xs={10}
                            style={{
                                margin: '0px',
                                padding: '0px',
                            }}
                        >
                            <Text grey bold="500" padding="8px 10px" align="left">
                                {t.betSlip.nrAndHorse}
                            </Text>
                        </Col>
                    </Row>
                    {this.renderRows(product)}
                </Wrapper>

                <Divider grey />
                <Text align="center" padding="10px 10px 10px">
                    {t.QuickPick.helpText}{' '}
                    <Link
                        style={{
                            color: COLORS.primary,
                            fontSize: '14px',
                            padding: '0px',
                        }}
                        onClick={this.props.hideQuickPickReceiptModal}
                        to={byPlatform(
                            '/mine-spil',
                            generatePopupLink('/konto/activebets', 'USER_AREA_DESKTOP')
                        )}
                    >
                        {t.receipt.myGamesLink}
                    </Link>
                </Text>
            </Fragment>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
    isUserLoggedIn: () => dispatch(isUserLoggedIn()),
    hideQuickPickReceiptModal: () => {
        // This receipt component is used for both - smart lyn and lyn spil.
        // So should be handled 2 cases.
        // smart lyn case
        dispatch(hideModal('SMART_LYN_RECEIPT'));
        // lynspil case
        dispatch(hideModal('QUICK_PICK_RECEIPT'));
    },
});

export default withRouter(connect(() => {}, mapDispatchToProps)(QuickPickReceipt));
