import { useDispatch } from 'react-redux';
import useTrackPage from './useTrackPage';

import {
    addBet,
    removeBet,
    addReserve as addReserveAction,
    removeReserve as removeReserveAction,
} from 'features/BetSlip/state/actions';

import { AnyRace } from 'features/TrackPage/model/Race';
import useBetPicks from 'features/BetSlip2/hooks/useBetPicks';

export declare type TrioPlace = 1 | 2 | 3;
export declare type BetPickModifier = 'U' | TrioPlace;

interface Parameters {
    startNr: number;
    race: AnyRace;
}

const MAX_RESERVES = 2;

const useRacePicks = ({ startNr, race }: Parameters) => {
    const { raceDay, productId, trackCode } = useTrackPage();

    const { singleRaceBetPicks: betPick, singleRaceReserves: reserves } = useBetPicks({
        raceDay,
        trackCode,
        productId,
        race,
    });

    const reserveNumber = reserves.indexOf(startNr) + 1;

    const isPicked = (betPickModifier?: BetPickModifier) =>
        Boolean(betPick?.satisfies(startNr, betPickModifier ?? startNr));

    const dispatch = useDispatch();

    const pickStart = (betPickModifier?: BetPickModifier) => {
        dispatch(
            addBet(
                raceDay.raceDayDate,
                trackCode,
                productId,
                race.index,
                startNr,
                betPickModifier ?? startNr
                //isBetbuddy
            )
        );
    };

    const unpickStart = (betPickModifier?: BetPickModifier) => {
        dispatch(
            removeBet(
                raceDay.raceDayDate,
                trackCode,
                productId,
                race.index,
                startNr,
                betPickModifier ?? startNr
            )
        );
    };

    const addReserve = () => {
        const lastAddedReserveStartNr = reserves[reserves.length - 1];

        dispatch(addReserveAction(raceDay.raceDayDate, trackCode, productId, race.index, startNr));

        if (reserves.length === MAX_RESERVES) {
            dispatch(
                removeReserveAction(
                    raceDay.raceDayDate,
                    trackCode,
                    productId,
                    race.index,
                    lastAddedReserveStartNr
                )
            );
        }
    };

    const removeReserve = () => {
        dispatch(
            removeReserveAction(raceDay.raceDayDate, trackCode, productId, race.index, startNr)
        );
    };

    return {
        pickStart,
        unpickStart,
        addReserve,
        removeReserve,
        reserveNumber,
        isPicked,
        picked: isPicked(),
        reserved: reserves.includes(startNr),
    };
};

export default useRacePicks;
