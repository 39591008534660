import React from 'react';
import Popup from 'ui/Popup';
import Text from 'ui/Text';
import { PopupWrapper, ButtonsRow, OKBtn, LogInBtn } from './styled';
import { useHistory } from 'react-router-dom';
import { openLogin } from 'utils/navigation';
import { useDispatch } from 'react-redux';

const LoginPrompt = ({ hideModal }) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const close = () => {
        history.push('/');
        hideModal('LOGIN_PROMPT');
    };
    const goToLogin = () => {
        hideModal('LOGIN_PROMPT');
        dispatch(openLogin());
    };

    return (
        <Popup PopupWrapperComponent={PopupWrapper} displayCloseButton={false}>
            <Text bold black size="x3">
                Log ind for at fortsætte
            </Text>
            <ButtonsRow>
                <OKBtn onClick={close}>OK</OKBtn>
                <LogInBtn onClick={goToLogin}>Log ind</LogInBtn>
            </ButtonsRow>
        </Popup>
    );
};

export default LoginPrompt;
