import axios from 'axios';
import { logMessage } from './log';

const jsonSerializer = data => JSON.stringify(data);
const formSerializer = data => {
    const p = Object.keys(data).map(key => {
        return data[key] ? `${key}=${decodeURIComponent(data[key].toString())}` : '';
    });
    return p.join('&');
};

const dataSerializers = {
    'application/json': jsonSerializer,
    'application/x-www-form-urlencoded': formSerializer,
    'multipart/form-data': jsonSerializer,
    'multipart/form-data; boundary=----DerbyRequestBoundary----': jsonSerializer,
};

const makeRequest = (requestMethod, url, data = {}, fetchParams = {}, headerParams = {}) => {
    const params = {
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Cache: 'no-cache',
            ...headerParams,
        },
        credentials: 'include',
        method: requestMethod,
        ...fetchParams,
    };

    if (requestMethod === 'post') {
        params['body'] = dataSerializers[params.headers['Content-Type']](data);
    } else if (requestMethod === 'get' && data) {
        const p = Object.keys(data).map(key => `${key}=${decodeURIComponent(data[key])}`);

        url = url + (p.length ? '?' : '') + p.join('&');
    }

    return fetch(url, params).catch(response => {
        const message = `Can not fetch ${url}. Original error - ${response}.`;
        logMessage(message);
    });
};

const makeJSONRequest = (...params) => makeRequest(...params).then(response => response.json());

const axiosRequest = (requestMethod, url, data = {}, headerParams = false) => {
    return !headerParams
        ? axios[requestMethod](url, data)
        : axios[requestMethod](url, data, headerParams);
};

export { makeRequest, makeJSONRequest, axiosRequest };
