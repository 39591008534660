import React from 'react';
import ShoeInfo from './ShoeInfo';
import { Horse } from 'features/TrackPage/model/Race';
import { ShoeInfoStart } from 'features/TrackPage/server/trackBetInfo';
import { ShoeCol } from './styled';

interface WeightOrShoeProps {
    horse: Horse;
    gallop: boolean;
    shoeInfoStart: ShoeInfoStart;
}

export function extractShoeInfo(shoeInfoStart: ShoeInfoStart) {
    return {
        front: shoeInfoStart?.shoeInfoCurrent?.foreShoes,
        back: shoeInfoStart?.shoeInfoCurrent?.hindShoes,
    };
}

const WeightOrShoeCol = ({ horse, gallop, shoeInfoStart }: WeightOrShoeProps) => {
    const shoeInfo = extractShoeInfo(shoeInfoStart);

    return gallop ? (
        <>{horse?.gallopStartInfo && horse?.gallopStartInfo?.weight?.cardWeight}</>
    ) : (
        <ShoeCol>
            <ShoeInfo frontShoe={shoeInfo.front} backShoe={shoeInfo.back} />
        </ShoeCol>
    );
};

export default WeightOrShoeCol;
