import React, { useEffect, useRef, memo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import StreamVideoContainer from 'features/StreamVideo/StreamVideoContainer';
import styled from 'styled-components';
import { showStream } from 'features/StreamVideo/actions';
import { useDispatch, useSelector } from 'react-redux';
import { StreamTypeHeadDef } from 'features/StreamVideo/StreamTypesDropdown';
import Text from 'ui/Text';
import step1Image from 'images/live-stream/step1.png';
import step2Image from 'images/live-stream/step2.png';
import step3Image from 'images/live-stream/step3.png';
import chromecastIcon from 'images/live-stream/chromecast.svg';
import resize from 'images/live-stream/resize.gif';
import drag from 'images/live-stream/drag.gif';
import useAuth from 'common/hooks/useAuth.ts';
import streamLoginImage from 'images/live-stream-restriction.png';
import { setComponentsOptions } from 'common/actions/uiActions';
import usePrevious from 'common/hooks/usePrevious';
import { isLiveStreamAllowed } from 'common/selectors/auth';
import NotificationConductor from 'common/conductors/NotificationConductor';
import getTexts from 'utils/localization';
import { COLORS } from 'themes';
import Button from 'ui/controls/Button';
import { openLogin } from '../utils/navigation';

const t = getTexts();

const StreamWrapperComponent = styled.div`
    height: 400px;
    width: 580px;
    padding: 30px 0;
`;

const StreamLoginImage = styled.div`
    border-radius: 15px;
    flex-basis: 60%;
    height: 320px;
    cursor: pointer;
    background: url('${streamLoginImage}') no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const StreamTypeContainer = styled.div`
    width: auto;
    font-size: 16px;
    margin-bottom: 15px;
    position: relative;
`;

const StreamTypeHead = styled(StreamTypeHeadDef)`
    display: inline-block;
`;

const Title = styled.h3`
    margin: 5px 0;
    font-size: 26px;
    font-weight: 900;
`;

const DescriptionSection = styled.div`
    display: flex;
    align-items: center;
`;

const TopText = styled.div`
    flex-basis: 40%;
    align-self: ${({ playerShown }) => (playerShown ? 'flex-start' : 'center')};
    transition: 500ms all ease;
    margin-top: 30px;
`;

const InstructionStepsSection = styled.div`
    margin-top: 80px;
    background: #eceff7;
    border-radius: 10px;
    padding-top: 25px;
`;

const InstructionStepsContainer = styled.div`
    display: flex;
    justify-content: space-evenly;
`;

const InstructionStep = styled.div`
    display: flex;
    flex-direction: column;
    width: 230px;
    align-items: center;
    position: relative;
`;

const InstructionStepNumber = styled.div`
    background: #fff;
    height: 50px;
    width: 50px;
    border-radius: 50px;
    position: absolute;
    left: 30px;
    font-size: 18px;
    font-weight: 900;
    text-align: center;
    line-height: 50px;
`;

const InstructionStepText = styled.div`
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    text-align: center;
    padding-top: 10px;
    line-height: 25px;
`;

const InstructionStepImage = styled.img`
    width: 200px;
`;

const ChromecastIcon = styled.img.attrs({ src: chromecastIcon })`
    position: absolute;
    top: 14px;
    right: -25px;
`;

const InstructionGenericText = styled.div`
    text-align: center;
    color: #333;
    font-size: 16px;
    font-weight: bold;
    padding: 35px;
`;

const DescriptionText = styled(Text)`
    font-size: 18px;
    font-weight: bold;
    line-height: 26px;
    letter-spacing: 0.3px;
    color: #333;
`;

const RestrictionText = styled.span`
    color: ${COLORS.white};
    font-weight: 900;
    width: 60%;
`;

const RestrictionMessage = () => {
    const auth = useAuth();
    const liveStreamAllowed = useSelector(isLiveStreamAllowed);
    const history = useHistory();

    const onClickHandler = () => {
        if (!auth.user) {
            openLogin();
        } else if (!liveStreamAllowed) {
            NotificationConductor.info(t.Live.restrictionMessage);
        }
    };

    return (
        <StreamLoginImage onClick={onClickHandler}>
            <RestrictionText
                style={{
                    fontSize: !auth.user ? '32px' : '26px',
                    textAlign: !auth.user ? 'center' : 'left',
                    width: !auth.user ? '60%' : '73%',
                }}
            >
                {!auth.user
                    ? 'For at se live stream skal du være logget ind'
                    : t.Live.restrictionMessage}
            </RestrictionText>
            {!auth.user && (
                <Button
                    accentAlt
                    bold
                    size="x3"
                    padding="10px 20px"
                    width="200px"
                    margin="30px 0 0 0"
                >
                    Log ind
                </Button>
            )}
        </StreamLoginImage>
    );
};

const LiveStreamDesktop = () => {
    const dispatch = useDispatch();
    const isFloatingStreamShown = useSelector(
        ({ StreamVideo }) => StreamVideo.isFloatingStreamShown
    );
    const isCastRunning = useSelector(({ StreamVideo }) => StreamVideo.isCastRunning);
    const liveStreamAllowed = useSelector(isLiveStreamAllowed);
    const playerContainerRef = useRef(null);
    const hlsPlayerRef = useRef(null);
    const auth = useAuth();

    const setLiveStreamTransitionStatus = (status) =>
        dispatch(
            setComponentsOptions({
                liveStreamPageMounted: status,
            })
        );

    const playerAllowed = !!auth.user && liveStreamAllowed;

    useEffect(() => {
        isFloatingStreamShown && setLiveStreamTransitionStatus(true);
        setTimeout(() => setLiveStreamTransitionStatus(false), 2000);
        return () => {
            auth.user && dispatch(showStream());
        };
    }, []);

    const prevIsFloatingStreamShown = usePrevious(isFloatingStreamShown);
    const prevIsCastRunning = usePrevious(isFloatingStreamShown);

    useEffect(() => {
        if (!prevIsCastRunning && isCastRunning) {
            hlsPlayerRef && hlsPlayerRef.current && hlsPlayerRef.current.pause();
        }
    }, [isCastRunning]);

    useEffect(() => {
        if (!prevIsFloatingStreamShown && isFloatingStreamShown) {
            hlsPlayerRef && hlsPlayerRef.current && hlsPlayerRef.current.pause();
        }
    }, [isFloatingStreamShown]);

    return (
        <>
            {/* ------- 1 -------*/}
            <DescriptionSection style={{ gap: '0 50px', marginTop: '50px' }}>
                {playerAllowed ? (
                    <StreamVideoContainer
                        id="inline-live-stream"
                        inline
                        noDrag
                        width="100%"
                        height="100%"
                        WrapperComponent={StreamWrapperComponent}
                        StreamTypeContainer={StreamTypeContainer}
                        StreamTypeHead={StreamTypeHead}
                        autoplayHLS={false}
                        innerRef={playerContainerRef}
                        hlsPlayerRef={hlsPlayerRef}
                    />
                ) : (
                    <RestrictionMessage />
                )}
                <TopText playerShown={playerAllowed}>
                    <Title>Se direkte</Title>
                    <DescriptionText>
                        Som bruger hos Bet25 heste har du gratis adgang til både danske løb via
                        Bet25 LIVE samt udenlandske løb fra Sverige, Frankrig, Australien, England
                        og Sydafrika. Skulle du gå glip af, hvem der vandt et løb, eller vil du se
                        hestens form an, inden du sætter dit spil, har du mulighed for at se eller
                        gense et løb i vores <Link to="/replays">løbsarkiv</Link>.
                    </DescriptionText>
                </TopText>
            </DescriptionSection>
            {/* ------- 2 -------*/}
            <InstructionStepsSection>
                <Title style={{ textAlign: 'center', marginBottom: '40px' }}>
                    Chromecast Bet25 LIVE
                </Title>

                <InstructionStepsContainer>
                    <InstructionStep>
                        <InstructionStepNumber>1</InstructionStepNumber>
                        <InstructionStepImage
                            src={step1Image}
                            alt="Live stream instructions step 1"
                        />
                        <InstructionStepText>
                            Sørg for at du er på samme netværk som din Chromecast
                        </InstructionStepText>
                    </InstructionStep>
                    <InstructionStep>
                        <InstructionStepNumber>2</InstructionStepNumber>
                        <InstructionStepImage
                            src={step2Image}
                            alt="Live stream instructions step 2"
                        />
                        <InstructionStepText style={{ position: 'relative' }}>
                            Tryk på Chromecast ikonet <ChromecastIcon />
                            <br />i bunden af streamen.
                        </InstructionStepText>
                    </InstructionStep>
                    <InstructionStep>
                        <InstructionStepNumber>3</InstructionStepNumber>
                        <InstructionStepImage
                            src={step3Image}
                            alt="Live stream instructions step 3"
                        />
                        <InstructionStepText>Vælg den enhed du vil vise.</InstructionStepText>
                    </InstructionStep>
                </InstructionStepsContainer>
                <InstructionGenericText>
                    Hvis du bruger Safari eller vil caste fra din iPhone, kan du streame til dit
                    Apple TV ved at klikke på Airplay ikonet.
                </InstructionGenericText>
            </InstructionStepsSection>
            {/* ------- 3 -------*/}
            <DescriptionSection>
                <div style={{ padding: '0 40px' }}>
                    <Title>Tilpas streamen på din skærm</Title>
                    <DescriptionText>
                        Du kan selv bestemme størrelsen på din skærm. Du skal blot trække den i
                        hjørnet som du kan se i vores eksempel. Hvis du vil se det i fuldskærm, skal
                        du trykke på fuldskærms ikonet i selve streamen.
                    </DescriptionText>
                </div>
                <img src={resize} alt="Resizing the live stream" />
            </DescriptionSection>
            {/* ------- 4 -------*/}
            {/* GIF has a 1px line on the left, so fixing it with -1px margin-left and parent overflow: hidden */}
            <DescriptionSection
                style={{
                    gap: '0 30px',
                    overflow: 'hidden',
                    margin: '50px 0 100px 0',
                }}
            >
                <img src={drag} style={{ marginLeft: '-1px' }} alt="Replacing the live stream" />
                <div>
                    <Title>Placer din stream hvor du vil</Title>
                    <DescriptionText>
                        Du kan også vælge at placere din stream hvor end du har lyst på din skærm.
                        Når du logger ind næste gang, har den gemt din placering, som du frit kan
                        flytte som du har lyst.
                    </DescriptionText>
                </div>
            </DescriptionSection>
        </>
    );
};

export default memo(LiveStreamDesktop);
