import { placeVinderPladsBetFromRecipt } from 'features/BetSlip/components/Receipt/Receipt';
import { useDispatch } from 'react-redux';
import { keepRaceIndexInState, setProduct } from 'features/AISDataProvider/actions';
import { addBet, setPrevAmount } from 'features/BetSlip/state/actions';
import useCreateDispatch from 'common/hooks/useCreateDispatch';

/**
 * Used in receipt for placing same Vinder or Plads bet
 * (either P after V or V after P accordingly)
 *
 * @param reset {function}
 * @return {function(...[*]=)}
 */
const usePlaceSameVPBet = ({ reset = () => {} }) => {
    // dispatch
    const dispatch = useDispatch();
    const addBetD = useCreateDispatch(addBet);
    const setProductD = useCreateDispatch(setProduct);
    const keepRaceIndexInStateD = useCreateDispatch(keepRaceIndexInState);

    return (betSlip, date, trackCode, product, raceIndex, prevAmount) => {
        reset();

        placeVinderPladsBetFromRecipt(
            addBetD,
            setProductD,
            keepRaceIndexInStateD,
            betSlip,
            date,
            trackCode,
            product,
            raceIndex
        );

        dispatch(setPrevAmount(prevAmount));
    };
};

export default usePlaceSameVPBet;
