import React from 'react';
import Button from 'ui/controls/Button';
import Spinner from 'ui/Spinner';
import getTexts from 'utils/localization';

const t = getTexts();

const PerformBetButton = ({
    pending = false,
    amount,
    amountValid,
    onClick,
}) => (
    <Button
        {...(amount > 0 && amountValid
            ? { accent: true }
            : { lightGrey: true })}
        width="210px"
        block
        size={pending ? 'x2' : 'x3'}
        margin="-30px auto"
        shadow="0px -10px 30px rgba(0,0,0,0.2)"
        rounded="30px"
        onClick={onClick}
        style={{
            position: 'relative',
        }}
    >
        {pending ? (
            <Spinner size="24" secondary />
        ) : amount > 0 ? (
            'KØB ' + amount + ' ' + t.currency
        ) : (
            t.QuickPick.buttonPlaceholder
        )}
    </Button>
);

export default PerformBetButton;
