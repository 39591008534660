import React from 'react';

export enum ThumbnailSize {
    small = 'default',
    medium = 'mqdefault',
    big = 'hqdefault',
    maximum = 'maxresdefault',
}

export interface ThumbnailProps {
    videoId: string;
    size?: string;
    originalSize?: ThumbnailSize;
    title?: string;
    alt?: string;
}

// @example return value: https://img.youtube.com/vi/PzHZQ0pNx-c/mqdefault.jpg
const URLResolver = (videoId: string, originalSize: ThumbnailSize) =>
    `https://img.youtube.com/vi/${videoId}/${originalSize}.jpg`;

const Thumbnail = ({
    videoId,
    size,
    originalSize = ThumbnailSize.medium,
    title,
    alt,
}: ThumbnailProps) => {
    return (
        <img
            style={{ width: size }}
            src={URLResolver(videoId, originalSize)}
            title={title}
            alt={alt}
        />
    );
};

export default Thumbnail;
