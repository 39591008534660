import UserConductor from 'common/conductors/UserConductor';
import { toDateTime } from 'utils/date.ts';
import NotificationConductor from 'common/conductors/NotificationConductor';
import { sprintf } from 'sprintf-js';
import getTexts from 'utils/localization';
const t = getTexts();

const notifyAboutSelfExclusion = () =>
    UserConductor.getUserStatus().then((userStatus) => {
        if (userStatus.time > new Date()) {
            const [date, time] = toDateTime(userStatus.time, '.');

            NotificationConductor.info(
                sprintf(t.selfExclusionNotification, date, time),
                '',
                5000
            );
        }
    });

export default notifyAboutSelfExclusion;
