import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { create } from 'zustand';

import { generateResultPath } from 'utils/navigation';
import { extractProductId } from 'features/EventBoard/utils/common';

export interface URLParameters {
    date: string;
    product: string;
    trackId: string;
    race: string;
}

interface URLParametersStore extends URLParameters {
    setDate(date: string): void;
    setProduct(date: string): void;
    setTrackId(date: string): void;
    setRace(date: string): void;
}

const useURLParametersState = create<URLParametersStore>()(set => ({
    date: '',
    product: '',
    trackId: '',
    race: '',
    setDate: date => set({ date }),
    setProduct: product => set({ product }),
    setTrackId: trackId => set({ trackId }),
    setRace: race => set({ race }),
}));

/**
 * React router useParams() works only when calling from the child component of <Router> (the root page component)
 * This component uses additional global state in order to make URL parameters reachable across the UI
 */
const useURLParameters = () => {
    const { date, product, trackId, race } = useParams<URLParameters>();

    const {
        date: dateState,
        product: productState,
        trackId: trackState,
        race: raceState,
        setDate,
        setProduct,
        setTrackId,
        setRace,
    } = useURLParametersState();

    const syncURLParameters = () => {
        date && setDate(date);
        product && setProduct(product);
        trackId && setTrackId(trackId);
        race && setRace(race);
    };

    const clearURLParameters = () => {
        setDate('');
        setProduct('');
        setTrackId('');
        setRace('');
    };

    const generateURL = ({ product, date, trackId, race }: any) => {
        const productId = extractProductId(product ?? productState);

        return generateResultPath({
            product: productId,
            date: date ?? dateState,
            trackId: trackId ?? trackState,
            race: race ?? raceState,
        });
    };

    useEffect(syncURLParameters, [date, product, trackId, race]);

    return {
        date: dateState,
        product: productState,
        trackId: trackState,
        race: raceState,
        // next 2 functions should be called only in the component inside <Route/>
        syncURLParameters,
        clearURLParameters,
        generateURL,
    };
};

export default useURLParameters;
