import { useQuery } from '@tanstack/react-query';

import { request } from 'utils/server';
import QuickPick from 'common/DataObjects/QuickPick';

const fetchQuickPick = async (trackId: number, date: string) => {
    const response = await request('InfoService', 'GET', `quickPicks`, {
        date,
        trackId,
    });

    if (!response.success) {
        if (!response.success) {
            throw new Error('Something wrong with quick pick: ' + response.errorMessage);
        }
    }

    return (
        response?.data &&
        [...(response.data as Array<QuickPick>)].map((item) => QuickPick.unserialize(item))
    );
};

interface UseQuickPickQueryProps {
    trackId: number;
    date: string;
}

const useQuickPickQuery = ({ trackId, date }: UseQuickPickQueryProps) => {
    const { status, data, error } = useQuery({
        queryKey: ['quickPick', trackId, date],
        queryFn: () => fetchQuickPick(trackId, date),
        staleTime: 60 * 10 * 1000,
        enabled: Boolean(trackId && date),
    });

    return {
        status,
        quickPickData: data,
        error,
    };
};

export default useQuickPickQuery;
