import styled from 'styled-components';
import isNil from 'lodash/isNil';
import * as theme from '../../themes';
import { BACKGROUNDS, COLORS } from '../../themes';
import { getBackgroundByProps as getBackgroundByPropsGlobal } from '../../utils/theme-helpers';

const colors = theme.COLORS;
const sizes = {
    x05: `
        font-size: 10px;
        margin: 1px;
        padding: 6px;
        border-radius: 4px;
    `,
    x1: `
        font-size: 12px;
        margin: 2px;
        padding: 8px;
        border-radius: 5px;
    `,
    x15: `
        font-size: 14px;
        margin: 5px;
        padding: 10px;
        border-radius: 5px;
        font-weight: bolder;
    `,
    x2: `
        font-size: 16px;
        margin: 5px;
        padding: 15px;
        border-radius: 8px;
        font-weight: bolder;
    `,
    x3: `
        font-size: 20px;
        margin: 10px;
        padding: 20px;
        border-radius: 10px;
        font-weight: bolder;
    `,
};
const radiusBySizes = {
    x05: 10,
    x1: 20,
    x2: 30,
    x3: 40,
};

const getBtnColors = props => {
    let btnClr = colors.white;

    if (props.accent) {
        btnClr = colors.black;
    } else if (props.grey) {
        btnClr = colors.grey;
    } else if (props.lightGrey) {
        btnClr = colors.primaryText;
    } else if (props.white) {
        btnClr = colors.black;
    } else if (props.link) {
        btnClr = colors.white;
    } else if (props.accentAlt) {
        btnClr = colors.black;
    } else if (props.red) {
        btnClr = colors.white;
    }

    return btnClr;
};

const getBtnSize = props => {
    let btnSize = sizes.x1;

    if (props.size && sizes[props.size]) {
        btnSize = sizes[props.size];
    }

    return btnSize;
};

const getStylesForLink = props => {
    return `
        background: none;
        color: ${getBtnColors(props)};
    `;
};

const getBackgroundByProps = props => {
    if (props.inactive) {
        return BACKGROUNDS.grey;
    }
    return getBackgroundByPropsGlobal(props);
};

const Button = styled.button`
    font-family: ${({ condensed }) => (condensed ? 'Roboto Condensed' : 'inherit')};
    transition: all easy-in-out 0.2s;
    ${props => getBtnSize(props)}
    ${props => (props.link ? `padding: 10px` : '')}
    background: ${props =>
        !props.color ? (!props.bg ? getBackgroundByProps(props) : props.bg) : props.color};
    color: ${props => getBtnColors(props)};
    background-position: center center;
    background-size: cover;
    border: none;
    cursor: pointer;
    opacity: ${props => (props.disabled ? '0.4' : '1')};
    ${props => (props.link ? getStylesForLink(props) : '')};
    width: ${props => (props.width ? props.width : 'auto')};
    height: ${props => (props.height ? props.height : 'auto')};
    ${props => (props.block ? `display: block; margin: 10px auto;` : '')}
    ${props => (props.padding ? `padding: ${props.padding};` : '')}
    ${props => (props.margin ? `margin: ${props.margin};` : '')}
    ${props =>
        props.rounded
            ? `border-radius: ${props.rounded > 0 ? props.rounded : radiusBySizes[props.size]}px;`
            : ''}
    ${props => (!isNil(props.borderRadius) ? `border-radius: ${props.borderRadius};` : '')}
    ${props =>
        props.shadow
            ? !isNaN(props.shadow)
                ? `box-shadow: 0px 0px ${props.shadow}px rgba(0,0,0,0.4);`
                : `box-shadow: ${props.shadow};`
            : ''}
    ${props => (props.textsize ? `font-size: ${props.textsize};` : '')}
    ${props => (props.nowrap ? `white-space: nowrap;` : '')}
    ${props => (props.uppercase ? `text-transform: uppercase;` : '')}
    ${props => (props.capitalize ? `text-transform: capitalize;` : '')}
    ${props => (props.bold ? `font-weight: ${props.bold > 1 ? props.bold : 900};` : '')}
    ${props => (props.center ? `display: block; margin: 0 auto;` : '')}
    & a {
        color: ${props => getBtnColors(props)};
        text-decoration: none;
    }
`;

const ConfirmButton = styled(Button)`
    max-width: 90%;
    color: ${COLORS.black};
    width: 100%;
    box-shadow: 0 0 36px #b5b5b5;
    background: ${BACKGROUNDS.accent};
    margin: ${({ margin }) => margin || '0 auto'};

    display: block;
    border-radius: 30px;
`;

export default Button;

export { ConfirmButton };
