import React from 'react';
import { isMobile } from 'react-device-detect';
import styled, { css, keyframes } from 'styled-components';

import { TitleWrapper } from 'features/EventBoard/components/TrackNameWithCountry';
import { ProductButton, ProductsContainer } from 'features/EventBoard/components/TrackProducts';

import { RaceInfoWrapper, Divider } from 'features/EventBoard/components/styled';
import Text from 'ui/Text';

import { PRODUCT_COLORS, PRODUCT_IDS } from 'configs/products';

const shine = keyframes`
  0% {
    left: -100px
  }
  100% {
    left: 100%
  }
`;

const shineStyle = css`
    position: relative;
    overflow: hidden;

    &:before {
        content: '';
        position: absolute;
        width: 100px;
        height: 100%;
        background-image: linear-gradient(
            120deg,
            rgba(255, 255, 255, 0) 30%,
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0) 70%
        );
        top: 0;
        left: -100px;
        animation: ${shine} 1.5s infinite linear; /* Animation */
    }
`;

const PreloadWrapper = styled.div`
    display: flex;
    gap: ${isMobile ? '0' : '20px'};
    flex-direction: ${isMobile ? 'column' : 'row'};
    align-items: center;
    background-color: #fff;
    border-radius: 5px;
    padding: 15px 20px;
    justify-content: space-between;

    ${isMobile &&
    css`
        & > div {
            width: 100%;
        }
    `};
`;

const TitleWrapperPreload = styled(TitleWrapper)`
    background-color: #ebebeb;
    height: 26px;
    border-radius: 5px;
    margin-right: 10px;
    flex: 1 0 8%;
    ${shineStyle};
`;

const MiddleWrapperPreload = styled.div`
    display: flex;
    justify-content: start;
    gap: 25px;
    flex: 1 0 45%;
`;

const ProductButtonPreload = styled(ProductButton)`
    height: ${isMobile ? '40px' : '46px'};
    min-width: ${isMobile ? '45px' : '65px'};
    box-sizing: border-box;
    ${shineStyle};
    p {
        filter: blur(3px);
    }
`;

const RaceInfoWrapperPreload = styled(RaceInfoWrapper)`
    flex: 1 0 5%;
    margin: 0;

    & > div {
        max-width: 100px;
    }
`;

const ResultsTrackSelectionPreloader = () => {
    const PRODUCT_BUTTONS = { ...PRODUCT_COLORS, [PRODUCT_IDS.V4]: '#2CB16F' };
    return (
        <>
            {Array(isMobile ? 2 : 3)
                .fill({})
                .map((raceDay, index) => (
                    <PreloadWrapper key={index}>
                        {isMobile ? (
                            <>
                                <RaceInfoWrapperPreload>
                                    <TitleWrapperPreload />
                                </RaceInfoWrapperPreload>
                                <Divider />
                                <ProductsContainer>
                                    {Object.keys(PRODUCT_COLORS)
                                        .slice(0, 3)
                                        .map((key, index) => (
                                            <ProductButtonPreload
                                                key={index}
                                                background={PRODUCT_COLORS[key]}
                                            />
                                        ))}
                                </ProductsContainer>
                            </>
                        ) : (
                            <>
                                <TitleWrapperPreload />
                                <MiddleWrapperPreload>
                                    <ProductsContainer>
                                        {Object.keys(PRODUCT_BUTTONS)
                                            .slice(0, 3)
                                            .map((key, index) => (
                                                <ProductButtonPreload
                                                    key={index}
                                                    background={PRODUCT_BUTTONS[key]}
                                                >
                                                    <Text color={'white'} bold align={'center'}>
                                                        {key}
                                                    </Text>
                                                </ProductButtonPreload>
                                            ))}
                                    </ProductsContainer>
                                </MiddleWrapperPreload>
                                <RaceInfoWrapperPreload>
                                    <TitleWrapperPreload />
                                </RaceInfoWrapperPreload>
                            </>
                        )}
                    </PreloadWrapper>
                ))}
        </>
    );
};

export default ResultsTrackSelectionPreloader;
