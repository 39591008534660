import moment from 'moment';
import { COMMON_TIMESTAMP_FORMAT, SERVER_DATE_FORMAT } from './date';

window.moment = moment;

/**
 * @param  {string} timeOfEvent  String timestamp suitable for moment.utc()
 * @param  {string} actualTime   String timestamp suitable for moment.utc()
 * @return {number}              UTC timestamp
 */
export const calculateTimeLeft = (timeOfEvent, actualTime) => {
    const diff = moment(timeOfEvent).utc() - moment(actualTime).utc();
    return diff < 0 ? 0 : diff;
};

export const hourAgo = (time) => moment(time, 'HH:mm:ss').subtract(1, 'hour').format('HH:mm:ss');

export const hourAgoUTC = (time) =>
    moment.utc(time, 'HH:mm:ss').subtract(1, 'hour').format('HH:mm:ss');

export const formatDate = (datetime) => moment(datetime).format(SERVER_DATE_FORMAT);
export const formatTimestamp = (datetime) => moment(datetime).format(COMMON_TIMESTAMP_FORMAT);

export const formatTimeUtc = (time) => moment.utc(time, 'HH:mm:ss')

export const hasPast = (time) => moment(time, 'HH:mm:ss').isBefore(moment());

/**
 * Subtract and format time
 * @param UTCDate
 * @param time
 * @param local
 * @param formatDate
 * @returns {string}
 */
export const substrLocalTime = (UTCDate, time, local, formatDate = 'YYYY-MM-DD HH:mm:ss') => {
    const formatToUTC = moment.utc(UTCDate);

    const subtractedTime = local
        ? formatToUTC.clone().local().subtract(time, 'minutes')
        : formatToUTC.clone().subtract(time, 'minutes');

    return subtractedTime.format(formatDate);
};
