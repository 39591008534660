import { create } from 'zustand';
import { useDidUpdate } from 'rooks';

import { isVProduct as isV } from 'features/TrackPage/model/Product';
import { extractProductId } from 'features/EventBoard/utils/common';
import { ProductId } from 'features/EventBoard/server/calendar';

import useURLParameters from 'features/ResultsNew/hooks/useURLParameters';
import useTracksResultsQuery from 'features/ResultsNew/hooks/useTracksResultsQuery';
import usePoolResultsQuery from 'features/ResultsNew/hooks/usePoolResultsQuery';
import useCalendarDate from 'features/ResultsNew/hooks/useCalendarDate';

interface ResultPage {
    raceLineNumbers: number[];
    productId: ProductId;
    setProductId: (product: string) => void;
    isVProduct: (product: ProductId) => boolean;
    setRaceLineNumbers: (lineNumbers: number[]) => void;
}

const useResultPageStore = create<ResultPage>(set => ({
    raceLineNumbers: [],
    productId: 'V',
    isVProduct: productId => isV(productId),
    setProductId: product => set({ productId: extractProductId(product) }),
    setRaceLineNumbers: lineNumbers => set({ raceLineNumbers: lineNumbers }),
}));

const useResultPage = () => {
    const { date, product, trackId, race } = useURLParameters();
    const { setDate } = useCalendarDate();
    const { tracks } = useTracksResultsQuery();
    const { results } = usePoolResultsQuery();

    const { raceLineNumbers, productId, setRaceLineNumbers, setProductId, isVProduct } =
        useResultPageStore();

    useDidUpdate(() => {
        setDate(date);
    }, [date]);

    useDidUpdate(() => {
        if (results?.length && !isVProduct(productId)) {
            setRaceLineNumbers(results.map((_, index) => index + 1)); // Map results to their indices + 1
        }
    }, [results, productId]);

    useDidUpdate(() => {
        setProductId(product);
    }, [product]);

    return {
        tracks,
        results,
        date,
        trackId,
        raceNumber: race,
        isVProduct: isVProduct(productId),
        raceLineNumbers,
        productId,
    };
};

export default useResultPage;
