import React from 'react';
import styled from 'styled-components';
import Button from 'ui/controls/Button';
import bg404 from 'images/dk/404_bg.png';
import { withRouter } from 'react-router-dom';
import getTexts from 'utils/localization';
const t = getTexts();

const Bg = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-image: url('${bg404}');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
`;

class Scene404 extends React.Component {
    homeRedirect = () => {
        this.props.history.push('/hjem');
    };

    render() {
        return (
            <Bg>
                <Button
                    onClick={this.homeRedirect}
                    secondary
                    size="x3"
                    block
                    width="200px"
                    rounded="20px"
                    shadow="25"
                    margin="0px -100px"
                    style={{
                        position: 'absolute',
                        bottom: '100px',
                        left: '50%',
                    }}
                >
                    {t.home}
                </Button>
            </Bg>
        );
    }
}

export default withRouter(Scene404);
