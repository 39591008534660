import React, { ComponentClass, MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';
import { BACKGROUNDS, COLORS } from '../themes';
import useToggle from '../common/hooks/useToggle';
import useClickAnywhere from './hooks/useClickAnywhere';
import { ComponentType } from './types';

interface ArrowCSSSettingsByLocation {
    top: string;
    bottom: string;
}

interface ArrowLocationCSSSettings {
    transform: ArrowCSSSettingsByLocation;
    top: ArrowCSSSettingsByLocation;
    bottom: ArrowCSSSettingsByLocation;
}

const arrowLocationCSSSettings: ArrowLocationCSSSettings = {
    transform: {
        top: 'rotate(45deg)',
        bottom: 'rotate(225deg)',
    },
    top: {
        top: '-9px',
        bottom: 'auto',
    },
    bottom: {
        top: 'auto',
        bottom: '-9px',
    },
};

export const Tooltip = styled.div<{
    arrowLocation?: keyof ArrowCSSSettingsByLocation;
}>`
    position: absolute;
    background: #f7f8fa;
    border: 0.5px solid #9198aa80;
    border-radius: 15px;
    padding: 10px 15px;
    box-sizing: border-box;
    z-index: 99;
    text-align: left;
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    animation: fadeIn 300ms ease-in-out 0ms both;
    &::before {
        content: ' ';
        display: block;
        background: #f7f8fa;
        border-top: 0.5px solid #9198aa80;
        border-left: 0.5px solid #9198aa80;
        height: 15px;
        width: 15px;
        position: absolute;
        transform: ${({ arrowLocation = 'top' }) =>
            arrowLocationCSSSettings.transform[arrowLocation]};
        top: ${({ arrowLocation = 'top' }) => arrowLocationCSSSettings.top[arrowLocation]};
        bottom: ${({ arrowLocation = 'top' }) => arrowLocationCSSSettings.bottom[arrowLocation]};
        z-index: 98;
        left: 54px;
    }
`;

export const QuestionMark = styled.button`
    border: 0;
    background: ${BACKGROUNDS.black};
    width: 23px;
    height: 23px;
    border-radius: 15px;
    margin: 0;
    padding: 3px;
    box-sizing: border-box;
    color: ${COLORS.white};
    text-align: center;
    font-weight: 900;
    cursor: pointer;
    position: absolute;
`;

// increases clickable area to allow users to click near the question mark
export const Area = styled.span`
    display: block;
    width: 50px;
    height: 50px;
    position: relative;
    top: -30px;
    left: -15px;
`;

export const QuestionMarkWithTooltip = ({
    style = {},
    TooltipComponent = Tooltip,
    children,
}: {
    style: object;
    TooltipComponent?: ComponentType;
    children: ReactNode;
}) => {
    const [tooltipShown, toggleTooltip, closeTooltip] = useToggle(false);
    const ids = [
        'group-form-public-group-question-mark',
        'group-form-public-group-question-mark-area',
    ];

    useClickAnywhere((e) => {
        e.preventDefault();
        const target = e.target as HTMLElement;
        if (e.target && !ids.includes(target?.id)) closeTooltip();
    });

    return (
        <QuestionMark
            onClick={(e: MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                toggleTooltip();
            }}
            style={style}
            id={ids[0]}
        >
            ?<Area id={ids[1]} />
            {tooltipShown && <TooltipComponent>{children}</TooltipComponent>}
        </QuestionMark>
    );
};

export default Tooltip;
