import React from 'react';
import moment from 'moment';
import { ClockWrapper, Time } from './styled';
import { ReactComponent as ClockIcon } from 'images/icons/clock.svg';

class Clock extends React.Component {
    constructor() {
        super();

        this.state = {
            time: moment(),
        };
    }

    componentDidMount() {
        const oneMin = 60000;

        this.timeout = window.setInterval(() => {
            this.setState({
                time: moment(),
            });
        }, oneMin);
    }

    componentWillUnmount() {
        clearInterval(this.timeout);
    }

    render() {
        const formatedTime =
            typeof this.state.time.format === 'function'
                ? this.state.time.format('LT')
                : this.state.time.toString();

        return (
            <ClockWrapper>
                <ClockIcon />
                <Time>{formatedTime}</Time>
            </ClockWrapper>
        );
    }
}

export default Clock;
