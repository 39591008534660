import React, { useEffect, useState } from 'react';
import { Background, StyledFullScreen, QRContainer, MobileBetText } from './styled';

import AnimatedButton from 'ui/controls/AnimatedButton';
import { ContentHeight } from 'ui/FullScreen';
import Icon from 'ui/Icon';
import Spinner from 'ui/Spinner';

import Summary from './components/Summary';
import BetSlipCard from './components/BetSlipCard';

import { StyledFillable } from 'features/UserArea/UserAreaMenu/styled';
import PlayAndPay from 'features/BetSlip/components/PlayAndPay';
import { sprintf } from 'sprintf-js';
import NotificationConductor from 'common/conductors/NotificationConductor';
import usePrevious from 'common/hooks/usePrevious';
import qr from 'images/icons/qr-code.svg';
import LocalMobileBet from 'features/MobileBet/LocalMobileBet';
import UserSession from 'common/adapters/UserSession';

import { forMobile, IS_TERMINAL, isTerminal } from 'utils/platforms';
import getTexts from 'utils/localization';
import { logMessage } from 'utils/log';

import notifyAboutSelfExclusion from 'features/UserArea/SelfExclusion/notifyAboutSelfExclusion';
import ForcedPermissionForm from 'features/BetSlip/components/ForcedPermissionForm';
import UserConductor from 'common/conductors/UserConductor';

const t = getTexts();

let resetAnimatedButton;

const BetSlip = props => {
    const {
        ready,
        date,
        product,
        combinedTrackName,
        combinationsCount,
        combinationPrice,
        isCombinationPriceValid,
        isCouponAmountExceed,
        confirmBet,
        performPlayAndPaySale,
        betSlip,
        spinnerSize,
        minimumPicksValid,
        maximumPicksValid,
        performBetPending,
        availableCardsPending,
        availableCardsError,
        performBetError,
        user,
        smartLynMode,
        allLegsLocked,
        quickPickBetPending,
        terminalLogin,
    } = props;

    const [forcedPermissionStatus, setForcedPermissionStatus] = useState(true);
    const [forcedPermissionEmail, setForcedPermissionEmail] = useState(user?.email);

    const requireForcedPermission =
        Boolean(user) && !isTerminal && !Boolean(user.newsletter) && user.forcedPermission === null;

    // if (!ready && !IS_TERMINAL) {
    //     return null;
    // }

    let styles = props.styles || {};
    //  eslint-disable-next-line
    const [PAPVisibility, setPAPVisibility] = useState(true);
    const receiptSum = smartLynMode
        ? combinationPrice
        : betSlip
        ? betSlip.calculateReceiptSum(combinationsCount, combinationPrice, product)
        : 0;

    //  eslint-disable-next-line
    useEffect(() => {
        if (resetAnimatedButton !== null && (availableCardsError || performBetError || !user)) {
            resetAnimatedButton && resetAnimatedButton();
        }
    }, [availableCardsError, performBetError, user]);
    //  eslint-disable-next-line
    const prevQuickPickBetPending = usePrevious(quickPickBetPending);
    //  eslint-disable-next-line
    useEffect(
        () => {
            prevQuickPickBetPending && !quickPickBetPending && resetAnimatedButton();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [quickPickBetPending]
    );

    const onConfirmButtonClick = async () => {
        if (user) {
            const betHasBeenPerformed =
                !quickPickBetPending && !performBetPending && !availableCardsPending;

            if (user.isSelfExcluded()) {
                notifyAboutSelfExclusion();
                resetAnimatedButton();
                return;
            }

            if (requireForcedPermission) {
                try {
                    await UserConductor.setForcedPermission(
                        forcedPermissionStatus,
                        forcedPermissionEmail
                    );
                } catch (e) {
                    console.error(e);
                    logMessage(e, {
                        context: 'BetSlip.onConfirmButtonClick:requireForcedPermission',
                    });
                }
            }

            if (betHasBeenPerformed) {
                try {
                    await confirmBet();
                } catch (e) {
                    console.error(e);
                    logMessage(e, {
                        context: 'BetSlip.onConfirmButtonClick:betHasBeenPerformed',
                    });
                }
                resetAnimatedButton();
            }
        } else {
            resetAnimatedButton();

            if (IS_TERMINAL) {
                let message =
                    UserSession.isManagerEntrance() && UserSession.getSessionId()
                        ? t.Terminal.notifications.startManagerSession
                        : t.Terminal.notifications.startSession;

                /** restoring lost user on ManagerPC  */
                if (
                    // UserSession.isManagerEntrance() &&
                    UserSession.getSessionId()
                ) {
                    console.log('TS. Restoring from BetSlip');

                    message = t.Terminal.notifications.startManagerSession;
                    terminalLogin(UserSession);
                }

                NotificationConductor.info(message);
            } else {
                props.onAuthenticationRequired();
            }
        }
    };

    const showPlayAndPayButton = () => {
        return PAPVisibility ? (
            <PlayAndPay
                conditionToShow={!isInvalidBetslipFilling()}
                performSale={playAndPayHandler}
            />
        ) : null;
    };

    const showMobileBetButton = () => {
        if (!isInvalidBetslipFilling()) {
            const postTime = props.race && props.race.postTime ? props.race.postTime : '';
            const raceIsStarted = LocalMobileBet.isOutdated(`${date} ${postTime}`);

            return forMobile(
                props.mobileBetInit ? (
                    <QRContainer>
                        <Spinner />
                    </QRContainer>
                ) : raceIsStarted ? null : (
                    <QRContainer
                        onClick={() => {
                            props.performMobileBet({
                                amountToPut: combinationPrice,
                                sumPrice: receiptSum,
                            });
                        }}
                    >
                        <Icon size="x07" src={qr} />
                        <MobileBetText>{t.Terminal.mobileBet.title}</MobileBetText>
                    </QRContainer>
                )
            );
        }
    };

    const playAndPayHandler = () => {
        setPAPVisibility(false);
        console.log('PAPVisibility', PAPVisibility);

        performPlayAndPaySale().finally(() => {
            console.log('Restore PAPVisibility', PAPVisibility);
            setPAPVisibility(true);
        });
    };

    const isInvalidBetslipFilling = () => {
        return !smartLynMode
            ? !isCombinationPriceValid ||
                  isCouponAmountExceed ||
                  !minimumPicksValid ||
                  !maximumPicksValid
            : !isCombinationPriceValid || isCouponAmountExceed || allLegsLocked;
    };

    const playAndPayWorkflow = !!showPlayAndPayButton();

    return (
        <Background style={styles.Background}>
            <StyledFullScreen style={styles.FullScreen}>
                {!IS_TERMINAL && (
                    <ContentHeight>
                        <Summary
                            product={product}
                            combinedTrackName={combinedTrackName}
                            date={date}
                            styles={styles}
                        />
                    </ContentHeight>
                )}

                <StyledFillable style={styles.StyledFillable}>
                    {ready || IS_TERMINAL ? (
                        <BetSlipCard
                            {...props}
                            combinedTrackName={combinedTrackName}
                            styles={styles}
                            playAndPayWorkflow={playAndPayWorkflow}
                        />
                    ) : (
                        <Spinner />
                    )}
                    {receiptSum > 0 && (
                        <AnimatedButton
                            onClickHandler={onConfirmButtonClick}
                            accentAlt={!isInvalidBetslipFilling()}
                            disabled={isInvalidBetslipFilling()}
                            grey={isInvalidBetslipFilling()}
                            className="uppercase"
                            style={{
                                position: 'relative',
                                top: '-25px',
                                boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.2) ',
                                fontWeight: 800,
                                padding: '12px 10px',
                                width: '80%',
                                ...styles.AnimatedButton,
                                cursor: isInvalidBetslipFilling() ? 'not-allowed' : 'pointer',
                            }}
                            label={
                                (isCombinationPriceValid
                                    ? sprintf(t.betSlip.confirmBet, receiptSum)
                                    : t.betSlip.disabledBtn) + ` ${t.currency}`
                            }
                            spinnerSize={spinnerSize}
                            styles={{ Label: styles.Label }}
                        >
                            {resetState => (resetAnimatedButton = resetState)}
                        </AnimatedButton>
                    )}
                    {requireForcedPermission && (
                        <ForcedPermissionForm
                            forcedPermissionStatus={forcedPermissionStatus}
                            setForcedPermissionStatus={setForcedPermissionStatus}
                            forcedPermissionEmail={forcedPermissionEmail}
                            setForcedPermissionEmail={setForcedPermissionEmail}
                        />
                    )}
                    {showPlayAndPayButton()}
                    {showMobileBetButton()}
                </StyledFillable>
            </StyledFullScreen>
        </Background>
    );
};

export default BetSlip;
