import React, { useEffect, useState, useCallback } from 'react';
import castIcon from 'icons/chrome-cast.svg';
import playIcon from '../Game/GameStatistic/icons/play.svg';
import pauseIcon from '../Game/GameStatistic/icons/pause.svg';
import { CastStream, CastStreamWrapper, PlayPauseButton } from './uiComponents';
import Icon from '../../ui/Icon';

let chrome = window.chrome;
let cast = window.cast;

/* Update cast instance when Google Chromecast api is loaded*/
window.__onGCastApiAvailable = isAvailable => {
    if (isAvailable) {
        cast = window.cast;
    }
};

const CastButton = ({ src, title = '', startStopCast = () => {} }) => {
    const [showButton, setShowButton] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [isPaused, setIsPaused] = useState(false);

    /* If is Chrome browser initialize chromecast script */
    useEffect(() => {
        if (typeof chrome === undefined) {
            // not chrome
            return;
        }

        /* Waiting for Google Chromecast api is ready*/
        const loadCastInterval = setInterval(function () {
            if (chrome.cast?.isAvailable && cast) {
                clearInterval(loadCastInterval);

                setShowButton(true);

                const player = new cast.framework.RemotePlayer();
                setIsConnected(player.isConnected);

                listenToRemote();
                initCastApi();
            }
        }, 1000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        isConnected && launchApp(src);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isConnected, src]);

    useEffect(() => {
        startStopCast(isConnected);
    }, [isConnected, startStopCast]);

    /* Setting options for upcoming cast */
    const initCastApi = () => {
        cast.framework.CastContext.getInstance().setOptions({
            receiverApplicationId: chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
            autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED,
        });
    };

    /* Connecting to device/receiver by creating session and set media for casting */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const launchApp = useCallback((videoSrc, stopSession = false) => {
        const castSession = cast.framework.CastContext.getInstance().getCurrentSession();
        if (stopSession && castSession) {
            stopApp();
        }

        return connectToSession()
            .then(session => {
                const mediaInfo = new chrome.cast.media.MediaInfo(videoSrc);
                mediaInfo.contentType = 'application/x-mpegURL';
                mediaInfo.streamType = chrome.cast.media.StreamType.LIVE;
                mediaInfo.metadata = new chrome.cast.media.TvShowMediaMetadata();
                mediaInfo.metadata.title = title;
                const request = new chrome.cast.media.LoadRequest(mediaInfo);
                request.autoplay = true;
                return session.loadMedia(request);
            })
            .catch(error => {
                console.log(error);
                // TODO: Handle any errors here
            });
    });

    const connectToSession = () => {
        return Promise.resolve().then(() => {
            var castSession = cast.framework.CastContext.getInstance().getCurrentSession();
            if (!castSession) {
                return cast.framework.CastContext.getInstance()
                    .requestSession()
                    .then(() => {
                        return Promise.resolve(
                            cast.framework.CastContext.getInstance().getCurrentSession()
                        );
                    });
            }
            return Promise.resolve(castSession);
        });
    };

    /* Receiver/player events */
    const listenToRemote = () => {
        const player = new cast.framework.RemotePlayer();
        const playerController = new cast.framework.RemotePlayerController(player);
        setIsConnected(player.isConnected);

        playerController.addEventListener(
            cast.framework.RemotePlayerEventType.IS_PAUSED_CHANGED,
            () => {
                setIsPaused(player.isPaused);
            }
        );

        playerController.addEventListener(
            cast.framework.RemotePlayerEventType.IS_CONNECTED_CHANGED,
            () => {
                setIsConnected(player.isConnected);
                !player.isConnected && stopApp();
            }
        );
    };
    const stopApp = () => {
        const castSession = cast.framework.CastContext.getInstance().getCurrentSession();
        castSession && castSession.endSession(true);
    };

    const togglePlayPause = () => {
        const player = new cast.framework.RemotePlayer();
        const playerController = new cast.framework.RemotePlayerController(player);
        playerController.playOrPause();
    };

    return (
        showButton && (
            <CastStreamWrapper isConnected={isConnected}>
                <PlayPauseButton isConnected={isConnected} onClick={togglePlayPause}>
                    {isPaused ? (
                        <Icon size="x3" src={playIcon} />
                    ) : (
                        <Icon size="x3" src={pauseIcon} />
                    )}
                </PlayPauseButton>
                <CastStream
                    size="x3"
                    src={castIcon}
                    color={isConnected ? 'lightBlue' : 'white'}
                    className={'cast-button-cl'}
                    id={'cast-button'}
                    onClick={() => launchApp(src, true)}
                />
            </CastStreamWrapper>
        )
    );
};

export default CastButton;
