import RaceDay from 'common/DataObjects/RaceDay';
import productSelector, { shouldProductBeHidden } from 'common/selectors/productSelector';
import { isMultitrackProduct } from 'common/selectors/multipleTrackSetupsSelector';
import { toggleModule } from 'utils/env';
import { memoize, get } from 'lodash';
import RacingCard from 'common/DataObjects/RacingCard';

export const root = (state) => state.AISDataProvider;

export const getRaceDay = (state) => root(state).selectedRaceDay;

export const getSelectedDate = (state) => root(state).racingCardData.date;

export const getServerTime = (state) => root(state).serverTime;

export const getCountryCode = (state) => get(getRaceDay(state), 'country.code', '');

export const getInputErrorReason = (state) => root(state).inputErrorReason;

export const getRaceDayData = (state) => root(state).raceDayData;

/**
 * @param  {Object}  state
 * @return {RaceDay}
 */
const raceDayDTOSelector = (state) => {
    if (!root(state).raceDayData) {
        return new RaceDay();
    }
    return new RaceDay(getRaceDay(state));
};

export const raceDayFetchedSelector = (state) => root(state).raceDayFetched;

export const getRaceDayForSelectedProduct = (state) => {
    return root(state).raceDayData.find((raceDay) => {
        return raceDay.raceCardAvailable
            ? raceDay.products.find((product) => {
                  return (
                      product.id === productSelector(state).id &&
                      !shouldProductBeHidden(product, raceDay.track.id)
                  );
              })
            : false;
    });
};

export const getDefaultRaceDay = (state) => {
    return root(state).raceDayData
        ? root(state).raceDayData.sort((a, b) =>
              a.firstRacePostTime < b.firstRacePostTime ? -1 : 1
          )[0]
        : {};
};

const raceDaySelectorMemoized = memoize(raceDayDTOSelector, (state) => {
    const raceDay = getRaceDay(state);

    if (raceDay === null) {
        return null;
    }
    return raceDay.date + raceDay.trackId;
});

/**
 *
 * @param  {Object} state
 * @return {Leg[]}
 */
export const getMultitrackLegs = (state) => {
    const raceDay = raceDayDTOSelector(state);
    const productId = productSelector(state).id;
    if (!isMultitrackProduct(state) || !raceDay.multipleTrackSetups[productId]) {
        return [];
    }
    return raceDay.multipleTrackSetups[productId].legs;
};

/**
 * Return multitrack setups for CURRENT RACE DAY
 * Keys are product ids, values are MultipleTrackSetup instances
 * @param state
 * @return {MultipleTrackSetup}
 */
export const getMultitrackSetups = (state) => {
    const raceDay = raceDayDTOSelector(state);
    return raceDay.multipleTrackSetups;
};

export const getAllMultitrackSetupsByDate = (state) => {
    const raceDay = raceDayDTOSelector(state);
    const raceDays = root(state).raceDayData;

    if (!raceDay || !raceDays) {
        return [];
    }

    const date = raceDay.date;
    return raceDays
        .filter((raceDay) => raceDay.date === date)
        .map((raceDay) => {
            return Object.entries(raceDay.multipleTrackSetups);
        })
        .reduce((previousEntries, currentEntries) => {
            return [...previousEntries, ...currentEntries];
        }, []);
};

/**
 * Returns multitrack id (like 40) in case track is present in multitrack setups (for any product).
 * Otherwise returns usual track id.
 * Warning: it doesn't consider current product.
 * Use it only in rare cases, e.g. to find trackId in global pool list (fetchAllPoolInformation endpoint)
 * @param {Array<Array<string, MultipleTrackSetup>>} allTracksMultitrackSetupsEntries , Entries of the object, keys are product ids, values are MultipleTrackSetup instances
 * @param track
 * @return {number}
 */
export const getUniversalTrackId = (allTracksMultitrackSetupsEntries, track) => {
    const multitrackSetupEntry = allTracksMultitrackSetupsEntries.find(([, multitrackSetup]) =>
        multitrackSetup.trackIds.includes(track.code)
    );

    if (!multitrackSetupEntry) {
        return track.id;
    }

    const [, multitrackSetup] = multitrackSetupEntry;

    return multitrackSetup.trackId;
};

/**
 *
 * @param state
 * @param trackId?
 * @param date
 * @return {RaceDay}
 */
export const getRaceDayBy = (state, { trackId, date }) => {
    if (!root(state).raceDayFetched) {
        return new RaceDay({ date });
    }

    return (
        root(state).raceDayData.find((raceDay) => {
            if (trackId === undefined) {
                return raceDay.date === date;
            }
            return raceDay.trackId === trackId && raceDay.date === date;
        }) || new RaceDay({ date })
    );
};

export const getRaceNumbersByCurrentProduct = (state) => {
    const raceDay = raceDayDTOSelector(state);

    return raceDay.raceNumbersByProducts[root(state).selectedProduct.id] || [];
};

/**
 * Returns true in case when no races returned for selected race day
 * @param state
 * @return boolean
 */
export const noRacesForSelectedDay = (state) => {
    return root(state).raceDayFetched && raceDayDTOSelector(state).isNull();
};

export const isPending = (state) => {
    const { raceDayPending, trackPoolPending, racingCardPending } = root(state);

    return raceDayPending || trackPoolPending || racingCardPending;
};

export const gotEventError = (state) => {
    const { raceDayError, trackPoolError, racingCardError, coupleRacingCardError } = root(state);

    const racingCardData = RacingCard.fill(root(state));

    return (
        raceDayError ||
        trackPoolError ||
        racingCardError ||
        coupleRacingCardError ||
        (racingCardData.races && racingCardData.races.length === 0 && !isPending(state))
    );
};

/**
 * Returns true in case when racing card response was returned, but data is missing
 * @param state
 * @return boolean
 */
export const noRacingCardData = (state) => {
    const races = root(state).racingCardData.races || [];
    const racingCardFetched = root(state).racingCardFetched;
    return racingCardFetched && races.length === 0;
};

/**
 * Returns true in case when pool response was returned, but data is missing
 * @param state
 * @return boolean
 */
export const noPoolData = (state) => {
    const trackPoolKeys = Object.keys(root(state).trackPool);
    const trackPoolFetched = root(state).trackPoolFetched;
    return trackPoolFetched && trackPoolKeys.length === 0;
};

export const getCountry = (state) => raceDayDTOSelector(state).country?.code;

export default toggleModule(raceDayDTOSelector, raceDaySelectorMemoized, 'MEMOIZED_SELECTORS');
