export const SHOW_KEYBOARD = 'SHOW_KEYBOARD';
export const HIDE_KEYBOARD = 'HIDE_KEYBOARD';

export const showKeyboard = () => {
    return (dispatch) => {
        dispatch({
            type: SHOW_KEYBOARD,
            payload: {},
        });
    };
};

export const hideKeyboard = (value) => {
    return (dispatch) => {
        dispatch({
            type: HIDE_KEYBOARD,
            payload: value,
        });
    };
};
