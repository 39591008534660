import useLegacyAdaptions from 'features/EventBoard/hooks/useLegacyAdaptions';
import { NEW_HOMEPAGE_UI } from 'configs/main';

const LegacyAdaptions = () => {
    useLegacyAdaptions({ enabled: NEW_HOMEPAGE_UI });

    return null;
};

export default LegacyAdaptions;
