import React from 'react';
import styled from 'styled-components';
import saadanNavigereImg from './assets/saadan_navigere_graphic.png';
import saadanSpillerImg from './assets/saadan_spiller_du_graphic.png';
import Wrapper from 'ui/Wrapper';
import Text, { TextFrame } from 'ui/Text';
import Headline from 'ui/Headline';
import getTexts from 'utils/localization';
import { byPlatform } from 'utils/platforms';

const t = getTexts();

const StyledRow = styled.div`
    display: flex;
    flex-wrap: wrap;
    > * {
        flex: 1;
    }
`;

const StyledImg = styled.img`
    margin-top: -30px;
    width: 335px;
`;

const StyledText = styled(Text)`
    min-width: 110px;
`;

class HowToPlayDescription extends React.Component {
    render() {
        return (
            <Wrapper padding="20px 0 0 0">
                <StyledRow>
                    <TextFrame>
                        <Headline size="x2" condensed bold>
                            {t.HowToPlay.Section1.title}
                        </Headline>
                        <StyledText size="x1" condensed lh={21}>
                            {t.HowToPlay.Section1.description}
                        </StyledText>
                    </TextFrame>
                    <Wrapper align="center">
                        <StyledImg src={saadanNavigereImg} />
                    </Wrapper>
                </StyledRow>
                <StyledRow>
                    <Wrapper align="left">
                        <StyledImg src={saadanSpillerImg} />
                    </Wrapper>
                    <Wrapper>
                        <Headline size="x2" condensed bold>
                            {t.HowToPlay.Section2.title}
                        </Headline>
                        <StyledText size="x1" lh={21} condensed>
                            {t.HowToPlay.Section2.description}
                        </StyledText>
                    </Wrapper>
                </StyledRow>
                <Text
                    padding={byPlatform('10px', '0px 30px')}
                    bold="500"
                    size="18"
                    condensed
                >
                    {t.Article.gameTypes}
                </Text>
            </Wrapper>
        );
    }
}

export default HowToPlayDescription;
