import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Card } from 'ui/Card';
import { PRODUCTS_IMAGES } from 'configs/products';
import { TAG_ICONS } from './styled';
import { DOMAIN_PATH } from 'configs/main';
import { COLORS } from 'themes';
import Text from 'ui/Text';
import Headline from 'ui/Headline';
import Wrapper from 'ui/Wrapper';
import { byPlatform } from 'utils/platforms';
import { isOnPath } from 'utils/navigation';

const StyledListItem = styled.div`
    flex: 1 0 ${(props) => 100 / (props.itemsPerRow ? props.itemsPerRow : 1)}%;
    padding: 6px;
    box-sizing: border-box;
    max-width: ${(props) =>
        props.itemStyle.maxWidth ? props.itemStyle.maxWidth : '375px'};
    margin: ${(props) =>
        Boolean(props.itemStyle.margin)
            ? props.itemStyle.margin
            : byPlatform('25px 40px', '25px 10px')};
    height: ${(props) =>
        props.itemStyle.height ? props.itemStyle.height : ''};
    min-height: 150px;
    ${(props) =>
        props.itemStyle.minWidth
            ? `min-width: ${props.itemStyle.minWidth};`
            : ''};
`;

const StyledCard = styled(Card)`
    cursor: pointer;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    height: ${(props) =>
        props.cardStyle.height ? props.cardStyle.height : ''};
    position: relative;
    border-radius: 15px;
    &:before {
        content: '';
        background: ${(props) =>
            props.articleType === 'news'
                ? TAG_ICONS[props.productId]
                    ? 'url("' + TAG_ICONS[props.productId] + '")'
                    : PRODUCTS_IMAGES[props.productId]
                : ''};
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        position: absolute;
        width: 75px;
        height: 28px;
        color: ${COLORS.white};
        font-weight: bold;
        border-radius: 4px;
        text-align: center;
        top: 102px;
        font-size: 16px;
        line-height: 28px;
        display: block;
    }
`;

const BottomSubText = styled.div`
    padding: ${({ padding }) => (padding ? padding : '10px')};
    border-top: 1px solid #eaeaea;
    box-sizing: border-box;
    background: #f8f8f8;
    color: ${COLORS.primaryText};
    font-family: 'Roboto Condensed';
    > * {
        margin: 0;
        font-family: Roboto Condensed;
    }
`;

const StyledCardImg = styled.div`
    background: url(${(props) => props.imgUrl}) top center no-repeat;
    height: ${(props) =>
        props.imgStyle.height ? props.imgStyle.height : '140px'};
    background-size: cover;

    ${(props) =>
        props.imgStyle.filter ? `filter: ${props.imgStyle.filter};` : ''};
    ${(props) =>
        props.imgStyle.transition
            ? `transition: ${props.imgStyle.transition};`
            : ''};
    ${(props) =>
        props.imgStyle.hover
            ? `&:hover { ${props.imgStyle.hover}; }`
            : 'filter: none'};
`;

const StyledNewsCardImg = styled.img`
    width: 100%;
`;

const StyledDescription = styled(Text)`
    padding: 0;
    font-family: 'Roboto Condensed';
    font-weight: ${(props) => props.descriptionStyles.fontWeight || '400'};
    font-size: ${(props) => props.descriptionStyles.fontSize || '18px'};
    color: ${(props) => props.descriptionStyles.color || COLORS.primaryText};
    ${(props) =>
        props.descriptionStyles.height
            ? `height: ${props.descriptionStyles.height};`
            : ''};
    ${(props) =>
        props.descriptionStyles.overflow
            ? `overflow: ${props.descriptionStyles.overflow};`
            : ''};
`;

class ArticleCard extends React.Component {
    clickHandler() {
        let articleId = isOnPath('/sadan-spiller-du')
            ? this.props.product_id
            : this.props.link;

        this.props.onCardClick && this.props.onCardClick(articleId);
    }

    render() {
        const styles = this.props.styles || {},
            listItemStyles = styles.StyledListItem || {},
            cardStyles = styles.card || {},
            textWrapperStyles = styles.textWrapper || {},
            imgStyles = styles.img || {},
            descriptionStyles = styles.text || {};

        return (
            <StyledListItem
                onClick={this.clickHandler.bind(this)}
                itemsPerRow={this.props.itemsPerRow}
                itemStyle={listItemStyles}
            >
                <StyledCard
                    productId={this.props.product_id}
                    articleType={this.props.articleType}
                    cardStyle={cardStyles}
                >
                    {this.props.articleType === 'news' ? (
                        <StyledNewsCardImg
                            src={DOMAIN_PATH + this.props.image}
                        />
                    ) : (
                        <StyledCardImg
                            imgUrl={DOMAIN_PATH + this.props.image}
                            imgStyle={imgStyles}
                        />
                    )}
                    <Wrapper padding={textWrapperStyles.padding}>
                        <Headline
                            size="x1"
                            short="true"
                            style={styles.Headline}
                        >
                            {this.props.header}
                        </Headline>
                        {this.props.teaser && (
                            <StyledDescription
                                descriptionStyles={descriptionStyles}
                                dangerouslySetInnerHTML={{
                                    __html: this.props.teaser,
                                }}
                            />
                        )}
                    </Wrapper>
                    {this.props.term_text ? (
                        <BottomSubText
                            padding={textWrapperStyles.padding}
                            dangerouslySetInnerHTML={{
                                __html: this.props.rules,
                            }}
                        />
                    ) : null}
                </StyledCard>
            </StyledListItem>
        );
    }
}

export default withRouter(ArticleCard);
