import styled from 'styled-components';
import { boxShadowMixin } from 'ui/Card';
import { byPlatform } from 'utils/platforms';
import { FilledWrapper } from 'ui/Wrapper';

const TrioOddsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 30px 30px 10px 30px;
`;

const RaceCardWrapper = styled(FilledWrapper).attrs({ white: true })`
    position: relative;
    z-index: 15;
    ${boxShadowMixin};
`;

const TrioOddsWrapperBlock = styled.div`
    ${byPlatform(
        null,
        `
        background: #EBEFF6;
        border: 2px solid #E1E5E9;
        margin: -20px 80px 20px 80px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        position: relative;
    `,
        `
        background: #EBEFF6;
        border: 2px solid #E1E5E9;
        margin: -20px 80px 20px 80px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        position: relative;
    `
    )};
`;

export { RaceCardWrapper, TrioOddsWrapper, TrioOddsWrapperBlock };
