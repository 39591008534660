import styled from 'styled-components';
import { getTextColorByProps } from '../utils/theme-helpers';

function getFontSize(props) {
    let size = '36px';

    if (String(props.size).endsWith('px')) {
        return props.size;
    }

    if (typeof props.size === 'number') {
        return props.size + 'px';
    }

    switch (props.size) {
        case 'x6':
            size = '36px';
            break;
        case 'x5':
            size = '30px';
            break;
        case 'x4':
            size = '26px';
            break;
        case 'x3':
            size = '22px';
            break;
        case 'x2':
            size = '18px';
            break;
        case 'x1':
            size = '14px';
            break;
        case 'x05':
            size = '10px';
            break;
        default:
            size = '36px';
    }

    return size;
}

const Headline = styled.h1`
    font-size: ${(props) => getFontSize(props)};
    margin: ${(props) => props.margin || '0px'};
    padding: ${(props) => props.padding || '10px 0px'};
    color: ${(props) => getTextColorByProps(props)};
    text-align: ${(props) => props.align || 'left'};
    font-weight: ${(props) => (props.bolder ? 900 : 500)};
    ${(props) => (props.height ? `height: ${props.height}` : '')};
    ${(props) =>
        props.short
            ? `text-overflow: ellipsis; white-space: nowrap; overflow: hidden; width: auto;`
            : ''};
    ${(props) => (props.shadow ? `text-shadow: ${props.shadow};` : '')};
    ${(props) => {
        if (props.condensed) {
            return 'font-family: Roboto Condensed';
        }
    }};
`;

export default Headline;
