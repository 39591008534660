import React from 'react';
import {
    ArrowDown,
    ArrowUp,
    CombinationsCounter,
    Toggler,
    TopLine,
} from 'features/BetSlip/platforms/Desktop/styled';
import Text from 'ui/Text';
import { NotifierCounter } from 'features/BetSlip/components/BetslipNotifier/StyledViews';
import { useSelector } from 'react-redux';
import combinationsCountSelector from 'common/selectors/combinationsCountSelector';
import productSelector from 'common/selectors/productSelector';

const BetslipHeader = ({ toggleBetslipVisibility, isBetslipHidden, combinationPrice }) => {
    const combinationsCount = useSelector(combinationsCountSelector);
    const selectedProduct = useSelector(productSelector);

    return (
        <TopLine onClick={toggleBetslipVisibility}>
            <CombinationsCounter>
                {selectedProduct.isVProduct() ? (
                    <Text white align="center">
                        <NotifierCounter combinationsCount={combinationsCount}>
                            {combinationsCount}
                        </NotifierCounter>{' '}
                        x <b>Indsats</b>
                    </Text>
                ) : (
                    <Text white align="center">
                        <b>Indsats:</b> <NotifierCounter>{combinationsCount}</NotifierCounter> x{' '}
                        {combinationPrice}
                    </Text>
                )}
            </CombinationsCounter>
            <Toggler>{isBetslipHidden ? <ArrowUp /> : <ArrowDown />}</Toggler>
        </TopLine>
    );
};

export default BetslipHeader;
