import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { toggleNewsletterSubscription } from 'common/actions/authActions';
import { Line, Right } from 'features/UserArea/UserAreaMenu/styled';
import { ContentHeight, FullScreen } from 'ui/FullScreen';
import Headline from 'ui/Headline';
import Text from 'ui/Text';
import COLORS from 'themes/dk/colors';
import getTexts from 'utils/localization';
import { isUserLoggedIn } from 'common/actions/authActions';
import Checkbox from 'ui/Checkbox';
import UserConductor from 'common/conductors/UserConductor';

const t = getTexts();

const ContentWrapper = styled(ContentHeight)`
    margin: 0 auto;
    padding: 20px 50px;
    width: 70%;
`;

const SubHeader = styled(Headline)`
    color: ${COLORS.dark};
    font-weight: 900;
    font-size: 16px;
    width: 70%;
`;

const CheckboxWithLabel = styled(Right)`
    margin-left: 15px;
    width: 30%;
    display: flex;
    align-items: center;
    font-weight: 700;
`;

const Label = styled.span`
    padding-left: 10px;
`;

const StyledText = styled(Text)`
    margin: 0 auto;
    font-size: 14px;
`;

const Link = styled.a`
    color: ${COLORS.primaryText};
`;

class Newsletter extends Component {
    componentDidMount() {
        this.props.isUserLoggedIn();
    }

    state = {
        forcedPermissionPending: false,
    };

    toggleNewsletterSubscription = () => {
        !this.props.newsletterSubscriptionPending &&
            this.props
                .toggleNewsletterSubscription()
                // isUserLoggedIn is called for updating whole user data in the state
                .then(this.props.isUserLoggedIn);
    };

    toggleForcedPermission = () => {
        this.setState({ forcedPermissionPending: true });

        const { user } = this.props;

        UserConductor.setForcedPermission(!user.forcedPermission, user.email)
            .then(this.props.isUserLoggedIn)
            .finally(() => this.setState({ forcedPermissionPending: false }));
    };

    render() {
        return (
            <FullScreen>
                {/* Newsletter subscription */}
                <ContentWrapper>
                    <Line>
                        <SubHeader>{t.userArea.newsletter.header}</SubHeader>
                        <CheckboxWithLabel>
                            <Checkbox
                                size="x2"
                                checked={this.props.user?.hasNewsletterSubscription}
                                changeHandler={this.toggleNewsletterSubscription}
                                disabled={this.props.newsletterSubscriptionPending}
                            />
                            <Label>{t.userArea.newsletter.label}</Label>
                        </CheckboxWithLabel>
                    </Line>
                    <StyledText>
                        {t.userArea.newsletter.text}{' '}
                        <Link
                            href="https://intercom.help/25syv/da/articles/3870767-persondatapolitik-for-25syv-a-s"
                            target="_blank"
                        >
                            {t.userArea.newsletter.link}
                        </Link>
                    </StyledText>
                </ContentWrapper>
                {/* Forced permission */}
                <ContentWrapper>
                    <Line>
                        <SubHeader>{t.userArea.newsletter.forcedPermissionHeader}</SubHeader>
                        <CheckboxWithLabel>
                            <Checkbox
                                size="x2"
                                checked={this.props.user?.forcedPermission}
                                changeHandler={this.toggleForcedPermission}
                                disabled={this.state.forcedPermissionPending || !this.props.user}
                            />
                        </CheckboxWithLabel>
                    </Line>
                    <StyledText>
                        {t.userArea.newsletter.forcedPermissionText}{' '}
                        <Link
                            href="https://intercom.help/25syv/da/articles/3870767-persondatapolitik-for-25syv-a-s"
                            target="_blank"
                        >
                            {t.userArea.newsletter.link}
                        </Link>
                    </StyledText>
                </ContentWrapper>
            </FullScreen>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        newsletterSubscriptionPending: state.auth.newsletterSubscriptionPending,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        isUserLoggedIn: () => {
            dispatch(isUserLoggedIn());
        },
        toggleNewsletterSubscription: (user) => {
            return dispatch(toggleNewsletterSubscription(user));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Newsletter);
