import React from 'react';
import { thousandsDots } from 'utils/formatters';
import { Horse } from 'features/TrackPage/model/Race';

interface KrStartColProps {
    horse: Horse;
}

const KrStartCol = ({ horse }: KrStartColProps) => {
    return (
        <>
            {horse.horseStat
                ? !horse.horseStat.total?.earning?.sum || !horse.horseStat.total?.nrOfStarts
                    ? 0
                    : thousandsDots(
                          (
                              horse.horseStat.total.earning.sum / horse.horseStat.total.nrOfStarts
                          ).toFixed(0)
                      )
                : 'n/a'}{' '}
            kr.
        </>
    );
};

export default KrStartCol;
