import { useDidMount, useDidUpdate } from 'rooks';
import { useState } from 'react';
import { request } from 'utils/server';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import useTransactionStateStore from './useTransactionStateStore';

interface Transaction {
    amount: number;
    balance: number;
    product: string;
    refId: number;
    date: string;
    text: string;
    transId: number;
    transactionDate: number;
    typeSub: 'string';
}

interface FetchTransactionListData {
    group: 0 | 1 | 2;
    dateFrom: string;
    dateTo: string;
    offset: number;
    rowCount: number;
}

interface FetchTransactionListResponse {
    transactions: Transaction[];
    count: number;
}

const fetchTransactionList = async (data: FetchTransactionListData) => {
    const { group, dateFrom, dateTo, offset, rowCount } = data;
    const response = await request<FetchTransactionListResponse, FetchTransactionListData>(
        'Punter',
        'GET',
        `/getTransactionGroupList/${group}/${dateFrom}/${dateTo}/${offset}/${rowCount}`
    );

    return response.data;
};

const useTransactionQuery = () => {
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const queryClient = useQueryClient();
    const { transactionState } = useTransactionStateStore();

    const { group, dateFrom, dateTo, rowCount } = transactionState ?? {};
    const enableFetch =
        group !== undefined && group !== null && !!dateFrom && !!dateTo && !!rowCount;

    const { status, data, error, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
        useInfiniteQuery<FetchTransactionListResponse, Error>({
            queryKey: ['transactionList', group, dateFrom, dateTo],
            queryFn: ({ pageParam = 0 }) =>
                fetchTransactionList({
                    ...transactionState,
                    offset: pageParam,
                } as FetchTransactionListData),
            getNextPageParam: (lastPage, allPages) => {
                const nextPage = allPages.length;
                const hasMore =
                    lastPage.transactions.length === rowCount &&
                    lastPage.count !== nextPage * rowCount;
                return hasMore ? nextPage : undefined;
            },
            enabled: enableFetch,
            staleTime: 60 * 5 * 1000,
        });

    useDidMount(() => {
        // Loop through groups [0, 1, 2] and invalidate or remove queries
        [0, 1, 2].forEach(group => {
            queryClient.removeQueries(['transactionList', group, dateFrom, dateTo], {
                exact: true,
            });
        });
    });

    useDidUpdate(() => {
        setTransactions(
            data
                ? data.pages.reduce<Transaction[]>(
                      (acc, page) => [...acc, ...page.transactions],
                      []
                  )
                : []
        );
    }, [data]);

    return {
        transactions,
        isLoading,
        fetchNextPage,
        hasNextPage,
    };
};

export default useTransactionQuery;
