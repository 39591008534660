import { Race as RaceObject, Start as StartObject } from 'features/TrackPage/server/raceCard';

import { VPOdds, VPPoolInfoItem, MarkingBetLeg, MarkInfo } from 'features/TrackPage/server/pool';
import { Label } from 'features/EventBoard/server/calendar';

export declare type Horse = StartObject & { vpPool: VPOdds };
export declare type VHorse = Horse & { VPool: MarkInfo };

export interface RaceCustomAttributes {
    index: number;
    horses: Horse[] | VHorse[];
    hostTrack?: Label;
    hostTrackId?: number;
}

// RaceObject.starts and VPPoolInfoItem.vpOdds must be omitted because their data will be merged into 'horses' property
export declare type RaceCardAttributes = Omit<RaceObject, 'starts'>;
export declare type RaceWithVPOdds = Pick<
    VPPoolInfoItem,
    'track' | 'trackId' | 'saleOpen' | 'poolClosed' | 'date'
>;
export declare type RaceWithVOdds = Omit<MarkingBetLeg, 'horseMarks'>;

export declare type Race = RaceCardAttributes & RaceWithVPOdds & RaceCustomAttributes;

export declare type VRace = Race & RaceWithVOdds;

export declare type AnyRace = Race | VRace;

export const isAnyRaceStarted = (races: VRace[]): boolean => races.some(r => !r.saleOpen);
