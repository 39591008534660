import styled, { css } from 'styled-components';
import { BACKGROUNDS, COLORS } from 'themes';
import { byPlatform } from 'utils/platforms';
import Button from 'ui/controls/Button';
import Wrapper, { FilledWrapper } from '../../../../../../ui/Wrapper';
import { boxShadowMixin } from '../../../../../../ui/Card';

export const smBreakpoint = '980px';

export const tableGrid = css`
    display: grid;
    grid-template-columns: 80px 1fr minmax(90px, 105px);
    @media (max-width: ${smBreakpoint}) {
        grid-template-columns: 80px 1fr;
    }
`;

export const Col = styled.div`
    box-sizing: border-box;
    flex-basis: 5%;
    align-self: center;
    display: ${({ flex }) => (flex ? 'flex' : 'auto')};
    justify-content: ${({ center }) => (center ? 'center' : 'auto')};
`;

export const VRaceOverviewContainer = styled(FilledWrapper).attrs({ white: true })`
    ${boxShadowMixin};
    position: relative;
    z-index: 15;
    border-radius: 10px;
    overflow: hidden;
    margin: 20px 0 40px;
    padding: 0;
    user-select: none;
`;

// Table row components

export const SelectAllColumn = styled(Col)`
    text-align: center;
    @media (max-width: ${smBreakpoint}) {
        display: none;
    }
`;

export const GameRow = styled.div`
    ${tableGrid};
    height: 45px;
    align-items: center;
    &:nth-child(even) {
        background: ${BACKGROUNDS.grey};
    }
`;

export const RaceNumber = styled(Col)`
    text-align: center;
    align-items: center;
    font-weight: 900;
    font-size: 16px;
    letter-spacing: 0;
    height: 100%;
    line-height: 45px;
    min-width: 35px;
`;

export const HorseButtonsContainer = styled(Col)`
    text-align: center;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    @media (max-width: ${smBreakpoint}) {
        gap: 4px;
    }
`;

// Header components

export const HeaderRow = styled.div`
    ${tableGrid};
    background: #333;
    color: ${COLORS.white};
    text-transform: uppercase;
    height: 18px;
    line-height: 18px;
    padding: 2px 0;
`;
export const GameHeaderCol = styled.div`
    font: normal ${byPlatform('14px/14px', '10px/10px')} 'Roboto Condensed';
    letter-spacing: 0;
    color: ${COLORS.white};
    text-transform: capitalize;
    font-weight: 900;
    align-self: center;
`;

export const GameHeaderColCenter = styled(GameHeaderCol)`
    text-align: center;
`;

export const SelectAllHeaderColumn = styled(GameHeaderCol)`
    text-align: center;
    @media (max-width: ${smBreakpoint}) {
        display: none;
    }
`;

export const SelectAllButton = styled(Button)`
    background: ${({ active }) => (active ? '#34be7a' : BACKGROUNDS.grey)};
    transition: background 300ms ease;
    font-weight: 900;
    font-size: 10px;
    border: ${({ active }) => (active ? `0.5px solid ${COLORS.flatGreen}` : '0.5px solid #c6ccdb')};
    ${({ active }) => active && `color: ${COLORS.white}`};
    &:hover {
        background: ${({ active }) => (active ? '#34be7a' : '#c6ccdb')};
    }
`;

// Sub header components

export const SubHeaderContainer = styled(Wrapper)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
    margin-left: 5px;
    margin-right: 5px;
`;

export const ClearAllButton = styled(Button)`
    border-radius: 5px;
    text-transform: capitalize;
    margin-right: 5px;
    width: 100px;
    font-size: 10px;
    font-weight: 900;
    cursor: pointer;
    line-height: 14px;
    border: ${props =>
        props.specialRed ? `0.5px solid ${COLORS.special.red}` : '0.5px solid #c6ccdb'};
`;

export const ReserveButton = styled(ClearAllButton)`
    transition: background 300ms ease;
    background: ${({ reserveMode }) => (reserveMode ? BACKGROUNDS.blue : BACKGROUNDS.lightGrey)};
    color: ${({ reserveMode }) => (reserveMode ? COLORS.white : COLORS.black)};
    &:hover {
        background: ${({ reserveMode }) => (reserveMode ? BACKGROUNDS.blue : '#c6ccdb')};
    }
`;

export const PdfDownloadSection = styled(Wrapper)`
    display: flex;
    padding: 0;
`;
