import Text from 'ui/Text';
import { ConfirmBtn } from 'features/UserArea/Withdraw/styled';
import Spinner from 'ui/Spinner';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import background from 'features/UserArea/Voucher/voucher_bg.png';
import Input from 'ui/controls/Input';
import { BACKGROUNDS } from 'themes';
import logo from 'images/dk/logo-white.png';
import Wrapper from 'ui/Wrapper';
import getTexts from 'utils/localization';

const t = getTexts().userArea.voucher;
const e = getTexts().validation;

const Background = styled.figure`
    margin: 0 auto;
    background: url(${background}) no-repeat;
    background-size: cover;
    border-radius: 30px;
    width: 69%;
    padding: 20px 50px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 1);
    display: flex;
    flex-direction: column;
    height: 220px;
    justify-content: space-evenly;
    opacity: ${({ pending }) => (pending ? 0.5 : 1)};
`;

const WhiteInput = styled(Input)`
    background: ${BACKGROUNDS.white};
    text-transform: uppercase;
`;

const Logo = styled.img.attrs({ src: logo })`
    width: 30%;
    align-self: center;
    height: 34px;
    display: block;
`;

const TextWrapper = styled(Wrapper)`
    width: 69%;
    margin: 0 auto;
`;

const DesktopVoucher = ({ pending, setSerial, setCode, confirm, serialValid, codeValid }) => (
    <Fragment>
        <TextWrapper>
            <Text black className="text-bold">
                {t.title}
            </Text>
            <Text>{t.description}</Text>
        </TextWrapper>
        <Background pending={pending}>
            <Logo />
            <WhiteInput
                placeholder={t.inputSerial}
                onChange={setSerial}
                validationError={serialValid ? '' : e.numeric}
            />
            <WhiteInput
                placeholder={t.inputCode}
                onChange={setCode}
                validationError={codeValid ? '' : e.required}
            />
        </Background>
        <ConfirmBtn
            accent
            size="x3"
            className="center uppercase block-center"
            onClick={confirm}
            disabled={!serialValid || !codeValid}
            style={{ marginTop: '50px' }}
        >
            {pending ? <Spinner black /> : t.button}
        </ConfirmBtn>
    </Fragment>
);

export default DesktopVoucher;
