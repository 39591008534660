// @ts-nocheck
import { useDispatch } from 'react-redux';

import useAuth from './useAuth';
import { useEffect } from 'react';
import usePrevious from './usePrevious';
import useModals from './useModals';
import { isUserLoggedIn } from '../actions/authActions';
import { LoginModalData } from 'ui/modals/types';
import { openLogin } from 'utils/navigation';

declare type UseAuthProtection = {
    authorized: boolean;
    defaultAuthHandler: () => void;
    checkSessionPending: boolean;
};

interface AuthProtectionInput {
    onLogin?(): void;
    redirectOnLogin?: string;
    pushOnLogin?: string;
    onClose?(): void;
    loginModalData?: LoginModalData;
    loginModalPriority?: number;
    disabled?: boolean;
}

//onSessionExpired = () => {} //@TODO Make onSessionExpired function argument
const useAuthProtection = ({
    onLogin = () => {}, // when user authorizes
    disabled = false,
    redirectOnLogin,
    pushOnLogin,
    onClose = () => {}, // when user jsut closes login modal
    loginModalData = {},
    loginModalPriority = 1,
}: AuthProtectionInput = {}): UseAuthProtection => {
    const dispatch = useDispatch();
    const auth = useAuth();
    const userId = auth.user ? auth.user.id : 0;
    const { isShown } = useModals();

    const isLoginModalShown = isShown('LOGIN');
    const wasLoginModalShown = usePrevious(isLoginModalShown);

    const authorized = !!(auth.user && userId !== 0);

    const prevUserState = usePrevious(auth.user);

    const defaultAuthHandler = () => {
        console.log({
            priority: loginModalPriority,
            redirectOnAuth: redirectOnLogin,
            pushOnAuth: pushOnLogin,
            ...loginModalData,
        });
        dispatch(
            openLogin({
                priority: loginModalPriority,
                redirectOnAuth: redirectOnLogin,
                pushOnAuth: pushOnLogin,
                ...loginModalData,
            })
        );
    };

    useEffect(() => {
        if (disabled) {
            return;
        }

        if (!auth.user) {
            return defaultAuthHandler();
        }

        dispatch(
            isUserLoggedIn({
                preventSideEffects: true,
            })
        ).then(user => {
            if (!user) {
                defaultAuthHandler();
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (disabled) {
            return;
        }
        if (auth.user && !prevUserState) {
            // logged in
            onLogin();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, auth.pending]);

    useEffect(() => {
        if (wasLoginModalShown && !isLoginModalShown && !auth.user) {
            onClose();
        }
    }, [isLoginModalShown]);

    return {
        authorized,
        defaultAuthHandler,
        checkSessionPending: auth.pending,
    };
};

export default useAuthProtection;
