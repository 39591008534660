import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDerbyLynBySmartLyn } from 'features/BetSlip/components/SmartLyn';
import NotificationConductor from 'common/conductors/NotificationConductor';
import { APP_MODE_TERMINAL } from 'configs/main';
import { getSmartLynBet } from 'common/selectors/smartLynSelector';
import get from 'lodash/get';
import getTexts from 'utils/localization';
import useSelections from 'features/BetSlip/state/useSelections';
import { setSmartLynMode } from 'features/BetSlip/state/actions';
import usePrevious from 'common/hooks/usePrevious';
import { getTrackCode } from 'common/selectors/trackSelector';

const t = getTexts();

const withSmartLynManager = (WrappedComponent) => {
    return (props) => {
        // selectors
        const smartLynBet = useSelector(getSmartLynBet);
        const shopConfig = useSelector((state) => state.ShopConfig);
        const { date, track, product: selectedProduct, betSlip } = useSelections();

        const trackCode = useSelector(getTrackCode);

        const prevDate = usePrevious(date);
        const prevTrackId = usePrevious(track.id);
        const prevProduct = usePrevious(selectedProduct.id);

        useEffect(() => {
            if (prevDate && prevTrackId && prevProduct) {
                dispatch(setSmartLynMode(false));
            }
        }, [date, track.id, track.code, selectedProduct.id]);

        const dispatch = useDispatch();

        const transferSmartLynBet = () => {
            const { onSmartLynPoolRowFetched } = props;

            getDerbyLynBySmartLyn(smartLynBet).then((quickPick) => {
                onSmartLynPoolRowFetched &&
                    onSmartLynPoolRowFetched({
                        ...quickPick,
                        date: quickPick.date.substring(0, 10),
                    });
            });
        };

        const activateSmartLyn = () => {
            const playAndPayWorkflow = get(shopConfig, 'data.playAndPayAllowed', false);
            let betslipHasSelections = false;

            const selections = betSlip.getAllRacesSelections(date, trackCode, selectedProduct.id);

            if (selections && Object.keys(selections || {}).length) {
                betslipHasSelections = Object.entries(selections).some(
                    (selection) => Object.keys(selection[1]).length > 0
                );
            }

            if (betslipHasSelections) {
                if (!APP_MODE_TERMINAL || playAndPayWorkflow) {
                    dispatch(setSmartLynMode(true));
                    transferSmartLynBet();
                } else {
                    NotificationConductor.error(t.COUPONFIRST);
                }
            } else {
                dispatch(setSmartLynMode(false));
                NotificationConductor.warning(t.smartLyn.atLeast1Horse);
            }
        };

        return (
            <WrappedComponent
                {...props}
                activateSmartLyn={activateSmartLyn}
                smartLynBet={smartLynBet}
            />
        );
    };
};

export default withSmartLynManager;
