import React from 'react';
import {
    PreloaderSmallerRow,
    PreloaderTitleRow,
    PreloaderButtonRow,
    QuickPickWrapper,
    TitleWrapper,
    ButtonWrapper,
} from './styled';
import Text from 'ui/Text';
import Button from 'ui/controls/Button';
import Wrapper from 'ui/Wrapper';
import moment from 'moment';
import { connect } from 'react-redux';
import { showModal } from 'common/actions/uiActions';
import { byPlatform, IS_TERMINAL } from 'utils/platforms';
import NotificationConductor from 'common/conductors/NotificationConductor';
import { resetDerbyLynBet } from 'features/BetSlip/state/actions';
import UserSession from 'common/adapters/UserSession';
import get from 'lodash/get';
import { terminalLogin } from 'common/actions/authActions';

import getTexts from 'utils/localization';
import { PRODUCT_COLORS, PRODUCT_IDS } from '../../../configs/products';
import { openLogin } from 'utils/navigation';
const t = getTexts();

export const Preloader = ({ color, singleItem, grow }) => (
    <QuickPickWrapper
        bg={color}
        height={'auto'}
        paddingBottom={byPlatform(16, singleItem ? 0 : 16)}
        singleItem={byPlatform(false, singleItem)}
        grow={singleItem || grow}
    >
        <TitleWrapper
            flex
            padding={byPlatform('16px', '20px')}
            singleItem={byPlatform(false, singleItem)}
        >
            <Text white size={'x2'} bold padding={'2px 0'} atg>
                <PreloaderTitleRow />
            </Text>

            <Text white block weight={'600'} size="x07" padding={'2px 0'} className={'capitalize'}>
                <PreloaderSmallerRow />
            </Text>
        </TitleWrapper>

        <Wrapper padding="0px">
            <Button
                accent
                block
                size="x1"
                textsize="14px"
                padding="10px"
                bold
                width={byPlatform('calc(100% - 32px)', singleItem ? '200px' : 'calc(100% - 40px)')}
                margin={byPlatform('0 16px', '0 20px')}
                height="43px"
            >
                <PreloaderButtonRow />
            </Button>
        </Wrapper>
    </QuickPickWrapper>
);

class QuickPickCard extends React.Component {
    componentDidMount() {
        const { item, derbyLynBet } = this.props;

        if (
            derbyLynBet &&
            item.product.id === derbyLynBet.product.id &&
            item.track.code === derbyLynBet.trackCode &&
            moment(item.date).format('YYYY-MM-DD') === derbyLynBet.date
        ) {
            this.props.showQuickPickAmountModal(this.props);
            this.props.resetDerbyLynBet();
        }
    }

    onClickProced = () => {
        const { shopConfig, auth, showQuickPickAmountModal, showLoginModal } = this.props;

        const playAndPayAllowed = get(shopConfig, 'data.playAndPayAllowed', false),
            playAndPayWorkflowAllowed =
                playAndPayAllowed &&
                IS_TERMINAL &&
                UserSession.isLowAmount(auth) &&
                !UserSession.isManagerEntrance();

        if (auth.user || playAndPayWorkflowAllowed) {
            showQuickPickAmountModal(this.props);
        } else {
            if (IS_TERMINAL) {
                let message = t.Terminal.notifications.startSession;
                /** restoring lost user on ManagerPC  */
                if (
                    // UserSession.isManagerEntrance() &&
                    UserSession.getSessionId()
                ) {
                    console.log('TS. Restoring from BetSlip');

                    message = t.Terminal.notifications.startManagerSession;
                    this.props.terminalLogin(UserSession);
                }

                NotificationConductor.info(message);
            } else {
                showLoginModal();
            }
        }
    };

    getCardView = (product, track, date, singleItem = false, grow = false) => {
        const formattedDate = moment(date).format('dddd DD[.] MMMM [-] HH:mm');
        return (
            <QuickPickWrapper
                bg={PRODUCT_COLORS[product.id] || '#46B16F'}
                height={'auto'}
                paddingBottom={byPlatform(16, singleItem ? 0 : 16)}
                singleItem={singleItem}
                grow={singleItem || grow}
            >
                <TitleWrapper flex padding={byPlatform('16px', '20px')} singleItem={singleItem}>
                    <Text white size={byPlatform('x1', 'x2')} bold padding={'2px 0'} atg>
                        {PRODUCT_IDS[product.id]} {track?.name}
                    </Text>

                    <Text
                        white
                        block
                        weight={'600'}
                        size="x07"
                        padding={'2px 0'}
                        className={'capitalize'}
                    >
                        {formattedDate}
                    </Text>
                </TitleWrapper>

                <ButtonWrapper padding="0px" singleItem={byPlatform(singleItem, false)}>
                    <Button
                        accent
                        block
                        size="x1"
                        textsize="14px"
                        padding="10px"
                        bold
                        width={
                            singleItem
                                ? byPlatform('100%', '200px')
                                : byPlatform('calc(100% - 32px)', 'calc(100% - 40px)')
                        }
                        margin={byPlatform(singleItem ? '0 16px 0 0' : '0 16px', '0 20px')}
                        height="43px"
                        onClick={this.onClickProced}
                    >
                        {t.DerbyFlash.bayFlashGame}
                    </Button>
                </ButtonWrapper>
            </QuickPickWrapper>
        );
    };

    render() {
        const { product, track, date } = this.props.item;
        return this.getCardView(product, track, date, this.props.singleItem, this.props.grow);
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    serverTime: state.AISDataProvider.serverTime,
    derbyLynBet: state.BetSlip.derbyLynBet,
    shopConfig: state.ShopConfig,
});

const mapDispatchToProps = dispatch => {
    return {
        showLoginModal: () => {
            dispatch(openLogin());
        },
        showQuickPickAmountModal: data => {
            dispatch(showModal('QUICK_PICK_AMOUNT', -896, data));
        },
        resetDerbyLynBet: () => {
            dispatch(resetDerbyLynBet());
        },
        terminalLogin: sessionId => {
            dispatch(terminalLogin(sessionId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(QuickPickCard);
