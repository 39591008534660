import PunterREST from 'common/api/PunterREST';
import { sha256 } from 'utils/crypto';
import isObject from 'lodash/isObject';
import persistentStorage from 'common/storage';

const punter = PunterREST();

export const trackEvent = (event, trackData = false) => {
    const eventName = isObject(event) ? event.event : event;

    window.dataLayer &&
        window.dataLayer.push({
            event: eventName,
            ...(isObject(event) && event),
        });

    trackData && trackUserData();
};

export const trackUserData = async () => {
    const user = await punter.getUser({ silent: true }).then((res) => {
        if (res?.success) {
            return res.data;
        }
        return false;
    });

    if (!user) {
        return;
    }

    const userData = {
        fn: await sha256(user.firstName),
        ln: await sha256(user.lastName),
        em: await sha256(user.email),
        Pn: await sha256(user.phoneNr),
    };

    persistentStorage.saveLS(userData);
};

export const withEventTracking =
    (fn, event) =>
    (...args) => {
        trackEvent(event);
        return fn(...args);
    };
