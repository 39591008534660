import { css } from 'styled-components';
import { ProductId } from 'features/EventBoard/server/calendar';

export interface HorseInfoRowElementProps {
    isVProduct: boolean;
    productId: ProductId;
    gallop?: boolean;
    hideNrCol?: boolean;
}

export const nrColVisibilityLogic = css<HorseInfoRowElementProps>`
    // hide pick buttons column
    & > div:nth-child(1) {
        display: ${props => (props.hideNrCol ? 'none' : '')};
    }
    // add some left indent because nr col actually doesn't have any padding but has some spacing due to its width and centering
    padding-left: ${props => (props.hideNrCol ? '15px' : '')};
`;
