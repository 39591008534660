import styled from 'styled-components';
import { BACKGROUNDS, COLORS } from 'themes';
import { byPlatform } from 'utils/platforms';

import PageContainerDef from 'ui/PageContainer';
import DefaultScrollBar from 'ui/ScrollBar';
import { forMobile } from 'utils/platforms';
import { NavigationButton } from 'ui/RaceNavigation';

const grid = {
    labelWidth: '90px',
    rowGap: '20px',
};

export const pagePadding = byPlatform('20px', '30px');

const columns = byPlatform(`1fr`, `${grid.labelWidth} 1fr`);

export const PageContainer = styled(PageContainerDef).attrs({
    padding: pagePadding,
})`
    overflow: visible;
`;

export const Navigation = styled.div`
    ${forMobile(`margin: 0 -${pagePadding};`)} // 
    display: grid;
    grid-template-columns: ${columns};
    grid-row-gap: ${grid.rowGap};
`;

export const Label = styled.span`
    align-self: center;
    color: ${COLORS.primaryText};
    font-weight: 900;
    font-size: 14px;
`;

export const ScrollBar = styled(DefaultScrollBar)`
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding: 0 ${byPlatform(pagePadding, 0)};
    &::-webkit-scrollbar {
        display: none; // Safari and Chrome
    }
`;

export const RaceButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    padding: 0 ${byPlatform(pagePadding, 0)};
`;

// Track row ---->

export const jackpotLabelTopOffset = '8px';

export const TrackLabel = styled(Label)`
    margin-top: ${jackpotLabelTopOffset};
`;

export const TracksScrollBar = styled(ScrollBar).attrs({
    orientation: 'horizontal',
})`
    padding-top: ${jackpotLabelTopOffset}; /* top indent is used to avoid partially hidden jackpot label */
    margin-top: -${jackpotLabelTopOffset}; /* negative indent is used to symmetrically align to the top */
`;

export const TrackRowSlickContainer = styled.div`
    overflow: hidden;

    & .slick-list {
        padding-top: ${byPlatform(0, jackpotLabelTopOffset)};
    }
`;

export const TrackNavigationButton = styled(NavigationButton)<{
    numberOfTracks: number;
    index: number;
}>`
    color: ${props =>
        props.active ? BACKGROUNDS.white : props.accent ? '#8BF7C3' : BACKGROUNDS.black};
    background: ${props =>
        props.active || props.accent ? BACKGROUNDS.primaryFlat : BACKGROUNDS.white};
    position: relative;

    width: ${props => (props.useSlider ? '100%' : 'auto')} !important;
    margin: ${props => (props.useSlider ? 0 : byPlatform('0 5px', '0 10px 0 0'))} !important;

    &:first-child {
        margin-left: 5px !important;
    }

    padding: ${byPlatform('10 14px', '0 13px', '0 18px')};
    margin-right: ${props => forMobile(props.index + 1 === props.numberOfTracks ? '20px' : '5px')};
    padding-left: ${byPlatform('14px', '13px', '18px')};
    padding-right: ${byPlatform('14px', '13px', '18px')};
    min-width: auto;
`;

// <---- Track row End

export const Row = styled.div`
    padding: ${grid.rowGap} 0;
`;

export const FixedContainer = styled.div`
    padding-right: 5px;
    width: auto;
    position: fixed;
    display: flex;
    left: 50%;
    margin: 0;
    bottom: 65px;
    justify-content: center;
    z-index: 1;
`;

export const BetslipNotifierContainer = styled.div`
    position: relative;
    left: -50%;
`;
