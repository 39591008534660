import styled, { css } from 'styled-components';
import Wrapper from 'ui/Wrapper.tsx';
import { byPlatform, omitMobile } from 'utils/platforms';
import { terminal } from 'configs/layout';
import { ScrollBar } from 'ui/RaceNavigation';

export const ForecastContainer = styled(Wrapper).attrs({
    padding: '0px',
    margin: '20px',
    rounded: '10',
    width: byPlatform(null, null, 'calc(100% - 440px)'),
})``;

export const RaceLineContainer = styled(Wrapper).attrs({
    position: 'relative',
    display: omitMobile('grid'),
    padding: byPlatform('10px 0', '30px 25px 10px 25px'),
    margin: byPlatform('0 auto', '0'),
})`
    width: ${byPlatform(
        'calc(100% - 40px)',
        'auto',
        `calc(${terminal.homepage.wrapperWidthWithMargin} - 10px)`
    )};
    position: relative;
    ${({ fixed }) =>
        fixed
            ? css`
                  position: fixed;
                  top: 140px;
                  padding-left: 20px;
                  width: 100%;
                  z-index: 2;
                  background-color: #f0f2f8;
                  section:first-child {
                      width: calc(100% - 40px);
                  }
              `
            : css`
                  position: relative;
                  top: 0;
                  padding-left: 5px;
                  background-color: transparent;
              `}
`;

export const RaceLineScrollbar = styled(ScrollBar)`
    display: flex;
    align-items: center;
`;

export const RacesRowWrapper = styled.div`
    display: flex;
    gap: 28px;
    ${() => byPlatform('width: 100%', null, null)};
    overflow: auto;
    &::-webkit-scrollbar {
        display: none; // Safari and Chrome
    }
`;
export const RaceLineWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin: ${byPlatform('0', '20px')};
`;
