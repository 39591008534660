import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get, has, cloneDeep } from 'lodash';
import DKRouter from '../routers/dk-router';
import TerminalTopHeader from './sections/Terminal/TerminalTopHeader/TerminalTopHeader';
import FullWidthTopBar from './sections/Terminal/FullWidthTopBar/index';
import DesktopModals from './sections/DesktopModals';
import { terminalLogin, cleanErrorMessage } from 'common/actions/authActions';
import NotificationConductor from 'common/conductors/NotificationConductor';
import ScanModal from 'features/ScanLogicTerminal';
import UserSessionAdapter from 'common/adapters/UserSession';
import { getTerminalShopConfig } from 'configs/ShopConfig/actions';
import {
    showCheckinModal,
    hideScanModal,
    checkin,
    checkinByCoupon,
    applyVoucher,
    checkCouponStatus,
    checkSystemCouponStatus,
    cleanTerminalErrorMessage,
    getUnpaidSaleInfo,
    getMobileBet,
    resetMobileBet,
} from 'features/ScanLogicTerminal/actions';
import {
    setMobileBetInit,
    setMobileBetData,
    resetMobileBetData,
    mobileBetWasSetToState,
} from 'features/MobileBet/state/actions';
import {
    resetBet,
    setPrevAmount,
    mergeBetslip,
    setSmartLynMode,
    setStrictMode,
} from 'features/BetSlip/state/actions';
import { setDate } from 'ui/DatePicker/actions';
import {
    fetchRaceDayCalendar,
    setProduct,
    setProductShortcut,
    resetProduct,
    resetRaceDay,
    resetRace,
    setRaceDay,
    fetchRacingCardWithPoolWithoutDispatch,
    fetchServerTime,
    setRace,
    setDefaultRaceDay,
    setInitializing,
} from 'features/AISDataProvider/actions';
import { PRODUCT_IDS } from 'configs/products';
import Scanner from 'features/ScanLogicTerminal/Scanner';
import ScreenKeyboard from 'ui/ScreenKeyboard';
import { TerminalWrapper, HeaderWrapper, ContentWrapper, RouterWrapper } from './styled';
import { POOL_OBJECT_KEYS } from 'configs/products';
import getTexts from 'utils/localization';
import Product from 'common/DataObjects/Product';

class Terminal extends React.Component {
    constructor() {
        super();

        this.state = {
            hashCode: '',
            activeNavItem: this.getActiveNavItem(),
        };

        this.UserSessionAdapter = UserSessionAdapter;
        this.scanner = Scanner;

        window.onkeypress = this.onScanningHandler;
    }

    getActiveNavItem = () => {
        const pathname = window.location.pathname;

        return pathname.indexOf('derby-lyn') > -1 ? '/derby-lyn' : '/';
    };

    componentDidMount() {
        if (window.Intercom) {
            window.Intercom('shutdown');
        }

        this.initializeSession();

        this.props.getTerminalShopConfig();
    }

    initializeSession = () => {
        const sessionId = this.UserSessionAdapter.getSessionId();
        if (!sessionId) {
            this.UserSessionAdapter.extractSessionData();
        }

        if (!this.UserSessionAdapter.error) {
            this.props.terminalLogin(this.UserSessionAdapter);
        }
    };

    componentDidUpdate() {
        const { auth, cleanErrorMessage, cleanTerminalErrorMessage, scanModal, mobileBet } =
            this.props;

        let authError = auth.error;
        if (authError) {
            if (authError === 'GettingSessionNetworkError') {
                /** retry session initializatoin after failed attempt */
                setTimeout(() => {
                    this.initializeSession();
                }, 3000);

                authError = 'Netværksforbindelsen fejler. Gentilslutning..'; //'Network Error. Reconnecting..';
            }

            NotificationConductor.info(authError);
            cleanErrorMessage();
        }

        const terminalError = typeof scanModal.error === 'string';
        if (terminalError) {
            NotificationConductor.info(scanModal.error);
            cleanTerminalErrorMessage();
        }

        const mobileBetData = get(mobileBet, 'data', {});
        const fetchedBetData = get(mobileBetData, 'fetchedBetData', null);
        /** */
        if (!mobileBet.wasSetToState && fetchedBetData) {
            this.setMobileBetToBetslip(mobileBetData);
            this.props.mobileBetWasSetToState();
        }

        /** */
        if (fetchedBetData && mobileBet.initialization) {
            const trackCode = get(fetchedBetData, 'trackCode', null);
            const product = get(mobileBetData, 'product', {});
            const date = get(mobileBetData, 'date', {});
            const trackIsReady = get(mobileBet, 'trackIsReady', false);
            const productIsReady = get(mobileBet, 'productIsReady', false);
            const trackWasChanged = this.state.trackCode !== trackCode || this.state.date !== date;
            const productWasChanged = this.state.productId !== product.id;
            const raceNumber = get(mobileBetData, 'raceNumber', null);
            const params = { id: product.id, raceNumber };

            if (trackWasChanged && productWasChanged) {
                if (trackIsReady && productIsReady) {
                    this.finishMobileBetInit(params);
                }
            } else if (trackWasChanged) {
                if (trackIsReady) {
                    this.finishMobileBetInit(params);
                }
            } else if (productWasChanged) {
                if (productIsReady) {
                    this.finishMobileBetInit(params);
                }
            } else {
                /** commented, because of smartLyn mobileBet, lockedLegs  */
                // this.finishMobileBetInit(params);
            }
        }
    }

    finishMobileBetInit = (params) => {
        console.log('finished init');
        const productId = params.id;
        const raceNumber = params.raceNumber;
        const errorMessage = params.errorMessage;

        if (productId && raceNumber) {
            const product = new Product().setAll(productId);

            if (!product.isVProduct()) {
                console.log('set race', raceNumber);
                this.props.setRace(raceNumber, raceNumber - 1);
            }
        }

        this.props.setMobileBetInit(false);
        this.props.hideScanModal(errorMessage);
    };

    onScanningHandler = async (e) => {
        const {
            scanModal,
            showCheckinModal,
            applyVoucher,
            checkin,
            checkCouponStatus,
            checkSystemCouponStatus,
            getUnpaidSaleInfo,
        } = this.props;

        const char = this.scanner.getScannedChar(e);
        /* hide char handling inside scanner.updateHashCode */
        let hashCode = this.state.hashCode + char;
        const scanningIsDone = this.scanner.scanningIsDone(e, this.state);
        const moneyTransaction = scanModal.checkInPending;

        if (this.UserSessionAdapter.isManagerEntrance() && scanningIsDone) {
            this.redirectToManager(hashCode);

            return;
        }

        if (this.scanner.isVoucherScanned(hashCode)) {
            if (!scanModal.modalVisible) {
                showCheckinModal();
            }

            if (scanningIsDone) {
                if (!moneyTransaction) {
                    const handler = this.UserSessionAdapter.getSessionId() ? applyVoucher : checkin;

                    handler.call(this, this.UserSessionAdapter, hashCode);
                }
            }
        } else if (this.scanner.isCouponScanned(hashCode)) {
            if (!scanModal.modalVisible) {
                showCheckinModal();
            }

            if (scanningIsDone && !moneyTransaction) {
                checkCouponStatus(hashCode);
            }
        } else if (this.scanner.isPlayAndPayCouponScanned(hashCode)) {
            if (!scanModal.modalVisible) {
                showCheckinModal();
            }

            if (scanningIsDone) {
                getUnpaidSaleInfo(hashCode);
            }
        } else if (this.scanner.isMobileBetHash(hashCode)) {
            if (scanningIsDone && !moneyTransaction && this.isAISDataReady()) {
                /**
                 * restrict scanning,
                 * MB scanning has sense only from 'home' page
                 * which is '/' or '/spil/...'
                 */
                const currentLocation = window.location.pathname;
                const isHomePage = currentLocation === '/' || currentLocation.indexOf('/spil') > -1;

                if (isHomePage) {
                    const mobileBetId = hashCode;
                    /**
                     * set timeout for case when switchin between tracks
                     * (TracksBySelectedDay.onSelectTrackhandler)
                     * component inits setRaceDay and setProduct
                     */
                    setTimeout(() => {
                        this.props.resetMobileBetData();
                        this.startMobileBetWorkflow(mobileBetId);
                        console.log('scan start ', mobileBetId);
                    }, 500);
                } else {
                    console.log('Scan is not allowed');
                }
            }
        } else if (this.scanner.isSystemCouponScanned(hashCode)) {
            if (!scanModal.modalVisible) {
                showCheckinModal();
            }

            if (scanningIsDone && !moneyTransaction) {
                checkSystemCouponStatus(hashCode);
            }
        } else {
            hashCode = '';
        }

        if (scanningIsDone || this.state.hashCode === this.placeholder) {
            hashCode = '';
        }

        this.setState({
            hashCode,
        });
    };

    isAISDataReady = () => {
        return (
            !this.props.mobileBet.initialization &&
            !this.props.aisDataProvider.racingCardPending &&
            !this.props.aisDataProvider.coupleRacingCardPending &&
            !this.props.aisDataProvider.raceDayPending &&
            !this.props.aisDataProvider.trackPoolPending
        );
    };

    startMobileBetWorkflow = async (hashCode) => {
        const { showCheckinModal, setMobileBetInit, shopConfig, setMobileBetData } = this.props;

        showCheckinModal();
        setMobileBetInit(true);

        const racingCard = get(this.props, 'aisDataProvider.racingCardData');
        const selectedProductId = get(this.props, 'aisDataProvider.selectedProduct.id');

        this.setState(
            {
                trackCode: get(racingCard, 'trackCode'),
                date: get(racingCard, 'date'),
                productId: selectedProductId,
            },
            async () => {
                const mobileBet = await getMobileBet(hashCode, shopConfig.data.shopId);

                if (mobileBet.errorMessage) {
                    this.finishMobileBetInit({
                        errorMessage: mobileBet.errorMessage,
                    });
                } else {
                    setMobileBetData(mobileBet.data);
                }
            }
        );
    };

    setMobileBetToBetslip = (data) => {
        const { aisDataProvider, fetchRaceDayCalendar } = this.props;
        const mobileBet = cloneDeep(data);
        const mobileBetDataFetched = has(mobileBet, 'fetchedBetData');
        const t = getTexts();

        if (!mobileBetDataFetched) {
            NotificationConductor.warning('MB is not fetched', null, 4000);
            console.log('mobileBetData is empty');
            return;
        }

        const fetchedBetData = get(mobileBet, 'fetchedBetData', {});
        const date = get(mobileBet, 'date', '');
        const raceNumber = get(mobileBet, 'raceNumber', null);
        const trackCode = get(fetchedBetData, 'trackCode', null);
        const trackId = get(fetchedBetData, 'trackId', null);
        const isMultitrack = get(fetchedBetData, 'isMultitrackProduct', false);
        const racingCard = get(aisDataProvider, 'racingCardData', {});
        const savedProduct = get(mobileBet, 'product', {});
        const selectedProductId = get(aisDataProvider, 'selectedProduct.id', {});
        const product = new Product().setAll(savedProduct.id, savedProduct.name);
        const {
            scratchedHorse,
            dateIsNotAvailable,
            raceIsAlreadyStarted,
            // raceListIsEmpty,
            raceIsNotFound,
        } = t.Terminal.mobileBet.errors;

        let picks = get(mobileBet, 'betslipData.picks', []);
        let reserves = get(mobileBet, 'betslipData.reserves', []);

        //move to mobileBets actions
        const checkOnScrachedHorses = (
            selections,
            scratchedHorses,
            selectedTrackCode = trackCode
        ) => {
            const selectedPicks = get(selections, `${date}.${selectedTrackCode}.${product.id}`, {});
            const raceNumbers = Object.keys(selectedPicks);
            if (raceNumber.length === 0) return selections;

            try {
                raceNumbers.forEach((raceNumber) => {
                    const scratchedStart = scratchedHorses.find(
                        (item) => item.raceNumber.toString() === raceNumber
                    );

                    if (!scratchedStart) return;

                    const startIsScratched = has(
                        selectedPicks,
                        `${raceNumber}.${scratchedStart.startNumber}`
                    );

                    if (scratchedStart && startIsScratched) {
                        const tenSeconds = 10000;

                        NotificationConductor.warning(
                            `${scratchedStart.startNumber}  ${scratchedHorse}`,
                            null,
                            tenSeconds
                        );

                        delete selections[date][selectedTrackCode][product.id][raceNumber][
                            scratchedStart.startNumber
                        ];
                    }
                });
            } catch (e) {
                console.log('checkOnScrachedHorses', e);
                return [];
            }

            return selections;
        };

        let stateWillBeChanged = false;
        let raceDay = null;

        fetchRaceDayCalendar()
            .then((raceDaysData) => {
                raceDay = raceDaysData.find((raceDate) => {
                    return raceDate.date === date && trackCode === raceDate.track.code;
                });

                if (!raceDay) {
                    throw dateIsNotAvailable;
                }

                const betInfoData = get(aisDataProvider, 'betInfoData', {});
                const trackPool = get(aisDataProvider, 'trackPool', {});
                const stateTheSame =
                    racingCard.trackCode === trackCode &&
                    racingCard.date === date &&
                    selectedProductId === product.id;

                if (stateTheSame) {
                    return new Promise((resolve) => {
                        resolve([racingCard, trackPool, betInfoData, []]);
                    });
                } else {
                    return fetchRacingCardWithPoolWithoutDispatch({
                        date,
                        trackId,
                        productId: product.id,
                        isMultitrack,
                        statePart: {
                            AISDataProvider: {
                                raceDayData: raceDaysData,
                                tracks: aisDataProvider.tracks,
                            },
                        },
                    });
                }
            })
            .then((fetchedRacingCardData) => {
                if (!fetchedRacingCardData || fetchedRacingCardData.errors) {
                    throw 'No RacingCardFetched';
                }

                const [, trackPool, betInfoData, secondMultitrack] = fetchedRacingCardData;

                const pathWithKey = has(trackPool, product.id + '.' + POOL_OBJECT_KEYS[product.id]);
                const path = pathWithKey
                    ? product.id + '.' + POOL_OBJECT_KEYS[product.id]
                    : product.id;

                let pool = get(trackPool, path, []);

                if (Array.isArray(pool)) {
                    pool = pool.find((race) => (race.raceNr || race.raceNumber) === raceNumber);
                }

                if (!pool) {
                    throw raceIsNotFound;
                } else if (!pool.saleOpen && pool.poolClosed) {
                    throw raceIsAlreadyStarted;
                }

                const scratchedHorses = get(betInfoData, 'scratched.scratched', []);

                if (isMultitrack && secondMultitrack.code) {
                    picks = checkOnScrachedHorses(picks, scratchedHorses, secondMultitrack.code);
                }

                picks = checkOnScrachedHorses(picks, scratchedHorses);
                reserves = checkOnScrachedHorses(reserves, scratchedHorses);
            })
            .then(() => {
                const prices = get(fetchedBetData, 'prices', {});
                const selectedProduct = get(aisDataProvider, 'selectedProduct', {});
                const selectedRaceDay = get(aisDataProvider, 'selectedRaceDay', {});

                this.props.setStrictMode(false);
                this.props.resetBet();
                this.props.setPrevAmount(prices.amount);

                const dateChanged = !selectedRaceDay || selectedRaceDay.date !== raceDay.date;
                const trackChanged =
                    !selectedRaceDay || selectedRaceDay.trackId !== raceDay.trackId;
                const productChanged = !selectedProduct || selectedProduct.id !== product.id;

                stateWillBeChanged = dateChanged || productChanged || trackChanged;

                /**
                 * we should update all fields even date/track/ is the same
                 * because wrong RaceCard will be fetched
                 */
                if (stateWillBeChanged) {
                    this.props.setDate(date);
                    this.props.setProduct(product);
                    this.props.setRaceDay(raceDay);
                }

                const smartLynMode = get(fetchedBetData, 'smartLynMode', false);

                if (smartLynMode) {
                    this.props.setSmartLynMode(smartLynMode);
                }

                const productWithStrictMode = [
                    PRODUCT_IDS.GS75,
                    PRODUCT_IDS.V64,
                    PRODUCT_IDS.V65,
                ].includes(product.id);

                if (productWithStrictMode) {
                    const strictMode = get(fetchedBetData, 'strictMode', false);

                    if (strictMode) {
                        this.props.setStrictMode(strictMode);
                    }
                }

                this.props.mergeBetslip(picks, reserves);
            })
            .catch((error) => {
                this.finishMobileBetInit({ errorMessage: error });
                throw error;
            })
            .finally(() => {
                if (!stateWillBeChanged) {
                    console.log('Finished in finally');
                    this.finishMobileBetInit({ id: product.id, raceNumber });
                }
            });
    };

    modalPlaceholderClickHandler = () => {
        this.props.hideScanModal();
    };

    redirectToManager = (hash) => {
        /* add animation for transition */
        window.location = 'http://operator.cafe:8080?hash=' + hash;
    };

    /** TODO move to the menu */
    redirectToBet25 = (path) => {
        const moneyTransaction = this.props.scanModal.checkInPending;
        if (moneyTransaction) return;

        const localPages = ['/hjem', '/sadan-spiller-du', '/derby-lyn', '/resultat', '/'];

        const isDerbyPage = localPages.includes(path);
        if (isDerbyPage) {
            this.setState(
                {
                    activeNavItem: path,
                },
                () => {
                    this.props.history.push(path);
                }
            );
        } else {
            const sessionId = window.localStorage.getItem('sessionId');

            this.UserSessionAdapter.eraseSession();

            let location = this.UserSessionAdapter.isManagerEntrance()
                ? 'http://terminal.cafe:8080'
                : 'http://terminal.bet25.dk:8080';

            if (path) {
                location += `${path}`;
            }

            window.location = location + (sessionId ? '?session_id=' + sessionId : '');
        }
    };

    render() {
        const { modalVisible, type, data } = this.props.scanModal;
        const terminalId = this.UserSessionAdapter.getTerminalId();

        return (
            <TerminalWrapper>
                <HeaderWrapper>
                    <TerminalTopHeader
                        isManagerEntrance={this.UserSessionAdapter.isManagerEntrance()}
                        scanButtonHandler={this.props.showCheckinModal}
                        redirectToBet25={this.redirectToBet25}
                        activeNavItem={this.state.activeNavItem}
                        terminalId={terminalId}
                        checkInPending={this.props.scanModal.checkInPending}
                    />
                    <FullWidthTopBar
                        terminalId={terminalId}
                        redirectToBet25={this.redirectToBet25}
                    />
                </HeaderWrapper>

                <ContentWrapper style={{ height: '100vh' }} modalVisible={modalVisible}>
                    <RouterWrapper>
                        <DKRouter />
                    </RouterWrapper>
                </ContentWrapper>

                <ScanModal
                    onPlaceholderClick={this.modalPlaceholderClickHandler}
                    visibility={modalVisible}
                    type={type}
                    data={data}
                    userSessionAdapter={this.UserSessionAdapter}
                />
                <DesktopModals />
                {this.props.screenKeyboard.visible ? <ScreenKeyboard /> : null}
            </TerminalWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        state: state,
        auth: state.auth,
        scanModal: state.ScanModal,
        screenKeyboard: state.ScreenKeyboard,
        shopConfig: state.ShopConfig,
        mobileBet: state.MobileBet,
        aisDataProvider: state.AISDataProvider,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMobileBetInit: (status) => {
            dispatch(setMobileBetInit(status));
        },
        resetMobileBetData: () => {
            dispatch(resetMobileBetData());
        },
        mobileBetWasSetToState: () => {
            dispatch(mobileBetWasSetToState());
        },
        setMobileBetData: (data) => {
            dispatch(setMobileBetData(data));
        },
        resetMobileBet: () => {
            dispatch(resetMobileBet());
        },
        getTerminalShopConfig: () => {
            dispatch(getTerminalShopConfig());
        },
        showCheckinModal: () => {
            dispatch(showCheckinModal());
        },
        hideScanModal: (error) => {
            dispatch(hideScanModal(error));
        },
        terminalLogin: (sessionId) => {
            dispatch(terminalLogin(sessionId));
        },
        cleanErrorMessage: () => {
            dispatch(cleanErrorMessage());
        },
        checkin: (UserSessionAdapter, hashCode) => {
            dispatch(checkin(UserSessionAdapter, hashCode));
        },
        checkinByCoupon: (hashCode) => {
            dispatch(checkinByCoupon(hashCode));
        },
        applyVoucher: (UserSessionAdapter, hashCode) => {
            dispatch(applyVoucher(UserSessionAdapter, hashCode));
        },
        checkCouponStatus: (couponHash) => {
            dispatch(checkCouponStatus(couponHash));
        },
        checkSystemCouponStatus: (couponHash) => {
            dispatch(checkSystemCouponStatus(couponHash));
        },
        cleanTerminalErrorMessage: () => {
            dispatch(cleanTerminalErrorMessage());
        },
        getUnpaidSaleInfo: (hash) => {
            dispatch(getUnpaidSaleInfo(hash));
        },
        setInitializing: (status) => dispatch(setInitializing(status)),
        fetchRaceDayCalendar: (withoutPending) => {
            return dispatch(fetchRaceDayCalendar(withoutPending));
        },
        setRaceDay: (raceDay) => {
            dispatch(setRaceDay(raceDay));
        },
        setDefaultRaceDay: (defaultRaceDay) => {
            dispatch(setDefaultRaceDay(defaultRaceDay));
        },
        setRace: (raceNumber, raceIndex) => {
            dispatch(setRace(raceNumber, raceIndex));
        },
        setProduct: (product) => {
            dispatch(setProduct(product));
        },
        setProductShortcut: (product) => {
            dispatch(setProductShortcut(product));
        },
        resetProduct: () => {
            dispatch(resetProduct());
        },
        resetRaceDay: () => {
            dispatch(resetRaceDay());
        },
        resetRace: () => {
            dispatch(resetRace());
        },
        setDate: (date) => dispatch(setDate(date)),
        fetchRacingCardWithPoolWithoutDispatch: (params) =>
            dispatch(fetchRacingCardWithPoolWithoutDispatch(params)),
        fetchServerTime: () => dispatch(fetchServerTime()),
        resetBet: () => dispatch(resetBet()),
        setPrevAmount: (amount) => dispatch(setPrevAmount(amount)),
        mergeBetslip: (picks, reserves) => dispatch(mergeBetslip(picks, reserves)),
        setSmartLynMode: (mode) => dispatch(setSmartLynMode(mode)),
        setStrictMode: (mode) => dispatch(setStrictMode(mode)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Terminal));
