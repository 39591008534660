import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import Button from 'ui/controls/Button';
import Text from 'ui/Text';

import Popup, {
    Overlay as TransparentBackgroundDef,
    PopupWrapper as PopupWrapperDef,
    CloseIcon as CloseIconDef,
    CloseButtonArea as CloseButtonAreaDef,
} from 'ui/Popup';

import useModals from 'common/hooks/useModals.ts';

import { openLogin } from 'utils/navigation';

import { COLORS } from 'themes';
import { byPlatform, isDesktop } from 'utils/platforms';

const TransparentBg = styled(TransparentBackgroundDef)`
    justify-content: flex-end;
    padding: 10px;
    box-sizing: border-box;
`;

const Description = styled(Text)`
    margin: 19px 0;
    font-weight: bold;
    font-size: 16px;
    color: ${COLORS.grey};
`;
const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    gap: 20px;
`;

const PopupWrapper = styled(PopupWrapperDef)`
    width: ${byPlatform('100%', '370px')};
    height: auto;
    min-height: 0;
    border-radius: 5px;
    padding: 30px 30px 20px;
    box-sizing: border-box;
    background: #edf1f8;
`;

const Title = styled.h3`
    font-size: 24px;
    margin: 0;
    font-family: 'Roboto Condensed', sans-serif;
    text-align: ${({ center }) => (center ? 'center' : 'left')};
    font-weight: 900;
`;

const CloseBtn = styled.div`
    width: 27px;
    height: 27px;
    border-radius: 5px;
    color: #ffffff;
    background: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 15px;
    top: 15px;
    transition: all 0.3s ease;
    cursor: pointer;

    &:hover {
        background: #585858;
    }
`;

const CloseBtnArea = styled(CloseButtonAreaDef)`
    width: 60px;
    height: 60px;
`;

const CloseIcon = styled(CloseIconDef).attrs({ color: 'white' })``;

const UserAlreadyExistPopup = () => {
    const { hide: hideModal, animateHide, getData } = useModals();

    const dispatch = useDispatch();

    const { message } = getData('USER_EXISTS');

    const onAuthSuccess = () => animateHide('SIGNUP');

    const onLoginClick = () => {
        animateHide('USER_EXISTS');

        if (isDesktop) {
            animateHide('SIGNUP');
        }

        dispatch(
            openLogin({
                priority: 3,
                ...byPlatform({ onAuthSuccess }, { replace: true }),
            })
        );
    };
    return (
        <Popup
            closeBtn
            onClose={() => {
                animateHide('USER_EXISTS');
            }}
            TransparentBackgroundComponent={byPlatform(TransparentBg, TransparentBackgroundDef)}
            CloseButtonAreaComponent={CloseBtnArea}
            CloseButtonComponent={CloseBtn}
            CloseIconComponent={CloseIcon}
            PopupWrapperComponent={PopupWrapper}
            closeButtonLocation="inner"
        >
            <Title>Velkommen tilbage</Title>
            <Description>{message}</Description>
            <ButtonsWrapper>
                <Button size="x2" margin="0" active accentAlt bold onClick={onLoginClick}>
                    Log ind
                </Button>
                <Button
                    size="x2"
                    margin="0"
                    bold
                    accent
                    bg={'none'}
                    onClick={() => {
                        hideModal('USER_EXISTS');
                        window.Intercom('show');
                    }}
                >
                    Kontakt os
                </Button>
            </ButtonsWrapper>
        </Popup>
    );
};

export default UserAlreadyExistPopup;
