import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { hideModal, showModal, hideModalAnimate } from 'common/actions/uiActions';
import { connect } from 'react-redux';
import { NotificationContainer } from 'react-notifications';

const modalRoot = document.getElementById('modal-root');

class ModalsHandler extends React.Component {
    constructor(props) {
        super(props);

        this.el = document.createElement('div');
    }

    componentDidMount() {
        modalRoot.appendChild(this.el);
    }

    render() {
        const { modalsMap, showModal, hideModal, hideModalAnimate } = this.props;
        const modalsIds = Object.keys(modalsMap).filter((id) => this.props[id]);

        const states = modalsIds.map((id) => this.props[id]);

        const modalItems = states
            .filter((item) => item.visible || item.animatingBeforeClose)
            .sort((a, b) => a.priority > b.priority);

        return ReactDOM.createPortal(
            <Fragment>
                {modalItems.map((modalState) => {
                    return this.props.children({
                        ModalComponent: modalsMap[modalState.id],
                        priority: modalState.priority,
                        nowrap: modalState.data?.nowrap,
                        actions: {
                            showModal,
                            hideModal,
                            hideModalAnimate,
                        },
                        modalId: modalState.id,
                        modalData: modalState.data,
                        key: modalState.id,
                        id: modalState.id,
                        animatingBeforeClose: modalState.animatingBeforeClose,
                        cameFrom: modalState.cameFrom,
                    });
                })}

                <NotificationContainer />
            </Fragment>,
            this.el
        );
    }
}

const mapStateToProps = (state) => {
    return state.ui.modals;
};

const mapDispatchToProps = (dispatch) => {
    return {
        showModal: (modalId, priority = 0, data = null, duration = 0) => {
            dispatch(showModal(modalId, priority, data, duration));
        },
        hideModal: (modalId, meta) => {
            dispatch(hideModal(modalId, meta));
        },
        hideModalAnimate: (modalId, meta, animationDuration) => {
            dispatch(hideModalAnimate(modalId, animationDuration, meta));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalsHandler);
