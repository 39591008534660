import React from 'react';
import styled from 'styled-components';

const sizes = {
    s: 8,
    x05: 16,
    x1: 24,
    x2: 32,
    x3: 48,
    x4: 72,
    x5: 128,
    x6: 192,
    x7: 256,
};

const getSize = (props) => {
    let dim = sizes.x1;

    if (props.size && sizes[props.size]) {
        dim = sizes[props.size];
    }

    return `
        width: ${dim}px;
        height: ${dim}px;
    `;
};

const getColor = (props) => {
    switch (props.color) {
        case 'black':
            return 'brightness(0%)';
        case 'white':
            return 'brightness(0) invert(1)';
        case 'grey':
            return 'brightness(70%) invert(0.6)';
        default:
            return 'none';
    }
};

const I = styled.i`
    img {
        ${getSize};
        filter: ${getColor};
    }
`;

const Fa = (props) => {
    const { section = 'regular', svgName = 'eye' } = props;
    const src = `/heste/img/fa/${section}/${svgName}.svg`;

    return src ? (
        <I {...props}>
            <img src={src} alt={svgName} />
        </I>
    ) : null;
};

export default Fa;
