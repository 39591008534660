import React, { useEffect } from 'react';
import styled from 'styled-components';
import Popup from 'ui/Popup';
import useModals from 'common/hooks/useModals.ts';
import { byPlatform } from '../../../../utils/platforms';

const IFrame = styled.iframe`
    border: none;
    border-radius: 25px;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: block;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
`;

const PopupWrapper = styled.div`
    position: relative;
    min-height: ${() => `${byPlatform('calc(100vw * 9 / 16)', '230px')}`};
    width: 100%;

    & iframe {
        border-radius: unset;
    }
`;
const CloseButtonArea = styled.div`
    position: absolute;
    width: 40px;
    height: 40px;
    cursor: pointer;
    top: 10px;
    right: 10px;
`;

const Replay = ({ modalData, setBackground }) => {
    useEffect(() => setBackground && setBackground('none'), [setBackground]);
    const { hide } = useModals();
    const { customPopup, replayLink } = modalData;

    return (
        <Popup
            onClose={() => hide('REPLAY')}
            PopupWrapperComponent={customPopup && byPlatform(PopupWrapper)}
            closeButtonLocation={byPlatform('inner')}
            CloseButtonAreaComponent={customPopup && byPlatform(CloseButtonArea)}
        >
            <IFrame
                id="video-iframe"
                title={`ATG Vision video replay`}
                src={replayLink.replace(/^http:\/\//i, 'https://')}
                frameborder="0"
                allowFullScreen
            />
        </Popup>
    );
};

export default Replay;
