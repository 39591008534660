import React from 'react';
import Wrapper from 'ui/Wrapper';
import Text from 'ui/Text';
import Switcher from 'ui/controls/Switcher';
import { Col, Row } from 'react-grid-system';
import { IconWrapper } from '../styled';
import NotificationConductor from 'common/conductors/NotificationConductor';
import { connect } from 'react-redux';
import { setStrictMode } from 'features/BetSlip2/state/actions';
import { PRODUCT_IDS } from 'configs/products';

import getTexts from 'utils/localization';
const t = getTexts();

const HelpIcon = props => (
    <IconWrapper {...props} secondary>
        ?
    </IconWrapper>
);

class StrictBet extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            strict: props.strictMode,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.strictMode !== prevProps.strictMode) {
            this.setState({
                strict: this.props.strictMode,
            });
        }
    }

    onChangeSwitcherStateHandler = state => {
        this.setState({
            strict: state,
        });

        this.props.setStrictMode(state);
    };

    formatMessage = product => {
        if (product === PRODUCT_IDS.GS75) {
            return t.betSlip.Strict.info.V7;
        } else if (product === PRODUCT_IDS.V64) {
            return t.betSlip.Strict.info.V64;
        } else {
            return t.betSlip.Strict.info.V65;
        }
    };

    showInfo = () => {
        NotificationConductor.info(this.formatMessage(this.props.product));
    };

    render() {
        return (
            <Wrapper>
                <Row>
                    <Col xs={8}>
                        <Text>
                            {this.props.product === PRODUCT_IDS.GS75
                                ? t.betSlip.Strict.label.V7
                                : t.betSlip.Strict.label.V6}{' '}
                            <HelpIcon onClick={this.showInfo} />
                        </Text>
                    </Col>
                    <Col xs={4}>
                        <Switcher
                            checked={this.state.strict}
                            onChange={this.onChangeSwitcherStateHandler}
                        />
                    </Col>
                </Row>
            </Wrapper>
        );
    }
}

const mapStateToProps = state => ({
    strictMode: state.BetSlip.strictMode,
});

const mapDispatchToProps = dispatch => {
    return {
        setStrictMode: status => dispatch(setStrictMode(status)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(StrictBet);
