import React from 'react';
import ValidInput from '../../../../ui/controls/ValidInput';

import getTexts from 'utils/localization';
const t = getTexts();

class UserProfileForm extends React.Component {
    validationHandlers = {};

    constructor() {
        super();

        this.state = {
            firstName: '',
            lastName: '',
            address: '',
            zip: '',
            city: '',
        };
    }

    onChangeValueHandler(key, val) {
        let newVal = {};

        newVal[key] = val;

        this.setState(newVal, () => {
            this.props.setFormValidity && this.props.setFormValidity(this.validateAllFields());
        });
    }

    setValidationHandler = (key, handler) => {
        this.validationHandlers[key] = handler;

        if (this.props.setFormValidationHandler && this.validationHandlers) {
            this.props.setFormValidationHandler(this.validateAllFields.bind(this));
        }
    };

    validateAllFields() {
        let isValid = true;

        Object.keys(this.validationHandlers).map((key) => {
            if (this.validationHandlers[key]() === false) {
                isValid = false;
            }

            return key;
        });

        return isValid;
    }

    getUserProfileData() {
        return { ...this.state };
    }

    componentDidMount() {
        this.props.defaultData && this.setState({ ...this.props.defaultData });

        if (this.props.dataCallback) {
            this.props.dataCallback(this.getUserProfileData.bind(this));
        }
    }

    render() {
        const { firstName, lastName, address, zip, city } = this.state;
        const { readOnlyFields } = this.props;
        const validationRules = {
            required: true,
        };

        return (
            <form style={{ padding: '10px 30px' }}>
                <ValidInput
                    value={firstName}
                    placeholder={t.user.firstName}
                    onChange={this.onChangeValueHandler.bind(this, 'firstName')}
                    validate={{
                        ...validationRules,
                        minLength: 2,
                        alphabetic: true,
                    }}
                    getValidator={(validator) => this.setValidationHandler('firstName', validator)}
                    disabled={readOnlyFields}
                    name="firstName"
                    autoComplete="on"
                />
                <ValidInput
                    value={lastName}
                    placeholder={t.user.lastName}
                    onChange={this.onChangeValueHandler.bind(this, 'lastName')}
                    validate={{
                        ...validationRules,
                        minLength: 2,
                        alphabetic: true,
                    }}
                    getValidator={(validator) => this.setValidationHandler('lastName', validator)}
                    disabled={readOnlyFields}
                    name="lastName"
                    autoComplete="on"
                />
                <ValidInput
                    value={address}
                    placeholder={t.user.address}
                    onChange={this.onChangeValueHandler.bind(this, 'address')}
                    validate={validationRules}
                    getValidator={(validator) => this.setValidationHandler('address', validator)}
                    name="address"
                    autoComplete="on"
                />
                <ValidInput
                    value={zip}
                    placeholder={t.user.zip}
                    onChange={this.onChangeValueHandler.bind(this, 'zip')}
                    validate={{ ...validationRules, numeric: true }}
                    getValidator={(validator) => this.setValidationHandler('zip', validator)}
                    name="zip"
                    autoComplete="on"
                />
                <ValidInput
                    value={city}
                    placeholder={t.user.city}
                    onChange={this.onChangeValueHandler.bind(this, 'city')}
                    validate={{ ...validationRules, minLength: 2 }}
                    getValidator={(validator) => this.setValidationHandler('city', validator)}
                    name="city"
                    autoComplete="on"
                />
            </form>
        );
    }
}

export default UserProfileForm;
