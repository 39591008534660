import React from 'react';
import styled from 'styled-components';
import icon from '../images/dk/logo-white.png';
import { COLORS } from '../themes';

const sizes = {
    x05: 16,
    x1: 18,
    x15: 22,
    x2: 32,
    x25: 42,
    x3: 48,
    x35: 52,
    x4: 64,
    x5: 96,
    x6: 128,
    x7: 196,
    x8: 256,
};

const getSize = (props) => {
    let dim = sizes.x1;

    if (props.size && sizes[props.size]) {
        dim = sizes[props.size];
    }

    return `
        height: ${dim}px;
    `;
};

const getIconSize = (props) => {
    let dim = sizes.x1;

    if (props.size && sizes[props.size]) {
        dim = sizes[props.size];
    }

    return dim;
};

const Logo = styled.img.attrs({
    src: (props) => props.src,
})`
    padding: 0px;
    display: block;
    margin: ${(props) => (props.margin ? props.margin : '0px')};
    vertical-align: middle;
    ${(props) => getSize(props)};
`;
Logo.defaultProps = {
    src: icon,
};

const LogoIconWrapper = styled.div`
    width: ${(props) => getIconSize(props)}px;
    height: ${(props) => getIconSize(props)}px;
    background: ${COLORS.primary};
    color: ${COLORS.white};
    line-height: ${(props) => getIconSize(props)}px;
    border-radius: ${(props) => getIconSize(props)}px;
    text-align: center;
    font-size: ${(props) => parseInt(getIconSize(props) / 1.4, 10)}px;
    margin: ${(props) => props.margin || '0px auto'};
    font-weight: 900;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
`;

const LogoIcon = (props) => <LogoIconWrapper {...props}>25</LogoIconWrapper>;

export { Logo, LogoIcon };
export default Logo;
