import { COLORS } from 'themes';
import styled from 'styled-components';
import { Fillable } from 'ui/FullScreen';
import BaseIcon from 'ui/Icon';
import Text from 'ui/Text';

const borderRadius = 27;

export const STYLES = {
    topBlock: `
        box-sizing: border-box;
        box-shadow: 0 0 40px #b5b5b5;
        background: ${COLORS.white};
        height: 69px;
        line-height: 69px;
        border-radius: ${borderRadius}px;
        margin: 0 auto;
        width: 90%;
    `,
};

const StyledFillable = styled(Fillable)`
    height: 100%;
    margin: 0;
    padding: 20px 30px 40px 30px;
    background: ${COLORS.white};
    position: relative;
    z-index: 2;
`;

const Icon = styled(BaseIcon)`
    display: inline-block;
    margin-right: 20px;
`;

const Line = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    @media (min-height: 720px) {
        p {
            font-size: 19px;
        }
    }
`;

const Right = styled.div`
    margin-left: auto;
`;

const AdaptiveText = styled(Text)`
    font-size: 16px;
    font-weight: 700;
    @media screen and (max-width: 360px) {
        font-size: 14px;
        padding: 7px 0;
    }
`;

export { StyledFillable, Icon, Line, Right, AdaptiveText };
