import { createReducer } from 'typesafe-actions';
import { combineReducers } from 'redux';
import { CouponDraftOutput, PoolExtendedInfoOutput } from './server';
import {
    deleteSubscriptions,
    dropSubscriptionStatuses,
    fetchGroupsAsync,
    fetchSubscriptionsAsync,
    getPoolAsync,
    getGroupAsync,
    getSubscriptionAsync,
    savePools,
    saveGroupDraft,
    setUIProps,
    getBetByHashAsync,
    getResultsAsync,
    getGroupMembersAsync,
    placeBetAsync,
    dropPlaceBetStatus,
    getPoolDetailsAsync,
    fetchGroupsCreatedByUserAsync,
    enableSmsNotification,
    cleanBetBuddyReducer,
    fetchPublicGroupsAsync,
    clearGroupsReducer,
    createCouponDraftAsync,
    resetStatus,
    updateCouponAsync,
    addCouponAsync,
    fetchFilteredGroupsAsync,
    clearFilteredGroups,
} from './actions';
import Group, { GroupMember } from './model/Group';
import { Subscription } from './model/Subscription';
import Results from './model/Results';
import Pool from './model/Pool';

export interface BetBuddyState {
    groupsCreatedByUser: GroupsState;
    groups: GroupsState;
    publicGroups: GroupsState;
    filteredGroups: Group[];
    createGroup: GroupCreationState;
    pools: PoolsState;
    poolDetails: PoolDetailsState;
    results: ResultsState;
    statuses: AsyncReducerStatuses;
    ui: UIState;
    subscriptions: Array<Subscription>;
    groupMembers: GroupMembersState;
}

export interface Status {
    pending: boolean;
    success: boolean;
    error: string;
    code: number;
}

export declare type AsyncReducerStatuses = Record<number | string, Status>;

export interface CreationOperation<T, O> {
    draft: T;
    output?: O;
    modelId?: string | number;
}

export interface DataByIds<T> {
    data: Record<number | string, T>;
}

export declare type GroupCreationState = CreationOperation<GroupDraft, CouponDraftOutput>;

export interface GroupsState extends DataByIds<Group> {}

export interface GroupMembersState {
    data: Record<number, GroupMember>;
}

export interface PoolsState {
    data: Record<number, Pool>;
}

export interface PoolDetailsState {
    data: Record<number, PoolExtendedInfoOutput>;
}

export interface ResultsState {
    data: Record<number, Results>;
}

export interface GroupDraft {
    // Id of group. Can be 0.
    // In this case model should be used to create new group,
    // otherwise it will update existing one
    groupId?: number;
    deadline: number;
    groupName: string;
    shareCost: number;
    sharesLimit: number;
    sharesCount: number;
    avatar: string;
    smsEnabled: boolean;
    update: boolean;
}

export interface UIState {
    poolHash: string;
    activeGroupId: number;
    activePoolId: number;
    activeCouponId: number;
    createGroupAvatar: string | null;
    createdPoolHash: string;
    groupsPageNumber: number;
    groupsPageSize: number;
    totalGroupsNumber: number;
    publicGroupsPageNumber: number;
    publicGroupsPageSize: number;
    totalPublicGroupsNumber: number;
    filteredGroupsPageNumber: number;
    filteredGroupsPageSize: number;
    totalFilteredGroupsNumber: number;
    isPersonalGroup: boolean;
    isPublicGroup: boolean;
}

export const createDefaultStatusSet = (): Status => ({
    pending: false,
    success: false,
    error: '',
    code: 0,
});

export const createDefaultSubscription = (): Subscription[] => [
    {
        userId: 0,
        product: '',
        groupId: 0,
        amount: 0,
        limit: 0,
    },
];

export const createNullGroupDraft = (): GroupDraft => ({
    groupId: 0,
    deadline: 0,
    groupName: '',
    shareCost: 0,
    sharesLimit: 0,
    sharesCount: 0,
    avatar: '',
    smsEnabled: true,
    update: false,
});

export const initialState: BetBuddyState = {
    groups: {
        data: {},
    },
    publicGroups: {
        data: {},
    },
    groupsCreatedByUser: {
        data: {},
    },
    filteredGroups: [],
    createGroup: {
        draft: createNullGroupDraft(),
    },
    pools: {
        data: {},
    },
    poolDetails: {
        data: {},
    },
    results: {
        data: {},
    },
    statuses: {
        createCouponDraft: createDefaultStatusSet(),
        groupList: createDefaultStatusSet(),
        filteredGroups: createDefaultStatusSet(),
        groupItem: createDefaultStatusSet(),
        createGroup: createDefaultStatusSet(),
        activeBetsInfo: createDefaultStatusSet(),
        poolDetails: createDefaultStatusSet(),
        subscriptions: createDefaultStatusSet(),
        subscriptionConfirm: createDefaultStatusSet(),
        subscriptionDelete: createDefaultStatusSet(),
        results: createDefaultStatusSet(),
        updateCoupon: createDefaultStatusSet(),
        addCoupon: createDefaultStatusSet(),
        placeBet: createDefaultStatusSet(),
    },
    groupMembers: {
        data: [],
    },
    subscriptions: createDefaultSubscription(),
    ui: {
        poolHash: '',
        activeGroupId: 0,
        activePoolId: 0,
        activeCouponId: 0,
        createGroupAvatar: '',
        createdPoolHash: '',
        groupsPageNumber: 0,
        groupsPageSize: 0,
        totalGroupsNumber: 0,
        publicGroupsPageNumber: 0,
        publicGroupsPageSize: 0,
        totalPublicGroupsNumber: 0,
        filteredGroupsPageNumber: 1,
        filteredGroupsPageSize: 10,
        totalFilteredGroupsNumber: 0,
        isPersonalGroup: false,
        isPublicGroup: false,
    },
};

const reducer = combineReducers({
    // GROUPS
    groups: createReducer(initialState.groups)
        .handleAction(fetchGroupsAsync.success, (state, action) => {
            return {
                data: {
                    ...state.data,
                    ...Object.fromEntries(action.payload.map((group) => [group.id, group])),
                },
            };
        })
        .handleAction(getGroupAsync.success, (state, action) => {
            const [group] = action.payload;

            return {
                data: {
                    ...state.data,
                    [group.id]: group,
                },
            };
        })
        .handleAction(cleanBetBuddyReducer, () => {
            return {
                data: {},
            };
        })
        .handleAction(clearGroupsReducer, () => {
            return {
                data: {},
            };
        }),
    publicGroups: createReducer(initialState.publicGroups).handleAction(
        fetchPublicGroupsAsync.success,
        (state, action) => {
            return {
                data: {
                    ...state.data,
                    ...Object.fromEntries(action.payload.map((group) => [group.id, group])),
                },
            };
        }
    ),
    groupsCreatedByUser: createReducer(initialState.groupsCreatedByUser)
        .handleAction(fetchGroupsCreatedByUserAsync.success, (state, action) => {
            return {
                data: {
                    ...state.data,
                    ...Object.fromEntries(action.payload.map((group) => [group.id, group])),
                },
            };
        })
        .handleAction(cleanBetBuddyReducer, () => {
            return {
                data: {},
            };
        }),
    filteredGroups: createReducer(initialState.filteredGroups)
        .handleAction(fetchFilteredGroupsAsync.success, (state, action) => {
            return [...state, ...action.payload];
        })
        .handleAction(clearFilteredGroups, () => {
            return [];
        }),
    // GROUP CREATION
    createGroup: createReducer(initialState.createGroup).handleAction(
        saveGroupDraft,
        (state, action) => ({
            ...state,
            draft: action.payload,
        })
    ),

    // Group members
    groupMembers: createReducer(initialState.groupMembers)
        .handleAction(getGroupMembersAsync.request, (state) => ({
            ...state,
            groupMembers: {
                pending: true,
                success: false,
                error: '',
            },
        }))
        .handleAction(getGroupMembersAsync.success, (state, action) => ({
            data: {
                ...Object.fromEntries(action.payload.map((member) => [member.userId, member])),
            },
        }))
        .handleAction(getGroupMembersAsync.failure, (state, action) => ({
            ...state,
            data: {
                pending: false,
                success: false,
                error: action.payload,
            },
        })),

    // POOLS
    pools: createReducer(initialState.pools)
        .handleAction(savePools, (state, action) => ({
            data: {
                ...state.data,
                ...Object.fromEntries(action.payload.map((bet) => [bet.internalPool.id, bet])),
            },
        }))
        .handleAction(getPoolAsync.request, (state) => ({
            ...state,
            bet: {
                pending: true,
                success: false,
                error: '',
            },
        }))
        .handleAction(getPoolAsync.success, (state, action) => ({
            data: {
                ...state.data,
                [action.payload.internalPool.id]: {
                    ...action.payload,
                    groupId: action.payload.groupId,
                },
            },
        }))
        .handleAction(getPoolAsync.failure, (state, action) => ({
            ...state,
            data: {
                pending: false,
                success: false,
                error: action.payload,
            },
        })),
    // POOL DETAILS
    poolDetails: createReducer(initialState.poolDetails).handleAction(
        getPoolDetailsAsync.success,
        (state, action) => {
            return {
                ...state,
                data: {
                    ...state.data,
                    [action.payload.poolId]: action.payload,
                },
            };
        }
    ),

    // RESULTS

    results: createReducer(initialState.results).handleAction(
        getResultsAsync.success,
        (state, action) => ({
            data: {
                ...state.data,
                [action.payload.couponId]: action.payload,
            },
        })
    ),
    // STATUSES
    statuses: createReducer(initialState.statuses)
        .handleAction(dropSubscriptionStatuses, (state) => ({
            ...state,
            subscriptions: {
                pending: false,
                success: false,
                error: '',
                code: 0,
            },
            subscriptionConfirm: {
                pending: false,
                success: false,
                error: '',
                code: 0,
            },
            subscriptionDelete: {
                pending: false,
                success: false,
                error: '',
                code: 0,
            },
        }))
        .handleAction(dropPlaceBetStatus, (state) => ({
            ...state,
            placeBet: {
                pending: false,
                success: false,
                error: '',
                code: 0,
            },
        }))
        // PENDING
        .handleAction(createCouponDraftAsync.request, (state) => ({
            ...state,
            createCouponDraft: {
                pending: true,
                success: false,
                error: '',
                code: 0,
            },
        }))
        .handleAction(fetchGroupsAsync.request, (state) => ({
            ...state,
            groupList: {
                pending: true,
                success: false,
                error: '',
                code: 0,
            },
        }))
        .handleAction(fetchFilteredGroupsAsync.request, (state) => ({
            ...state,
            filteredGroups: {
                pending: true,
                success: false,
                error: '',
                code: 0,
            },
        }))
        .handleAction(placeBetAsync.request, (state) => ({
            ...state,
            placeBet: {
                pending: true,
                success: false,
                error: '',
                code: 0,
            },
        }))
        .handleAction(getGroupAsync.request, (state) => ({
            ...state,
            groupItem: {
                pending: true,
                success: false,
                error: '',
                code: 0,
            },
        }))
        .handleAction(fetchSubscriptionsAsync.request, (state) => ({
            ...state,
            subscriptions: {
                pending: true,
                success: false,
                error: '',
                code: 0,
            },
        }))
        .handleAction(getSubscriptionAsync.request, (state) => ({
            ...state,
            subscriptionConfirm: {
                pending: true,
                success: false,
                error: '',
                code: 0,
            },
        }))
        .handleAction(deleteSubscriptions.request, (state) => ({
            ...state,
            subscriptionDelete: {
                pending: true,
                success: false,
                error: '',
                code: 0,
            },
        }))
        .handleAction(getResultsAsync.request, (state) => ({
            ...state,
            results: {
                pending: true,
                success: false,
                error: '',
                code: 0,
            },
        }))
        .handleAction(getPoolDetailsAsync.request, (state) => ({
            ...state,
            poolDetails: {
                pending: true,
                success: false,
                error: '',
                code: 0,
            },
        }))
        .handleAction(enableSmsNotification.request, (state) => ({
            ...state,
            smsNotification: {
                pending: true,
                success: false,
                error: '',
                code: 0,
            },
        }))
        .handleAction(updateCouponAsync.request, (state) => ({
            ...state,
            updateCoupon: {
                pending: true,
                success: false,
                error: '',
                code: 0,
            },
        }))
        .handleAction(addCouponAsync.request, (state) => ({
            ...state,
            addCoupon: {
                pending: true,
                success: false,
                error: '',
                code: 0,
            },
        }))
        // SUCCESS
        .handleAction(createCouponDraftAsync.success, (state) => ({
            ...state,
            createCouponDraft: {
                pending: false,
                success: true,
                error: '',
                code: 0,
            },
        }))
        .handleAction(fetchGroupsAsync.success, (state) => ({
            ...state,
            groupList: {
                pending: false,
                success: true,
                error: '',
                code: 0,
            },
        }))
        .handleAction(fetchFilteredGroupsAsync.success, (state) => ({
            ...state,
            filteredGroups: {
                pending: false,
                success: true,
                error: '',
                code: 0,
            },
        }))
        .handleAction(enableSmsNotification.success, (state) => ({
            ...state,
            groupList: {
                pending: false,
                success: true,
                error: '',
                code: 0,
            },
        }))
        .handleAction(getGroupAsync.success, (state) => ({
            ...state,
            groupItem: {
                pending: false,
                success: true,
                error: '',
                code: 0,
            },
        }))
        .handleAction(fetchSubscriptionsAsync.success, (state) => ({
            ...state,
            subscriptions: {
                pending: false,
                success: true,
                error: '',
                code: 0,
            },
        }))
        .handleAction(updateCouponAsync.success, (state) => ({
            ...state,
            updateCoupon: {
                pending: false,
                success: true,
                error: '',
                code: 0,
            },
        }))
        .handleAction(addCouponAsync.success, (state) => ({
            ...state,
            addCoupon: {
                pending: false,
                success: true,
                error: '',
                code: 0,
            },
        }))
        .handleAction(placeBetAsync.success, (state) => ({
            ...state,
            placeBet: {
                pending: false,
                success: true,
                error: '',
                code: 0,
            },
        }))
        .handleAction(getSubscriptionAsync.success, (state) => ({
            ...state,
            subscriptionConfirm: {
                pending: false,
                success: true,
                error: '',
                code: 0,
            },
        }))
        .handleAction(deleteSubscriptions.success, (state) => ({
            ...state,
            subscriptionDelete: {
                pending: false,
                success: true,
                error: '',
                code: 0,
            },
        }))
        .handleAction(getPoolAsync.success, (state) => ({
            ...state,
            activeBetsInfo: {
                pending: false,
                success: true,
                error: '',
                code: 0,
            },
        }))
        .handleAction(getPoolDetailsAsync.success, (state) => ({
            ...state,
            poolDetails: {
                pending: false,
                success: true,
                error: '',
                code: 0,
            },
        }))
        // FAILURE
        .handleAction(createCouponDraftAsync.failure, (state, action) => ({
            ...state,
            createCouponDraft: {
                pending: false,
                success: false,
                error: action.payload.message,
                code: action.payload.code,
            },
        }))
        .handleAction(fetchGroupsAsync.failure, (state, action) => ({
            ...state,
            groupList: {
                pending: false,
                success: false,
                error: action.payload.message,
                code: action.payload.code,
            },
        }))
        .handleAction(fetchFilteredGroupsAsync.failure, (state, action) => ({
            ...state,
            groupList: {
                pending: false,
                success: false,
                error: action.payload.message,
                code: action.payload.code,
            },
        }))
        .handleAction(enableSmsNotification.failure, (state, action) => ({
            ...state,
            smsNotification: {
                pending: false,
                success: false,
                error: action.payload.message,
                code: action.payload.code,
            },
        }))
        .handleAction(getSubscriptionAsync.failure, (state, action) => ({
            ...state,
            subscriptionConfirm: {
                pending: false,
                success: false,
                error: action.payload.message,
                code: action.payload.code,
            },
        }))
        .handleAction(deleteSubscriptions.failure, (state, action) => ({
            ...state,
            subscriptionDelete: {
                pending: false,
                success: false,
                error: action.payload.message,
                code: action.payload.code,
            },
        }))
        .handleAction(fetchSubscriptionsAsync.failure, (state, action) => ({
            ...state,
            subscriptions: {
                pending: false,
                success: false,
                error: action.payload.message,
                code: action.payload.code,
            },
        }))
        .handleAction(getGroupAsync.failure, (state, action) => ({
            ...state,
            groupItem: {
                pending: false,
                success: false,
                error: action.payload.message,
                code: action.payload.code,
            },
        }))
        .handleAction(getBetByHashAsync.failure, (state, action) => ({
            ...state,
            activeBetsInfo: {
                pending: false,
                success: false,
                error: action.payload.message,
                code: action.payload.code,
            },
        }))
        .handleAction(updateCouponAsync.failure, (state, action) => ({
            ...state,
            placeBet: {
                pending: false,
                success: false,
                error: action.payload.message,
                code: action.payload.code,
            },
        }))
        .handleAction(addCouponAsync.failure, (state, action) => ({
            ...state,
            addBet: {
                pending: false,
                success: false,
                error: action.payload.message,
                code: action.payload.code,
            },
        }))
        .handleAction(placeBetAsync.failure, (state, action) => ({
            ...state,
            placeBet: {
                pending: false,
                success: false,
                error: action.payload.message,
                code: action.payload.code,
            },
        }))
        .handleAction(getResultsAsync.failure, (state, action) => ({
            ...state,
            results: {
                pending: false,
                success: false,
                error: action.payload.message,
                code: action.payload.code,
            },
        }))
        .handleAction(getPoolDetailsAsync.failure, (state, action) => ({
            ...state,
            results: {
                pending: false,
                success: false,
                error: action.payload.message,
                code: action.payload.code,
            },
        }))
        // RESET STATUS
        .handleAction(resetStatus, (state, action) => ({
            ...state,
            [action.payload]: {
                pending: false,
                success: false,
                error: '',
                code: 0,
            },
        })),
    // SUBSCRIPTIONS
    subscriptions: createReducer(initialState.subscriptions)
        .handleAction(getSubscriptionAsync.success, (state, action) => ({
            ...state,
            ...action.payload,
        }))
        .handleAction(fetchSubscriptionsAsync.success, (state, action) => ({
            ...action.payload,
        }))
        .handleAction(deleteSubscriptions.success, (state) => ({
            ...state,
            subscriptions: {
                pending: false,
                success: true,
                error: '',
            },
        })),

    ui: createReducer(initialState.ui)
        // UI
        .handleAction(setUIProps, (state, action) => ({
            ...state,
            ...action.payload,
        })),
});

export default reducer;
