import React, { Component } from 'react';
import omit from 'lodash/omit';

import NotificationConductor from 'common/conductors/NotificationConductor';
import {
    BetPickBtn,
    ReserveNumber,
} from 'features/TrackPage/components/RaceCard/common/PickButtons.styled';
import { PickButtonsContainerOld } from 'features/TrackPage/components/RaceCard/common/PickButtons.styled';

class BetButton extends Component {
    toggle = e => {
        e.stopPropagation();

        const { startNumber, reserved, reserveModeEnabled } = this.props;
        if (reserveModeEnabled) {
            !reserved
                ? this.props.addReserve && this.props.addReserve(startNumber)
                : this.props.removeReserve && this.props.removeReserve(startNumber);
        } else {
            this.isActive()
                ? this.props.onDisactivate && this.props.onDisactivate(this.props.betValue)
                : this.props.onActivate && this.props.onActivate(this.props.betValue);
        }
    };

    showNotificationScratchedHorse = e => {
        e.stopPropagation();
        NotificationConductor.info('Hesten er udgået');
    };

    showNotificationRaceStarted = e => {
        e.stopPropagation();
        NotificationConductor.info(
            'Det er ikke muligt at spille på dette løb, da det er gået igang'
        );
    };

    clickHandler = e => {
        // race has finished
        if (this.props.raceFinished) {
            return;
        }

        if (this.props.raceStarted) {
            return this.showNotificationRaceStarted(e);
        }

        if (this.props.scratched && !this.isActive()) {
            return this.showNotificationScratchedHorse(e);
        }

        this.toggle(e);
    };

    isActive = () => {
        const { bet, betValue, startNumber } = this.props;
        return bet.satisfies(startNumber, betValue);
    };

    render() {
        return (
            <PickButtonsContainerOld>
                <BetPickBtn
                    {...omit(this.props, ['onActivate', 'onDisactivate'])}
                    active={this.isActive()}
                    // secondary={!this.isActive()}
                    size="x1"
                    onClick={this.clickHandler}
                    disallowed={this.props.scratched && !this.isActive()}
                    scratched={this.props.scratched}
                >
                    {this.props.betValue}
                    {this.props.reserved && (
                        <ReserveNumber>{this.props.reserveNumber}</ReserveNumber>
                    )}
                </BetPickBtn>
            </PickButtonsContainerOld>
        );
    }
}

export default BetButton;
