import global from 'window-or-global';
import { Ref, RefObject } from 'react';

export const openLinkNewTab = (href: string) => {
    const link = document.createElement('a');
    link.href = href;
    link.target = '_blank';
    link.id = 'dibsFundLink';
    //@ts-ignore
    document.getElementById('root').appendChild(link);
    link.click();
    link.remove();
};

export const getHost = (): string => {
    const g = global as Window;
    return g.location ? g.location.host : '';
};

export const getScrollTop = () => {
    return window.pageYOffset !== undefined
        ? window.pageYOffset
        : (document.documentElement || document.body.parentNode || document.body).scrollTop;
};

export const getDocumentHeight = () => {
    const body = document.body;
    const html = document.documentElement;

    return Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight
    );
};

export const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};

export const scrollToTop = (settings = {}) => {
    window.scrollTo({
        top: 0,
        ...settings,
    });
};

export const scrollToElement = (
    scrollContainerRef: RefObject<HTMLElement>,
    childElementRef: RefObject<HTMLElement>
) => {
    const scrollBarElement = scrollContainerRef.current;
    const activeButtonRect = childElementRef.current?.getBoundingClientRect();

    if (scrollBarElement && activeButtonRect) {
        scrollBarElement.scrollLeft = activeButtonRect?.left - activeButtonRect?.width / 2 || 0;
    }
};

export const getVerticalHeight = () => {
    return Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
};

export const getSwipingDirectionVertical = (e: TouchEvent, touchStartPositionY: number) => {
    const currentTouchPositionY = e.touches[0].clientY;
    const yDiff = touchStartPositionY - currentTouchPositionY;
    return yDiff > 0 ? 'up' : 'down';
};

export function extractTouchStartYPosition(e: TouchEvent) {
    const firstTouch = e.touches.item(0);
    return firstTouch?.clientY ?? 0;
}

export function extractCurrentTouchPositionY(e: TouchEvent) {
    return e.touches[0].clientY;
}

export function verticallyMoveTo(ref: { current: HTMLElement }, positionY: number | string) {
    if (!ref.current) {
        return;
    }
    ref.current.style.top = positionY + (typeof positionY === 'number' ? 'px' : '');
}

export function downloadFile(blob: Blob | MediaSource, fileName: string) {
    const link = document.createElement('a');
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
}

export async function downloadFileByLink(
    linkToFile: string,
    fileName: string,
    contentType: string
) {
    try {
        const response = await fetch(linkToFile, {
            method: 'GET',
            headers: {
                'Content-Type': contentType,
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const downloadUrl = window.URL.createObjectURL(blob);
        const linkElement = document.createElement('a');
        linkElement.href = downloadUrl;
        linkElement.download = fileName;
        document.body.appendChild(linkElement);
        linkElement.click();
        document.body.removeChild(linkElement);
        window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
        console.error('Error downloading the PDF:', error);
    }
}
