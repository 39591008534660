import React, { Component } from 'react';
import { connect } from 'react-redux';
import { checkout } from 'features/ScanLogicTerminal/actions';
import {
    resetProduct,
    setRaceDay,
    resetRace,
    setDefaultRaceDay,
} from 'features/AISDataProvider/actions';
import { setDate } from 'ui/DatePicker/actions';
import { withRouter } from 'react-router-dom';
import Button from 'ui/controls/Button';
import Spinner from 'ui/Spinner';
import Wrapper from 'ui/Wrapper';
import Logo from 'ui/Logo';
import {
    BalanceText,
    HeaderWrapper,
    NavList,
    NavListItem,
    NavListLink,
    SaldoText,
    TerminalBalance,
} from './styled';

import getTexts from 'utils/localization';
import {
    Divider,
    StopSpillet,
    ModifiedIcon,
} from 'layouts/sections/Terminal/FullWidthTopBar/styled';
import footerSpl from 'images/dk/rofus.png';
const t = getTexts();

class TerminalToolbar extends Component {
    constructor() {
        super();

        this.state = {
            checkoutPending: false,
        };
    }

    componentDidUpdate(prevProps) {
        const logout = prevProps.authorized !== this.props.authorized;

        if (logout) {
            this.setState({
                checkoutPending: false,
            });
        }
    }

    checkout = async () => {
        const { performBetPending, checkInPending, checkout, terminalId, user } = this.props;
        const checkoutIsSave = !this.checkoutPending && !performBetPending && !checkInPending;

        if (checkoutIsSave && user) {
            this.setState(
                {
                    checkoutPending: true,
                },
                async () => {
                    await checkout(terminalId, user.id);

                    this.setState({
                        checkoutPending: false,
                    });
                }
            );
        }
    };

    showCheckinModal = () => {
        this.props.scanButtonHandler();
    };

    clickManagerButtonHandler = () => {
        window.location = 'http://operator.cafe:8080';
    };

    onClickLogoHandler = () => {
        this.props.history.push('/');
        this.props.setDate(new Date());
        //@TODO Replace setTimeout with something smarter
        // setTimeout here fixes the chain of side effects
        // setDate action leads to setRaceDay and setProduct
        // which is important for switching between dates to
        // run all the needed queries (fetch everything).
        // But for resetting state we need all the resetting
        // actions to be run AFTER the setDate side effects.
        setTimeout(() => {
            this.props.resetProduct();
            this.props.resetRaceDay();
            this.props.setDefaultRaceDay(false);
            this.props.resetRace();
        }, 1000);
    };

    renderUserAcountOrButtons = () => {
        const { authorized, balance, loginPending, isManagerEntrance } = this.props;

        const commonWrapperStyles = {
            padding: '0px 13px',
            align: 'right',
            display: 'flex',
        };

        if (!loginPending) {
            return (
                <Wrapper {...commonWrapperStyles}>
                    <Spinner />
                </Wrapper>
            );
        }

        const buttonStyles = {
            borderRadius: '5px',
            height: '44px',
            width: '175px',
            marginLeft: '15px',
            fontSize: '14px',
        };

        let UserAccount = null,
            clickHandler = this.showCheckinModal,
            buttonText = t.Terminal.topBar.scanCoupon;

        if (authorized && !isManagerEntrance) {
            UserAccount = (
                <TerminalBalance>
                    <SaldoText>{t.Terminal.amount}</SaldoText>
                    <BalanceText>{balance} kr.</BalanceText>
                </TerminalBalance>
            );
            clickHandler = this.checkout;
            buttonText = t.Terminal.topBar.udbetaling;
        }

        return (
            <Wrapper {...commonWrapperStyles}>
                {UserAccount}
                {isManagerEntrance ? (
                    <Button
                        accent
                        bold
                        onClick={this.clickManagerButtonHandler}
                        style={buttonStyles}
                        className="uppercase"
                    >
                        Manager
                    </Button>
                ) : (
                    <Button
                        accent
                        bold
                        onClick={clickHandler}
                        style={buttonStyles}
                        disabled={this.state.checkoutPending}
                    >
                        {buttonText}
                    </Button>
                )}
            </Wrapper>
        );
    };

    navigationList = [
        {
            path: '/prebet',
            section: 'prebet',
            label: 'Sport',
        },
        {
            path: '/livebet',
            section: 'live',
            label: 'Live',
        },
        {
            path: '/virtualbet',
            section: 'virtual',
            label: 'Virtuel',
        },
        {
            path: '/big9',
            section: 'big9',
            label: 'Big 9',
        },
        {
            path: '/',
            section: 'derby',
            label: 'Hestevæddeløb',
        },
        {
            path: '/derby-lyn',
            section: 'derby',
            label: 'Lynspil',
        },
    ];

    onNavClick(path) {
        this.props.redirectToBet25(path);
    }

    getClassName = (path) => {
        if (this.props.activeNavItem === path) {
            return 'active';
        } else if (this.props.activeNavItem !== '/derby-lyn') {
            return ['/', '/hjem'].indexOf(path) > -1 ? 'active' : '';
        }
    };

    renderMainNavList() {
        return (
            this.props.shopConfig.fetched && (
                <NavList>
                    {this.navigationList
                        .filter((item) => this.props.shopConfig.data.sections[item.section])
                        .map((item) => (
                            <NavListItem key={item.path}>
                                <NavListLink
                                    className={this.getClassName(item.path)}
                                    onClick={this.onNavClick.bind(this, item.path)}
                                >
                                    {item.label}
                                </NavListLink>
                            </NavListItem>
                        ))}
                </NavList>
            )
        );
    }

    renderDGA() {
        return (
            <div className="center-v justify-evenly" style={{ width: '350px' }}>
                <ModifiedIcon size="x3" src={footerSpl} />
                18+
                <Divider>|</Divider>
                <StopSpillet />
                <Divider>|</Divider> Udeluk dig via ROFUS <Divider>|</Divider> Ansvarligt spil
            </div>
        );
    }

    render() {
        return (
            <HeaderWrapper>
                <Logo size="x2" style={{ paddingLeft: '20px' }} onClick={this.onClickLogoHandler} />
                {this.renderMainNavList()}
                {this.renderDGA()}
                {this.renderUserAcountOrButtons()}
            </HeaderWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    const loginPending = !state.auth.pending && !state.auth.login.pending;
    const authorized = state.auth.login.success || state.auth.user;

    return {
        shopConfig: state.ShopConfig,
        terminalSession: state.terminalSession,
        user: state.auth.user,
        loginPending,
        authorized,
        userName: authorized ? state.auth.user.userName : null,
        balance: authorized ? state.auth.user.balance : null,
        performBetPending: state.BetSlip.performBetPending,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        checkout: (terminalId, sessionId, cb) => {
            return dispatch(checkout(terminalId, sessionId, cb));
        },
        resetProduct: () => {
            dispatch(resetProduct());
        },
        setDate: (date) => dispatch(setDate(date)),
        resetRaceDay: () => {
            dispatch(setRaceDay(null));
        },
        setDefaultRaceDay: (defaultRaceDay) => {
            dispatch(setDefaultRaceDay(defaultRaceDay));
        },
        resetRace: () => dispatch(resetRace()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TerminalToolbar));
