import React from 'react';
import { Delimiter, styles } from '../Mobile/styled';
import BetPick from 'features/BetSlip/components/BetPick';
import BetForm from 'features/BetSlip/components/BetForm';
import { PRODUCT_IDS } from 'configs/products';
import BetSlipCard2 from 'features/BetSlip/components/BetSlipCard2';
import Product from 'common/DataObjects/Product';
import Track from 'common/DataObjects/Track';
import Race from 'common/DataObjects/Race';
import { Race as SimplifiedRaceObject } from '../server';
import StrictBet from 'features/BetSlip/components/StrictBet';

interface BetSlipProps {
    product: Product;
    track: Track;
    date: string;
    firstVRace?: Race | SimplifiedRaceObject;
    races: Race[] | SimplifiedRaceObject[];
    bet: any; //@TODO Define real type
    betSlip: any; //@TODO Define real type
    reserves: any; //@TODO Define real type
    serverTime: string;
    isMultitrack: boolean;
    combinedTrackName: string | null;
    combinationsCount: number;
    combinationPrice: number;
    applyCombinationPrice: any; //@TODO Define real type
    isCombinationPriceValid: boolean;
    isCouponAmountExceed: boolean;
    errorMessage: string;
    minimumPicksValid: boolean;
    maximumPicksValid: boolean;
    withoutDeleteButton?: boolean;
    useSeparateMultitrackCodesForBetslip?: boolean;
}

const BetSlip = ({
    product,
    track,
    date,
    firstVRace,
    races,
    bet,
    betSlip,
    reserves,
    serverTime,
    combinedTrackName,
    minimumPicksValid,
    maximumPicksValid,
    isMultitrack,
    combinationsCount,
    combinationPrice,
    applyCombinationPrice,
    isCombinationPriceValid,
    isCouponAmountExceed,
    errorMessage,
    withoutDeleteButton = false,
    useSeparateMultitrackCodesForBetslip = false,
}: BetSlipProps) => {
    return (
        <BetSlipCard2
            withoutDeleteButton={withoutDeleteButton}
            combinedTrackName={combinedTrackName}
            product={product}
            //@ts-ignore
            race={firstVRace}
            serverTime={serverTime}
            date={date}
            styles={styles.BetSlipCard}
        >
            <BetPick
                product={product}
                combinationsCount={combinationsCount}
                date={date}
                bet={bet}
                race={firstVRace}
                races={races}
                reserves={reserves}
                trackCode={track.code}
                betSlip={betSlip}
                isMultitrackProduct={isMultitrack}
                useSeparateMultitrackCodesForBetslip={useSeparateMultitrackCodesForBetslip}
                placeholders
            />
            <Delimiter />
            <BetForm
                combinationsCount={combinationsCount}
                product={product}
                amount={combinationPrice}
                applyCombinationPrice={applyCombinationPrice}
                isCombinationPriceValid={isCombinationPriceValid}
                isCouponAmountExceed={isCouponAmountExceed}
                combinationPriceError={errorMessage}
                minimumPicksValid={minimumPicksValid}
                maximumPicksValid={maximumPicksValid}
                spilKlub
            />

            {product.id === PRODUCT_IDS.V65 ||
            product.id === PRODUCT_IDS.V64 ||
            product.id === PRODUCT_IDS.GS75 ? (
                <StrictBet product={product.id} />
            ) : null}
        </BetSlipCard2>
    );
};

export default BetSlip;
