import React from 'react';
import moment from 'moment';

import * as Styled from './Shortcuts.styled';

import { PRODUCT_COLORS, PRODUCT_IDS } from 'configs/products';
import { byPlatform } from 'utils/platforms';

import { DATE_FORMAT } from 'features/EventBoard/components/Shortcuts';
import { COLORS } from 'features/EventBoard/theme';

import Text from 'ui/Text';

const PRELOADER_PRODUCTS = Object.keys({
    ...PRODUCT_COLORS,
    [PRODUCT_IDS.V5]: '',
    [PRODUCT_IDS.V4]: '',
});

const ShortcutsPreloader = () =>
    PRELOADER_PRODUCTS.map((key, index) => (
        <Styled.ShortcutContainer
            key={index}
            background={PRODUCT_COLORS[key] || COLORS.defaultShortcutBg}
            blurred
            length={9}
        >
            <Text white size={byPlatform('x1', 'x2')} bold padding={'2px 0'} atg>
                {key}
            </Text>
            <Text white size={'x07'} padding={'0'} className={'capitalize'} weight={'700'}>
                {moment(Date.now()).format(DATE_FORMAT)}
            </Text>
        </Styled.ShortcutContainer>
    ));

export default ShortcutsPreloader;
