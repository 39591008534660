import React from 'react';
import Mobile from './Mobile';
import Desktop from './Desktop';
import Terminal from './Terminal';
import { isMobile, isTablet, isIPad13 } from 'react-device-detect';
import { APP_MODE_TERMINAL } from 'configs/main';

const Layout = ({ isVProduct }) => {
    return APP_MODE_TERMINAL !== true ? (
        isMobile || isTablet || isIPad13 ? (
            <Mobile isVProduct={isVProduct} />
        ) : (
            <Desktop isVProduct={isVProduct} />
        )
    ) : (
        <Terminal />
    );
};

export default Layout;
