import React, { ReactNode, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useStatsExpanded } from './RaceCardActionsRow';
import { VHorse, Horse } from 'features/TrackPage/model/Race';
import { ProductId } from 'features/EventBoard/server/calendar';
import { TableRowContainer, TableRowWithStats } from './styled';
import DetailedStatisticTable from './DetailedStatisticTable';
import { showModal } from 'common/actions/uiActions';
import { priority } from 'configs/layout';

interface TableRowProps {
    hideNrCol?: boolean;
    horse: Horse | VHorse;
    isVProduct: boolean;
    productId: ProductId;
    isGallop: boolean;
    children: ReactNode;
}

const TableRow = ({
    horse,
    hideNrCol,
    isVProduct,
    productId,
    isGallop,
    children,
}: TableRowProps) => {
    const [statsVisible, setStatsVisibility] = useState(false);

    const toggleStatsVisibility = () => setStatsVisibility(!statsVisible);

    const dispatch = useDispatch();
    const showReplayPopup = (data: any) => dispatch(showModal('REPLAY', priority.replays, data));

    const { allStatsExpanded } = useStatsExpanded();

    useEffect(() => {
        setStatsVisibility(allStatsExpanded);
    }, [allStatsExpanded]);

    return (
        <TableRowWithStats>
            <TableRowContainer
                hideNrCol={hideNrCol}
                isVProduct={isVProduct}
                productId={productId}
                data-test-id={horse.vpPool.scratched ? 'scratched' : 'outright'}
                gallop={isGallop}
                onClick={toggleStatsVisibility}
            >
                {children}
            </TableRowContainer>

            <DetailedStatisticTable
                start={horse}
                gallop={isGallop}
                isShown={statsVisible}
                useExpandedProp={true}
                expanded={statsVisible}
                showReplayPopup={showReplayPopup}
            />
        </TableRowWithStats>
    );
};

export default TableRow;
