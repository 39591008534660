import Cookies from 'js-cookie';
import { get as _get } from 'lodash';

const get = (key, isValid, defaultValue = null) => {
    const value = Cookies.getJSON(key);

    if (isValid) {
        if (isValid(value)) {
            return getValueByPath(key, value, defaultValue);
        } else {
            this.save({ [key]: defaultValue });
            return defaultValue;
        }
    }

    return getValueByPath(key, value, defaultValue) || defaultValue;
};

const getValueByPath = (key, value, defaultValue) => {
    if (key.includes('.')) {
        return _get(value, key, defaultValue);
    }

    return value;
};

/**
 * Save data to cookies
 * @param {Object} object Key value pairs to save in cookies
 */
const save = (object, settings = {}) => {
    for (let p in object) {
        if (object.hasOwnProperty(p)) {
            Cookies.set(p, object[p], { path: '/', expires: 7, ...settings });
        }
    }
};

const pop = (key) => {
    const value = get(key);
    remove(key);
    return value;
};

const remove = (keys) => {
    if (Array.isArray(keys)) {
        keys.forEach((key) => Cookies.remove(key));
    } else {
        Cookies.remove(keys);
    }
};

const removeBy = (regex) => {
    const cookieKeys = getCookieKeys();
    const cookiesToRemove = cookieKeys.filter((k) => new RegExp(regex).test(k));
    remove(cookiesToRemove);
    return cookiesToRemove;
};

const getCookieKeys = () =>
    document.cookie.split(';').map((c) => c.trim().replace(/=(.*)/g, ''));

const getExpirationTimeMinutes = (minutes = 1) =>
    new Date(new Date().getTime() + minutes * 60 * 1000);

export { get, save, remove, removeBy, pop, getExpirationTimeMinutes };
