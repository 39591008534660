import styled from 'styled-components';
import { BACKGROUNDS, COLORS } from 'themes/';
import Button from 'ui//controls/Button';

const QPWrapper = styled.aside`
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
    background-position: center;
    background: ${(props) => (props.bg ? props.bg : BACKGROUNDS.primary)}
        no-repeat center/cover;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: ${(props) => props.radius || 0}px;
`;

const QPCard = styled.div`
    width: auto;
    min-height: 375px;
    background: ${BACKGROUNDS.white};
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    padding: 10px;
    position: relative;
`;

const CloseButton = styled.div`
    position: absolute;
    top: 0px;
    color: ${COLORS.white};
    font-size: 14px;
    font-weight: bold;
    padding-left: 20px;
    margin: 20px;
    & > .triangle {
        display: inline-block;
        position: relative;
        background-color: ${COLORS.white};
        text-align: left;
        margin: 3px;
        top: 4px;
    }
    & > .triangle:before,
    & > .triangle:after {
        content: '';
        position: absolute;
        background-color: inherit;
    }
    & > .triangle,
    & > .triangle:before,
    & > .triangle:after {
        width: 8px;
        height: 8px;
        border-top-right-radius: 40%;
    }

    & > .triangle {
        transform: rotate(90deg) skewX(-30deg) scale(1, 0.866);
    }
    & > .triangle:before {
        transform: rotate(-135deg) skewX(-45deg) scale(1.414, 0.707)
            translate(0, -50%);
    }
    & > .triangle:after {
        transform: rotate(135deg) skewY(-45deg) scale(0.707, 1.414)
            translate(50%);
    }
`;

const AnimatedButton = styled(Button)`
    transition: all 0.2s ease-in-out;
`;

export { QPWrapper, QPCard, CloseButton, AnimatedButton };
