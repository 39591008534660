import React, { useCallback, useEffect, useState } from 'react';
import SpilIdCard from 'features/SpilID/SpilIdCard';
import useSpilIdCard from 'features/SpilID/useSpilIdCard';
import useModals from 'common/hooks/useModals.ts';
import {
    RightArrow,
    SpilIdButtonsContainer,
    SpilIdBtn,
    SpilIdLink,
    SpilIDTooltip,
    SpilIdWrapper,
    NavigationBar,
    PinTitleDotsIndicators,
} from 'features/SpilID/styled';

import useAuthProtection from 'common/hooks/useAuthProtection.tsx';
import useAuth from 'common/hooks/useAuth';

import { isMobile } from 'react-device-detect';
import withPopupWrapper from './withPopupWrapper';
import FlexWrapper from 'ui/FlexWrapper';
import { connect } from 'react-redux';

const SpilIdModal = ({ isRetailPinSet, retailPin }) => {
    const { show, animateHide, hide, isShown } = useModals();

    const auth = useAuth();
    const user = auth?.user;

    const showPinCodeModal = useCallback(
        () => {
            show('SPIL_ID_PIN_CODE', 2);
        },
        [show]
    );

    useAuthProtection({
        pushOnLogin: '/?spilid=1',
        loginModalPriority: 3,
        onClose: () => {
            animateHide('SPIL_ID');
            if (isShown('SPIL_ID_PIN_CODE')) {
                animateHide('SPIL_ID_PIN_CODE');
            }
        },
    });

    useEffect(
        () => {
            if (user && !isRetailPinSet && !isShown('SPIL_ID_PIN_CODE')) {
                showPinCodeModal();
            }
        },
        [isRetailPinSet, showPinCodeModal, user, isShown]
    );

    const {
        pending,
        failed,
        spilIdCard,
        fullName,
        address,
        zipCode,
        birthdate,
        city,
    } = useSpilIdCard({ isRetailPinSet, retailPin });

    const [tooltipPopupShown, setTooltipPopupShown] = useState(false);
    const showTooltipPopup = (e) => {
        e.stopPropagation();
        setTooltipPopupShown(true);
    };
    const hideTooltipPopup = () => {
        setTooltipPopupShown(false);
    };

    const { barcode, name, id: cardId, year, hash } = spilIdCard;

    const showPCPreviewModal = () => {
        show('SPIL_ID_PC_PREVIEW', 2, {
            fullName,
            address,
            zipCode,
            birthdate,
            city,
            cardId,
        });
    };

    return (
        <SpilIdWrapper onClick={hideTooltipPopup}>
            <div style={{ width: '100%' }}>
                <NavigationBar
                    title={'Mit Spil-ID'}
                    clickHandler={() => hide('SPIL_ID')}
                    isClose
                    onQuestionMarkClick={showTooltipPopup}
                />
                {/* Spil id card */}
                {failed ? null : (
                    <SpilIdCard
                        pending={pending}
                        barcode={barcode}
                        year={year}
                        fullName={name}
                        birthdate={birthdate}
                        hash={hash}
                    />
                )}
            </div>
            {/* Spil id actions */}
            <SpilIdButtonsContainer>
                <SpilIdBtn onClick={showPinCodeModal}>
                    Skift kode{' '}
                    <FlexWrapper
                        direction={'row'}
                        alignItems={'center'}
                        width={'auto'}
                    >
                        <PinTitleDotsIndicators /> <RightArrow />
                    </FlexWrapper>
                </SpilIdBtn>
                <SpilIdBtn onClick={showPCPreviewModal}>
                    Bestil fysisk Spil ID <RightArrow />
                </SpilIdBtn>
                <SpilIdLink href="https://bet25.dk/spil-id" target="_blank">
                    Læs mere om Spil-ID <RightArrow />
                </SpilIdLink>
            </SpilIdButtonsContainer>

            <SpilIDTooltip
                hideTooltipPopup={hideTooltipPopup}
                tooltipPopupShown={tooltipPopupShown}
            />
        </SpilIdWrapper>
    );
};

const ConnectedSpilIdModal = connect((state) => ({
    isRetailPinSet: state.auth?.user?.isRetailPinSet,
    retailPin: state.auth?.user?.retailPin,
}))(SpilIdModal);

export default (isMobile
    ? ConnectedSpilIdModal
    : withPopupWrapper(ConnectedSpilIdModal));
