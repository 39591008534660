import React from 'react';
import styled from 'styled-components';

import { BACKGROUNDS, COLORS } from 'themes';
import NotificationConductor from 'common/conductors/NotificationConductor';
import useReservesMode from 'features/TrackPage/hooks/useReservesMode';
import useRacePicks from 'features/TrackPage/hooks/useRacePicks';

const BetBtn = styled.div`
    box-sizing: border-box;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    caret-color: transparent;
    font: 900 14px 'Roboto Condensed';
    opacity: ${({ scratched }) => (scratched ? 0.4 : 1)};
    cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
    border: ${({ active, reserved }) => (active || reserved ? 0 : '0.5px solid #C6CCDB')};
    color: ${({ active, reserved }) => (active || reserved ? COLORS.white : '#333333')};
    background: ${({ active, reserved, disabled }) => {
        if (active && reserved) {
            return `linear-gradient(45deg, #04a358 0%,#36b575 51%,#207cca 51%,#2989d8 51%,#0091d6 100%);`;
        }
        if (active) {
            return BACKGROUNDS.primary;
        }
        if (disabled) {
            return BACKGROUNDS.lightGrey;
        }
        if (reserved) {
            return BACKGROUNDS.blue;
        }

        return BACKGROUNDS.lightGrey;
    }};
    text-align: center;
    line-height: 30px;
    transition: background 300ms ease;
    &:hover {
        background: ${({ reserveMode, active }) =>
            reserveMode ? BACKGROUNDS.blue : active ? BACKGROUNDS.primary : '#c6ccdb'};
    }
`;

const ReserveNumber = styled.span`
    display: block;
    background: ${BACKGROUNDS.accentAlt};
    height: 12px;
    width: 12px;
    line-height: 13px;
    border-radius: 10px;
    position: absolute;
    font-size: 8px;
    color: ${COLORS.black};
    transform: translate(21px, -36px);
`;

const BetButton = ({ horse, scratched, raceStarted, race }) => {
    const { pickStart, unpickStart, isPicked, reserved, addReserve, removeReserve, reserveNumber } =
        useRacePicks({
            startNr: horse.startNr,
            race,
        });

    const { reservesMode } = useReservesMode();

    const toggle = e => {
        e.stopPropagation();

        if (reservesMode) {
            reserved ? removeReserve() : addReserve();
        } else {
            isPicked() ? unpickStart() : pickStart();
        }
    };

    const showNotificationScratchedHorse = e => {
        e.stopPropagation();
        NotificationConductor.info('Hesten er udgået');
    };

    const showNotificationRaceStarted = e => {
        e.stopPropagation();
        NotificationConductor.info(
            'Det er ikke muligt at spille på dette løb, da det er gået igang'
        );
    };

    const clickHandler = e => {
        if (raceStarted) {
            return showNotificationRaceStarted(e);
        }

        if (scratched && !isPicked()) {
            return showNotificationScratchedHorse(e);
        }

        toggle(e);
    };

    return (
        <BetBtn
            onClick={clickHandler}
            scratched={scratched}
            disabled={scratched}
            active={isPicked()}
            reserveMode={reservesMode}
            reserved={reserved}
        >
            {horse.startNr}
            {reserved && <ReserveNumber>{reserveNumber}</ReserveNumber>}
        </BetBtn>
    );
};

export default BetButton;
