import React from 'react';
import { Col } from 'react-grid-system';
import { Row } from 'features/BetSlip/components/BetPick/styled';
import { useSelector } from 'react-redux';

const EmptyBetPickRow = ({ raceIndex, isMultitrackProduct, race, product }) => {
    const selectedUIRaceNumber = useSelector((state) => state.AISDataProvider.selectedRaceNumber);

    const { hostTrack } = race;

    return (
        <Row style={{ height: '31px' }} key={raceIndex}>
            <Col xs={2} className="text-bold" style={{ padding: '5px 0 0', textAlign: 'center' }}>
                {isMultitrackProduct
                    ? `${hostTrack ? hostTrack.code : race.trackCode} / ${
                          race.legNr || race.index + 1
                      }`
                    : product.isVProduct()
                    ? race.index + 1
                    : race
                    ? race.raceNumber
                    : selectedUIRaceNumber}
                .
            </Col>
            <Col xs={9} style={{ padding: '0 20px 0 0' }}>
                -
            </Col>
        </Row>
    );
};

export default EmptyBetPickRow;
