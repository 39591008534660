import React, { useState, useEffect } from 'react';
import { BottomMenu } from '@it25syv/bottom-menu';
import bottomMenuConfig from './config';
import { priority } from 'configs/layout';

const BottomMenuWrapped = () => {
    const [initialMenuPosition, setInitialMenuPosition] = useState(0);

    useEffect(() => {
        getMenuPosition();
    }, []);

    useEffect(() => {
        getMenuPosition();
    }, [window.innerHeight]);

    const getMenuPosition = () => {
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;

        if (windowHeight > 320 && windowHeight < 768 && windowWidth > 650 && windowWidth < 1200) {
            setInitialMenuPosition(200);
        } else if (windowWidth > 767 && windowWidth < 1400) {
            setInitialMenuPosition(527);
        } else {
            setInitialMenuPosition(505);
        }
    };

    return (
        <BottomMenu
            id={'navigation-mobile'}
            zIndexWrapper={priority.bottomBar + 100500}
            config={bottomMenuConfig(initialMenuPosition)}
        />
    );
};

export default BottomMenuWrapped;
