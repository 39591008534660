import Product from 'common/DataObjects/Product';
import { useDidUpdate } from 'rooks';
import { create } from 'zustand';

import { NOT_V_SIMILAR_PRODUCTS } from 'features/Results';

import { ProductId } from 'features/EventBoard/server/calendar';
import { TrackResult } from 'features/ResultsNew/hooks/useTracksResultsQuery';
import useActiveTrack from 'features/ResultsNew/hooks/useActiveTrack';
import useURLParameters from 'features/ResultsNew/hooks/useURLParameters';

import { PRODUCTS_NAMES } from 'configs/products';
import { generateResultPath, history } from 'utils/navigation';

interface ActiveProduct {
    activeProduct: Product | null;
    setActiveProduct(product: Product): void;
}
const useActiveProductState = create<ActiveProduct>()(set => ({
    activeProduct: null,
    setActiveProduct: (product: Product) => set({ activeProduct: product }),
}));

const useActiveProduct = () => {
    const { product: productId, trackId, date, race } = useURLParameters();
    const { activeTrack } = useActiveTrack();
    const { activeProduct, setActiveProduct } = useActiveProductState();

    useDidUpdate(() => {
        if (activeTrack) {
            mergeActiveProduct(activeTrack, productId);
        }
    }, [activeTrack, productId]);

    const mergeActiveProduct = (track: TrackResult, productId: string) => {
        const selectedProduct =
            track.products.find(product => product.poolType === productId) || track.products[0];
        const product = Product.unserialize({
            code: selectedProduct.poolType,
            domesticText: PRODUCTS_NAMES[selectedProduct.poolType],
        });
        product.trackId = trackId;
        // @ts-ignore
        setActiveProduct({ ...product, ...selectedProduct });

        const raceNumber = NOT_V_SIMILAR_PRODUCTS.includes(productId) ? race : 1;
        history.push(generateResultPath({ date, product: productId, trackId, race: raceNumber }));
    };

    const onSelectProduct = (productId: ProductId) => {
        if (activeTrack) {
            mergeActiveProduct(activeTrack, productId);
        }
    };

    return {
        activeProduct,
        setActiveProduct,
        onSelectProduct,
    };
};

export default useActiveProduct;
