import React from 'react';
import { useSelector } from 'react-redux';

import { BetslipNotifierContainer, FixedContainer } from 'features/TrackPage/components/styled';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';

import { BetslipNotifier } from 'features/BetSlip/components/BetslipNotifier';
import SpilklubButton from 'features/Spilklub/Mobile/SpilklubButton';
import { checkMaximumPicks, isSpilklubSelectionsMode } from 'common/selectors/betSlipSelector';
import { combinationsCountBy } from 'common/selectors/combinationsCountSelector';

const BetslipButtonRow = () => {
    const { isVProduct, productId, raceDay, races, race } = useTrackPage();
    const spilklubSelectionsMode = useSelector(isSpilklubSelectionsMode);
    const maximumPicksValid = useSelector(checkMaximumPicks);

    const combinationsCount = useSelector((state) =>
        combinationsCountBy(state, {
            productId,
            trackCode: raceDay.track.code,
            date: raceDay.raceDayDate,
            raceIndex: race.index,
            races,
        })
    );

    return (
        <FixedContainer>
            <BetslipNotifierContainer>
                {!spilklubSelectionsMode && (
                    <BetslipNotifier
                        selectedProduct={{ id: productId }}
                        combinationsCount={combinationsCount}
                        maximumPicksValid={maximumPicksValid}
                    />
                )}
            </BetslipNotifierContainer>
            {isVProduct && <SpilklubButton />}
        </FixedContainer>
    );
};

export default BetslipButtonRow;
