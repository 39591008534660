import React from 'react';

import Spinner from 'ui/Spinner';

import ResultsCard from 'features/Results/components/ResultsCard';
import { Navigation, PageContainer } from 'features/TrackPage/components/styled';
import ProductsRow from 'features/ResultsNew/components/ResultPage/ProductsRow';
import RacesRow from 'features/ResultsNew/components/ResultPage/RacesRow';
import TrackBar from 'features/ResultsNew/components/ResultPage/TrackBar';

import usePoolResultsQuery from 'features/ResultsNew/hooks/usePoolResultsQuery';
import useResultPage from 'features/ResultsNew/hooks/useResultPage';
import useActiveProduct from 'features/ResultsNew/hooks/useActiveProduct';
import useActiveTrack from 'features/ResultsNew/hooks/useActiveTrack';

import { isMobile } from 'utils/platforms';
import Track from 'common/DataObjects/Track';

const ResultPage = () => {
    const { date, raceNumber, isVProduct } = useResultPage();
    const { activeProduct, onSelectProduct } = useActiveProduct();
    const { activeTrack, onSelectRace } = useActiveTrack();
    const { results, isLoading } = usePoolResultsQuery();

    return (
        <>
            <TrackBar />
            <PageContainer>
                {activeProduct?.id ? (
                    <>
                        <Navigation>
                            <ProductsRow
                                showLabels={!isMobile}
                                onSelect={onSelectProduct}
                                productId={activeProduct.id}
                            />
                            {!isVProduct && (
                                <RacesRow showLabels={!isMobile} onSelect={onSelectRace} />
                            )}
                        </Navigation>
                        {!isLoading && results && activeTrack ? (
                            <div>
                                {!isVProduct ? (
                                    <>
                                        <ResultsCard
                                            product={activeProduct}
                                            track={Track.unserialize(
                                                activeTrack.track.trackId,
                                                activeTrack.track.track
                                            )}
                                            result={results[parseInt(raceNumber) - 1]}
                                            index={parseInt(raceNumber) - 1}
                                            date={date}
                                            winnersOnly={true}
                                        />
                                        <ResultsCard
                                            product={activeProduct}
                                            track={Track.unserialize(
                                                activeTrack.track.trackId,
                                                activeTrack.track.track
                                            )}
                                            result={results[parseInt(raceNumber) - 1]}
                                            index={parseInt(raceNumber) - 1}
                                            date={date}
                                            withoutWinners={true}
                                        />
                                    </>
                                ) : (
                                    <ResultsCard
                                        product={activeProduct}
                                        track={Track.unserialize(
                                            activeTrack.track.trackId,
                                            activeTrack.track.track
                                        )}
                                        result={results[0]}
                                        index={parseInt(raceNumber) - 1}
                                        date={date}
                                    />
                                )}
                            </div>
                        ) : (
                            <Spinner />
                        )}
                    </>
                ) : (
                    <Spinner />
                )}
            </PageContainer>
        </>
    );
};

export default ResultPage;
