import styled from 'styled-components';
import { BACKGROUNDS } from 'themes';
import CloseIcon from 'images/icons/edit.png';

export const PopupWrapperStyled = styled.div`
    width: 800px;
    height: 666px;
    border-radius: 15px;
    box-shadow: 0px 0px 80px rgba(0, 0, 0, 0.5);
    background: ${BACKGROUNDS.white};
    overflow: hidden;
    position: relative;
    padding: 0px;
    margin: 0px auto;
`;

export const CloseModalBtnStyled = styled.div`
    width: 20px;
    height: 20px;
    background: url(${CloseIcon}) center center no-repeat;
    background-size: 14px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 1;
`;
