import React, { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import { ChildrenProps } from 'ui/types';
import { byPlatform } from 'utils/platforms';

const iconHoverCSS = css`
    opacity: 0.7;
`;

const PlayButtonContainer = styled.div<{ size?: string; round?: boolean }>`
    cursor: pointer;
    position: relative;
    border-radius: ${({ round }) => (round ? '15px' : '0')};
    overflow: hidden;
    width: ${({ size }) => size};
    display: inline-flex;
    margin: ${byPlatform('0 auto', 0)};
    &:hover > div {
        ${iconHoverCSS};
    }
`;

const Circle = styled.div<{ size: string }>`
    width: ${({ size }) => size};
    height: ${({ size }) => size};
    border-radius: ${({ size }) => size};
    background: #04a358;
    opacity: 0.5;
    position: absolute;
    top: calc(50% - ${({ size }) => size} / 2);
    left: calc(50% - ${({ size }) => size} / 2);
    transition: opacity 400ms linear;
    &:hover {
        ${iconHoverCSS};
    }
`;

const Triangle = styled.div`
    width: 0;
    height: 0;
    border-top: 16px solid transparent;
    border-bottom: 16px solid transparent;
    border-left: 30px solid white;
    position: absolute;
    top: calc(50% - 30px / 2);
    left: calc(50% - 20px / 2);
`;

export interface PlayIconSettings {
    playIconSize?: string;
}

export interface PlayButtonStyle {
    Circle: CSSProperties;
    PlayIcon?: CSSProperties;
}

interface PlayButtonWrapperProps extends PlayIconSettings, ChildrenProps {
    size?: string;
    round?: boolean;
    styles?: PlayButtonStyle;
    renderPlayIcon?: () => React.ReactNode;
}

const PlayButtonWrapper = ({
    playIconSize,
    children,
    size,
    styles,
    renderPlayIcon,
}: PlayButtonWrapperProps) => {
    return (
        <PlayButtonContainer size={size}>
            {children}
            {renderPlayIcon ? (
                renderPlayIcon()
            ) : (
                <Circle size={playIconSize || ''} style={styles?.Circle}>
                    <Triangle style={styles?.PlayIcon} />
                </Circle>
            )}
        </PlayButtonContainer>
    );
};

export default PlayButtonWrapper;
