// import { CMS_API_BASE_URL /*, CMS_REST_BASE_URL */ } from '../../configs/rest';
import { DOMAIN_PATH } from 'configs/main';
import axios from 'axios';
import qs from 'qs';
import { byWebEnv } from 'utils/env';

export const CMS_API_FEATURE_FLAGS = {
    SPIL_ID: 'SPIL_ID',
};

export const getOrigin = (featureFlag) => {
    if (featureFlag === CMS_API_FEATURE_FLAGS.SPIL_ID) {
        return byWebEnv(
            'https://cms.retail.25syv.dk',
            'https://cms-3.bet25dev.dk',
            'https://cms.retail.25syv.dk',
            'https://cms-3.bet25dev.dk'
        );
    }

    return DOMAIN_PATH;
};

export function post(method, data, featureFlag) {
    return request('post', getOrigin(featureFlag) + method, data);
}

export function get(method, data, featureFlag) {
    return request('get', getOrigin(featureFlag) + method, data);
}

function request(requestType, url, data) {
    let options = {
        method: requestType,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
        url,
    };

    if (requestType === 'get') {
        options.params = data;
    }

    return axios(options).then((res) => res.data);
}
