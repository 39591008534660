import React from 'react';
import { useQuery } from '@tanstack/react-query';

import useSelections from 'features/BetSlip/state/useSelections';
import { getProductColor } from 'features/EventBoard/components/TrackProducts';
import { ProductId } from 'features/EventBoard/server/calendar';

import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import useProductTipsVisibility from 'features/TrackPage/hooks/useProductTipsVisibility';

import { NEW_TRACK_PAGE } from 'configs/main';
import { PRODUCT_COLORS, PRODUCT_COLORS_LIGHT } from 'configs/products';

import { request } from 'utils/server';
import { hidePopup, showPopup } from 'utils/navigation';

import {
    Container,
    CloseButtonArea,
    CloseButton,
    ProductTitle,
    ShortText,
    ShowMoreButton,
    RemoveProductTipsP,
} from './ProductTips.styled';

import DraggablePopup, { DraggablePopupTitle } from 'ui/DraggablePopup';
import Button from 'ui/controls/Button';
import Divider from 'ui/Divider';

interface QueryParameters {
    product: ProductId;
}

interface ProductTextResponse {
    product: ProductId;
    shortText: string;
    longText: string;
    url: string;
}

const fetchProductText = async ({ product }: QueryParameters) => {
    const response = await request<ProductTextResponse, QueryParameters>(
        'InfoService',
        'GET',
        '/product/text',
        { product }
    );

    if (!response.success) {
        throw new Error(response.errorMessage);
    }

    return response.data;
};

export const ProductDescriptionPopup = ({
    text,
    url,
    product,
}: {
    text: string;
    url: string;
    product: string;
}) => {
    return (
        <DraggablePopup
            height={'auto'}
            padding="25px 30px"
            closeHandler={() => hidePopup('PRODUCT_DESCRIPTION')}
            closeBtn
        >
            <>
                <div
                    dangerouslySetInnerHTML={{
                        __html: text,
                    }}
                />
                <Divider margin={'30px 0'} color={'#DFE3ED'} />
                <Button
                    accent
                    width={'100%'}
                    size={'x15'}
                    margin={'0'}
                    onClick={() => {
                        window.location.href = url;
                    }}
                    bold
                >
                    Læs mere om {product}
                </Button>
            </>
        </DraggablePopup>
    );
};

export const RemoveProductTipsPopup = () => {
    const { setProductTipsVisibility } = useProductTipsVisibility();

    const btnExtra = {
        className: 'flex-grow',
        bold: true,
        height: '40px',
        textsize: '14px',
    };

    const hideSelf = () => hidePopup('PRODUCT_DESCRIPTION_REMOVE');

    const hideProductTipsForever = (close: () => void) => {
        close();
        setProductTipsVisibility(false);
    };

    return (
        <DraggablePopup padding="25px 35px" height={'35%'} closeHandler={hideSelf}>
            {({ close }) => (
                <>
                    <DraggablePopupTitle>Skjul denne guide?</DraggablePopupTitle>
                    <RemoveProductTipsP>
                        Vælger du at skjule denne guide, kan du altid finde samme information under{' '}
                        <b>Sådan spiller du</b> under <b>Mere</b> i bundmenuen.
                    </RemoveProductTipsP>
                    <div className="flex">
                        <Button grey {...btnExtra} onClick={close}>
                            Annuller
                        </Button>
                        <Button
                            accentAlt
                            {...btnExtra}
                            onClick={() => hideProductTipsForever(close)}
                        >
                            Bekræft
                        </Button>
                    </div>
                </>
            )}
        </DraggablePopup>
    );
};

const ProductTips = () => {
    const legacyMode = !NEW_TRACK_PAGE;
    const { product: legacyProduct } = useSelections();
    const { productId: productIdURL } = useTrackPage({ fetchRaces: NEW_TRACK_PAGE });
    const productId = (legacyMode ? legacyProduct.id : productIdURL) ?? 'V';

    const { data } = useQuery({
        queryKey: ['productTexts', productId],
        queryFn: () => fetchProductText({ product: productId }),
        staleTime: 60 * 10 * 1000,
    });

    const placeholderData = { shortText: '', longText: '', url: '', product: '' };
    const { shortText, longText, url, product } = data ?? placeholderData;

    const backgroundColor = getProductColor(productId) ?? PRODUCT_COLORS['V'];
    const buttonsColor = PRODUCT_COLORS_LIGHT[productId] ?? PRODUCT_COLORS_LIGHT['V'];

    return (
        <Container bg={backgroundColor}>
            <CloseButtonArea
                bg={buttonsColor}
                onClick={() => {
                    showPopup('PRODUCT_DESCRIPTION_REMOVE');
                }}
            >
                <CloseButton />
            </CloseButtonArea>
            <ProductTitle>{product}</ProductTitle>
            <ShortText
                dangerouslySetInnerHTML={{
                    __html: shortText,
                }}
            />
            <p>
                <ShowMoreButton
                    bg={buttonsColor}
                    onClick={() =>
                        showPopup('PRODUCT_DESCRIPTION', {
                            params: { productId },
                            text: longText,
                            url,
                            product,
                        })
                    }
                >
                    Læs mere
                </ShowMoreButton>
            </p>
        </Container>
    );
};

export default ProductTips;
