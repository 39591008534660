import { useSelector } from 'react-redux';

import betSlipSelector from 'common/selectors/betSlipSelector';
import { combinationsCountBy } from 'common/selectors/combinationsCountSelector';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import useServerTimeQuery from 'features/EventBoard/hooks/useServerTimeQuery';
import { extractMultitrackTrackCodes } from 'features/EventBoard/server/calendar';
import useBetPicks from '../hooks/useBetPicks';
import { getBetPicksWithRaces } from '../../../common/selectors/betTableSelector';

const useSelections = () => {
    const {
        product,
        raceDay,
        trackNames,
        isMultitrack,
        races: serverRaces,
        trackCode,
        productId,
        race,
        isVProduct,
    } = useTrackPage();

    //@type {UIRaceObject}
    //@see features/Spilklub2/components/BetSlip.jsx
    const races = serverRaces.map(race => ({
        ...race,
        trackCode: race.hostTrack?.code || trackCode,
        hostTrack: { id: race.hostTrack?.id ?? 0, code: race.hostTrack?.code ?? '' },
        horses: race.horses.map(horse => ({ ...horse, scratched: horse.vpPool.scratched })),
    }));

    const { serverTime } = useServerTimeQuery();

    const combinedTrackName = trackNames.join(' / ');

    const firstVRace = races[0];

    const { singleRaceBetPicks, raceReservesMap: reserves } = useBetPicks({
        raceDay,
        trackCode,
        productId,
        race,
    });

    const betSlip = useSelector(betSlipSelector);

    const combinationsCount = useSelector(state =>
        combinationsCountBy(state, {
            productId,
            trackCode,
            date: raceDay.raceDayDate,
            raceIndex: race.index,
            races,
        })
    );

    const minimumPicksValid = betSlip.checkMinimumPicks({
        date: raceDay.raceDayDate,
        trackCode,
        productId: productId,
        races,
    });

    const maximumPicksValid = betSlip.checkMaximumPicks({
        date: raceDay.raceDayDate,
        trackCode,
        productId: productId,
        races,
    });

    const multitrackCodes = extractMultitrackTrackCodes(raceDay, product.id);

    const strictBet = useSelector(state => state.BetSlip.strictMode);

    const betPicksWithRaces = useSelector(state =>
        getBetPicksWithRaces(state, {
            date: raceDay.raceDayDate,
            trackCode,
            product,
            races: serverRaces,
        })
    );

    return {
        product,
        trackCode,
        isVProduct,
        serverTime,
        raceDay,
        date: raceDay.date,
        firstVRace,
        races,
        reserves,
        combinationsCount,
        combinedTrackName,
        singleRaceBetPicks,
        betSlip,
        minimumPicksValid,
        maximumPicksValid,
        isMultitrack,
        multitrackCodes,
        strictBet,
        betPicksWithRaces,
    };
};

export default useSelections;
