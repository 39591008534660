import React from 'react';

const ActivePoolsCount = ({ activePools }: { activePools: String[] }) => {
    const count = activePools ? activePools.length : 0;

    return (
        <>
            {count
                ? count +
                  (count === 1
                      ? ` aktivt spil - ` + activePools.map((p) => ` ${p}`)
                      : ` aktive spil - ` + activePools.map((p) => ` ${p}`))
                : `Ingen aktive spil`}
        </>
    );
};

export default ActivePoolsCount;
