import React from 'react';

import { Label } from 'features/TrackPage/components/styled';
import { ProductId } from 'features/EventBoard/server/calendar';
import ProductButtons from 'features/TrackPage/components/Products/ProductButtons';

import useActiveTrack from 'features/ResultsNew/hooks/useActiveTrack';

interface ProductsRowProps {
    onSelect: (productId: ProductId) => void;
    showLabels?: boolean;
    productId: ProductId;
}

const ProductsRow = ({ onSelect, showLabels = true, productId = 'V' }: ProductsRowProps) => {
    const { activeTrack } = useActiveTrack();

    return activeTrack ? (
        <>
            {showLabels && <Label>Spiltype</Label>}

            <ProductButtons
                data={activeTrack.sortedProducts}
                loading={false}
                selectedProductId={productId}
                onSelect={onSelect}
                disabled={false}
                legacyMode={false}
            />
        </>
    ) : null;
};

export default ProductsRow;
