import { create } from 'zustand';
import { Shortcut } from 'features/EventBoard/server/calendar';

interface ShortcutStore {
    shortcut: Shortcut | null;
    setShortcut(shortcut: Shortcut | null): void;
}

const useShortcutState = create<ShortcutStore>()(set => ({
    shortcut: null,
    setShortcut: (shortcut: Shortcut | null) => set({ shortcut }),
}));

export default useShortcutState;
