import React from 'react';
import FlexWrapper from 'ui/FlexWrapper';
import Text from 'ui/Text';
import { byPlatform } from 'utils/platforms';
import {
    NOT_V_SIMILAR_PRODUCTS,
    PRODUCTS_MIXED_VIEW,
    PRODUCTS_WITHOUT_PODDS,
} from '../';
import styled from 'styled-components';

const FlexWrapperModified = styled(FlexWrapper)`
    & > * {
        flex: unset;
    }
`;

const WinnerRowHeader = ({ product }) => {
    return (
        <FlexWrapperModified
            width="auto"
            direction="row"
            padding={byPlatform('5px 10px', '5px 20px', '5px 20px')}
            align="start"
            style={{
                background: '#9198aa',
            }}
        >
            <Text
                white
                size={byPlatform('x05', 13, 13)}
                padding="0px"
                width={byPlatform('40px', '50px', '50px')}
                align="center"
            >
                {NOT_V_SIMILAR_PRODUCTS.includes(product.id) ? 'PLAC.' : 'Afd.'}
            </Text>

            <Text
                white
                size={byPlatform('x05', 13, 13)}
                padding="0px"
                width={byPlatform('140px', '300px', '300px')}
                align="left"
            >
                HEST/KUSK
            </Text>

            {!NOT_V_SIMILAR_PRODUCTS.includes(product.id) &&
            !PRODUCTS_MIXED_VIEW.includes(product.id) ? (
                <Text
                    align="left"
                    white
                    size={byPlatform('x05', 13, 13)}
                    padding="0px"
                    width={byPlatform('40px', '100px', '100px')}
                >
                    {product.id.toString().toUpperCase()}%
                </Text>
            ) : null}

            {/* For simple products the V odds are displayed before the P odds */}
            {NOT_V_SIMILAR_PRODUCTS.includes(product.id) && (
                <Text
                    align="left"
                    white
                    size={byPlatform('x05', 13, 13)}
                    padding="0px"
                    width={byPlatform('60px', '100px', '100px')}
                >
                    V-ODDS
                </Text>
            )}

            {NOT_V_SIMILAR_PRODUCTS.includes(product.id) ? (
                !PRODUCTS_WITHOUT_PODDS.includes(product.id) &&
                !PRODUCTS_MIXED_VIEW.includes(product.id) ? (
                    <Text
                        white
                        size={byPlatform('x05', 13, 13)}
                        padding="0px"
                        width={byPlatform('60px', '100px', '100px')}
                        align="left"
                    >
                        P-ODDS
                    </Text>
                ) : null
            ) : !PRODUCTS_MIXED_VIEW.includes(product.id) ? (
                <Text
                    white
                    size={byPlatform('x05', 13, 13)}
                    padding="0px"
                    width={byPlatform('60px', '100px', '100px')}
                    align="left"
                    i
                >
                    Systemer
                </Text>
            ) : null}

            {/* For V-like products the V odds are displayed after systems */}
            {!NOT_V_SIMILAR_PRODUCTS.includes(product.id) && (
                <Text
                    align="left"
                    white
                    size={byPlatform('x05', 13, 13)}
                    padding="0px"
                    width={byPlatform('60px', '100px', '100px')}
                >
                    V-ODDS
                </Text>
            )}

            {!NOT_V_SIMILAR_PRODUCTS.includes(product.id) &&
            !PRODUCTS_MIXED_VIEW.includes(product.id) ? (
                <Text
                    align="left"
                    white
                    size={byPlatform('x05', 13, 13)}
                    padding="0px"
                    width={byPlatform('60px', '100px', '100px')}
                >
                    Værdi
                </Text>
            ) : null}

            <Text
                white
                size={byPlatform('x05', 13, 13)}
                padding="0px"
                width={byPlatform('60px', '100px', '100px')}
                align="center"
            >
                TID
            </Text>
        </FlexWrapperModified>
    );
};

export default WinnerRowHeader;
