import React from 'react';
import styled from 'styled-components';
import Icon from 'ui/Icon';
import play from 'icons/white-play.png';
import { connect } from 'react-redux';
import { COLORS } from 'themes/index';
import { priority } from 'configs/layout';

const animationSpeed = '0.4s';

const IconContainer = styled.div`
    transition: all ease ${animationSpeed};
    position: fixed;
    bottom: 20px;
    width: 60px;
    height: 60px;
    left: 100px;
    right: auto;
    z-index: ${priority.streamPlayButton};
    border-radius: 50%;
    background: ${COLORS.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid ${COLORS.white};
    &:hover img {
        transform: scale(1.1);
    }
    &.hidden {
        transform: scale(0);
    }
`;
const StyledIcon = styled(Icon).attrs({
    src: play,
})`
    transition: all ease ${animationSpeed};
    margin-left: 5px;
    &.hidden {
        transform: scale(0);
    }
`;

const PLayStreamButton = (props) => {
    return (
        <IconContainer
            className={props.isFloatingStreamShown ? 'hidden' : ''}
            onClick={props.playStream}
        >
            <StyledIcon
                className={props.isFloatingStreamShown ? 'hidden' : ''}
            />
        </IconContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        isFloatingStreamShown: state.StreamVideo.isFloatingStreamShown,
    };
};

export default connect(mapStateToProps)(PLayStreamButton);
