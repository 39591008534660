import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import RacesCarusel from 'features/RacesCarusel';
import { setHighlightState, showModal } from 'common/actions/uiActions';
import OverviewQuickPick from 'features/QuickPick/OverviewQuickPick';
import { byPlatform, isTerminal } from 'utils/platforms';
import { fetchPoolList, setProductShortcut } from 'features/AISDataProvider/actions';
import NotificationConductor from 'common/conductors/NotificationConductor';
import AISDataProvider from 'features/AISDataProvider';
import { isMobile } from 'react-device-detect';
import axios from 'axios';
import { getDefaultRace } from 'common/selectors/racesSelector';
import getTexts from 'utils/localization';
import { isModalShown } from 'common/selectors/uiSelector';
import {
    checkURL,
    isOnPath,
    history,
    isDirectEventLink,
    extractEventParams,
    showPinPopup,
} from 'utils/navigation';
import { mitIdErrorHandler, mitIdSuccessHandler } from 'features/Signup/helpers';
import { isUserLoggedIn } from 'common/actions/authActions';
import TerminalBetslip from 'features/BetSlip/platforms/Terminal';
import { terminal } from 'configs/layout';
import persistentStorage from 'common/storage';
import EventBoard from 'features/EventBoard';
import { NEW_TRACK_PAGE } from 'configs/main';
import Shortcuts from 'features/EventBoard/components/Shortcuts';
import SpilklubGroupsSection from 'features/Spilklub2/components/FocusGroups';
import Divider from 'ui/Divider';
import { isSpilklubSelectionsMode } from 'common/selectors/betSlipSelector';

import LegacyAdaptions from 'features/EventBoard/components/LegacyAdaptions';
import HorseVideos from 'features/HorseVideos';
import TrackBar from 'features/EventBoard/components/TrackBar';
import { gotEventError } from 'common/selectors/raceDaySelector';
import PageContainer from 'ui/PageContainer';
import Navigation from './components/Navigation';
import { scrollToTop } from 'utils/DOM';

const t = getTexts();

class Home extends React.Component {
    state = {
        fastTrackUrl: '',
    };

    componentDidMount() {
        if (!NEW_TRACK_PAGE) {
            this.props.fetchPoolList();
        }

        this.checkCprConfirmUrl();
        this.checkPinSettingUrl();
        this.checkSignupURL();
        // if (isMobile) {
        this.checkLoginURL();
        // }
        this.checkMitidRedirect();
        this.showHighlightHandler();

        !isMobile &&
            this.props.authorized &&
            axios
                .post('/heste/media/fasttrack/getUrl')
                .then(({ data }) => {
                    data.success && this.setState({ fastTrackUrl: data.data });
                })
                .catch(e => {
                    console.log(`Impossible to get fast track URL. ${e}`);
                });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.raceDaySelected !== this.props.raceDaySelected) {
            this.showHighlightHandler();
        }

        if (!prevProps.raceDaySelected && this.props.raceDaySelected) {
            scrollToTop();
        }
    }
    componentWillUnmount() {
        this.props.setHighlightState(false);
    }

    checkCprConfirmUrl = () => {
        const path = history.location.pathname.replace('/', '');
        if (path.indexOf('mitid-verify/confirm') > -1) {
            const idp = this.props?.match?.params?.type || '';
            this.props.showCprConfirmationModal(idp);
        }
    };

    checkPinSettingUrl = () => {
        // user forgot pin and used mitid in order to create a new one
        const path = history.location.pathname.replace('/', '');
        if (path.indexOf('mitid-verify/forgotpin') > -1) {
            this.props.showSetPinModal();
        }
    };

    checkSignupURL = () => {
        const path = history.location.pathname.replace('/', '');
        if (['opret-konto', 'opret'].includes(path)) {
            this.props.showSignupModal();
        }
    };

    checkLoginURL = () => {
        const path = history.location.pathname.replace('/', '');
        if (
            ['log-ind', 'login', 'testing/log-ind'].includes(path) &&
            !this.props.isLoginModalShown
        ) {
            if (isOnPath('/testing/log-ind')) {
                persistentStorage.save({ isItInitialLogin: true });
            }

            this.props.showLoginModal();
        }
    };

    checkMitidRedirect = () => {
        if (checkURL('mitid-verify/success')) {
            mitIdSuccessHandler(
                this.props.showModal,
                this.props.history,
                this.props.match,
                this.props.isUserLoggedIn,
                this.props.showPinPopup
            );
        }

        if (checkURL('mitid-verify/error')) {
            mitIdErrorHandler(this.props.showModal, this.props.history);
        }
    };

    onClickHandler = e => {
        console.log(e);
        NotificationConductor.error(t.Live.authErrorMessage);
    };

    showHighlightHandler = () => {
        const showRaceCalendarLayout =
            this.props.raceDaySelected || isDirectEventLink() || isTerminal;
        this.props.setHighlightState(!showRaceCalendarLayout);
    };

    renderTrackList = () => {
        return <EventBoard />;
    };

    render() {
        const { raceDaySelected, history, defaultRace, isBetBuddySelectionsMode, gotEventError } =
            this.props;

        const raceEventParams = extractEventParams();

        const showRaceCalendarLayout =
            raceDaySelected || isDirectEventLink() || isBetBuddySelectionsMode || isTerminal;

        return (
            <div data-test-id="home-page-betting-root">
                <TrackBar />

                <PageContainer padding={byPlatform('0', '0 30px 30px')}>
                    {isTerminal && (
                        <div style={{ position: 'sticky', top: '140px' }}>
                            <TerminalBetslip />
                        </div>
                    )}
                    {isTerminal ? (
                        <div style={{ width: terminal.homepage.wrapperWidth }}>
                            <Navigation gotEventError={gotEventError} />
                        </div>
                    ) : showRaceCalendarLayout ? (
                        <Navigation showLabels={!isMobile} gotEventError={gotEventError} />
                    ) : (
                        <>
                            {this.renderTrackList()}
                            <Divider margin={'35px 0 15px'} color={'#DFE3ED'} />
                        </>
                    )}

                    {!showRaceCalendarLayout && (
                        <>
                            <Shortcuts />
                            <Divider margin={'35px 0 15px'} color={'#DFE3ED'} />
                        </>
                    )}

                    {!NEW_TRACK_PAGE && <LegacyAdaptions />}

                    {!NEW_TRACK_PAGE && showRaceCalendarLayout && (
                        <AISDataProvider
                            key={`${raceEventParams?.date}+${raceEventParams?.track}+${raceEventParams?.product}`}
                            urlParams={raceEventParams ? raceEventParams : {}}
                            history={history}
                        />
                    )}
                    {!NEW_TRACK_PAGE && showRaceCalendarLayout && (
                        <RacesCarusel
                            raceIndex={
                                raceEventParams && raceEventParams.race
                                    ? raceEventParams.race - 1
                                    : defaultRace
                                    ? defaultRace.index
                                    : 0
                            }
                        />
                    )}

                    <SpilklubGroupsSection isTrackMode={showRaceCalendarLayout} />

                    <OverviewQuickPick
                        size={byPlatform(2, 3)}
                        isTrackQuickPick={showRaceCalendarLayout}
                    />

                    {!showRaceCalendarLayout && <HorseVideos />}
                </PageContainer>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isLoginModalShown: isModalShown(state, 'LOGIN'),
    authorized: state.auth.login.success,
    productShortcut: state.AISDataProvider.productShortcut,
    raceDaySelected: state.AISDataProvider.raceDaySelected,
    defaultRace: getDefaultRace(state),
    authPending: state.auth.pending,
    pathname: history.location.pathname,
    isBetBuddySelectionsMode: isSpilklubSelectionsMode(state),
    gotEventError: gotEventError(state),
});

const mapDispatchToProps = dispatch => {
    return {
        showUserAreaModal: () => {
            dispatch(showModal('USER_AREA_MENU'));
        },
        showSetPinModal: () => {
            dispatch(showModal('PIN_SETTING', 1));
        },
        setProductShortcut: product => {
            dispatch(setProductShortcut(product));
        },
        showCprConfirmationModal: idp => {
            dispatch(
                showModal('CPR_CONFIRMATION', 1, {
                    openedByInitialURL: true,
                    idp,
                })
            );
        },
        showSignupModal: () => {
            dispatch(showModal('SIGNUP', 1, { openedByInitialURL: true }));
        },
        showLoginModal: () => {
            dispatch(showModal('LOGIN', 1, { openedByInitialURL: true }));
        },
        showModal: (modalId, priority = 0, modalData) => {
            dispatch(showModal(modalId, priority, modalData));
        },
        isUserLoggedIn: () => dispatch(isUserLoggedIn()),
        fetchPoolList: () => {
            dispatch(fetchPoolList());
        },
        setHighlightState: state => dispatch(setHighlightState(state)),
        showPinPopup: () => dispatch(showPinPopup()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
