import { get, post, del, put } from './RequestHandler';
import { Subscription } from './model/Subscription';
import { GroupMember } from './model/Group';
import { createNullCouponDraft } from './model/CouponDraft';
import { PoolStatus } from './model/Pool';

//////////////////// Input ////////////////////

export interface UserGroupsInput {
    userId: number;
    pageNumber: number;
    pageSize: number;
}

export interface FilteredGroupsInput {
    maxPrice?: number;
    minPrice?: number;
    pageNumber: number;
    pageSize?: number;
    product?: string;
    search?: string;
    userId: number;
}

export interface GetSubscriptionInput {
    userId: number;
    groupId: number;
    product?: string;
}

export interface SMSNotificationInput {
    active: boolean;
    userId: number;
    groupId: number;
}

export interface CouponDraftInput {
    avatarUrl: string;
    deadlineTime: number;
    publicGroup: boolean;
    groupName: string;
    groupOwnerId: number;
    maxTotalBetAmount: number;
    product: string;
    trackCode: number;
    quickPick: boolean;
    strictBet: boolean;
    raceDay: string;
    sharePrice: number;
    sharesLimit: number;
    shares: Share[];
    numberOfBoughtShares: number;
    combinationNumber: number;
    combinationPrice: number;
    legs: any; //@TODO fix BetDraftInput.legs any type
}

export interface BuyShareInput {
    poolId: number;
    shareNumber: number;
    userId: number;
}

//@TODO Extend Legs interface (one attribute name is diffetent)
//@TODO Extend CouponDraftInfo interface (almost all attributes are same)
export interface CreateCouponInput {
    poolId: number;
    externalPoolId: number;
    combinationNumber: number;
    combinationPrice: number;
    strictBet: boolean;
    quickPick: boolean;
    smartLyn: boolean;
    maxTotalBetAmount?: number;
    legs: {
        legNumber: number;
        selected15Horses: string;
        firstReserve: number;
        secondReserve: number;
    }[];
}

export interface UpdateCouponInput extends CreateCouponInput {
    couponId: number;
    externalCouponID?: number;
}

export interface UpdateGroupInput {
    poolTemplateId: number;
    name?: string;
    avatarUrl?: string;
    groupOwnerId?: number;
    created?: string;
    description?: string;
    smsEnabled?: boolean;
    membersCount?: number;
    groupClosed?: boolean;
    publicGroup?: boolean;
    settings?: PoolSettings;
}

//////////////////// Output ///////////////////

export interface Response {
    statusCode: number;
    errorMessage: string;
    success: boolean;
}

export interface DataResponse<T> extends Response {
    data: T;
}

export interface GroupsOutput {
    groups: PoolTemplate[];
    pageNumber: number;
    pageSize: number;
    totalGroupsNumber: number;
    totalNumberOfPages: number;
    userId: number;
}

export interface CouponDraftOutput {
    poolId: number;
    poolHash: string;
}

export interface GroupInfoOutput {
    poolTemplate: PoolTemplate;
    activeBets: Bet[];
    finishedBets: Bet[];
}

export interface CouponDraftInfoOutput extends CouponDraftInfo {
    legs: LegDetailed[];
}

export interface SubscriptionsOutput {
    data: Subscription[];
}

export interface PoolExtendedInfoOutput {
    poolId: number;
    externalPoolId: number;
    productName: string;
    tracks: Track[];
    date: [string, string, string]; // year, month without zeros, day
    multiTrackId: number;
    coupons: CouponDraftInfoOutput[];
    results: LegResult[];
    races: PoolRace[];
}

export const createNullPoolExtendedInfo = () => ({
    poolId: 0,
    externalPoolId: 0,
    productName: '',
    tracks: [
        {
            trackId: 0,
            track: {
                code: '',
                domesticText: '',
                englishText: null,
            },
            country: '',
        },
    ],
    date: ['', '', ''], // year, month without zeros, day
    multiTrackId: 0,
    coupons: [createNullCouponDraft()],
    results: [],
    races: [],
});

//////////////////// DTO's ////////////////////

export interface PoolTemplate {
    poolTemplateId: number;
    name: string;
    avatarUrl: string;
    groupOwnerId: number;
    created: string;
    description?: string;
    smsEnabled: boolean;
    membersCount: number;
    groupClosed: boolean;
    publicGroup: boolean;
    groupMember: boolean;
    boughtSharesNumber?: number;
    activePools?: String[];
    settings: PoolSettings;
}

export interface PoolSettings {
    sharePrice: number;
    sharesLimit: number;
    deadlineTime: number;
}

export interface Pool {
    poolId: number;
    externalPoolId: number;
    poolSettings: PoolSettings;
    externalCouponId: number;
    poolHash: string;
    created: string;
    status: PoolStatus;
    payout: Payout;
    totalAmount: number;
    winAmount?: number;
    placedCouponsAmount: number;
    poolTemplate: PoolTemplate;
    shares: Share[];
    poolCommissionPercent: number;
    shareCommissionAmount: number;
}

export interface Payout {
    poolId: number;
    poolLeftOverAmount: number;
    poolSpentAmount: number;
    poolTotalWin: number;
    totalPoolAmount: number;
    userId: number;
    userWin: number;
}

export interface Share {
    sharingId: number;
    poolId: number;
    userId: number;
    numberOfShares: number;
    totalAmount: number;
    boughtBySubscription: boolean;
}

export interface Bet {
    pool: Pool;
    externalPoolInfo: ExternalPoolInfo;
    postTime: string;
    postTimeUtc: string;
}

export interface ExternalPoolInfo {
    externalPoolId: number;
    poolInfo: {
        raceDate: string;
        trackId: number;
        trackName: string;
        product: string;
        raceNumber: number;
    };
}

export interface CouponDraftInfo {
    poolId: number;
    externalPoolId: number;
    combinationNumber: number;
    combinationPrice: number;
    couponAmount: number;
    strictBet: boolean;
    quickPick: boolean;
    smartLyn: boolean;
    maxTotalBetAmount: number;
    externalCouponID: number;
    couponId: number;
    winAmount: number;
    userWinAmount: number;
}

export interface Leg {
    legNumber: number;
    marks: string;
    firstReserve: string;
    secondReserve: string;
    open: boolean;
}

export interface LegDetailed extends Leg {
    trackId: number;
    trackCode: string;
    raceNumber: number;
}

export interface Result {
    couponId: number;
    results: LegResult[];
}

export interface LegResult {
    legNumber: number;
    raceDate: Date;
    trackId: number;
    raceNumber: number;
    startNumber: number;
    place: number;
    driverName: string;
    driverSurname: string;
    horseName: string;
    odds: number;
    stakeDistributionPercent: number;
    systems: number;
    value: 2;
}

export interface PoolRace {
    raceNumber: number;
    trackId: number;
    leg: number;
    postTime: string[];
    postTimeUTC: string[];
    starts: Horse[];
}

export interface Horse {
    startNr: number;
    horseNameAndNationality: string;
    scratched: boolean;
}

export interface Track {
    trackId: number;
    track: {
        code: string;
        domesticText: string;
        englishText: string | null;
    };
    country: string;
}

export const createCouponDraft = (
    payload: CouponDraftInput
): Promise<DataResponse<CouponDraftOutput>> => {
    return post('createBetDraft', payload);
};

export const updateGroup = (
    payload: UpdateGroupInput
): Promise<DataResponse<CouponDraftOutput>> => {
    return put('updateGroup', payload);
};

export const joinGroup = (betId: number, userId: number): Promise<DataResponse<boolean>> => {
    return put('joinGroup', {}, { betId, userId });
};

export const leaveGroup = (groupId: number, userId: number): Promise<DataResponse<boolean>> => {
    return put('leaveGroup', {}, { groupId, userId });
};

export const getUserGroups = (input: UserGroupsInput): Promise<DataResponse<GroupsOutput>> => {
    return get('getUserGroups', input);
};

export const getPublicGroups = (input: UserGroupsInput): Promise<DataResponse<GroupsOutput>> => {
    return get('getPublicGroups', input);
};

export const getCaptainGroups = (input: {
    userId: number;
}): Promise<DataResponse<GroupsOutput>> => {
    return get('getCaptainGroups', input);
};

export const getFilteredGroups = (
    input: FilteredGroupsInput
): Promise<DataResponse<GroupsOutput>> => {
    return get('getFilteredGroups', input);
};

export const getBetInfoByHash = (poolHash: string): Promise<DataResponse<Bet>> => {
    return get('getBetInfo', { poolHash });
};

export const getGroupInfo = (groupId: number): Promise<DataResponse<GroupInfoOutput>> => {
    return get('getGroupInfo', { groupId });
};

export const getGroupMembers = (groupId: number): Promise<DataResponse<GroupMember[]>> => {
    return get('getGroupMembers', { groupId });
};

export const getCouponDraftInfo = (
    bbBetId: number
): Promise<DataResponse<CouponDraftInfoOutput>> => {
    return get('getCouponDraftInfo', { bbBetId });
};

export const getBetInfoById = (bbBetId: number): Promise<DataResponse<Bet>> => {
    return get('getBetInfoById', { bbBetId });
};

export const buyShare = (input: BuyShareInput): Promise<DataResponse<Pool>> => {
    return post('buyShare', undefined, input);
};

export const fetchSubscriptions = (
    groupId: number,
    userId: number
): Promise<DataResponse<Subscription[]>> => {
    return get('subscription', { groupId, userId });
};

export const getSubscriptions = (
    subscriptions: Subscription[]
): Promise<DataResponse<Subscription[]>> => {
    return post('subscription', subscriptions);
};

export const enableSmsNotification = (
    active: boolean,
    groupId: number,
    userId: number
): Promise<DataResponse<boolean>> => {
    return post(
        'enableSmsNotification',
        {},
        {
            active,
            groupId,
            userId,
        }
    );
};

export const deleteSubscriptions = (
    groupId: number,
    userId: number,
    product?: string
): Promise<DataResponse<boolean>> => {
    return del('subscription', {
        groupId,
        product,
        userId,
    });
};

export const getCouponResults = (couponId: number): Promise<DataResponse<Result>> =>
    get('getCouponResults', { couponId }).then(response => {
        // add couponId to output
        if (!response.success) {
            throw response.errorMessage;
        }
        return { ...response, data: { couponId, results: response.data } };
    });

export const placeBet = (userId: number, betId: number): Promise<DataResponse<{}>> => {
    return post('placeBet', {}, { userId, bbBetID: betId });
};

export const getPoolExtendedInfo = (betId: number): Promise<DataResponse<PoolExtendedInfoOutput>> =>
    get('getPoolExtendedInfo', { bbBetId: betId });

export const updateCouponDraft = (
    updateDraftInput: UpdateCouponInput
): Promise<DataResponse<any>> => put('updateCouponDraft', updateDraftInput);

export const addCouponDraft = (couponDraftInput: CreateCouponInput): Promise<DataResponse<any>> =>
    post('addCouponDraft', couponDraftInput);

export const makeBet = (updateDraftInput: CreateCouponInput): Promise<DataResponse<any>> =>
    post('makeBet', updateDraftInput);

export default {
    createCouponDraft,
    getCaptainGroups,
    getUserGroups,
    getPublicGroups,
    getFilteredGroups,
    getGroupInfo,
    getCouponDraftInfo,
    getBetInfoById,
    getBetInfoByHash,
    buyShare,
    getSubscriptions,
    fetchSubscriptions,
    deleteSubscriptions,
    getCouponResults,
    getGroupMembers,
    updateCouponDraft,
    placeBet,
    makeBet,
    getPoolExtendedInfo,
    enableSmsNotification: enableSmsNotification,
    addCouponDraft,
};
