import React from 'react';
import { useDispatch } from 'react-redux';

import DatePicker2 from 'ui/DatePicker2';
import { CalendarControls } from 'ui/RaceNavigation';
import { ModalContainer, ModalTransparency } from 'ui/Modal';
import { FadeInSpan } from 'ui/animations/FadeIn2';
import { ResultCalendarLabel, ResultStateLabel } from './styled';

import useCalendarDate from 'features/ResultsNew/hooks/useCalendarDate';
import { setEditSelectionsInitializing } from 'features/AISDataProvider/actions';

import useModals from 'common/hooks/useModals';
import { SERVER_DATE_FORMAT, todayNative, toNativeDate, toServerDate } from 'utils/date';
import { byPlatform, isMobile } from 'utils/platforms';

const DateSelection = ({ isFirstVersion = true }) => {
    const {
        isNextDateAvailable,
        isPreviousDateAvailable,
        mDate,
        selectNextDate,
        selectPrevDate,
        isToday,
    } = useCalendarDate();

    const { show: showModal } = useModals();

    const showDatePickerModal = () => showModal('RESULTS_DATE_PICKER');

    return (
        <>
            {isToday && !isMobile && !isFirstVersion && (
                <ResultCalendarLabel
                    bold
                    size={byPlatform('14', '32')}
                    padding={byPlatform('10px 0 10px 10px', '10px 0 10px 20px')}
                    className={byPlatform('flex align-center')}
                >
                    Resultater
                </ResultCalendarLabel>
            )}
            <div className="flex space-between">
                {isToday && !isMobile && !isFirstVersion ? (
                    <ResultStateLabel
                        bold
                        size={byPlatform('14', '22')}
                        padding={byPlatform('10px 0 10px 10px', '10px 0 10px 20px')}
                        className={byPlatform('flex align-center')}
                    >
                        <FadeInSpan>Afsluttet</FadeInSpan>
                    </ResultStateLabel>
                ) : (
                    <ResultCalendarLabel
                        bold
                        size={byPlatform('14px', '32px')}
                        padding={byPlatform('10px 20px 10px 10px', '10px 0 10px 20px')}
                        className={byPlatform('flex align-center')}
                    >
                        <FadeInSpan>Resultater</FadeInSpan>
                    </ResultCalendarLabel>
                )}

                <CalendarControls
                    mCurrentDate={mDate}
                    showDatePickerModal={showDatePickerModal}
                    selectNextDate={selectNextDate}
                    selectPrevDate={selectPrevDate}
                    isNextDateAvailable={isNextDateAvailable}
                    isPrevDateAvailable={isPreviousDateAvailable}
                />
            </div>
        </>
    );
};

export const CalendarDatePicker = () => {
    const dispatch = useDispatch();

    const { date, setDate } = useCalendarDate();

    const { hide: hideModal } = useModals();

    const maxDate = todayNative();

    const minDate = false;

    const onConfirm = (date: Date) => {
        setDate(toServerDate(date));
        dispatch(setEditSelectionsInitializing(false));
        hideModal('RESULTS_DATE_PICKER');
    };

    if (isMobile) {
        return (
            <DatePicker2
                date={toNativeDate(date, SERVER_DATE_FORMAT)}
                onConfirm={onConfirm}
                minDate={minDate}
                maxDate={maxDate}
            />
        );
    }

    return (
        <ModalTransparency>
            <ModalContainer>
                <DatePicker2
                    date={toNativeDate(date, SERVER_DATE_FORMAT)}
                    onConfirm={onConfirm}
                    height={450}
                    width={500}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            </ModalContainer>
        </ModalTransparency>
    );
};

export default DateSelection;
