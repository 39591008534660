import React from 'react';
import { useParams } from 'react-router-dom';
import take from 'lodash/take';

import { CampaignPreview, getCampaignsBySection } from './index';
import useSingleCampaignQuery from './useSingleCampaignQuery';
import useCampaignsQuery, { Campaign, SectionName } from './useCampaignsQuery';

import { DOMAIN_PATH } from 'configs/main';
import {
    CampaignContainer,
    CampaignContent,
    CampaignRules,
    CampaignTitle,
    CampaignTopImage,
    Delimiter,
    Grid,
    OtherCampaignsContainer,
    OtherCampaignsTitle,
    CampaignVideo,
    CampaignRulesHeadline,
} from './styled';

import PageContainer from 'ui/PageContainer';
import FadeIn from 'ui/animations/FadeIn';
import Player from 'ui/YouTubeVideo/Player';
import { byPlatform } from 'utils/platforms';

const OtherCampaigns = ({ relatedCampaign }: { relatedCampaign: Campaign }) => {
    const { data: campaigns } = useCampaignsQuery();

    const news = getCampaignsBySection(campaigns, [SectionName.news]).filter(
        campaign => campaign.id !== relatedCampaign.id
    );

    if (relatedCampaign.section_name !== SectionName.news || news.length === 0) {
        return null;
    }

    return (
        <OtherCampaignsContainer>
            <Delimiter style={{ marginTop: byPlatform('24px', '68px') }} />
            <OtherCampaignsTitle>Andre nyheder</OtherCampaignsTitle>

            <Grid>
                {take(news, 2).map(campaign => (
                    <CampaignPreview campaign={campaign} />
                ))}
            </Grid>
        </OtherCampaignsContainer>
    );
};

const containerProps = {
    background: '#F6F8FC',
    padding: byPlatform('32px', '30px 80px'),
    boxSizing: 'border-box',
    maxWidth: '100%',
};

const SingleCampaign = () => {
    const { link } = useParams<{ link: string }>();

    const { data, isLoading } = useSingleCampaignQuery({ link });

    const campaign = data?.article ?? ({} as Campaign);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    return (
        <FadeIn>
            <CampaignTopImage src={DOMAIN_PATH + campaign.image} alt={campaign.header} />

            <PageContainer {...containerProps}>
                <CampaignContainer>
                    <CampaignTitle>{campaign.header}</CampaignTitle>

                    <CampaignContent // @ts-ignore
                        dangerouslySetInnerHTML={{ __html: campaign.description }}
                    />

                    {campaign.video ? (
                        <CampaignVideo>
                            <Player
                                videoLink={campaign.video}
                                width={'100%'}
                                height={byPlatform('190px', '370px')}
                            />
                        </CampaignVideo>
                    ) : null}

                    {campaign.term_text ? (
                        <CampaignRules>
                            <CampaignRulesHeadline>Regler for tilbuddet</CampaignRulesHeadline>
                            <div dangerouslySetInnerHTML={{ __html: campaign.term_text }} />
                        </CampaignRules>
                    ) : null}
                </CampaignContainer>

                <OtherCampaigns relatedCampaign={campaign} />
            </PageContainer>
        </FadeIn>
    );
};

export default SingleCampaign;
