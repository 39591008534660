import React from 'react';
import { useHistory } from 'react-router';
import GroupShortcut from './GroupShortcut';
import {
    ExpertRow,
    ExpertGroups,
    ExpertTextCol,
    ExpertPhoto,
    ExpertPhotoCol,
    StyledText,
} from './styled';
import { Group } from './server';
import { CMSImage } from '../../common/api/CMSAPI/utils';
import renderHTML from 'react-render-html';
import { isDesktop } from '../../utils/platforms';

interface ExpertProps {
    title: string;
    text: string;
    photoSrc: string;
    groups: Group[];
}

const ExpertItem = ({ title, text, photoSrc, groups }: ExpertProps) => {
    const history = useHistory();
    const navigate = () => history.push('/sådan-fungerer-spilklub25');

    if (!isDesktop) {
        return (
            <div onClick={navigate}>
                <StyledText bold black size={'x2'}>
                    {title}
                </StyledText>
                <StyledText black>{renderHTML(text)}</StyledText>
                <ExpertGroups>
                    {groups.map(group => (
                        <GroupShortcut group={group} />
                    ))}
                </ExpertGroups>
            </div>
        );
    }

    return (
        <ExpertRow onClick={navigate}>
            {
                <ExpertPhotoCol>
                    <ExpertPhoto src={CMSImage(photoSrc)} width={100} />
                </ExpertPhotoCol>
            }
            <ExpertTextCol>
                <StyledText bold black size={'x2'}>
                    {title}
                </StyledText>
                <StyledText black>{renderHTML(text)}</StyledText>
            </ExpertTextCol>
            <ExpertPhotoCol />
            <ExpertTextCol>
                <ExpertGroups>
                    {groups.map(group => (
                        <GroupShortcut group={group} />
                    ))}
                </ExpertGroups>
            </ExpertTextCol>
        </ExpertRow>
    );
};

export default ExpertItem;
