import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { SpilIdBarcodePreloader } from 'features/SpilID/styled';
import MainCardBG from 'images/spil-id/spil-id-background.png';
import Flex from 'ui/Flex';
import { ReactComponent as Bet25Logo } from 'images/product-logo/bet25-logo.svg';
import { ReactComponent as SpilIDLogo } from 'images/spil-id/spil-id-logo.svg';

const CARD_TYPE = {
    BARCODE: 'BARCODE',
    SPIL_ID: 'SPIL_ID',
};

const SpilCardWrapper = styled.div`
    width: 343px;
    margin: 0 auto;
    position: relative;
    padding-bottom: 60px;
    @media screen and (max-width: 350px) {
        width: calc(100% - 35px);
    }
`;

const cardStyle = css`
    border-radius: 12px;
    box-shadow: 0 5px 20px #1a2aa229;
    height: 209px;
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;
    padding: 20px;
    @media screen and (max-width: 350px) {
        height: 190px;
    }
`;

const styleForBackCard = css`
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    cursor: pointer;
`;

const styleForMainCard = css`
    position: relative;
    top: 80px;
    z-index: 2;
    cursor: auto;
`;

const BarcodeCard = styled.div`
    ${cardStyle};
    display: flex;
    justify-content: center;
    background: transparent linear-gradient(122deg, #ffffff 0%, #d5d5d5 100%) 0%
        0% no-repeat padding-box;
    ${styleForBackCard};
    ${({ activeCard }) =>
        activeCard === CARD_TYPE.BARCODE
            ? styleForMainCard
            : css`
                  ${styleForBackCard};
                  &:hover {
                      transform: translateY(-10px);
                  }
              `};
`;

const CardBackground = styled.div`
    ${cardStyle};
    background: url(${MainCardBG});
    background-size: cover;
    color: #FFFFFF;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    ${({ activeCard }) =>
        activeCard === CARD_TYPE.SPIL_ID
            ? styleForMainCard
            : css`
                  ${styleForBackCard};
                  &:hover {
                      transform: translateY(-10px);
                  }
              `};};
`;

const BarCodeImage = styled.img`
    background: url(${(props) => props.barcode});
    width: 224px;
    height: 40px;
    position: relative;
    top: 0;
    transition: height 300ms ease;
    ${({ active }) =>
        active &&
        css`
            position: absolute;
            top: 50%;
            left: 50%;
            box-sizing: border-box;
            //width: calc(100% - 40px);
            transform: translate(-50%, -50%);
            height: 100px;
        `}};
`;

const UserNameWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const StyledSpilIDLogo = styled(SpilIDLogo)`
    height: 40px;
    position: relative;
    right: 10px;
    fill: #fff;
`;

const SpilIdCard = ({
    pending,
    barcode,
    fullName = '',
    birthdate = '',
    hash = '',
}) => {
    const ready = !pending;
    const [activeCard, setActiveCard] = useState(CARD_TYPE.SPIL_ID);

    return (
        <SpilCardWrapper>
            <BarcodeCard
                activeCard={activeCard}
                onClick={() => setActiveCard(CARD_TYPE.BARCODE)}
            >
                {ready ? (
                    <BarCodeImage
                        active={activeCard === CARD_TYPE.BARCODE}
                        barcode={barcode}
                    />
                ) : (
                    <SpilIdBarcodePreloader />
                )}
            </BarcodeCard>
            <CardBackground
                activeCard={activeCard}
                onClick={() => setActiveCard(CARD_TYPE.SPIL_ID)}
            >
                <Flex
                    justify={'space-between'}
                    align={'flex-start'}
                    margin={'10px 0 0'}
                    cursor={
                        activeCard === CARD_TYPE.BARCODE ? 'pointer' : 'auto'
                    }
                >
                    <Bet25Logo style={{ height: 40, fill: '#fff' }} />
                    <StyledSpilIDLogo />
                </Flex>
                <Flex justify={'space-between'} align={'flex-end'}>
                    <UserNameWrapper>
                        <span className="text-bold">{fullName}</span>
                        <span>{birthdate.replace(/-/g, '.')}</span>
                    </UserNameWrapper>
                    <Flex
                        justify={'flex-end'}
                        align={'flex-end'}
                        direction={'column'}
                    >
                        <span className="text-bold">Spil-ID</span>
                        <span>{hash}</span>
                    </Flex>
                </Flex>
            </CardBackground>
        </SpilCardWrapper>
    );
};

export default SpilIdCard;
