import { PRODUCT_IDS, PRODUCTS_BETTING_RULES } from 'configs/products';
import Bet from 'common/DataObjects/Bet';
import Product from 'common/DataObjects/Product';

import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import { range } from 'utils/array';
import { NEW_TRACK_PAGE } from '../../configs/main';

/**
 * Counts how much starts are marked on each race
 * and multiplies mapped values.
 * Eg: user bets for 3 races.
 * First race contains 2 bets, second one contains 3 bets
 * and third one - 4 bets.
 * Then it will return 2 x 3 x 4 = 24 combination.
 *
 * Suits all V games and DD
 *
 * @param  {Bet}          currentRaceBet
 * @param  {Object.<Bet>} allRacesBets
 * @return {number}
 */
const multiplyRacesMarkCounts = (currentRaceBet, allRacesBets) => {
    const markedRaceIndexes = Object.keys(allRacesBets);

    if (!markedRaceIndexes.length) {
        return 0;
    }

    let res = markedRaceIndexes.map((raceIndex) => {
        const markedStarts = Object.keys(allRacesBets[raceIndex]);
        return markedStarts.length;
    });

    res = res.reduce((previousRaceMarksCount, currentRaceMarksCount) => {
        if (previousRaceMarksCount === undefined) {
            return currentRaceMarksCount;
        }
        if (previousRaceMarksCount !== undefined && currentRaceMarksCount === 0) {
            return previousRaceMarksCount;
        }
        return previousRaceMarksCount !== 0
            ? previousRaceMarksCount * currentRaceMarksCount
            : currentRaceMarksCount;
    });

    return res;
};
/**
 * If you select two participants, you play 1 combination.
 * If you select three participants, you play 3 combinations (3 x 2/2 = 3)
 * If you select four participants, you play 6 combinations (4 x 3/2 = 6)
 * If you select five participants, you play 10 combinations (5 x 4/2 = 10)
 *
 * @see    https://www.atg.se/tvilling/om-tvilling
 * @see    https://ecosys.atlassian.net/browse/DER-141
 * @param  {Bet}    raceBet
 * @return {number}
 */
const twinCounter = (raceBet) => {
    const startNumbers = Object.keys(raceBet);
    let simpleCombinationsCount = 0;
    let uHorseCombinationsCount = 0;
    let repeatabilityAccumulator = 0;

    startNumbers.forEach((startNr) => {
        startNr = parseInt(startNr, 10);
        const simpleBetExists = raceBet[startNr].indexOf(startNr) !== -1;
        if (simpleBetExists) {
            simpleCombinationsCount++;
        }
    });

    startNumbers.forEach((startNr) => {
        startNr = parseInt(startNr, 10);
        const uHorseBetExists = raceBet[startNr].indexOf('U') !== -1;
        if (uHorseBetExists) {
            // Same start / horse obviously can not represent a pair
            // So we reduce count of simple combinations if the horse
            // was marked as both - winner and its U-horse
            const simpleBetExists = raceBet[startNr].indexOf(startNr) !== -1;
            if (simpleBetExists) {
                repeatabilityAccumulator++;
            }

            uHorseCombinationsCount = simpleBetExists
                ? uHorseCombinationsCount + simpleCombinationsCount - repeatabilityAccumulator
                : uHorseCombinationsCount + simpleCombinationsCount;
        }
    });

    if (uHorseCombinationsCount === 0) {
        //console.log(simpleCombinationsCount);
        return simpleCombinationsCount === 2
            ? 1
            : simpleCombinationsCount * ((simpleCombinationsCount - 1) / 2);
    }

    return uHorseCombinationsCount;
};

/**
 * Amount of chosen winners * amount of chosen second place horses – amount
 * of horses picked for both first and second
 *
 * @param raceBet
 * @return {number}
 */
const kombCounter = (raceBet) => {
    const firstPlaces = [];
    const secondPlaces = [];
    const both = [];
    for (let [startNr, marks] of Object.entries(raceBet)) {
        if (marks.includes(1)) {
            firstPlaces.push(startNr);
        }
        if (marks.includes(2)) {
            secondPlaces.push(startNr);
        }
        if (marks.includes(1) && marks.includes(2)) {
            both.push(startNr);
        }
    }
    return firstPlaces.length * secondPlaces.length - both.length;
};

/**
 * Assume we have chosen to spike the winner in the race (horse 1),
 * have three horses in second place (2,3 and 5) and six horses in
 * third place (2,3,5,6,7 and 8). This gives us 1 x 3 x 6 = 18 rows.
 *
 * @see    https://www.atg.se/trio/om-trio
 * @param  {Bet}    raceBet
 * @return {number}
 */
const trioCounter = (raceBet) => {
    const bet = new Bet(raceBet);
    const [firstPlaces, secondPlaces, thirdPlaces] = bet.groupStartNumbersByPlaces();

    // values are the start numbers ordered by place
    const combinations = [];

    firstPlaces.forEach((startNrFirstPlace) => {
        secondPlaces.forEach((startNrSecondPlace) => {
            thirdPlaces.forEach((startNrThirdPlace) => {
                if (
                    startNrFirstPlace !== startNrSecondPlace &&
                    startNrFirstPlace !== startNrThirdPlace &&
                    startNrSecondPlace !== startNrThirdPlace
                ) {
                    combinations.push([startNrFirstPlace, startNrSecondPlace, startNrThirdPlace]);
                }
            });
        });
    });

    return combinations.length;
};

/**
 * @param  {Bet}    currentRaceBet
 * @return {number}
 */
const sumCounter = (currentRaceBet) => Object.keys(currentRaceBet).length;

const combinationCounters = {
    [PRODUCT_IDS.V]: sumCounter,
    [PRODUCT_IDS.P]: sumCounter,
    [PRODUCT_IDS.VP]: sumCounter,
    [PRODUCT_IDS.K]: kombCounter,
    [PRODUCT_IDS.V3]: multiplyRacesMarkCounts,
    [PRODUCT_IDS.V4]: multiplyRacesMarkCounts,
    [PRODUCT_IDS.V5]: multiplyRacesMarkCounts,
    [PRODUCT_IDS.V64]: multiplyRacesMarkCounts,
    [PRODUCT_IDS.V65]: multiplyRacesMarkCounts,
    [PRODUCT_IDS.V75]: multiplyRacesMarkCounts,
    [PRODUCT_IDS.T]: trioCounter,
    [PRODUCT_IDS.TV]: twinCounter,
    [PRODUCT_IDS.LD]: multiplyRacesMarkCounts,
    [PRODUCT_IDS.DD]: multiplyRacesMarkCounts, // only for single-track case
    [PRODUCT_IDS.V86]: multiplyRacesMarkCounts, // only for single-track case
    [PRODUCT_IDS.GS75]: multiplyRacesMarkCounts,
};

export default class Betslip {
    betsByDates = {};
    combinationPrice = 0;

    constructor(betsByDates, combinationPrice) {
        this.betsByDates = betsByDates;
        this.combinationPrice = combinationPrice;
    }

    getRaceSelection = ({ date, trackCode, productId, raceIndex, horseCounts }) => {
        if (!this.hasCombinations({ date, trackCode, productId, raceIndex })) {
            return {};
        }

        const selection = this.betsByDates[date][trackCode][productId][raceIndex];
        const horseCount = horseCounts ? horseCounts[raceIndex] : 0;

        return !isEmpty(horseCounts) ? pick(selection, range(horseCount, true)) : selection;
    };

    getAllRacesSelections = (date, trackCode, productId, horseCounts) => {
        if (
            this.betsByDates[date] &&
            this.betsByDates[date][trackCode] &&
            this.betsByDates[date][trackCode][productId]
        ) {
            const allRacesBets = this.betsByDates[date][trackCode][productId];

            if (horseCounts) {
                // remove horses that are not presented in the racing card
                return mapValues(allRacesBets, (raceBet, raceIndex) => {
                    return pick(raceBet, range(horseCounts[raceIndex], true));
                });
            }

            return allRacesBets;
        }
        return {};
    };

    countPickedRaces = ({ date, trackCode, productId }) => {
        const allRacesBets = this.getAllRacesSelections(date, trackCode, productId);
        let count = 0;
        Object.keys(allRacesBets).forEach((raceBetKey) => {
            if (Object.keys(allRacesBets[raceBetKey]).length > 0) {
                ++count;
            }
        });
        return count;
    };

    /**
     * Checks minimum horse picks for V product
     * Essentially we had simple rules only for V-products:
     *  - all the races must have at least 1 picked horse,
     *  - at least one horse must not be picked
     *
     * @see https://ecosys.atlassian.net/browse/DER-325
     *
     * @param  {Object}  parameters
     * @return {boolean}
     */
    checkMinimumPicks = (parameters) => {
        const { productId } = parameters;

        if (!PRODUCTS_BETTING_RULES[productId] || !Product.isVProduct(productId)) {
            return true;
        }
        // >= quick fixes V86 (sometimes gives 9 race picks)
        return this.countPickedRaces(parameters) >= PRODUCTS_BETTING_RULES[productId].races;
    };

    /**
     * Checks maximum horse picks for V product
     * Essentially we had simple rules only for V-products:
     *  - all the races must have at least 1 picked horse,
     *  - at least one horse must not be picked
     *
     * @see https://ecosys.atlassian.net/browse/DER-325
     *
     * @param  {Object}  parameters
     * @return {boolean}
     */
    checkMaximumPicks = (parameters) => {
        const { productId } = parameters;

        if (!PRODUCTS_BETTING_RULES[productId] || !Product.isVProduct(productId)) {
            return true;
        }

        const combinationsCount = this.countCombinations(parameters);
        // should not be possible to pick all the horses for V products
        return this.calculateMaxVCombinations(parameters) > combinationsCount;
    };

    static isExistingCombination(hashMap, parameters) {
        const instance = new Betslip(hashMap);
        return instance.hasCombinations(parameters);
    }

    hasCombinations = ({ date, trackCode, productId, raceIndex }) => {
        return !!(
            this.betsByDates[date] &&
            this.betsByDates[date][trackCode] &&
            this.betsByDates[date][trackCode][productId] &&
            this.betsByDates[date][trackCode][productId][raceIndex]
        );
    };

    countCombinations = (parameters) => {
        const bet = this.getRaceSelection(parameters),
            { date, trackCode, productId, horseCounts } = parameters;

        const combinationCountingStrategy = combinationCounters[productId];

        if (!productId) return 0;

        if (!combinationCountingStrategy) {
            console.error(
                `Implement and configure appropriate combination 
                 counter for ${productId} product. 
                 0 combinations returned by default.`
            );
            return 0;
        }

        return combinationCountingStrategy(
            bet,
            this.getAllRacesSelections(date, trackCode, productId, horseCounts)
        );
    };

    /**
     * Returns maximum combinations count
     * for all the races of V product
     * @param  {Object} parameters
     * @return {number}
     */
    calculateMaxVCombinations = (parameters) => {
        const horsesKey = NEW_TRACK_PAGE ? 'horses' : 'starts';
        return parameters.races.reduce(
            (accumulator, race) => race?.[horsesKey]?.length * accumulator,
            1
        );
    };

    /**
     *
     * @param  {number}   combinationsCount
     * @param  {number}   combinationPrice
     * @param  {Product}  product
     * @return {number}
     */
    calculateReceiptSum = (combinationsCount, combinationPrice, product) => {
        let sum = combinationsCount * combinationPrice;
        if (product.id === PRODUCT_IDS.VP) {
            sum = sum * 2;
        }
        return sum.toFixed(2);
    };
}
