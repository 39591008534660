import { createAction, createAsyncAction } from 'typesafe-actions';
import { AsyncReducerStatuses, GroupDraft, UIState } from './reducer';
import Group, { GroupMember } from './model/Group';
import { Subscription } from './model/Subscription';
import {
    Response,
    GetSubscriptionInput,
    SMSNotificationInput,
    PoolExtendedInfoOutput,
    CreateCouponInput,
    UserGroupsInput,
    CouponDraftInput,
    CouponDraftOutput,
    UpdateCouponInput,
    FilteredGroupsInput,
} from './server';
import { DeserializedGroupInfo } from './serializing';
import Results from './model/Results';
import Pool from './model/Pool';

//@see https://github.com/piotrwitek/typesafe-actions#api-docs-v4-v5-is-wip-189

// ASYNC

export interface ServerError extends Error {
    code: number;
}

export const createServerError = ({
    errorMessage,
    statusCode,
}: Response): ServerError => ({
    name: 'ServerError',
    message: errorMessage,
    code: statusCode,
});

export const createCouponDraftAsync = createAsyncAction(
    '@bet-buddy/CREATE_COUPON_DRAFT_REQUEST',
    '@bet-buddy/CREATE_COUPON_DRAFT_SUCCESS',
    '@bet-buddy/CREATE_COUPON_DRAFT_FAILURE'
)<CouponDraftInput, CouponDraftOutput, ServerError>();

export const fetchGroupsAsync = createAsyncAction(
    '@bet-buddy/FETCH_GROUPS_REQUEST',
    '@bet-buddy/FETCH_GROUPS_SUCCESS',
    '@bet-buddy/FETCH_GROUPS_FAILURE'
)<UserGroupsInput, Group[], ServerError>();

export const fetchFilteredGroupsAsync = createAsyncAction(
    '@bet-buddy/FETCH_FILTERED_GROUPS_REQUEST',
    '@bet-buddy/FETCH_FILTERED_GROUPS_SUCCESS',
    '@bet-buddy/FETCH_FILTERED_GROUPS_FAILURE'
)<FilteredGroupsInput, Group[], ServerError>();

export const fetchPublicGroupsAsync = createAsyncAction(
    '@bet-buddy/FETCH_PUBLIC_GROUPS_REQUEST',
    '@bet-buddy/FETCH_PUBLIC_GROUPS_SUCCESS',
    '@bet-buddy/FETCH_PUBLIC_GROUPS_FAILURE'
)<UserGroupsInput, Group[], ServerError>();

export const getGroupAsync = createAsyncAction(
    '@bet-buddy/GET_GROUP_REQUEST',
    '@bet-buddy/GET_GROUP_SUCCESS',
    '@bet-buddy/GET_GROUP_FAILURE'
)<number, DeserializedGroupInfo, ServerError>();

export const fetchGroupsCreatedByUserAsync = createAsyncAction(
    '@bet-buddy/FETCH_GROUPS_CAPTAIN_REQUEST',
    '@bet-buddy/FETCH_GROUPS_CAPTAIN_SUCCESS',
    '@bet-buddy/FETCH_GROUPS_CAPTAIN_FAILURE'
)<UserGroupsInput, Group[], ServerError>();

export const getGroupMembersAsync = createAsyncAction(
    '@bet-buddy/GET_GROUP_MEMBERS_REQUEST',
    '@bet-buddy/GET_GROUP_MEMBERS_SUCCESS',
    '@bet-buddy/GET_GROUP_MEMBERS_FAILURE'
)<number, GroupMember[], ServerError>();

export const fetchSubscriptionsAsync = createAsyncAction(
    '@bet-buddy/GET_SUBSCRIPTION',
    '@bet-buddy/GET_SUBSCRIPTION_SUCCESS',
    '@bet-buddy/GET_SUBSCRIPTION_ERROR'
)<GetSubscriptionInput, Subscription[], ServerError>();

export const enableSmsNotification = createAsyncAction(
    '@bet-buddy/SAVE_SMS_NOTIFICATION',
    '@bet-buddy/SAVE_SMS_NOTIFICATION_SUCCESS',
    '@bet-buddy/SAVE_SMS_NOTIFICATION_ERROR'
)<SMSNotificationInput, boolean, ServerError>();

export const getSubscriptionAsync = createAsyncAction(
    '@bet-buddy/SAVE_SUBSCRIPTION',
    '@bet-buddy/SAVE_SUBSCRIPTION_SUCCESS',
    '@bet-buddy/SAVE_SUBSCRIPTION_ERROR'
)<Subscription[], Subscription[], ServerError>();

export const deleteSubscriptions = createAsyncAction(
    '@bet-buddy/DELETE_SUBSCRIPTION',
    '@bet-buddy/DELETE_SUBSCRIPTION_SUCCESS',
    '@bet-buddy/DELETE_SUBSCRIPTION_ERROR'
)<GetSubscriptionInput, boolean, ServerError>();

export const updateCouponAsync = createAsyncAction(
    '@bet-buddy/UPDATE_COUPON',
    '@bet-buddy/UPDATE_COUPON_SUCCESS',
    '@bet-buddy/UPDATE_COUPON_ERROR'
)<UpdateCouponInput, boolean, ServerError>();

export const addCouponAsync = createAsyncAction(
    '@bet-buddy/ADD_COUPON',
    '@bet-buddy/ADD_COUPON_SUCCESS',
    '@bet-buddy/ADD_COUPON_ERROR'
)<CreateCouponInput, boolean, ServerError>();

export const placeBetAsync = createAsyncAction(
    '@bet-buddy/PLACE_BET',
    '@bet-buddy/PLACE_BET_SUCCESS',
    '@bet-buddy/PLACE_BET_ERROR'
)<CreateCouponInput, {}, ServerError>();

export const getPoolAsync = createAsyncAction(
    '@bet-buddy/GET_POOL_REQUEST',
    '@bet-buddy/GET_POOL_SUCCESS',
    '@bet-buddy/GET_POOL_FAILURE'
)<number, Pool, ServerError>();

export const getBetByHashAsync = createAsyncAction(
    '@bet-buddy/GET_BET_INFO_REQUEST',
    '@bet-buddy/GET_BET_INFO_SUCCESS',
    '@bet-buddy/GET_BET_INFO_FAILURE'
)<SetBetInfoPropsPayload, Pool, ServerError>();

export const getResultsAsync = createAsyncAction(
    '@bet-buddy/GET_RESULTS_REQUEST',
    '@bet-buddy/GET_RESULTS_SUCCESS',
    '@bet-buddy/GET_RESULTS_FAILURE'
)<number, Results, ServerError>();

export const getPoolDetailsAsync = createAsyncAction(
    '@bet-buddy/GET_POOL_DETAILS_REQUEST',
    '@bet-buddy/GET_POOL_DETAILS_SUCCESS',
    '@bet-buddy/GET_POOL_DETAILS_FAILURE'
)<number, PoolExtendedInfoOutput, ServerError>();

// SYNC

export const dropSubscriptionStatuses = createAction(
    '@bet-buddy/DROP_DELETE_STATUSES'
)();

export const cleanBetBuddyReducer = createAction(
    '@bet-buddy/CLEAN_BETBUDDY_REDUCER'
)();

export const dropPlaceBetStatus = createAction(
    '@bet-buddy/DROP_PLACE_BET_STATUSES'
)();

export const savePools = createAction('@bet-buddy/SAVE_POOLS')<Pool[]>();

export const saveGroupDraft = createAction('@bet-buddy/SAVE_GROUP_DRAFT')<
    GroupDraft
>();

export type SetUIPropsPayload = { [P in keyof UIState]?: UIState[P] };

export interface SetBetInfoPropsPayload {
    poolHash: string;
    activeBet?: boolean;
}

export const resetStatus = createAction('@bet-buddy/RESET_STATUS')<
    keyof AsyncReducerStatuses
>();

export const setUIProps = createAction('@bet-buddy/SET_UI_PROPS')<
    SetUIPropsPayload
>();

export const startPoolDataPolling = createAction(
    '@bet-buddy/START_POOL_DATA_POLLING'
)<number>();

export const stopPoolDataPolling = createAction(
    '@bet-buddy/STOP_POOL_DATA_POLLING'
)<number>();

export const clearGroupsReducer = createAction(
    '@bet-buddy/CLEAR_GROUPS_REDUCER'
)();

export const clearFilteredGroups = createAction(
    '@bet-buddy/CLEAR_FILTERED_GROUPS'
)();
