import styled from 'styled-components';

const ContentHeight = styled.div`
    flex: 0 1 auto;
    width: 100%;
    /* background: #efdeb1; */
`;

const FixedHeight = styled.div`
    flex-grow: 0;
    width: 100%;
    height: ${(props) => props.height || 0};
    min-height: ${(props) => props.height || 0};
    /* background: red; */
`;

const FullScreen = styled.div.attrs({ className: 'full-screen' })`
    position: relative;
    // height: 100%;
    display: flex;
    flex-flow: column;
    min-height: initial;
    height: ${({ height }) => height ?? 'auto'};
`;

const Fillable = styled.div`
    flex: 1 1 auto;
    min-height: initial;
`;

export { FullScreen, FixedHeight, ContentHeight, Fillable };
