import styled, { css } from 'styled-components';

export const defaultDuration = 200;

export const fadeInMixin = css`
    @keyframes ${({ animationName }) => `${animationName}`} {
        from {
            opacity: ${(props) => (props.fadeOut ? 1 : 0)};
        }

        to {
            opacity: ${(props) => (props.fadeOut ? 0 : 1)};
        }
    }
    opacity: 0;
    animation-duration: ${({ duration = defaultDuration }) => `${duration}ms`};
    animation-name: ${({ animationName }) => `${animationName}`};
    animation-delay: ${({ delay = 0 }) => `${delay}ms`};
    animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
    animation-fill-mode: ${({ mode = 'forwards' }) => mode};
    animation-direction: ${({ direction = 'normal' }) => direction};
`;

export const FadeInSpan = styled.span`
    ${fadeInMixin};
`;
