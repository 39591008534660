import {
    BETTING_HISTORY_REST_PATH,
    BETTING_REST_PATH,
    TERMINAL_BETTING_REST_PATH,
} from 'configs/rest';
import { IS_TERMINAL } from 'utils/platforms';

import axios from 'axios';

export function post(method, data, historyMode = false) {
    return request(
        'post',
        getUrlAccordingToPlatform(historyMode, method, IS_TERMINAL),
        data
    );
}

export function get(method, data, historyMode = false) {
    return request('get', getUrlAccordingToPlatform(historyMode, method), data);
}

function getUrlAccordingToPlatform(historyMode, method, terminalMode) {
    let url =
        (!historyMode ? BETTING_REST_PATH : BETTING_HISTORY_REST_PATH) + method;

    if (terminalMode) {
        url = TERMINAL_BETTING_REST_PATH + method;
    }

    return url;
}

function request(requestType, url, data) {
    const options = {
        method: requestType,
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
        data: data,
        url,
    };

    return axios(options).then((res) => res.data);
}
