import React from 'react';

import BetButton from 'features/TrackPage/components/RaceCard/Desktop/VRaceOverview/BetButton';
import SelectRowButton from 'features/TrackPage/components/RaceCard/Desktop/VRaceOverview/SelectRowButton';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';

import {
    SelectAllColumn,
    GameRow,
    RaceNumber,
    HorseButtonsContainer,
} from 'features/TrackPage/components/RaceCard/Desktop/VRaceOverview/styled';

const RaceRow = ({ race, index }) => {
    const { product } = useTrackPage();

    return (
        <GameRow>
            <RaceNumber>
                {product.name} - {index + 1}
            </RaceNumber>
            <HorseButtonsContainer>
                {race.horses
                    ? race.horses.map((horse, index) => {
                          return (
                              <BetButton
                                  key={index}
                                  horse={horse}
                                  race={race}
                                  scratched={horse.vpPool.scratched}
                                  //raceStarted={props.raceStarted}
                              />
                          );
                      })
                    : null}
            </HorseButtonsContainer>
            <SelectAllColumn>
                <SelectRowButton
                    race={race}
                    //raceStarted={props.raceStarted}
                />
            </SelectAllColumn>
        </GameRow>
    );
};

export default RaceRow;
