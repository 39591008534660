import styled from 'styled-components';
import { BACKGROUNDS } from 'themes/index.ts';
import { ReactComponent as CloseIconDef } from 'icons/close.svg';
import { ReactComponent as InfoIcon } from 'icons/info.svg';
import React, { useState } from 'react';

const icons = {
    info: InfoIcon,
};

const backgrounds = {
    info: BACKGROUNDS.accentAlt,
};

const AlertContainer = styled.div`
    height: 45px;
    width: 100%;
    background: ${({ type }) => backgrounds[type] || BACKGROUNDS.accentAlt};
    border-radius: 5px;
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 0 20px;
    box-sizing: border-box;
    position: relative;
`;

const AlertTitle = styled.div`
    display: flex;
    flex-direction: row;
    font-weight: 900;
    align-items: center;
    padding-right: 3px;
    svg {
        margin-right: 10px;
    }
`;

const CloseIcon = styled(CloseIconDef)`
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
`;

const Alert = ({ title, children, type, closable, styles = {} }) => {
    const [closed, setClosed] = useState(false);

    if (closed) {
        return null;
    }

    const IconComponent = icons[type];

    return (
        <AlertContainer
            style={styles.AlertContainer}
            type={type}
            closed={closed}
        >
            <AlertTitle>
                {IconComponent && <IconComponent />}
                {title}
            </AlertTitle>
            {children}
            {closable && <CloseIcon onClick={() => setClosed(true)} />}
        </AlertContainer>
    );
};

export default Alert;
