import React from 'react';

import { RaceDay, getRaceDays } from 'features/EventBoard/server/calendar';

import DateSelection from 'features/EventBoard/components/DateSelection';
import TrackSelection from 'features/EventBoard/components/TrackSelection';

import useCalendarQuery from 'features/EventBoard/hooks/useCalendarQuery';
import useCalendarDate from 'features/EventBoard/hooks/useCalendarDate';
import useRaceDayState from 'features/EventBoard/hooks/useRaceDayState';

import { SelectionScreenContainer } from './styled';

import { NEW_TRACK_PAGE } from 'configs/main';
import { PRODUCT_IDS } from 'configs/products';
import { generateEventPath, history } from 'utils/navigation';
import { getNextRaceNumber } from 'features/EventBoard/utils/common';

const SelectionScreen = () => {
    const { date, isToday } = useCalendarDate();
    const { data, isSuccess, isLoading } = useCalendarQuery({ date, useRefetchInterval: isToday });

    const { setRaceDay } = useRaceDayState();
    const onRaceDaySelected = (raceDay: RaceDay) => {
        if (NEW_TRACK_PAGE) {
            return history.push(
                generateEventPath({
                    date,
                    productId: PRODUCT_IDS.V,
                    track: raceDay.trackName,
                    race: getNextRaceNumber(
                        raceDay?.youthRaces,
                        raceDay?.racesInfo.length,
                        raceDay?.nextSaleOpeRaceNumber || 1,
                        1
                    ),
                })
            );
        }
        setRaceDay(raceDay);
    };

    const raceDays = isSuccess ? getRaceDays(data) : [];

    return (
        <>
            <SelectionScreenContainer>
                <DateSelection />

                <TrackSelection
                    onRaceDaySelected={onRaceDaySelected}
                    onProductSelected={() => {}}
                    isLoading={isLoading}
                    raceDays={raceDays}
                />
            </SelectionScreenContainer>
        </>
    );
};

export default SelectionScreen;
