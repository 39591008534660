import React from 'react';
import Wrapper from 'ui/Wrapper';
import Headline from 'ui/Headline';
import Text, { TextFrame } from 'ui/Text';
import FlexWrapper from 'ui/FlexWrapper';
import Spinner from 'ui/Spinner';
import horseImage from 'images/dk/hest-live.png';
import getTexts from 'utils/localization';
const t = getTexts();

class LiveWithReplays extends React.Component {
    constructor() {
        super();

        this.state = {
            loading: true,
        };
    }

    iframeReady = () => {
        this.setState({
            loading: false,
        });
    };

    render() {
        return (
            <Wrapper padding="20px 10px">
                <FlexWrapper direction="row">
                    <TextFrame pr={60}>
                        <Headline size="x2" condensed bold>
                            {t.Live.Desktop.Section3.title}
                        </Headline>

                        <Text lh={21} condensed>
                            {t.Live.Desktop.Section3.description}
                        </Text>
                    </TextFrame>

                    <Wrapper>
                        <img src={horseImage} width="500px" alt="hest" />
                    </Wrapper>
                </FlexWrapper>

                <Wrapper padding="20px" align="center">
                    {this.state.loading ? <Spinner size="x3" /> : null}

                    <iframe
                        src="https://replays.webstream.dk/?parent=derby25"
                        allowFullScreen
                        alt="replays"
                        title="replays"
                        width="100%"
                        height="1000px"
                        frameBorder="0"
                        onLoad={this.iframeReady}
                    />
                </Wrapper>
            </Wrapper>
        );
    }
}

export default LiveWithReplays;
