import { BET_BUDDY_REST_PATH } from 'configs/rest';

import axios from 'axios';

export function post(method, data, urlParams) {
    return request('post', BET_BUDDY_REST_PATH + method, data, urlParams);
}

export function put(method, data, urlParams) {
    return request('put', BET_BUDDY_REST_PATH + method, data, urlParams);
}

export function get(method, data) {
    return request('get', BET_BUDDY_REST_PATH + method, data);
}
export function del(method, data) {
    return request('delete', BET_BUDDY_REST_PATH + method, data);
}

function request(requestType, url, data, urlParams) {
    const options = {
        method: requestType,
        headers: {
            'Content-Type': 'application/json',
        },
        data: data,
        withCredentials: true,
        url,
        params:
            requestType === 'get' || requestType === 'delete'
                ? data
                : urlParams,
    };

    return axios(options).then((res) => res.data);
}
