import { useEffect, useState } from 'react';
import axios from 'axios';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import { RACE_INFO_SERVICE } from 'configs/rest';

const useATGPdfDownload = () => {
    const { raceDay, racesFetched } = useTrackPage();

    const [pdfAvailable, setPdfAvailable] = useState(false);

    const PDFLink = RACE_INFO_SERVICE(raceDay.trackId, raceDay.raceDayDate);

    const checkPDFAvailability = () => {
        if (racesFetched) {
            axios(PDFLink).then((response) => {
                setPdfAvailable(response.status === 200);
            });
        }
    };

    useEffect(() => {
        checkPDFAvailability();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [racesFetched]);

    return { pdfAvailable, PDFLink };
};

export default useATGPdfDownload;
