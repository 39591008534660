import React, { useLayoutEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import AnimationProps from './AnimationProps';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export const fadeInMixin = css<AnimationProps>`
    opacity: 0;
    animation-duration: ${({ duration = 200 }) => `${duration}ms`};
    animation-name: ${fadeIn};
    animation-delay: ${({ delay = 0 }) => `${delay}ms`};
    animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
    animation-fill-mode: ${({ mode = 'forwards' }) => mode};
    animation-direction: ${({ direction = 'normal' }) => direction};
`;

const FadeInEffect = styled.div<AnimationProps>`
    ${fadeInMixin};
`;

const InputFadeInEffect = styled.div<AnimationProps>`
    input {
        ${fadeInMixin};
    }
`;

const FadeIn = (props: AnimationProps) => {
    const { children, direction: defaultDirection = 'normal', style = {} } = props;

    const [direction, setDirection] = useState(defaultDirection);

    useLayoutEffect(() => {
        return () => {
            setDirection('reverse');
        };
    }, []);

    return (
        <FadeInEffect direction={direction} style={style} {...props}>
            {children}
        </FadeInEffect>
    );
};

export const InputFadeIn = (props: AnimationProps) => {
    const { children, direction: defaultDirection = 'normal', style = {} } = props;

    const [direction, setDirection] = useState(defaultDirection);

    useLayoutEffect(() => {
        return () => {
            setDirection('reverse');
        };
    }, []);

    return (
        <InputFadeInEffect direction={direction} style={style} {...props}>
            {children}
        </InputFadeInEffect>
    );
};

export default FadeIn;
