import React from 'react';
import ModalsHandler from '../Modals/ModalsHandler';
import { MapConfig } from 'configs/desktop-modals';
import ModalContainer from './ModalContainer';

class DesktopModals extends React.Component {
    render() {
        return (
            <ModalsHandler modalsMap={MapConfig}>
                {(modalProps) => <ModalContainer {...modalProps} />}
            </ModalsHandler>
        );
    }
}

export default DesktopModals;
