import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch } from 'react-redux';
import { useDidMount } from 'rooks';

import { CloseButton, QPCard, QPWrapper } from 'features/QuickPick/styled';
import QuickPickReceipt from './QuickPickReceipt';

import { PRODUCT_BACKGROUNDS } from 'features/QuickPick';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';

import Text from 'ui/Text';
import Wrapper from 'ui/Wrapper';

import { isUserLoggedIn } from 'common/actions/authActions';
import { IS_TERMINAL } from 'utils/platforms';
import getTexts from 'utils/localization';
import { NEW_TRACK_PAGE } from 'configs/main';

const t = getTexts();

const QuickPickReceiptModal = props => {
    const { hideModal, useDesktopModal, setBackground, modalData, enableCloseButton } = props;
    const dispatch = useDispatch();

    const onCancelHandler = () => {
        hideModal('QUICK_PICK_RECEIPT');
    };

    useDidMount(() => {
        dispatch(isUserLoggedIn());

        if (useDesktopModal === undefined) {
            enableCloseButton(<Text white>Lynspil</Text>);
        } else {
            // Note: useDesktopModal is not a hook
            // eslint-disable-next-line react-hooks/rules-of-hooks
            useDesktopModal();
            setBackground('rgba(0,0,0,0.7)');
        }
    });

    const { product, track, date } = modalData.modalAmountData.item;
    let { receipt } = modalData;

    let legs = modalData.receipt.coupon.legs;

    if (IS_TERMINAL) {
        try {
            legs = JSON.parse(modalData.receipt.coupon.receipt).coupon.legs;
        } catch (e) {
            console.log('legs', e);
        }

        try {
            receipt = JSON.parse(modalData.receipt.coupon.receipt);
        } catch (e) {
            console.log('legs', e);
        }
    }

    // fetchRaces: false can be deleted once we move to NEW_TRACK_PAGE
    const { raceDay } = useTrackPage({ fetchRaces: false });

    const newTrackPageProps = { raceDay };
    const oldTrackPageProps = { date, track };

    return (
        <QPWrapper
            bg={
                PRODUCT_BACKGROUNDS[product.id]
                    ? `url("${PRODUCT_BACKGROUNDS[product.id]}")`
                    : product.color
            }
            radius={useDesktopModal !== undefined ? 10 : 0}
        >
            <Scrollbars universal={true}>
                <CloseButton onClick={onCancelHandler}>
                    <span className="triangle" />
                    {t.QuickPick.label}
                </CloseButton>
                <Wrapper height="auto" padding="15% 10%">
                    <QPCard white>
                        <QuickPickReceipt
                            {...(NEW_TRACK_PAGE ? newTrackPageProps : oldTrackPageProps)}
                            product={product}
                            legs={legs}
                            receipt={receipt}
                        />
                    </QPCard>
                </Wrapper>
            </Scrollbars>
        </QPWrapper>
    );
};

export default QuickPickReceiptModal;
