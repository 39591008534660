import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import { COLORS } from 'themes';
import Button from 'ui/controls/Button';
import { ExpandableArrow } from 'ui/Arrow';
import { Body, BodyWrapper } from './Accordion';

const arrowSize = 6;
const borderRadius = 8;

const Arrow = styled(ExpandableArrow)`
    margin-left: 8px;
`;

const StyledBodyWrapper = styled(BodyWrapper)`
    top: -8px;
    border: 1px solid ${COLORS.black};
    position: relative;
    border-radius: 0 0 ${borderRadius}px ${borderRadius}px;
`;

const Toggler = styled(Button)`
    width: 100%;
    margin: 0;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 3;
`;

class AccordionDark extends Component {
    constructor() {
        super();
        this.state = {
            expanded: false,
        };
    }

    toggle = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    render() {
        const { title, children } = this.props;
        const { expanded } = this.state;
        return (
            <Fragment>
                <Toggler secondary size="x2" onClick={this.toggle}>
                    {title}
                    <Arrow
                        expanded={expanded}
                        size={arrowSize}
                        color={COLORS.white}
                    />
                </Toggler>
                <StyledBodyWrapper expanded={expanded}>
                    <Body>{children}</Body>
                </StyledBodyWrapper>
            </Fragment>
        );
    }
}

export default AccordionDark;
