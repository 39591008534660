import styled, { css } from 'styled-components';
import React from 'react';
import Text from 'ui/Text';
import { BACKGROUNDS } from 'themes';
import { DGABar } from 'configs/layout';

const Line = styled.div`
    width: 20px;
    height: 2px;
    background: white;
    border-radius: 2px;
    display: flex;
    transition: all 0.2s ease;
    opacity: 0.5;
`;
const MiddleLine = styled.div`
    position: relative;
    width: 100%;
    height: 2px;
    ${Line} {
        position: absolute;
    }
`;

const BurgerMenu = styled.div`
    width: 20px;
    height: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    box-sizing: content-box;
    transition: all 0.2s ease;
    position: relative;
`;

const StyledWrapper = styled.div`
    width: 100%;
    height: ${DGABar.desktop.height}px;
    border-bottom: 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 8px;
    & .tooltip-derby {
        opacity: 1 !important;
        font-size: 14px;
        font-weight: bold;
        color: #505050;
    }
`;

const BurgerMenuWrapper = styled.div`
    position: relative;
    height: 46px;
    cursor: pointer;
    display: flex;
    padding: 5px 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
    border-radius: 5px;
    width: 100%;

    ${({ isOpen }) =>
        css`
            justify-content: ${isOpen ? 'start' : 'center'};

            ${BurgerMenu} {
                ${isOpen && 'margin-right: 21px'};
            }
            ${Text} {
                ${!isOpen && 'display: none'};
                color: white;
            }
            ${Line} {
                align-self: ${isOpen ? 'end' : 'start'};
            }
            ${MiddleLine} {
                ${Line}:first-child {
                    width: 8px;
                    opacity: 0;
                    ${isOpen ? 'left: 0' : 'right: 0'};
                }
                ${Line}:last-child {
                    width: 8px;
                    opacity: 0;
                    ${isOpen ? 'left: 0' : 'right: 0'};
                }
            }
            &:hover {
                background: ${BACKGROUNDS.navigationActiveElement};
                ${Line} {
                    opacity: 1;
                }
                ${BurgerMenu} {
                    & > ${Line} {
                        width: 15px;
                    }
                }
                ${MiddleLine} {
                    ${Line}:first-child {
                        transform: rotate(${isOpen ? '135deg' : '45deg'});
                        ${isOpen ? 'right: 15px' : 'left: 15px'};
                        ${isOpen && 'left: auto'};
                        top: -2px;
                        opacity: 1;
                    }
                    ${Line}:last-child {
                        transform: rotate(${isOpen ? '-135deg' : '-45deg'});
                        ${isOpen ? 'right: 15px' : 'left: 15px'};
                        ${isOpen && 'left: auto'};
                        top: 2px;
                        opacity: 1;
                    }
                }
            }
        `};
`;

const BurgerIcon = ({ isOpen = false, onClickHandler = () => {} }) => {
    return (
        <StyledWrapper>
            <BurgerMenuWrapper
                data-tip={isOpen ? '' : 'Menu'}
                data-place="right"
                onClick={onClickHandler}
                isOpen={isOpen}
            >
                <BurgerMenu>
                    <Line />
                    <MiddleLine>
                        <Line />
                        <Line />
                        <Line />
                    </MiddleLine>
                    <Line />
                </BurgerMenu>
                <Text>Menu</Text>
            </BurgerMenuWrapper>
        </StyledWrapper>
    );
};

export default BurgerIcon;
