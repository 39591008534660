import { create } from 'zustand';
import { RaceDay } from 'features/EventBoard/server/calendar';

interface RaceDayStore {
    raceDay: RaceDay | null;
    isRaceDayFound(): boolean;
    setRaceDay(raceDay: RaceDay | null): void;
}

const useRaceDayState = create<RaceDayStore>()((set, getState) => ({
    raceDay: null,
    isRaceDayFound: () => getState().raceDay !== null,
    setRaceDay: (raceDay: RaceDay) => set({ raceDay }),
}));

export default useRaceDayState;
