import Coupon from 'features/BetSlip2/BettingREST/DataObjects/Coupon';
import VxyLeg from './VxyLeg';

class VxyCoupon extends Coupon {
    legs: VxyLeg[] = [];
    /**
     * True if a bet should be placed – participating only in the highest win
     * class. Having this false (default) gives you a bet that participates in
     * all three win classes.
     * @type {boolean}
     */
    strictBet: boolean = false;
}

export default VxyCoupon;
