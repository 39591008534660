import React from 'react';
import Input from './Input';
import getTexts from 'utils/localization';
const t = getTexts();

export const emailRegEx =
    /^[a-zA-Z0-9æøåöÆØÅÖ.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const numericRegEx = /^\d+$/;
export const alphabeticRegEx = /^[a-zA-Z æøåöÆØÅÖ]+$/;
export const noSpaceRegEx = /^((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%]).)$/;
export const userNameRegEx = /^[a-zA-Z0-9æøåöÆØÅÖ]{6,}$/;
export const passwordRegEx = /^(?=.*[A-Za-z])(?=(.*[\d]))(?=.*[A-Z])[A-Za-z0-9]{6,}$/;
// const passwordRegEx = /^(?=.*[A-Za-z])(?=(.*[\d]){2})(?=.*[A-Z])[A-Za-z0-9]{8,}$/;

class ValidInput extends React.Component {
    constructor() {
        super();

        this.state = {
            subLabel: true,
            validationError: null,
            brokenRules: [],
        };
    }

    onChange = (value) => {
        const valid = this.validate(value);

        if (!valid && !this.props.showErrorOnChange) {
            // if showing error on change is not enabled
            // the validation error will be reset
            this.setState({ validationError: null });
        }

        if (this.props.onChange) {
            this.props.onChange(value, valid);
        }
    };

    isValidInput = () => {
        this.validate(this.props.value);
    };

    validate(value) {
        const inputValidateRules = this.props.validate;

        let isValid = true;

        if (inputValidateRules) {
            if (
                inputValidateRules.required &&
                // deny empty string or just string with empty whitespaces
                (value === '' || /^ +$/g.test(value))
            ) {
                this.setState({
                    validationError: inputValidateRules.required.message
                        ? inputValidateRules.required.message
                        : t.validation.required,
                    brokenRules: [...this.state.brokenRules, 'required'],
                });

                isValid = false;
            }

            let emptyInput = typeof value === 'undefined' || value === '';
            if (!emptyInput) {
                if (inputValidateRules.numeric && !numericRegEx.test(value)) {
                    this.setState({
                        validationError: inputValidateRules.numeric.message
                            ? inputValidateRules.numeric.message
                            : t.validation.numeric,
                        brokenRules: [...this.state.brokenRules, 'numeric'],
                    });

                    isValid = false;
                }

                if (inputValidateRules.alphabetic && !alphabeticRegEx.test(value)) {
                    this.setState({
                        validationError: inputValidateRules.alphabetic.message
                            ? inputValidateRules.alphabetic.message
                            : t.validation.alphabetic,
                        brokenRules: [...this.state.brokenRules, 'alphabetic'],
                    });

                    isValid = false;
                }

                if (inputValidateRules.email && !emailRegEx.test(value)) {
                    this.setState({
                        validationError: inputValidateRules.email.message
                            ? inputValidateRules.email.message
                            : t.validation.email,
                        brokenRules: [...this.state.brokenRules, 'email'],
                    });

                    isValid = false;
                }

                if (inputValidateRules.withoutSpaces && !noSpaceRegEx.test(value)) {
                    this.setState({
                        validationError: inputValidateRules.withoutSpaces.message
                            ? inputValidateRules.withoutSpaces.message
                            : t.validation.notValid,
                        brokenRules: [...this.state.brokenRules, 'withoutSpaces'],
                    });

                    isValid = false;
                }

                if (
                    inputValidateRules.sameAs &&
                    value !== inputValidateRules.sameAs.confirmationValue
                ) {
                    this.setState({
                        validationError: inputValidateRules.sameAs.message
                            ? inputValidateRules.sameAs.message
                            : t.validation.sameAs,
                        brokenRules: [...this.state.brokenRules, 'sameAs'],
                    });

                    isValid = false;
                }

                if (inputValidateRules.min && value < inputValidateRules.min) {
                    this.setState({
                        validationError: t.validation.notValid,
                        brokenRules: [...this.state.brokenRules, 'min'],
                    });

                    isValid = false;
                }

                if (inputValidateRules.max && value > inputValidateRules.max) {
                    this.setState({
                        validationError: t.validation.notValid,
                        brokenRules: [...this.state.brokenRules, 'max'],
                    });

                    isValid = false;
                }

                if (inputValidateRules.minLength && value.length < inputValidateRules.minLength) {
                    this.setState({
                        validationError:
                            inputValidateRules.minLengthMessage || t.validation.notValid,
                        brokenRules: [...this.state.brokenRules, 'minLength'],
                    });

                    isValid = false;
                }

                if (inputValidateRules.maxLength && value.length > inputValidateRules.maxLength) {
                    this.setState({
                        validationError: t.validation.notValid,
                        brokenRules: [...this.state.brokenRules, 'maxLength'],
                    });

                    isValid = false;
                }

                if (inputValidateRules.userName && !userNameRegEx.test(value)) {
                    this.setState({
                        validationError: inputValidateRules.userName.message
                            ? inputValidateRules.userName.message
                            : t.validation.notValid,
                        brokenRules: [...this.state.brokenRules, 'userName'],
                    });

                    isValid = false;
                }

                if (inputValidateRules.password && !passwordRegEx.test(value)) {
                    this.setState({
                        validationError: inputValidateRules.password.message
                            ? inputValidateRules.password.message
                            : t.validation.notValid,
                        brokenRules: [...this.state.brokenRules, 'password'],
                    });

                    isValid = false;
                }

                if (inputValidateRules.regExp && !inputValidateRules.regExp.test(value)) {
                    this.setState({
                        validationError: t.validation.notValid,
                        brokenRules: [...this.state.brokenRules, 'regExp'],
                    });

                    isValid = false;
                }
            }
        }

        if (isValid) {
            this.setState({
                validationError: null,
            });
        }

        if (this.props.validationHandler) {
            this.props.validationHandler(isValid);
        }

        return isValid;
    }

    componentDidMount() {
        if (this.props.getValidator) {
            this.props.getValidator(this.validate.bind(this, this.props.value));
        }
    }
    componentDidUpdate() {
        if (this.props.getValidator) {
            this.props.getValidator(this.validate.bind(this, this.props.value));
        }
    }

    render() {
        return (
            <Input
                {...this.props}
                value={this.props.value}
                onChange={this.onChange}
                placeholder={this.props.placeholder}
                inputBlueredHandler={this.isValidInput.bind(this)}
                validationError={
                    !this.props.onDemand
                        ? this.state.validationError
                        : this.props.showError
                        ? this.state.validationError
                        : null
                }
                disabled={this.props.disabled}
                type={this.props.type}
                sublabel={this.props.sublabel}
                align={this.props.align}
            />
        );
    }
}

export default ValidInput;
