import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { get } from 'lodash';
import {
    fetchRaceDayCalendar,
    setProduct,
    setProductShortcut,
    resetProduct,
    resetRaceDay,
    resetRace,
    setRaceDay,
    fetchRacingCardWithPool,
    fetchServerTime,
    setRace,
    setDefaultRaceDay,
    setInitializing,
    setInputErrorReason,
} from './actions';
import { setDate } from 'ui/DatePicker/actions';
import { DEFAULT_SELECTED_PRODUCT, PRODUCT_IDS } from 'configs/products';
import RacingCard from 'common/DataObjects/RacingCard';
import { isMultitrackProduct } from 'common/selectors/multipleTrackSetupsSelector';
import {
    getMultitrackCodes,
    getTrackFromRacingCard,
    getTrackBySelectedRace,
    getLegTrackMap,
    getMultitrackTracks,
} from 'common/selectors/trackSelector';
import { ProductList } from 'common/DataObjects/Product';
import { getModalStatuses } from 'common/selectors/uiSelector';
import Track from 'common/DataObjects/Track';
import racesSelector from 'common/selectors/racesSelector';
import { getDefaultRace, getRacesOpenForSale } from 'common/selectors/racesSelector';
import { createEventLink, history, showPopup } from 'utils/navigation';
import { mergeBetslip, resetBet } from 'features/BetSlip/state/actions';
import { isMobile } from 'utils/platforms';
import { setMobileBetTrackReady, setMobileBetProductReady } from 'features/MobileBet/state/actions';
import { getRaceNumbersByCurrentProduct } from 'common/selectors/raceDaySelector';
import { isSpilklubSelectionsMode } from 'common/selectors/betSlipSelector';

export const inputErrorReasons = {
    INPUT_ERROR_TRACK: 'INPUT_ERROR_TRACK',
    INPUT_ERROR_DATE: 'INPUT_ERROR_DATE',
    INPUT_ERROR_PRODUCT: 'INPUT_ERROR_PRODUCT',
};

export function getDefaultRaceDay(raceDayData = []) {
    return raceDayData
        ? raceDayData.sort((a, b) => (a.firstRacePostTime < b.firstRacePostTime ? -1 : 1))[0]
        : {}; //@TODO Figure out how to avoid pushing empty object raceDay into state
}

class AISDataProvider extends React.Component {
    static Timers = {
        racingCardWithPool: null,
        raceDayCalendar: null,
    };

    _timeConfig = {
        racingCardWithPool: 60,
        raceDayCalendar: 60 * 10,
        intervalPausedTimestamp: null,
    };

    _shortcutProcessing = false;
    _initializing = false;

    replaceHomeLink = () => {
        const eventLink = createEventLink({
            raceDay: this.props.AISDataProvider.selectedRaceDay,
            product: this.props.AISDataProvider.selectedProduct,
            raceIndex: this.props.AISDataProvider.raceIndex,
        });
        eventLink && this.props.history.replace(eventLink);
    };

    shouldUpdateDataOnRestoringIntervals = () =>
        moment().diff(this._timeConfig.intervalPausedTimestamp, 'minutes') >= 1;

    // used only for restoring timers to update data when a user went back to the tab / window
    updateData = () => {
        const { selectedRaceDay, selectedProduct } = this.props.AISDataProvider;

        this.props.fetchRaceDayCalendar();

        selectedRaceDay &&
            this.props.fetchRacingCardWithPool(
                selectedRaceDay.date,
                selectedRaceDay.trackId,
                selectedProduct.id
            );

        this.props.fetchServerTime();
    };

    // used for restoring intervals when user backs from another tab to the current one
    restoreIntervals = () => {
        const { selectedRaceDay, selectedProduct } = this.props.AISDataProvider;

        // fetch data to keep it up-to-date after tab switching as setInterval doesn't work immediately
        if (this.shouldUpdateDataOnRestoringIntervals()) {
            this.updateData();
        }

        // restore timers
        this.handleRaceDayCalendarTimer();

        selectedRaceDay &&
            this.handleRacingCardWithPoolTimer(
                selectedRaceDay.date,
                selectedRaceDay.trackId,
                selectedProduct.id
            );

        this.handleServerTimeTimer();
    };

    // used for pausing intervals when user switches to another tab and componentWillUnmount
    clearIntervals = () => {
        this.poolTimerCounter = 0;
        this._timeConfig.intervalPausedTimestamp = moment();

        if (AISDataProvider.Timers.racingCardWithPool) {
            window.clearInterval(AISDataProvider.Timers.racingCardWithPool);
        }

        if (AISDataProvider.Timers.raceDayCalendar) {
            window.clearInterval(AISDataProvider.Timers.raceDayCalendar);
        }

        if (AISDataProvider.Timers.fetchServerTime) {
            window.clearInterval(AISDataProvider.Timers.fetchServerTime);
        }
    };

    onTabSwitch = () => {
        if (document.visibilityState === 'visible') {
            this.restoreIntervals();
        } else {
            this.clearIntervals();
        }
    };

    componentDidMount() {
        const { raceDayFetched, urlParams } = this.props;

        window.addEventListener('visibilitychange', this.onTabSwitch);

        const { raceDayData, selectedProduct, racingCardFetched } = this.props.AISDataProvider;
        let shouldDisableDefaultRaceDay = !this.shouldApplyURLParams();

        if (this.props.history.location.pathname === '/hjem') {
            this.replaceHomeLink();
            shouldDisableDefaultRaceDay = false;
            // racingCardFetched in this case determines if it's ever
            // was fetched after the page reloading or it's initial
            // page loading of /hjem
            if (racingCardFetched) {
                return;
            }
        }

        const urlRace = parseInt(this.props.urlParams.race, 10);

        // important to set it to true eventually (when race day is not fetched yet)
        // not initially - when class is constructed
        // it fixes an issue occurring when the component is mounted second time
        // (use case - switching between nav links and going back to /hjem)
        this._initializing = true;
        this.props.setInitializing(true);
        //  eslint-disable-next-line
        let raceDay = null;
        // important to save value in the variable here to use later
        // since method this.shouldApplyURLParams will return another
        // value during the component lifecycle
        const shouldApplyURLParams = this.shouldApplyURLParams();

        const getProductId = () => {
            if (urlParams && urlParams.product) {
                return ProductList.getByName(urlParams.product).id;
            }
            return selectedProduct ? selectedProduct.id : DEFAULT_SELECTED_PRODUCT.id;
        };

        this.handleRaceDayCalendarTimer();

        const getCalendarData = () => {
            return raceDayFetched
                ? Promise.resolve(raceDayData)
                : this.props.fetchRaceDayCalendar();
        };

        getCalendarData()
            .then(raceDayData => {
                return this.shouldApplyURLParams() && this.validateURL()
                    ? this.getRaceDayByURL(raceDayData)
                    : getDefaultRaceDay(raceDayData);
            })
            .then(selectedRaceDay => {
                if (!selectedRaceDay) {
                    this._initializing = false;
                    this.props.setInitializing(false);
                    return Promise.reject(`No race day found in the calendar.`);
                }
                raceDay = selectedRaceDay;
                if (selectedRaceDay) {
                    this.props.setRaceDay(selectedRaceDay);

                    raceDay = selectedRaceDay;

                    this.handleRacingCardWithPoolTimer(
                        selectedRaceDay.date,
                        selectedRaceDay.trackId,
                        getProductId()
                    );

                    return this.props.fetchRacingCardWithPool(
                        selectedRaceDay.date,
                        selectedRaceDay.trackId,
                        getProductId()
                    );
                }
            })
            .then(([racingCardWithPool]) => {
                !this._shortcutProcessing &&
                    this.props.setDate(
                        moment(raceDay && raceDay.date ? raceDay.date : new Date()).format()
                    );

                !this._shortcutProcessing &&
                    shouldApplyURLParams &&
                    this.props.setProduct(ProductList.getById(getProductId()));
                !this._shortcutProcessing && this.props.setRaceDay(raceDay);

                !this._shortcutProcessing && shouldApplyURLParams && this.validateURLRace();

                if (!this._shortcutProcessing && urlRace) {
                    const race = racingCardWithPool.races[urlRace - 1];

                    if (race) {
                        this.props.setRace(race.raceNumber, urlRace - 1);
                    }
                } else {
                    this.props.defaultRace &&
                        this.props.setRace(
                            this.props.defaultRace.raceNumber,
                            this.props.defaultRace.index
                        );
                }
                // important to use the variable here
                // since method this.shouldApplyURLParams will return another
                // value during the component lifecycle
                shouldDisableDefaultRaceDay && this.props.setDefaultRaceDay(false);

                this._initializing = false;

                setTimeout(this.fillBetslipFromURL, 1000);
                this.props.setInitializing(false);
            })
            .catch(error => {
                !shouldApplyURLParams && this.props.setDefaultRaceDay(false);

                this._initializing = false;
                this.props.setInitializing(false);

                console.log(error);
            });

        this.handleServerTimeTimer();

        this.props.fetchServerTime();
    }

    componentWillUnmount() {
        this.clearIntervals();

        window.removeEventListener('visibilitychange', this.onTabSwitch);
    }

    poolTimerCounter = 0;

    handleRacingCardWithPoolTimer = (date, trackId, productId) => {
        if (AISDataProvider.Timers.racingCardWithPool) {
            window.clearInterval(AISDataProvider.Timers.racingCardWithPool);
            this.poolTimerCounter = 0;
        }

        AISDataProvider.Timers.racingCardWithPool = window.setInterval(() => {
            this.poolTimerCounter++;
            // Skipping some data is used to reduce number of calls to the server
            // poolTimerCounter % 3 is used because the whole timer's frequency is 1 minute
            // If it should be changed from 1 minute to something else, this approach
            // has to be rewritten. This way betInfo and mergedPools requests are skipped
            // every 1st and 2nd call of fetchRacingCardWithPool. 3rd time it will be requested
            const skipBetInfo = this.poolTimerCounter % 3 !== 0;
            const skipMergedPools = this.poolTimerCounter % 3 !== 0;

            this.props.fetchRacingCardWithPool(
                moment(date).format('YYYY-MM-DD'),
                trackId,
                productId,
                true,
                { skipBetInfo, skipMergedPools }
            );
        }, this._timeConfig.racingCardWithPool * 1000);
    };

    handleRaceDayCalendarTimer = () => {
        if (AISDataProvider.Timers.raceDayCalendar) {
            window.clearInterval(AISDataProvider.Timers.raceDayCalendar);
        }

        AISDataProvider.Timers.raceDayCalendar = window.setInterval(() => {
            this.props.fetchRaceDayCalendar(this.props.selectedDate);
        }, this._timeConfig.raceDayCalendar * 1000);
    };

    handleServerTimeTimer = () => {
        if (AISDataProvider.Timers.fetchServerTime) {
            window.clearInterval(AISDataProvider.Timers.fetchServerTime);
        }

        AISDataProvider.Timers.fetchServerTime = window.setInterval(() => {
            this.props.fetchServerTime();
        }, 60000);
    };

    shouldApplyURLParams = () => !!(this.props.urlParams && this.props.urlParams.date);

    validateURL = () => {
        if (this.props.urlParams.date && !this.validateURLDate()) {
            return false;
        }

        if (this.props.urlParams.product && !this.validateURLProduct()) {
            return false;
        }

        if (this.props.urlParams.race && !this.validateURLRace()) {
            return false;
        }

        return true;
    };

    validateURLDate = () => /\d{4}-\d{2}-\d{2}/g.test(this.props.urlParams.date);

    validateURLProduct = () => ProductList.getByName(this.props.urlParams.product);

    validateURLRace = () => !isNaN(parseInt(this.props.urlParams.race, 10));

    fillBetslipFromURL = () => {
        const { races, track } = this.props;
        const url = new URL(window.location.href);
        const queryParamNames = Array.from(url.searchParams.keys());

        //Example href: ?leg1=2&leg2=6,7&leg3=1,2,3&leg4=1,2,3,4,5,6,7,8,9,10,11,12,13

        if (
            history.location.pathname.indexOf('spil/') > -1 &&
            queryParamNames.some(param => param.startsWith('leg')) &&
            this.props.AISDataProvider.selectedProduct.isVProduct() &&
            this.props.AISDataProvider.selectedRaceDay
        ) {
            const date = this.props.AISDataProvider.selectedRaceDay.date;
            const productId = this.props.AISDataProvider.selectedProduct.id;
            const picks = {
                [date]: {},
            };
            const reserves = {
                [date]: {},
            };

            for (let [key, legSelections] of url.searchParams.entries()) {
                const isLegParam = key.match(/^leg\d$/) !== null;
                if (!isLegParam) {
                    break;
                }
                const legNr = parseInt(key.replace('leg', ''), 10);
                const race = races[legNr - 1];
                if (!race || !race.saleOpen) {
                    break;
                }
                if (!picks[date][track.code]) {
                    picks[date][track.code] = {
                        [productId]: {},
                    };
                    reserves[date][track.code] = {
                        [productId]: {},
                    };
                }
                picks[date][track.code][productId][race.index] = {};
                reserves[date][track.code][productId][race.index] = [];

                const [pickSelections, reserveSelections] = legSelections.split('_');
                pickSelections.split(',').forEach(startNr => {
                    startNr = parseInt(startNr, 10);
                    const start = race.starts[startNr - 1];
                    if (!start || start.scratched) {
                        return;
                    }
                    picks[date][track.code][productId][race.index][startNr] = [startNr];
                });

                ![PRODUCT_IDS.DD, PRODUCT_IDS.LD].includes(
                    this.props.AISDataProvider.selectedProduct.id
                ) &&
                    reserveSelections &&
                    reserveSelections
                        .split(',')
                        .slice(0, 2)
                        .forEach(startNr => {
                            const start = race.starts[startNr - 1];
                            if (!start || start.scratched) {
                                return;
                            }
                            startNr = parseInt(startNr, 10);
                            reserves[date][track.code][productId][race.index].push(startNr);
                        });
            }

            history.replace(history.location.pathname);

            if (Object.keys(picks[date]).length > 0) {
                this.props.resetBet();
                this.props.mergeBetslip(picks, reserves);
                isMobile && showPopup('BETSLIP_MOBILE');
            }
        }
    };

    rewriteRacingCardParams = (trackId, productId) => {
        /** product changed before RaceDay (Terminal.jsx),
         *  thus it's trying to get RacingCard with old trackID
         *  It should be changed to proper trackId
         *  */
        const { mobileBet } = this.props;
        if (mobileBet.initialization) {
            const mbTrackId = get(mobileBet, 'trackId', null);
            const mbProductId = get(mobileBet, 'productId', null);

            if (mbTrackId !== trackId || mbProductId !== productId) {
                trackId = mbTrackId;
                productId = mbProductId;
            }
        }

        return { trackId, productId };
    };

    componentDidUpdate() {
        const { selectedRaceDay, raceDayData } = this.props.AISDataProvider;

        const currentRaceDay = selectedRaceDay
            ? selectedRaceDay
            : getDefaultRaceDay(this.props.AISDataProvider.raceDayData);

        if (this.props.history.location.pathname === '/hjem') {
            this.replaceHomeLink();
        }

        // if track was finished
        if (
            raceDayData &&
            currentRaceDay &&
            !raceDayData.find(
                raceDay =>
                    raceDay.trackId === currentRaceDay.trackId &&
                    raceDay.date === currentRaceDay.date
            ) &&
            !this._initializing
        ) {
            let nextRaceDay = raceDayData.find(raceDay => raceDay.date === currentRaceDay.date);

            if (nextRaceDay) {
                this.props.setRaceDay(nextRaceDay);
            }
        }

        // Default state
        // if (!selectedRaceDay && prevProps.AISDataProvider.selectedRaceDay) {
        //     //put default data from MobileBet?
        //     //for better transition from Manager
        //
        //     const defaultRaceDay = getDefaultRaceDay(this.props.AISDataProvider.raceDayData);
        //     const defaultDate = moment(selectedDate).format('YYYY-MM-DD');
        //     // setRaceDay call fixes strange bug appearing when
        //     // eg Vinder is selected but 4 races is displayed
        //     // (in case of previously selected V4)
        //     this.props.setRaceDay(defaultRaceDay);
        //     // default state means the application status when
        //     // selected track is not highlighted as active (green)
        //     // and product shortcut is displayed instead of showing
        //     // product list of the current race day
        //     //@see https://ecosys.atlassian.net/browse/DER-1003
        //     // [TracksBySelectedDay] and [Products] components use it
        //     this.props.setDefaultRaceDay(false);
        //
        //     this.props.fetchRacingCardWithPool(
        //         defaultDate,
        //         defaultRaceDay.trackId,
        //         selectedProduct.id
        //     );
        // }
        //
        // // Track was changed
        // const previousRaceDay = get(prevProps, 'AISDataProvider.selectedRaceDay', null);
        // const trackWasChanged =
        //     selectedProduct &&
        //     selectedRaceDay &&
        //     !productShortcut &&
        //     !this._initializing &&
        //     (!previousRaceDay ||
        //         previousRaceDay.trackId !== selectedRaceDay.trackId ||
        //         previousRaceDay.date !== selectedRaceDay.date);
        //
        // if (trackWasChanged) {
        //     setTimeout(() => {
        //         const { trackId, productId } = this.rewriteRacingCardParams(
        //             selectedRaceDay.trackId,
        //             selectedProduct.id
        //         );
        //
        //         this.props
        //             .fetchRacingCardWithPool(
        //                 moment(selectedDate).format('YYYY-MM-DD'),
        //                 trackId,
        //                 productId
        //             )
        //             .then(() => {
        //                 if (
        //                     previousRaceDay &&
        //                     !this.props.multitrackCodes.includes(previousRaceDay.track.code) &&
        //                     !this.props.multitrackCodes.includes(selectedRaceDay.track.code)
        //                 ) {
        //                     this.props.resetRace();
        //                 }
        //
        //                 this.props.setMobileBetTrackReady();
        //             });
        //
        //         this.handleRacingCardWithPoolTimer(
        //             selectedDate,
        //             selectedRaceDay.trackId,
        //             selectedProduct.id
        //         );
        //     }, 300);
        // }

        // Product was changed
        // const productWasChanged =
        //     !this._initializing &&
        //     !prevProps.AISDataProvider.defaultProduct &&
        //     selectedProduct &&
        //     prevProps.AISDataProvider.selectedProduct &&
        //     selectedRaceDay &&
        //     !productShortcut &&
        //     selectedProduct.id !== prevProps.AISDataProvider.selectedProduct.id;
        //
        // if (productWasChanged) {
        //     const { trackId, productId } = this.rewriteRacingCardParams(
        //         selectedRaceDay.trackId,
        //         selectedProduct.id
        //     );
        //
        //     const simpleProductRaceCountChanged =
        //         !prevProps.AISDataProvider.selectedProduct.isVProduct() &&
        //         !this.props.AISDataProvider.selectedProduct.isVProduct() &&
        //         this.props.raceNumbersByCurrentProduct.length !==
        //             prevProps.raceNumbersByCurrentProduct.length;
        //
        //     if (
        //         !SAVE_RACE_NUMBER_BETWEEN_PRODUCTS.includes(
        //             prevProps.AISDataProvider.selectedProduct.id
        //         ) ||
        //         !SAVE_RACE_NUMBER_BETWEEN_PRODUCTS.includes(productId) ||
        //         simpleProductRaceCountChanged
        //     ) {
        //         !this._initializing && this.props.setRace(1, 0);
        //     }
        //
        //     this.props
        //         .fetchRacingCardWithPool(
        //             moment(selectedDate).format('YYYY-MM-DD'),
        //             trackId,
        //             productId
        //         )
        //         .then(() => {
        //             if (
        //                 (!SAVE_RACE_NUMBER_BETWEEN_PRODUCTS.includes(
        //                     prevProps.AISDataProvider.selectedProduct.id
        //                 ) ||
        //                     !SAVE_RACE_NUMBER_BETWEEN_PRODUCTS.includes(selectedProduct.id)) &&
        //                 !this._initializing
        //             ) {
        //                 this.props.resetRace();
        //             }
        //
        //             this.props.setMobileBetProductReady();
        //         });
        //
        //     this.handleRacingCardWithPoolTimer(
        //         selectedDate,
        //         selectedRaceDay.trackId,
        //         selectedProduct.id
        //     );
        // }

        // Selected Product Shortcut
        // if (
        //     productShortcut &&
        //     selectedProduct &&
        //     selectedRaceDay &&
        //     prevProps.AISDataProvider.selectedProduct &&
        //     selectedProduct.id !== prevProps.AISDataProvider.selectedProduct.id
        // ) {
        //     console.log('SHORTCUT');
        //     this._shortcutProcessing = true;
        //     if (selectedRaceDay.date !== moment(selectedDate).format('YYYY-MM-DD')) {
        //         this.props.setDate(moment(selectedRaceDay.date));
        //     }
        //
        //     this.props.setRace(1, 0);
        //     this.props
        //         .fetchRacingCardWithPool(
        //             selectedRaceDay.date,
        //             selectedRaceDay.trackId,
        //             selectedProduct.id
        //         )
        //         .then(() => {
        //             this.props.resetRace();
        //             this._shortcutProcessing = false;
        //         });
        //
        //     this.handleRacingCardWithPoolTimer(
        //         selectedRaceDay.date,
        //         selectedRaceDay.trackId,
        //         selectedProduct.id
        //     );
        // }

        // const prevStateDate = moment(prevProps.selectedDate).format('YYYY-MM-DD');
        // const newStateDate = moment(this.props.selectedDate).format('YYYY-MM-DD');

        // Date was changed
        // if (
        //     selectedProduct &&
        //     prevStateDate !== newStateDate &&
        //     !this._shortcutProcessing &&
        //     !this._initializing &&
        //     !this.props.editSelectionsInitializing
        // ) {
        //     /**  */
        //     if (this.props.mobileBet.initialization) {
        //         return;
        //     }
        //
        //     const rDay = this.getRaceDayByDate(
        //         this.props.AISDataProvider.raceDayData,
        //         newStateDate
        //     );
        //
        //     if (rDay) {
        //         this.props.setRaceDay(rDay);
        //         this.props.setProduct(ProductList.getDefaultProduct());
        //     }
        // }

        // Product was changed in URL by history (going back or forward)
        // const urlProduct = this.props.urlParams.product;
        // const prevUrlProduct = prevProps.urlParams.product;
        //
        // const urlDateChanged = this.props.urlParams.date !== prevProps.urlParams.date;
        // const urlTrackChanged = this.props.urlParams.track !== prevProps.urlParams.track;
        //
        // if (
        //     !productWasChanged && // <-- this one is used to prevent double update (because otherwise changing product will cause extra pushing to history)
        //     urlProduct !== prevUrlProduct &&
        //     !urlTrackChanged &&
        //     !urlDateChanged &&
        //     this.shouldApplyURLParams() &&
        //     this.validateURL()
        // ) {
        //     const product = ProductList.getByName(this.props.urlParams.product);
        //     this.props.setProduct(product);
        //     console.log(`Product was changed by URL, new product is: ${product.id}`);
        // }
    }

    getRaceDayByURL(raceDayData) {
        const { date, product } = this.props.urlParams;

        let { track } = this.props.urlParams;
        // ATG sends multitrack combined track name as a URL parameter
        // we just take the first part and set it as selected track
        // Note: we can't use isMultitrackProduct selector here because state.AISDataProvider.selectedRaceDay is not set yet
        const isMultitrack = track.includes('-');

        if (isMultitrack) {
            const dashIndex = track.indexOf('-');
            track = dashIndex !== -1 ? track.substring(0, dashIndex) : track;
        }

        const trackExists = this.props.AISDataProvider.tracks.list.some(t => {
            return Track.getSlug(t.name).toLowerCase() === Track.getSlug(track).toLowerCase();
        });
        //@TODO Implement checking product and date
        // if (!productExists) {
        //     this.props.setInputErrorReason(
        //         inputErrorReasons.INPUT_ERROR_PRODUCT
        //     );
        // }
        //
        // if (!wrongDate) {
        //     this.props.setInputErrorReason(
        //         inputErrorReasons.INPUT_ERROR_DATE
        //     );
        // }
        if (!trackExists) {
            this.props.setInputErrorReason(inputErrorReasons.INPUT_ERROR_TRACK);
        }

        return raceDayData.find(day => {
            const satisfiesProduct = !product
                ? true
                : day.fullProductList.indexOf(ProductList.getByName(product).id) !== -1;
            // swedish and danish characters must be replaced with english
            // characters, so the track names are converted to slugs before
            // comparing.

            const satisfiesTrack = !track
                ? true
                : Track.getSlug(day.track.name).toLowerCase() ===
                  Track.getSlug(track).toLowerCase();

            const satisfiesDate = day.date === date;

            return satisfiesDate && satisfiesProduct && satisfiesTrack;
        });
    }

    getRaceDayByDate(raceDayData, date) {
        return raceDayData?.find(day => day.date === date);
    }

    render() {
        return null;
    }
}

const mapStateToProps = state => ({
    AISDataProvider: {
        ...state.AISDataProvider,
        racingCardData: RacingCard.fill(state.AISDataProvider),
    },
    raceDayFetched: state.AISDataProvider.raceDayFetched,
    selectedDate: state.DatePicker.date,
    isMultitrackProduct: isMultitrackProduct(state),
    multitrackCodes: getMultitrackCodes(state),
    track: getTrackFromRacingCard(state),
    modalStatuses: getModalStatuses(state),
    enabledRaces: getRacesOpenForSale(state),
    defaultRaceDay: state.AISDataProvider.defaultRaceDay,
    defaultRace: getDefaultRace(state),
    trackBySelectedRace: getTrackBySelectedRace(state),
    legTrackMap: getLegTrackMap(state),
    multitracks: getMultitrackTracks(state),
    races: racesSelector(state),
    mobileBet: state.MobileBet,
    editSelectionsInitializing: state.AISDataProvider.editSelectionsInitializing,
    raceNumbersByCurrentProduct: getRaceNumbersByCurrentProduct(state),
    isBetBuddySelectionsMode: isSpilklubSelectionsMode(state),
});

const mapDispatchToProps = dispatch => {
    return {
        setInitializing: status => dispatch(setInitializing(status)),

        fetchRaceDayCalendar: withoutPending => {
            return dispatch(fetchRaceDayCalendar(withoutPending));
        },
        setRaceDay: raceDay => {
            dispatch(setRaceDay(raceDay));
        },
        setDefaultRaceDay: defaultRaceDay => {
            dispatch(setDefaultRaceDay(defaultRaceDay));
        },
        setRace: (raceNumber, raceIndex) => {
            dispatch(setRace(raceNumber, raceIndex));
        },
        setProduct: product => {
            dispatch(setProduct(product));
        },
        setProductShortcut: product => {
            dispatch(setProductShortcut(product));
        },
        resetProduct: () => {
            dispatch(resetProduct());
        },
        resetRaceDay: () => {
            dispatch(resetRaceDay());
        },
        resetRace: () => {
            dispatch(resetRace());
        },
        setDate: date => dispatch(setDate(date)),
        fetchRacingCardWithPool: (date, trackId, productId, withoutPending, settings) =>
            dispatch(fetchRacingCardWithPool(date, trackId, productId, withoutPending, settings)),
        fetchServerTime: () => dispatch(fetchServerTime()),
        resetBet: () => dispatch(resetBet()),
        mergeBetslip: (picks, reserves) => dispatch(mergeBetslip(picks, reserves)),
        setInputErrorReason: reason => {
            dispatch(setInputErrorReason(reason));
        },
        setMobileBetTrackReady: () => {
            dispatch(setMobileBetTrackReady());
        },
        setMobileBetProductReady: () => {
            dispatch(setMobileBetProductReady());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AISDataProvider);
