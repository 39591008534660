import React, { RefObject, useRef } from 'react';
import Slider from 'react-slick';
import { useEffectOnceWhen } from 'rooks';

import {
    extractMultitrackTrackId,
    findCoupleTrack,
    RaceDay,
} from 'features/EventBoard/server/calendar';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import useAllPoolInformationQuery from 'features/EventBoard/hooks/useAllPoolInformationQuery';
import { getTracksJackpotAvailabilityMap } from 'features/TrackPage/utils/jackpot';

import { byPlatform, isMobile } from 'utils/platforms';
import { scrollToElement } from 'utils/DOM';

import * as RaceNavigation from 'ui/RaceNavigation';
import {
    PreloaderButtons,
    NextTrackButton,
    PrevTrackButton,
    JackpotLabel,
    jackpotLabelTopOffset,
} from 'ui/RaceNavigation';

import './slick.css';
import { TracksScrollBar, TrackNavigationButton, TrackRowSlickContainer } from '../styled';
import useURLParameters from 'features/TrackPage/hooks/useURLParameters';

export const TRACKS_ORDER_BY_COUNTRY = ['DK', 'SE', 'NO', 'FR', 'AU', 'GB', 'ZA', 'US', 'CA', 'NL'];

export const MINIMUM_ITEMS_TO_SHOW_SLIDER = 7;

interface TrackButtonsProps {
    handleSelectTrack(raceDay: RaceDay): void;
}

const TrackButtons = ({ handleSelectTrack }: TrackButtonsProps) => {
    const {
        raceDays,
        raceDay: selectedRaceDay,
        calendarLoading,
        racesLoading,
        productId,
    } = useTrackPage();

    const { track } = useURLParameters();

    const { data: allPoolInformation } = useAllPoolInformationQuery();

    const coupleTrack = findCoupleTrack(selectedRaceDay, productId);

    const tracksJackpotAvailabilityMap = getTracksJackpotAvailabilityMap(
        selectedRaceDay,
        allPoolInformation
    );

    const scrollContainerRef = useRef(document.createElement('div'));
    const activeButtonRef = useRef(null);

    useEffectOnceWhen(
        () => {
            scrollToElement(scrollContainerRef, activeButtonRef);
        },
        Boolean(track) && Boolean(activeButtonRef.current)
    );

    const getTracks = () => {
        return [...raceDays].sort((a, b) => {
            const trackACountryOrder = TRACKS_ORDER_BY_COUNTRY.indexOf(a.country.code);
            const trackBCountryOrder = TRACKS_ORDER_BY_COUNTRY.indexOf(b.country.code);

            if (trackBCountryOrder === -1) {
                return -1;
            }

            if (trackACountryOrder === trackBCountryOrder) {
                // if both tracks are in the same country, sort by post time
                return a.firstRacePostTime < b.firstRacePostTime ? -1 : 1;
            }

            return trackACountryOrder > trackBCountryOrder ? 1 : -1;
        });
    };

    const getNumberOfTracks = () => getTracks().length;

    const showPreloader = calendarLoading;

    const shouldUseSlider = () => {
        const numberOfTracks = getNumberOfTracks();
        return numberOfTracks > MINIMUM_ITEMS_TO_SHOW_SLIDER && !isMobile && !showPreloader;
    };

    const renderTrackList = () => {
        if (showPreloader) {
            return renderPreloader();
        }

        const tracks = getTracks();
        const useSlider = shouldUseSlider();

        return tracks.map((raceDay, i) => {
            const multitrackTrackId = extractMultitrackTrackId(raceDay);
            const hasJackpot = tracksJackpotAvailabilityMap[multitrackTrackId || raceDay.trackId];

            const isActive = selectedRaceDay.trackId === raceDay.trackId;

            const countryImage = // @ts-ignore
                RaceNavigation.countryCodeMap[raceDay.country.code] ||
                RaceNavigation.countryCodeMap.Default;

            return (
                <RaceNavigation.ButtonWithSublabel
                    key={raceDay.trackId}
                    style={{ display: 'flex' }}
                >
                    {hasJackpot && <JackpotLabel>Jackpot</JackpotLabel>}

                    <TrackNavigationButton
                        disabled={racesLoading}
                        active={isActive}
                        data-test-id={isActive ? 'active' : 'inactive'}
                        accent={raceDay.trackId === coupleTrack?.trackId}
                        onClick={() => handleSelectTrack(raceDay)} // @TODO: from mapDispatchToProps
                        useSlider={useSlider}
                        numberOfTracks={getNumberOfTracks()}
                        index={i}
                        {...(isActive ? { ref: activeButtonRef } : {})}
                    >
                        <RaceNavigation.CountryImage src={countryImage} />
                        {raceDay.trackName}
                    </TrackNavigationButton>
                    {/*<RaceNavigation.SubLabel>*/}
                    {/*    {moment(raceDay.firstRacePostTime).utc() > moment(serverTime).utc()*/}
                    {/*        ? moment(raceDay.firstRacePostTime).utc().from(moment(serverTime).utc())*/}
                    {/*        : t.RacingCard.ongoing}*/}
                    {/*</RaceNavigation.SubLabel>*/}
                </RaceNavigation.ButtonWithSublabel>
            );
        });
    };

    const renderPreloader = () => (
        <PreloaderButtons count={4} width="100px" wrapperStyles={{ padding: 0 }} />
    );

    return (
        <TrackButtonsContainer
            useSlider={shouldUseSlider()}
            scrollContainerRef={scrollContainerRef}
        >
            {renderTrackList()}
        </TrackButtonsContainer>
    );
};

interface ContainerProps {
    children: React.ReactNode;
    useSlider: boolean;
    scrollContainerRef: RefObject<HTMLElement>;
}

export const TrackButtonsContainer = ({
    children,
    useSlider,
    scrollContainerRef,
}: ContainerProps) => {
    const sliderArrowsStyle = {
        marginTop: byPlatform('0', jackpotLabelTopOffset),
    };

    const sliderSettings = {
        className: 'slider variable-width',
        dots: false,
        infinite: false,
        speed: 500,
        slidesToScroll: 2,
        slidesToShow: MINIMUM_ITEMS_TO_SHOW_SLIDER,
        prevArrow: <PrevTrackButton styles={sliderArrowsStyle} />,
        nextArrow: <NextTrackButton styles={sliderArrowsStyle} />,
        responsive: [
            { breakpoint: 630, settings: { slidesToShow: 1, slidesToScroll: 1 } },
            { breakpoint: 750, settings: { slidesToShow: 2, slidesToScroll: 1 } },
            { breakpoint: 850, settings: { slidesToShow: 3 } },
            { breakpoint: 1030, settings: { slidesToShow: 4 } },
            { breakpoint: 1100, settings: { slidesToShow: 5 } },
            { breakpoint: 1200, settings: { slidesToShow: 6 } },
        ],
    };

    return useSlider ? (
        <TrackRowSlickContainer className="homepage-tracks">
            <Slider {...sliderSettings}>{children}</Slider>
        </TrackRowSlickContainer>
    ) : (
        <TracksScrollBar ref={scrollContainerRef}>{children}</TracksScrollBar>
    );
};

export default TrackButtons;
