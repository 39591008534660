import React, { useState } from 'react';
import styled from 'styled-components';

import useModals from 'common/hooks/useModals';

import DraggablePopup from 'ui/DraggablePopup';
import Wrapper from 'ui/Wrapper';
import Text from 'ui/Text';
import Checkbox from 'ui/Checkbox';
import Button from 'ui/controls/Button';

import { ReactComponent as PinLoginDef } from '../assets/pin_login.svg';

const TitleWrapper = styled(Wrapper)`
    display: flex;
    flex-direction: column;
    padding: 0;
`;

const PinLoginSvg = styled(PinLoginDef)`
    margin: 0 auto 20px;
`;

const CheckboxWrapper = styled.div`
    border: 1px solid #f1f1f1;
    width: 35%;
    height: 44px;
    border-radius: 8px;
    display: inline-flex;
    align-items: center;
    padding: 0 12px;
    margin: 10px 0 20px;

    span {
        font-size: 14px;
        font-weight: 700;
        color: #8e8e8e;
        margin: 0 0 0 8px;
    }
`;

const PinPopup = () => {
    const { hide, show } = useModals();
    const [checkboxChecked, setCheckboxChecked] = useState(false);

    const setPinLogin = () => {
        onClose();
        show('PIN_SETTING', 1);
    };

    const setPinStatus = () => {
        if (checkboxChecked) {
            window.localStorage.setItem('pinPopup', '' + checkboxChecked);
        }
    };

    const onClose = () => {
        setPinStatus();
        hide('PIN_POPUP');
    };

    return (
        <DraggablePopup
            padding="25px 20px 0"
            height={'auto'}
            closeHandler={() => hide('PIN_POPUP')}
            withoutDragIndicator
        >
            <TitleWrapper>
                <PinLoginSvg />
                <Text size={'x2'} align={'center'} bold>
                    Log ind med PIN kode
                </Text>
                <Text size={'x1.5'} weight={'700'}>
                    Brug PIN kode fremover for nem og hurtig adgang til din konto
                </Text>
                <CheckboxWrapper onClick={() => setCheckboxChecked(!checkboxChecked)}>
                    <Checkbox
                        checked={checkboxChecked}
                        activeColor={'#51a351'}
                        color={'#f1f1f1'}
                        styles={{ margin: 0 }}
                    />
                    <span>Vis ikke igen</span>
                </CheckboxWrapper>
                <Button accent onClick={setPinLogin}>
                    <Text align={'center'} bold size={'x2'}>
                        Brug PIN kode
                    </Text>
                </Button>
                <Button transperent onClick={onClose}>
                    <Text align={'center'} weight={'700'} size={'x1.5'}>
                        Fortsæt uden PIN kode
                    </Text>
                </Button>
            </TitleWrapper>
        </DraggablePopup>
    );
};

export default PinPopup;
