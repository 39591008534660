import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { BACKGROUNDS } from 'themes/index.ts';
import Icon from 'ui/Icon';
import istar from 'icons/star.svg';
import istarActive from 'icons/star_in_yellow.svg';
import iteam from 'icons/team.svg';
import useAuth from 'common/hooks/useAuth';
import usePreventDefault from 'common/hooks/usePreventDefault';
import { COLORS } from 'themes';
import { CloseButton } from './styled';
import getTexts from 'utils/localization';
import ActivePoolsCount from 'features/Spilklub2/components/ActivePoolsCount.tsx';

const t = getTexts();

const CommonCardWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #fff;
    border: solid 1px lightgrey;
    border-radius: 30px;
    padding: 10px;
    position: relative;
    ${({ disabled = false }) => (disabled ? 'opacity: .6' : '')};
`;

const Image = styled.div`
    min-width: 88px;
    height: 88px;
    background: url(${({ image }) => image || 'none'}) ${BACKGROUNDS.lightGrey} no-repeat center;
    background-size: cover;
    text-transform: uppercase;
    border-radius: 25px;
    border: solid 1px lightgrey;
    text-align: center;
    line-height: 90px;
    font-size: 35px;
`;

export const CardContent = styled.div`
    flex-direction: column;
    flex-basis: 80%;
    padding: 0 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    justify-content: ${({ showPoolSummary }) => (showPoolSummary ? 'space-between' : 'flex-start')};
`;

const Flag = styled.span`
    padding: 3px 5px;
    background-color: red;
    border-radius: 20px;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 10px;
`;

export const CardTitle = styled.p`
    font-size: 22px;
    margin: 0;
    color: #333333;
    font-weight: 900;
    line-height: 18px;
    ${({ showPoolSummary }) => !showPoolSummary && `padding-bottom: 10px`};
`;

const ActivePools = styled.span`
    color: #404757;
    font-family: 'Roboto Condensed', sans-serif;
    font-size: 14px;
    white-space: normal;
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

const Children = styled(Row)`
    font-size: 16px;
`;

const BottomLine = styled(Row)`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 15px;
`;

export const CommonTextWithBg = styled.div`
    height: 22px;
    background: ${({ background }) => background || '#404757'};
    border-radius: 9px;
    line-height: 22px;
    text-align: center;
    font-size: 14px;
    color: #fff;
    display: inline-block;
    padding: 0 10px;
    margin-top: 3px;
`;

const IconLeftGroup = styled.div`
    width: 125px;
    height: 22px;
    background: ${COLORS.red};
    border-radius: 9px;
    line-height: 22px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
    display: inline-block;
`;

const GroupIcon = styled.div`
    position: relative;
`;

const GroupCount = styled.div`
    min-width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #404757;
    text-align: center;
    position: absolute;
    font-size: 8px;
    line-height: 15px;
    font-weight: bold;
    color: ${COLORS.special.calm};
    top: 1px;
    left: -8px;
`;

const Badge = styled.span`
    font-weight: 900;
    font-size: 14px;
    background: #edf1f8;
    padding: 5px 10px;
    margin-right: 6px;
    border-radius: 3px;
    display: inline-flex;
    color: #333;
`;

const Card = ({
    image,
    flag = '',
    children = '',
    title = '',
    closable = false,
    showPoolSummary = false,
    settings,
    showBottomLineContent = true,
    showBoughtSharesLabel = true,
    ownerId,
    groupId,
    membersCount = 0,
    showMembersCount = true,
    favoriteGroups = [],
    disabled = false,
    noFavouriteButton = false,
    boughtShares = 0,
    activePools = [],
    setFavorite = () => {},
    onClose = () => {},
    onClick = () => {},
    styled: { CardWrapper = CommonCardWrapper, TextWithBg = CommonTextWithBg },
}) => {
    const auth = useAuth();
    const isCaptain = ownerId && auth.user && auth.user.id === ownerId;
    setFavorite = usePreventDefault(setFavorite, true);

    const BottomLineContent = () => (
        <BottomLine>
            {showMembersCount && (
                <GroupIcon>
                    {membersCount > 0 && <GroupCount>{membersCount}</GroupCount>}
                    <Icon
                        src={iteam}
                        style={{
                            marginLeft: '5px',
                            marginRight: '8px',
                        }}
                        size={'x15'}
                    />
                </GroupIcon>
            )}
            {!disabled && !noFavouriteButton ? (
                <Icon
                    onClick={setFavorite}
                    style={{
                        marginRight: '8px',
                        cursor: 'pointer',
                    }}
                    src={favoriteGroups.includes(groupId) ? istarActive : istar}
                    size={'x15'}
                />
            ) : null}

            {!disabled && isCaptain && (
                <TextWithBg style={{ marginRight: '8px' }}>{t.bb.group.captain}</TextWithBg>
            )}
            {!disabled && boughtShares > 0 && (
                <TextWithBg background={COLORS.primary}>{t.bb.group.boughtShares}</TextWithBg>
            )}
            {disabled && <IconLeftGroup>{t.bb.group.captainLeftGroup}</IconLeftGroup>}
        </BottomLine>
    );

    return (
        <CardWrapper onClick={onClick} disabled={disabled}>
            {<Image image={image}>{!image ? title[0] : ''}</Image>}
            <CardContent showPoolSummary={showPoolSummary}>
                {flag && <Flag>{flag}</Flag>}
                <CardTitle showPoolSummary={showPoolSummary}>{title}</CardTitle>
                <ActivePools>
                    <ActivePoolsCount activePools={activePools} />
                </ActivePools>
                {children && <Children>{children}</Children>}
                {showPoolSummary && settings && (
                    <div>
                        <Badge>
                            Solgte {boughtShares} / {settings.sharesLimit}
                        </Badge>
                        <Badge>Pris {settings.sharePrice} kr.</Badge>
                        {!disabled && boughtShares > 0 && showBoughtSharesLabel && (
                            <TextWithBg background={COLORS.primary}>
                                {t.bb.group.boughtShares}
                            </TextWithBg>
                        )}
                    </div>
                )}
                {showBottomLineContent && BottomLineContent()}
            </CardContent>
            {closable && (
                <CloseButton
                    style={{
                        position: 'absolute',
                        top: '-3px',
                        right: '5px',
                        padding: '10px',
                    }}
                    color={'secondary'}
                    onClick={onClose}
                />
            )}
        </CardWrapper>
    );
};

Card.propTypes = {
    image: PropTypes.string,
    flag: PropTypes.string,
    title: PropTypes.string,
    boughtShares: PropTypes.number,
    activePools: PropTypes.arrayOf(PropTypes.string),
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
        PropTypes.arrayOf(PropTypes.node),
    ]),
    settings: PropTypes.shape({
        sharePrice: PropTypes.number,
        sharesLimit: PropTypes.number,
        deadlineTime: PropTypes.number,
    }),
    showPoolSummary: PropTypes.bool,
    showMembersCount: PropTypes.bool,
    showBottomLineContent: PropTypes.bool,
    showBoughtSharesLabel: PropTypes.bool,
    ownerId: PropTypes.number,
    closable: PropTypes.bool,
    onClose: PropTypes.func,
    onClick: PropTypes.func,
};

export default Card;

export { CommonCardWrapper };
