import React from 'react';
import { Pick, Row, Reserve, ReserveNumber } from './styled';
import { Col } from 'react-grid-system';
import Label from 'ui/Label';
import { Ul } from 'ui/List';
import EmptyBetPickRow from 'features/BetSlip/components/BetPick/EmptyBetPickRow';

const BetPickRow = (props) => {
    const { rowLabel, bet, pickedStarts, renderItem } = props;
    let { reserves } = props;
    reserves = reserves || [];

    return !pickedStarts || pickedStarts.length === 0 ? (
        props.placeholders ? (
            <EmptyBetPickRow
                product={props.product}
                race={props.race}
                raceIndex={props.race?.index}
                isMultitrackProduct={props.isMultitrackProduct}
            />
        ) : null
    ) : (
        <Row>
            <Col xs={2} className="text-bold" style={{ padding: '5px 0 0', textAlign: 'center' }}>
                {rowLabel}.
            </Col>
            <Col xs={10} style={{ padding: '0 20px 0 0' }}>
                {pickedStarts && reserves && (pickedStarts.length > 1 || reserves.length > 0) ? (
                    <Ul>
                        {pickedStarts.map((start) =>
                            bet.isStartMarked(start.startNr) ? (
                                renderItem ? (
                                    renderItem(start, rowLabel)
                                ) : (
                                    <Pick key={start.startNr} scratched={start.scratched}>
                                        {start.startNr}
                                    </Pick>
                                )
                            ) : null
                        )}
                        {reserves.map((startNr, i) => (
                            <Reserve key={startNr}>
                                {startNr}
                                <ReserveNumber>{i + 1}</ReserveNumber>
                            </Reserve>
                        ))}
                    </Ul>
                ) : (
                    <Ul>
                        {renderItem ? (
                            renderItem(pickedStarts[0], rowLabel)
                        ) : (
                            <Pick scratched={pickedStarts[0].scratched}>
                                {pickedStarts[0].startNr}
                            </Pick>
                        )}{' '}
                        <Label scratched={pickedStarts[0].scratched}>
                            {pickedStarts[0].horse.horseNameAndNationality}
                        </Label>
                    </Ul>
                )}
            </Col>
        </Row>
    );
};

export default BetPickRow;
