import { makeRequest } from 'utils/REST';

import {
    DIBS_ACCEPT_RETURN_URL,
    DIBS_CANCEL_RETURN_URL,
    DIBS_CLIENT_NAME,
    DIBS_ERROR_URL,
    DIBS_EXCHANGE_DK,
    DIBS_PMNT,
} from 'configs/dibs';
import { DK_AUTH_REST_PATH } from 'configs/rest';

const merchantID = 824495;

class ApplePayService {
    amount = 0;
    session = undefined;
    validationURL = '';

    setValidationURL = (validationURL) => {
        this.validationURL = validationURL;
        return this;
    };

    request(requestType, url, data) {
        return makeRequest(requestType, url, data)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                console.log(data);
                return data;
            });
    }

    post(method, data) {
        return this.request('post', method, data);
    }

    get(method, data) {
        return this.request('get', method, data);
    }

    createPaymentRequest = (amount) => ({
        currencyCode: 'DKK',
        countryCode: 'DK',
        validationURL: this.validationURL,

        total: {
            label: 'Bet25 Heste deposit',
            type: 'final',
            amount,
        },

        merchantIdentifier: `merchant.${merchantID}`,

        merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],

        supportedNetworks: ['amex', 'masterCard', 'visa'],
    });

    setSession = (session) => {
        this.session = session;
    };

    validateMerchant = () => {
        // Call your own server to request a new merchant session.
        return this.verifyMerchantThroughOwnServer().then((res) => {
            if (res?.data?.merchantSessionIdentifier) {
                this.session.completeMerchantValidation(res?.data);
            } else {
                throw Error(
                    `Can not get merchant session identifier from server verification endpoint response.`
                );
            }
        });
    };

    verifyMerchantThroughOwnServer() {
        return this.get(
            `${DK_AUTH_REST_PATH}payment/dibs?op=applepayinit&merchant=${merchantID}&name=Derby25`
        ).then((res) => {
            return res;
        });
    }

    completePayment = (paymentData, amount) => {
        // Define ApplePayPaymentAuthorizationResult
        //@see https://developer.apple.com/documentation/apple_pay_on_the_web/apple_pay_js_api/apple_pay_status_codes

        return this.actualizeCompletePayment(amount, paymentData)
            .then((res) => {
                this.session.completePayment({
                    status: window.ApplePaySession.STATUS_SUCCESS,
                });
                return res;
            })
            .catch((e) => {
                console.log(e);
                this.session.completePayment({
                    status: window.ApplePaySession.STATUS_FAILURE,
                });
                return Promise.reject(
                    'Fejl - prøv igen og kontakt kundesupport hvis problemet fortsætter'
                );
            });
    };

    actualizeCompletePayment(inputAmount, payData) {
        return this.get(
            `${DK_AUTH_REST_PATH}payment/dibs?op=start&client=${DIBS_CLIENT_NAME}&exchcode=${DIBS_EXCHANGE_DK}&errorUrl=${DIBS_ERROR_URL}&amount=${
                inputAmount * 100
            }&input_amount=${inputAmount}&agree=on&acceptReturnUrl=${DIBS_ACCEPT_RETURN_URL}&cancelReturnUrl=${DIBS_CANCEL_RETURN_URL}&payType=applePay&payData=${payData}&pmnt=${DIBS_PMNT}`
        );
    }
}

export default ApplePayService;

export { merchantID };
