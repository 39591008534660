import { MoveUpper, TopBarText } from 'features/BetSlip/styled';
import classNames from 'classnames';
import moment from 'moment';
import TopBar from 'ui/topbar';
import React from 'react';

import getTexts from 'utils/localization';
const t = getTexts();

const Summary = ({ product, combinedTrackName, date, styles = {} }) => {
    return (
        <TopBar height="250px" style={styles.TopBar}>
            <MoveUpper>
                <TopBarText
                    className={classNames(['sm', 'uppercase'])}
                    style={styles.TopBarTextSm}
                >
                    {t.betSlip.topBarText}
                </TopBarText>
                <TopBarText
                    className={classNames(['lg', 'uppercase'])}
                    style={{ fontWeight: 500, ...styles.TopBarTextLg }}
                >
                    {product && `${combinedTrackName}: ${product.name}`}
                </TopBarText>
                <TopBarText className="sm" style={styles.TopBarTextSm}>
                    {moment(date, 'YYYY-MM-DD').format('DD.MM.YYYY')}
                </TopBarText>
            </MoveUpper>
        </TopBar>
    );
};

export default Summary;
