import { useDidUpdate, useEffectOnceWhen } from 'rooks';
import { generateEventPath, history } from 'utils/navigation';
import useURLParameters from './useURLParameters';
import useTrackPage from './useTrackPage';

const useAdjustATGLinks = () => {
    const { date, product, track: trackName, race: raceNr } = useURLParameters();

    const { raceDay, calendarFetched } = useTrackPage();

    useDidUpdate(() => {
        // ATG uses track names for multitrack setups separated by dash in their URL's (e.g. 'bjerke-axevalla')
        if (trackName.includes('-')) {
            const [firstTrackName] = trackName.split('-');
            history.replace(
                generateEventPath({ date, productId: product, track: firstTrackName, race: raceNr })
            );
        }
    }, [trackName]);

    useEffectOnceWhen(() => {
        // ATG redirects to our site without race number parameter, so we have to add it on our side
        if (!raceNr) {
            history.replace(
                generateEventPath({
                    date,
                    productId: product,
                    track: trackName,
                    race: raceDay.nextSaleOpeRaceNumber || 1,
                })
            );
        }
    }, calendarFetched);
};

export default useAdjustATGLinks;
