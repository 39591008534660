import React from 'react';
import styled from 'styled-components';

import PoolOverview from 'features/TrackPage/components/PoolOverview/PoolOverviewRow';
import { PoolOverview as PoolOverviewDTO } from 'common/DataObjects/PoolOverview';
import { COLORS } from 'themes';
import { Card } from 'ui/Card';
import { BolderText } from 'features/UserArea/SelfExclusion/styled';
import { byPlatform } from 'utils/platforms';
import useMegredPoolsQuery from 'features/TrackPage/hooks/useMegredPoolsQuery';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';

const StyledCard = styled(Card)`
    box-sizing: border-box;
    padding: 5px 30px 10px;
    display: inline-block;
    width: ${byPlatform('100%', 'auto')};
    margin: ${byPlatform('15px 0', '0 0 15px')};
`;

const PoolOverviewItem = styled.div`
    font-size: ${byPlatform('auto', '16px')};
    padding-right: 10px;
    display: inline;
`;

const Label = styled.span`
    font-weight: 700;
    color: ${COLORS.black};
    box-sizing: border-box;
`;

const Value = styled.span`
    font-weight: 900;
    padding: 0 0 0 2px;
    color: ${COLORS.primaryText};
    box-sizing: border-box;
    color: ${COLORS.black};
`;

const MergedPools = ({ race }) => {
    const { raceDay, trackId, poolQueryData } = useTrackPage();

    const { data: mergedPools } = useMegredPoolsQuery({
        date: raceDay.raceDayDate,
        trackId,
        enabled: Boolean(raceDay.raceDayDate) && Boolean(trackId),
    });

    const trackName = race.hostTrack?.domesticText ?? race.track?.domesticText;

    const poolOverview = new PoolOverviewDTO().unserialize(poolQueryData, mergedPools);

    return (
        <StyledCard>
            <BolderText size="x05" style={{ fontSize: '12px' }}>{`Omsætning ${
                trackName ? trackName : ''
            }:`}</BolderText>
            <PoolOverview
                poolOverview={poolOverview}
                race={race}
                Item={PoolOverviewItem}
                Label={Label}
                Value={Value}
            />
        </StyledCard>
    );
};

export default MergedPools;
