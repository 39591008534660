class Breed {
    code = null;
    domesticText = null;
    englishText = null;

    static unserialize(data) {
        const breed = new Breed();

        try {
            breed.code = data.code;
            breed.domesticText = data.domesticText;
            breed.englishText = data.englishText;
        } catch (error) {
            //@TODO Logger of errors
        }

        return breed;
    }
}

class Colour {
    code = null;
    domesticText = null;
    englishText = null;

    static unserialize(data) {
        const object = new Colour();

        try {
            object.code = data.code;
            object.domesticText = data.domesticText;
            object.englishText = data.englishText;
        } catch (error) {
            //@TODO Logger of errors
        }

        return object;
    }
}

class Dam {
    id = null;
    name = null;
    seRegNr = null;
    uelnNr = null;

    static unserialize(data) {
        const object = new Colour();

        try {
            object.id = data.id;
            object.name = data.name;
            object.seRegNr = data.seRegNr;
            object.uelnNr = data.uelnNr;
        } catch (error) {
            //@TODO Logger of errors
        }

        return object;
    }
}

class DamSire extends Dam {
    static unserialize(data) {
        return Dam.unserialize(data);
    }
}

class Key extends Dam {
    static unserialize(data) {
        return Dam.unserialize(data);
    }
}

class Sex extends Colour {
    static unserialize(data) {
        return Colour.unserialize(data);
    }
}

class Sire extends Dam {
    static unserialize(data) {
        return Dam.unserialize(data);
    }
}

export default class Horse {
    age = null;
    breeder = null;
    horseNameAndNationality = null;
    linkable = null;
    nationalityBorn = null;
    nationalityOwner = null;
    nationalityRaised = null;
    owner = null;
    ownerId = null;
    swedenReg = null;
    thoroughbred = null;
    breed = null;
    colour = null;
    dam = null;
    damSire = null;
    key = null;
    sex = null;
    sire = null;

    constructor() {
        return this;
    }

    static unserialize(data) {
        const horse = new Horse();

        try {
            horse.age = data.age;
            horse.breeder = data.breeder;
            horse.horseNameAndNationality = data.horseNameAndNationality;
            horse.linkable = data.linkable;
            horse.nationalityBorn = data.nationalityBorn;
            horse.nationalityOwner = data.nationalityOwner;
            horse.nationalityRaised = data.nationalityRaised;
            horse.owner = data.owner;
            horse.ownerId = data.ownerId;
            horse.swedenReg = data.swedenReg;
            horse.thoroughbred = data.thoroughbred;
            horse.breed = Breed.unserialize(data.breed);
            horse.colour = Colour.unserialize(data.colour);
            horse.dam = Dam.unserialize(data.dam);
            horse.damSire = DamSire.unserialize(data.damSire);
            horse.key = Key.unserialize(data.key);
            horse.sex = Sex.unserialize(data.sex);
            horse.sire = Sire.unserialize(data.sire);
        } catch (error) {
            //@TODO Logger of errors
        }

        return horse;
    }
}
