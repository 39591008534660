import styled from 'styled-components';
import BaseIcon from 'ui/Icon';
import Text from 'ui/Text';
import { BACKGROUNDS, COLORS } from 'themes';
import { fadeInMixin } from 'ui/animations/FadeIn2';

const Icon = styled(BaseIcon)`
    margin-right: 10px;
`;

const PinTip = styled(Text)`
    padding-top: 0;
    @media screen and (max-height: 610px) {
        display: none;
    }
`;

const Bullets = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 7;
    ${fadeInMixin};
`;

const Bullet = styled.div`
    height: ${(props) => props.size};
    width: ${(props) => props.size};
    border-radius: ${(props) => props.size};
    margin: 10px;
    background: ${BACKGROUNDS.white};
    box-sizing: border-box;
    transition: all 100ms ease;
    &.active {
        background: #13a660;
        height: 30px;
        width: 30px;
        margin: 23px;
    }
    ${({ processing }) => processing && 'border: 3px solid #13a660'};
`;

const BulletsWithTItle = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const Title = styled.h4`
    color: ${COLORS.white};
    text-align: center;
    font-size: 28px;
    font-weight: 900;
`;

const ForgotPassword = styled.button`
    width: 100%;
    background: ${BACKGROUNDS.lightGrey};
    font-size: 18px;
    font-weight: 900;
    color: ${COLORS.black};
    height: 60px;
    border: 0;
    border-radius: 5px;
    margin-bottom: 20px;
`;

export { Icon, Title, PinTip, Bullets, Bullet, BulletsWithTItle, ForgotPassword };
