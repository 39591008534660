import { chain, get } from 'lodash';
import { dateStringFromDateTimeString } from 'utils/date';
import { RaceDay } from 'features/EventBoard/server/calendar';
import { PoolInformationItem } from 'features/EventBoard/hooks/useAllPoolInformationQuery';

export function getTracksJackpotAvailabilityMap(
    raceDay: RaceDay,
    allPoolInformation: PoolInformationItem[]
) {
    return chain(allPoolInformation)
        .filter(({ startDate }) => {
            return dateStringFromDateTimeString(startDate) === raceDay.raceDayDate;
        })
        .groupBy('trackId')
        .map((trackPools, trackId) => {
            const hasJackpot = trackPools.some(
                trackPool => Number(get(trackPool, 'jackpot.sum')) > 0
            );

            return [Number(trackId), hasJackpot];
        })
        .fromPairs()
        .value();
}
