import React from 'react';
import PlayButtonWrapper, {
    PlayButtonStyle,
    PlayIconSettings,
} from './PlayButtonWrapper';
import Thumbnail, { ThumbnailProps } from './Thumbnail';

interface PlayableThumbnail extends ThumbnailProps, PlayIconSettings {
    round?: boolean;
    playButtonStyles?: PlayButtonStyle;
    renderPlayIcon?: () => React.ReactNode;
}

const PlayableThumbnail = ({
    videoId,
    size,
    originalSize,
    title,
    alt,
    playIconSize,
    round,
    playButtonStyles,
    renderPlayIcon,
}: PlayableThumbnail) => {
    return (
        <PlayButtonWrapper
            size={size}
            playIconSize={playIconSize}
            round={round}
            styles={playButtonStyles}
            renderPlayIcon={renderPlayIcon}
        >
            <Thumbnail
                videoId={videoId}
                originalSize={originalSize}
                size={size}
                title={title}
                alt={alt}
            />
        </PlayButtonWrapper>
    );
};

export default PlayableThumbnail;
