import React from 'react';
import Articles from '../features/Articles/Articles';
import HowToPlayDescription from '../features/Articles/HowToPlayDescription';
import Wrapper from 'ui/Wrapper';
import { withRouter } from 'react-router-dom';
import { byPlatform } from 'utils/platforms';
import Article from 'features/Articles/Article';

class HowToPlay extends React.Component {
    onCardClick = (productId) => {
        this.props.history.push('/sadan-spiller-du/' + productId);
    };

    render() {
        const styles = {
            StyledListItem: {
                maxWidth: byPlatform('100%', '180px'),
                margin: byPlatform('15px 0', '30px 20px'),
            },
            HeadLine: {
                padding: byPlatform('3px 10px'),
            },
            card: {
                height: byPlatform('auto', '275px'),
            },
            img: {
                height: '190px',
                filter: 'grayscale(100%)',
                transition: 'all .5s ease-in-out',
                hover: 'filter: none',
            },
            textWrapper: {
                padding: '5px 25px',
            },
            text: {
                height: '30px',
                overflow: 'hidden',
                fontSize: '13px',
            },
        };
        return (
            <Wrapper padding="20px">
                <HowToPlayDescription />
                <Articles
                    articleType="race"
                    itemsPerRow={byPlatform(1, 5)}
                    styles={styles}
                    itemStyle={byPlatform('100%')}
                    onCardClick={this.onCardClick}
                />
            </Wrapper>
        );
    }
}

export const ProductDetails = ({ match }) => {
    const articleIds = {
        link: match.params.link,
        productId: match.params.productId,
    };

    const styles = {
        Wrapper: {
            padding: byPlatform('30px'),
        },
        StyledArticleWrapper: {
            padding: byPlatform(0),
        },
        Card: {
            borderRadius: byPlatform('5px'),
            marginTop: byPlatform(0),
            marginBottom: byPlatform('10px'),
        },
        StyledCardImg: {
            height: byPlatform('180px'),
            borderRadius: byPlatform('5px'),
        },
        StyledHeadLine: {
            fontWeight: byPlatform('900'),
            fontSize: byPlatform('18px'),
            padding: byPlatform('8px 0'),
        },
        StyledTextContainer: {
            fontSize: byPlatform('14px'),
            paddingTop: byPlatform(0),
        },
    };

    return <Article articleIds={articleIds} styles={styles} />;
};

export default withRouter(HowToPlay);
