import { useQuery } from '@tanstack/react-query';
import useTrackPage from './useTrackPage';
import useURLParameters from './useURLParameters';
import { useMemo } from 'react';
import { hasResultByRaceNr, isVProduct } from 'features/EventBoard/server/calendar';
import {
    fetchPoolResults,
    PoolResultsQueryData,
    sortWinners,
    WinnersProps,
} from 'features/ResultsNew/hooks/usePoolResultsQuery';

const usePoolResultsQueryForReplay = () => {
    const { date, race: raceNumber } = useURLParameters();
    const { trackId, productId, raceDay, product, trackNames } = useTrackPage();

    const { status, data, error, isSuccess, isLoading } = useQuery<
        unknown,
        Error,
        Array<PoolResultsQueryData>
    >({
        queryKey: ['poolResultsTrack', date, trackId, productId],
        queryFn: () =>
            fetchPoolResults({ date, trackId, poolType: productId, actualTrack: trackId }),
        enabled:
            hasResultByRaceNr(raceDay, raceNumber) &&
            trackId > 0 &&
            !!date &&
            !isVProduct(productId),
        staleTime: 60 * 10 * 1000,
    });

    const winners = useMemo((): Array<WinnersProps> => {
        return data?.length
            ? sortWinners(data[Number(raceNumber) - 1]?.legs[0]?.winners).slice(0, 3)
            : [];
    }, [data, raceNumber]);

    return {
        status,
        results: data,
        winners,
        raceNumber,
        productId,
        productName: product.name,
        trackName: trackNames.join(' '),
        error,
        isSuccess,
        isLoading,
    };
};

export default usePoolResultsQueryForReplay;
