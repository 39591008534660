import React from 'react';
import { thousandsDots } from 'utils/formatters';
import { Horse } from 'features/TrackPage/model/Race';

interface EarningColProps {
    horse: Horse;
}

const EarningCol = ({ horse }: EarningColProps) => {
    return (
        <>
            {horse.horseStat.total?.earning?.sum
                ? thousandsDots(horse.horseStat.total?.earning?.sum) +
                  ' ' +
                  horse.horseStat.total?.earning?.currency
                : 'n/a'}
        </>
    );
};

export default EarningCol;
