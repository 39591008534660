import { useEffect } from 'react';
import Group from '../model/Group';
import useAuth from 'common/hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import {
    getActiveGroupId,
    getActivePoolsByGroupId,
    getGroupById,
    getGroups,
    getPublicGroups,
    getGroupsCreatedByUser,
    getGroupsPageNumber,
    getGroupsPageSize,
    getStatus,
    getTotalGroupsNumber,
    Status,
    getPublicGroupsPageNumber,
    getPublicGroupsPageSize,
    getTotalPublicGroupsNumber,
    getFilteredGroups,
} from '../selectors';
import {
    fetchGroupsAsync,
    fetchPublicGroupsAsync,
    getGroupAsync,
    fetchGroupsCreatedByUserAsync,
    setUIProps,
    clearGroupsReducer,
    fetchFilteredGroupsAsync,
} from '../actions';
import Pool from '../model/Pool';
import { useHistory } from 'react-router-dom';
import { FilteredGroupsInput } from '../server';
import { isMobile } from 'utils/platforms';

export interface GroupsPages {
    pageNumber: number;
    pageSize: number;
    totalGroupsNumber: number;
}

interface UseGroupsOutput {
    groups: Group[];
    filteredGroups: Group[];
    publicGroups: Group[];
    group: Group;
    groupsPending: boolean;
    groupInfoPending: boolean;
    groupsError: string;
    groupInfoError: string;
    singleGroupMode: boolean;
    groupStatus: Status;
    enableGroupMode(groupId: number, isPersonalGroup: boolean, isPublicGroup?: boolean): void;
    disableGroupMode(): void;
    activeBets: Pool[];
    //finishedBets: Bet[];
    groupsCreatedByUser: Group[];
    groupsPages: GroupsPages;
    publicGroupsPages: GroupsPages;
    fetchMemberGroups(): void;
    fetchFilteredGroups(input: FilteredGroupsInput): void;
}

const useGroups = (pageNumber: number = 1, publicGroupPageNumber: number = 1): UseGroupsOutput => {
    const dispatch = useDispatch();

    const groups = useSelector(getGroups);
    const publicGroups = useSelector(getPublicGroups);
    const groupsCreatedByUser = useSelector(getGroupsCreatedByUser);
    const filteredGroups = useSelector(getFilteredGroups);
    const selectedGroupId = useSelector(getActiveGroupId);

    const group = useSelector(state => getGroupById(state, selectedGroupId));

    const activeBets = useSelector(state => getActivePoolsByGroupId(state, selectedGroupId));

    const fetchMemberGroups = () => {
        dispatch(
            fetchGroupsAsync.request({
                userId,
                pageNumber,
                pageSize: 10, //@TODO: remove strict number
            })
        );
    };

    const fetchFilteredGroups = (input: FilteredGroupsInput) => {
        dispatch(fetchFilteredGroupsAsync.request(input));
    };

    /**
     * TODO: Refactor getting page Numbers for different types of groups
     */
    const groupsPages = {
        pageNumber: useSelector(getGroupsPageNumber),
        pageSize: useSelector(getGroupsPageSize),
        totalGroupsNumber: useSelector(getTotalGroupsNumber),
    };

    const publicGroupsPages = {
        pageNumber: useSelector(getPublicGroupsPageNumber),
        pageSize: useSelector(getPublicGroupsPageSize),
        totalGroupsNumber: useSelector(getTotalPublicGroupsNumber),
    };

    const [groupsPending, groupsError] = useSelector(state => getStatus(state, 'groupList'));

    const groupStatus = useSelector(state => getStatus(state, 'groupItem'));

    const [groupInfoPending, groupInfoError] = groupStatus;

    const auth = useAuth();

    const history = useHistory();

    const userId = auth.user ? auth.user.id : 0;
    // effects
    useEffect(() => {
        if (!auth.user || !auth.user.id) {
            return;
        }

        fetchMemberGroups();

        dispatch(
            fetchGroupsCreatedByUserAsync.request({
                userId,
                pageNumber,
                pageSize: 20, //@TODO: remove strict number
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, pageNumber]);

    useEffect(() => {
        dispatch(
            fetchPublicGroupsAsync.request({
                userId,
                pageNumber: publicGroupPageNumber,
                pageSize: 10, //@TODO: remove strict number
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, publicGroupPageNumber]);

    const disableGroupMode = () => {
        dispatch(setUIProps({ activeGroupId: 0 }));
        dispatch(setUIProps({ isPersonalGroup: false }));
        //TODO: needs to be refactored
        if (group.id !== 0 && !group.groupMember) {
            isMobile && history.push('/spil-klub25');
            dispatch(clearGroupsReducer());

            fetchMemberGroups();
        }
    };

    const enableGroupMode = (
        groupId: number,
        isPersonalGroup: boolean,
        isPublicGroup: boolean = false
    ) => {
        dispatch(setUIProps({ activeGroupId: groupId }));
        dispatch(setUIProps({ isPersonalGroup }));
        dispatch(setUIProps({ isPublicGroup }));
        dispatch(getGroupAsync.request(groupId));
    };

    return {
        groups,
        filteredGroups,
        group,
        groupsPending,
        groupInfoPending,
        groupsError,
        groupInfoError,
        groupStatus,
        singleGroupMode: selectedGroupId !== 0,
        activeBets,
        //finishedBets,
        disableGroupMode,
        enableGroupMode,
        groupsCreatedByUser,
        groupsPages,
        publicGroups,
        publicGroupsPages,
        fetchMemberGroups,
        fetchFilteredGroups,
    };
};

export default useGroups;
