import HistoryContainer from './HistoryContainer';

class ActiveBets extends HistoryContainer {
    componentDidMount() {
        this.setDataHandlerName('getActiveBets');

        super.componentDidMount();
    }
}

export default ActiveBets;
