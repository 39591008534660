import { create } from 'zustand';

interface TransactionState {
    dateFrom: string;
    dateTo: string;
    group: 0 | 1 | 2; // group can only be 0, 1, or 2
    rowCount: number;
}

interface TransactionStateStore {
    transactionState: TransactionState | null;
    setTransactionState: (data: Partial<TransactionState>) => void;
}

const useTransactionStateStore = create<TransactionStateStore>(set => ({
    transactionState: null,

    setTransactionState: data => {
        set(state => ({
            transactionState: {
                ...(state.transactionState ?? {}), // Initialize as empty object if null
                ...data,
            } as TransactionState,
        }));
    },
}));

export default useTransactionStateStore;
