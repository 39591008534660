import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Button from 'ui/controls/Button';
import { BACKGROUNDS } from 'themes';
import { getIn } from 'immutable';
import getTexts from 'utils/localization';
import { COLORS } from 'themes';
import { generateRaceKey } from 'features/TrackPage/components/RaceCard/Desktop/VRaceOverview/utils';

const t = getTexts();

const SelectAllButton = styled(Button)`
    background: ${({ active }) => (active ? '#34be7a' : BACKGROUNDS.grey)};
    transition: background 300ms ease;
    font-weight: 900;
    font-size: 10px;
    border: ${({ active }) => (active ? `0.5px solid ${COLORS.flatGreen}` : '0.5px solid #c6ccdb')};
    ${({ active }) => active && `color: ${COLORS.white}`};
    &:hover {
        background: ${({ active }) => (active ? '#34be7a' : '#c6ccdb')};
    }
`;

const SelectRowButton = props => {
    const [isDisabled, setIsDisabled] = useState(false);
    const {
        date,
        product,
        selectedBets,
        addAllBets,
        resetRaceBet,
        race: { trackCode, index: raceIndex },
    } = props;

    const checkSelection = () => {
        return getIn(
            selectedBets,
            [generateRaceKey(date, trackCode, product.id, raceIndex)],
            false
        );
    };
    let isActiveRow = checkSelection();

    useEffect(() => {
        isActiveRow = checkSelection();
    }, [selectedBets]);

    useEffect(() => {
        const { race, raceReserves, reserveModeEnabled } = props;
        if (isDisabled) {
            mapBets(race.starts, raceReserves, reserveModeEnabled, trackCode, !isActiveRow).then(
                () => {
                    setIsDisabled(false);
                }
            );
        }
    }, [isDisabled]);

    const toggleBet = (start, raceReserves, reserveModeEnabled, trackCode, activate) => {
        const startNumber = start.startNr;
        const reserved = raceReserves.includes(startNumber);
        if (!start.isScratched()) {
            if (reserveModeEnabled) {
                !reserved
                    ? props.addReserve && props.addReserve(startNumber)
                    : props.removeReserve && props.removeReserve(startNumber);
            } else {
                activate
                    ? onActivate(startNumber, startNumber, trackCode, props.betBuddySelectionsMode)
                    : onDisactivate(startNumber, startNumber, trackCode);
            }
        }
    };

    const mapBets = async (starts, raceReserves, reserveModeEnabled, trackCode, activate) => {
        let selectedStarts = {},
            betsByDates = {};

        betsByDates = { [raceIndex]: selectedStarts };
        if (activate) {
            addAllBets(date, trackCode, product.id, raceIndex, betsByDates);
            starts.forEach(start => {
                toggleBet(start, raceReserves, reserveModeEnabled, trackCode, activate);
                if (!start.isScratched()) {
                    selectedStarts[start.startNr] = [start.startNr];
                }
            });
        } else {
            resetRaceBet(date, trackCode, product.id, raceIndex, props.betBuddySelectionsMode);
        }
    };

    const clickHandler = e => {
        setIsDisabled(true);
        e.stopPropagation();
    };

    const onActivate = (betValue, startNr, trackCode) => {
        props.onBetAdded && props.onBetAdded(props.race, startNr, betValue, trackCode);
    };

    const onDisactivate = (betValue, startNr, trackCode) =>
        props.onBetRemoved && props.onBetRemoved(props.race, startNr, betValue, trackCode);

    return (
        <SelectAllButton
            width="80px"
            active={isActiveRow}
            disabled={isDisabled}
            grey
            onClick={clickHandler}
        >
            {t.RacingCard.vRaceHeader.all}
        </SelectAllButton>
    );
};

export default SelectRowButton;
