import React from 'react';
import moment from 'moment';

import {
    Divider,
    TrackMiddleSection,
    TrackSelectionContainer,
} from 'features/EventBoard/components/styled';
import { ResultTrackStateLabel } from 'features/ResultsNew/components/styled';

import TrackNameWithCountry from 'features/EventBoard/components/TrackNameWithCountry';
import TrackProducts from 'features/EventBoard/components/TrackProducts';
import ResultsTrackSelectionPreloader from 'features/ResultsNew/components/ResultsTrackSelectionPreloader';

import { TrackResult } from 'features/ResultsNew/hooks/useTracksResultsQuery';
import { ProductId } from 'features/EventBoard/server/calendar';
import useCalendarDate from 'features/ResultsNew/hooks/useCalendarDate';

import { isMobile } from 'utils/platforms';
import { history } from 'utils/navigation';

interface ResultTrackSelectionProps {
    tracks: Array<TrackResult>;
    onRaceDaySelected: (raceDay: TrackResult) => void;
    isLoading: boolean;
    isFirstVersion?: boolean;
    upcoming?: boolean;
}

const ResultTrackSelection = ({
    tracks = [],
    onRaceDaySelected,
    upcoming = false,
    isLoading,
    isFirstVersion = true,
}: ResultTrackSelectionProps) => {
    const { date } = useCalendarDate();

    const navigateToProduct = (productId: ProductId, track: TrackResult) => {
        return history.push(`/resultat/${date}/${productId}/${track.track.trackId}/1`);
    };

    if (isLoading) {
        // return <TrackSelectionPreloader />;
        return <ResultsTrackSelectionPreloader />;
    }

    return (
        <>
            {tracks.map((track, index) => {
                const startedTime = upcoming
                    ? moment(track.firstRacePostTimeUTC).format('HH:mm')
                    : '';
                return (
                    <TrackSelectionContainer
                        key={index}
                        onClick={() => {
                            onRaceDaySelected(track);
                        }}
                    >
                        {isMobile ? (
                            <>
                                <div className={'space-between center-v'} style={{ gap: 10 }}>
                                    <TrackNameWithCountry
                                        trackCode={track.track.country}
                                        trackName={track.track.track.domesticText}
                                    />
                                </div>
                                <Divider />
                                <TrackProducts
                                    onProductClick={productId => {
                                        navigateToProduct(productId, track);
                                    }}
                                    products={track.frontPageProducts}
                                />
                            </>
                        ) : (
                            <>
                                <TrackNameWithCountry
                                    trackCode={track.track.country}
                                    trackName={track.track.track.domesticText}
                                />
                                <TrackMiddleSection>
                                    <TrackProducts
                                        onProductClick={productId => {
                                            navigateToProduct(productId, track);
                                        }}
                                        products={track.frontPageProducts}
                                    />
                                </TrackMiddleSection>
                                {!isFirstVersion && (
                                    <ResultTrackStateLabel color={upcoming ? '#333' : '#b2b9c6'}>
                                        {upcoming ? `Starter ${startedTime}` : 'Afsluttet'}
                                    </ResultTrackStateLabel>
                                )}
                            </>
                        )}
                    </TrackSelectionContainer>
                );
            })}
        </>
    );
};

export default ResultTrackSelection;
