import React from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import MobileBet from './MobileBet';
import betTableSelector from 'common/selectors/betTableSelector';
import betSlipSelector, { getReserves } from 'common/selectors/betSlipSelector';
import combinationsCountSelector from 'common/selectors/combinationsCountSelector';
import { getCombinedTrackName, getTrackFromRacingCard } from 'common/selectors/trackSelector';
import RacingCard from 'common/DataObjects/RacingCard';
import racesSelector from 'common/selectors/racesSelector';
import { isMultitrackProduct } from 'common/selectors/multipleTrackSetupsSelector';
import productSelector from 'common/selectors/productSelector';
import { useData, deleteMobileBet } from './hooks/useData';
import { hideModal, showModal } from 'common/actions/uiActions';
import getTexts from 'utils/localization';
import LocalMobileBet from './LocalMobileBet';
import { setMobileBetInit, addMobileBetCount } from './state/actions';
import { get, cloneDeep } from 'lodash';

const t = getTexts();

const MobileBetContainer = (props) => {
    const dispatch = useDispatch();
    const betData = cloneDeep(props);
    betData.user = useSelector((state) => state.auth.user);

    const [data, isPending] = useData(betData);

    if (!isPending && props.mobileBetInit) {
        dispatch(setMobileBetInit(false));
    }

    const saveBetToLocal = (id, startDateTime) => {
        dispatch(addMobileBetCount());
        LocalMobileBet.add(id, startDateTime);

        dispatch(showModal('SUCCESS_BET_SAVING', 103));
        setTimeout(() => {
            dispatch(hideModal('SAVED_BET_CONTAINER'));
            dispatch(hideModal('SUCCESS_BET_SAVING'));
        }, 3000);
    };

    const closeModal = () => {
        dispatch(hideModal('SAVED_BET_CONTAINER'));

        if (props.mobileBetInit) {
            dispatch(setMobileBetInit(false));
        }
    };

    return (
        <MobileBet
            isPending={isPending}
            yellowButtonText={t.Terminal.mobileBet.saveCoupon}
            onClose={(id) => {
                deleteMobileBet(id);
                closeModal();
            }}
            onYellowButtonClick={(id, startDateTime) => {
                saveBetToLocal(id, startDateTime);
            }}
            showShopList={() => {
                dispatch(showModal('SHOP_LIST', 103));
            }}
            data={data}
        />
    );
};

const mapStateToProps = (state) => {
    const product = productSelector(state);
    const racingCard = RacingCard.fill(state.AISDataProvider);
    const raceNumber = state.AISDataProvider.selectedRaceNumber;
    const race = product.isVProduct()
        ? racingCard.races[0]
        : racingCard.races.find((r) => r.raceNumber === raceNumber);
    const isMultitrack = isMultitrackProduct(state);
    const betSlip = betSlipSelector(state);
    const track = getTrackFromRacingCard(state);
    const date = racingCard.date;
    const bet = betTableSelector(state);
    const reserves = getReserves(state);
    const multitrackReserves = get(state, 'BetSlip.reservesByDates[date]', []);

    return {
        user: state.auth.user,
        date,
        race,
        raceNumber,
        races: racesSelector(state),
        betSlip,
        strictMode: state.BetSlip.strictMode,
        smartLynMode: state.BetSlip.smartLynMode,
        combinedTrackName: getCombinedTrackName(state),
        trackCode: track.code,
        trackId: track.id,
        product,
        bet,
        combinationsCount: combinationsCountSelector(state),
        serverTime: state.AISDataProvider.serverTime,
        isMultitrackProduct: isMultitrack,
        reserves,
        multitrackReserves,
        mobileBetInit: state.MobileBet.initialization,
    };
};

export default connect(mapStateToProps)(MobileBetContainer);
