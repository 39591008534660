import { CONTENT_API_BASE_URL } from 'configs/rest';
import { makeRequest } from 'utils/REST';

export function get(method, data) {
    return request('get', CONTENT_API_BASE_URL + method, data, {
        credentials: 'omit',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
    });
}

function request(requestType, url, data, fetchParams) {
    return makeRequest(requestType, url, data, fetchParams).then((response) => {
        return response.json();
    });
}
