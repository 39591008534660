import React, { Fragment } from 'react';
import styled from 'styled-components';
import Articles from 'features/Articles/Articles';
import Wrapper from 'ui/Wrapper';
import Article from 'features/Articles/Article';
import { COLORS } from 'themes';

const NewsContent = styled(Wrapper)`
    padding: 10px 20px;
    box-sizing: border-box;
    max-width: 100%;
`;

const NewsMobile = ({ history, match, style }) => {
    const articleId = match.params.link;
    const wrapperStyle = style || {};

    const onArticleSelect = (articleId) => {
        history.push(`/25-nyt/${articleId}`);
    };

    return (
        <NewsContent listShown={!articleId}>
            {articleId ? (
                <Fragment>
                    <Article
                        id={articleId}
                        articleIds={{ link: articleId }}
                        styles={{
                            Wrapper: {
                                ...wrapperStyle,
                                padding: 0,
                            },
                            Card: {
                                marginTop: '30px',
                            },
                            StyledHeadLine: {
                                fontSize: '19px',
                                fontWeight: 600,
                                padding: '30px 0 0',
                            },
                            StyledCardImg: {
                                height: '140px',
                            },
                            StyledArticleWrapper: {
                                padding: '0',
                            },
                            StyledTextContainer: {
                                fontSize: '15px',
                                fontWeight: 400,
                                padding: '0',
                            },
                            StyledAuthorImg: {
                                marginLeft: '5px',
                            },
                            AuthorData: {
                                fontWeight: '600',
                                fontSize: '12px',
                            },
                            Date: {
                                fontSize: '12px',
                                fontWeight: '300',
                            },
                            ShareArticleWrapper: {
                                padding: '10px 0',
                            },
                            ShareArticleText: {
                                paddingLeft: '0px',
                                fontWeight: '600',
                                fontSize: '11px',
                                lineHeight: '19px',
                            },
                        }}
                    />
                </Fragment>
            ) : (
                <Articles
                    articleType="news"
                    onCardClick={onArticleSelect}
                    styles={{
                        Container: {
                            justifyContent: 'space-evenly',
                        },
                        StyledListItem: {
                            margin: '15px 0',
                            minWidth: '294px',
                            height: 'auto',
                        },
                        card: {
                            height: 'auto',
                        },
                        Headline: {
                            fontWeight: 'bold',
                            fontSize: '24px',
                            color: COLORS.primaryText,
                            fontFamily: 'Roboto Condensed',
                        },
                        text: {
                            fontSize: '18px',
                        },
                    }}
                />
            )}
        </NewsContent>
    );
};

export default NewsMobile;
