import React from 'react';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import getTexts from 'utils/localization';
import { RaceInfoContainer } from './styled';
const t = getTexts();

const RaceInfo = () => {
    const { raceDay, isMultitrack, race, racesFetched } = useTrackPage();
    const trackName = isMultitrack ? race.hostTrack?.domesticText : raceDay.trackName;

    const startMethodTitle = race?.trotRaceInfo?.startMethod?.domesticText;
    const surface = race.gallopRaceInfo?.trackSurface?.domesticText;

    if (!racesFetched) {
        return null;
    }

    return (
        <RaceInfoContainer>
            {trackName + '  |  '}
            {race?.trotRaceInfo?.startMethod
                ? (race?.trotRaceInfo.monte ? t.RaceType.monte : t.RaceType.trot) + '  |  '
                : t.RaceType.gallop + '  |  '}
            {race?.gallopRaceInfo?.trackSurface?.code === 'gr'
                ? 'GRÆS  |  '
                : surface
                ? surface + '  |  '
                : null}
            {startMethodTitle ? startMethodTitle + '  |  ' : null}
            {race.distance} M {'  |  '}
            {t.RacingCard.race} {race.raceNumber}
        </RaceInfoContainer>
    );
};

export default RaceInfo;
