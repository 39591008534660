import React from 'react';
import styled from 'styled-components';
import { BACKGROUNDS, COLORS } from 'themes';
import { isMobile } from 'react-device-detect';

import { Bullet } from 'features/Login/Pin/styled';
import { Button } from 'features/Login/Pin/Keyboard';

import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import { ReactComponent as QuestionIcon } from './assets/question.svg';
import { ReactComponent as CloseSvg } from 'icons/close.2.svg';

import cardsImage from './assets/cards.jpg';

import { rowPreloaderAnimation } from 'ui/animations/RowPreloader';
import TooltipPopup from 'ui/TooltipPopup';
import Flex from 'ui/Flex';
import Text from 'ui/Text';
import { fadeInMixin } from 'ui/animations/FadeIn2';
import FlexWrapper from 'ui/FlexWrapper';

export const BACKGROUND = '#F6F7FC';

export const SpilIdWrapper = styled(Flex)`
    background-color: ${BACKGROUND};
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 38px;
    box-sizing: border-box;
`;

export const SpilIdButtonsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 20px auto 0;
`;

export const SpilIdBtn = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    background: #edf1f8;
    border-radius: 10px;
    color: #333333;
    font-size: 18px;
    font-weight: 900;
    padding: 15px 20px;
    box-sizing: border-box;
    height: 60px;
    margin-bottom: 20px;
    opacity: ${(props) => (props.muted ? '0.3' : 1)};
    ${({ muted }) => !muted && 'cursor: pointer'};
`;

export const SpilIdLink = styled(SpilIdBtn).attrs({ as: 'a' })``;

export const Title = styled.div`
    font-size: 22px;
    font-weight: 900;
`;
export const DescriptionText = styled.p`
    font-size: 16px;
`;

export const Wrapper = styled.div`
    width: 100%;
    font-size: 20px;
    color: #333333;
    font-weight: 900;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 22px 24px;
    box-sizing: border-box;
`;

export const BackButton = styled(ArrowIcon)`
    fill: ${({ color }) => color};
    transform: rotateZ(180deg);
    cursor: pointer;
`;

export const QuestionMark = styled(QuestionIcon)`
    fill: ${({ color }) => color};
    cursor: pointer;
    ${({ disable }) => disable && 'opacity: 0'};
    & path {
        fill: ${({ color }) => color};
    }
`;

export const CardsImage = styled.div`
    background: url(${cardsImage}) center center / cover no-repeat;
    width: 100%;
    height: 100%;
`;

export const PreviewButtons = styled.div`
    display: flex;
    gap: 20px;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    & button {
        margin: 0;
        height: 55px;
        width: 100%;
    }
`;

export const PhysicalCardFormWrapper = styled.div`
    padding-top: 40px;
`;

export const FormModalWrapper = styled.div`
    display: flex;
    height: 100%;
    justify-content: space-between;
    flex-direction: column;
`;

export const RightArrow = styled(ArrowIcon)`
    height: 12px;
    fill: black;
`;

export const FadedBackground = styled.div`
    z-index: 5;
    background: ${(props) =>
        props.shown ? `rgba(0, 0, 0, ${props.opacity || 0.4})` : 'rgba(0, 0, 0, 0)'};
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transition: 500ms ease all;
    visibility: ${(props) => (props.shown ? 'shown' : 'hidden')};
`;

export const SuccessPopupFaded = styled(FadedBackground)`
    box-sizing: border-box;
    padding: 10px;
    overflow: hidden;
`;

export const SuccessPopupContainer = styled.div`
    transition: 500ms ease all;
    background: #fff;
    border-radius: 8px;
    position: absolute;
    bottom: ${(props) => (props.shown ? '10px' : '-300px')};
    visibility: ${(props) => (props.shown ? 'shown' : 'hidden')};
    left: 10px;
    width: calc(100% - 20px);
    height: 200px;
    padding: 20px;
    box-sizing: border-box;
`;

export const SpilIdBarcodePreloader = styled.div`
    border-radius: 14px;
    width: 100%;
    height: 80px;
    padding: 0;

    ${rowPreloaderAnimation};
`;

export const SuccessPopupText = styled.span`
    flex-basis: 70%;
`;

const TooltipContentWrapper = styled.div`
    padding: 0 10px;
`;

const TooltipTitleBase = styled.div`
    color: ${COLORS.black};
    font-size: 22px;
    font-weight: 900;
`;

export const TooltipTitle = styled(TooltipTitleBase)`
    text-align: center;
`;

export const TooltipSubTitle = styled(TooltipTitleBase)`
    margin: 30px 0 15px;
`;

export const BulletComponent = styled(Bullet)`
    margin: 0;
    border: 3px solid #dee2eb;
    ${fadeInMixin} &.active {
        border: 3px solid #13a660;
        height: ${(props) => props.size}; /* override inherited style */
        width: ${(props) => props.size}; /* override inherited style */
        background: ${BACKGROUNDS.white}; /* override inherited style */
        margin: 0; /* override inherited style */
    }
`;

export const NavigationBar = ({
    title = '',
    isClose = false,
    clickHandler = () => {},
    onQuestionMarkClick = () => {},
    hideQuestionMark = false,
    isAbove = false,
    iconColor = '#c6ccdb',
    titleColor = '#333',
    background = 'none',
    underline = false,
    padding,
}) => (
    <Wrapper
        style={{
            position: isAbove ? 'absolute' : 'relative',
            background: background,
            borderBottom: underline ? `1px solid #ECEFF6` : 'none',
            ...(padding ? { padding } : {}),
        }}
    >
        {isClose ? (
            <CloseIcon style={{ height: 20, width: 20 }} onClick={clickHandler} color={iconColor} />
        ) : (
            <BackButton
                style={{ height: 22, width: 22 }}
                onClick={clickHandler}
                color={iconColor}
            />
        )}
        <Text size={'x2'} color={titleColor} bold align={'center'}>
            {title}
        </Text>
        {hideQuestionMark ? null : (
            <QuestionMark
                style={{ height: 22, width: 22 }}
                onClick={onQuestionMarkClick}
                disable={!onQuestionMarkClick}
                color={iconColor}
            />
        )}
    </Wrapper>
);

export const SpilIDTooltip = ({ hideTooltipPopup, tooltipPopupShown }) => (
    <TooltipPopup
        hide={hideTooltipPopup}
        shown={tooltipPopupShown}
        topIndent={'3%'}
        styles={!isMobile && { position: 'absolute', height: '100%' }}
    >
        <TooltipContentWrapper>
            <TooltipTitle>Spil ID</TooltipTitle>

            <TooltipSubTitle>Hvordan spiller jeg med Spil ID?</TooltipSubTitle>
            <Text size={'x1.5'} black>
                Dit Spil ID består af en unik stregkode, som du skal scanne på terminalen ved hvert
                køb hos forhandleren. Følg instruktionen på skærmen, når du enten trykker ‘Placer
                væddemål’ eller ‘Print kupon’.
            </Text>

            <TooltipSubTitle>Hvorfor skal jeg benytte Spil ID hos forhandlerne?</TooltipSubTitle>
            <Text size={'x1.5'} black>
                Det skal være trygt og sikkert at spille, og vi ønsker ikke, at vores spil benyttes
                af unge under 18 år eller er genstand for hvidvask og matchfixing. Derfor er Spil ID
                obligatorisk, når du spiller sport betting (Bet25) eller hestevæddeløb (Derby25) hos
                forhandlerne.
            </Text>
        </TooltipContentWrapper>
    </TooltipPopup>
);

export const CloseIcon = styled(CloseSvg)`
    z-index: 2;
    cursor: pointer;
    & path {
        fill: ${({ color }) => color};
    }
`;

export const PinKeyboardBtn = styled(Button)`
    background: ${COLORS.white};
    border: 0.5px solid #dee2eb;
`;

const PinTitleDotsIndicator = styled.div`
    height: 6px;
    width: 6px;
    border-radius: 6px;
    background: #9198aa;
`;

export const PinTitleDotsIndicators = () => (
    <FlexWrapper align={'space-between'} width={'40px'} alignItems={'center'} direction={'row'}>
        <PinTitleDotsIndicator />
        <PinTitleDotsIndicator />
        <PinTitleDotsIndicator />
        <PinTitleDotsIndicator />
    </FlexWrapper>
);
