import React from 'react';
import { Li, Linked, Pad, TextBlock, Text, Ul } from './styled';

const PermanentlyExcluded = ({ isModal = false }) => {
    return (
        <Pad isModal={isModal}>
            <TextBlock>
                <Text>Du har valgt at selvudelukke dig fra din konto.</Text>
                <Text>
                    Din eventuelle balance vil blive udbetalt automatisk til din
                    Nemkonto.
                </Text>
                <Text>
                    Skulle du have spørgsmål, kan du kontakte vores support.
                </Text>
            </TextBlock>

            <TextBlock>
                <Text>
                    Har du spilleproblemer, eller er i tvivl om du har
                    spilleproblemer så kan du henvende dig til &rdquo;Center for
                    ludomani&rdquo; på telefon:{' '}
                    <Linked href="tel:70-11-18-10">70 11 18 10</Linked> eller
                    e-mail på{' '}
                    <Linked href="mailto:info@ludomani.dk">
                        info@ludomani.dk
                    </Linked>
                    {'. '}
                    Du kan læse mere på om ludomani på
                    <Linked href="https://ludomani.dk/" target="_blank">
                        ludomani.dk
                    </Linked>{' '}
                    hvor du også kan teste dig selv for ludomani.
                </Text>
                <Text>
                    Du kan også besøge Center for Ludomani på følge adresser:
                </Text>
            </TextBlock>

            <Ul>
                <Li>
                    Center for ludomani i København Fiolstræde 17B st tv. 1171
                    København
                </Li>
                <Li>Center for ludomani i Odense Østergade 42 5000 Odense</Li>
                <Li>
                    Center for ludomani i Århus Gammel Munkegade 6D 8000 Århus C
                </Li>
            </Ul>

            <TextBlock>
                <Text>
                    Du kan ligeledes henvende dig til "Frederiksberg Centeret"
                    på telefon:{' '}
                    <Linked href="tel:33-21-73-00">33 21 73 00</Linked> eller
                    e-mail på{' '}
                    <Linked href="mailto:info@frederiksberg-centeret.dk">
                        info@frederiksberg-centeret.dk
                    </Linked>{' '}
                    eller læse mere på{' '}
                    <Linked
                        href="https://frederiksberg-centeret.dk/ludomani/"
                        target="_blank"
                    >
                        www.frederiksberg-centeret.dk/ludomani/
                    </Linked>
                </Text>
            </TextBlock>

            <Ul>
                <Li>
                    Frederiksberg Centeret - København Afdeling Esplanaden 8C,
                    2. sal 1263 København K.
                </Li>
            </Ul>
        </Pad>
    );
};

export default PermanentlyExcluded;
