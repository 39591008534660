import { useQuery } from '@tanstack/react-query';
import DerbyVisionAPI from 'common/api/DerbyVisionAPI';
import useTrackPage from './useTrackPage';
import useURLParameters from './useURLParameters';
import { hasResultByRaceNr, isVProduct } from 'features/EventBoard/server/calendar';

interface ReplayUrlQueryParams {
    countryCode: string;
    date: string;
    raceNumber: string;
    trackCode: string;
    trackId: number;
}

const fetchReplayUrl = async ({
    countryCode,
    date,
    raceNumber,
    trackCode,
    trackId,
}: ReplayUrlQueryParams) => {
    const response = await DerbyVisionAPI.getReplayUrl({
        countryCode,
        date,
        raceNumber,
        trackCode,
        trackId,
    });

    return response.data.data;
};

const useReplayUrlQuery = (enabled = true) => {
    const { date, race: raceNumber } = useURLParameters();
    const { raceDay, trackId, productId } = useTrackPage();
    const countryCode = raceDay?.country?.code || '';
    const trackCode = raceDay?.track?.code || '';

    const { status, data, error, isSuccess, isLoading } = useQuery<
        unknown,
        Error,
        string | undefined
    >({
        queryKey: ['replayUrl', countryCode, date, raceNumber, trackCode, trackId],
        queryFn: () => fetchReplayUrl({ countryCode, date, raceNumber, trackCode, trackId }),
        enabled:
            hasResultByRaceNr(raceDay, raceNumber) &&
            trackId > 0 &&
            !isVProduct(productId) &&
            enabled,
        staleTime: 60 * 10 * 1000,
    });
    return { status, replayUrl: data || '', countryCode, error, isSuccess, isLoading };
};

export default useReplayUrlQuery;
