import styled from 'styled-components';
import AnimationProps from './AnimationProps';

interface MoveProps extends AnimationProps {
    offset?: string;
}

const Move = styled.div<MoveProps>`
    @keyframes bottomToTop {
        from {
            transform: translateY(${({ offset = '100vh' }) => offset});
        }

        to {
            transform: translateY(0);
        }
    }
    @keyframes topToBottom {
        from {
            transform: translateY(-${({ offset = '100vh' }) => offset});
        }

        to {
            transform: translateY(0);
        }
    }
    @keyframes rightToLeft {
        from {
            transform: translateY(${({ offset = '100vh' }) => offset});
        }

        to {
            transform: translateX(0);
        }
    }
    @keyframes leftToRight {
        from {
            transform: translateY(-${({ offset = '100vh' }) => offset});
        }

        to {
            transform: translateX(0);
        }
    }
    transform: translateY(
        ${({ offset, effect }) =>
            `${
                effect === 'topToBottom' || effect === 'leftToRight' ? '-' : ''
            }${offset}`}
    );
    animation-duration: ${({ duration = 500 }) => `${duration}ms`};
    animation-name: ${({ effect = 'bottomToTop' }) => effect};
    animation-delay: ${({ delay = 0 }) => `${delay}ms`};
    animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
    animation-fill-mode: forwards;
`;

export default Move;
