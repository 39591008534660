export const topbar = {
    height: 32,
    padding: 10,
};

export const nav = {
    height: 60,
    padding: 0,
};

export const DGABar = {
    mobile: {
        height: 140,
    },
    desktop: {
        height: 59,
    },
};

export const priority = {
    DGABar: 103,
    leftSidebar: 102,
    streamPlayButton: 102,
    topBar: 100,
    bottomBar: 104,
    overlapTopBar: 101,
    overlapBottomBar: 105,
    betslipNotifier: 6,
    liveStream: 5,
    overlapBetslipNotifier: 7,
    horseStats: 6,
    replays: 8,
    liveBurgerMenu: 102,
    titlePadBurgerMenu: 103,
    pageTitleBurgerMenu: 104,
    betBuddyButtonDesktop: 15,
    modal: 106,
    modalWrapper: 1000,
    desktopModalBase: 1000,
    betSlipPopupDesktop: 999,
    overlay: 1001,
    overlapOverlay: 1002,
};

export const pageContainerWidth = '1100px';

export const priorityModal = {
    betslipMoible: 102,
    loginMobile: 103,
};

export const sidebar = {
    width: 200,
    widthClosed: 78,
};

export const terminal = {
    homepage: {
        betslipWidth: '350px',
        // 400px considers the betslip width
        wrapperWidth: `calc(100% - 400px)`,
        wrapperWidthWithMargin: `calc(100% - 440px)`,
    },
};
