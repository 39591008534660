import { MANAGER_HOST_BASE_URL } from 'configs/rest';
import { makeRequest } from 'utils/REST';

const getRequestOptions = (contentType = 'urlencoded') => {
    return {
        credentials: 'omit',
        headers: {
            Accept: 'application/json',
            /** x-www-form-urlencoded works for derby-cafe methods,
             *  but in case with unpad-sale data not plain object but instanse of coupon class, it leads to wrong JSON.stringify ([Object object])
             * so param 'contentType' has been added */
            'Content-Type':
                contentType === 'urlencoded'
                    ? 'application/x-www-form-urlencoded'
                    : 'application/json',
            //'multipart/form-data; boundary=----DerbyRequestBoundary----',
            //'application/json', //'application/x-www-form-urlencoded'
        },
    };
};

export function get(method, data) {
    return request(
        'get',
        MANAGER_HOST_BASE_URL + method,
        data,
        getRequestOptions()
    );
}

export function post(method, data, contentType) {
    return request(
        'post',
        MANAGER_HOST_BASE_URL + method,
        data,
        getRequestOptions(contentType)
    );
}

function request(requestType, url, data, fetchParams) {
    return makeRequest(requestType, url, data, fetchParams).then((response) => {
        return response.json();
    });
}
