import React from 'react';
import ArticleCard from './ArticleCard';
import Spinner from 'ui/Spinner';
import CMSApi from '../../common/api/CMSAPI';
import styled from 'styled-components';
import { COLORS } from 'themes';
import { forDesktop } from 'utils/platforms';
import { withRouter } from 'react-router-dom';
import { makeCancelable } from 'utils/promise';
import getTexts from 'utils/localization';

const t = getTexts();

const StyledContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    ${forDesktop('justify-content: space-between')};
    color: ${COLORS.primaryText};
`;

class Articles extends React.Component {
    constructor() {
        super();

        this.state = {
            articles: [],
            status: 'pending',
        };
    }

    requestPromiseCancelable = null;

    onRequestFailed = (reason) => {
        if (!reason.isCanceled) {
            this.props.history.push('/404');
            throw reason;
        }
    };

    componentDidMount() {
        this.requestPromiseCancelable = makeCancelable(CMSApi.getArticles(this.props.articleType));

        this.requestPromiseCancelable.promise
            .then((res) => {
                if (res.success && res.data) {
                    this.setState({
                        articles: res.data,
                        status: 'fetched',
                    });
                } else {
                    this.onRequestFailed(res.data);
                }
            })
            .catch(this.onRequestFailed);
    }

    componentWillUnmount() {
        this.requestPromiseCancelable.cancel();
    }

    renderArticles() {
        return this.state.articles.map((article) => (
            <ArticleCard
                key={article.id}
                articleType={this.props.articleType}
                itemsPerRow={this.props.itemsPerRow}
                onCardClick={this.props.onCardClick}
                styles={this.props.styles}
                {...article}
            />
        ));
    }

    render() {
        let content = <Spinner />,
            dataFetched = this.state.status === 'fetched',
            articleQuantity = this.state.articles.length;

        if (dataFetched && articleQuantity > 0) {
            content = this.renderArticles();
        } else if (dataFetched && articleQuantity === 0) {
            content = <p>{t.Article.noArticlesAvailable}</p>;
        }

        return <StyledContainer style={this.props.styles.Container}>{content}</StyledContainer>;
    }
}

export default withRouter(Articles);
