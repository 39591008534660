import React from 'react';
import Bet from 'common/DataObjects/Bet';
import BetPickRow from 'features/BetSlip/components/BetPick/BetPickRow';
import BetPickTrio from 'features/BetSlip/components/BetPick/BetPickTrio';
import UHorsePick from 'features/BetSlip/components/BetPick/UHorsePick';
import { PRODUCT_IDS } from 'configs/products';

const BetPickMobileBet = (props) => {
    const { product, races, race, bet, betSlip, date, trackCode, isMultitrackProduct, reserves } =
        props;
    const isTwin = product.id === PRODUCT_IDS.TV;

    const renderMultilegPickedStarts = () => {
        if (!betSlip) return null;

        return races.map((race) => {
            const raceTrackCode = isMultitrackProduct
                ? race.trackCode // race.hostTrack?.code ?
                : trackCode;

            const betSlipInput = {
                date,
                trackCode: raceTrackCode,
                productId: product.id,
                raceIndex: race.index,
            };

            const combinationExist = betSlip.hasCombinations(betSlipInput);
            if (combinationExist) {
                const bet = new Bet(betSlip.getRaceSelection(betSlipInput));
                const pickedStarts = retrievePickedStarts(race.starts, bet);
                const reserv = reserves[race.index];
                const rowLabel = isMultitrackProduct
                    ? `${race.hostTrack?.code || race.trackCode} / ${race.index + 1}`
                    : product.isVProduct()
                    ? race.index + 1
                    : race.raceNumber;

                return (
                    <BetPickRow
                        key={`${raceTrackCode}${race.index}`}
                        rowLabel={rowLabel}
                        bet={bet}
                        isMultitrackProduct={isMultitrackProduct}
                        pickedStarts={pickedStarts}
                        reserves={reserv}
                    />
                );
            }

            return null;
        });
    };

    const renderSingleRowView = () => (
        <BetPickRow
            key={1}
            rowLabel={product.isTvilling() ? '1./2' : race.raceNumber}
            bet={bet}
            pickedStarts={excludeUHorses(retrievePickedStarts(race.starts, bet))}
            product={product}
        />
    );

    const retrievePickedStarts = (starts, bet) => {
        return starts.filter((start) => {
            return bet.isStartMarked(start.startNr);
        });
    };

    const retrievePickedStartsKomb = (starts, bet, place) => {
        return starts.filter((start) => {
            return bet.satisfies(start.startNr, place);
        });
    };

    const excludeUHorses = (starts) => {
        if (!isTwin) {
            return starts;
        }

        return starts.filter((start) => {
            return (
                bet.getPickedStartNumbers().indexOf(start.startNr) !== -1 &&
                bet.betTable[start.startNr].indexOf(start.startNr) !== -1
            );
        });
    };

    if (product.isVProduct()) {
        return renderMultilegPickedStarts();
    }

    switch (product.id) {
        case PRODUCT_IDS.T:
            return <BetPickTrio bet={bet} pickedStarts={retrievePickedStarts(race.starts, bet)} />;
        case PRODUCT_IDS.TV:
            return [renderSingleRowView(), <UHorsePick key={2} race={race} bet={bet} />];
        case PRODUCT_IDS.K:
            return [
                <BetPickRow
                    key={1}
                    rowLabel={1}
                    bet={bet}
                    pickedStarts={retrievePickedStartsKomb(race.starts, bet, 1)}
                    product={product}
                />,
                <BetPickRow
                    key={2}
                    rowLabel={2}
                    bet={bet}
                    pickedStarts={retrievePickedStartsKomb(race.starts, bet, 2)}
                    product={product}
                />,
            ];
        default:
            return renderSingleRowView();
    }
};

export default BetPickMobileBet;
