import React, { useEffect, useState } from 'react';
import { RestorePassword as RestorePass, WithClearSafariPageCache } from '@it25syv/25syv-ui';
import { hidePopup } from 'utils/navigation';
import NotificationConductor from 'common/conductors/NotificationConductor';
import Modal from 'ui/ModalContent';
import { Overlay } from 'ui/Popup';
import useAuth from 'common/hooks/useAuth';
import { useHistory } from 'react-router';

const RestorePassword = () => {
    const [userData, setUserData] = useState({});
    const auth = useAuth();
    const history = useHistory();

    useEffect(() => {
        if (auth?.user) {
            setUserData(auth.user);
        }
    }, [auth.user]);

    return userData?.userName ? (
        <Modal>
            <Overlay>
                <RestorePass
                    closeAction={() => {
                        hidePopup('RESTORE_PASS');
                        history.push('/');
                    }}
                    site={'heste'}
                    userProfile={userData}
                    successHandler={() => {
                        NotificationConductor.success('Dit kodeord er blevet ændret');
                        hidePopup('RESTORE_PASS');
                        history.push('/');
                    }}
                    onError={e => {
                        NotificationConductor.error(e.errorMessage);
                    }}
                />
            </Overlay>
        </Modal>
    ) : null;
};

export default WithClearSafariPageCache(RestorePassword);
