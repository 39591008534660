import { Component } from 'react';
import { connect } from 'react-redux';
import { showModal } from 'common/actions/uiActions';
import { withRouter } from 'react-router-dom';

import getTexts from 'utils/localization';
const t = getTexts();
const errors = t.userArea.deposit.fundErrors;

class Error extends Component {
    constructor() {
        super();

        if (window.self !== window.top) {
            window.top.location.href = window.location.href;
        }
    }

    componentDidMount() {
        if (window.self === window.top) {
            const url = new URL(window.location.href);
            const reason = url.searchParams.get('reason');

            this.props.showModal('STATUS_OF_ACTION', 0, {
                success: false,
                title: t.userArea.deposit.error,
                text: errors[reason],
                redirectTo: this.props.lastVisitedRaceURL,
            });
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => ({
    lastVisitedRaceURL: state.ui.lastVisitedRaceURL,
});

const mapDispatchToProps = (dispatch) => ({
    showModal: (modalId, priority, data) =>
        dispatch(showModal(modalId, priority, data)),
});
export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(Error)
);
