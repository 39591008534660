import React from 'react';
import Wrapper from 'ui/Wrapper.tsx';
import Accordion from 'ui/Accordion';
import Text from 'ui/Text';
import { COLORS } from 'themes/index.ts';
import moment from 'moment';
import Icon from 'ui/Icon';
import { formatProductName } from 'features/History/utils';
import Divider from 'ui/Divider';
import { Col, Row } from 'react-grid-system';
import { BatchBettingHistoryItemColText, StatusBar } from './styled';
import getTexts from 'utils/localization';
import { thousandsDots } from 'utils/formatters';
import { buildDownloadBatchFileLink } from 'features/BatchBetting/server';
const t = getTexts();

const BatchBettingHistoryItem = ({ data, product }) => {
    const {
        batchFile,
        trackInfo,
        coupon,
        couponId,
        receipt,
        winAmount,
        batchCount,
        status,
    } = data;
    return (
        <Wrapper>
            <Accordion
                head={
                    <div>
                        <Text size="x05" padding="0px">
                            {moment(coupon.raceDay).format('DD/MM/YY')}
                        </Text>
                        <Text
                            size="x07"
                            bold="700"
                            black
                            padding="0px"
                            flex={'2 1 auto'}
                        >
                            <Icon
                                icloudfilled
                                size={'x025'}
                                style={{ paddingRight: '5px' }}
                            />
                            {trackInfo.domesticText}:{' '}
                            {formatProductName(product.name, coupon.strictBet)}
                            {/*{formatRaceNumber(coupon, couponInfo)}*/}
                        </Text>
                    </div>
                }
                bg={COLORS.white}
            >
                <Wrapper padding="10px 20px">
                    <Text size="x05" bold="700" black padding="0">
                        {couponId}: {product.name}
                        {' - Systemspil '}
                        {/*{groupName ? ' - ' + groupName : ''}*/}
                    </Text>
                    <Text size="x05" bold="700" black padding="0 0 10px 0">
                        {moment(receipt.transactionDate).format(
                            'DD/MM/YY HH:mm'
                        )}
                    </Text>

                    <Divider lightGrey padding="5px 0px" />

                    <BatchBettingHistoryItemColText black>
                        Download din fil her, da kuponer fra systemspil ikke
                        vises enkeltvis:{' '}
                        <a
                            href={buildDownloadBatchFileLink(
                                receipt.externalTransId
                            )}
                        >
                            {batchFile}
                        </a>
                    </BatchBettingHistoryItemColText>

                    <Row>
                        <Col sm={4}>
                            <BatchBettingHistoryItemColText bold black>
                                Dato:
                            </BatchBettingHistoryItemColText>
                        </Col>
                        <Col sm={8}>
                            <BatchBettingHistoryItemColText black>
                                {moment(coupon.raceDay).format('DD.MM.YYYY')}
                            </BatchBettingHistoryItemColText>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <BatchBettingHistoryItemColText bold black>
                                Kuponer:
                            </BatchBettingHistoryItemColText>
                        </Col>
                        <Col sm={8}>
                            <BatchBettingHistoryItemColText black>
                                {batchCount} stk.
                            </BatchBettingHistoryItemColText>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <BatchBettingHistoryItemColText bold black>
                                Systemer:
                            </BatchBettingHistoryItemColText>
                        </Col>
                        <Col sm={8}>
                            <BatchBettingHistoryItemColText black>
                                {coupon.combinationNumber} st.
                            </BatchBettingHistoryItemColText>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={4}>
                            <BatchBettingHistoryItemColText bold black>
                                Indsats:
                            </BatchBettingHistoryItemColText>
                        </Col>
                        <Col sm={8}>
                            <BatchBettingHistoryItemColText black>
                                {(receipt.totalCost / 100).toFixed(2)}{' '}
                                {t.currency}
                            </BatchBettingHistoryItemColText>
                        </Col>
                    </Row>
                </Wrapper>
                {/* Bet in status 'queue' */}
                {status === 6 && (
                    <StatusBar accent>Afventer godkendelse</StatusBar>
                )}
                {/* Finished bet */}
                {status === 2 && (
                    <StatusBar
                        {...(winAmount
                            ? { primary: 'primary' }
                            : { red: 'red' })}
                    >
                        {winAmount
                            ? `Vundet - ${thousandsDots(winAmount)} kr.`
                            : t.betSlip.lose}
                    </StatusBar>
                )}
                {status === 3 && <StatusBar red>{t.betSlip.lose}</StatusBar>}
            </Accordion>
        </Wrapper>
    );
};

export default BatchBettingHistoryItem;
