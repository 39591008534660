import { useEffect } from 'react';
import { create } from 'zustand';
import { useParams } from 'react-router-dom';
import { generateEventPath } from 'utils/navigation';
import { extractProductId } from 'features/EventBoard/utils/common';

export interface URLParameters {
    date: string;
    product: string;
    track: string;
    race: string;
}

interface URLParametersStore extends URLParameters {
    setDate(date: string): void;
    setProduct(date: string): void;
    setTrack(date: string): void;
    setRace(date: string): void;
}

const useURLParametersState = create<URLParametersStore>()(set => ({
    date: '',
    product: '',
    track: '',
    race: '',
    setDate: date => set({ date }),
    setProduct: product => set({ product }),
    setTrack: track => set({ track }),
    setRace: race => set({ race }),
}));

/**
 * React router useParams() works only when calling from the child component of <Router> (the root page component)
 * This component uses additional global state in order to make URL parameters reachable across the UI
 */
const useURLParameters = () => {
    const { date, product, track, race } = useParams<URLParameters>();

    const {
        date: dateState,
        product: productState,
        track: trackState,
        race: raceState,
        setDate,
        setProduct,
        setTrack,
        setRace,
    } = useURLParametersState();

    const syncURLParameters = () => {
        date && setDate(date);
        product && setProduct(product);
        track && setTrack(track);
        race && setRace(race);
    };

    const clearURLParameters = () => {
        setDate('');
        setProduct('');
        setTrack('');
        setRace('');
    };

    const generateURL = ({ product, date, track, race }: any) => {
        const productId = extractProductId(product ?? productState);

        return generateEventPath({
            productId,
            date: date ?? dateState,
            track: track ?? trackState,
            race: race ?? raceState,
        });
    };

    useEffect(syncURLParameters, [date, product, track, race]);

    return {
        date: dateState,
        product: productState,
        track: trackState,
        race: raceState,
        // next 2 functions should be called only in the component inside <Route/>
        syncURLParameters,
        clearURLParameters,
        generateURL,
    };
};

export default useURLParameters;
