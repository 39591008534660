import React from 'react';
import { Horse } from 'features/TrackPage/model/Race';

interface HorseNameProps {
    horse: Horse;
    hideNrCol: boolean;
}

const HorseName = ({ horse, hideNrCol }: HorseNameProps) => {
    return (
        <>
            {hideNrCol && horse.startNr + '.'}{' '}
            {horse.horse.horseNameAndNationality?.toLowerCase() ?? '--'}
        </>
    );
};

export default HorseName;
