import React from 'react';
import Popup from 'ui/Popup';
import Text from 'ui/Text';
import { PopupWrapper, ButtonsRow, OKBtn, LogInBtn } from './styled';
import { useDispatch } from 'react-redux';
import { openLogin } from 'utils/navigation';

const SessionExpired = ({ hideModal }) => {
    const dispatch = useDispatch();
    const close = () => hideModal('SESSION_EXPIRED');
    const goToLogin = () => {
        close();
        dispatch(openLogin());
    };

    return (
        <Popup PopupWrapperComponent={PopupWrapper} displayCloseButton={false}>
            <Text bold black size="x3">
                Session udløbet
            </Text>
            <Text size="x2">
                Din session er udløbet, det kan skyldes at du er logget ind via en anden enhed,
                eller har været inaktiv for længe.
            </Text>
            <ButtonsRow>
                <OKBtn onClick={close}>OK</OKBtn>
                <LogInBtn onClick={goToLogin}>Log ind</LogInBtn>
            </ButtonsRow>
        </Popup>
    );
};

export default SessionExpired;
