import { calculateTimeLeft } from 'utils/time';
import BettingREST from 'features/BetSlip/BettingREST';
import { LOGOUT } from 'common/actions/authActions';
import { getIds, excludeScratched } from 'common/selectors/raceStartsSelector';
import { isSpilklubSelectionsMode } from 'common/selectors/betSlipSelector';
import getTexts from 'utils/localization';

export const ADD_ALL_BETS = 'ADD_ALL_BETS';
export const MERGE_BETSLIP = 'MERGE_BETSLIP';
export const ADD_BET = 'ADD_BET';
export const REMOVE_BET = 'REMOVE_BET';
export const RESET_IRRELEVANT_BETS = 'RESET_IRRELEVANT_BETS';
export const RESET_RACE_BET = 'RESET_RACE_BET';
export const RESET_BET = 'RESET_BET';
export const PERFORM_BET_PENDING = 'PERFORM_BET_PENDING';
export const PERFORM_BET_ERROR = 'PERFORM_BET_ERROR';
export const PERFORM_BET_SUCCESS = 'PERFORM_BET_SUCCESS';
export const STRICT_MODE = 'STRICT_MODE';
export const PREV_AMOUNT = 'PREV_AMOUNT';
export const SET_DERBY_LYN_BET = 'SET_DERBY_LYN_BET';
export const RESET_DERBY_LYN_BET = 'RESET_DERBY_LYN_BET';
export const MARK_TRIO_ROW = 'MARK_TRIO_ROW';
export const UNMARK_TRIO_ROW = 'UNMARK_TRIO_ROW';
export const MARK_KOMB_ROW = 'MARK_KOMB_ROW';
export const UNMARK_KOMB_ROW = 'UNMARK_KOMB_ROW';
export const ADD_RESERVE = 'ADD_RESERVE';
export const REMOVE_RESERVE = 'REMOVE_RESERVE';
export const REMOVE_LAST_RESERVE = 'REMOVE_LAST_RESERVE';
export const SMART_LYN_MODE = 'SMART_LYN_MODE';
export const SMART_LYN_BET = 'SMART_LYN_BET';
export const SET_BET_BUDDY_SELECTIONS_MODE = 'SET_BET_BUDDY_SELECTIONS_MODE';
export const RESET_BET_BUDDY_SELECTIONS_MODE = 'RESET_BET_BUDDY_SELECTIONS_MODE';
export const RESET_BET_BUDDY_SELECTIONS = 'RESET_BET_BUDDY_SELECTIONS';

const t = getTexts();

export const addAllBets = (date, trackId, productId, raceIndex, betsByDates) => {
    return {
        type: ADD_ALL_BETS,
        payload: { date, trackId, productId, raceIndex, betsByDates },
    };
};

export const mergeBetslip = (picks, reserves) => {
    return {
        type: MERGE_BETSLIP,
        payload: { picks, reserves },
    };
};

export const addBet = (date, trackId, productId, raceNr, startNr, betValue) => {
    return (dispatch, getState) => {
        dispatch({
            type: ADD_BET,
            payload: {
                date,
                trackId,
                productId,
                raceNr,
                startNr,
                betValue,
                betBuddy: isSpilklubSelectionsMode(getState()),
            },
        });
    };
};

export const removeBet = (date, trackId, productId, raceNr, startNr, betValue) => {
    return (dispatch, getState) => {
        dispatch({
            type: REMOVE_BET,
            payload: {
                date,
                trackId,
                productId,
                raceNr,
                startNr,
                betValue,
                betBuddy: isSpilklubSelectionsMode(getState()),
            },
        });
    };
};

export const addReserve = (date, trackId, productId, raceNr, startNr) => (dispatch, getState) =>
    dispatch({
        type: ADD_RESERVE,
        payload: {
            date,
            trackId,
            productId,
            raceNr,
            startNr,
            betBuddy: isSpilklubSelectionsMode(getState()),
        },
    });

export const removeReserve = (date, trackId, productId, raceNr, startNr) => (dispatch, getState) =>
    dispatch({
        type: REMOVE_RESERVE,
        payload: {
            date,
            trackId,
            productId,
            raceNr,
            startNr,
            betBuddy: isSpilklubSelectionsMode(getState()),
        },
    });

export const removeLastReserve = (date, trackId, productId, raceNr) => (dispatch, getState) =>
    dispatch({
        type: REMOVE_LAST_RESERVE,
        payload: {
            date,
            trackId,
            productId,
            raceNr,
            betBuddy: isSpilklubSelectionsMode(getState()),
        },
    });

export const resetRaceBet = (date, trackCode, productId, raceIndex) => (dispatch, getState) => {
    dispatch({
        type: RESET_RACE_BET,
        payload: {
            date,
            trackId: trackCode,
            productId,
            raceIndex,
            betBuddy: isSpilklubSelectionsMode(getState()),
        },
    });
};

/**
 * Remove all the bets for started and already finished races.
 */
export const resetIrrelevantBets = (date, trackCode, productId) => {
    return (dispatch, getStore) => {
        const AISData = getStore().AISDataProvider;
        const serverTime = AISData.serverTime;
        const pastRaceNumbers = AISData.racingCardData.races
            .filter(race => {
                return calculateTimeLeft(`${date} ${race.postTime}`, serverTime) <= 0;
            })
            .map(race => race.raceNumber);

        return dispatch({
            type: RESET_IRRELEVANT_BETS,
            payload: {
                date,
                trackId: trackCode,
                productId,
                pastRaceNumbers,
            },
        });
    };
};

export const resetBet = () => {
    return {
        type: RESET_BET,
    };
};

export const resetBetBuddySelections = () => {
    return {
        type: RESET_BET_BUDDY_SELECTIONS,
    };
};

/**
 * @param {Coupon}  coupon
 * @param {Product} product
 */
export const performQuickPickBet = (coupon, product) => {
    return async dispatch => {
        dispatch(performBetPending());

        try {
            const response = await BettingREST.performQuickPickBet(coupon, product);

            if (response.success) {
                dispatch(performBetSuccess(response.data));
            } else {
                dispatch(performBetError(t.sessionExpired));
            }

            return response;
        } catch (e) {
            dispatch(performBetError(e.message));
            throw e;
        }
    };
};

/**
 * @param {Product} product
 * @param {Coupon}  coupon
 */
export const performBet = (product, coupon) => {
    return async dispatch => {
        dispatch(performBetPending());

        try {
            const response = await BettingREST.performBet(product.id, coupon);
            if (response.success) {
                dispatch(performBetSuccess(response.data));
            } else {
                if (response.errorMessage !== "Can't get JSESSIONID cookie") {
                    dispatch(performBetError(response.errorMessage));
                } else {
                    dispatch(performBetError(t.sessionExpired));

                    if (LOGOUT) {
                        dispatch({
                            type: LOGOUT,
                            payload: true,
                        });
                    }
                }
            }
            return response;
        } catch (e) {
            dispatch(performBetError(e.message));
            throw e;
        }
    };
};

export const markTrioRow = (date, trackId, raceNr, row) => (dispatch, getState) =>
    dispatch({
        type: MARK_TRIO_ROW,
        payload: {
            date,
            trackId,
            raceNr,
            row,
            startNumbers: getIds(excludeScratched(getState())),
        },
    });

export const unmarkTrioRow = (date, trackId, raceNr, row) => (dispatch, getState) =>
    dispatch({
        type: UNMARK_TRIO_ROW,
        payload: {
            date,
            trackId,
            raceNr,
            row,
            startNumbers: getIds(excludeScratched(getState())),
        },
    });

export const markKombRow = (date, trackId, raceNr, row) => (dispatch, getState) =>
    dispatch({
        type: MARK_KOMB_ROW,
        payload: {
            date,
            trackId,
            raceNr,
            row,
            startNumbers: getIds(excludeScratched(getState())),
        },
    });

export const unmarkKombRow = (date, trackId, raceNr, row) => (dispatch, getState) =>
    dispatch({
        type: UNMARK_KOMB_ROW,
        payload: {
            date,
            trackId,
            raceNr,
            row,
            startNumbers: getIds(excludeScratched(getState())),
        },
    });

export const performBetPending = () => ({
    type: PERFORM_BET_PENDING,
});
/**
 * @param  {Object}                           betData
 * @return {{type: string, payload: Object}}
 */
export const performBetSuccess = betData => ({
    type: PERFORM_BET_SUCCESS,
    payload: betData,
});
/**
 * @param  {string}                           error
 * @return {{type: string, payload: string}}
 */
export const performBetError = error => ({
    type: PERFORM_BET_ERROR,
    payload: error,
});
/**
 * @param  {boolean}                           state
 * @return {{type: string, payload: string}}
 */
export const setStrictMode = status => ({
    type: STRICT_MODE,
    payload: status,
});
/**
 * @param  {number}                           state
 * @return {{type: string, payload: number}}
 */
export const setPrevAmount = amount => ({
    type: PREV_AMOUNT,
    payload: amount,
});

/**
 * @param  {String, number, Product}          state
 * @return {{type: string, payload: Object}}
 */
export const setDerbyLynBet = (date, trackCode, product) => ({
    type: SET_DERBY_LYN_BET,
    payload: { date, trackCode, product },
});

/**
 * @param  {String, number, Product}          state
 * @return {{type: string, payload: Object}}
 */
export const resetDerbyLynBet = (date, trackCode, product) => ({
    type: RESET_DERBY_LYN_BET,
    payload: { date, trackCode, product },
});

/**
 * @param  {boolean}            status
 * @return {{type: string, payload: boolean}}
 */
export const setSmartLynMode = status => ({
    type: SMART_LYN_MODE,
    payload: status,
});

/**
 * @param  {SmartLynBet}            smartLynBet
 * @return {{type: string, payload: object}}
 */
export const setSmartLynBet = smartLynBet => ({
    type: SMART_LYN_BET,
    payload: smartLynBet,
});

export const resetSmartLyn = () => dispatch => {
    dispatch({
        type: SMART_LYN_BET,
        payload: null,
    });

    dispatch({
        type: SMART_LYN_MODE,
        payload: false,
    });
};
