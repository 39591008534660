import React, { useEffect } from 'react';
import {
    CloseButton,
    PopupButton,
    PopupContent,
    PopupMainImage,
    ProductPopupContainer,
} from './styled';
import closeButton from 'icons/edit.png';

const SidebarPopup = (props) => {
    useEffect(() => {
        props.setBackground('rgba(0,0,0,.45)');
    }, []);

    const { modalData } = props;
    return (
        <ProductPopupContainer background={modalData.background}>
            <PopupContent name={modalData.name}>
                <img src={modalData.logo} alt={modalData.name} />
                <h3>{modalData.title}</h3>
                {modalData.texts.map((text, index) => (
                    <p key={index}>{text}</p>
                ))}
                <PopupMainImage
                    style={{
                        height: modalData.mainImg.height,
                        right: modalData.mainImg.right,
                        bottom: modalData.mainImg.bottom,
                    }}
                    src={modalData.mainImg.url}
                    alt={modalData.name}
                />
                <PopupButton href={modalData.url}>
                    {modalData.buttonTitle}
                </PopupButton>
                <CloseButton
                    onClick={() => {
                        props.hideModal('SIDEBAR_POPUP');
                    }}
                >
                    <img src={closeButton} alt={modalData.name + ' close'} />
                </CloseButton>
            </PopupContent>
        </ProductPopupContainer>
    );
};

export default SidebarPopup;
