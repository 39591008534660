import store from 'common/store';
import NotificationConductor from 'common/conductors/NotificationConductor';
import { isTerminal } from 'utils/platforms';
import getTexts from 'utils/localization';

const t = getTexts();

/**
 * Decorator function that calls a needed function when user is authorized
 * otherwise it shows a notification.
 *
 * @param  {Function}     wrappedFn    Function to wrap
 * @param  {?string}      msg          Optional - message to show
 * @return {Function}
 */
const checkAuthority = (wrappedFn, msg = '') => (...args) => {
    const user = store.getState().auth.user;

    if (user) {
        return wrappedFn(...args);
    } else {
        NotificationConductor.warning(
            msg || isTerminal ? t.COUPONFIRST : t.LOGINFIRST
        );
    }
};

const lockBodyScroll = (
    delay = 0,
    affectOverflow = true,
    withScrollTop = true
) => {
    const appRoot = document.getElementById('root');

    const lock = () => {
        window._scrollY = window.scrollY;

        if (affectOverflow) window.document.body.style.overflow = 'hidden';
        if (withScrollTop) {
            window.document.body.style.position = 'fixed';
            appRoot.classList.add('without-scroll');
        }
    };

    if (delay > 0) {
        let timeoutId = setTimeout(lock, delay);

        return [timeoutId];
    }

    lock();

    return [null];
};

const unlockBodyScroll = (lockingTimeoutId = null, affectOverflow = true) => {
    if (lockingTimeoutId) {
        clearTimeout(lockingTimeoutId);
    }

    const appRoot = document.getElementById('root');

    window.document.body.style.position = 'static';
    if (affectOverflow) window.document.body.style.overflow = 'auto';
    appRoot && appRoot.classList.remove('without-scroll');
    window.scrollTo(0, window._scrollY);
    window._scrollY = undefined;
};

export { checkAuthority, lockBodyScroll, unlockBodyScroll };
