import { RaceDay } from '../server/calendar';
import get from 'lodash/get';

export type Track = Pick<
    RaceDay,
    | 'firstRacePostTime'
    | 'firstRacePostTimeUTC'
    | 'weather'
    | 'productsWithJackpot'
    | 'trackName'
    | 'trackId'
    | 'country'
    | 'fullProductList'
    | 'raceDayDate'
>;

export const getWeatherAttributes = (raceDay: RaceDay) => {
    const currentConditions = get(raceDay, ['weather', 'currentConditions', 0]);
    return {
        tempCelsius: currentConditions?.tempCelsius,
        weatherCode: currentConditions?.weatherCode,
        weatherDescription: get(currentConditions, ['weatherDescription', 0, 'value']),
    };
};
