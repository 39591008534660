import React from 'react';
import styled from 'styled-components';
import { COLORS } from 'themes/index.ts';
import { BACKGROUNDS } from 'themes/index';
import Text from 'ui/Text';
import AUIcon from 'images/flags/AU.png';
import SEIcon from 'images/flags/SE.png';
import DKIcon from 'images/flags/DK.png';
import UKIcon from 'images/flags/UK.png';
import NOIcon from 'images/flags/NO.png';
import AfricaIcon from 'images/flags/ZA.png';
import FinlandIcon from 'images/flags/FL.png';
import DefaultIcon from 'images/flags/WW.png';
import FRIcon from 'images/flags/FR.png';
import { byPlatform } from 'utils/platforms';
import Button from 'ui/controls/Button';
import arrowLeftIcon from 'images/icons/date-picker-arrow-left.svg';
import arrowRightIcon from 'images/icons/date-picker-arrow-right.svg';
import arrowDownIcon from 'images/icons/date-picker-arrow-down.svg';
import { fadeInMixin } from 'ui/animations/FadeIn2';
import DefaultScrollBar from 'ui/ScrollBar';
import { range } from 'utils/array';

const rowSidePadding = byPlatform('20px', 0);

const breakpoints = {
    xs: byPlatform('386px', '675px'),
    sm: byPlatform('475px', '775px'),
    md: byPlatform('735px', '1024px'),
};

export const jackpotLabelTopOffset = '8px';

export const countryCodeMap = {
    AU: AUIcon,
    SE: SEIcon,
    DK: DKIcon,
    GB: UKIcon,
    NO: NOIcon,
    ZA: AfricaIcon,
    FR: FRIcon,
    FL: FinlandIcon,
    Default: DefaultIcon,
};

export const gapCompensation = '-15px';

export const SlickSliderContainer = styled.div`
    margin-bottom: ${gapCompensation};
    overflow: hidden;

    & .slick-list {
        padding-top: ${byPlatform(0, jackpotLabelTopOffset)};
    }
`;

export const Grid = styled.div`
    display: grid;
    grid-template-columns: ${byPlatform('100%', '90px auto')};
    margin: ${byPlatform('25px 0 20px 0', '30px 0 0')};
    padding-left: ${byPlatform('0', '5px')};
    row-gap: ${byPlatform('35px', '38px')};
`;

export const ScrollBar = styled(DefaultScrollBar)`
    padding-left: ${rowSidePadding};
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    &::-webkit-scrollbar {
        display: none; // Safari and Chrome
    }
`;

export const TracksScrollBar = styled(ScrollBar).attrs({
    orientation: 'horizontal',
})`
    margin-bottom: ${gapCompensation};
    padding-top: ${jackpotLabelTopOffset}; /* top indent is used to avoid partially hidden jackpot label */
    margin-top: -${jackpotLabelTopOffset}; /* negative indent is used to symmetrically align to the top */
`;

const ArrowLeftIcon = styled.img`
    filter: ${props =>
        props.active ? 'brightness(0)' : 'grayscale(100%) brightness(110%) contrast(0.8)'};
    position: relative;
    left: -1px;
`;

const ArrowRightIcon = styled.img`
    filter: ${props =>
        props.active ? 'brightness(0)' : 'grayscale(100%) brightness(110%) contrast(1.8)'};
    position: relative;
    left: 1px;
`;

const ArrowDownIcon = styled.img`
    margin-left: 15px;
    position: relative;
    top: 1px;
`;

export const RaceNavigationLabel = styled.span`
    align-self: center;
    padding: 0 15px;
    color: ${COLORS.primaryText};
    font-weight: 900;
    font-size: 14px;
`;

export const CountryImage = styled.img`
    height: 12px;
    margin-right: 6px;
    border-radius: 2px;
`;

export const ButtonWithSublabel = styled.div`
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
`;

export const SubLabel = styled.span`
    font-size: 10px;
    color: ${COLORS.black};
    padding: 5px 0;
`;

export const NavigationButton = styled(Button)`
    transition: all 300ms ease-in;
    height: ${byPlatform('45px', '38px', '45px')};
    min-width: ${byPlatform('45px', '38px', '60px')};
    font-size: ${byPlatform('14px', '12px', '14px')};
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box !important;
    font-weight: 900;
    color: ${props =>
        props.active ? BACKGROUNDS.white : props.accent ? '#C5C9D2' : BACKGROUNDS.black};
    background: ${props => (props.active ? BACKGROUNDS.primaryFlat : BACKGROUNDS.white)};
    border: ${props =>
        props.active
            ? '0.5px solid ' + BACKGROUNDS.primaryFlat
            : props.accent
            ? '0.5px solid #C5C9D2'
            : '0.5px solid #c6ccdb'};
    margin: 0 ${byPlatform('5px', '5px', '10px')};

    &:first-child {
        margin-left: ${byPlatform('5px', '5px', '10px')};
    }
    &:last-child {
        margin-right: ${byPlatform('5px', '5px', '10px')};
    }

    &:hover {
        ${({ accent, active }) => !accent && !active && byPlatform('', 'background: #c6ccdb')};
    }
`;

export const RaceButtonsScrollbar = styled(ScrollBar)`
    justify-content: space-between;
`;

export const RaceLineNavigationButton = styled(NavigationButton)`
    height: auto;
    padding: 12px;
    flex-direction: column;
    color: ${({ active, accent }) =>
        active ? BACKGROUNDS.white : accent ? '#C6CCDB' : BACKGROUNDS.black};
    background: ${({ active, accent }) =>
        active ? BACKGROUNDS.primaryFlat : accent ? '#ECEFF6' : BACKGROUNDS.white};
    border: ${({ active, accent }) =>
        active
            ? '0.5px solid ' + BACKGROUNDS.primaryFlat
            : accent
            ? '0.5px solid #C6CCDB'
            : '0.5px solid #C5C9D2'};

    min-width: ${byPlatform('45px', '45px', '60px')};
`;

export const ScrollbarRightIndent = styled.div`
    height: 45px;
    min-width: 15px;
`;

export const PreloaderButton = styled.div`
    background: ${BACKGROUNDS.white};
    border-radius: 5px;
    height: ${({ height }) => height || byPlatform('45px', '38px', '45px')};
    width: ${({ width }) => width || '5px'};
    margin: 0 ${byPlatform('5px', '5px', '10px')};
    border: 0.5px solid #c6ccdb;
`;

export const PreloaderButtonsWrapper = styled.div`
    display: flex;
    padding: ${byPlatform('0 10px 0 20px', '0')};
`;

export const TrackNavigationButton = styled(NavigationButton)`
    color: ${props =>
        props.active ? BACKGROUNDS.white : props.accent ? '#8BF7C3' : BACKGROUNDS.black};
    background: ${props =>
        props.active || props.accent ? BACKGROUNDS.primaryFlat : BACKGROUNDS.white};
    position: relative;
    width: ${props => (props.useSlider ? '100%' : 'auto')} !important;
    margin: ${props => (props.useSlider ? 0 : byPlatform('0 5px', '0 10px 0 0'))} !important;
    &:first-child {
        margin-left: ${props =>
            props.useSlider
                ? '0'
                : '5px !important'}; // need !important notation to override higher "margin" that is also !important that needed to override slick styles
    }
`;

export const JackpotLabel = styled.div`
    padding: 2px 5px;
    font-size: 10px;
    border-radius: 3px;
    background: ${BACKGROUNDS.accentAlt};
    font-weight: 900;
    position: absolute;
    z-index: 1;
    top: -${jackpotLabelTopOffset};
    left: 18px;
`;

export const RaceNavigationButton = styled(NavigationButton)`
    ${fadeInMixin};
    width: 40px;
    padding: 0;
    border: 0.5px solid #c6ccdb;
    background: ${props => (props.active ? BACKGROUNDS.white : BACKGROUNDS.lightGrey)};
    color: ${COLORS.black};
    &:last-of-type {
        margin-right: 0;
    }
`;

export const ArrowButton = styled(RaceNavigationButton)`
    &:hover {
        background: ${({ active }) => (active ? '#c6ccdb' : BACKGROUNDS.white)};
    }
`;

const SliderArrowPrevButton = styled(ArrowButton)`
    position: absolute;
    z-index: 1;
    left: 0;
`;
const SliderArrowNextButton = styled(ArrowButton)`
    position: absolute;
    z-index: 1;
    right: 0;
    top: 0;
    visibility: ${({ active }) => (active ? 'visible' : 'hidden')};
`;

export const CalendarDateButton = styled(RaceNavigationButton)`
    min-width: ${byPlatform('125px', '150px')};
    font-size: ${byPlatform('12px', '14px')};
    flex-grow: ${byPlatform(2, 'auto')};
    font-weight: 900;
    &:hover {
        background: #c6ccdb;
    }
    &:first-child {
        margin-left: ${byPlatform('5px', '5px', '10px')};
        flex-grow: ${props => (props.dateButtonGrow ? 1 : 0)};
        @media (max-width: ${breakpoints.xs}) {
            margin-left: 0;
        }
    }
`;

export const CalendarControlWrapper = styled.div`
    padding: 0 0 0 ${rowSidePadding};
    display: flex;
    align-items: center;
    justify-content: flex-start;
    @media (max-width: ${breakpoints.xs}) {
        padding: 0;
        width: 100%;
    }
`;

export const CalendarLabel = styled(Text)`
    @media (max-width: ${breakpoints.xs}) {
        display: none !important;
    }
    @media (min-width: ${breakpoints.xs}) and (max-width: ${breakpoints.sm}) {
        font-size: 16px;
    }
    @media (min-width: ${breakpoints.sm}) and (max-width: ${breakpoints.md}) {
        font-size: 22px;
    }
    font-size: 32px;
`;

export const PrevTrackButton = ({ onClick = () => {}, className = '', styles = {} }) => {
    const disabled = className.includes('slick-disabled');
    return (
        <SliderArrowPrevButton active={!disabled} onClick={onClick} style={styles}>
            <ArrowLeftIcon src={arrowLeftIcon} active={!disabled} />
        </SliderArrowPrevButton>
    );
};

export const NextTrackButton = ({ onClick = () => {}, className = '', styles = {} }) => {
    const disabled = className.includes('slick-disabled');
    return (
        <SliderArrowNextButton active={!disabled} onClick={onClick} style={styles}>
            <ArrowRightIcon src={arrowRightIcon} active={!disabled} />
        </SliderArrowNextButton>
    );
};

const calendarComponentFormat = 'D. MMM. YYYY';

export const CalendarControls = ({
    mCurrentDate,
    showDatePickerModal,
    selectNextDate,
    selectPrevDate,
    isPrevDateAvailable,
    isNextDateAvailable,
    dateButtonGrow = true,
}) => {
    const selectedDate = mCurrentDate.format(calendarComponentFormat);

    return (
        <CalendarControlWrapper>
            <CalendarDateButton
                onClick={showDatePickerModal}
                className="capitalize"
                dateButtonGrow={dateButtonGrow}
                active
            >
                {selectedDate}
                <ArrowDownIcon src={arrowDownIcon} alt={'Arrow down icon'} />
            </CalendarDateButton>
            <ArrowButton
                active={isPrevDateAvailable}
                onClick={() => isPrevDateAvailable && selectPrevDate()}
            >
                <ArrowLeftIcon src={arrowLeftIcon} active={isPrevDateAvailable} />
            </ArrowButton>
            <ArrowButton
                active={isNextDateAvailable}
                onClick={() => isNextDateAvailable && selectNextDate()}
            >
                <ArrowRightIcon src={arrowRightIcon} active={isNextDateAvailable} />
            </ArrowButton>
        </CalendarControlWrapper>
    );
};

export const PreloaderButtons = ({ count, width, height = '', wrapperStyles = {} }) => (
    <PreloaderButtonsWrapper style={wrapperStyles}>
        {range(count).map(key => (
            <PreloaderButton key={key} width={width} height={height} />
        ))}
    </PreloaderButtonsWrapper>
);
