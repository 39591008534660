import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { addAllBets, resetRaceBet, addBet, removeBet } from 'features/BetSlip/state/actions';

import { getIn } from 'immutable';
import getTexts from 'utils/localization';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import { SelectAllButton } from './styled';
import { generateRaceKey } from './utils';

const t = getTexts();

const SelectRowButton = ({ race }) => {
    const dispatch = useDispatch();

    const { product, raceDay } = useTrackPage();
    const trackCode = raceDay.track.code;

    const selectedBets = useSelector(state => state.BetSlip.selectedBets);

    const date = raceDay.raceDayDate;

    const { index: raceIndex } = race;

    const betBuddySelectionsMode = useSelector(
        state => state.BetSlip.betBuddySelectionsMode.status
    );

    const checkSelection = () => {
        return getIn(
            selectedBets,
            [generateRaceKey(date, trackCode, product.id, raceIndex)],
            false
        );
    };
    let isActiveRow = checkSelection();

    useEffect(() => {
        isActiveRow = checkSelection();
    }, [selectedBets]);

    const toggleBet = (start, trackCode, activate) => {
        const startNumber = start.startNr;
        if (!start.vpPool.scratched) {
            activate
                ? onActivate(startNumber, startNumber, trackCode, betBuddySelectionsMode)
                : onDisactivate(startNumber, startNumber, trackCode);
        }
    };

    const mapBets = (horses, trackCode, activate) => {
        let selectedStarts = {},
            betsByDates = {};

        betsByDates = { [raceIndex]: selectedStarts };
        if (activate) {
            dispatch(addAllBets(date, trackCode, product.id, raceIndex, betsByDates));
            horses.forEach(start => {
                toggleBet(start, trackCode, activate);
                if (!start.vpPool.scratched) {
                    selectedStarts[start.startNr] = [start.startNr];
                }
            });
        } else {
            dispatch(resetRaceBet(date, trackCode, product.id, raceIndex, betBuddySelectionsMode));
        }
    };

    const clickHandler = e => {
        e.stopPropagation();

        mapBets(race.horses, trackCode, !isActiveRow);
    };

    const onActivate = (betValue, startNr, trackCode) => {
        dispatch(addBet(date, trackCode, product.id, race.index, startNr, betValue));
    };

    const onDisactivate = (betValue, startNr, trackCode) =>
        dispatch(removeBet(date, trackCode, product.id, race.index, startNr, betValue));

    return (
        <SelectAllButton width="80px" active={isActiveRow} grey onClick={clickHandler}>
            {t.RacingCard.vRaceHeader.all}
        </SelectAllButton>
    );
};

export default SelectRowButton;
