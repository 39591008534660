import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'react-grid-system';
import { getBackgroundByProps } from '../utils/theme-helpers';
import { topbar } from 'configs/layout';

const Toolbar = styled.header`
    font-size: 14px;
    margin: 0px;
    padding: ${topbar.padding}px;
    background: ${(props) => getBackgroundByProps(props)};
    color: #fff;
    box-shadow: 0px 0px 20px rgba(00, 80, 00, 0.5);
    min-height: ${topbar.height}px;
    line-height: ${topbar.height}px;
`;

const resetStyle = {
    padding: '0px',
    margin: '0px',
};

export default (props) => (
    <Toolbar {...props}>
        <Row style={resetStyle}>
            <Col
                xs={props.leftSize ? props.leftSize : 4}
                align={props.alignLeft ? props.alignLeft : 'start'}
                style={resetStyle}
            >
                {props.left}
            </Col>
            <Col
                xs={props.centerSize ? props.centerSize : 4}
                align={props.alignCenter ? props.alignCenter : 'center'}
                style={resetStyle}
            >
                {props.center}
            </Col>
            <Col
                xs={props.rightSize ? props.rightSize : 4}
                align={props.alignRight ? props.alignRight : 'end'}
                style={resetStyle}
            >
                {props.right}
            </Col>
        </Row>
    </Toolbar>
);
