import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import QuickPick from 'features/QuickPick';
import { setHighlightState } from 'common/actions/uiActions';

import Headline from 'ui/Headline';
import Text from 'ui/Text';
import PageContainer from 'ui/PageContainer';

import getTexts from 'utils/localization';
import { byPlatform } from '../utils/platforms';
const t = getTexts();

const DerbyLyn = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setHighlightState(true));
    }, [dispatch]);

    return (
        <PageContainer padding={byPlatform('32px 16px', '0 30px 30px 30px')}>
            <Headline
                bolder
                size={byPlatform(20, 32)}
                padding={byPlatform('0 0 16px 0', '64px 0 24px')}
            >
                {t.QuickPick.title}
            </Headline>

            <Text size={byPlatform(14, 16)} padding="0" lh={24}>
                {t.QuickPick.description}
            </Text>

            <QuickPick />
        </PageContainer>
    );
};

export default DerbyLyn;
