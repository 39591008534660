import { Label, ProductId } from 'features/EventBoard/server/calendar';
import { useQuery } from '@tanstack/react-query';

import { request } from 'utils/server';
import { PRODUCTS_SYS_NAMES } from 'configs/products';
import { RaceCardData } from 'features/TrackPage/server/raceCard';
import { deserializeRaces } from 'features/TrackPage/server/unserialize';
import { TrackPool } from 'features/TrackPage/server/pool';

interface QueryParameters {
    date: string;
    trackId: number;
}

interface UseRacesQueryProps {
    date: string;
    enabled?: boolean;
    productId: ProductId;
    racingCardTrackId: number;
    poolTrackId: number;
    isMultitrack?: boolean;
}

const raceCardPlaceholderData = {
    date: '',
    races: { races: [] },
    track: {} as Label,
    betType: {} as Label,
    timestamp: '',
} as RaceCardData;

const trackPoolPlaceholderData = {} as TrackPool;

const fetchRaces = async ({
    racingCardTrackId,
    date,
    productId,
}: {
    racingCardTrackId: number;
    date: string;
    productId: ProductId;
}) => {
    const response = await request<RaceCardData, QueryParameters>(
        'InfoService',
        'POST',
        `fetch${PRODUCTS_SYS_NAMES[productId]}RacingCard`,
        { trackId: racingCardTrackId, date }
    );

    if (!response.success || Object.keys(response.data).length === 0) {
        throw new Error(response.errorMessage);
    }

    return response.data;
};

const fetchTrackPool = async ({
    poolTrackId,
    date,
}: Partial<UseRacesQueryProps & QueryParameters>) => {
    const response = await request<TrackPool, Partial<QueryParameters>>(
        'InfoService',
        'POST',
        `fetchTrackPoolInformation`,
        { trackId: poolTrackId, date }
    );

    if (!response.success) {
        throw new Error(response.errorMessage);
    }

    return response.data;
};

const useRacesQuery = ({
    date,
    racingCardTrackId,
    poolTrackId,
    productId,
    isMultitrack = false,
    enabled = false,
}: UseRacesQueryProps) => {
    const {
        status: racesQueryStatus,
        data: racesQueryData,
        error: racesQueryError,
        isSuccess: racesQuerySuccess,
        isLoading: racesQueryLoading,
    } = useQuery({
        queryKey: ['racingCard', date, racingCardTrackId, productId],
        queryFn: () => fetchRaces({ date, racingCardTrackId, productId }),
        staleTime: 60 * 10 * 1000,
        enabled,
    });

    const {
        status: poolQueryStatus,
        data: poolQueryData,
        error: poolQueryError,
        isSuccess: poolQuerySuccess,
        isLoading: poolQueryLoading,
    } = useQuery({
        queryKey: ['trackPool', date, poolTrackId],
        queryFn: () => fetchTrackPool({ date, poolTrackId }),
        staleTime: 60 * 10 * 1000,
        enabled,
    });

    const requestsSuccess = racesQuerySuccess && poolQuerySuccess;

    const data = requestsSuccess
        ? deserializeRaces(
              racesQueryData ?? raceCardPlaceholderData,
              poolQueryData ?? trackPoolPlaceholderData,
              productId,
              poolTrackId,
              isMultitrack
          )
        : [];

    return {
        // races query
        racesQueryData,
        racesQueryStatus,
        racesQuerySuccess,
        racesQueryLoading,
        racesQueryError,
        // pool query
        poolQueryData: poolQueryData ?? trackPoolPlaceholderData,
        poolQueryStatus,
        poolQuerySuccess,
        poolQueryLoading,
        poolQueryError,
        // merged requests
        data,
        error: racesQueryError ? racesQueryError : poolQueryError ? poolQueryError : null,
        isLoading: racesQueryLoading || poolQueryLoading,
        isSuccess: racesQuerySuccess && poolQuerySuccess,
    };
};

export default useRacesQuery;
