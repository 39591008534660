import styled, { css } from 'styled-components';
import Wrapper from 'ui/Wrapper';
import { byPlatform } from 'utils/platforms';
import Text from 'ui/Text';

export const ShortcutsTitle = styled(Text).attrs({
    bold: true,
    size: byPlatform('20', '32'),
    padding: byPlatform('10px 20px 15px', '10px 30px 15px'),
})``;

export const ShortcutsContainer = styled(Wrapper)`
    display: flex;
    flex-direction: column;
    padding: ${byPlatform('0 15px', '0')};
`;

export const CardsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 13px;

    margin-right: -15px;
    padding-right: 15px;

    overflow-y: auto;
    &::-webkit-scrollbar {
        display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
`;

export const ShortcutContainer = styled.div`
    min-width: 110px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px;
    ${({ background }) => background && `background-color: ${background}`};
    border-radius: 5px;
    ${({ length }) => length && `flex: 1 1 calc(${100 / length}%)`};
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    ${({ blurred }) =>
        blurred &&
        css`
            filter: blur(5px);
        `}

    &:hover {
        ${({ hoverColor, background }) =>
            (hoverColor || background) && `background-color: ${hoverColor || background}`};
    }
`;
