import { defaultDuration } from 'ui/animations/FadeIn2';
import { history, syncModalStateWithQueryParams } from 'utils/navigation';

const SHOW_MODAL = 'SHOW_MODAL';
const HIDE_MODAL = 'HIDE_MODAL';
const ANIMATE_MODAL_BEFORE_CLOSE = 'ANIMATE_MODAL_BEFORE_CLOSE';
const SHOW_UNWRAPPED_MODAL = 'SHOW_UNWRAPPED_MODAL';
const HIDE_UNWRAPPED_MODAL = 'HIDE_UNWRAPPED_MODAL';
const TRACK_HISTORY = 'TRACK_HISTORY';
const SET_LAST_VISITED_RACE_URL = 'SET_LAST_VISITED_RACE_URL';
const SET_LAST_URL_BEFORE_LOGIN = 'SET_LAST_URL_BEFORE_LOGIN';
const SET_PREVIOUS_URL = 'SET_PREVIOUS_URL';
const SET_OVERLAY = 'SET_OVERLAY';
const SET_COMPONENTS_OPTIONS = 'SET_COMPONENTS_OPTIONS';
const SHOW_HIGHLIGHT = 'SHOW_HIGHLIGHT';

export function showModal<D>(
    modalId = '',
    priority = 0,
    data: D | null | undefined = null,
    duration = 0,
    showingAction = SHOW_MODAL,
    hidingAction = HIDE_MODAL
) {
    return (dispatch: any) => {
        dispatch({
            type: showingAction,
            payload: {
                id: modalId,
                data,
                visible: true,
                priority,
                cameFrom: history.location.pathname,
            },
        });

        if (duration > 0) {
            setTimeout(() => {
                dispatch({
                    type: hidingAction,
                    payload: {
                        id: modalId,
                        meta: {},
                    },
                });
            }, duration);
        }
    };
}

export function hideModal(modalId = '', meta = {}) {
    return (dispatch: any) => {
        dispatch({
            type: HIDE_MODAL,
            payload: {
                id: modalId,
                meta,
            },
        });

        syncModalStateWithQueryParams(modalId);
    };
}

export function hideModalAnimate(modalId = '', animationDuration = defaultDuration, meta = {}) {
    return (dispatch: any) => {
        dispatch({
            type: ANIMATE_MODAL_BEFORE_CLOSE,
            payload: {
                id: modalId,
                duration: animationDuration,
                meta,
            },
        });

        setTimeout(() => {
            dispatch(hideModal(modalId, meta));
        }, animationDuration);
    };
}

export function showUnwrappedModal(modalId = '', data = {}) {
    return {
        type: SHOW_UNWRAPPED_MODAL,
        payload: {
            id: modalId,
            data,
        },
    };
}

export function hideUnwrappedModal(modalId = '') {
    return {
        type: HIDE_UNWRAPPED_MODAL,
        payload: {
            id: modalId,
        },
    };
}

export function trackHistory(currentItem: string) {
    return {
        type: TRACK_HISTORY,
        payload: {
            currentItem,
        },
    };
}

export function setLastVisitedRaceURL(lastVisitedRaceURL: string) {
    return {
        type: SET_LAST_VISITED_RACE_URL,
        payload: lastVisitedRaceURL,
    };
}

export function setLastURLBeforeLogin(lastURLBeforeLogin: string) {
    return {
        type: SET_LAST_URL_BEFORE_LOGIN,
        payload: lastURLBeforeLogin,
    };
}

export function setOverlay(status: string) {
    return {
        type: SET_OVERLAY,
        payload: status,
    };
}

export function setPreviousURL(previousURL: string) {
    return {
        type: SET_PREVIOUS_URL,
        payload: previousURL,
    };
}

export function setComponentsOptions(options: any) {
    return {
        type: SET_COMPONENTS_OPTIONS,
        payload: options,
    };
}

export function setHighlightState(state: boolean) {
    return {
        type: SHOW_HIGHLIGHT,
        payload: state,
    };
}
export {
    SHOW_MODAL,
    HIDE_MODAL,
    ANIMATE_MODAL_BEFORE_CLOSE,
    SHOW_UNWRAPPED_MODAL,
    HIDE_UNWRAPPED_MODAL,
    TRACK_HISTORY,
    SET_LAST_VISITED_RACE_URL,
    SET_LAST_URL_BEFORE_LOGIN,
    SET_PREVIOUS_URL,
    SET_OVERLAY,
    SET_COMPONENTS_OPTIONS,
    SHOW_HIGHLIGHT,
};
