import React from 'react';
import Popup, { PopupWrapper as PopupWrapperDef } from 'ui/Popup';
import styled from 'styled-components';

const PopupWrapper = styled(PopupWrapperDef)`
    width: 450px;
    border-radius: 7px;
    height: 700px;
    min-height: 0;
    box-sizing: border-box;
    overflow: hidden;
`;

const withPopupWrapper = (WrappedComponent) => (props) => {
    return (
        <Popup PopupWrapperComponent={PopupWrapper} disableCloseButton>
            <WrappedComponent {...props} />;
        </Popup>
    );
};

export default withPopupWrapper;
