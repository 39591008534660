import React from 'react';
import FlexWrapper from 'ui/FlexWrapper';
import Wrapper from 'ui/Wrapper';
import Text from 'ui/Text';
import { COLORS } from 'themes';
import qrIcon from './assets/qr_code.png';
import UserConductor from 'common/conductors/UserConductor';

class QRCodeModal extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            profile: null,
        };
    }
    componentDidMount() {
        this.props.enableCloseButton(null, COLORS.grey);

        UserConductor.getProfile()
            .then((profile) => {
                this.setState({ profile });
            })
            .catch((err) => console.log(err));
    }

    render() {
        return (
            <FlexWrapper align="space-between" margin="0px" padding="0px">
                <Wrapper margin="0px" padding="0px">
                    <Text align="center" size="x3" padding="50px 0px">
                        Denne indgangsbillet er tilhørende:{' '}
                        {this.state.profile
                            ? `${this.state.profile.firstName} ${this.state.profile.lastName}`
                            : 'Gæst'}
                    </Text>
                </Wrapper>

                <Wrapper align="center" margin="0px" padding="0px">
                    <img src={qrIcon} alt="QR" width="200px" />
                </Wrapper>

                <Wrapper margin="0px" padding="0px">
                    <Text
                        secondary
                        padding="50px 0px"
                        width="300px"
                        align="center"
                        style={{ margin: '0px auto' }}
                    >
                        Vi forbeholder os retten til at spørge efter ID ved indgangen
                    </Text>
                </Wrapper>
            </FlexWrapper>
        );
    }
}

export default QRCodeModal;
