import React from 'react';
import { Col as GameHeaderCol, Row as GameHeaderRow } from 'react-grid-system';
import { COLORS } from 'themes';

import getTexts from 'utils/localization';
const t = getTexts();

const SubHeader = ({ product, noBackground = false }) => (
    <GameHeaderRow
        style={{
            margin: 0,
            background: !noBackground ? COLORS.special.calm : 'none',
            color: COLORS.primaryText,
            textTransform: 'uppercase',
            height: '18px',
            lineHeight: '18px',
            fontWeight: 500,
        }}
    >
        <GameHeaderCol
            xs={2}
            style={{
                padding: '0px',
                fontSize: '10px',
                textAlign: 'center',
            }}
        >
            {/* Get rid of the copy/paste. There is the same code in the features/Receipt/PickHeader */}
            {product.isTrio() || product.isTvilling() || product.isKomb()
                ? t.betSlip.place
                : product.isVProduct()
                    ? t.betSlip.department
                    : t.betSlip.track}
        </GameHeaderCol>
        <GameHeaderCol
            xs={10}
            style={{
                padding: '0 15px 0 0',
                fontSize: '10px',
                paddingLeft: 0,
            }}
        >
            {t.betSlip.nrAndHorse}
        </GameHeaderCol>
    </GameHeaderRow>
);

export default SubHeader;
