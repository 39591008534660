import raceDaySelector from 'common/selectors/raceDaySelector';
import productSelector from 'common/selectors/productSelector';
import Track from 'common/DataObjects/Track';
import {
    getMultitrackCodes,
    getTrackByIdentity,
    getTrackFromRaceDay,
} from 'common/selectors/trackSelector';
import get from 'lodash/get';

/**
 * @param  {Object}    state
 * @return {boolean}
 */
export const isMultitrackProduct = (state) => {
    return productSelector(state).isMultitrack;
};

export const getMultitrackId = (state, { productId }) => {
    const raceDay = raceDaySelector(state);
    const multitrackSetup = get(raceDay, ['multipleTrackSetups', productId]);
    return multitrackSetup ? multitrackSetup.trackId : 0;
};

export const isMultitrackBy = (state, date, trackId, productId) => {
    try {
        const raceDay = state.AISDataProvider.raceDayData?.find(
            (rd) => rd.date === date && rd.trackId === trackId
        );

        if (!raceDay) {
            return false;
        }

        const product = raceDay.products.find((p) => p.id === productId);

        if (!product) {
            return false;
        }

        return product.isMultitrack;
    } catch (e) {
        console.log('isMultitrackBy', e);
    }
};

export const findMultitrackCoupleBy = (state, date, trackId, productId) => {
    const raceDay = state.AISDataProvider.raceDayData.find(
        (rd) => rd.date === date && rd.trackId === trackId
    );

    if (!raceDay) {
        return false;
    }

    const tracks = state.AISDataProvider.tracks;
    const multipleTrackSetup = raceDay.multipleTrackSetups[productId];
    const mainTrack = tracks.list.find(
        (t) => t.id === trackId && multipleTrackSetup.trackIds.includes(t.code)
    );

    if (!mainTrack) {
        return false;
    }

    const coupleTrackId = raceDay.multipleTrackSetups[productId].trackIds.find(
        (trackId) => trackId !== mainTrack.code
    );

    return Track.fill(tracks.byCode[coupleTrackId]);
};

export const getMultitrackCouple = (state) => {
    const currentTrack = getTrackFromRaceDay(state);

    const coupleTrackCode = getMultitrackCodes(state).find(
        (trackCode) => trackCode !== currentTrack.code
    );

    return getTrackByIdentity(state, coupleTrackCode, true);
};
