import React from 'react';
import Wrapper from 'ui/Wrapper';
import UIButton from 'ui/controls/Button';
import Text from 'ui/Text';
import styled from 'styled-components';
import { connect } from 'react-redux';
import {
    applySystemCoupon,
    checkinBySystemCoupon,
} from 'features/ScanLogicTerminal/actions';
import { COUPON_STATUSES, SYSTEM_NAMES } from './contstants';
import { getFormattedCouponDate, getMappedData, getPositions } from './common';
import { hideScanModal } from './actions';
import { Col, Row } from 'react-grid-system';
import { saveJSONparsing } from 'utils/formatters';
import { TopWinIcon } from './uiComponents';

import getTexts from 'utils/localization';
import { COLORS } from 'themes/index.ts';
import Divider from 'ui/Divider';

const t = getTexts();

const MainWrapper = styled(Wrapper)`
    padding-top: 60px;
`;
const ControlsWrapper = styled(Wrapper)`
    padding: 0 20px;
    display: flex;
    justify-content: center;
`;

const Button = styled(UIButton)`
    border-radius: 20px;
    padding: 10px 20px;
    height: 40px;
    width: 450px;
    margin: 0 0 0 10px;
`;

const TextWrapper = styled(Wrapper)`
    box-sizing: border-box;
    padding: 5px;
    color: ${COLORS.primaryText};
    font-size: 14px;
    p {
        margin: 0;
        color: ${COLORS.primaryText};
        font-weight: bold;
    }
`;

class SystemStatus extends React.Component {
    constructor() {
        super();

        this.state = {
            disableApplyButton: false,
        };
    }

    isSystemBets() {
        return !!this.props.data.coupons;
    }

    isCouponStatusWon() {
        return this.getCouponStatus(this.props.data) === COUPON_STATUSES.WON;
    }

    isCouponStatusLost() {
        return this.getCouponStatus(this.props.data) === COUPON_STATUSES.LOST;
    }

    isCouponStatusPending() {
        return (
            this.getCouponStatus(this.props.data) === COUPON_STATUSES.PENDING
        );
    }

    isCouponWinAmountOverflowed() {
        const maxPossibleWin = 1500000; /* 15k kr*/
        if (this.isSystemBets()) {
            return this.props.data.sale.win >= maxPossibleWin;
            /*return this.props.data.coupons.some(
                (coupon) => coupon.win_amount > maxPossibleWin
            );*/
        }
    }

    isDerbyCoupon() {
        if (this.isSystemBets()) {
            return this.props.data.coupons.some(
                (coupon) =>
                    coupon.couponInfo &&
                    coupon.resultInfo &&
                    coupon.trackInfo &&
                    coupon.receipt
            );
        } else {
            return !this.isBet25Coupon();
        }
    }

    isBet25Coupon() {
        if (this.isSystemBets()) {
            return (
                !this.isDerbyCoupon() &&
                this.props.data.coupons.some((coupon) => coupon.slip_data)
            );
        } else {
            return !this.isDerbyCoupon();
        }
    }

    isCouponPayouted() {
        return this.getCoupons().some(
            (coupon) => String(coupon.paidout).toLowerCase() === 'yes'
        );
    }

    lostClickHandler = () => {
        this.props.hideScanModal();
    };

    doneClickHandler = () => {
        this.props.hideScanModal();
    };

    applyHandler = () => {
        if (this.props.auth.user) {
            this.setState(
                {
                    disableApplyButton: true,
                },
                () => {
                    this.props.applySystemCoupon(
                        this.props.userSessionAdapter,
                        this.props.auth.user.id,
                        this.props.data.sale.hash
                    );
                }
            );
        } else {
            this.setState(
                {
                    disableApplyButton: true,
                },
                () => {
                    this.props.checkinBySystemCoupon(
                        this.props.userSessionAdapter,
                        this.props.data.sale.hash
                    );
                }
            );
        }
    };

    getCouponStatus(data) {
        let status = COUPON_STATUSES.PENDING;
        if (
            data.coupons.some(
                (coupon) => coupon.status === COUPON_STATUSES.PENDING
            )
        ) {
            return COUPON_STATUSES.PENDING;
        }
        if (data.coupons) {
            status = data.coupons.some(
                (coupon) => coupon.status === COUPON_STATUSES.WON
            )
                ? COUPON_STATUSES.WON
                : data.coupons.some(
                      (coupon) => coupon.status === COUPON_STATUSES.LOST
                  )
                    ? COUPON_STATUSES.LOST
                    : COUPON_STATUSES.PENDING;
        } else {
            status =
                data.coupon.status === COUPON_STATUSES.WON
                    ? COUPON_STATUSES.WON
                    : data.coupon.status === COUPON_STATUSES.LOST
                        ? COUPON_STATUSES.LOST
                        : COUPON_STATUSES.PENDING;
        }
        return status;
    }

    getSystemNames(coupons) {
        let systems = [];
        let systemText = '';
        if (coupons) {
            coupons.forEach((coupon) => {
                const slip = coupon.slipData;

                if (slip.length > 0) {
                    systems.push(SYSTEM_NAMES[slip.length]);
                }
            });
            const result = systems.reduce(
                (a, c) => a.set(c, (a.get(c) || 0) + 1),
                new Map()
            );
            result.forEach((value, key) => {
                if (systemText !== '') {
                    systemText += `, ${value > 0 ? value : ''} ${key}`;
                } else {
                    systemText += `${value > 0 ? value : ''} ${key}`;
                }
            });
            return <TextWrapper>{systemText}</TextWrapper>;
        } else {
            return null;
        }
    }

    getCoupons() {
        let coupons;
        if (this.isSystemBets()) {
            coupons = this.props.data.coupons;
        } else {
            return;
        }

        let mappedData = [];

        coupons.forEach((coupon) => {
            const temMappedData = getMappedData(coupon);

            temMappedData.couponInfo = saveJSONparsing(
                temMappedData.couponInfo
            );
            temMappedData.slipData = saveJSONparsing(
                temMappedData.slipData,
                {}
            );
            temMappedData.receipt = saveJSONparsing(temMappedData.receipt, {});
            temMappedData.coupon = saveJSONparsing(temMappedData.coupon, {});
            temMappedData.resultInfo = saveJSONparsing(
                temMappedData.resultInfo
            );
            mappedData.push(temMappedData);
        });
        return mappedData;
    }

    getSale() {
        if (this.isSystemBets()) {
            const sale = this.props.data.sale;
            const mappedData = {
                ...sale,
                betList: sale.betList || sale.bet_list,
                couponList: sale.couponList || sale.coupon_list,
                couponQuantity: sale.couponQuantity || sale.coupon_quantity,
                createdAt: sale.createdAt || sale.created_at,
                saleDate: sale.saleDate || sale.sale_date,
                sessionId: sale.sessionId || sale.session_id,
                shopId: sale.shopId || sale.shop_id,
                saleId: sale.saleId || sale.sale_id,
            };
            mappedData.betList = saveJSONparsing(mappedData.betList, {});
            mappedData.couponList = saveJSONparsing(mappedData.couponList, []);

            return mappedData;
        }
    }

    getBet25CouponStatusLabel() {
        return this.isCouponStatusWon()
            ? t.Terminal.CouponStatus.bet25CouponTitles.won
            : this.isCouponStatusLost()
                ? t.Terminal.CouponStatus.bet25CouponTitles.lost
                : t.Terminal.CouponStatus.bet25CouponTitles.pending;
    }

    renderWarningAboutOverflow() {
        const transferableCoupon =
                this.isCouponStatusWon() && !this.isCouponPayouted(),
            overflowedWinAmount = this.isCouponWinAmountOverflowed();

        return transferableCoupon && overflowedWinAmount ? (
            <Text align="center">
                {t.Terminal.CouponStatus.overflowedWinAmount}
            </Text>
        ) : null;
    }
    titleByStatus(status) {
        switch (status) {
            case COUPON_STATUSES.WON:
                return t.Terminal.CouponStatus.statusTitles.win;
            case COUPON_STATUSES.LOST:
                return t.Terminal.CouponStatus.statusTitles.lost;
            case COUPON_STATUSES.PENDING:
                return t.Terminal.CouponStatus.statusTitles.pending;
            case COUPON_STATUSES.CANCELLED:
                return t.Terminal.CouponStatus.statusTitles.cancelled;
            default:
                return '';
        }
    }

    totalWinPart(sale) {
        return sale.stake || sale.win ? (
            <TextWrapper>
                {sale.stake ? <p>Indsats: {sale.stake / 100} kr.</p> : null}
                {sale.win ? <p>Gevinst: {sale.win / 100} kr.</p> : null}
            </TextWrapper>
        ) : null;
    }

    renderCommonControls() {
        const transferableCoupon =
                this.isCouponStatusWon() && !this.isCouponPayouted(),
            overflowedWinAmount = this.isCouponWinAmountOverflowed();
        return (
            <ControlsWrapper>
                {transferableCoupon &&
                    !overflowedWinAmount && (
                        <Button
                            accent
                            bold
                            onClick={this.applyHandler}
                            disabled={this.state.disableApplyButton}
                            className="uppercase"
                        >
                            {t.Terminal.CouponStatus.transferToTerminal}
                        </Button>
                    )}
                {(this.isCouponStatusWon() ||
                    this.isCouponStatusLost() ||
                    this.isCouponStatusPending()) && (
                    <Button
                        grey
                        bold
                        onClick={this.doneClickHandler}
                        className="uppercase"
                    >
                        {t.Terminal.CouponStatus.close}
                    </Button>
                )}
                {this.isCouponStatusLost() && (
                    <Button
                        red
                        bold
                        onClick={this.lostClickHandler}
                        style={{
                            color: '#fff',
                        }}
                        className="uppercase"
                    >
                        {t.Terminal.CouponStatus.lost}
                    </Button>
                )}
            </ControlsWrapper>
        );
    }

    renderBetCoupon() {
        const { createdAt, saleId } = this.getSale();
        const formattedCouponDate = getFormattedCouponDate(createdAt);
        return (
            <MainWrapper>
                {this.isCouponStatusWon() && <TopWinIcon />}
                <Text align="center" size="x3">
                    {this.getBet25CouponStatusLabel()}
                </Text>
                <Text align="center">
                    {saleId}
                    {' - '}
                    {formattedCouponDate}
                </Text>
                <Wrapper
                    style={{
                        overflow: 'auto',
                        maxHeight: '450px',
                    }}
                >
                    {this.getCoupons().map((coupon) => (
                        <Wrapper key={coupon.couponId}>
                            {getPositions(coupon.slipData, coupon.status, true)}
                            <Row>
                                <Col
                                    xs={7}
                                    style={{
                                        margin: '0px',
                                        padding: '0px',
                                    }}
                                >
                                    <Text
                                        bold={900}
                                        size="x1"
                                        primaryText
                                        padding="10px"
                                    >
                                        {t.betSlip.sum}:{' '}
                                        {(coupon.stake / 100).toFixed(2)}{' '}
                                        {t.currency}
                                    </Text>
                                    {this.isCouponStatusWon() && (
                                        <Text
                                            bold={900}
                                            size="x1"
                                            secondary
                                            padding="10px"
                                        >
                                            {t.History.winAmount}:{' '}
                                            {(coupon.winAmount / 100).toFixed(
                                                2
                                            )}{' '}
                                            {t.currency}
                                        </Text>
                                    )}
                                    <Text
                                        bold={400}
                                        size="x1"
                                        accent={
                                            this.titleByStatus(
                                                coupon.status
                                            ) ===
                                            t.Terminal.CouponStatus.statusTitles
                                                .pending
                                        }
                                        green={
                                            this.titleByStatus(
                                                coupon.status
                                            ) ===
                                            t.Terminal.CouponStatus.statusTitles
                                                .win
                                        }
                                        red={
                                            this.titleByStatus(
                                                coupon.status
                                            ) ===
                                            t.Terminal.CouponStatus.statusTitles
                                                .lost
                                        }
                                        padding="10px"
                                    >
                                        Status:{' '}
                                        {this.titleByStatus(coupon.status)}
                                    </Text>
                                </Col>
                            </Row>
                            <Divider grey />
                        </Wrapper>
                    ))}
                    {this.getSystemNames(this.getCoupons())}
                    {this.totalWinPart(this.getSale())}
                </Wrapper>
                {this.renderCommonControls()}
                {this.renderWarningAboutOverflow()}
            </MainWrapper>
        );
    }

    render() {
        if (this.isBet25Coupon()) {
            return this.renderBetCoupon();
        }
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth,
        scanModal: state.ScanModal,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        applySystemCoupon: (userSessionAdapter, sessionId, couponHash) => {
            dispatch(
                applySystemCoupon(userSessionAdapter, sessionId, couponHash)
            );
        },
        checkinBySystemCoupon: (userSessionAdapter, couponHash) => {
            dispatch(checkinBySystemCoupon(userSessionAdapter, couponHash));
        },

        hideScanModal: () => {
            dispatch(hideScanModal());
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SystemStatus);
