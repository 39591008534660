import axios from 'axios';

const POLLING_INTERVAL = 60 * 1000;

const trackStat = (userId) => {
    const payload = {
        hash: 'live',
        streamname: 'fasttrack.smil',
        client_id: userId,
        browser_width: window.innerWidth,
        browser_height: window.innerHeight,
    };

    axios
        .post(
            'https://fasttrack.webstream.dk/statistik/save_player_statistic',
            payload
        )
        .catch(function(error) {
            console.log(error);
        });
};

export const trackStatPolling = (userId) => {
    // run immediately
    trackStat(userId);

    const intervalId = setInterval(() => {
        trackStat(userId);
    }, POLLING_INTERVAL);

    return () => clearInterval(intervalId);
};
