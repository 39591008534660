import React from 'react';
import classNames from 'classnames';

import { BetPickBtn } from './PickButtons.styled';
import useTrioColumnState from 'features/TrackPage/hooks/useTrioColumnState';
import useColumnSelection from 'features/TrackPage/hooks/useColumnSelection';
import { isMobile } from 'utils/platforms';

const TrioPickColumnButtons = () => {
    const { trioMarksIndicator } = useTrioColumnState();
    const { toggleTrioColumn } = useColumnSelection();
    const cls = classNames('flex col-gap-5 pl-15 fade-in', { 'pt-5': isMobile, 'pb-5': isMobile });

    return (
        <div className={cls}>
            <BetPickBtn active={trioMarksIndicator[1]} onClick={() => toggleTrioColumn(1)}>
                1
            </BetPickBtn>
            <BetPickBtn active={trioMarksIndicator[2]} onClick={() => toggleTrioColumn(2)}>
                2
            </BetPickBtn>
            <BetPickBtn active={trioMarksIndicator[3]} onClick={() => toggleTrioColumn(3)}>
                3
            </BetPickBtn>
        </div>
    );
};

export default TrioPickColumnButtons;
