import styled from 'styled-components';
import { COLORS } from '../../../../../../themes';
import { InputControl } from '../../../../../../ui/controls/Input';
import Button from '../../../../../../ui/controls/Button';

const SendBarWrapper = styled.section`
    border: 1px solid ${COLORS.lightGrey};
    border-radius: 7px;
    width: auto;
    height: 32px;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    line-height: 32px;
    margin: 10px 20px;
`;

const SendButton = styled(Button)`
    margin: 0px;
    width: 80px;
    transition: background-color 0.3s ease-out;
`;

const TextInput = styled(InputControl)`
    font-size: 12px;
    width: 100%;
    margin: 0px;
    padding: 0px 15px;
    &::placeholder {
        opacity: 1;
        transition: opacity 0.3s ease-in-out;

        &:focus {
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
        }

        &:bluer {
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }
    }
    &:focus::placeholder {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }
    &:bluer::placeholder {
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
    }
`;

export { SendBarWrapper, SendButton, TextInput };
