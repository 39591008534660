import React, { useEffect } from 'react';
import styled from 'styled-components';
import SignUp from '@it25syv/sign-up';
import { history } from 'utils/navigation';
import { useSelector } from 'react-redux';
import { fadeInMixin } from 'ui/animations/FadeIn2';
import NotificationConductor from 'common/conductors/NotificationConductor';

export const Wrapper = styled.div`
    width: 100%;
    min-height: 100%;
    position: absolute;
    padding: 15px;
    box-sizing: border-box;
    right: 0;
    top: 0;
    background-color: white;
    ${fadeInMixin};
    @media screen and (min-width: 500px) {
        width: 375px;
    }
`;

const EXISTING_EMAIL_MSG =
    'Din email er allerede registreret og din konto ligger klar til brug. Har du glemt dit kodeord anbefaler vi, at du logger ind med NemID eller kontakter kundeservice.';

const EXISTING_CPR_MSG =
    'Dit CPR-nr. er allerede registreret, og din konto ligger klar til brug. Har du glemt dit kodeord, anbefaler vi, at du logger ind med MitID eller kontakter kundeservice.';

const Modal = ({ setBackground, showModal, hideModal, scrollableWrapperRef }) => {
    // Set overlay instead of default green desktop background
    useEffect(() => {
        setBackground('rgba(0,0,0,0.5)');
        window.dataLayer.push({ event: 'derby25.clickToSignup' });
    }, [setBackground]);

    useEffect(() => {
        history.push('/opret-konto');
    }, []);

    const user = useSelector(state => state?.auth?.user);

    useEffect(() => {
        if (user?.userId) {
            history.push('/');
            hideModal('SIGNUP');
        }
    }, [hideModal, user]);

    const closeSignup = () => {
        hideModal('SIGNUP');
        history.push('/');
    };

    return (
        <Wrapper>
            <SignUp
                onEmailExists={() => {
                    showModal('USER_EXISTS', 2, {
                        message: EXISTING_EMAIL_MSG,
                    });
                }}
                onCPRExists={() => {
                    showModal('USER_EXISTS', 2, {
                        message: EXISTING_CPR_MSG,
                    });
                }}
                showError={error => NotificationConductor.error(error)}
                onClose={closeSignup}
                project="derby25"
                scrollableWrapperRef={scrollableWrapperRef}
                minimumDepositLimit={100}
                step1Headline={'Modtag nyheder og tilbud'}
            />
        </Wrapper>
    );
};

export default Modal;
