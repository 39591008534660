import React from 'react';
import get from 'lodash/get';
import { CloseModalBtnStyled, PopupWrapperStyled } from './styled';
import Menu from './Menu';
import { connect } from 'react-redux';
import { logout } from 'common/actions/authActions';
import { getComponentsByUrls, getConfigByUrls, Paths, ViewsMap } from 'configs/user-area';
import { withRouter } from 'react-router-dom';
import Headline from 'ui/Headline';
import { history, hidePopup } from 'utils/navigation';
import { Overlay } from 'ui/Popup';
import Modal from 'ui/ModalContent';
import FlexWrapper from 'ui/FlexWrapper';
import { FilledWrapper } from 'ui/Wrapper';
import classNames from 'classnames';

class UserAreaPopup extends React.Component {
    state = {
        hiding: false,
    };

    componentDidUpdate(prevProps) {
        // session expired
        if (prevProps.auth.user && !this.props.auth.user) {
            history.replace(history.location.state?.background ?? '/');
        }
    }

    animateHide = () => {
        this.setState({ hiding: true });
    };

    closePopup = () => {
        if (this.state.hiding) {
            hidePopup('USER_AREA_DESKTOP');
        }
    };

    getPathname = () => {
        const backgroundPathname = get(history, 'location.state.background.pathname', '');
        const currentPathname = history.location.pathname;

        if (!currentPathname.startsWith('/konto') && backgroundPathname.startsWith('/konto')) {
            return backgroundPathname.replace('/', '');
        }

        return currentPathname.replace('/', '');
    };

    isNewUserAreaUI = path => [Paths.transactions.url].includes(path); // temp solution while migrating to NEW_USER_AREA UI

    renderContentView = () => {
        const path = this.getPathname();
        const components = getComponentsByUrls();
        const ViewMapComponent = components[path];

        if (ViewMapComponent) {
            return <ViewMapComponent {...this.props} desktop={true} />;
        } else {
            const DepositComponent = ViewsMap.indbetaling;

            return <DepositComponent {...this.props} desktop={true} />;
        }
    };

    renderHeadline = () => {
        const path = this.getPathname();
        const userAreaConfig = getConfigByUrls();
        let pathConfig = userAreaConfig[path];

        if (!pathConfig) {
            pathConfig = Paths.deposit;
        }

        return (
            <Headline
                padding="30px"
                align="center"
                black
                style={{
                    ...(this.isNewUserAreaUI(path)
                        ? {
                              padding: '16px 0',
                              fontSize: '16px',
                              fontWeight: '900',
                              borderBottom: '1px solid #DFE5ED',
                          }
                        : {}),
                }}
            >
                {this.isNewUserAreaUI(path) ? null : (
                    <>
                        <img
                            src={pathConfig?.icon}
                            alt={pathConfig?.label}
                            style={{
                                verticalAlign: 'middle',
                                filter: 'grayscale(100%) brightness(110%) contrast(0.1)',
                            }}
                            width="30px"
                        />{' '}
                    </>
                )}
                {pathConfig?.label}
            </Headline>
        );
    };

    logoutHandler = () => {
        this.animateHide();
        this.props.logout();
    };

    render() {
        const { priority } = this.props;
        const path = this.getPathname();

        const userData = this.props.auth.user;
        const authFinished = this.props.auth.finished;

        return (
            <Modal
                priority={priority}
                className={classNames('modal-fade', { 'modal-fade-out': this.state.hiding })}
                onAnimationEnd={this.closePopup}
            >
                <Overlay>
                    <FlexWrapper direction="column">
                        <PopupWrapperStyled>
                            <CloseModalBtnStyled onClick={this.animateHide} />
                            {userData && (
                                <FlexWrapper padding="0px" direction="row">
                                    <FilledWrapper width="250px" padding="0px" grey>
                                        <Menu
                                            userData={userData}
                                            authFinished={authFinished}
                                            logoutHandler={this.logoutHandler}
                                            hideModal={this.closeSelf}
                                        />
                                    </FilledWrapper>

                                    <FilledWrapper
                                        width="550px"
                                        padding="10px"
                                        style={{
                                            overflowY: 'auto',
                                            ...(this.isNewUserAreaUI(path) && {
                                                display: 'flex',
                                                flexDirection: 'column',
                                                padding: 0,
                                                fontSize: '16px', // todo fix it
                                            }),
                                            position: 'relative',
                                        }} // temp solution while migrating to NEW_USER_AREA UI
                                        {...(this.isNewUserAreaUI(path)
                                            ? { newLightGrey: true }
                                            : { white: true })} // temp solution while migrating to NEW_USER_AREA UI
                                    >
                                        {this.renderHeadline()}
                                        {this.renderContentView()}
                                    </FilledWrapper>
                                </FlexWrapper>
                            )}
                        </PopupWrapperStyled>
                    </FlexWrapper>
                </Overlay>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(logout());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserAreaPopup));
