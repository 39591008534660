import { combineEpics, Epic } from 'redux-observable';
import * as betBuddyEpics from 'features/Spilklub/epic';
import * as batchBettingEpics from 'features/BatchBetting/epic';
import * as uiEpics from './uiEpic';
import * as authEpics from './authEpic';
import { RootAction, RootState, Services } from '../store';

export type RootEpic = Epic<RootAction, RootAction, RootState, Services>;

export default combineEpics(
    ...Object.values(betBuddyEpics),
    ...Object.values(batchBettingEpics),
    ...Object.values(uiEpics),
    ...Object.values(authEpics)
);
