import { useCallback } from 'react';

const usePreventDefault = (fn, stopPropagation) => {
    return useCallback((...args) => {
        const [e] = args;
        e.preventDefault();
        stopPropagation && e.stopPropagation();
        fn(...args.splice(0, 1));
    });
};

export default usePreventDefault;
