import axios from 'axios';
import { REPLAYS_REST_PATH } from 'configs/rest';

const DerbyVisionAPI = {
    /**
     * Gets the replays using statistic data rows
     * Returns array of the links to video replays
     * @param data
     * @return {Promise<string[] | void>}
     */
    getUrl: async (data) => {
        const replayLinkPromises = data.map(({ raceKey }) => {
            const { date, raceNr, track } = raceKey;

            return axios.get(
                `${REPLAYS_REST_PATH}getUrl?trackCode=${
                    track.code
                }&date=${date}&raceNumber=${raceNr}`
            );
        });

        return Promise.all(replayLinkPromises)
            .then((responses) =>
                responses.map((res) => {
                    return res.data.data;
                })
            )
            .catch((reason) =>
                console.error(
                    `Could'nt get data from DerbyVision for multiple replays. ${reason}`
                )
            );
    },
    getReplayUrl: (data) => {
        const { countryCode, date, raceNumber, trackCode, trackId } = data;

        return axios.get(
            `${REPLAYS_REST_PATH}replay/url?countryCode=${countryCode}&date=${date}&raceNumber=${raceNumber}&trackCode=${trackCode}&trackId=${trackId}`
        );
    },
};
export default DerbyVisionAPI;
