import React, { Component } from 'react';
import Status from '../scenes/Status';

class ErrorBoundary extends Component {
    state = {
        error: '',
        errorInfo: {},
    };

    static getDerivedStateFromError(error, errorInfo) {
        return {
            error,
            errorInfo,
        };
    }

    render() {
        return this.state.error ? (
            <Status
                success={false}
                title={this.state.error.toString()}
                text={this.state.error.toString()}
                onClose={this.props.onClose}
            />
        ) : (
            this.props.children
        );
    }
}

export default ErrorBoundary;
