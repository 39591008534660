import { useEffect, useState } from 'react';

const useToggle = (
    initialValue: boolean = false,
    delay: number = 0
): [boolean, () => void, () => void, boolean] => {
    const [status, setStatus] = useState(initialValue);
    const [delayedStatus, setDelayedStatus] = useState(initialValue);

    const toggle = (): void => {
        setStatus(!status);
    };

    const turnOff = (): void => {
        setStatus(false);
    };

    useEffect(
        () => {
            if (!status) {
                setTimeout(() => setDelayedStatus(status), delay);
            } else {
                setDelayedStatus(status);
            }
        },
        [status, delay]
    );

    return [status, toggle, turnOff, delayedStatus];
};

export default useToggle;
