import styled, { css } from 'styled-components';
import { COLORS } from '../themes';
import { getBackgroundByProps } from 'utils/theme-helpers';

const boxShadowMixin = css`
    box-shadow: 0 0 15px ${COLORS.blockShadow};
`;

const CardHeader = styled.header`
    width: auto;
    height: auto;
    background: ${(props) => getBackgroundByProps(props)};
    color: ${COLORS.white};
    font-size: 13px;
    padding: 10px;
`;

const CardContent = styled.section`
    width: auto;
    height: auto;
    margin-top: 10px;
    color: ${COLORS.accentText};
    font-size: 14px;
    padding: 10px;
`;

const CardFooter = styled(CardContent)`
    font-size: 12px;
    border-top: 1px solid ${COLORS.lightGrey};
`;

const Card = styled.section`
    width: 100%;
    height: auto;
    min-height: 30px;
    color: ${COLORS.primary};
    ${boxShadowMixin};
    border-radius: ${(props) => (!props.borderRadius ? '8px' : props.borderRadius)};
    overflow: hidden;
    background: ${COLORS.white};
`;

export { CardHeader, CardContent, CardFooter, Card, boxShadowMixin };
