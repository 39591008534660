import React from 'react';
import styled from 'styled-components';
import { UserHistory } from '@it25syv/25syv-ui';
import { hidePopup, showPopup } from 'utils/navigation';
import { byPlatform } from 'utils/platforms';
import getTexts from 'utils/localization';
import Text from 'ui/Text';
import TopBar from 'ui/topbar';
import Modal from 'ui/ModalContent';
import Icon from 'ui/Icon';
import backBtnIcon from 'images/icons/chevron-back.svg';
import closeBtnIcon from 'images/icons/close-thin.svg';
import useTransactionQuery from './hooks/useTransactionsQuery';
import useTransactionStateStore from './hooks/useTransactionStateStore';

const ShortTopBar = styled(TopBar)`
    height: 94px;
    width: 100%;
    min-height: auto;
    border-radius: 0;
    display: flex;
    justify-content: end;
    align-items: center;
`;
const TextWrapper = styled.div`
    height: 54px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
`;

const ModalWrapper = styled(Modal)`
    display: flex;
    flex-direction: column;
`;

const t = getTexts();

const TransactionsHistory = () => {
    const { transactionState, setTransactionState } = useTransactionStateStore();

    const { dateFrom, dateTo } = transactionState ?? {};

    const { transactions, hasNextPage, fetchNextPage, isLoading } = useTransactionQuery();

    const loadMore = () => {
        if (hasNextPage) {
            fetchNextPage();
        }
    };

    const renderContent = () => {
        return (
            <UserHistory
                showDetails={data => {
                    showPopup('TRANSACTION_INFO', {
                        ...data,
                        keepPreviousBackgroundLocation: true,
                    });
                }}
                transactions={transactions ?? []}
                setTab={selectedTab => {
                    setTransactionState({ group: selectedTab.groupKey });
                }}
                setInitialConfig={config => {
                    const { dateFrom, dateTo, rowCount = 20 } = config;
                    setTransactionState({ rowCount, dateFrom, dateTo });
                }}
                loading={isLoading}
                loadMoreHandler={loadMore}
                loadMoreColor={'#1CA963'}
                showMore={hasNextPage}
                openChooseDate={() => {
                    showPopup('CHOOSE_DATE_POPUP', { keepPreviousBackgroundLocation: true });
                }}
                currentDate={{ dateFrom, dateTo }}
                resetDateHandler={date => {
                    setTransactionState({ ...date });
                }}
            />
        );
    };

    return byPlatform(
        <ModalWrapper>
            <ShortTopBar>
                <TextWrapper>
                    <Icon
                        onClick={() => {
                            hidePopup('TRANSACTIONS');
                        }}
                        src={backBtnIcon}
                        margin={'0 0 0 16px'}
                    />
                    <Text bold size={'x1.5'} white flex={'none'} style={{ alignContent: 'center' }}>
                        {t.userArea.nav.transactions}
                    </Text>
                    <Icon
                        onClick={() => {
                            hidePopup('TRANSACTIONS');
                            hidePopup('USER_AREA_MOBILE');
                        }}
                        src={closeBtnIcon}
                        margin={'0 16px 0 0'}
                    />
                </TextWrapper>
            </ShortTopBar>
            {renderContent()}
        </ModalWrapper>,
        renderContent()
    );
};

export default TransactionsHistory;
