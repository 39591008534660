import { request } from 'utils/server';
import { useQuery } from '@tanstack/react-query';

export interface Campaign {
    id: number;
    header: string;
    teaser: string;
    pretitle: string;
    rules: string;
    seo_url: string;
    link: string;
    term_text: string;
    image: string;
    bgvideo: string;
    section_name: SectionName;
    description: string;
    video: string;
    videomob: string;
}

export enum SectionName {
    offer = 'Tilbud',
    news = 'Nyheder',
    otherBrandCampaign = 'kampagner',
}

const fetchCampaigns = async () => {
    const response = await request<Campaign[], { type: string }>(
        'CMS',
        'GET',
        '/derby/rest/article/get-articles',
        {
            type: 'news',
        }
    );

    if (!response.success) {
        throw new Error(response.errorMessage);
    }

    return response.data;
};

const useCampaignsQuery = () => {
    const { status, data, error, isSuccess, isLoading } = useQuery({
        queryKey: ['campaigns'],
        queryFn: fetchCampaigns,
        staleTime: 60 * 60 * 1000,
    });

    return {
        status,
        data: data ?? [],
        error,
        isSuccess,
        isLoading,
    };
};

export default useCampaignsQuery;
