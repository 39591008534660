import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { betBuddyShown } from 'common/selectors/uiSelector';
import { hasURLParameters } from 'utils/navigation';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';

const URL_LEGS_REGEX = /^(d,)*\d_?(d,)*\d$/g;

const useBetslipVisibility = () => {
    const { isVProduct } = useTrackPage();

    const isBetBuddyShown = useSelector(betBuddyShown);

    const [visible, setVisibility] = useState(
        isVProduct &&
            hasURLParameters(
                ['leg1', 'leg2', 'leg3', 'leg4', 'leg5', 'leg6', 'leg7', 'leg8'],
                (key, value) => URL_LEGS_REGEX.test(value)
            )
    );

    const toggle = () => {
        setVisibility(!visible);
    };

    useEffect(() => {
        if (isBetBuddyShown) {
            setVisibility(true);
        }
    }, [isBetBuddyShown]);

    return [visible, setVisibility, toggle];
};

export default useBetslipVisibility;
