import React from 'react';
import styled from 'styled-components';
import DataPicker from './index';
import { byPlatform } from 'utils/platforms';

const StyledWrapper = styled.div`
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column;
    position: absolute;
    z-index: 1000;
`;

const StyledPopupWrapper = styled.div`
    height: 450px;
    width: 500px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.4);
    position: relative;
    z-index: 1001;
    margin: 0px auto;
    overflow: hidden;
`;

export default class DesktopDataPicker extends React.Component {
    componentDidMount() {
        this.props.setBackground('none');
    }

    render() {
        return (
            <StyledWrapper>
                <StyledPopupWrapper>
                    <DataPicker
                        {...this.props}
                        width={500}
                        height={byPlatform(500, 500, 406)}
                    />
                </StyledPopupWrapper>
            </StyledWrapper>
        );
    }
}
