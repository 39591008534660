import React from 'react';
import { PRODUCTS_BACKGROUNDS } from 'configs/products.ts';
import { ProductLogo } from 'features/Spilklub/Mobile/styled.tsx';
import { TopBar } from 'features/Spilklub/Mobile/styled';
import { PRODUCTS_LABELS } from 'configs/products';
import { DraftLabel, DraftWrapper, IconWrapper } from '../../BetSlip/styled';
import NotificationConductor from 'common/conductors/NotificationConductor';

const CenteredLogoTopBar = ({ product, showDraftInfo = false }) => {
    const productLogo = PRODUCTS_LABELS[product.id];

    const showDraftInfoBlock = () => {
        NotificationConductor.info(
            'Dette er ikke den endelig kupon , men blot et udkast. Den endelig kupon kan du færdiggøre senere'
        );
    };

    return (
        <TopBar height={'200px'} background={PRODUCTS_BACKGROUNDS[product.id]}>
            {showDraftInfo && (
                <DraftWrapper>
                    <DraftLabel>Udkast</DraftLabel>
                    <IconWrapper secondary onClick={showDraftInfoBlock}>
                        ?
                    </IconWrapper>
                </DraftWrapper>
            )}

            <ProductLogo
                src={productLogo}
                alt={product.name}
                style={{
                    alignSelf: `center`,
                    transform: `translate(10px, -20px)`,
                }}
            />
        </TopBar>
    );
};

export default CenteredLogoTopBar;
