import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment/moment';

import { ProductIds } from 'features/EventBoard/server/calendar';

import useCalendarDate from 'features/ResultsNew/hooks/useCalendarDate';
import useServerTimeQuery from 'features/EventBoard/hooks/useServerTimeQuery';

import { request } from 'utils/server';
import { PRODUCT_IDS } from 'configs/products';

const isFirstVersion = true; // temp solution for first version of Result page

interface QueryParameters {
    date: string;
}

export interface TracksResultsQueryData {
    data: Array<Object>;
}

export interface TrackProduct {
    trackId: number;
    poolType: keyof typeof PRODUCT_IDS;
}

export interface TrackResult {
    track: {
        disabledProducts: Array<string>;
        trackId: number;
        dhvTrackId: number;
        track: {
            code: string;
            domesticText: string;
            englishText: Object;
        };
        location: object;
        country: string;
    };
    mainProduct: string;
    firstRacePostTimeUTC: string;
    products: Array<TrackProduct>;
    frontPageProducts: ProductIds;
    sortedProducts: ProductIds;
}

const fetchTracksResultsQuery = async ({ date }: QueryParameters) => {
    const response = await request<Array<TracksResultsQueryData>, QueryParameters>(
        'Results',
        'POST',
        `fetchTracks`,
        { date }
    );
    if (!response.success) {
        throw new Error(response.errorMessage);
    }

    return response.data;
};

const useTracksResultsQuery = () => {
    const { date, isToday } = useCalendarDate();
    const { serverTime } = useServerTimeQuery();

    const { status, data, error, isSuccess, isLoading } = useQuery<
        unknown,
        Error,
        Array<TrackResult>
    >({
        queryKey: ['tracksResultsQuery', date],
        queryFn: () => fetchTracksResultsQuery({ date }),
        enabled: true,
        staleTime: 60 * 10 * 1000,
    });

    const segmentedTracks = useMemo(() => {
        if (serverTime && data && isToday && !isFirstVersion) {
            const upcoming: Array<TrackResult> = [];
            const started: Array<TrackResult> = [];

            data.forEach(track => {
                const isUpcoming = moment(serverTime)
                    .utc()
                    .isBefore(moment(track.firstRacePostTimeUTC).utc());

                if (isUpcoming) {
                    upcoming.push(track);
                } else {
                    started.push(track);
                }
            });

            return { upcoming, started };
        }
    }, [data, serverTime, isToday]);

    return {
        status,
        tracks: data,
        segmentedTracks,
        error,
        isSuccess,
        isLoading,
    };
};

export default useTracksResultsQuery;
