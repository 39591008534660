import User from 'common/DataObjects/User';

export default interface Pool {
    groupId: number;
    internalPool: InternalPool;
    externalPool: ExternalPool;
    payout: Payout;
    postTime: string;
    postTimeUtc: string;
}

export interface Payout {
    poolLeftoverAmount: number;
    poolSpentAmount: number;
    poolTotalWin: number;
    totalPoolAmount: number;
    userWin: number;
    userId: number;
}

export enum PoolStatus {
    CREATED = 'CREATED', // active 0
    OPEN = 'OPEN', // active 1
    PENDING = 'PENDING', // active 2
    WIN_SCAN = 'WIN_SCAN', // active 3
    PAYOUT = 'PAYOUT', // finished 4
    REFUND = 'REFUND', // finished 7
    CANCEL = 'CANCEL', // finished 8
}

const activePoolStatuses = [
    PoolStatus.CREATED,
    PoolStatus.OPEN,
    PoolStatus.PENDING,
    PoolStatus.WIN_SCAN,
];

const finishedPoolStatuses = [
    PoolStatus.PAYOUT,
    PoolStatus.REFUND,
    PoolStatus.CANCEL,
];

export interface InternalPool {
    id: number;
    externalId: number;
    poolSettings: PoolSettings;
    shares: Share[];
    externalCouponId: number;
    hash: string;
    created: Date;
    status: PoolStatus;
    totalAmount: number;
    winAmount: number;
    totalSpend: number;
    poolCommissionPercent: number;
    shareCommissionAmount: number;
}

export interface ExternalPool {
    id: number;
    raceDate: Date;
    trackId: number;
    trackName: string;
    productId: string;
    raceNumber: number;
}

export interface Share {
    sharingId: number;
    poolId: number;
    userId: number;
    numberOfShares: number;
    totalAmount: number;
    boughtBySubscription: boolean;
}

export interface PoolSettings {
    sharePrice: number;
    sharesLimit: number;
    deadlineTime: number;
}

export const getNumberOfBoughtShares = (pool: InternalPool): number => {
    return pool.shares.reduce(
        (totalShares, share: Share) => totalShares + share.numberOfShares,
        0
    );
};

export const getNumberOfAllowedShares = (pool: InternalPool): number => {
    return pool.poolSettings.sharesLimit - getNumberOfBoughtShares(pool);
};

// returns amount of shares (kronas)
export const totalSharesForUser = (
    pool: InternalPool,
    userId: number
): number => {
    return pool.shares
        .filter((share) => share.userId === userId)
        .reduce(
            (totalShares, share: Share) => totalShares + share.totalAmount,
            0
        );
};
// returns number of shares
export const sharesCountForUser = (
    pool: InternalPool,
    userId: number
): number => {
    return pool.shares
        .filter((share) => share.userId === userId)
        .reduce(
            (sharesNumber, share: Share) => sharesNumber + share.numberOfShares,
            0
        );
};

export const sharesCountForPool = (pool: InternalPool): number => {
    return pool.shares.reduce(
        (sharesNumber, share: Share) => sharesNumber + share.numberOfShares,
        0
    );
};

export const isPoolActive = (pool: Pool): boolean =>
    activePoolStatuses.includes(PoolStatus[pool.internalPool.status]);

export const isLimitReached = (pool: Pool): boolean =>
    pool.internalPool.totalAmount /
        pool.internalPool.poolSettings.sharePrice ===
    pool.internalPool.poolSettings.sharesLimit;

export const allSharesWereBought = (pool: Pool): boolean =>
    getNumberOfBoughtShares(pool.internalPool) >=
    pool.internalPool.poolSettings.sharesLimit;

/**
 * Calculates the total count of shares after
 * the {sharesToBuy} are bought.
 * @param pool
 * @param user
 * @param sharesToBuy
 */
export const recalculateTotalShares = (
    pool: Pool,
    user: User,
    sharesToBuy: number
): number => {
    if (
        totalSharesForUser(pool.internalPool, user.id) >= 0 &&
        sharesToBuy === 0
    ) {
        return totalSharesForUser(pool.internalPool, user.id);
    } else if (sharesToBuy > 0) {
        return (
            totalSharesForUser(pool.internalPool, user.id) +
            pool.internalPool.poolSettings.sharePrice * sharesToBuy
        );
    } else {
        return 0;
    }
};

export const createNullPool = (): Pool => ({
    groupId: 0,
    internalPool: {
        id: 0,
        externalId: 0,
        externalCouponId: 0,
        poolSettings: {
            sharePrice: 0,
            sharesLimit: 0,
            deadlineTime: 60,
        },
        shares: [],
        hash: '',
        created: new Date(),
        status: PoolStatus.OPEN,
        totalAmount: 0,
        winAmount: 0,
        totalSpend: 0,
        poolCommissionPercent: 0,
        shareCommissionAmount: 0,
    },
    externalPool: {
        id: 0,
        raceDate: new Date(),
        trackId: 0,
        trackName: '',
        productId: '',
        raceNumber: 0,
    },
    payout: createNullPayout(),
    postTime: '00:00:00',
    postTimeUtc: '00:00:00',
});

export const createNullPayout = () => ({
    poolLeftoverAmount: 0,
    poolSpentAmount: 0,
    poolTotalWin: 0,
    totalPoolAmount: 0,
    userWin: 0,
    userId: 0,
});
