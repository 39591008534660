import React from 'react';
import styled from 'styled-components';
import { byPlatform } from 'utils/platforms';
import CloseBtn from '../../../layouts/sections/Modals/CloseBtn';
import Status from '../../../scenes/Status';
import { getUrl } from 'utils/navigation';

const StyledWrapper = styled.div`
    background: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-flow: column;
    position: absolute;
    z-index: 1000;
`;

const StyledPopupWrapper = styled.div`
    height: 600px;
    width: 500px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.4);
    position: relative;
    z-index: 1001;
    margin: 0 auto;
    overflow: hidden;
`;

class StatusOfAction extends React.Component {
    componentDidMount() {
        this.props.enableCloseButton(this.renderCloseButton());
        if (this.props.setBackground) {
            this.props.setBackground(`none`);
        }
    }

    renderCloseButton = () => {
        return (
            <CloseBtn iclose alt="close" color={'black'} size="x05" onClick={this.onCloseHandler} />
        );
    };

    onCloseHandler = () => {
        if (this.props.modalData.redirectTo) {
            window.location.href = getUrl(this.props.modalData.redirectTo);
        } else {
            this.props.hideModal('STATUS_OF_ACTION');
            this.props.modalData &&
                this.props.modalData.history &&
                this.props.modalData.history.push('/');
        }
    };

    renderContent() {
        return (
            <Status
                success={this.props.modalData.success}
                title={this.props.modalData.title}
                text={this.props.modalData.text}
                onClose={this.onCloseHandler}
            />
        );
    }

    renderPopup() {
        return (
            <StyledWrapper>
                <StyledPopupWrapper>{this.renderContent()}</StyledPopupWrapper>
            </StyledWrapper>
        );
    }

    render() {
        return byPlatform(this.renderContent(), this.renderPopup());
    }
}

export default StatusOfAction;
