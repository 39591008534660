import React from 'react';
import {
    Wrapp,
    Container,
    MainIcon,
    MainText,
    LinkText,
} from 'features/MobileBet/styled';
import { useDispatch } from 'react-redux';
import { hideModal } from 'common/actions/uiActions';
import check from '../../images/icons/check.png';
import getTexts from 'utils/localization';

const t = getTexts();

const clickHandler = (dispatch) => {
    dispatch(hideModal('SAVED_BET_CONTAINER'));
    dispatch(hideModal('SUCCESS_BET_SAVING'));
};

const SuccessBetSaving = (props) => {
    const dispatch = useDispatch();

    return (
        <Wrapp
            onClick={() => {
                clickHandler(dispatch);
            }}
        >
            <Container>
                <MainIcon src={check} />
                <MainText>{t.Terminal.savedCoupons.mainText}</MainText>
                <LinkText>{t.Terminal.savedCoupons.info}</LinkText>
            </Container>
        </Wrapp>
    );
};

export default SuccessBetSaving;
