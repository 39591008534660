import React from 'react';
import styled, { keyframes } from 'styled-components';
import logo from 'images/dk/logo-white.png';
import { COLORS } from 'themes';

const BounceDelay = keyframes`
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`;
const SpinnerWrapper = styled.div`
    background: ${COLORS.primary};
    position: fixed;
    z-index: 999999999999;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
`;
const LoaderWrapper = styled.div`
    width: 150px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -75px;
    margin-top: -140px;
    color: #fff;
    text-align: center;
`;
const Spinner = styled.div`
    .spinner {
        margin: 50px auto 0;
        width: 70px;
        text-align: center;
    }

    & > div {
        width: 18px;
        height: 18px;
        background-color: #fff;
        border-radius: 100%;
        display: inline-block;
        -webkit-animation: ${BounceDelay} 1.4s infinite ease-in-out both;
        animation: ${BounceDelay} 1.4s infinite ease-in-out both;
    }

    div:first-child {
        -webkit-animation-delay: -0.32s;
        animation-delay: -0.32s;
    }

    div:nth-child(2) {
        -webkit-animation-delay: -0.16s;
        animation-delay: -0.16s;
    }
`;

const PromoLoader = () => {
    return (
        <SpinnerWrapper>
            <LoaderWrapper>
                <img src={logo} alt={'Roed25'} width="170px" />
                <Spinner>
                    <div />
                    <div />
                    <div />
                </Spinner>
            </LoaderWrapper>
        </SpinnerWrapper>
    );
};

export default PromoLoader;
