import React from 'react';
import { Horse } from 'features/TrackPage/model/Race';
import { ProductId } from 'features/EventBoard/server/calendar';
import { PRODUCT_IDS } from 'configs/products';

interface OddsProps {
    horse: Horse;
    productId: ProductId;
}

const DEFAULT_VALUE = '1.00';

const Odds = ({ horse, productId }: OddsProps) => {
    const minOdds = horse.vpPool.platsOdds.minOdds.odds.toFixed(2);

    const maxOdds = horse.vpPool.platsOdds.maxOdds.odds.toFixed(2);

    const pladsOdds = horse.vpPool.platsOdds
        ? minOdds !== maxOdds
            ? `${minOdds}-${maxOdds}`
            : maxOdds
        : DEFAULT_VALUE;

    const vinderOdds = horse.vpPool.vinnarOdds.odds.toFixed(2) ?? DEFAULT_VALUE;

    if (productId === PRODUCT_IDS.P) {
        return <>{pladsOdds}</>;
    }

    if (productId === PRODUCT_IDS.VP) {
        return (
            <>
                {vinderOdds}-{pladsOdds}
            </>
        );
    }

    return <>{vinderOdds}</>;
};

export default Odds;
