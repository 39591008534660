import React from 'react';
import {
    SendBarWrapper,
    SendButton,
    TextInput,
} from './components/ui-extended';
import PropTypes from 'prop-types';

class SendBar extends React.Component {
    constructor() {
        super();

        this.state = {
            text: '',
            isProcessActivated: false,
        };
    }

    onTextChanged = (event) => {
        this.setState({ text: event.target.value });
    };

    onSendClickHandler = () => {
        this.props.onSendHandler(this.state.text);
        this.setState({ isProcessActivated: true });
    };

    render() {
        return (
            <SendBarWrapper>
                <TextInput
                    placeholder={this.props.placeholder}
                    value={this.state.searchText}
                    onChange={this.onTextChanged}
                />{' '}
                <SendButton onClick={this.onSendClickHandler}>Send</SendButton>
            </SendBarWrapper>
        );
    }
}

SendBar.propTypes = {
    onSendHandler: PropTypes.func.isRequired,
};

export default SendBar;
