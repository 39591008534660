import styled from 'styled-components';
import { DGABar, priority } from 'configs/layout';
import { byPlatform } from 'utils/platforms';

const FixedFullScreen = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${priority.overlapBottomBar};
    overflow: hidden;
`;

const TopSection = styled(FixedFullScreen)`
    height: ${DGABar.mobile.height}px;
    z-index: ${priority.topBar};
    bottom: auto;
    top: ${byPlatform(0, DGABar.desktop.height)}px;
    & button {
        font-family: 'Roboto Condensed';
        font-weight: 900;
        font-size: 14px;
    }
`;

const BottomSection = styled(FixedFullScreen)`
    height: ${(props) => props.height || '50px'};
    z-index: ${priority.bottomBar};
    top: auto;
`;

export default FixedFullScreen;
export { FixedFullScreen, TopSection, BottomSection };
