import Race from './Race';

class Leg extends Race {
    trackId = null;

    constructor(attributes) {
        super();
        Object.assign(this, attributes);
    }
}

export default Leg;
