import React, { Fragment, PureComponent, memo } from 'react';
import { Router } from 'react-router-dom';
import styled, { StyleSheetManager, css } from 'styled-components';
import { connect } from 'react-redux';
import moment from 'moment';
import 'moment-timezone';
import 'moment/locale/da.js';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { FastDepositLimitForm } from '@it25syv/25syv-ui';

import { history, isOnPath, isPromoPage } from 'utils/navigation';
import GlobalStyles from 'themes/global-inject';
import { byPlatform, isMobile, isTerminal, isDesktop } from 'utils/platforms';
import Layout from '../layouts';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';

import StreamVideo from 'features/StreamVideo/StreamVideoContainer';
import { Helmet } from 'react-helmet';
import Button from 'ui/controls/Button';

import { BACKGROUNDS } from 'themes/index';
import { COLORS } from 'ui/topbar';
import userConductor from 'common/conductors/UserConductor';
import notification from 'common/conductors/NotificationConductor';
import { LIMIT_TYPE_DAILY } from 'features/UserArea/DepositLimits/DepositLimitDTO';
import { sprintf } from 'sprintf-js';
import { thousandsDots } from 'utils/formatters';
import Text from 'ui/Text';
import getTexts from 'utils/localization';
import productSelector from 'common/selectors/productSelector';
import { setComponentsOptions, showModal } from 'common/actions/uiActions';
import Promo from 'layouts/Promo';
import { trackUserData } from 'utils/tracking';
import { isLiveStreamAllowed } from 'common/selectors/auth';
import { showStream, hideStream } from 'features/StreamVideo/actions';
import AmountForm from 'features/UserArea/Deposit/AmountForm';
import { shouldForwardProp } from '../utils/styled-components';

import '../include-fonts.css';
import { isLocalhost } from '../utils/env';

window.onload = () => {
    moment.locale('da');
    //moment.tz.setDefault('Denmark/Copenhagen');
};

const t = getTexts();

const queryClient = new QueryClient();

const TransparentBackground = styled.div`
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    z-index: 104;
    height: ${byPlatform('calc(100vh - 100px)', '100vh')};
    width: 100%;
`;

const Popup = styled.div`
    background: #fff;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    top: ${byPlatform('100px', '10vh')};
    height: ${byPlatform('100%', '80%')};
    width: ${byPlatform('100%', '40%')};
    padding: 15px;
    box-sizing: border-box;
    ${byPlatform(
        `
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
    `,
        `border-radius: 20px;`
    )};
`;

const PopupScroll = styled.div`
    overflow-y: auto;
    overflow-x: hidden;
    height: 95%;
    padding: 25px 15px 15px 15px;
`;

const FastDepositLimitMaxAmountBtn = styled(Button).attrs({ accent: true })`
    box-sizing: border-box;
    height: 40px;
    font-weight: bold;
    font-size: 16px;
`;

const FastDepositLimitConfirmBtn = styled(Button)`
    box-sizing: border-box;
    height: 40px;
    font-weight: bold;
    font-size: 16px;
`;

const fastDepositLimitInputCSS = css`
    border: 1px solid ${BACKGROUNDS.grey};
    border-radius: 3px;
`;

const fastDepositLimitInputLabelCSS = css`
    color: ${COLORS.lightGrey};
    text-transform: lowercase;
`;

const fastDepositLimitTexts = {
    title: 'Daglig indbetalingsgrænse',
    description:
        'Du bedes indtaste en daglig indbetalingsgrænse for dit spil. Dette er et krav fra Spillemyndigheden, og det er ikke muligt at spille hos os, uden du har sat en indbetalingsgrænse. Bemærk, at din indbetalingsgrænse er gældende på både Bet25 Casino, Bet25 Heste, Bet25 Sport og Casino Go.',
    inputLabel: 'Indtast grænse',
    maxAmountLabel: 'Eller',
    maxAmountBtn: 'Vælg max grænse',
    confirmBtnLabel: '(50.000 kr.)',
    confirmBtn: 'Gem',
    termsCondsLink: 'ansvarligt spil',
    termsCondsTitle: 'Ansvarligt spil',
    termsCondsText: 'Terms & Conditions',
    readMore: 'Læs mere om ',
    successTitle: `Din indbetalingsgrænse er gemt - %s kr. dagligt.`,
    successMessage:
        'Du kan altid ændre grænsen under dine kontoindstillinger i øverste højre hjørne.',
};

class App extends PureComponent {
    state = {
        fastDepositLimitShown: false,
        limit: {},
        limitReady: false,
    };

    shouldComponentUpdate(nextProps, nextState) {
        return (
            (!this.state.fastDepositLimitShown && nextState.fastDepositLimitShown) ||
            (this.state.fastDepositLimitShown && !nextState.fastDepositLimitShown) ||
            (nextProps.user && !this.props.user) ||
            (this.props.user && nextProps.user && this.props.user.id !== nextProps.user.id) ||
            this.props.isVProduct !== nextProps.isVProduct ||
            this.props.liveStreamPageMounted !== nextProps.liveStreamPageMounted ||
            this.props.liveStreamAllowed !== nextProps.liveStreamAllowed
        );
    }
    componentDidMount() {
        trackUserData();
        // this.props.showModal('TEMP_USER', 4);
        // this.props.setComponentsOptions({
        //     showLiveStreamTooltip: true,
        // });
        if (!isTerminal) {
            this.checkDepositLimit();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!prevProps.user && this.props.user && !isTerminal) {
            this.checkDepositLimit();
        }

        if (
            this.props.isTempUser &&
            prevState.fastDepositLimitShown &&
            !this.state.fastDepositLimitShown &&
            !isTerminal
        ) {
            this.props.showModal('TEMP_USER', 4);
        }

        if (prevProps.liveStreamAllowed && !this.props.liveStreamAllowed) {
            this.props.hideStream();
        }

        if (
            !prevProps.liveStreamAllowed &&
            this.props.liveStreamAllowed &&
            isDesktop &&
            !isLocalhost()
        ) {
            this.props.showStream();
        }
    }

    checkDepositLimit = () => {
        this.props.user &&
            !this.state.fastDepositLimitShown &&
            userConductor.getDepositLimits().then((limit) => {
                this.setState({ limit, limitReady: true }, () => {
                    if (
                        limit.hasOwnProperty('dailyAmount') &&
                        limit.dailyAmount === 0 &&
                        limit.hasOwnProperty('weeklyAmount') &&
                        limit.weeklyAmount === 0 &&
                        limit.hasOwnProperty('monthlyAmount') &&
                        limit.monthlyAmount === 0 &&
                        limit.hasOwnProperty('dailyAmountChange') &&
                        limit.dailyAmountChange === 0 &&
                        limit.hasOwnProperty('weeklyAmountChange') &&
                        limit.weeklyAmountChange === 0 &&
                        limit.hasOwnProperty('monthlyAmountChange') &&
                        limit.monthlyAmountChange === 0
                    ) {
                        this.setState({ fastDepositLimitShown: true }, () => {
                            window.document.body.style.position = 'fixed';
                        });
                    }
                });
            });
    };

    setDepositLimit = (value) => {
        const limit = this.state.limit.changeAmount(value, LIMIT_TYPE_DAILY);

        this.setState({ limit }, () => {
            userConductor.setDepositLimits(this.state.limit).then(() => {
                this.setState({ fastDepositLimitShown: false }, () => {
                    window.document.body.style.position = 'static';

                    notification.success(
                        <Fragment>
                            <Text bold white>
                                {sprintf(fastDepositLimitTexts.successTitle, thousandsDots(value))}
                            </Text>
                            <Text white>{fastDepositLimitTexts.successMessage}</Text>
                        </Fragment>
                    );

                    if (isMobile) {
                        this.props.setComponentsOptions({
                            showLiveStreamTooltip: true,
                        });
                    }
                });
            });
        });
    };

    render() {
        const { liveStreamAllowed } = this.props;

        if (isPromoPage()) {
            return (
                <>
                    <Promo />
                    <GlobalStyles />
                </>
            );
        }

        if (isOnPath('deposit-modal')) {
            return (
                <>
                    <AmountForm />
                    <GlobalStyles />
                </>
            );
        }

        return (
            <div className="full-height">
                <QueryClientProvider client={queryClient}>
                    <StyleSheetManager enableVendorPrefixes shouldForwardProp={shouldForwardProp}>
                        <Router history={history}>
                            <Layout isVProduct={this.props.isVProduct} />
                        </Router>

                        {this.state.fastDepositLimitShown && (
                            <TransparentBackground>
                                <Popup>
                                    <PopupScroll>
                                        <FastDepositLimitForm
                                            inputCSS={fastDepositLimitInputCSS}
                                            inputLabelCSS={fastDepositLimitInputLabelCSS}
                                            MaxAmountButton={FastDepositLimitMaxAmountBtn}
                                            ConfirmationButton={FastDepositLimitConfirmBtn}
                                            texts={fastDepositLimitTexts}
                                            onConfirm={this.setDepositLimit}
                                        />
                                    </PopupScroll>
                                </Popup>
                            </TransparentBackground>
                        )}

                        <Helmet>
                            <meta name="title" content={t.meta.title} />
                            <meta name="description" content={t.meta.description} />
                        </Helmet>

                        {liveStreamAllowed &&
                            byPlatform(
                                <StreamVideo
                                    transform="scale(.9)"
                                    height="278px"
                                    width="365px"
                                    floatingMode
                                />,
                                <StreamVideo
                                    floatingMode
                                    liveStreamPageMounted={this.props.liveStreamPageMounted}
                                    key={liveStreamAllowed}
                                />,
                                <span />
                            )}
                        <NotificationContainer />

                        <GlobalStyles />
                        {/*<CacheBuster />*/}
                    </StyleSheetManager>
                </QueryClientProvider>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    isVProduct: productSelector(state).isStrictVProduct(),
    isTempUser: state.auth.isTemp,
    liveStreamPageMounted: state.ui.components.liveStreamPageMounted,
    liveStreamAllowed: isLiveStreamAllowed(state),
});

const mapDispatchToProps = (dispatch) => ({
    showModal: (id, priority, data) => dispatch(showModal(id, priority, data)),
    setComponentsOptions: (options) => {
        dispatch(setComponentsOptions(options));
    },
    showStream: () => {
        dispatch(showStream());
    },
    hideStream: () => {
        dispatch(hideStream());
    },
});

export default memo(connect(mapStateToProps, mapDispatchToProps)(App));
