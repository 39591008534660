import { useQuery } from '@tanstack/react-query';
import { request } from 'utils/server';

interface ResponseData {
    time: string;
}

const fetchServerTime = async () => {
    const response = await request<ResponseData>('InfoService', 'GET', '/getTime');
    //@ts-ignore. @TODO: Figure out how to overload request function without Response<D>, with just Promise<D> instead
    return response;
};

const useServerTimeQuery = ({ enabled = true } = { enabled: true }) => {
    const { status, data, error, isSuccess, isLoading } = useQuery<unknown, Error, ResponseData>({
        queryKey: ['serverTime'],
        queryFn: () => fetchServerTime(),
        refetchInterval: 60 * 1000,
        refetchOnWindowFocus: false,
        refetchOnReconnect: true,
        enabled,
    });

    return { status, serverTime: data?.time, error, isSuccess, isLoading };
};

export default useServerTimeQuery;
