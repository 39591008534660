export default class ActiveBonus {
    /**
     * @type {number}
     */
    totalBonus = 0;
    /**
     * @type {number}
     */
    rollover = 0;
    /**
     * @type {number}
     */
    initialRollover = 0;
    /**
     * @type {number}
     */
    expirationDate = 0;
    /**
     * @type {number}
     */
    gameId = 0;

    /**
   * Raw object shape:
   *{
        "amount": 100.0,
        "bonusType": 'W',
        "expire": 1676551974000,
        "rollOver": 10.0,
        "initRollOver": 10.0,
        "transType": null,
        "gameId": null,
    }
   */
    static unserialize(data) {
        const bonus = new ActiveBonus();
        bonus.totalBonus = data.amount;
        bonus.rollover = data.rollOver;
        bonus.initialRollover = data.initRollOver;
        bonus.expirationDate = data.expire;
        bonus.gameId = data.gameId;
        return bonus;
    }
}
