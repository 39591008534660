import React, { useEffect, useState } from 'react';

import { byPlatform } from 'utils/platforms';
import Countdown from 'ui/Countdown';

import {
    RaceDay,
    getRaceCount,
    isRaceOngoing,
    isRaceOutdated,
} from 'features/EventBoard/server/calendar';

import { RacesDetailsText, RaceInfoWrapper } from './styled';

interface RaceInfoSectionProps {
    raceDay: RaceDay;
    isRacesOver?: boolean;
    canceled?: boolean;
    serverTime?: string;
}

interface CurrentRaceProps {
    raceNr: number;
    postTime: string;
    postTimeUTC: string;
}

enum RACE_STATE {
    CANCELED = 'CANCELED',
    FINISHED = 'FINISHED',
    DEFAULT = 'DEFAULT',
}

const RaceCountWithTime = ({
    raceDay,
    isRacesOver = false,
    canceled = false,
    serverTime,
}: RaceInfoSectionProps) => {
    const raceCount = getRaceCount(raceDay);

    const [raceState, setRaceState] = useState(RACE_STATE.DEFAULT);

    useEffect(() => {
        if (isRacesOver && !canceled) {
            setRaceState(RACE_STATE.FINISHED);
        } else if (canceled) {
            setRaceState(RACE_STATE.CANCELED);
        } else {
            setRaceState(RACE_STATE.DEFAULT);
        }
    }, [isRacesOver, canceled]);

    const selectCurrentRace = (raceDay: RaceDay): CurrentRaceProps => {
        const currentIndex = raceDay.racesInfo.findIndex(
            race => !isRaceOutdated(race, raceDay.raceDayDate) && !race.hasResult && !race.canceled
        );

        const raceInfo = {
            currentRace: raceDay.racesInfo[currentIndex],
            nextRace: currentIndex >= 0 ? raceDay.racesInfo[currentIndex + 1] : undefined,
        };

        const selectedRace =
            raceInfo?.nextRace &&
            isRaceOngoing(`${raceDay.raceDayDate} ${raceInfo.nextRace.postTimeUTC}`)
                ? raceInfo.nextRace
                : raceInfo.currentRace;

        // check if next race is start and if there is still no result for previous race
        if (!selectedRace) {
            setRaceState(RACE_STATE.FINISHED);
        }

        return {
            raceNr: selectedRace?.raceNr || 1,
            postTime: `${raceDay?.raceDayDate} ${selectedRace?.postTime}` || '',
            postTimeUTC: `${raceDay?.raceDayDate} ${selectedRace?.postTimeUTC}` || '',
        };
    };

    const selectDetailsContent = (state: RACE_STATE) => {
        switch (state) {
            case RACE_STATE.CANCELED:
                return 'Løbet er aflyst';
            case RACE_STATE.FINISHED:
                return 'Løbet er afsluttet';
            case RACE_STATE.DEFAULT:
            default:
                const { raceNr, postTimeUTC, postTime } = selectCurrentRace(raceDay);
                return (
                    <>
                        <span>{`Løb ${raceNr}`}</span> {` af ${raceCount} - `}
                        <Countdown
                            time={postTime}
                            timeUTC={postTimeUTC}
                            serverTime={serverTime}
                            withDay
                        />
                    </>
                );
        }
    };

    return (
        <RaceInfoWrapper>
            <RacesDetailsText size={byPlatform('x1', 'x2')} align={byPlatform('x1', 'x2')}>
                {selectDetailsContent(raceState)}
            </RacesDetailsText>
        </RaceInfoWrapper>
    );
};

export default RaceCountWithTime;
