import React from 'react';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface PayoutConfirmationVisibilityStore {
    doNotShowAgain: boolean;
    setPayoutConfirmationPopupVisibility(tipsAppearance: boolean): void;
}

const usePayoutConfirmationPopupVisibility = create<PayoutConfirmationVisibilityStore>()(
    persist(
        set => ({
            doNotShowAgain: false,
            setPayoutConfirmationPopupVisibility: doNotShowAgain => set({ doNotShowAgain }),
        }),
        { name: 'ui.payoutConfirmationPopupVisibility' }
    )
);

// useful for class components since it's not possible to use hooks with them
export const withPayoutConfirmationPopupVisibilityStore = (BaseComponent: any) => (props: any) => {
    const store = usePayoutConfirmationPopupVisibility();
    return <BaseComponent {...props} doNotShowPayoutConfirmationScreen={store.doNotShowAgain} />;
};

export default usePayoutConfirmationPopupVisibility;
