import clearDay from 'images/weather/clear-day.svg';
import partlyCloudyDay from 'images/weather/cloudy-1-day.svg';
import overcast from 'images/weather/cloudy-3-day.svg';
import cloudyDay from 'images/weather/cloudy.svg';
import mist from 'images/weather/fog-day.svg';
import patchyRain from 'images/weather/rainy-1-day.svg';
import cloudyLightRain from 'images/weather/rainy-1.svg';
import heavyRain from 'images/weather/rainy-3-day.svg';
import cloudyHeavyRain from 'images/weather/rainy-3.svg';
import patchySnow from 'images/weather/rain-and-snow-mix.svg';
import patchySleet from 'images/weather/rain-and-sleet-mix.svg';
import lightSnow from 'images/weather/snowy-1-day.svg';
import blowingSnow from 'images/weather/snowy-1.svg';
import heavySnow from 'images/weather/snowy-3-day.svg';
import blizzard from 'images/weather/snowy-3.svg';
import fog from 'images/weather/fog.svg';
import thunderyOutbreaks from 'images/weather/isolated-thunderstorms-day.svg';
import thunderstorm from 'images/weather/thunderstorms.svg';

export const WEATHER_ICONS: { [index: number]: any } = {
    395: heavySnow,
    392: thunderyOutbreaks,
    389: thunderstorm,
    386: thunderyOutbreaks,
    377: patchySleet,
    374: patchySnow,
    371: heavySnow,
    368: lightSnow,
    365: patchySnow,
    362: patchySnow,
    359: cloudyHeavyRain,
    356: heavyRain,
    353: patchyRain,
    350: patchySleet,
    338: blizzard,
    335: heavySnow,
    332: blizzard,
    329: blizzard,
    326: lightSnow,
    323: lightSnow,
    320: blowingSnow,
    317: patchySleet,
    314: patchySleet,
    311: patchySleet,
    308: cloudyHeavyRain,
    305: heavyRain,
    302: cloudyHeavyRain,
    299: heavyRain,
    296: cloudyLightRain,
    293: cloudyLightRain,
    284: patchySleet,
    281: patchySleet,
    266: cloudyLightRain,
    263: patchyRain,
    260: fog,
    248: fog,
    230: blizzard,
    227: blowingSnow,
    200: thunderyOutbreaks,
    185: patchySleet,
    182: patchySleet,
    179: patchySnow,
    176: patchyRain,
    143: mist,
    122: overcast,
    119: cloudyDay,
    116: partlyCloudyDay,
    113: clearDay,
};

// weather condition codes https://www.worldweatheronline.com/weather-api/api/docs/weather-icons.aspx
