import React from 'react';
import useTipsFromAPI from './useTipsFromAPI';
import {
    TableHeader,
    TableRow,
    TableContent,
    SpilTipsWrapper,
    RankSection,
    RankLabel,
    RankList,
    RankCategory,
    RankValues,
    Label,
    RankListWrapper,
} from './styled';
import Text from 'ui/Text';

const SwedenSpilTips = ({ date, raceNumber, trackId, product, productName, isVProduct }) => {
    const { tips, ranks } = useTipsFromAPI({
        date,
        raceNumber,
        trackId,
        ...(isVProduct && { product }),
    });

    return (
        <>
            {tips.length ? (
                <SpilTipsWrapper>
                    <TableHeader>
                        <Text bold white size="16px" padding={'0'}>
                            Spiltips {productName}
                        </Text>
                    </TableHeader>
                    <TableContent isOpen={true}>
                        {tips.map((tip, index) => (
                            <TableRow key={index} dangerouslySetInnerHTML={{ __html: tip }} />
                        ))}
                        {ranks ? (
                            <RankSection>
                                <RankLabel>Rank:</RankLabel>
                                <RankListWrapper>
                                    <RankList>
                                        {Object.keys(ranks).map(key => (
                                            <div key={key}>
                                                <RankCategory>{key.toUpperCase()}: </RankCategory>
                                                <RankValues>{ranks[key].join(', ')}</RankValues>
                                            </div>
                                        ))}
                                    </RankList>
                                    <Label>Kilde: TR media</Label>
                                </RankListWrapper>
                            </RankSection>
                        ) : null}
                    </TableContent>
                </SpilTipsWrapper>
            ) : null}
        </>
    );
};

export default SwedenSpilTips;
