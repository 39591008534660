import React from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import androidImage from './android.png';
import iosImage from './ios.png';
import FlexWrapper from 'ui/FlexWrapper';
import CloseBtn from '../../../layouts/sections/Modals/CloseBtn';

class OSShortcutInfo extends React.Component {
    componentDidMount() {
        this.props.enableCloseButton(<CloseBtn iclose alt="close" size="x05" />);
    }

    render() {
        return isIOS ? (
            <FlexWrapper margin="0px" padding="0px">
                <img src={iosImage} width="100%" alt="IOS help" />
            </FlexWrapper>
        ) : isAndroid ? (
            <FlexWrapper margin="0px" padding="0px">
                <img src={androidImage} width="100%" alt="IOS help" />
            </FlexWrapper>
        ) : null;
    }
}

export default OSShortcutInfo;
