import React from 'react';
import styled from 'styled-components';
import FlexWrapper from 'ui/FlexWrapper';
import { NavLink } from 'react-router-dom';
import { isHomePage } from 'utils/navigation';

const MenuItemsConfig = [
    {
        title: 'Hjem',
        path: '/hjem',
    },
    {
        title: 'Spil Lynspil',
        path: '/derby-lyn',
    },
    {
        title: 'Se resultater',
        path: '/resultat',
    },
    {
        title: 'Sådan spiller du',
        path: '/spilguide',
    },
];

const StyledItem = styled.div`
    font-size: 16px;
    font-weight: 900;
    padding: 0px 10px;
    & a {
        color: #8bf7c3;
        text-decoration: none;
        position: relative;
    }
    & a.active {
        color: #fff;
    }
    & a.active::after {
        content: ' ';
        position: absolute;
        height: 3px;
        left: 0;
        bottom: -15px;
        width: 100%;
        background: #fed300;
    }
`;
//${COLORS.white}
const MenuWrapper = styled(FlexWrapper)`
    flex-basis: 700px;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    margin-left: 20px;
`;

class NavigationMenu extends React.Component {
    render() {
        return (
            <MenuWrapper direction="row">
                {MenuItemsConfig.map((item, index) => (
                    <StyledItem key={index}>
                        <NavLink
                            to={item.path}
                            isActive={(match, location) => {
                                if (item.path !== '/hjem') {
                                    return item.path === location.pathname;
                                } else {
                                    return (
                                        location.pathname === '/' ||
                                        item.path === location.pathname ||
                                        isHomePage()
                                    );
                                }
                            }}
                            activeClassName="active"
                            onClick={() => {
                                this.props.onClickHandler(item.path);
                            }}
                        >
                            {item.title}
                        </NavLink>
                    </StyledItem>
                ))}
            </MenuWrapper>
        );
    }
}

export default NavigationMenu;
