export const thousandsDots = (value) => {
    if (value === undefined || value === null) {
        return value;
    }
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

export const numberWithCommasAndDots = (x) => {
    if (typeof x !== 'number') {
        return x;
    }
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    if (parts[1]) {
        parts[1] = (Math.floor(`1.${parts[1]}` * 100) / 100)
            .toString()
            .split('.')[1];
    }
    return parts.join(',');
};

export const formatMoney = (money) =>
    new Intl.NumberFormat('da-DK', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(money || 0);

export const stripTrailinSymbol = (str, symbol) => {
    if (str.substr(-1) === symbol) {
        return str.substr(0, str.length - 1);
    }
    return str;
};

export const trimBoth = (str, char = ' ') => {
    if (str[0] === char) {
        str = str.slice(1);
    }
    if (str[str.length - 1] === char) {
        str = str.slice(0, str.length - 1);
    }
    return str;
};
export const saveJSONparsing = (jsonStr, defaultValue) => {
    let parsedJson = defaultValue || [];
    if (jsonStr !== 'null' && typeof jsonStr === 'string') {
        try {
            parsedJson = JSON.parse(jsonStr);
        } catch (e) {
            parsedJson = defaultValue || [];
        }
    }

    return parsedJson;
};

export const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatDenmarkCurrency = (value = 0, fractionDigits = 2) =>
    new Intl.NumberFormat('da-DK', {
        minimumFractionDigits: fractionDigits,
        maximumFractionDigits: fractionDigits,
    }).format(value || 0);
