import { priority } from 'configs/layout';

const MODALS = {
    BET_BUDDY_ENTRY: 'BET_BUDDY_ENTRY',
};

const SCREENS = {
    BET_SLIP: 'BET_SLIP',
    CREATE_GROUP: 'CREATE_GROUP',
    AVATARS: 'AVATARS',
    SHARE_LINK: 'SHARE_LINK',
    GROUPS: 'GROUPS',
    GROUP_INFO: 'GROUP_INFO',
    SUBSCRIBE: 'SUBSCRIBE',
    SUBMIT_SUBSCRIBE: 'SUBMIT_SUBSCRIBE',
    POOL_DASHBOARD: 'POOL_DASHBOARD',
    FINAL_SELECTIONS: 'FINAL_SELECTIONS',
    EDIT_SUBSCRIPTIONS: 'EDIT_SUBSCRIPTIONS',
    LEAVE_GROUP: 'LEAVE_GROUP',
    PRODUCTS_SHORTCUTS: 'PRODUCT_SHORTCUTS',
    COUPON_PREVIEW: 'COUPON_PREVIEW',
};

const PRIORITY = {
    SCREEN_PRIORITY_BASE: priority.modalWrapper,
    CLOSE_BUTTON_BASE: priority.modalWrapper + 1,
};

const TEST_USER_ID = 81;

const SHARE_COST_MIN = 50;

const SHARE_NUMBER_MAX = 1000;

const SCREEN_MOVING_DURATION = 200;

const WHITE_LIST_TEST_USER_IDS = [162, 168, 125, 99, 81, 52];

const INTERCOM_LINK =
    'https://intercom.help/25syv/da/articles/4031323-spilklub25-gevinst-udregning';

const AVATARS_FOLDER = `/img/group_avatars`;

export {
    MODALS,
    SCREENS,
    PRIORITY,
    TEST_USER_ID,
    SCREEN_MOVING_DURATION,
    WHITE_LIST_TEST_USER_IDS,
    SHARE_COST_MIN,
    SHARE_NUMBER_MAX,
    INTERCOM_LINK,
    AVATARS_FOLDER,
};
