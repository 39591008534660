import { Component } from 'react';
import moment from 'moment';
import { calculateTimeLeft } from 'utils/time';
import getTexts from 'utils/localization';
import { capitalizeFirstLetter } from 'utils/formatters';
const t = getTexts();

export default class Countdown extends Component {
    hour = 60 * 60 * 1000;

    week = 60 * 60 * 24 * 7 * 1000;

    state = {
        timeLeft: calculateTimeLeft(this.props.time, this.props.serverTime),
        timeLeftUTC: calculateTimeLeft(this.props.timeUTC, moment.utc().format()),
    };
    initializeTimer = () => {
        if (this.props.limit) this.hour = this.props.limit * 1000;

        const timeLeftUTC = calculateTimeLeft(this.props.timeUTC, this.getMixedTimeUTC());

        if (timeLeftUTC <= 0) {
            // no need to initialize timer if it's initially no time left
            return;
        }

        this.setState({
            timeLeft: calculateTimeLeft(this.props.time, this.getMixedTime()),
            timeLeftUTC: calculateTimeLeft(this.props.timeUTC, this.getMixedTimeUTC()),
        });

        this.interval = window.setInterval(() => {
            if (this.state.timeLeftUTC < 1000) {
                clearInterval(this.interval);

                this.props.handleTimeIsUpEvent && this.props.handleTimeIsUpEvent();
            }

            const diff = calculateTimeLeft(this.props.timeUTC, this.getMixedTimeUTC());

            this.setState({
                timeLeftUTC: this.state.timeLeftUTC - 1000 > 0 ? diff : 0,
            });
        }, 1000);
    };

    componentDidMount() {
        this.initializeTimer();
    }

    componentDidUpdate(prevProps) {
        prevProps.timeUTC !== this.props.timeUTC && this.initializeTimer();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    isTimeUp = () => {
        const { alwaysDisplayStartTime } = this.props;
        const { timeLeft, timeLeftUTC } = this.state;
        const left = alwaysDisplayStartTime ? timeLeft : timeLeftUTC;

        return left <= 0;
    };

    getMixedTime = () => {
        const mServerTime = moment(this.props.serverTime || new Date());
        const mLocalTime = moment();
        return moment(
            `${mServerTime.format('YYYY-MM-DD HH:mm:')}${mLocalTime.format('ss')}`
        ).format();
    };

    getMixedTimeUTC = () => {
        const mServerTimeUTC = moment.utc();
        const mLocalTime = moment();
        return moment(
            `${mServerTimeUTC.format('YYYY-MM-DD HH:mm:')}${mLocalTime.format('ss')}`
        ).format();
    };

    renderTime = () => {
        const { hour, getTimeLeft, getTimeOfEvent } = this;
        const { alwaysDisplayStartTime } = this.props;
        if (alwaysDisplayStartTime) {
            return getTimeOfEvent();
        }
        const { timeLeftUTC } = this.state;
        return timeLeftUTC <= hour ? getTimeLeft() : getTimeOfEvent();
    };

    getTimeLeft = () => {
        const { timeLeftUTC } = this.state;
        return moment.utc(timeLeftUTC).format('HH:mm:ss');
    };

    getTimeOfEvent = () => {
        const { time } = this.props;
        const formattedTime = moment.utc(time).format('HH:mm');

        return this.formatDateEntities(time) + ' ' + formattedTime;
    };
    /**
     *
     * @param  {number} timestamp
     * @return {string}
     */
    formatDateEntities = timestamp => {
        const { timeLeft } = this.state;
        const { withDay, daysToUpperCase } = this.props;

        if (!withDay) {
            return '';
        }

        if (moment(timestamp).isSame(new Date(), 'day')) {
            return this.getToday();
        }

        const dateEntities =
            timeLeft >= this.week
                ? moment.utc(timestamp).format('DD.MM.YYYY')
                : moment.utc(timestamp).format('dddd');
        return daysToUpperCase ? dateEntities.toUpperCase() : capitalizeFirstLetter(dateEntities);
    };

    getToday = () =>
        this.props.daysToUpperCase
            ? t.RacingCard.today.toUpperCase()
            : capitalizeFirstLetter(t.RacingCard.today);

    render() {
        return this.isTimeUp()
            ? this.props.timeIsUpString || t.RacingCard.ongoing
            : this.renderTime();
    }
}
