import styled from 'styled-components';
import { BACKGROUNDS, COLORS } from 'themes';
import { getBackgroundByProps } from 'utils/theme-helpers';

const getBackground = props => {
    if (props.accent) {
        return BACKGROUNDS.accentAlt;
    } else if (props.red) {
        return BACKGROUNDS.red;
    }

    return getBackgroundByProps(props);
};

const getAlertColors = props => {
    let btnClr = COLORS.white;

    if (props.accent) {
        btnClr = COLORS.black;
    } else if (props.grey) {
        btnClr = COLORS.grey;
    } else if (props.lightGrey) {
        btnClr = COLORS.lightGrey;
    } else if (props.white) {
        btnClr = COLORS.black;
    } else if (props.link) {
        btnClr = COLORS.white;
    }

    return btnClr;
};

const Mark = styled.mark`
    display: block;
    background: ${getBackground};
    color: ${getAlertColors};
    border-radius: 9px;
    box-shadow: 1px 1px 9px #c1c1c1;
    padding: ${props => props.padding ?? '10px 30px'};
    font-size: 13px;
    margin: 10px auto;
`;

export default Mark;
