import React from 'react';
import styled from 'styled-components';

const DotsWrapper = styled.section`
    width: auto;
    height: auto;
    display: flex;
    flex-flow: row;
    justify-content: center;
`;

const Dot = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 8px;
    background: ${(props) =>
        props.active
            ? props.colorActive || '#404757'
            : props.colorInactive || '#ECEFF5'};
    margin: 5px;
`;

const getDots = (dotsNumber, active, colorActive, colorInactive) => {
    let dots = [];

    for (let i = 0; i < dotsNumber; i++) {
        if (active !== i) {
            dots.push(
                <Dot
                    key={i}
                    colorActive={colorActive}
                    colorInactive={colorInactive}
                />
            );
        } else {
            dots.push(
                <Dot
                    active
                    key={i}
                    colorActive={colorActive}
                    colorInactive={colorInactive}
                />
            );
        }
    }

    return dots;
};

export default (props) => (
    <DotsWrapper>
        {getDots(
            props.dotsNumber,
            props.activeDot,
            props.colorActive,
            props.colorInactive
        )}
    </DotsWrapper>
);
