import React from 'react';
import styled, { css } from 'styled-components';
import { isMobile } from 'react-device-detect';

import Text from 'ui/Text';

import { ProductId, ProductIds } from 'features/EventBoard/server/calendar';
import { COLORS } from 'features/EventBoard/theme';
import { sortProducts } from './Shortcuts/helpers';
import JackpotLabel from './JackpotLabel';

import { PRODUCT_COLORS, PRODUCT_HOVER_COLORS } from 'configs/products';

interface ProductsListProps {
    handleDisable?: (productId: ProductId) => boolean;
    onProductClick: (productId: ProductId) => void;
    products: ProductIds;
    productsWithJackpot?: ProductIds;
}

export const ProductsContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
`;

interface ProductButtonProps {
    background?: string;
    disabled?: boolean;
    hoverBackground?: string;
}

const disabledButtonCSS = css<ProductButtonProps>`
    cursor: pointer;

    &:hover {
        //box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.2);
        background: ${({ hoverBackground, background }) => hoverBackground || background};
        transition: background 300ms ease;
    }
`;

export const ProductButton = styled.div<ProductButtonProps>`
    position: relative;
    border-radius: 5px;
    background-color: ${({ background }) => background || '#2CB16F'};
    padding: ${isMobile ? '2px 15px' : '5px 25px'};
    transition: box-shadow 0.3s ease-in-out;

    ${({ disabled }) => !disabled && disabledButtonCSS}}
`;

export const getProductColor = (product: string) => PRODUCT_COLORS[product];
const getProductHoverColor = (product: string) => PRODUCT_HOVER_COLORS[product];

const TrackProducts = ({
    handleDisable,
    onProductClick,
    products,
    productsWithJackpot,
}: ProductsListProps) => {
    const supportedProducts = sortProducts(products);

    return (
        <ProductsContainer>
            {supportedProducts.map((productId, index) => {
                const disabled = handleDisable ? handleDisable(productId) : false;
                const background = disabled ? COLORS.resultedProductBg : getProductColor(productId);
                const hoverBackground = disabled
                    ? COLORS.resultedProductBg
                    : getProductHoverColor(productId);

                const color = disabled ? COLORS.resultedProductText : 'white';

                return (
                    <ProductButton
                        key={index}
                        background={background}
                        hoverBackground={hoverBackground}
                        onClick={(e: { stopPropagation: () => void }) => {
                            e.stopPropagation();
                            onProductClick(productId);
                        }}
                        disabled={disabled}
                    >
                        <Text color={color} bold align={'center'}>
                            {productId}
                        </Text>
                        {!isMobile && productsWithJackpot?.includes(productId) ? (
                            <JackpotLabel />
                        ) : null}
                    </ProductButton>
                );
            })}
        </ProductsContainer>
    );
};

export default TrackProducts;
