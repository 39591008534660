import React, { useEffect, useState } from 'react';
import Wrapper from 'ui/Wrapper';
import Text from 'ui/Text';
import Spinner from 'ui/Spinner';
import { byPlatform, omitMobile } from 'utils/platforms';
import { PRODUCT_IDS } from 'configs/products';
import ResultsCard from './components/ResultsCard';
import Track from 'common/DataObjects/Track';
import { range } from 'utils/array';
import Forecast from 'features/RacesCarusel/Forecast';
import useRacingCard from 'features/AISDataProvider/hooks/useRacingCard.ts';
import RacingCard from 'common/DataObjects/RacingCard';
import { RaceNavigationLabel, ScrollBar } from 'ui/RaceNavigation';
import RaceLine from 'features/RacesCarusel/RaceLine';

export const NOT_V_SIMILAR_PRODUCTS = [
    PRODUCT_IDS.V,
    PRODUCT_IDS.K,
    PRODUCT_IDS.T,
    PRODUCT_IDS.TV,
    PRODUCT_IDS.P,
];

export const PRODUCTS_WITHOUT_PODDS = [PRODUCT_IDS.T, PRODUCT_IDS.TV, PRODUCT_IDS.K];
export const PRODUCTS_MIXED_VIEW = [PRODUCT_IDS.LD, PRODUCT_IDS.DD];

let _PrevActiveProduct = null;
let _PrevActiveTrack = null;

const Results = ({ results, activeProduct, activeTrack, date }) => {
    const [sliderIndex, setSliderIndex] = useState(0);
    const productId = activeProduct?.id;
    const trackId = activeTrack?.track?.trackId;
    const stringifiedDate = date.format('YYYY-MM-DD');

    const { racingCard } = useRacingCard({
        productId,
        trackId,
        date: stringifiedDate,
    });

    const forecastRace = RacingCard.getForecastRace(racingCard.races);

    useEffect(() => {
        if (
            _PrevActiveTrack &&
            activeTrack &&
            activeTrack.track.trackId !== _PrevActiveTrack.track.trackId
        ) {
            setSliderIndex(0);
        }
    }, [activeProduct, activeTrack]);

    const moveCaruselToSlide = index => {
        setSliderIndex(index);
    };

    const getResultsView = () => {
        return NOT_V_SIMILAR_PRODUCTS.includes(activeProduct.id) ? (
            <div>
                <ResultsCard
                    product={activeProduct}
                    track={Track.unserialize(activeTrack.track.trackId, activeTrack.track.track)}
                    result={results[sliderIndex]}
                    index={sliderIndex}
                    date={date}
                    winnersOnly={true}
                />
                <ResultsCard
                    product={activeProduct}
                    track={Track.unserialize(activeTrack.track.trackId, activeTrack.track.track)}
                    result={results[sliderIndex]}
                    index={sliderIndex}
                    date={date}
                    withoutWinners={true}
                />
            </div>
        ) : (
            <ResultsCard
                product={activeProduct}
                track={Track.unserialize(activeTrack.track.trackId, activeTrack.track.track)}
                result={results[0]}
                index={sliderIndex}
                date={date}
            />
        );
    };

    if (results && activeProduct && activeTrack) {
        _PrevActiveProduct = activeProduct;
        _PrevActiveTrack = activeTrack;

        let resultsView = getResultsView();

        return (
            <>
                {NOT_V_SIMILAR_PRODUCTS.includes(activeProduct.id) ? (
                    <Wrapper
                        display={omitMobile('grid')}
                        padding={byPlatform('30px 0 10px 0', '30px 25px 10px 25px')}
                        style={byPlatform(
                            {},
                            {
                                gridTemplateColumns: '90px 3fr 0',
                            }
                        )}
                    >
                        {omitMobile(<RaceNavigationLabel>Løb/afd.</RaceNavigationLabel>)}
                        <ScrollBar style={{ display: 'flex', alignItems: 'center' }}>
                            <RaceLine
                                raceNumbers={
                                    NOT_V_SIMILAR_PRODUCTS.includes(activeProduct.id)
                                        ? range(results.length, true)
                                        : range(results[0].legs.length, true)
                                }
                                setRaceIndex={moveCaruselToSlide}
                                raceIndex={sliderIndex}
                                fadedRaceNumbers={[]}
                            />
                        </ScrollBar>
                    </Wrapper>
                ) : null}

                <Wrapper width={byPlatform(null, null, 'calc(100% - 440px)')}>
                    <Forecast product={activeProduct} ongoingRace={forecastRace} />

                    {resultsView}
                </Wrapper>
            </>
        );
    } else {
        return results && results.length === 0 ? (
            <Wrapper>
                <Text align="center">Der er ingen afsluttede løb i øjeblikket.</Text>
            </Wrapper>
        ) : (
            <Spinner />
        );
    }
};

export default Results;
