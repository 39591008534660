import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { BETSLIP_NOTIFICATION_VIEWS } from 'configs/betslip';
import { DEFAULT_COMBINATION_PRICES } from 'configs/products';
import { showPopup } from 'utils/navigation';

import { checkMaximumPicks } from 'common/selectors/betSlipSelector';
import NotificationConductor from 'common/conductors/NotificationConductor';
import combinationsCountSelector from 'common/selectors/combinationsCountSelector';

import getTexts from 'utils/localization';
const t = getTexts();

export class BetslipNotifier extends Component {
    validateBettingSelection = () => {
        const { maximumPicksValid } = this.props;

        maximumPicksValid && showPopup('BETSLIP_MOBILE');

        !maximumPicksValid && NotificationConductor.error(t.betSlip.maximumPicksError);
    };

    render() {
        const { selectedProduct, combinationsCount: count } = this.props;

        if (!selectedProduct) {
            return null;
        }

        const productId = selectedProduct.id;

        if (!BETSLIP_NOTIFICATION_VIEWS[productId]) {
            throw new Error(`No notification view component specified for product ${productId}`);
        }

        const NotificationComponent = BETSLIP_NOTIFICATION_VIEWS[productId];

        return (
            <div
                style={{ visibility: count === 0 ? 'hidden' : 'visible' }}
                onClick={this.validateBettingSelection}
            >
                <NotificationComponent
                    combinationsCount={count}
                    basicPrice={
                        this.props.selectedProduct
                            ? DEFAULT_COMBINATION_PRICES[this.props.selectedProduct.id]
                            : 0
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    selectedProduct: state.AISDataProvider.selectedProduct,
    combinationsCount: combinationsCountSelector(state),
    maximumPicksValid: checkMaximumPicks(state),
});

export default withRouter(connect(mapStateToProps)(BetslipNotifier));
