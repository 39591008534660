import styled, { css } from 'styled-components';

const resetMixin = css`
    margin: 0;
    padding: 0;
    text-indent: 0;
    list-style-type: none;
`;

const Ul = styled.ul`
    ${resetMixin};
`;

const Ol = styled.ol`
    ${resetMixin};
`;

const Li = styled.li`
    ${resetMixin};
`;

export { Ul, Ol, Li, resetMixin };
