import React, { useState, useEffect, useRef } from 'react';
import noop from 'lodash/noop';
import { isMobile } from 'react-device-detect';
import Button from 'ui/controls/Button';
import NotificationConductor from 'common/conductors/NotificationConductor';
import Spinner from 'ui/Spinner';
import Flex from 'ui/Flex';

import {
    NavigationBar,
    Title,
    PreviewButtons,
    PhysicalCardFormWrapper,
    FormModalWrapper,
} from 'features/SpilID/styled';

import Input, { AddressSelect } from 'features/SpilID/Input';
import useOrderPhysicalCard from 'features/SpilID/useOrderPhysicalCard';
import SuccessPopup from 'features/SpilID/SuccessPopup';
import withPopupWrapper from './withPopupWrapper';

const ZIP_CODE_REGEX = /\d{4,5}/;

const FULL_NAME_REGEX = /^[a-zA-Z æøåöÆØÅÖ-]+$/;

const FULL_NAME_MESSAGE = 'Feltet må kun indeholde bogstaver';

const ADDRESS_REQUIRED_MESSAGE = 'Begynd at skrive og vælg en adresse';

const inputExtra = { width: '100%', height: '55px' };

const PhysicalCardForm = ({ hideModalAnimate, modalData }) => {
    const {
        fullName: prefilledFullName,
        address: prefilledAddress,
        zipCode: prefilledZipCode,
        cardId,
        birthdate,
        city: prefilledCity,
    } = modalData;

    const [fullName, setFullName] = useState(prefilledFullName);
    const [address, setAddress] = useState(
        prefilledCity ? prefilledAddress : ''
    );
    const [zipCode, setZipCode] = useState(prefilledZipCode);
    const [city, setCity] = useState(prefilledCity);

    const { requestCardOrder, pending, error, success } = useOrderPhysicalCard({
        cardId,
        address,
        fullName,
        zipCode,
        birthdate,
        city,
    });

    const fullNameRef = useRef(null);
    const addressRef = useRef(null);

    const enableEditingFullName = () => {
        setFullName('');
        fullNameRef.current.focus();
    };

    const enableEditingAddress = () => {
        setAddress('');
        addressRef.current.focus();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const backToSpilIdRoot = () => {
        hideModalAnimate('SPIL_ID_PC_FORM');
        hideModalAnimate('SPIL_ID_PC_PREVIEW');
    };

    useEffect(
        () => {
            const zipCodeMatch = address.match(ZIP_CODE_REGEX);

            if (zipCodeMatch) {
                setZipCode(zipCodeMatch[0]);
            } else {
                setZipCode('');
            }
        },
        [address]
    );

    useEffect(
        () => {
            if (error) {
                NotificationConductor.error(error);
                backToSpilIdRoot();
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [error]
    );

    const fullNameError = FULL_NAME_REGEX.test(fullName)
        ? null
        : FULL_NAME_MESSAGE;

    const addressError = zipCode ? null : ADDRESS_REQUIRED_MESSAGE;

    const formValid = !fullNameError && !addressError;

    return (
        <FormModalWrapper>
            <SuccessPopup shown={success} backToSpilIdRoot={backToSpilIdRoot} />

            <NavigationBar
                title={'Bestil Spil ID kort'}
                clickHandler={() => hideModalAnimate('SPIL_ID_PC_FORM')}
            />

            <Flex
                padding="30px 22px 24px 22px"
                boxSizing={'border-box'}
                direction={'column'}
            >
                <Flex direction={'column'} justify={'start'} align={'stretch'}>
                    <Title>
                        Bekræft nedenstående <br /> information.
                    </Title>
                    <PhysicalCardFormWrapper>
                        <Input
                            value={fullName}
                            inputRef={fullNameRef}
                            onChange={(fullName) => setFullName(fullName)}
                            onEdit={enableEditingFullName}
                            label={'Fulde navn'}
                            placeholder={'Fulde navn'}
                            name="fullName"
                            style={inputExtra}
                            error={fullNameError}
                        />

                        <AddressSelect
                            value={address}
                            inputRef={addressRef}
                            onChange={(address) => setAddress(address)}
                            onEdit={enableEditingAddress}
                            label={'Adresse'}
                            placeholder={'Adresse'}
                            name="address"
                            style={inputExtra}
                            setZipCode={setZipCode}
                            setCity={setCity}
                            error={addressError}
                        />
                    </PhysicalCardFormWrapper>
                </Flex>
                <PreviewButtons>
                    <Button
                        onClick={() => hideModalAnimate('SPIL_ID_PC_FORM')}
                        size="x2"
                        grey
                    >
                        Annuller
                    </Button>
                    <Button
                        size="x2"
                        disabled={!formValid}
                        onClick={formValid ? requestCardOrder : noop}
                        accentAlt
                        bold
                    >
                        {pending ? <Spinner black size="x07" /> : 'Bestil'}
                    </Button>
                </PreviewButtons>
            </Flex>
        </FormModalWrapper>
    );
};

export default (isMobile
    ? PhysicalCardForm
    : withPopupWrapper(PhysicalCardForm));
