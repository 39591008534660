import React from 'react';
import Wrapper from 'ui/Wrapper';
import Spinner from 'ui/Spinner';

class VideoReplays extends React.Component {
    constructor() {
        super();

        this.state = {
            loading: true,
        };
    }

    iframeReady = () => {
        this.setState({
            loading: false,
        });
    };

    render() {
        return (
            <Wrapper
                padding="0px"
                align="center"
                style={{ margin: '0px 10px' }}
            >
                {this.state.loading ? <Spinner size="x3" /> : null}

                <iframe
                    src="https://replays.webstream.dk/?parent=derby25"
                    allowFullScreen
                    alt="replays"
                    title="replays"
                    width="100%"
                    height="1000px"
                    frameBorder="0"
                    onLoad={this.iframeReady}
                />
            </Wrapper>
        );
    }
}

export default VideoReplays;
