import React from 'react';
import styled from 'styled-components';
import { ProductsTransitionBar } from '@it25syv/25syv-ui';
import rofusLogo from 'images/rofus.svg';
import { COLORS } from 'themes';
import LoginArea from 'layouts/Promo/Components/LoginArea';
import betImage from 'images/bet.png';
import derbyImage from 'images/derby.png';
import rodImage from 'images/rod.png';

const HeaderContainer = styled.div`
    position: relative;
    z-index: 1;
    box-sizing: border-box;
`;

const HeaderWrapper = styled.div`
    background: #18aa63;
    width: 100%;
    position: relative;
    height: 100%;

    padding: 10px;
    box-sizing: border-box;
`;
const HeaderContent = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1040px;
    margin: 0 auto;
    box-sizing: border-box;
`;
const ProductLogo = styled.img`
    height: 30px;
    vertical-align: middle;
    padding-left: 20px;
`;

const PromoHeader = ({ defaultLogo, logo, projectName, imgHost, signUp }) => {
    return (
        <HeaderContainer>
            <ProductsTransitionBar
                p={2}
                betImage={betImage}
                derbyImage={derbyImage}
                rodImage={rodImage}
                rofusLogo={rofusLogo}
                lastLoginTime={1577537877702}
                showTimer={false}
                clockFormat={'HH:mm'}
                activeProject={'derby'}
                background={'#18AA63'}
                hideMiddlePart={true}
                color={COLORS.white}
            />
            {/*<TopBar>*/}
            {/*    <TopMenu>*/}
            {/*        <TopMenuLogo href="https://www.spillemyndigheden.dk/">*/}
            {/*            <img src={rofusLogo} alt="Rofus" />*/}
            {/*        </TopMenuLogo>*/}
            {/*        <TopMenuText>*/}
            {/*            18+*/}
            {/*            <Separator>|</Separator>*/}
            {/*            <a href="tel:70222825">StopSpillet: 70 22 28 25</a>*/}
            {/*            <Separator>|</Separator>*/}
            {/*            <a href="https://www.rofus.nu/">ROFUS.nu</a>*/}
            {/*        </TopMenuText>*/}
            {/*    </TopMenu>*/}
            {/*    {clock()}*/}
            {/*</TopBar>*/}
            <HeaderWrapper>
                <HeaderContent>
                    <a href="/">
                        <ProductLogo src={logo ? imgHost + logo : defaultLogo} alt={projectName} />
                    </a>
                    <LoginArea signUp={signUp} />
                </HeaderContent>
            </HeaderWrapper>
        </HeaderContainer>
    );
};

export default PromoHeader;
