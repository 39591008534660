import 'console-polyfill';
import 'babel-polyfill';
import 'core-js/es/object/from-entries';
import 'react-app-polyfill/stable';
import 'core-js';
import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from 'common/reducers';
import rootEpic from 'common/epic';
import { createEpicMiddleware, Epic } from 'redux-observable';
import { ActionType, StateType } from 'typesafe-actions';
import betBuddyServices from 'features/Spilklub/server';
import * as batchBettingServices from 'features/BatchBetting/server';
import { history } from 'utils/navigation';
import Punter from 'common/api/PunterREST';

declare module 'typesafe-actions' {
    interface Types {
        RootAction: RootAction;
    }
}

const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });

const services = {
    api: {
        betBuddy: betBuddyServices,
        batchBetting: batchBettingServices,
        punter: Punter,
    },
    history,
};

export declare type Services = typeof services;

export type RootAction = ActionType<typeof import('./actions').default>;

export type Store = StateType<typeof import('./store').default>;

export type RootState = StateType<ReturnType<typeof import('./reducers').default>>;

export type RootEpic = Epic<RootAction, RootAction, RootState, Services>;

const epicMiddleware = createEpicMiddleware<RootAction, RootAction, RootState, Services>({
    dependencies: services,
});

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...[thunk, epicMiddleware]))
);

epicMiddleware.run(rootEpic);

export default store;
