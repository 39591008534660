class Bet {
    /**
     * Keys are start numbers and values are bet marks
     * @type {Object.<Array>.<number>}
     */
    betTable = {};

    constructor(betTable = {}) {
        this.betTable = betTable;
    }

    /**
     * Determines if current bet and specified bet are equal by value
     *
     * @param  {Bet}     betToCompare
     * @return {boolean}
     */
    isEqual = (betToCompare) => {
        const startNumbers = Object.keys(this.betTable);
        let result = true;

        if (startNumbers.length !== Object.keys(betToCompare.betTable).length) {
            return false;
        }

        startNumbers.forEach((startNumber) => {
            if (
                !betToCompare.betTable[startNumber] ||
                betToCompare.betTable[startNumber].length !==
                    this.betTable[startNumber].length
            ) {
                result = false;
            }
        });

        return result;
    };

    isStartMarked = (startNumber) => {
        return !!this.betTable[startNumber];
    };

    satisfies = (startNumber, betValue) => {
        if (!this.betTable[startNumber]) {
            return false;
        }

        return this.betTable[startNumber].indexOf(betValue) !== -1;
    };

    /**
     * @return {Array.<number>}
     */
    getPickedStartNumbers = () =>
        Object.keys(this.betTable).map((startNr) => parseInt(startNr, 10));

    /**
     * Grouping of starts that were picked by places
     * Result schema: { 1: [ 2, 3 ] }
     * Where the keys are the place numbers
     * and the values are the appropriate start numbers
     * @return {{'1': Array, '2': Array, '3': Array}}
     */
    groupStartNumbersByPlaces = () => {
        const result = {
            1: [],
            2: [],
            3: [],
            *[Symbol.iterator]() {
                yield result[1];
                yield result[2];
                yield result[3];
            },
        };

        Object.keys(this.betTable).forEach((startNumber) => {
            if (this.betTable[startNumber].indexOf(1) !== -1) {
                result[1].push(parseInt(startNumber, 10));
            }
            if (this.betTable[startNumber].indexOf(2) !== -1) {
                result[2].push(parseInt(startNumber, 10));
            }
            if (this.betTable[startNumber].indexOf(3) !== -1) {
                result[3].push(parseInt(startNumber, 10));
            }
        });

        return result;
    };

    /**
     * Returns numbers of starts / horses that were picked as U-horses.
     * Works only for Twin product, for others will always return empty array.
     * @return {Array.<number>}
     */
    getUHorseNumbers = () => {
        const pickedStartNumbers = Object.keys(this.betTable);
        const uHorseNumbers = [];

        pickedStartNumbers.forEach((startNr) => {
            if (this.betTable[startNr].indexOf('U') !== -1) {
                uHorseNumbers.push(parseInt(startNr, 10));
            }
        });

        return uHorseNumbers;
    };
}

export default Bet;
