import React from 'react';
import styled from 'styled-components';

import { countryCodeMap } from 'ui/RaceNavigation';
import Text from 'ui/Text';
import { byPlatform } from 'utils/platforms';

interface TrackInfoProps {
    trackCode: string;
    trackName: string;
}

export const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    flex: 1 0 15%;
`;

export const CountryImage = styled.img`
    height: ${props => props.height || byPlatform('14px', '16px')};
    margin-right: 6px;
    border-radius: 2px;
`;

export const getCountryImage = (code: string) =>
    countryCodeMap[code as keyof typeof countryCodeMap] || countryCodeMap.Default;

const TrackNameWithCountry = ({ trackCode, trackName }: TrackInfoProps) => {
    return (
        <TitleWrapper>
            <CountryImage src={getCountryImage(trackCode)} />
            <Text size={byPlatform('x2', 'x3')} bold>
                {trackName}
            </Text>
        </TitleWrapper>
    );
};

export default TrackNameWithCountry;
