import LocalMobileBet from '../LocalMobileBet';
import {
    MOBILE_BET_INIT,
    SUBTRACT_MOBILE_BET_COUNT,
    ADD_MOBILE_BET_COUNT,
    RESET_MOBILE_BET_COUNT,
    SET_MOBILE_BET,
    RESET_MOBILE_BET,
    SET_MOBILE_BET_TO_STATE,
    SET_MOBILE_BET_TRACK_READY,
    SET_MOBILE_BET_PRODUCT_READY,
} from './constants';

const initialState = {
    count: LocalMobileBet.count(),
    initialization: false,
    wasSetToState: false,
    trackIsReady: false,
    productIsReady: false,
    trackId: null,
    productId: null,
    data: {},
};

export default function mobileBet(state = initialState, action) {
    switch (action.type) {
        case MOBILE_BET_INIT:
            return { ...state, initialization: action.payload };
        case SUBTRACT_MOBILE_BET_COUNT:
            return { ...state, count: state.count - action.payload };
        case ADD_MOBILE_BET_COUNT:
            return { ...state, count: state.count + action.payload };
        case RESET_MOBILE_BET_COUNT:
            return { ...state, count: LocalMobileBet.count() };
        case SET_MOBILE_BET:
            return {
                ...state,
                data: action.payload,
                trackId: action.payload.fetchedBetData.trackId,
                productId: action.payload.fetchedBetData.product.id,
            };
        case SET_MOBILE_BET_TO_STATE:
            return { ...state, wasSetToState: true };
        case SET_MOBILE_BET_TRACK_READY:
            return { ...state, trackIsReady: true };
        case SET_MOBILE_BET_PRODUCT_READY:
            return { ...state, productIsReady: true };
        case RESET_MOBILE_BET:
            return { ...initialState };
        default:
            return state;
    }
}
