import { useSelector } from 'react-redux';
import { get } from 'lodash';

import { getRacingCardPick, getRacingCardReserves } from 'common/selectors/betTableSelector';
import { getReservesBy } from 'common/selectors/betSlipSelector';

import { ProductId, RaceDay } from 'features/EventBoard/server/calendar';
import { AnyRace } from 'features/TrackPage/model/Race';

interface Parameters {
    raceDay: RaceDay;
    trackCode: string;
    productId: ProductId;
    race: AnyRace;
}
// trackCode should be multitrack compatible, usually it's "Xa" and taken from the racing card response
const useBetPicks = ({ raceDay, trackCode, productId, race }: Parameters) => {
    const shortenProduct = { id: productId };

    const params = {
        date: raceDay.raceDayDate,
        trackCode,
        product: shortenProduct,
        raceIndex: race.index,
    };

    const singleRaceBetPicks = useSelector(state => getRacingCardPick(state, params));

    const racePicksMap = useSelector(state =>
        get(state, ['BetSlip', 'betsByDates', raceDay.raceDayDate, trackCode, productId], {})
    );

    const singleRaceReserves = useSelector(state => getRacingCardReserves(state, params));

    const raceReservesMap = useSelector(state =>
        getReservesBy(state, { raceDay, product: shortenProduct, track: { code: trackCode } })
    );

    return { singleRaceBetPicks, singleRaceReserves, racePicksMap, raceReservesMap };
};

export default useBetPicks;
