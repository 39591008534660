/**
 * Leg is an abstract class with general
 * information about a leg in a VxyCoupon
 * or a VxyQuickPickCoupon.
 */
class CouponLeg {
    /**
     * The number of the current leg.
     * Legs are numbered from 1 up to
     * the number of races in the pool.
     * @type {number}
     */
    legNumber = 0;
    /**
     * The selected horses to bet on.
     * Some products will use 15 Horses format,
     * other products will use 20.
     * It's defined in the products.js config.
     * @type {string}
     */
    selected15Horses = '';
    selected20Horses = '';
}

export default CouponLeg;
