import React from 'react';
import styled from 'styled-components';
import PoolOverview from 'features/TrackPage/components/PoolOverview/PoolOverviewRow';
import { COLORS } from 'themes';
import { Card } from 'ui/Card';
import Wrapper from 'ui/Wrapper.tsx';
import { BolderText } from 'features/UserArea/SelfExclusion/styled';
import { byPlatform } from 'utils/platforms';
import { useSelector } from 'react-redux';
import { getUITrackName } from 'common/selectors/trackSelector';

const StyledCard = styled(Card)`
    box-sizing: border-box;
    padding: 5px 30px 10px;
    display: inline-block;
    width: ${byPlatform('100%', 'auto')};
`;

const PoolOverviewItem = styled.div`
    font-size: ${byPlatform('auto', '16px')};
    padding-right: 10px;
    display: inline;
`;

const Label = styled.span`
    font-weight: 700;
    color: ${COLORS.black};
    box-sizing: border-box;
`;

const Value = styled.span`
    font-weight: 900;
    padding: 0 0 0 2px;
    color: ${COLORS.primaryText};
    box-sizing: border-box;
    color: ${COLORS.black};
`;

const PoolInformation = ({ poolOverview, race }) => {
    const trackName = useSelector(getUITrackName);

    return (
        <Wrapper padding={byPlatform('0 20px 20px', '20px')}>
            <StyledCard>
                <BolderText
                    size="x05"
                    style={{ fontSize: '12px' }}
                >{`Omsætning ${trackName}:`}</BolderText>
                <PoolOverview
                    poolOverview={poolOverview}
                    race={race}
                    Item={PoolOverviewItem}
                    Label={Label}
                    Value={Value}
                />
            </StyledCard>
        </Wrapper>
    );
};

export default PoolInformation;
