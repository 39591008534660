import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import {
    TableContent as TableContentDef,
    TableHeader as TableHeaderDef,
    TableRowCheckered,
} from 'ui/Table';
import { boxShadowMixin } from 'ui/Card';
import Wrapper from 'ui/Wrapper';
import { byPlatform } from 'utils/platforms';
import { COLORS } from '../../themes';
import { NEW_TRACK_PAGE } from 'configs/main';

export const SpilTipsWrapper = styled(Wrapper)`
    padding: 0;
    margin: ${NEW_TRACK_PAGE
        ? byPlatform('20px 0 5px', '20px 0')
        : byPlatform('0 20px 20px', '0 20px')};
    border-radius: 8px;
    ${boxShadowMixin};
`;

export const TableHeader = styled(TableHeaderDef)`
    display: flex;
    padding: 7px 15px 5px 15px;
    border-radius: 8px 8px 0 0;
`;

export const TableRow = styled(TableRowCheckered)`
    padding: 10px 15px;
    display: inline-block;
    font-weight: 600;
    ${() => isMobile && 'line-height: 16px;'}

    strong {
        font-weight: 900;
    }
`;

export const TableContent = styled(TableContentDef)`
    background: white;
    border-radius: 0 0 8px 8px;
    padding-bottom: 2px;
`;

export const TableCell = styled.span`
    color: ${COLORS.primaryText};
`;

export const RankSection = styled(TableRow)`
    line-height: 20px;
    display: flex;
    flex-direction: column;
    position: relative;
`;

export const RankLabel = styled.span`
    font-weight: 900;
`;

export const RankListWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

export const RankList = styled.div`
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    strong {
        font-weight: 900;
    }
`;

export const RankCategory = styled.strong`
    font-weight: 900;
`;

export const RankValues = styled.span``;

export const Label = styled.div`
    margin-left: auto;
    color: #8d94a0;
`;

export const HorseName = styled.span`
    font-weight: 900;
`;

export const HorseTip = styled.span`
    font-weight: bold;
`;
