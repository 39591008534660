import React, { Fragment, useRef, useState, useEffect } from 'react';
import { CloseButton, QPCard, QPWrapper } from 'features/QuickPick/styled';
import { PRODUCT_BACKGROUNDS } from 'features/QuickPick/index';
import Wrapper from 'ui/Wrapper';
import Headline from 'ui/Headline';
import Text from 'ui/Text';
import moment from 'moment';
import FlexWrapper from 'ui/FlexWrapper';
import Button from 'ui/controls/Button';
import PerformBetButton from 'features/QuickPick/QuickPickAmount/PerformBetButton';
import withQuickPickBetting from 'features/QuickPick/withQuickPickBetting';
import Input from 'ui/controls/Input';
import { postPlayAndPaySale } from 'features/BetSlip/components/PlayAndPay/actions';
import UserSessionAdapter from 'common/adapters/UserSession';
import NotificationConductor from 'common/conductors/NotificationConductor';
import PlayAndPay from 'features/BetSlip/components/PlayAndPay';
import Spinner from 'ui/Spinner';
import { get } from 'lodash';
import { getQuickPickMethodName } from 'utils/retail';
import getTexts from 'utils/localization';
import { Error } from 'features/BetSlip/styled';
import { MAX_DERBY_LYN_COUPON_AMOUNT } from 'configs/products';

const t = getTexts();

const QuickPickAmount = (props) => {
    const {
        date,
        track,
        product,
        programNumber,
        amount,
        amountValid,
        onCancelHandler,
        onFocusHandler,
        setAmount,
        setQuickPickAmount,
        onChangeHandler,
        // from HOC withQuickPickBetting
        performQuickPickBet,
        pending,
        // smartLynBet,
        // smartLynMode,
        auth,
        resetBet,
        // resetSmartLyn,
        // createSmartLynLegs,
        hideModal,
    } = props;

    const [playAndPayPending, setPlayAndPayPending] = useState(false);

    useEffect(() => {
        if (amountValid) {
            fillInput(amount);
        }
    }, [amount]);

    useEffect(() => {
        if (!playAndPayPending) {
            return;
        }

        const method = getQuickPickMethodName(product);
        const params = {
            raceDay: moment(date).format('YYYY-MM-DD'),
            trackCode: track.id,
            maxTotalBetAmount: amount,
            programNumber,
            productInfo: product,
            trackInfo: track,
            method,
            lyn: true,
        };

        /* smartLynMode is not available anymore via QuickPick modal */
        // if (smartLynMode && smartLynBet) {
        //     params.legs = createSmartLynLegs();
        // }

        const bettingData = UserSessionAdapter.getPerformSaleData(
            params,
            get(auth, 'user.id', null),
            null,
            null,
            moment(date).format('YYYY-MM-DD HH:mm')
        );

        postPlayAndPaySale(bettingData.payload, (res) => {
            if (res.success) {
                NotificationConductor.success(t.Terminal.PlayAndPay.saleNotification);

                hideModal('QUICK_PICK_AMOUNT');
                // resetSmartLyn();
                resetBet();
            } else {
                NotificationConductor.error(res.error_message);
            }
        });
    }, [playAndPayPending]);

    const inputElement = useRef(null);

    const fillInput = (newAmount) => {
        setAmount(newAmount);
        setQuickPickAmount(newAmount);
        inputElement.current.value = newAmount;
    };

    const performBet = () => {
        if (amountValid) {
            performQuickPickBet();
        }
    };

    const performPlayAndPaySale = () => {
        if (amountValid) {
            setPlayAndPayPending(true);
        }
    };

    const onChangeInputHandler = (newAmount) => {
        setQuickPickAmount(newAmount);
        onChangeHandler(newAmount);
    };

    const showStartSessionNotification = () => {
        NotificationConductor.info(t.Terminal.notifications.startSession);
    };

    const renderSubmitButton = () => {
        const PAPButton = (
            <PlayAndPay
                conditionToShow={true}
                performSale={performPlayAndPaySale}
                styles={{ marginTop: '25px', width: '210px;', height: '48px;' }}
            />
        );

        const attributes = {
            width: '210px',
            block: true,
            margin: '-30px auto',
            shadow: '0px -10px 30px rgba(0,0,0,0.2)',
            rounded: '30px',
            style: {
                position: 'relative',
            },
            size: playAndPayPending ? 'x3' : 'x2',
        };

        const userLoggined = get(props, 'auth.user.id', false);

        return !pending ? (
            <Fragment>
                <PerformBetButton
                    amount={amount}
                    amountValid={amountValid}
                    pending={pending}
                    onClick={userLoggined ? performBet : showStartSessionNotification}
                />
                {PAPButton}
            </Fragment>
        ) : (
            <Fragment>
                <Button accent {...attributes}>
                    <Spinner size="24" secondary />
                </Button>
            </Fragment>
        );
    };

    return (
        <QPWrapper
            bg={
                PRODUCT_BACKGROUNDS[product.id]
                    ? `url("${PRODUCT_BACKGROUNDS[product.id]}")`
                    : product.color
            }
            radius={props.useDesktopModal !== undefined ? 10 : 0}
        >
            {props.useDesktopModal === undefined ? (
                <CloseButton onClick={onCancelHandler}>
                    <span className="triangle" />
                    {t.QuickPick.label}
                </CloseButton>
            ) : null}

            <Wrapper height="auto" padding="60px 15%" align="center">
                <QPCard white style={{ minHeight: '420px' }}>
                    <Headline margin="10px 0px 0px" padding="0px 10px" grey size="x4">
                        {product.name} - {track.name}
                    </Headline>
                    <Text padding="0px 10px">{moment(date).format('DD/MM, HH:mm')}</Text>
                    <Text padding="10px 10px 0px">{t.QuickPick.setAmount}</Text>

                    <Wrapper width="auto" margin="20px auto" padding="0px" align="center">
                        <FlexWrapper padding="0px" direction="row">
                            <Button
                                onClick={fillInput.bind(this, 50)}
                                {...(amount !== 50 ? { lightGrey: true } : { primary: true })}
                                width="43%"
                                textsize="14px"
                                size="x2"
                                padding="18px"
                            >
                                50 {t.currency}
                            </Button>
                            <Button
                                onClick={fillInput.bind(this, 200)}
                                {...(amount !== 200 ? { lightGrey: true } : { primary: true })}
                                width="43%"
                                textsize="14px"
                                size="x2"
                                padding="18px"
                            >
                                200 {t.currency}
                            </Button>
                        </FlexWrapper>

                        <FlexWrapper padding="0px" direction="row">
                            <Button
                                onClick={fillInput.bind(this, 500)}
                                {...(amount !== 500 ? { lightGrey: true } : { primary: true })}
                                width="43%"
                                textsize="14px"
                                size="x2"
                                padding="18px"
                            >
                                500 {t.currency}
                            </Button>
                            <Button
                                onClick={fillInput.bind(this, 1000)}
                                {...(amount !== 1000 ? { lightGrey: true } : { primary: true })}
                                width="43%"
                                textsize="14px"
                                size="x2"
                                padding="18px"
                            >
                                1.000 {t.currency}
                            </Button>
                        </FlexWrapper>
                    </Wrapper>

                    <Input
                        defaultValue={amount}
                        inputRef={inputElement}
                        onChange={onChangeInputHandler}
                        onFocus={onFocusHandler}
                        placeholder={t.QuickPick.amountPlaceholder}
                        validationError={amountValid ? null : t.validation.notValid}
                        align="center"
                        style={{
                            height: '40px',
                            background: '#fff',
                            border: '2px solid #eceff5',
                            fontSize: '22px',
                            borderRadius: '40px',
                        }}
                    />

                    {amount >= MAX_DERBY_LYN_COUPON_AMOUNT && (
                        <Error>{`> ${MAX_DERBY_LYN_COUPON_AMOUNT}`}</Error>
                    )}

                    <Text align="center" padding="10px 0px 40px">
                        {t.QuickPick.minAmount}
                    </Text>
                </QPCard>

                {renderSubmitButton()}
            </Wrapper>
        </QPWrapper>
    );
};

export default withQuickPickBetting(QuickPickAmount);
