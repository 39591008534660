import React from 'react';
import { useDispatch } from 'react-redux';
import { BackBtn, Wrapper, ShopListHeader } from './styled';
import Logo from 'ui/Logo';
import NewsMobile from '../../layouts/sections/OldDesktopSidebar/News';
import { hideModal } from 'common/actions/uiActions';

const ShopList = () => {
    const dispatch = useDispatch();
    const articleProps = {
        params: {
            link: 'derby25-forhandlerliste-',
        },
    };
    const onClose = (e) => {
        dispatch(hideModal('SHOP_LIST'));
    };

    return (
        <Wrapper>
            <ShopListHeader>
                <BackBtn
                    onClick={onClose}
                    size="x2"
                    section="solid"
                    svgName="chevron-left"
                    color={'white'}
                />
                <Logo size="x2" />
            </ShopListHeader>
            <NewsMobile match={articleProps} />
        </Wrapper>
    );
};

export default ShopList;
