import styled from 'styled-components';

const Text = styled.p`
    margin: 8px auto;
    align-items: center;
    text-align: center;

    &.sm-top {
        font-size: 12px;
        margin-top: -50px;
        margin-bottom: 20px;
        font-weight: 700;
    }

    &.sm {
        font-size: ${(props) => (props.small ? '20px' : '24px')};
        font-weight: 700;
    }

    &.lg {
        font-size: ${(props) => (props.small ? '24px' : '34px')};
    }
`;

export default Text;
