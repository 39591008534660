import HistoryContainer, { HISTORY_MODE_RETAIL_BETS } from './HistoryContainer';

class RetailBets extends HistoryContainer {
    deserializeData = data => {
        return data.bets || [];
    };

    componentDidMount() {
        this.setDataHandlerName('getRetailBets');
        this.setMode(HISTORY_MODE_RETAIL_BETS);
        super.componentDidMount();
    }
}

export default RetailBets;
