import styled from 'styled-components';
import { COLORS } from 'themes';

const Label = styled.span`
    display: inline-block;
    margin: 0;
    color: ${COLORS.primaryText};
    text-transform: uppercase;
    font-weight: 800;
    text-decoration: ${(props) => (props.scratched ? 'line-through' : 'none')};
`;

export default Label;
