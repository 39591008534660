import React from 'react';
import { CarouselProvider, DotGroup, Slide, Slider } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import FlexWrapper from 'ui/FlexWrapper';
import './carousel-styles.css';
import StatisticList from './StatisticList';
import styled from 'styled-components';
import { BACKGROUNDS } from 'themes';

const DotsWrapper = styled(FlexWrapper)`
    position: fixed;
    width: 100%;
    bottom: 0;
    display: flex;
    height: 50px;
    left: 0;
    background: ${BACKGROUNDS.accent};
    button {
        align-self: center;
        background: ${BACKGROUNDS.secondary} !important;
        opacity: 0.5 !important;
    }
    button.carousel__dot--selected {
        opacity: 1 !important;
    }
`;

const StyledSlider = styled(Slider)`
    height: 100%;
    & > div {
        height: 100%;
    }
    ul {
        height: 100%;
    }
`;

class StatisticSlider extends React.Component {
    state = {
        appear: false,
    };

    componentDidMount() {
        setTimeout(() => this.setState({ appear: true }), 50);
    }

    render() {
        return (
            <CarouselProvider
                naturalSlideWidth={0}
                naturalSlideHeight={0}
                totalSlides={2}
                lockOnWindowScroll
                currentSlide={0}
                dragEnabled={false}
                style={{
                    opacity: this.state.appear ? 1 : 0,
                    transition: 'all 500ms ease-in',
                    height: '100%',
                }}
            >
                <StyledSlider classNameAnimation="slider-animation">
                    <Slide
                        style={{
                            float: 'none',
                            display: 'inline-flex',
                            height: 'auto',
                        }}
                        key={0}
                        index={0}
                    >
                        <StatisticList
                            slide={0}
                            data={this.props.data}
                            gallop={this.props.gallop}
                        />
                    </Slide>

                    <Slide
                        style={{
                            float: 'none',
                            display: 'inline-flex',
                            height: 'auto',
                        }}
                        key={1}
                        index={1}
                    >
                        <StatisticList
                            slide={1}
                            data={this.props.data}
                            gallop={this.props.gallop}
                        />
                    </Slide>
                </StyledSlider>

                <DotsWrapper align="center">
                    <DotGroup className="slider-dots" />
                </DotsWrapper>
            </CarouselProvider>
        );
    }
}

export default StatisticSlider;
