import React from 'react';
import { NotifierCounter, NotifierWrapper } from './StyledViews';
import Text from 'ui/Text';

const Notification = ({ combinationsCount, basicPrice, innerRef }) => {
    return (
        <NotifierWrapper ref={innerRef}>
            <Text white align="center">
                <b>Indsats:</b>{' '}
                <NotifierCounter combinationsCount={combinationsCount}>
                    {combinationsCount}
                </NotifierCounter>{' '}
                x {basicPrice}
            </Text>
        </NotifierWrapper>
    );
};

export default Notification;
