import Coupon from './Coupon';

class UnpaidSaleCoupon extends Coupon {
    constructor(coupon) {
        super();
        Object.assign(this, coupon);
    }

    method = '';
    productInfo = {
        id: '',
        name: '',
    };
    postTime = '';
    trackInfo = {};
}

export default UnpaidSaleCoupon;
