import React from 'react';

import Wrapper from 'ui/Wrapper';
import { FadeInSpan } from 'ui/animations/FadeIn2';

import DateSelection from 'features/ResultsNew/components/DateSelection';
import ResultTrackSelection from 'features/ResultsNew/components/ResultTrackSelection';
import { SelectionScreenContainer } from 'features/EventBoard/components/styled';
import {
    ResultStateLabel,
    SubSelectionScreenContainer,
} from 'features/ResultsNew/components/styled';

import useCalendarDate from 'features/ResultsNew/hooks/useCalendarDate';
import useTracksResultsQuery, {
    TrackResult,
} from 'features/ResultsNew/hooks/useTracksResultsQuery';

import { byPlatform, isMobile } from 'utils/platforms';
import { pageContainerWidth } from 'configs/layout';
import { history } from 'utils/navigation';

const isFirstVersion = true; // temp solution for first version of Result page

const Results = () => {
    const { date, isToday } = useCalendarDate();

    const onRaceDaySelected = (track: TrackResult) => {
        return history.push(`/resultat/${date}/V/${track.track.trackId}/1`);
    };

    const { tracks, segmentedTracks, isLoading } = useTracksResultsQuery();

    return (
        <Wrapper maxWidth={pageContainerWidth} padding={byPlatform('0', '0 30px 30px')}>
            <SelectionScreenContainer>
                <DateSelection isFirstVersion={isFirstVersion} />
                {isToday && !isMobile && !isFirstVersion ? (
                    <>
                        <SubSelectionScreenContainer>
                            <ResultTrackSelection
                                tracks={segmentedTracks?.started || []}
                                onRaceDaySelected={onRaceDaySelected}
                                isLoading={isLoading}
                            />
                        </SubSelectionScreenContainer>
                        <ResultStateLabel
                            bold
                            size={byPlatform('14', '22')}
                            padding={byPlatform('10px 0 10px 10px', '10px 0 10px 20px')}
                            className={byPlatform('flex align-center')}
                        >
                            <FadeInSpan>Senere</FadeInSpan>
                        </ResultStateLabel>
                        <SubSelectionScreenContainer>
                            <ResultTrackSelection
                                tracks={segmentedTracks?.upcoming || []}
                                onRaceDaySelected={onRaceDaySelected}
                                upcoming
                                isLoading={isLoading}
                            />
                        </SubSelectionScreenContainer>
                    </>
                ) : (
                    <ResultTrackSelection
                        tracks={tracks || []}
                        onRaceDaySelected={onRaceDaySelected}
                        isLoading={isLoading}
                        isFirstVersion
                    />
                )}
            </SelectionScreenContainer>
        </Wrapper>
    );
};

export default Results;
