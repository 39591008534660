import CouponLeg from 'features/BetSlip/BettingREST/DataObjects/CouponLeg';

/**
 VxyLeg represents a leg in a VxyCoupon, for instance a leg in a V75Coupon.
 A reserve horse is the chosen replacement or substitution horse in the case of
 a horse being withdrawn is marked in the selected set of horses.

 If one selected horse in the current leg is withdrawn, the first reserve will
 be used as substitution horse. If two selected horses in the current leg are
 withdrawn, also the second reserve will be used as substitution horse.

 In the case where more selected horses have been withdrawn than can be replaced
 by specified reserves, the substitution order specified by ATG will be used.

 In the case where reserves are not provided for a specific leg the substitution
 order specified by ATG will be used if needed (if a marked horse is withdrawn).
 */
class VxyLeg extends CouponLeg {
    firstReserve = '';
    secondReserve = '';
}

export default VxyLeg;
