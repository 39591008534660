import React from 'react';
import DateSelection from 'features/EventBoard/components/DateSelection';
import TrackSelectionPreloader from 'features/EventBoard/components/TrackSelectionPreloader';

const EventBoardPreloader = () => (
    <>
        <DateSelection />
        <TrackSelectionPreloader />
    </>
);

export default EventBoardPreloader;
