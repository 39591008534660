import React, { useEffect, useState } from 'react';
import userConductor from 'common/conductors/UserConductor';
import NotificationConductor from 'common/conductors/NotificationConductor';
import DesktopVoucher from './DesktopVoucher';
import MobileVoucher from './MobileVoucher';
import { byPlatform, isMobile } from 'utils/platforms';
import { hideStream } from 'features/StreamVideo/actions';
import { isUserLoggedIn } from 'common/actions/authActions';
import { connect } from 'react-redux';

const NUMERIC_REGEX = /^\d+$/;

const VoucherContainer = (props) => {
    const [serial, setSerial] = useState(null);
    const [code, setCode] = useState(null);
    const [pending, setPending] = useState(false);
    const [codeValid, setCodeValid] = useState(true);
    const [serialValid, setSerialValid] = useState(true);
    const uxDelay = 1000;
    let initialized = false;

    useEffect(() => {
        initialized = true;
    }, []);

    useEffect(() => {
        !initialized && setSerialValid(new RegExp(NUMERIC_REGEX).test(serial));
    }, [serial]);

    useEffect(() => {
        !initialized && setCodeValid(!!code);
    }, [code]);

    const confirm = () => {
        setPending(true);
        userConductor
            .applyVoucher(serial, code)
            .then(() => {
                props.isUserLoggedIn(); // update the balance
                setTimeout(() => {
                    //  eslint-disable-next-line
                    isMobile ? props.hideModal('VOUCHER') : null;

                    NotificationConductor.success('Din voucher er nu blevet indløst');
                }, uxDelay);
            })
            .catch((reason) => {
                setTimeout(() => {
                    NotificationConductor.error(reason);
                }, uxDelay);
            })
            .finally(() => {
                setTimeout(() => {
                    setPending(false);
                }, uxDelay);
            });
    };

    return byPlatform(
        <MobileVoucher
            confirm={confirm}
            pending={pending}
            setSerial={setSerial}
            setCode={setCode}
            serialValid={serialValid}
            codeValid={codeValid}
            {...props}
        />,
        <DesktopVoucher
            confirm={confirm}
            pending={pending}
            setSerial={setSerial}
            setCode={setCode}
            serialValid={serialValid}
            codeValid={codeValid}
            {...props}
        />
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideStream: () => {
            dispatch(hideStream());
        },
        isUserLoggedIn: () => {
            dispatch(isUserLoggedIn());
        },
    };
};

export default connect(null, mapDispatchToProps)(VoucherContainer);
