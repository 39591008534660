import styled from 'styled-components';

const ContentWrapper = styled.div`
    width: 100%;
    height: auto;
    margin-top: ${({ showAppToolbar }) => (showAppToolbar ? '140px' : 0)};
    @media (min-width: 700px) {
        margin-top: ${({ showAppToolbar }) => (showAppToolbar ? '120px' : 0)};
    }
    padding-bottom: 50px;
    overflow-x: hidden;
    overflow-y: scroll;
    /* 50px twice is a top bar and a bottom bar heights */
    min-height: calc(100vh - 50px - 50px);
    display: flex;
    flex-direction: column;
`;

export default ContentWrapper;
