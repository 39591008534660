import { DK_AUTH_REST_PATH } from 'configs/rest';
import { makeRequest } from 'utils/REST';
import { logMessage } from '../../../utils/log';

export function post(method, data) {
    return request('post', DK_AUTH_REST_PATH + method, data);
}

export function get(method, data) {
    return request('get', DK_AUTH_REST_PATH + method, data);
}

export function request(requestType, url, data) {
    return makeRequest(requestType, url, data)
        .then(response => {
            return response.json();
        })
        .catch(response => {
            logMessage(response);
        });
}
