import styled from 'styled-components';
import { COLORS } from 'themes';
import Text from 'ui/Text';
import { forMobile } from 'utils/platforms';
import CheckIcon from 'images/icons/check.png';

const rippedHeight = '10px';

const Popup = styled.div`
    background: ${COLORS.white};
    width: 80%;
    margin: 0 auto;
    ${forMobile('margin-bottom: 50px;')};
    position: relative;
    padding: 25px 20px;
    box-sizing: border-box;
    top: 50px;
    border-radius: 15px;
    /*
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    */
    box-shadow: ${(props) =>
        props.recipeMode ? `0 0 30px rgba(0, 0, 0, 0.3)` : 'none'};
    font-size: 14px;
    ${(props) =>
        props.recipeMode
            ? `
        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -${rippedHeight};
            height: ${rippedHeight};
            /*
            background: linear-gradient(
                    45deg,
                    transparent 33.333%,
                    ${COLORS.white} 33.333%,
                    ${COLORS.white} 66.667%,
                    transparent 66.667%
                ),
                linear-gradient(
                    -45deg,
                    transparent 33.333%,
                    ${COLORS.white} 33.333%,
                    ${COLORS.white} 66.667%,
                    transparent 66.667%
                );
            background-size: ${rippedHeight} calc(${rippedHeight} * 2);
            background-position: 0 -${rippedHeight};
            */
        }
    `
            : ''};
`;

const CloseButton = styled.div`
    width: 30px;
    height: 30px;
    background: ${COLORS.grey};
    border-radius: 15px;
    position: relative;
    z-index: 9999;
    left: 100%;
    top: -36px;
    color: ${COLORS.white};
    text-align: center;
    font-weight: 900;
    box-sizing: border-box;
    font-size: 30px;
    line-height: 25px;
`;

const RaceSummaryLine = styled(Text)`
    padding: 2px 0;
    ${forMobile(`
        line-height: 12px;
    `)};
`;

const SuccessIcon = styled.div`
    background: url(${CheckIcon}) no-repeat 0px 0px;
    width: 80px;
    height: 80px;
    position: absolute;
    left: 50%;
    margin-left: -40px;
    z-index: 2;
    top: -42px;
    background-size: contain;
`;

export { Popup, CloseButton, RaceSummaryLine, SuccessIcon };
