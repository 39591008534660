import Track from './Track';
import Product from './Product';
import { arrayToDateFormat } from 'utils/date';

export default class QuickPick {
    product = null;
    track = null;
    date = null;
    danish = false;
    jackpot = null;
    turnover = null;
    programNumber = null;

    constructor() {
        return this;
    }

    static unserialize(data) {
        const quickPick = new QuickPick();

        try {
            quickPick.product = Product.unserialize(data.product);
            quickPick.track = Track.unserialize(data.trackId, data.track);
            quickPick.date = arrayToDateFormat(data.startDate);
            quickPick.danish = data.danish;
            quickPick.danish = data.danish;
            quickPick.jackpot = data.jackpot;
            quickPick.turnover = data.turnover;
            quickPick.programNumber = data.programNumber;
        } catch (error) {
            //@TODO Logger of errors
        }

        return quickPick;
    }
}
