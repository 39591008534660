import React from 'react';
import PDFSection from 'features/TrackPage/components/RaceCard/Desktop/PdfDownload';
import useReservesMode from 'features/TrackPage/hooks/useReservesMode';

import { SubHeaderContainer, ClearAllButton, ReserveButton } from './styled';

import getTexts from 'utils/localization';

const t = getTexts();

const VRaceSubHeader = ({ isVStrictProduct, clearAllPicks }) => {
    const { reservesMode, toggleReservesMode } = useReservesMode();

    return (
        <SubHeaderContainer>
            <div>
                <ClearAllButton onClick={clearAllPicks} specialRed>
                    {t.RacingCard.controlArea.clearAll}
                </ClearAllButton>
                {isVStrictProduct && (
                    <ReserveButton onClick={toggleReservesMode} reserveMode={reservesMode} grey>
                        Reserver
                    </ReserveButton>
                )}
            </div>

            <PDFSection />
        </SubHeaderContainer>
    );
};

export default VRaceSubHeader;
