import UserConductor from 'common/conductors/UserConductor';
import { byPlatform } from 'utils/platforms';

export function intercomUpdate(user) {
    UserConductor.getProfile()
        .then(data => {
            window.Intercom('update', {
                name: data.firstName + ' ' + data.lastName,
                phone: data.phoneNr,
                // user_id: data.userId,
                email: user.email,
                username: user.userName,
                postal_code: data.zip,
            });
        })
        .catch(() => console.log('Could not track user data due to failed getProfile request'));
}

export function intercomLastLoginUpdate() {
    UserConductor.getProfile()
        .then(data => {
            window.Intercom('update', {
                last_login_derby25_at: Math.floor(Date.now() / 1000, 0),
                name: data.firstName + ' ' + data.lastName,
                phone: data.phoneNr,
                // user_id: data.userId,
            });
        })
        .catch(() => console.log('Could not track user data due to failed getProfile request'));
}

export function intercomBoot() {
    if (window.self !== window.top) {
        // bypass for iframe
        return;
    }
    window.Intercom('boot', {
        app_id: window.APP_ID,
        alignment: 'left',
        last_active_on: byPlatform('derby25_mob', 'derby25_web', 'derby25_term'),
        hide_default_launcher: byPlatform(true, false, true),
    });
}

export function intercomReboot() {
    if (window.self !== window.top) {
        // bypass for iframe
        return;
    }
    window.intercomSettings = {
        app_id: window.APP_ID,
        alignment: 'left',
    };
    //window.Intercom('shutdown');
    intercomBoot();
}
