import React from 'react';
import BetPickRow from './BetPickRow';

const UHorsePick = (props) => {
    const { race, bet } = props;
    const uHorseNumbers = bet.getUHorseNumbers();
    const pickedStarts = race.starts.filter((start) => {
        return uHorseNumbers.indexOf(start.startNr) !== -1;
    });

    return <BetPickRow rowLabel="U" pickedStarts={pickedStarts} bet={bet} />;
};

export default UHorsePick;
