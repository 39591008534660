import Button from 'ui/controls/Button';
import { BACKGROUNDS } from 'themes';
import Wrapper from 'ui/Wrapper';
import Text from 'ui/Text';
import styled from 'styled-components';

const StyledWrapper = styled(Wrapper)`
    text-align: center;
    margin-top: ${(props) => {
        return props.styles && props.styles.marginTop
            ? props.styles.marginTop
            : '-25px';
    }};
`;

const StyledText = styled(Text)`
    padding: 0;
    margin: 0;
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    color: ${BACKGROUNDS.white};
`;

const StyledButton = styled(Button)`
    padding: 10px;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    boxshadow: '0px 0px 30px rgba(0, 0, 0, 0.2) ';
    fontweight: 800;
    width: ${(props) => {
        return props.styles && props.styles.width
            ? props.styles.width
            : '145px;';
    }}
    height: ${(props) => {
        return props.styles && props.styles.height
            ? props.styles.height
            : '45px;';
    }}
    background: ${BACKGROUNDS.secondary};
`;

export { StyledWrapper, StyledText, StyledButton };
