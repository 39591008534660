import { css } from 'styled-components';

export interface TypographyProps {
    align?: string;
    capitalize?: boolean;
    strike?: boolean;
    bold?: boolean;
    solid?: boolean;
    short?: boolean;
}

export const typographyCSS = css<TypographyProps>`
    font-weight: ${props => (props.bold ? 900 : props.solid ? 700 : 'normal')};
    text-align: ${props => props.align ?? 'left'};
    text-transform: ${props => (props.capitalize ? 'capitalize' : 'auto')};
    text-decoration: ${props => (props.strike ? 'line-through' : 'none')};
    ${props =>
        props.short
            ? 'text-overflow: ellipsis; white-space: nowrap; overflow: hidden; width: auto;'
            : ''};
`;
