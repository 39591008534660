import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';

import NotificationConductor from 'common/conductors/NotificationConductor';
import { APP_MODE_TERMINAL } from 'configs/main';
import getTexts from 'utils/localization';

import { getDerbyLynBySmartLyn } from 'features/BetSlip2/components/SmartLyn';
import { setSmartLynMode } from 'features/BetSlip2/state/actions';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';

import SmartLynBet from 'common/DataObjects/SmartLynBet';
import betSlipSelector from 'common/selectors/betSlipSelector';

const t = getTexts();

const withSmartLynManager = (WrappedComponent) => {
    return (props) => {
        const { raceDay, productId, product, isMultitrack, races } = useTrackPage();

        // selectors
        const betSlip = useSelector(betSlipSelector);

        const smartLynBet = SmartLynBet.unserialize(
            raceDay.raceDayDate,
            product,
            raceDay.track.code,
            races,
            betSlip,
            isMultitrack ? raceDay.multipleTrackPoolSetups[productId] : null
        );

        const shopConfig = useSelector((state) => state.ShopConfig);

        const dispatch = useDispatch();

        const transferSmartLynBet = () => {
            const { onSmartLynPoolRowFetched } = props;

            getDerbyLynBySmartLyn(smartLynBet).then((quickPick) => {
                onSmartLynPoolRowFetched &&
                    onSmartLynPoolRowFetched({
                        ...quickPick,
                        date: quickPick.date.substring(0, 10),
                    });
            });
        };

        const activateSmartLyn = () => {
            const playAndPayWorkflow = get(shopConfig, 'data.playAndPayAllowed', false);
            let betslipHasSelections = false;

            const selections = betSlip.getAllRacesSelections(
                raceDay.raceDayDate,
                raceDay.track.code,
                productId
            );

            if (selections && Object.keys(selections || {}).length) {
                betslipHasSelections = Object.entries(selections).some(
                    (selection) => Object.keys(selection[1]).length > 0
                );
            }

            if (betslipHasSelections) {
                if (!APP_MODE_TERMINAL || playAndPayWorkflow) {
                    dispatch(setSmartLynMode(true));
                    transferSmartLynBet();
                } else {
                    NotificationConductor.error(t.COUPONFIRST);
                }
            } else {
                dispatch(setSmartLynMode(false));
                NotificationConductor.warning(t.smartLyn.atLeast1Horse);
            }
        };

        return (
            <WrappedComponent
                {...props}
                activateSmartLyn={activateSmartLyn}
                smartLynBet={smartLynBet}
            />
        );
    };
};

export default withSmartLynManager;
