import React, { Fragment } from 'react';
import { groupBy, uniq } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    DesktopSidebarUL,
    DesktopSidebarLink,
    DesktopSidebarLI,
    DesktopSidebarNav,
    SidebarIcon,
    HorizontalDelimiter,
    DesktopSideWrapper,
} from './styled';
import { showModal } from 'common/actions/uiActions';
import { getUrl, history, isHomePage, openLogin, showPopup } from 'utils/navigation';
import persistentStorage from 'common/storage';
import useAuth from 'common/hooks/useAuth.ts';
import { sidebar } from 'configs/layout';
import Burger from 'ui/Burger2';

// sidebar icons
import { ReactComponent as Nyt25Icon } from 'images/icons/desktop-sidebar/25nyt.svg';
import { ReactComponent as DerbyLynIcon } from 'images/icons/desktop-sidebar/derby-lyn.svg';
import { ReactComponent as EkspertSpilIcon } from 'images/icons/desktop-sidebar/ekspertspil.svg';
import { ReactComponent as HelpIcon } from 'images/icons/desktop-sidebar/help.svg';
import { ReactComponent as HomeIcon } from 'images/icons/desktop-sidebar/hjem.svg';
import { ReactComponent as MineSpilIcon } from 'images/icons/desktop-sidebar/minespil.svg';
import { ReactComponent as ResultsIcon } from 'images/icons/desktop-sidebar/results.svg';
import { ReactComponent as SpilKlubIcon } from 'images/icons/desktop-sidebar/spilklub.svg';
import { ReactComponent as ReplaysIcon } from 'images/icons/desktop-sidebar/replays.svg';
import { ReactComponent as SpilIdIcon } from 'features/SpilID/assets/customer.svg';

// import { ReactComponent as SKGuideIcon } from 'images/icons/desktop-sidebar/spilklub-guide.svg';
import { ReactComponent as SystemBetIcon } from 'images/icons/desktop-sidebar/systemspil.svg';
import { ReactComponent as ArrowRight } from 'images/icons/desktop-sidebar/menu-arrow-right.svg';
import { ReactComponent as PlayerIcon } from 'images/icons/desktop-sidebar/player.svg';
import { ReactComponent as LiveCasinoIcon } from 'icons/bottom-menu/live-casino.svg';
import useAuthCallback from 'common/hooks/useAuthCallback';
import { SCREENS } from 'features/Spilklub/config';
import { saveMitIdRedirectURL } from '../../../features/Signup/helpers';

const renderIcon = (SVG, active, className = '') => (
    <SidebarIcon active={active} className={className}>
        <SVG style={{ width: '18px', maxHeight: '18px' }} size={'18px'} />
    </SidebarIcon>
);

const getMenuItemsConfig = (handlers = {}) => [
    // Menu
    {
        title: 'Hjem',
        onClick: () => (window.location.pathname = getUrl('/')),
        iconJSX: renderIcon(HomeIcon, isHomePage() || history.location.pathname === '/'),
        section: 'Menu',
    },
    {
        title: '25-nyt',
        path: '/25-nyt',
        iconJSX: renderIcon(Nyt25Icon, history.location.pathname === '/25-nyt'),
        section: 'Menu',
    },
    {
        title: 'Hjælp',
        onClick: handlers.showIntercom,
        blank: true,
        iconJSX: renderIcon(HelpIcon),
        section: 'Menu',
    },
    {
        title: 'Spil ID',
        onClick: handlers.showSpilID,
        iconJSX: renderIcon(SpilIdIcon),
        section: 'Menu',
    },
    // Spil på heste
    {
        title: 'Systemspil',
        iconJSX: renderIcon(SystemBetIcon),
        section: 'Spil på heste',
        onClick: handlers.navigateToSystembet,
    },
    {
        title: 'Live stream',
        path: '/live-stream',
        iconJSX: renderIcon(PlayerIcon),
        section: 'Spil på heste',
    },
    {
        title: 'Replays',
        path: '/replays',
        iconJSX: renderIcon(ReplaysIcon),
        section: 'Spil på heste',
    },
    {
        title: 'SpilKlub25',
        onClick: handlers.showSpilKlubModal,
        iconJSX: renderIcon(SpilKlubIcon),
        section: 'Spil på heste',
    },
    {
        title: 'Ekspertspil',
        path: '/spilklub25-køb-andele-til-eksperternes',
        iconJSX: renderIcon(
            EkspertSpilIcon,
            history.location.pathname === encodeURI('/spilklub25-køb-andele-til-eksperternes')
        ),
        section: 'Spil på heste',
    },
    {
        title: 'Lynspil',
        path: '/derby-lyn',
        iconJSX: renderIcon(DerbyLynIcon, history.location.pathname === '/derby-lyn'),
        section: 'Spil på heste',
    },
    {
        title: 'Resultater',
        path: '/resultat',
        iconJSX: renderIcon(ResultsIcon, history.location.pathname === '/resultat'),
        section: 'Spil på heste',
    },
    {
        title: 'Mine spil',
        onClick: handlers.showMyBets,
        iconJSX: renderIcon(MineSpilIcon),
        section: 'Spil på heste',
    },
    {
        title: 'Live Casino',
        path: '/live-casino',
        section: 'Live Casino',
        iconJSX: renderIcon(LiveCasinoIcon),
    },
    // Information
    {
        title: 'Sådan spiller du',
        onClick: handlers.showSpilGuide,
        section: 'Information',
        hideOnClosed: true,
        blank: true,
        styles: {
            p: { margin: 0 },
        },
    },
    {
        title: 'SpilKlub guide',
        path: '/25-nyt/saadan-fungerer-spilklub25',
        section: 'Information',
    },
];

const DesktopSidebar = ({ isOpen = false, onBurgerClickHandler = () => {} }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const auth = useAuth();

    const showSpilKlubModal = () => {
        showPopup('SPILKLUB_DESKTOP', { showScreens: [SCREENS.GROUPS] });
    };

    const showMyBets = () => {
        if (!auth.user) {
            dispatch(
                openLogin({
                    replaceOnAuth: '/konto/activebets',
                    locationState: { background: history.location, USER_AREA_DESKTOP: {} },
                })
            );
        } else {
            showPopup('USER_AREA_DESKTOP', { params: { subpage: 'activebets' } });
        }
    };

    const showLiveStream = () => {
        if (!auth.user) {
            // Live stream will be shown with any additional actions
            // since it's showing up after login by default.
            dispatch(openLogin());
        } else {
            dispatch({ type: 'SHOW_STREAM' });
        }
    };

    const showIntercom = () => {
        window.Intercom('show');
    };

    const showSpilID = useAuthCallback(
        () => {
            dispatch(showModal('SPIL_ID'));
        },
        null,
        null,
        3,
        '/?spilid=1'
    );

    const showSpilGuide = () => {
        window.open('https://bet25.dk/heste/spilguide', '_blank');
    };

    const navigateToSystembet = () => {
        if (!auth.user) {
            saveMitIdRedirectURL('/systemspil');
            dispatch(openLogin({ pushOnAuth: '/systemspil' }));
        } else {
            history.push('/systemspil');
        }
    };

    const menuConfig = getMenuItemsConfig({
        showSpilKlubModal,
        showMyBets,
        showIntercom,
        showLiveStream,
        showSpilID,
        showSpilGuide,
        navigateToSystembet,
    });

    const menuItems = groupBy(menuConfig, 'section');

    const menuSections = uniq(menuConfig.map(item => item.section));

    const showItem = icon => isOpen || (!isOpen && icon);

    return (
        <DesktopSidebarNav
            className={!isOpen ? 'closed-sidebar' : ''}
            sideBarWidth={isOpen ? sidebar.width : sidebar.widthClosed}
        >
            <DesktopSideWrapper
                style={{
                    width: isOpen ? sidebar.width : sidebar.widthClosed,
                }}
            >
                <Burger isOpen={isOpen} onClickHandler={onBurgerClickHandler} />
                {menuSections.map((section, index) => (
                    <Fragment key={index}>
                        <HorizontalDelimiter />
                        <DesktopSidebarUL aria-label="Menu">
                            {menuItems[section].map(
                                (item, ind) =>
                                    showItem(item.iconJSX) && (
                                        <li
                                            key={ind}
                                            data-tip={isOpen ? '' : item.title}
                                            data-place="right"
                                        >
                                            {item.path ? (
                                                <DesktopSidebarLI
                                                    to={item.path}
                                                    locationprop={history.location}
                                                    isActive={(match, location) => {
                                                        if (item.path !== '/hjem') {
                                                            return item.path === location.pathname;
                                                        } else {
                                                            return (
                                                                location.pathname === '/' ||
                                                                item.path === location.pathname ||
                                                                isHomePage()
                                                            );
                                                        }
                                                    }}
                                                    activeClassName="active"
                                                    noicon={item.iconJSX ? 0 : 1}
                                                >
                                                    {item.iconJSX ? item.iconJSX : null}

                                                    <p className={isOpen ? 'open' : 'closed'}>
                                                        {item.title}
                                                    </p>
                                                    {isOpen &&
                                                        renderIcon(
                                                            ArrowRight,
                                                            false,
                                                            'arrow-right'
                                                        )}
                                                </DesktopSidebarLI>
                                            ) : (
                                                <DesktopSidebarLink
                                                    href={item.href || null}
                                                    target={item.blank ? '_blank' : ''}
                                                    onClick={item.onClick || null}
                                                    style={
                                                        item.styles && item.styles.link
                                                            ? item.styles.link
                                                            : undefined
                                                    }
                                                    {...item.props}
                                                >
                                                    {item.iconJSX ? item.iconJSX : null}

                                                    <p
                                                        style={
                                                            item.styles && item.styles.p
                                                                ? item.styles.p
                                                                : undefined
                                                        }
                                                        className={isOpen ? 'open' : 'closed'}
                                                    >
                                                        {item.title}
                                                    </p>
                                                    {isOpen &&
                                                        renderIcon(
                                                            ArrowRight,
                                                            false,
                                                            'arrow-right'
                                                        )}
                                                </DesktopSidebarLink>
                                            )}
                                        </li>
                                    )
                            )}
                        </DesktopSidebarUL>
                    </Fragment>
                ))}
            </DesktopSideWrapper>
        </DesktopSidebarNav>
    );
};

export default DesktopSidebar;
