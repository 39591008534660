import React from 'react';
import { FeedbackWrapper } from './styled';
import Options from './Options';
import { connect } from 'react-redux';
import PersistentStorage from 'common/storage';
import CMSAPI from 'common/api/CMSAPI';

class Feedback extends React.Component {
    constructor() {
        super();

        this.state = {
            visible: false,
        };
    }

    componentDidMount() {
        if (this.props.auth.user) {
            const feedbackCookies = PersistentStorage.get('feedback-' + this.props.auth.user.id);

            if (!feedbackCookies) {
                this.checkFeedbackForuser(this.props.auth.user.id);
            }
        }
    }

    componentDidUpdate(prevProps) {
        if (!prevProps.auth.user && this.props.auth.user) {
            const feedbackCookies = PersistentStorage.get('feedback-' + this.props.auth.user.id);

            if (!feedbackCookies) {
                this.checkFeedbackForuser(this.props.auth.user.id);
            }
        }
    }

    checkFeedbackForuser(userId) {
        CMSAPI.wasFeedbackSent(userId).then((res) => {
            if (res.success && !res.data.sent) {
                this.setState({
                    visible: true,
                });
            } else {
                let feedbackObj = {};

                feedbackObj['feedback-' + this.props.auth.user.id] = true;

                PersistentStorage.save(feedbackObj);
            }
        });
    }

    onComplete = (rating, text) => {
        let feedbackObj = {};

        feedbackObj['feedback-' + this.props.auth.user.id] = true;

        PersistentStorage.save(feedbackObj);

        let data = {
            user_id: this.props.auth.user.id,
            rating,
            text,
        };
        CMSAPI.createFeedback(data);

        setTimeout(
            () =>
                this.setState({
                    visible: false,
                }),
            2000
        );
    };

    render() {
        if (
            this.state.visible &&
            this.props.auth.user &&
            this.props.auth.user.id !== PersistentStorage.get('feedback')
        ) {
            return (
                <FeedbackWrapper>
                    <Options onComplete={this.onComplete} />
                </FeedbackWrapper>
            );
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps)(Feedback);
