import React from 'react';
import classNames from 'classnames';
import CloseBtn from './CloseBtn';
import Modal from 'ui/ModalContent';
import styled from 'styled-components';
import { BACKGROUNDS, COLORS } from 'themes/';
import { lockBodyScroll, unlockBodyScroll } from 'utils/ui';

import './modals.css';

const StyledModalWrapper = styled(Modal)`
    overflow-y: ${(props) => (props.scrollingEnabled ? 'scroll' : 'hidden')};
    -webkit-overflow-scrolling: touch;
    overscroll-behavior: none;
`;

export default class ModalContainer extends React.Component {
    constructor() {
        super();

        this.bodyScrollLockTimeoutIdRef = React.createRef();

        this.state = {
            closeButtonEnabled: false,
            closeButtonComponent: null,
            closeButtonColor: null,
            closeButtonZIndex: null,
            closeButtonHandler: null,
            topBarHidden: true,
            background: BACKGROUNDS.white,
            scrollingEnabled: true,
        };

        this.scrollableWrapperRef = React.createRef();
    }

    restoreTopBar = () => this.setState({ topBarHidden: false });

    hideModal = () => {
        this.props.actions.hideModal(this.props.modalId);

        if (this.props.animatingBeforeClose) {
            setTimeout(this.restoreTopBar, this.props.closingAnimationDuration);
        } else {
            this.restoreTopBar();
        }
    };

    closeModal = () => {
        this.props.modalData && this.props.modalData.customModalCloseHandler
            ? this.props.modalData.customModalCloseHandler()
            : this.state.closeButtonHandler
            ? this.state.closeButtonHandler()
            : this.hideModal();
    };

    showTopBar = () => {
        this.setState({ topBarHidden: false });
    };

    setBackground = (background) => {
        this.setState({ background });
    };

    enableCloseButton = (closeButtonComponent, color, closeButtonHandler, closeButtonZIndex) => {
        this.setState({
            closeButtonEnabled: true,
            closeButtonComponent,
            closeButtonColor: color,
            closeButtonHandler,
            closeButtonZIndex,
        });
    };

    disableCloseButton = () => {
        this.setState({
            closeButtonEnabled: false,
        });
    };

    enableScrolling = () => this.setState({ scrollingEnabled: true });
    disableScrolling = () => this.setState({ scrollingEnabled: false });

    componentDidMount() {
        const stopScrollToTop = this.props.modalData?.stopScrollToTop;
        const [timeoutId] = lockBodyScroll(
            this.props.modalData ? this.props.modalData.bodyScrollLockDelay : 0,
            stopScrollToTop,
            !stopScrollToTop
        );

        this.bodyScrollLockTimeoutIdRef.current = timeoutId;
    }

    componentWillUnmount() {
        const stopScrollToTop = this.props.modalData?.stopScrollToTop;
        unlockBodyScroll(this.bodyScrollLockTimeoutIdRef.current, stopScrollToTop);
    }

    renderModalContent() {
        const ModalComponent = this.props.ModalComponent;

        return (
            <ModalComponent
                {...this.props.actions}
                close={this.closeModal}
                modalData={this.props.modalData}
                enableCloseButton={this.enableCloseButton}
                disableCloseButton={this.disableCloseButton}
                enableScrolling={this.enableScrolling}
                disableScrolling={this.disableScrolling}
                showTopBar={this.showTopBar}
                setBackground={this.setBackground}
                priority={this.props.priority}
                topBarHidden={this.state.topBarHidden}
                scrollableWrapperRef={this.scrollableWrapperRef}
                cameFrom={this.props.cameFrom}
            />
        );
    }

    render() {
        const { nowrap, animatingBeforeClose } = this.props;

        const { closeButtonEnabled, closeButtonComponent, closeButtonColor, closeButtonZIndex } =
            this.state;

        if (nowrap) {
            return this.renderModalContent();
        }

        return (
            <StyledModalWrapper
                priority={this.props.priority}
                topBarShown={!this.state.topBarHidden}
                background={this.state.background}
                ref={this.scrollableWrapperRef}
                scrollingEnabled={this.state.scrollingEnabled}
                animatingBeforeClose={animatingBeforeClose}
                className={classNames('modal-fade', { 'modal-fade-out': animatingBeforeClose })}
            >
                {closeButtonEnabled &&
                    (closeButtonComponent ? (
                        <section onClick={this.closeModal}>{closeButtonComponent}</section>
                    ) : (
                        <CloseBtn
                            {...(closeButtonColor !== COLORS.white
                                ? { iclose: true }
                                : { iclosewhite: true })}
                            alt="close"
                            color={closeButtonColor ? closeButtonColor : 'white'}
                            size="x05"
                            onClick={this.closeModal}
                            zIndex={closeButtonZIndex}
                        />
                    ))}

                {this.renderModalContent()}
            </StyledModalWrapper>
        );
    }
}
