import { useDispatch, useSelector } from 'react-redux';
import { getIn } from 'immutable';
import {
    addAllBets,
    addBet,
    markTrioColumn,
    unmarkTrioColumn,
    markKombColumn,
    unmarkKombColumn,
    resetBet,
    resetRaceBet,
} from 'features/BetSlip2/state/actions';
import useTrackPage from './useTrackPage';
import useTrioColumnState from './useTrioColumnState';
import useKombColumnState from './useKombColumnState';
import { generateRaceKey } from 'features/TrackPage/components/RaceCard/Desktop/VRaceOverview/utils';
import betSlipSelector from 'common/selectors/betSlipSelector';
import betTableSelector from 'common/selectors/betTableSelector';
import { PRODUCT_IDS } from 'configs/products';
import { AnyRace } from '../model/Race';

const useColumnSelection = () => {
    const { raceDay, trackCode, race, productId } = useTrackPage();

    const dispatch = useDispatch();
    const selections = useSelector(betTableSelector);
    const betSlip = useSelector(betSlipSelector);

    //@ts-ignore
    const selectedBets = useSelector(state => state.BetSlip.selectedBets);

    const { trioMarksIndicator, toggleTrioState, resetTrioState } = useTrioColumnState();

    const { kombMarksIndicator, toggleKombState, resetKombState } = useKombColumnState();

    // @ts-ignore
    const getBetValueArr = (productId, startNr, existingSelections) => {
        let betValues = {
            TV: [
                startNr,
                // preserve U horses
                existingSelections.getUHorseNumbers().includes(startNr) ? 'U' : undefined,
            ].filter(item => item !== undefined),
            T: [1, 2, 3],
            K: [1, 2],
        };

        // @ts-ignore
        return typeof betValues[productId] === 'object' ? betValues[productId] : [startNr];
    };

    const checkSelection = () => {
        return getIn(
            selectedBets,
            [generateRaceKey(raceDay.raceDayDate, trackCode, productId, race.index)],
            false
        );
    };

    const selectAll = () => {
        let selectedStarts = {};

        if (checkSelection()) {
            resetRaceBet && resetRaceBet(raceDay.raceDayDate, trackCode, productId, race.index);
        } else {
            race?.horses?.forEach((horse, index) => {
                if (horse.vpPool.scratched) return;
                dispatch(
                    addBet(
                        raceDay.raceDayDate,
                        trackCode,
                        productId,
                        race.index,
                        horse.startNr,
                        index + 1
                        //isBetbuddy
                    )
                );
                //@ts-ignore
                selectedStarts[horse.startNr] = getBetValueArr(
                    productId,
                    horse.startNr,
                    selections
                );
            });
        }

        const betsByDates = { [race.index]: selectedStarts };
        dispatch(addAllBets(raceDay.raceDayDate, trackCode, productId, race.index, betsByDates));
    };

    const removeAllSelection = () => {
        dispatch(resetBet());

        if (productId === PRODUCT_IDS.T) {
            resetTrioState();
        }

        if (productId === PRODUCT_IDS.K) {
            resetKombState();
        }
    };

    const getStartNumbers = (race: AnyRace) => {
        return race.horses.filter(horse => !horse.vpPool.scratched).map(horse => horse.startNr);
    };

    const toggleTrioColumn = (column: number) => {
        trioMarksIndicator[column]
            ? dispatch(unmarkTrioColumn(raceDay.raceDayDate, trackCode, race.index, column))
            : dispatch(
                  markTrioColumn(
                      raceDay.raceDayDate,
                      trackCode,
                      race.index,
                      column,
                      getStartNumbers(race)
                  )
              );
        toggleTrioState(column);
    };

    const toggleKombColumn = (column: number) => {
        kombMarksIndicator[column]
            ? dispatch(unmarkKombColumn(raceDay.raceDayDate, trackCode, race.index, column))
            : dispatch(
                  markKombColumn(
                      raceDay.raceDayDate,
                      trackCode,
                      race.index,
                      column,
                      getStartNumbers(race)
                  )
              );
        toggleKombState(column);
    };

    const combinationsCount = () =>
        betSlip.countCombinations({
            productId,
            trackCode,
            date: raceDay.raceDayDate,
            raceIndex: race.index,
            horseCounts: race.horses.filter(horse => !horse.vpPool.scratched).length,
        });

    return {
        selectAll,
        removeAllSelection,
        toggleTrioColumn,
        toggleKombColumn,
        isAllPicked: checkSelection(),
        combinationsCount: combinationsCount(),
    };
};
export default useColumnSelection;
