import React from 'react';
import moment from 'moment';

import { DATE_FORMAT } from 'features/EventBoard/components/Shortcuts';

import * as Styled from 'features/EventBoard/components/Shortcuts/Shortcuts.styled';

import Text from 'ui/Text';
import { byPlatform } from 'utils/platforms';
import { SERVER_DATE_FORMAT } from 'utils/date';

const ShortcutButton = ({ product, shortcutsNumber, onClick }) => {
    return (
        <Styled.ShortcutContainer
            background={product?.color}
            hoverColor={product?.hoverColor}
            length={shortcutsNumber}
            onClick={onClick}
        >
            <Text white size={byPlatform('x1', '20')} bold padding={'2px 0'} atg>
                {product?.product}
            </Text>
            <Text white size={'x07'} padding={'0'} className={'capitalize'} weight={'700'}>
                {moment(product?.raceDate, SERVER_DATE_FORMAT).format(DATE_FORMAT)}
            </Text>
        </Styled.ShortcutContainer>
    );
};

export default ShortcutButton;
