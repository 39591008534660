import React, { Fragment } from 'react';
import { Pick, Row } from './styled';
import { Col } from 'react-grid-system';
import Label from 'ui/Label';
import Text from 'ui/Text';

/**
 *
 * @param                bet
 * @param  {Starts[]}    pickedStarts
 * @constructor
 */
const BetPickRowTrio = ({ bet, pickedStarts }) => {
    const placesByStartNumbers = bet.groupStartNumbersByPlaces();

    return Object.keys(placesByStartNumbers).map((placeNr) => {
        /**
         * @type {number[]}
         */
        const startNumbers = placesByStartNumbers[placeNr];
        /**
         * @type {Starts}
         */
        const firstStart = pickedStarts.find(
            (start) => start.startNr === startNumbers[0]
        );

        return (
            <Row key={placeNr}>
                <Col
                    xs={2}
                    className="text-bold text-center"
                    style={{ paddingLeft: '15px' }}
                >
                    {placeNr}.
                </Col>
                <Col style={{ padding: '0 20px 0 0' }}>
                    {startNumbers.length > 1 ? (
                        startNumbers.map((startNr) => {
                            /**
                             * @type {Starts}
                             */
                            const start = pickedStarts.find(
                                (start) => start.startNr === startNr
                            );

                            return (
                                <Pick key={startNr} scratched={start.scratched}>
                                    {startNr}
                                </Pick>
                            );
                        })
                    ) : firstStart ? (
                        <Fragment>
                            <Pick scratched={firstStart.scratched}>
                                {firstStart.startNr}
                            </Pick>{' '}
                            <Label scratched={firstStart.scratched}>
                                {firstStart.horse.horseNameAndNationality}
                            </Label>
                        </Fragment>
                    ) : (
                        <Text bold>-</Text>
                    )}
                </Col>
            </Row>
        );
    });
};

export default BetPickRowTrio;
