import React from 'react';
import { Label, Value } from './styled';
import { getBuild } from 'common/api/TerminalAPI';

class Build extends React.Component {
    constructor() {
        super();

        this.state = {
            version: null,
        };
    }

    componentDidMount() {
        getBuild()
            .then((res) => {
                let version = ((res || {}).data || {}).version || '..';
                if (typeof version === 'string') {
                    version = version.split(' ').slice(0, 3).join(' ');
                }

                this.setState({ version });
            })
            .catch((e) => {
                console.log('getBuild error ', e);
            });
    }

    render() {
        return (
            this.state.version && (
                <>
                    <Label>Build: </Label>
                    <Value>{this.state.version}</Value>
                </>
            )
        );
    }
}

export default Build;
