import React from 'react';

const MassSelectionHOC = WrappedComponent => {
    return props => {
        const {
            unmarkTrioRow,
            markTrioRow,
            trioMarksIndicator,
            markKombRow,
            unmarkKombRow,
            kombMarksIndicator,
        } = props;

        const toggleTrioRow = row =>
            trioMarksIndicator[row - 1] ? unmarkTrioRow(row) : markTrioRow(row);

        const toggleTrio1stRow = () => toggleTrioRow(1);
        const toggleTrio2ndRow = () => toggleTrioRow(2);
        const toggleTrio3rdRow = () => toggleTrioRow(3);
        const [trio1stRowSelected, trio2ndRowSelected, trio3rdRowSelected] = trioMarksIndicator;

        const toggleKombRow = row =>
            kombMarksIndicator[row - 1] ? unmarkKombRow(row) : markKombRow(row);

        const [komb1stRowSelected, komb2ndRowSelected] = kombMarksIndicator;

        const toggleKomb1stRow = () => toggleKombRow(1);
        const toggleKomb2ndRow = () => toggleKombRow(2);

        return (
            <WrappedComponent
                {...props}
                trio1stRowSelected={trio1stRowSelected}
                trio2ndRowSelected={trio2ndRowSelected}
                trio3rdRowSelected={trio3rdRowSelected}
                toggleTrio1stRow={toggleTrio1stRow}
                toggleTrio2ndRow={toggleTrio2ndRow}
                toggleTrio3rdRow={toggleTrio3rdRow}
                komb1stRowSelected={komb1stRowSelected}
                komb2ndRowSelected={komb2ndRowSelected}
                toggleKomb1stRow={toggleKomb1stRow}
                toggleKomb2ndRow={toggleKomb2ndRow}
            />
        );
    };
};

export default MassSelectionHOC;
