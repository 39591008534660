import memoize from 'lodash/memoize';
import map from 'lodash/map';
import get from 'lodash/get';
import omit from 'lodash/omit';

import routes from 'configs/routes';
import { createBrowserHistory } from 'history';
import { generatePath, matchPath } from 'react-router-dom';
import { DEFAULT_SELECTED_PRODUCT, PRODUCTS_NAMES } from 'configs/products';
import Track from 'common/DataObjects/Track';
import { trimBoth } from 'utils/formatters';
import { getLS, removeLS } from 'common/storage/localStor';
import { ModalsByURLSearchParams, ROUTER_POPUPS } from 'configs/modals';
import { isDesktop } from './platforms';
import { hideModal, showModal } from 'common/actions/uiActions';
import persistentStorage from 'common/storage';
import { parseNullString } from 'utils/object-helper';
import { NEW_TRACK_PAGE } from '../configs/main';

const BASE_PATH = '/heste';

const browserHistory = createBrowserHistory({ basename: BASE_PATH });
export const history = browserHistory;

export const EVENT_PATH = {
    DK: 'spil',
    EN: 'event',
};

export const RACE_EVENT_PATH = `/${EVENT_PATH['DK']}/:date/:product?/:track?/:race?`;

export const RESULTS_EVENT_PATH = `/resultat/:date/:product?/:trackId?/:race?`;

// history.listen((location, action) => {
//     console.log(location, action);
//     console.trace();
// });

export const getUrl = (url = '') => {
    if (url && url.startsWith('http')) {
        return url;
    }

    if (url && url.startsWith(BASE_PATH)) {
        // url already contains "BASE_PATH". For example, it's a link from local storage that already created with getUrl
        return url;
    }

    return `${BASE_PATH}/${url.startsWith('/') ? url.substr(1) : url}`;
};

export const getHost = () => `${window.location.host}${BASE_PATH}`;
export const getOrigin = () => `${window.location.origin}${BASE_PATH}`;

export const navigateWithRefresh = url => {
    window.location.href = getUrl(url);
};

//@TODO Get locale dynamically
//@TODO Get [current] property to be working correctly. Spec: fakeLocationPathname('/users/1/posts/3');     expect(satisfiesRoute({ path: '/news/' })).toEqual(false);
const lang = () => 'DK';

export const satisfiesRoute = route => {
    const multiPathPattern = '[0-9A-z-_æøåÆØÅ]*';
    const pattern = route.path.replace(/:\w+/gi, multiPathPattern);
    return new RegExp(pattern).test(history.location.pathname);
};

export const findRoute = routeId => getRoutes().find(r => r.id === routeId);

export const getRoutes = () => {
    return routes.map(r => wrapRoute(r));
};

export const wrapRoute = r => {
    const path = typeof r.path === 'object' ? r.path[lang()] : r.path;
    return {
        id: r.id,
        path,
        current: satisfiesRoute({ path }),
        component: typeof r.component === 'object' ? r.component[lang()] : r.component,
    };
};

export const isNewsItemPage = () =>
    /\/25-nyt\/[0-9A-z-_æøåÆØÅ]*\/?/.test(decodeURIComponent(history.location.pathname));

export const isHomePage = () => {
    return (
        history.location.pathname === '/hjem' ||
        isDirectEventLink() ||
        history.location.pathname === '/'
    );
};

export const isDirectEventLink = (pathname = history.location.pathname) => {
    const backgroundPathname = history.location.state?.background?.pathname;

    if (backgroundPathname) {
        pathname = backgroundPathname;
    }

    return (
        pathname.startsWith(`${EVENT_PATH[lang()]}/`) ||
        pathname.startsWith(`/${EVENT_PATH[lang()]}/`)
    );
};

export const createEventLink = ({ raceDay, product, raceIndex = 0 }) => {
    if (!raceDay || !product || !raceDay.track.name) {
        return '';
    }
    let params = `/${EVENT_PATH[lang()]}/${raceDay.date}`;
    params = `${params}/${product.name || DEFAULT_SELECTED_PRODUCT.name}/${Track.getSlug(
        raceDay.track.name || ''
    )}`;
    return `${params}/${raceIndex + 1}`;
};

export function extractTrackSlug(trackName) {
    return trackName
        .replace(/\s/g, '')
        .toLowerCase()
        .replace(/æ/gi, 'ae')
        .replace(/ø/gi, 'o')
        .replace(/ö/gi, 'o')
        .replace(/å/gi, 'a')
        .replace(/ä/gi, 'a');
}

export const generateEventPath = ({ date, productId, track, race }) => {
    const productName = PRODUCTS_NAMES[productId];

    return generatePath(`/${EVENT_PATH[lang()]}/:date/:product?/:track?/:race?`, {
        date,
        product: productName,
        track: NEW_TRACK_PAGE ? extractTrackSlug(track) : track,
        race,
    });
};

export const generateResultPath = ({ date, product, trackId, race }) => {
    return generatePath(`/resultat/:date/:product?/:trackId?/:race?`, {
        date,
        product,
        trackId,
        race,
    });
};

export const extractEventParams = () => {
    const match = matchPath(history.location.pathname, {
        path: RACE_EVENT_PATH,
        exact: true,
    });

    const isRaceEventPath =
        Boolean(
            matchPath(history.location.pathname, {
                path: RACE_EVENT_PATH,
                exact: true,
            })
        ) && history.location.pathname.startsWith('/spil');

    // if a router popup is shown then we check background location params
    const backgroundMatchParams = matchPath(history.location.state?.background?.pathname, {
        path: RACE_EVENT_PATH,
        exact: true,
    });

    return isRaceEventPath ? match.params : backgroundMatchParams?.params;
};

export const addQueryParam = (url, key, value) => {
    url = new URL(url);
    url.searchParams.append(key, value);
    return decodeURIComponent(url.origin + url.pathname + '?' + url.searchParams.toString());
};
/**
 * Makes array of values from object for the path described in string.
 * Object like { param1: 'foo', param2: 'bar', param3: undefined }
 * using pathname schema like '/:param1/:param2/:param3'
 * would be transformed to the following:
 * /foo/bar/:param3
 *
 * @param {Object}        o
 * @param {String}        pathnameSchema
 * @return {any[]}
 */
export const objectToPathname = (o, pathnameSchema) => {
    return (
        pathnameSchema
            .split('/')
            // .filter((s) => o.hasOwnProperty(s.substr(1)))
            .map(s => o[s.substr(1)] || s)
    );
};

export const pathnameToObject = (pathname, pathnameSchema) => {
    const keys = trimBoth(pathnameSchema, '/').split('/');
    const values = trimBoth(pathname, '/').split('/');
    const entries = keys.map((key, index) => [key.slice(1), values[index]]);
    return Object.fromEntries(entries);
};

export const removeSegment = (pathname, segment) => {
    return trimBoth(
        pathname
            .split('/')
            .filter(s => s !== trimBoth(segment, '/'))
            .join('/'),
        '/'
    );
};

export const queryStringToObject = queryString =>
    Object.fromEntries(Array.from(new URLSearchParams(queryString)));

/**
 * Determines if one of the parameters exist in URL query
 * Optional validator argument is a function checking
 * the value of the URL parameters (will be called for each of them)
 * @param {string[]} parameters
 * @param {function.<boolean>} validator
 * @return {boolean}
 */
export const hasURLParameters = (parameters, validator = () => true) => {
    const url = new URL(window.location.href);

    for (let [key, value] of url.searchParams.entries()) {
        if (parameters.includes(key) && validator(key, value)) {
            return true;
        }
    }

    return false;
};

export const getURLParameter = paramName => {
    //@TODO Sanitize URL parameter
    const url = new URL(window.location.href);
    return url.searchParams.get(paramName) || '';
};

export const checkURLs = URLs => {
    return URLs.some(checkURL);
};

export const checkURL = urlPart => {
    const currentPath = history.location.pathname;
    const backgroundLocation = history.location.state?.background;

    if (isPopupUrl(currentPath) && backgroundLocation?.pathname) {
        return backgroundLocation.pathname.includes(urlPart);
    }

    return currentPath.includes(urlPart);
};

export const navigateToRaceDay = (date, productId, trackName) => {
    const path = `/spil/${date}/${productId}/${Track.getSlug(trackName)}/1`;
    history.push(path);
};

export const redirectByLocalStorageParameter = parameterName => {
    const redirectFromLS = getLS(parameterName);

    if (redirectFromLS) {
        removeLS(parameterName);
        history.location.pathname = redirectFromLS;
    }
};

export const isPromoPage = () => {
    const pathname = history.location.pathname;
    const segments = pathname.split('/');
    return segments[1] && segments[1] === 'promo';
};

export function trimSlashes(str) {
    if (!str) return;
    return str.replace(/^\/|\/$/g, '');
}

export const isOnPath = path => {
    const pathname = trimSlashes(history.location.pathname);

    if (Array.isArray(path)) {
        const paths = path.map(p => trimSlashes(p));
        return paths.includes(pathname);
    }
    return pathname === trimSlashes(path);
};

export const extractModalIdQueryParam = modalId => {
    const [modalQueryParam] =
        Object.entries(ModalsByURLSearchParams)
            .map(([queryParam, { modalId }]) => [queryParam, modalId])
            .find(([, configModalId]) => configModalId === modalId) || [];

    return modalQueryParam;
};

export const wasModalOpenByQueryParam = (modalId, queryString = history.location.search) => {
    const modalQueryParam = extractModalIdQueryParam(modalId);

    if (!modalQueryParam) {
        return false;
    }

    const queryParams = queryStringToObject(queryString);

    const hasModalIdInQueryString = queryParams.hasOwnProperty(modalQueryParam);

    const value = queryParams[modalQueryParam];

    return hasModalIdInQueryString && value === '1';
};

export const removeQueryParamFromBrowserHistory = param => {
    const queryParams = new URLSearchParams(history.location.search);

    if (queryParams.has(param)) {
        queryParams.delete(param);
        history.replace({
            search: queryParams.toString(),
        });
    }
};

export const syncModalStateWithQueryParams = (modalId, queryString = history.location.search) => {
    if (wasModalOpenByQueryParam(modalId, queryString)) {
        const queryParam = extractModalIdQueryParam(modalId);
        removeQueryParamFromBrowserHistory(queryParam);
    }
};

export const push = (path, state = {}) => {
    history.push(path, { ...state, prevLocation: history.location });
};

/**
 *
 * @param {string} popupId       - The popup that should be added into ROUTER_POPUPS object config
 * @param {any}    params        - Pathname params that should be passed to URLS like /articles/:id, then it should be {id: 9}
 * @param background    - The component that will be shown under the popup.
 *                        Most of the time should not be changed so the UI looks smooth.
 *                        That's why it's history.location by default
 * @param replace       - pass 'true' if it's needed to use react-router 'replace' function instead of 'push'
 * @param keepPathname  - pass 'true' if a popup should be open with existing pathname, so only the router state will be pushed
 * @param keepPreviousBackgroundLocation - pass 'true' if the popups are opened one-by-one (previous location would be already a popup),
 *                                         so background location will remain the same.
 *                                         Otherwise, router would show 404 page under the popup.
 *
 * @param restSettings
 */
export const showPopup = (
    popupId,
    {
        background,
        params = {},
        replace = false,
        keepPathname = false,
        keepPreviousBackgroundLocation = false,
        ...props
    } = {}
) => {
    if (!Object.keys(ROUTER_POPUPS).includes(popupId)) {
        return console.warn(
            `Popup with id ${popupId} is not registered in the ROUTER_POPUPS config, but you tried to call 'showPopup' with it as an argument.`
        );
    }

    const { pathname, Component } = ROUTER_POPUPS[popupId];

    if (!pathname) {
        return console.warn('Popup config does not contain required "pathname" option.');
    }

    if (!Component) {
        return console.warn('Popup config does not contain required "Component" option.');
    }

    const path = generatePath(pathname, params);

    const previousBackgroundLocation = history.location.state?.background;

    const backgroundLocation =
        keepPreviousBackgroundLocation && previousBackgroundLocation
            ? previousBackgroundLocation
            : background ?? history.location;

    if (replace) {
        return history.replace(path, {
            ...getRouterState(),
            [popupId]: props,
            // prevents creation of deep nested state with a chain of background with state with backgrounds having state
            background: omit(backgroundLocation, 'state'),
            // same should be done for prevLocation (it actually used for navigation.goBack utility only which doesn't need state)
            prevLocation: omit(history.location, 'state'),
        });
    }

    push(keepPathname ? history.location.pathname : path, {
        ...getRouterState(),
        background: omit(backgroundLocation, 'state'),
        [popupId]: props,
    });
};

export const goBack = () => {
    if (history.location.key || history.location.state?.prevLocation) {
        history.goBack();
    } else {
        push('/', { background: undefined });
    }
};

export const hidePopup = popupId => {
    goBack();
};

export const destroyPopupState = popupId => {
    history.push({ state: getDestroyedPopupState(popupId) });
};

export const getDestroyedPopupState = popupId => ({
    ...history.location.state,
    [popupId]: undefined,
});

export const openNextPopup = (popupId, previousPopupId, params = {}) => {
    showPopup(popupId, {
        keepPreviousBackgroundLocation: true,
        replace: true,
        ...params,
    });

    setTimeout(() => destroyPopupState(previousPopupId), 200);
};

export const closeLastPopup = popupId => {
    const backgroundPathname = history.location.state?.background?.pathname;
    // we should destroy popup state + navigate a user to the page route on the background
    history.replace(backgroundPathname, getDestroyedPopupState(popupId));
};

export const getPopupPaths = memoize(() => map(ROUTER_POPUPS, popup => get(popup, 'pathname')));

export const isPopupUrl = url =>
    getPopupPaths().some(popupPathname => matchPath(url, popupPathname));

/**
 * For desktop - priority will be passed as component props along with other options
 * For other platforms - `options` will be passes as `modalData` argument to redux action
 * @param {number|any} priority
 * @param options
 * @returns Redux action object
 */
export const openLogin = ({ priority = 1, ...options } = {}) => {
    if (isDesktop) {
        showPopup('LOGIN', { priority, ...options });
        // returning a null action to be consistent with mobile case,
        // as the return result will be used with redux `dispatch`
        return { type: 'NULL_ACTION' };
    }

    return showModal('LOGIN', priority, options);
};

export const getRouterState = () => {
    return history.location.state;
};

export const generatePopupLink = (pathname, popupId, props = {}) => {
    return {
        pathname,
        state: { ...getRouterState(), background: history.location, [popupId]: props },
    };
};

export const closeLogin = () => {
    if (isDesktop) {
        hidePopup('LOGIN');
        return { type: 'NULL_ACTION' };
    }

    return hideModal('LOGIN');
};

export const showPinPopup = () => {
    const pinLoginStatus = persistentStorage.getLS('pinPopup');
    const pinHash = parseNullString(persistentStorage.getLS('pinHash'));
    const pinUserName = persistentStorage.getLS('pinUserName');

    if (!(pinHash && pinUserName) && (!pinLoginStatus || pinLoginStatus === 'false')) {
        return showModal('PIN_POPUP');
    }

    return { type: 'NULL_ACTION' };
};
