import { get } from 'lodash';
import NotificationConductor from 'common/conductors/NotificationConductor';
import UserSessionAdapter from 'common/adapters/UserSession';
import {
    checkoutCms,
    checkinCms,
    applyVoucherCms,
    checkinByCouponCms,
    checkinBySystemCouponCms,
    getCouponStatusCms,
    getSystemCouponStatusCms,
    applyCouponCms,
    applySystemCouponCms,
    getUnpaidSale,
} from 'common/api/TerminalAPI';
import CMSApi from 'common/api/CMSAPI';
import { LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT } from 'common/actions/authActions';
import { saveJSONparsing } from 'utils/formatters';
import { RESET_MOBILE_BET } from 'features/MobileBet/state/constants';
import Product from 'common/DataObjects/Product';

export const CHECK_IN = 'ScanLogicTerminal/CHECK_IN';
export const CHECK_OUT = 'ScanLogicTerminal/CHECK_OUT';
export const STATUS_CHECK = 'ScanLogicTerminal/STATUS_CHECK';
export const SYSTEM_STATUS_CHECK = 'ScanLogicTerminal/SYSTEM_STATUS_CHECK';
export const UNPAID_SALE_CHECK = 'ScanLogicTerminal/UNPAID_SALE_CHECK';
export const FETCH_PENDING = 'ScanLogicTerminal/FETCH_PENDING';
export const FETCH_ERROR = 'ScanLogicTerminal/FETCH_ERROR';
export const HIDE_SCAN_MODAL = 'ScanLogicTerminal/HIDE_SCAN_MODAL';
export const CLEAN_ERROR_MESSAGE = 'CLEAN_ERROR_MESSAGE';
export const CHECKIN_PENDING = 'CHECKIN_PENDING';

export const getMobileBet = async (id, shopId) => {
    const payload = {
        data: {},
        errorMessage: null,
    };

    try {
        const response = await CMSApi.getMobileBets({
            ids: {
                actual: [id],
            },
            shopId,
            scannedOn: 'terminal',
        });

        if (!response.success && !response.data) {
            throw new SyntaxError(get(response, 'error_message', 'Wrong response format'));
        }

        const data = get(response, 'data[0]', []);
        const mobileBetData = saveJSONparsing(data.race_info, null);

        if (!mobileBetData) {
            throw new SyntaxError('Invalid data format');
        }

        const date = get(mobileBetData, 'raceDate.date', '');
        const product = new Product().setAll(
            get(mobileBetData, 'product.id', ''),
            get(mobileBetData, 'product.name', '')
        );
        const raceNumber = get(mobileBetData, 'race.raceNumber', null);
        const reserves = mobileBetData.isMultitrackProduct
            ? { [date]: mobileBetData.multitrackReserves }
            : { ...mobileBetData.reserves };

        const betslipData = {
            picks: {
                ...mobileBetData.betslip,
            },
            reserves,
        };

        payload.data = {
            fetchedBetData: mobileBetData,
            date,
            product,
            raceNumber,
            betslipData,
        };
    } catch (e) {
        payload.errorMessage = 'Failed getting CMS data. ' + e;
    }

    return payload;
};

export const resetMobileBet = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_MOBILE_BET,
            payload: {},
        });

        dispatch({
            type: HIDE_SCAN_MODAL,
            payload: {},
        });
    };
};

export const getUnpaidSaleInfo = (hash) => {
    return (dispatch) => {
        getUnpaidSale(hash)
            .then((response) => {
                const res = response || {};
                if (res.success && res.data) {
                    dispatch({
                        type: UNPAID_SALE_CHECK,
                        payload: res.data,
                    });
                } else {
                    const errorMessage = res.msg ? res.msg : res.error_message;

                    dispatch({
                        type: HIDE_SCAN_MODAL,
                        payload: {
                            error: errorMessage,
                        },
                    });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const cleanTerminalErrorMessage = () => {
    return (dispatch) => {
        dispatch({
            type: CLEAN_ERROR_MESSAGE,
            payload: {},
        });
    };
};

export const showCheckinModal = () => {
    return (dispatch) => {
        dispatch({
            type: CHECK_IN,
            payload: {},
        });
    };
};

export const checkin = (UserSessionAdapter, voucherHash) => {
    return (dispatch) => {
        dispatch({
            type: CHECKIN_PENDING,
            payload: true,
        });

        checkinCms(UserSessionAdapter.getTerminalId(), voucherHash)
            .then((res) => {
                const authData = UserSessionAdapter.extractAuthData(res);

                if (!authData.error && authData.user && authData.user.balance > 0) {
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: authData.user,
                    });
                } else {
                    dispatch({
                        type: LOGIN_ERROR,
                        // payload: authData.error,
                    });
                }

                dispatch({
                    type: HIDE_SCAN_MODAL,
                    payload: {
                        error: authData.error,
                        checkInPending: false,
                    },
                });
            })
            .catch((error) => {
                console.error('checkin', error);
                unlockCheckIn(dispatch);
            });
    };
};

export const checkinByCoupon = (UserSessionAdapter, couponHash) => {
    return (dispatch) => {
        dispatch({
            type: CHECKIN_PENDING,
            payload: true,
        });

        checkinByCouponCms(UserSessionAdapter.getTerminalId(), couponHash)
            .then((res) => {
                const authData = UserSessionAdapter.extractAuthData(res);

                if (!authData.error && authData.user && authData.user.balance > 0) {
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: authData.user,
                    });
                } else {
                    dispatch({
                        type: LOGIN_ERROR,
                        payload: authData.error,
                    });
                }

                unlockCheckIn(dispatch);
            })
            .catch((error) => {
                console.error(error);
                unlockCheckIn(dispatch);
            });
    };
};

export const checkinBySystemCoupon = (UserSessionAdapter, couponHash) => {
    return (dispatch) => {
        dispatch({
            type: CHECKIN_PENDING,
            payload: true,
        });

        checkinBySystemCouponCms(UserSessionAdapter.getTerminalId(), couponHash)
            .then((res) => {
                const authData = UserSessionAdapter.extractAuthData(res);

                if (!authData.error && authData.user && authData.user.balance > 0) {
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: authData.user,
                    });
                } else {
                    dispatch({
                        type: LOGIN_ERROR,
                        payload: authData.error,
                    });
                }

                unlockCheckIn(dispatch);
            })
            .catch((error) => {
                console.error(error);
                unlockCheckIn(dispatch);
            });
    };
};

export const checkout = (terminalId, sessionId, cb) => {
    return (dispatch) => {
        dispatch({
            /** also sets checkInPending to 'true' */
            type: CHECK_OUT,
            payload: {},
        });

        sessionId = sessionId || window.localStorage.getItem('sessionId');
        return checkoutCms(terminalId, sessionId)
            .then((res) => {
                if (res.success) {
                    dispatch({
                        type: LOGOUT,
                        payload: true,
                    });

                    UserSessionAdapter.eraseSession();
                }

                if (res.error_message && res.error_message.indexOf('SESSION IKKE FUNDET') > -1) {
                    dispatch({
                        type: LOGOUT,
                        payload: true,
                    });

                    UserSessionAdapter.eraseSession();
                }

                unlockCheckIn(dispatch);
            })
            .catch((error) => {
                console.error(error);
                unlockCheckIn(dispatch);
            });
    };
};

export const checkCouponStatus = (couponHash) => {
    return (dispatch) => {
        getCouponStatusCms(couponHash)
            .then((res) => {
                if (res.success && res.data) {
                    dispatch({
                        type: STATUS_CHECK,
                        payload: res.data,
                    });
                } else {
                    dispatch({
                        type: HIDE_SCAN_MODAL,
                        payload: {
                            error: res.msg ? res.msg : res.error_message,
                        },
                    });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const checkSystemCouponStatus = (couponHash) => {
    return (dispatch) => {
        getSystemCouponStatusCms(couponHash)
            .then((res) => {
                if (res.success && res.data) {
                    dispatch({
                        type: SYSTEM_STATUS_CHECK,
                        payload: res.data,
                    });
                } else {
                    dispatch({
                        type: HIDE_SCAN_MODAL,
                        payload: {
                            error: res.msg ? res.msg : res.error_message,
                        },
                    });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    };
};

export const applyVoucher = (UserSessionAdapter, voucherHash) => {
    return (dispatch) => {
        dispatch({
            type: CHECKIN_PENDING,
            payload: true,
        });

        const sessionId = UserSessionAdapter.getSessionId(); //sessionId || window.localStorage.getItem('sessionId');
        applyVoucherCms(sessionId, voucherHash)
            .then((res) => {
                const authData = UserSessionAdapter.extractAuthData(res);

                if (!authData.error && authData.user && authData.user.balance > 0) {
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: authData.user,
                    });
                }

                if (authData.error) {
                    NotificationConductor.error(authData.error);
                }

                unlockCheckIn(dispatch);
            })
            .catch((error) => {
                console.error(error);
                unlockCheckIn(dispatch);
            });
    };
};

export const applyCoupon = (UserSessionAdapter, sessionId, couponHash) => {
    return (dispatch) => {
        dispatch({
            type: CHECKIN_PENDING,
            payload: true,
        });

        sessionId = sessionId || window.localStorage.getItem('sessionId');
        applyCouponCms(sessionId, couponHash)
            .then((res) => {
                const authData = UserSessionAdapter.extractAuthData(res);

                if (!authData.error && authData.user && authData.user.balance > 0) {
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: authData.user,
                    });
                }

                if (authData.error) {
                    NotificationConductor.error(authData.error);
                }

                unlockCheckIn(dispatch);
            })
            .catch((error) => {
                console.error(error);
                unlockCheckIn(dispatch);
            });
    };
};

export const applySystemCoupon = (UserSessionAdapter, sessionId, couponHash) => {
    return (dispatch) => {
        dispatch({
            type: CHECKIN_PENDING,
            payload: true,
        });

        sessionId = sessionId || window.localStorage.getItem('sessionId');
        applySystemCouponCms(sessionId, couponHash)
            .then((res) => {
                const authData = UserSessionAdapter.extractAuthData(res);

                if (!authData.error && authData.user && authData.user.balance > 0) {
                    dispatch({
                        type: LOGIN_SUCCESS,
                        payload: authData.user,
                    });
                }

                if (authData.error) {
                    NotificationConductor.error(authData.error);
                }

                unlockCheckIn(dispatch);
            })
            .catch((error) => {
                console.error(error);
                unlockCheckIn(dispatch);
            });
    };
};

const unlockCheckIn = (dispatch) => {
    dispatch({
        type: HIDE_SCAN_MODAL,
        payload: {
            checkInPending: false,
        },
    });
};

export const hideScanModal = (error) => {
    return (dispatch) => {
        dispatch({
            type: HIDE_SCAN_MODAL,
            payload: {
                error,
            },
        });
    };
};
