import styled from 'styled-components';
import { getBackgroundByProps } from 'utils/theme-helpers';
import { AdjustableComponent } from './types';
import { COLORS } from '../themes';

const Wrapper = styled.div<AdjustableComponent>`
    font-size: 12px;
    margin: ${props => (props.margin ? props.margin : '0px')};
    padding: ${props => (props.padding ? props.padding : '10px')};
    display: ${props => (props.display ? props.display : 'block')};
    text-align: ${props => (props.align ? props.align : 'left')};
    max-width: ${props => (props.maxWidth ? props.maxWidth : 'auto')};
    border-radius: ${props => (props.borderRadius ? props.borderRadius : 'none')};
    ${props => (props.width ? 'width: ' + props.width + ';' : '')};
    ${props => (props.height ? 'height: ' + props.height + ';' : '')};
    ${props => (props.nowrap ? 'white-space: nowrap;' : '')};
    ${props =>
        props.shadow
            ? 'box-shadow: 0px 0px ' +
              (props.shadow ? props.shadow : 0) +
              'px ' +
              COLORS.blockShadow
            : null};
    ${props =>
        props.rounded ? 'border-radius: ' + (props.rounded ? props.rounded : 10) + 'px;' : null};
    ${props => (props.flex ? 'display: flex' : '')};
    ${props =>
        props.transition
            ? 'transition: ' + (props.transition ? props.transition : '') + ';'
            : null};
    ${props =>
        props.overflow ? 'overflow: ' + (props.overflow ? props.overflow : 'auto') + ';' : null};
    ${props => (props.position ? 'position: ' + props.position + ';' : null)};
    ${props => props.background && `background: ${props.background}`};
    ${props => props.background && `background-color: ${props.background}`};
    ${props => props.boxSizing && `box-sizing: ${props.boxSizing}`};
`;

export const FilledWrapper = styled(Wrapper)`
    background: ${props => getBackgroundByProps(props)};
`;

export default Wrapper;
