import { createGlobalStyle } from 'styled-components';

const GlobalStyleTheme = createGlobalStyle`

      html {
         -webkit-overflow-scrolling: auto;
         height: 100%;
      }

      .noscroll {
          overflow: hidden;
        }

      * {
        font-family: 'Roboto Condensed', sans-serif;
        outline: none !important;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
      }

      *::focus {
            outline: none;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      }

      *::active {
            outline: none;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      }

      *::target {
            outline: none;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      }

      body {
        margin: 0px;
        padding: 0px;
        height: 100%;
        font-family: 'Roboto Condensed', sans-serif;
        position: relative;
      }
      
      #root {
        height: 100%;
      }
      
      .notification-container .notification {
          box-shadow: 0 0 12px rgba(0,0,0,0.2);
      }

      .carousel img {
          width: inherit !important;
      }
      
      [class^="icon-"], [class*=" icon-"] {
          /* use !important to prevent issues with browser extensions that change fonts */
          font-family: 'Icon Fonts', sans-serif !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
        
          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
    `;

export default GlobalStyleTheme;
