import React from 'react';
import { connect } from 'react-redux';
import InfiniteCalendar from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';
import Wrapper from 'ui/Wrapper';
import ScrollBar from 'ui/ScrollBar';
import { Section, SplitScreen } from 'ui/layouts/SplitScreen';
import Button from 'ui/controls/Button';
import { setDate } from './actions';
import moment from 'moment';
import { DATEPICKER_THEME_CONFIG } from 'utils/theme-helpers';
import daLocale from 'date-fns/locale/da';
import styled from 'styled-components';
import { setEditSelectionsInitializing } from 'features/AISDataProvider/actions';

const ThemeConfig = DATEPICKER_THEME_CONFIG;

export const DatePickerSection = styled(Section)`
    li.Cal__Day__root {
        height: 100%;
    }
    .Cal__Day__month {
        top: 11px;
    }
    .Cal__Today__root .Cal__Today__chevron {
        position: relative;
        top: 28%;
    }
`;

class DatePicker extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            date: new Date(),
        };
    }

    setDate = () => {
        if (!this.props.onDateSelected) {
            this.props.setEditSelectionsInitializing(false);
            this.props.setDate(this.state.date);

            this.props.hideModal('DATE_PICKER');
        } else {
            this.props.onDateSelected(this.state.date);
        }
    };

    onSelectHandler = (date) => {
        this.setState({ date: moment(date) });
    };

    componentDidMount() {
        if (!this.props.selectedDate) {
            this.setState({ date: this.props.date });
        } else {
            this.setState({ date: this.props.selectedDate });
        }
    }

    render() {
        const defaultMinDate = new Date(1980, 0, 1); // default infinite scroll value
        const defaultMaxDate = new Date(2050, 11, 31);
        const rangeConfigs = !this.props.selectedDate
            ? {
                  minDate: this.props.minDate
                      ? this.props.minDate
                      : new Date(moment().format()),
                  maxDate: this.props.maxDate
                      ? this.props.maxDate
                      : new Date(
                            moment()
                                .add(6, 'days')
                                .format()
                        ),
              }
            : {
                  minDate: this.props.minDate
                      ? this.props.minDate
                      : defaultMinDate,
                  maxDate: this.props.maxDate
                      ? this.props.maxDate
                      : defaultMaxDate,
              };

        let { width, height, modalData } = this.props;

        if (modalData) {
            if (modalData.width) {
                width = modalData.width;
            }
            if (modalData.height) {
                height = modalData.height;
            }
        }

        return (
            <SplitScreen>
                <DatePickerSection
                    white
                    height={height ? height + 'px' : '75%'}
                    width={width || window.innerWidth}
                >
                    <ScrollBar orientation="hidden">
                        <InfiniteCalendar
                            {...rangeConfigs}
                            selected={this.state.date}
                            onSelect={this.onSelectHandler}
                            theme={ThemeConfig}
                            width={width || window.innerWidth}
                            height={height || window.innerHeight - 205}
                            rowHeight={70}
                            locale={{
                                locale: daLocale,
                                blank: 'Vælg en dato ...',
                                headerFormat: 'ddd, MMM DD',
                                todayLabel: {
                                    long: 'I dag',
                                },
                                months: 'januar, februar, marts, april, maj, juni, juli, august, september, oktober, november, december'.split(
                                    ','
                                ),
                                monthsShort: 'jan,feb,mar2,apr2,maj,jun,jul,aug,sep,okt,nov,dec'.split(
                                    ','
                                ),
                                weekdays: [
                                    'Søn',
                                    'Man',
                                    'Tir',
                                    'Ons',
                                    'Tor',
                                    'Fre',
                                    'Lør',
                                ],
                                weekStartsOn: 0,
                            }}
                        />
                    </ScrollBar>
                </DatePickerSection>
                <Section grey height="10" align="center">
                    <Wrapper padding="0px" align="center">
                        <Button
                            onClick={this.setDate}
                            shadow="10"
                            size="x1"
                            width="100px"
                        >
                            OK
                        </Button>
                    </Wrapper>
                </Section>
            </SplitScreen>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        date: state.DatePicker.date,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setDate: (date) => {
            dispatch(setDate(date));
        },
        setEditSelectionsInitializing: (status) => {
            dispatch(setEditSelectionsInitializing(status));
        },
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DatePicker);
