import { PRODUCT_IDS, ProductNameValue, PRODUCTS_NAMES } from 'configs/products';
import { ProductId, RaceDay } from '../server/calendar';
import moment, { Moment } from 'moment/moment';

export const extractProductId = (productName: ProductNameValue): ProductId => {
    const productEntry = Object.entries(PRODUCTS_NAMES).find(([, name]) => name === productName);
    //@ts-ignore
    return productEntry ? productEntry[0] : PRODUCT_IDS.V;
};

export const extractRaceSchedule = (raceDays: RaceDay[]): Moment[] => {
    return raceDays
        .flatMap(raceDay =>
            raceDay.racesInfo.map(race => ({
                date: raceDay.raceDayDate,
                trackId: raceDay.trackId,
                postTimeUTC: race.postTimeUTC,
                raceNr: race.raceNr,
                canceled: race.canceled,
                hasResult: race.hasResult,
                raceStartMoment: moment.utc(race.postTimeUTC, 'HH:mm:ss'),
                pastRace: moment.utc(race.postTimeUTC, 'HH:mm:ss').isBefore(moment.utc()),
            }))
        )
        .filter(({ canceled, hasResult, pastRace }) => !canceled && !hasResult && !pastRace)
        .sort((raceA, raceB) => {
            return raceA.raceStartMoment > raceB.raceStartMoment ? 1 : -1;
        })
        .map(raceSchedule => raceSchedule.raceStartMoment);
};

export const getNextRaceNumber = (
    array: number[] = [],
    racesLength: number = 0,
    nextSaleOpeRaceNumber: number,
    defaultRaceNumber: number
) => {
    let nextRaceNumber = nextSaleOpeRaceNumber;

    while (array.includes(nextRaceNumber) && nextRaceNumber < racesLength) {
        nextRaceNumber++;
    }

    return array.includes(nextRaceNumber) ? defaultRaceNumber : nextRaceNumber;
};
