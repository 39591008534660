import React, { useState } from 'react';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import zipObject from 'lodash/zipObject';
// Warning! Sorting types asc/desc must stay same since they are used in lodash.
export const SORTING_TYPE_DISABLED = 'disabled';
export const SORTING_TYPE_ASC = 'asc';
export const SORTING_TYPE_DESC = 'desc';

const sortable = (
    WrappedComponent,
    columns = ['id'],
    sourcePropName = 'data',
    targetPropName = 'data',
    noDescendant = [],
    noAscendant = []
) => (props) => {
    const data = get(props, sourcePropName);

    const [columnSettings, updateColumnSettings] = useState(
        columns.map((c) => ({
            column: c,
            status: SORTING_TYPE_DISABLED,
            options: [
                SORTING_TYPE_DISABLED,
                noAscendant.includes(c) ? null : SORTING_TYPE_ASC,
                noDescendant.includes(c) ? null : SORTING_TYPE_DESC,
            ].filter((o) => o != null),
        }))
    );

    const getToggledColumnNames = () =>
        columnSettings
            .filter((cs) => cs.status !== SORTING_TYPE_DISABLED)
            .map((cs) => cs.column);

    const getToggledColumnStatuses = () =>
        columnSettings
            .filter((cs) => cs.status !== SORTING_TYPE_DISABLED)
            .map((cs) => cs.status);

    const sort = (data) => {
        const toggledColumns = getToggledColumnNames();
        // sorting will be disabled by default
        if (
            !toggledColumns.length ||
            columnSettings.every((cs) => cs.status === SORTING_TYPE_DISABLED)
        ) {
            return data;
        }
        return orderBy(data, toggledColumns, getToggledColumnStatuses());
    };

    const updateColumnStatus = (target, status) => {
        const withoutOldPreference = columnSettings.filter(
            (cp) => cp.column !== target.column
        );
        return [
            { column: target.column, status, options: target.options },
            ...withoutOldPreference,
        ];
    };

    const toggleSortingHandler = (columnToSort) => {
        const setting = columnSettings.find((statusItem) => {
            return statusItem.column === columnToSort;
        });

        const currentStatusIndex = setting.options.indexOf(setting.status);
        const nextStatusIndex =
            currentStatusIndex + 1 === setting.options.length
                ? 0
                : currentStatusIndex + 1;

        return updateColumnSettings(
            updateColumnStatus(setting, setting.options[nextStatusIndex])
        );
    };

    return (
        <WrappedComponent
            {...props}
            {...{ [targetPropName]: sort(data) }}
            sort={sort}
            toggleSortingHandler={toggleSortingHandler}
            sortingStatuses={zipObject(
                getToggledColumnNames(),
                getToggledColumnStatuses()
            )}
        />
    );
};

export default sortable;
