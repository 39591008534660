import React from 'react';
import Countdown from 'ui/Countdown';

import { hasResultByRaceNr, RaceDay } from 'features/EventBoard/server/calendar';
import useServerTimeQuery from 'features/EventBoard/hooks/useServerTimeQuery';

import { AnyRace } from 'features/TrackPage/model/Race';

interface ClockProps {
    raceDay: RaceDay;
    race: AnyRace;
}

const Clock = ({ raceDay, race }: ClockProps) => {
    const startDateTime = raceDay.raceDayDate + ' ' + race.postTime;
    const startDateTimeUTC = raceDay.raceDayDate + ' ' + race.postTimeUTC;
    const { serverTime } = useServerTimeQuery();

    const hasResult = hasResultByRaceNr(raceDay, race.raceNumber);

    return (
        <time>
            <b className="text-bold">
                {race.postTimeUTC === null ? (
                    '--:--:--'
                ) : hasResult ? null : (
                    <Countdown
                        time={startDateTime}
                        timeUTC={startDateTimeUTC}
                        serverTime={serverTime}
                        withDay
                    />
                )}
            </b>
        </time>
    );
};

export default Clock;
