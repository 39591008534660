import React, { Fragment } from 'react';
import styled from 'styled-components';
import BaseIcon from 'ui/Icon';
import { bottomBarStyles } from './index';
import { Link } from 'react-router-dom';
import { COLORS } from '../../themes';

const StyledNav = styled.div`
    display: flex;
    justify-content: space-around;
    ${bottomBarStyles};
    color: ${COLORS.black};
    top: -52px;
    width: 110%;
    margin-left: -5%;
`;

const NavItem = styled.span`
    height: 100%;
    margin: 0 auto;
    font-size: 16px;
    font-weight: 700;
`;

const Icon = styled(BaseIcon)`
    margin-right: 7px;
    filter: brightness(0%);
`;

const VerticalDivider = styled.span`
    height: ${({ height }) => height || '100%'};
    border: 0.5px solid #f4f4f4;
`;

const Nav = (props) => {
    const { items } = props;
    return (
        <StyledNav>
            {items.map((i, index) => {
                return (
                    <Fragment key={index}>
                        <NavItem onClick={i.onClick}>
                            <Icon size="x1" alt={i.title} src={i.icon} />
                            {i.link ? (
                                <Link to={i.link} className="reset-link">
                                    {i.title}
                                </Link>
                            ) : (
                                i.title
                            )}
                        </NavItem>
                        {index !== items.length - 1 && (
                            <VerticalDivider height="97%" />
                        )}
                    </Fragment>
                );
            })}
        </StyledNav>
    );
};

export default Nav;
