import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { disablePin, logout, toggleNewsletterSubscription } from 'common/actions/authActions';
import { isPinSet } from 'common/selectors/auth';
import NotificationConductor from 'common/conductors/NotificationConductor';
import { showModal } from 'common/actions/uiActions';

import getTexts from 'utils/localization';
import { history, openLogin, hidePopup } from 'utils/navigation';

import { LENGTH_SHORT } from 'configs/main';
import ModalContent from 'ui/ModalContent';
import Menu from './Menu';

import { getConfigByUrls } from 'configs/user-area';
import { withRouter } from 'react-router-dom';
import CloseBtn from 'layouts/sections/Modals/CloseBtn';

const t = getTexts();

class Container extends Component {
    constructor() {
        super();
        this.state = {
            balanceChanged: false,
            hiding: false,
        };
    }

    previousPathname = null;

    componentDidMount() {
        const path = history.location.pathname.replace('/', '');
        const userAreaScreensConfig = getConfigByUrls();
        const modalId = userAreaScreensConfig[path]?.modalId?.mobile;

        if (modalId) {
            this.props.showModal(modalId, 2);
        }

        this.previousPathname = history.location.pathname;
    }

    animateHide = () => {
        this.setState({ hiding: true });
    };

    closePopup = () => {
        if (this.state.hiding) {
            hidePopup('USER_AREA_MOBILE');
        }
    };

    componentDidUpdate(prevProps) {
        if (!this.props.authPending && !this.props.user) {
            this.animateHide();
            this.props.showLoginModal();
        } else if (
            prevProps.user &&
            this.props.user &&
            prevProps.user.balance !== this.props.user.balance
        ) {
            this.setState({ balanceChanged: true });
        }
    }

    togglePin = checked => {
        const { showModal, disablePin } = this.props;
        checked ? showModal('PIN_SETTING', 1) : disablePin();
    };

    toggleNewsletterSubscription = () => {
        const { user, toggleNewsletterSubscription } = this.props;
        toggleNewsletterSubscription(user)
            .then(() =>
                this.props.showModal(
                    'STATUS_OF_ACTION',
                    2,
                    {
                        success: true,
                        text: user.hasNewsletterSubscription
                            ? t.userArea.newsletterUnsubscribed
                            : t.userArea.newsletterSubscribed,
                    },
                    LENGTH_SHORT
                )
            )
            .catch(err => NotificationConductor.error(err.toString()));
    };

    render() {
        const { user, isPinSet, showModal, logout, priority } = this.props;
        const { balanceChanged } = this.state;

        if (!user) {
            return null;
        }

        return (
            <ModalContent
                priority={priority}
                className={classNames('modal-fade', { 'modal-fade-out': this.state.hiding })}
                onAnimationEnd={this.closePopup}
            >
                <CloseBtn iclosewhite size="x05" onClick={this.animateHide} />
                <Menu
                    balanceChanged={balanceChanged}
                    user={user}
                    isPinSet={isPinSet}
                    togglePin={this.togglePin}
                    showModal={showModal}
                    logout={logout}
                    toggleNewsletterSubscription={this.toggleNewsletterSubscription}
                />
            </ModalContent>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        authPending: state.auth.pending,
        isPinSet: isPinSet(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        logout: () => {
            dispatch(logout());
        },
        showLoginModal: () => {
            dispatch(openLogin());
        },
        disablePin: () => {
            dispatch(disablePin());
        },
        showModal: (modalId, priority, data) => {
            dispatch(showModal(modalId, priority, data));
        },
        toggleNewsletterSubscription: user => {
            return dispatch(toggleNewsletterSubscription(user));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Container));
