import React from 'react';
import { TransactionDetails as TransactionDetailsComp } from '@it25syv/25syv-ui';
import { hidePopup } from 'utils/navigation';
import { Overlay } from 'ui/Popup';
import Modal from 'ui/ModalContent';

const TransactionDetails = props => {
    const hideSelf = () => {
        hidePopup('TRANSACTION_INFO');
    };

    return (
        <Modal>
            <Overlay onClick={hideSelf}>
                <TransactionDetailsComp data={props} onClose={hideSelf} />
            </Overlay>
        </Modal>
    );
};

export default TransactionDetails;
