import React from 'react';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

import UserSession from 'common/adapters/UserSession';
import { IS_TERMINAL } from 'utils/platforms';
import { StyledWrapper, StyledText, StyledButton } from './styled';
import getTexts from 'utils/localization';
import { combinationsCountBy } from 'common/selectors/combinationsCountSelector';
import useTrackPage from 'features/TrackPage/hooks/useTrackPage';

const t = getTexts();

const PlayAndPay = ({ performSale, conditionToShow, races, styles }) => {
    const shopConfig = useSelector((state) => state.ShopConfig);

    const { productId, raceDay, race } = useTrackPage();

    const combinationsCount = useSelector((state) =>
        combinationsCountBy(state, {
            productId,
            date: raceDay.raceDayDate,
            trackCode: raceDay.track.code,
            raceIndex: race.index,
            races,
        })
    );
    const proceedPlayAndPlay = () => {
        performSale();
    };

    const buttonShouldBeShown = () => {
        const playAndPayAllowed = get(shopConfig, 'data.playAndPayAllowed', null);

        const allowedOnTerminal =
            IS_TERMINAL &&
            playAndPayAllowed &&
            UserSession.isLowAmount({}) &&
            !UserSession.isManagerEntrance() &&
            combinationsCount > 0;

        return allowedOnTerminal && conditionToShow;
    };

    return (
        buttonShouldBeShown() && (
            <StyledWrapper styles={styles}>
                <StyledButton onClick={proceedPlayAndPlay} styles={styles}>
                    <StyledText>{t.Terminal.PlayAndPay.button}</StyledText>
                </StyledButton>
            </StyledWrapper>
        )
    );
};

export default PlayAndPay;
