import Product, { ProductList } from 'common/DataObjects/Product';
import RaceDay from 'common/DataObjects/RaceDay';
import { DEFAULT_SELECTED_PRODUCT, HIDDEN_PRODUCTS, PRODUCTS_SHORTCUTS } from 'configs/products';
import moment from 'moment';
import { getTrack } from 'common/selectors/trackSelector';
import { root } from 'common/selectors/raceDaySelector';

/**
 * @param  {Object}  state
 * @return {Product}
 */
export default function productSelector(state) {
    const raceDayData = state.AISDataProvider.selectedRaceDay;

    if (!raceDayData) {
        return Product.fill(DEFAULT_SELECTED_PRODUCT);
    }
    const raceDay = new RaceDay(raceDayData);
    const { id, name, priority, color } = state.AISDataProvider.selectedProduct;
    const isMultitrack = raceDay.hasMultitrackSetups(state.AISDataProvider.selectedProduct.id);
    return new Product().setAll(id, name, priority, color, isMultitrack);
}

/**
 * @TODO Should return Product instances
 * @param  {Object} state
 * @return {Array}
 */
export function getProductsFromSelectedTrack(state) {
    if (
        !state.AISDataProvider.selectedRaceDay ||
        !state.AISDataProvider.selectedRaceDay.date // AISDataProvider/ShoeInfo [getDefaultRaceDay] pushes empty object
        // Date property check is needed to ensure that selectedRaceDay is not an empty object
        // @see https://ecosys.atlassian.net/browse/DER-1161, V86 multitrack case
    ) {
        return [];
    }

    const trackId = getTrack(state).id;

    const fullProductList = state.AISDataProvider.selectedRaceDay.fullProductList;

    return ProductList.getProductsFromIds(fullProductList).filter(product => {
        const isProductAvailable = fullProductList.includes(product.id);

        return isProductAvailable && !shouldProductBeHidden(product, trackId);
    });
}
/**
 * Returns Product Id / RaceDay record of the existing products for the current race day.
 * The keys are product ids and the values are appropriate race days
 * @param  {Object} state
 * @return {Array.<number>}
 */
function getProductRaceDaysFromCalendar(state) {
    if (!state.AISDataProvider.raceDayData) {
        return [];
    }

    const availableProducts = {};

    state.AISDataProvider.raceDayData.forEach(raceDay => {
        raceDay.products.forEach(product => {
            const noDuplicate = !availableProducts.hasOwnProperty(product.id);

            if (noDuplicate && !shouldProductBeHidden(product, raceDay.track.id)) {
                availableProducts[product.id] = raceDay;
            }
        });
    });

    return availableProducts;
}
/**
 * Returns IDS of the existing products for the current race day.
 * @param  {Object} state
 * @return Object}  the keys are the product ids and the values are the string dates
 */
export function getProductDatesFromCalendar(state) {
    if (!state.AISDataProvider.raceDayData) {
        return {};
    }

    const productDates = {};

    state.AISDataProvider.raceDayData.forEach(raceDay =>
        raceDay.products.forEach(product => {
            if (!productDates[product.id]) {
                productDates[product.id] = raceDay.date;
            }
        })
    );

    return productDates;
}

export function getShortcutProducts(state) {
    const productRecords = getProductRaceDaysFromCalendar(state);

    return addDateInformation(
        state,
        ProductList.getAll()
            .filter(
                product =>
                    PRODUCTS_SHORTCUTS.includes(product.id) &&
                    productRecords.hasOwnProperty(product.id)
            )
            .map(product => ({ ...product, raceDay: productRecords[product.id] }))
    ).sort((a, b) => (a.priority > b.priority ? 1 : -1));
}

export function addDateInformation(state, products) {
    const productDates = getProductDatesFromCalendar(state);

    return products.map(product => {
        return Product.fill({
            ...product,
            date: productDates[product.id],
            today: productDates[product.id] === moment().format('YYYY-MM-DD'),
        });
    });
}

export function shouldProductBeHidden(product, trackId) {
    return HIDDEN_PRODUCTS[trackId] && HIDDEN_PRODUCTS[trackId].includes(product.id);
}

export function isVProduct(state) {
    return productSelector(state).isVProduct();
}

export function isStrictVProduct(state) {
    return productSelector(state).isStrictVProduct();
}

export function isProductShortcutSelected(state) {
    return root(state).productShortcut;
}

export function getRawProduct(state) {
    return root(state).selectedProduct;
}
