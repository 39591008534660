import React from 'react';
import ModalsHandler from './ModalsHandler';
import { ModalsMapConfig } from 'configs/modals';
import ModalContainer from './ModalContainer';

class Modals extends React.Component {
    render() {
        return (
            <ModalsHandler modalsMap={ModalsMapConfig}>
                {(modalProps) => <ModalContainer {...modalProps} />}
            </ModalsHandler>
        );
    }
}

export default Modals;
