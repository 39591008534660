import React from 'react';
import FlexWrapper from 'ui/FlexWrapper';
import Wrapper from 'ui/Wrapper';
import Text from 'ui/Text';
import Button from 'ui/controls/Button';
import mehIcon from './assets/meh.png';
import mmmIcon from './assets/mmm.png';
import niceIcon from './assets/nice.png';
import pffIcon from './assets/pff.png';
import wauwIcon from './assets/wauw.png';
import SendBar from 'layouts/sections/Mobile/Feedback/SendBar';
import getTexts from 'utils/localization';
const t = getTexts();

const raiting = {
    wauw: 5,
    nice: 4,
    mmm: 3,
    meh: 2,
    pff: 1,
};

class Options extends React.Component {
    constructor() {
        super();

        this.state = {
            textMode: false,
            thxMode: false,
        };
    }

    getButtonView(label, icon) {
        return (
            <Button
                grey
                size="x1"
                block
                padding="10px"
                rounded="10"
                margin="5px"
                onClick={() => this.provideFeedback(label)}
            >
                {t.Feedback[label]}{' '}
                <img
                    width="16px"
                    src={icon}
                    alt={label}
                    style={{
                        verticalAlign: 'middle',
                    }}
                />
            </Button>
        );
    }

    provideFeedback = (key) => {
        if (key !== 'pff') {
            this.sendFeedback(raiting[key], '');
        } else {
            this.setState({
                textMode: true,
            });
        }
    };

    sendFeedback = (val, text) => {
        this.setState({ thxMode: true });

        this.props.onComplete(val, text);
    };

    render() {
        return !this.state.thxMode ? (
            <FlexWrapper margin="0px" padding="0px">
                <Text size="x1" bold align="center">
                    {!this.state.textMode ? t.Feedback.question : t.Feedback.label}
                </Text>

                {!this.state.textMode ? (
                    <Wrapper>
                        <FlexWrapper height="40px" direction="row" margin="0px" padding="0px">
                            {this.getButtonView('wauw', wauwIcon)}
                            {this.getButtonView('nice', niceIcon)}
                            {this.getButtonView('mmm', mmmIcon)}
                        </FlexWrapper>
                        <FlexWrapper height="40px" direction="row" margin="0px" padding="0px">
                            {this.getButtonView('meh', mehIcon)}
                            {this.getButtonView('pff', pffIcon)}
                        </FlexWrapper>
                    </Wrapper>
                ) : (
                    <FlexWrapper height="120px" direction="column" margin="0px" padding="0px">
                        <FlexWrapper height="40px" direction="row" margin="0px" padding="0px">
                            <SendBar
                                placeholder={t.Feedback.writeHere}
                                onSendHandler={this.sendFeedback.bind(this, 1)}
                            />
                        </FlexWrapper>

                        <br />
                        <br />

                        <Text size="x1" align="center" bold onClick={() => this.sendFeedback(1)}>
                            Nej tak
                        </Text>
                    </FlexWrapper>
                )}
            </FlexWrapper>
        ) : (
            <FlexWrapper margin="0px" padding="0px">
                <Text size="x2" align="center" bold>
                    {t.Feedback.thanks}
                </Text>
            </FlexWrapper>
        );
    }
}

export default Options;
