import { DOMAIN_PATH } from 'configs/main';

export default class Highlight {
    id = null;
    area = null;
    showButton = null;
    type = null;
    buttonText = null;
    linkTo = null;
    image = null;
    landscapeImage = null;
    video = null;
    title = null;
    subTitle = null;
    terms = null;
    clickHandler = () => {};

    static unserialize(data) {
        const highlight = new Highlight();

        try {
            highlight.id = data.id;
            highlight.area = data.area;
            highlight.showButton = data.show_button;
            highlight.type = data.type;
            highlight.buttonText = data.button_text;
            highlight.linkTo = data.link_to;
            highlight.image = data.image ? DOMAIN_PATH + data.image : '';
            highlight.landscapeImage = data.landscape_image
                ? DOMAIN_PATH + data.landscape_image
                : '';
            highlight.video = data.video;
            highlight.title = data.title;
            highlight.subTitle = data.sub_title;
            highlight.terms = data.term_text;
        } catch (error) {
            //@TODO Logger of errors
        }

        return highlight;
    }
}
