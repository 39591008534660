import styled, { css } from 'styled-components';
import Wrapper from 'ui/Wrapper';

export const QuickPickWrapper = styled.div`
    width: auto;
    height: ${({ height, small }) => (height ? `${height}` : !small ? '110px' : '96px')};
    border-radius: 5px;
    background: ${(props) => props.bg};
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: ${({ grow }) => (grow ? 1 : 0)};
    flex-basis: 300px;
    ${({ paddingBottom }) => paddingBottom && `padding-bottom: ${paddingBottom}px`};
    ${({ singleItem }) =>
        singleItem &&
        css`
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 18px 20px;
        `}
`;

export const TitleWrapper = styled(Wrapper)`
    flex-direction: column;
    align-items: start;
    ${({ singleItem }) =>
        singleItem &&
        css`
            flex: 1 0 45%;
            padding: 0;
        `}
`;

export const ButtonWrapper = styled(Wrapper)`
    ${({ singleItem }) =>
        singleItem &&
        css`
            flex: 1 0 auto;
        `}
`;

const preloaderCSS = css`
    border-radius: 3px;
    background: rgba(1, 1, 1, 0.1);
`;

export const PreloaderTitleRow = styled.div`
    ${preloaderCSS};
    width: 150px;
    height: 18px;
    margin-bottom: 5px;
`;

export const PreloaderSmallerRow = styled.div`
    ${preloaderCSS};
    width: 130px;
    height: 14px;
`;

export const PreloaderButtonRow = styled.div`
    ${preloaderCSS};
    width: 130px;
    height: 14px;
    margin: 0 auto;
`;
