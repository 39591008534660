import moment from 'moment';
import * as RaceNavigation from 'ui/RaceNavigation';
import React from 'react';
import { showModal } from 'common/actions/uiActions';
import { forDesktop } from 'utils/platforms';
import { setDate } from 'ui/DatePicker/actions';
import { setProduct, setRaceDay } from 'features/AISDataProvider/actions';
import { connect } from 'react-redux';
import { getRaceDayBy } from 'common/selectors/raceDaySelector';
import { DEFAULT_SELECTED_PRODUCT } from 'configs/products.ts';

export default class RaceCalendar extends React.Component {
    getCurrentMomentDate = () => {
        return typeof this.props.selectedDate === 'object'
            ? moment.isMoment(this.props.selectedDate)
                ? this.props.selectedDate
                : moment()
            : moment(this.props.selectedDate);
    };

    selectDate = (mDate) => {
        this.props.setDate(mDate);

        const nextRaceDay = this.props.getRaceDayByDate(mDate.format('YYYY-MM-DD'));

        this.props.setRaceDay(nextRaceDay);
        this.props.setProduct(DEFAULT_SELECTED_PRODUCT);
    };

    selectPrevDate = () => {
        this.selectDate(this.getCurrentMomentDate().subtract(1, 'days'));
    };

    selectNextDate = () => {
        this.selectDate(this.getCurrentMomentDate().add(1, 'days'));
    };

    render() {
        const isPreviousDateAvailable = this.getCurrentMomentDate().isAfter(moment(), 'day');
        const isNextDateAvailable = this.getCurrentMomentDate().isBefore(moment().add(5, 'days'));
        return (
            <RaceNavigation.CalendarControls
                mCurrentDate={this.getCurrentMomentDate()}
                showDatePickerModal={this.props.showDatePickerModal}
                selectNextDate={this.selectNextDate}
                selectPrevDate={this.selectPrevDate}
                isPrevDateAvailable={isPreviousDateAvailable}
                isNextDateAvailable={isNextDateAvailable}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    selectedDate: state.DatePicker.date,
    getRaceDayByDate: (date) => getRaceDayBy(state, { date }),
});

const mapDispatchToProps = (dispatch) => {
    return {
        showDatePickerModal: () => {
            dispatch(showModal('DATE_PICKER', 1, forDesktop({ width: 500, height: 405 })));
        },
        setDate: (date) => {
            dispatch(setDate(date));
        },
        setRaceDay: (raceDay) => {
            dispatch(setRaceDay(raceDay));
        },
        setProduct: (product) => {
            dispatch(setProduct(product));
        },
    };
};

export const HomepageRaceCalendar = connect(mapStateToProps, mapDispatchToProps)(RaceCalendar);
