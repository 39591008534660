import styled from 'styled-components';
import Headline from 'ui/Headline';
import { PRODUCTS_IMAGES } from 'configs/products';
import { Card } from 'ui/Card';
import { COLORS } from 'themes';
import Wrapper from 'ui/Wrapper';
import UIText from 'ui/Text';
import Button from 'ui/controls/Button';

import iGS75 from './assets/GS75.png';
import iV4 from './assets/V4.png';
import iV5 from './assets/V5.png';
import iV64 from './assets/V64.png';
import iV65 from './assets/V65.png';
import iV75 from './assets/V75.png';
import iV86 from './assets/V86.png';
import { byPlatform } from 'utils/platforms';

const Text = styled(UIText).attrs({ as: 'div' })``;

const TextBlockWithTable = styled(Text)`
    & table {
        width: auto !important;
    }

    & p {
        margin: ${byPlatform('5px', 'inherit')} 0;
        line-height: ${byPlatform('18px', 'inherit')};
    }

    & iframe {
        max-width: 100%;
        max-height: ${byPlatform('400px', 'auto')};
    }
`;

const TAG_ICONS = {
    GS75: iGS75,
    V4: iV4,
    V5: iV5,
    V64: iV64,
    V65: iV65,
    V75: iV75,
    V86: iV86,
};

const StyledArticleWrapper = styled(Wrapper)`
    padding: 50px;
`;

const StyledHeadline = styled(Headline)`
    font-size: 24px;
    color: ${COLORS.black};
`;

const Author = styled(Text)`
    padding: 0;
    font-weight: 500;
    font-size: 14px;
`;

const DateText = styled(Text)`
    padding: 0;
    font-size: 12px;
`;

const ShareArticleWrapper = styled(Wrapper)`
    padding: 30px 0;
    display: inline-flex;
`;

const ShareArticleText = styled(Text)`
    padding: 5px;
    font-weight: 500;
    font-size: 14px;
`;

const StyledCard = styled(Card)`
    display: flex;
    align-items: center;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
    margin-top: ${(props) => (props.cardStyles.marginTop ? props.cardStyles.marginTop : '10px')};
    border-radius: 15px;
    position: relative;
    &:before {
        content: '';
        background: ${(props) =>
            TAG_ICONS[props.productId]
                ? 'url("' + TAG_ICONS[props.productId] + '")'
                : PRODUCTS_IMAGES[props.productId]};
        background-size: cover;
        position: absolute;
        width: 75px;
        height: 28px;
        color: ${COLORS.white};
        font-weight: bold;
        border-radius: 4px;
        text-align: center;
        bottom: 10px;
        font-size: 18px;
        line-height: 28px;
        display: block;
        background-position: center center;
    }
`;

const StyledAuthorImg = styled.img`
    margin: 10px;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    float: left;
`;

const StyledCardImg = styled.div`
    background: url(${(props) => props.imgUrl});
    height: 330px;
    width: 100%;
    background-position: center;
    background-size: cover;
    ${byPlatform(
        () =>
            `@media screen and (min-width: 560px) {
                 min-height: 275px !important;
            }`
    )};
`;

const StyledAuthorContainer = styled.div`
    display: flex;
    align-items: center;
`;

const StyledIcon = styled.img`
    width: 25px;
    height: 25px;
    margin-left: 5px;
`;

const StyledButton = styled(Button)`
    display: block;
    margin: 0 auto;
    width: 130px;
    height: 40px;
    font-size: 16px;
    text-align: center;
    border-radius: 15px;
`;

export {
    TAG_ICONS,
    StyledArticleWrapper,
    StyledHeadline,
    Author,
    DateText,
    ShareArticleWrapper,
    ShareArticleText,
    StyledCard,
    StyledAuthorImg,
    StyledCardImg,
    StyledAuthorContainer,
    StyledIcon,
    StyledButton,
    Text,
    TextBlockWithTable,
};
