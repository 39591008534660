import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import rodLogo from 'images/rod.png';

import PromoHeader from 'layouts/Promo/Components/PromoHeader';
import PromoLoader from 'layouts/Promo/Components/PromoLoader';
import PromoContent from 'layouts/Promo/Components/PromoContent';
import PromoFooter from 'layouts/Promo/Components/PromoFooter';

const PromoWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100vw;
    background-color: white;
    -webkit-overflow-scrolling: auto;
    outline: none !important;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box; /* Firefox, other Gecko */
    box-sizing: border-box;
    & * {
        box-sizing: border-box;
    }
`;
const ContentWrapper = styled.div`
    flex: 1 0 auto;
    box-sizing: border-box;
`;
const Container = styled.div`
    width: 100%;
    min-height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 0;
    position: relative;
    z-index: 0;
    box-sizing: border-box;
`;

const projectData = {
    loginLink: '/',
    signLink: '/opret-bruger',
    projectName: 'Bet25 Heste',
    logo: rodLogo,
    color: 'rgb(236, 64, 60)',
};

const PromoPage = () => {
    const [items, setItems] = useState([]);
    const [pending, setPending] = useState(false);

    const imgHost = 'https://cms.bet25.dk';

    const signUp = () => (window.location.pathname = '/opret-konto');

    const buildRequestUrl = () => {
        const urlParam = window.location.pathname.split('/').slice(-1);
        const host = 'cms.bet25.dk';
        const hostName = 'derby';

        return `https://${host}/rest/landing-page/get-page?url=${urlParam}&hostName=${hostName}_`;
    };

    useEffect(() => {
        fetch(buildRequestUrl())
            .then((res) => res.json())
            .then(
                (result) => {
                    if (result.success) {
                        setItems(result.data);
                        setInterval(() => setPending(false), 500);
                    } else {
                        window.location = '/404';
                    }
                },
                (error) => {
                    console.error(error);
                }
            );
    }, []);

    return (
        <PromoWrapper>
            {pending ? (
                <PromoLoader />
            ) : (
                <ContentWrapper>
                    <PromoHeader
                        defaultLogo={projectData.logo}
                        logo={items.logo}
                        projectName={projectData.projectName}
                        imgHost={imgHost}
                        signUp={signUp}
                    />
                    <Container
                        style={{
                            backgroundImage: 'url(' + imgHost + items.background_image + ')',
                        }}
                    >
                        <PromoContent items={items} signUp={signUp} />
                    </Container>
                    <PromoFooter
                        projectName={projectData.projectName}
                        items={items}
                        imgHost={imgHost}
                        logo={projectData.logo}
                    />
                </ContentWrapper>
            )}
        </PromoWrapper>
    );
};

export default PromoPage;
