import {
    AUTH_PENDING,
    CLEAN_ERROR_MESSAGE,
    GET_USER_ERROR,
    GET_USER_PENDING,
    GET_USER_SUCCESS,
    LOGIN_ERROR,
    LOGIN_PENDING,
    LOGIN_SUCCESS,
    LOGOUT,
    PIN_LOGIN_ERROR,
    PIN_LOGIN_PENDING,
    PIN_LOGIN_REDUCE_ATTEMPTS,
    PIN_LOGIN_SUCCESS,
    SESSION_ERROR,
    SESSION_PENDING,
    SESSION_SUCCESS,
    SET_PIN_ERROR,
    SET_PIN_PENDING,
    SET_PIN_SUCCESS,
    SIGNUP_SUCCESS,
    TOGGLE_NEWS_LETTER_SUBSCRIPTIONS_PENDING,
    TOGGLE_NEWS_LETTER_SUBSCRIPTIONS_SUCCESS,
    TOGGLE_NEWS_LETTER_SUBSCRIPTIONS_ERROR,
    RESET_PIN_PENDING,
    RESET_PIN_SUCCESS,
    RESET_PIN_ERROR,
    SET_TEMP_USER_STATUS,
    SET_RETAIL_PIN,
} from '../actions/authActions';
import persistentStorage from 'common/storage';
import { intercomBoot, intercomLastLoginUpdate, intercomUpdate } from 'utils/trackers';
import { IS_TERMINAL } from 'utils/platforms';
import { parseNullString } from 'utils/object-helper';

const initialState = {
    pending: false,
    finished: false,
    user: null,
    isTemp: false,
    error: null,
    login: {
        pending: false,
        success: false,
        error: null,
        initiated: false, // determines if the login call to server was ever called
    },
    pin: {
        hash: parseNullString(persistentStorage.getLS('pinHash')),
        userName: persistentStorage.getLS('pinUserName'),
        error: null,
        pending: false,
    },
    resetPin: {
        error: null,
        pending: false,
    },
    newsletterSubscriptionPending: false,
    newsletterSubscriptionError: '',
};

const auth = (state = initialState, action) => {
    switch (action.type) {
        case SIGNUP_SUCCESS:
            return {
                ...state,
                user: action.payload,
            };
        case GET_USER_SUCCESS:
            return {
                ...state,
                user: action.payload,
            };
        case GET_USER_PENDING:
        case SESSION_PENDING:
        case AUTH_PENDING:
            return {
                ...state,
                pending: action.payload,
                finished: !action.payload,
                error: null,
                login: {
                    ...state.login,
                    initiated: true,
                },
            };
        case LOGIN_PENDING:
            return {
                ...state,
                pending: true,
                error: null,
                login: {
                    pending: true,
                    error: null,
                    success: false,
                    initiated: true,
                },
            };
        case LOGIN_SUCCESS:
            window.intercomSettings = {
                app_id: window.APP_ID,
                name: action.payload.firstName + ' ' + action.payload.lastName,
                email: action.payload.email,
            };

            if (!IS_TERMINAL) {
                intercomBoot();
                intercomUpdate(action.payload);
                intercomLastLoginUpdate();
            }

            return {
                ...state,
                pending: false,
                user: action.payload,
                finished: true,
                error: null,
                login: {
                    pending: false,
                    error: null,
                    success: true,
                    initiated: true,
                },
            };
        case SESSION_SUCCESS:
            return {
                ...state,
                pending: false,
                user: action.payload,
                finished: true,
                error: null,
                login: {
                    pending: false,
                    error: null,
                    success: true,
                    initiated: true,
                },
            };
        case SESSION_ERROR:
        case GET_USER_ERROR:
        case LOGIN_ERROR:
            return {
                ...state,
                pending: false,
                finished: false,
                user: null,
                error: action.payload,
                login: {
                    pending: false,
                    error: action.payload,
                    success: false,
                    initiated: true,
                },
            };
        case CLEAN_ERROR_MESSAGE:
            return {
                ...state,
                error: null,
            };
        case LOGOUT:
            return {
                ...initialState,
                pin: state.pin,
            };
        case SET_PIN_SUCCESS:
            return {
                ...state,
                pin: { ...state.pin, ...action.payload },
            };
        case SET_PIN_PENDING:
            return { ...state, pin: { ...state.pin, pending: true } };
        case SET_PIN_ERROR:
            return { ...state, pin: action.payload };
        case PIN_LOGIN_SUCCESS:
            return {
                ...state,
                pin: {
                    ...state.pin,
                    ...action.payload,
                    pending: false,
                },
            };
        case PIN_LOGIN_REDUCE_ATTEMPTS:
            return {
                ...state,
                pin: {
                    ...state.pin,
                    ...action.payload,
                },
            };
        case PIN_LOGIN_PENDING:
            return {
                ...state,
                pin: { ...state.pin, pending: true, error: null },
            };
        case PIN_LOGIN_ERROR:
            return {
                ...state,
                pin: { ...state.pin, error: action.payload, pending: false },
            };
        case TOGGLE_NEWS_LETTER_SUBSCRIPTIONS_PENDING:
            return {
                ...state,
                newsletterSubscriptionPending: true,
            };
        case TOGGLE_NEWS_LETTER_SUBSCRIPTIONS_SUCCESS:
            return {
                ...state,
                user: {
                    ...state.user,
                    hasNewsletterSubscription: !state.user.hasNewsletterSubscription,
                },
                newsletterSubscriptionPending: false,
            };
        case TOGGLE_NEWS_LETTER_SUBSCRIPTIONS_ERROR:
            return {
                ...state,
                newsletterSubscriptionPending: false,
                newsletterSubscriptionError: action.payload,
            };
        case RESET_PIN_PENDING:
            return {
                ...state,
                resetPin: { pending: true, error: null },
            };
        case RESET_PIN_ERROR:
            return {
                ...state,
                resetPin: { pending: false, error: action.payload },
            };
        case RESET_PIN_SUCCESS:
            return {
                ...state,
                resetPin: initialState.resetPin,
                pin: {
                    hash: '',
                    userName: '',
                    error: null,
                    pending: false,
                },
            };
        case SET_TEMP_USER_STATUS:
            return {
                ...state,
                isTemp: action.payload,
            };
        case SET_RETAIL_PIN:
            return {
                ...state,
                user: {
                    ...state.user,
                    retailPin: action.payload,
                    isRetailPinSet: true,
                },
            };
        default:
            return { ...state };
    }
};

export default auth;
