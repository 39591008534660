import { GroupCreationState } from './reducer';
import Group, { createNullGroup, createNullGroupMember, GroupMember } from './model/Group';
import { Subscription } from './model/Subscription';
import Results from './model/Results';
import { createNullPoolExtendedInfo, PoolExtendedInfoOutput } from './server';
import Pool, { isPoolActive } from './model/Pool';
import Product, { createById } from 'common/DataObjects/Product';
import RaceDay from 'common/DataObjects/RaceDay';
import moment from 'moment';
import Track from 'common/DataObjects/Track';
import { getRaceDayBy } from 'common/selectors/raceDaySelector';
import { getAuth } from 'common/selectors/auth';
import CouponDraft, { createNullCouponDraft } from './model/CouponDraft';
import { deserializeCouponDraft } from './serializing';

/**
 * @return pending
 * @return error message
 * @return success
 */
export declare type Status = [boolean, string, boolean];

export const getGroupCreation = (state: any): GroupCreationState => state.BetBuddy.createGroup;

export const getGroups = (state: any): Group[] => Object.values(state.BetBuddy.groups.data);

export const getPublicGroups = (state: any): Group[] =>
    Object.values(state.BetBuddy.publicGroups.data);

export const getGroupsCreatedByUser = (state: any): Group[] =>
    Object.values(state.BetBuddy.groupsCreatedByUser.data);

export const getFilteredGroups = (state: any): Group[] => state.BetBuddy.filteredGroups;

//@TODO investigate why returning null object leads to recursive updates in PoolDashboard
export const getGroupById = (state: any, groupId: number): Group =>
    state.BetBuddy.groups.data[groupId] || createNullGroup();

export const getGroupCaptainById = (state: any, groupId: number): GroupMember => {
    const group = getGroupById(state, groupId);
    if (!group || state.BetBuddy.groupMembers.data.length === 0) {
        return createNullGroupMember();
    }
    return state.BetBuddy.groupMembers.data[group.ownerId];
};

export const getStatus = (state: any, statusKey: string): Status => [
    state.BetBuddy.statuses[statusKey].pending,
    state.BetBuddy.statuses[statusKey].error,
    state.BetBuddy.statuses[statusKey].success,
];

export const getPools = (state: any): Pool[] => Object.values(state.BetBuddy.pools.data);

export const getActiveSubscriptions = (state: any): Subscription[] =>
    Object.values(state.BetBuddy.subscriptions) || [];

export const getActivePoolsByGroupId = (state: any, groupId: number): Pool[] =>
    getPools(state).filter(pool => pool.groupId === groupId && isPoolActive(pool));

export const getFinishedPoolsByGroupId = (state: any, groupId: number): Pool[] =>
    getPools(state).filter(pool => pool.groupId === groupId && !isPoolActive(pool));

export const checkEmptyLegs = (legs: any[]): boolean => {
    return legs.every(leg => leg.marks.includes('1'));
};

/**
 * Returns pool selected in ui
 * @param state
 */
export const getSelectedPool = (state: any): Pool =>
    state.BetBuddy.pools.data[state.BetBuddy.ui.activePoolId];

/**
 * Returns coupon selected in ui
 * @param state
 */
export const getSelectedCoupon = (state: any): CouponDraft => {
    const poolDetails = getPoolDetailsById(state, getActivePoolId(state));
    const activeCouponId = getActiveCouponId(state);
    const unserializedCoupon = poolDetails.coupons.find(
        coupon => coupon.couponId === activeCouponId
    );
    return unserializedCoupon
        ? deserializeCouponDraft(unserializedCoupon)
        : createNullCouponDraft();
};

/**
 * Returns pool by id
 * @param          state
 * @param {number} id     Pool id
 */
export const getPoolById = (state: any, id: number): Pool => state.BetBuddy.pools.data[id];

/**
 * Returns pool details by pool id
 * @param          state
 * @param {number} id     Pool id
 */
export const getPoolDetailsById = (state: any, id: number): PoolExtendedInfoOutput =>
    state.BetBuddy.poolDetails.data[id] || createNullPoolExtendedInfo();

export const getResults = (state: any): Results[] => Object.values(state.BetBuddy.results.data);

export const getResultsById = (state: any, couponId: number): Results =>
    state.BetBuddy.results.data[couponId];

/**
 * @param state
 * @param poolId
 * @return Pool
 */
export const getProductByPool = (state: any, poolId: number): Product => {
    const pool = getPoolById(state, poolId);
    if (!pool) {
        return new Product();
    }
    const poolDetails = getPoolDetailsById(state, poolId);

    const isMultitrackProduct = poolDetails.multiTrackId !== 0;

    return createById(pool.externalPool.productId, isMultitrackProduct);
};

/**
 * @param state
 * @param poolId
 * @return Track
 */
export const getTrackByPool = (state: any, poolId: number): Track => {
    const poolDetails = getPoolDetailsById(state, poolId);

    const track = poolDetails.tracks[0].track;

    return Track.unserialize(poolDetails.tracks[0].trackId, {
        ...track,
        code: poolDetails.multiTrackId > 0 ? 'Xa' : track.code,
    });
};

export const getCombinedTrackName = (state: any, poolId: number): string => {
    const pool = getPoolById(state, poolId);
    return pool?.externalPool?.trackName ?? '';
};

/**
 * @param state
 * @param poolId
 * @return RaceDay
 */
export const getRaceDayByPool = (state: any, poolId: number): RaceDay => {
    const pool = getPoolById(state, poolId);
    if (!pool) {
        return new RaceDay();
    }
    const track = getTrackByPool(state, poolId);
    const date = moment(pool.externalPool.raceDate).format('YYYY-MM-DD');
    const raceDay = getRaceDayBy(state, { trackId: track.id, date });

    return raceDay.isNull()
        ? new RaceDay({
              date,
              track,
              trackId: track.id,
          })
        : raceDay;
};

export const getActiveGroupId = (state: any): number => state.BetBuddy.ui.activeGroupId;

export const getCreateGroupAvatar = (state: any): string | null =>
    state.BetBuddy.ui.createGroupAvatar;

export const getActivePoolId = (state: any): number => state.BetBuddy.ui.activePoolId;

export const getActiveCouponId = (state: any): number => state.BetBuddy.ui.activeCouponId;

export const getGroupsPageNumber = (state: any): number => state.BetBuddy.ui.groupsPageNumber;

export const getGroupsPageSize = (state: any): number => state.BetBuddy.ui.groupsPageSize;

export const getTotalGroupsNumber = (state: any): number => state.BetBuddy.ui.totalGroupsNumber;

export const getPublicGroupsPageNumber = (state: any): number =>
    state.BetBuddy.ui.publicGroupsPageNumber;

export const getPublicGroupsPageSize = (state: any): number =>
    state.BetBuddy.ui.publicGroupsPageSize;

export const getTotalPublicGroupsNumber = (state: any): number =>
    state.BetBuddy.ui.totalPublicGroupsNumber;

export const getFilteredGroupsPageNumber = (state: any): number =>
    state.BetBuddy.ui.filteredGroupsPageNumber;

export const getFilteredGroupsPageSize = (state: any): number =>
    state.BetBuddy.ui.filteredGroupsPageSize;

export const getFilteredTotalGroupsNumber = (state: any): number =>
    state.BetBuddy.ui.totalFilteredGroupsNumber;

export const isPersonalGroup = (state: any): boolean => state.BetBuddy.ui.isPersonalGroup;

export const isPublicGroup = (state: any): boolean => state.BetBuddy.ui.isPublicGroup;

export const getCreatedPoolHash = (state: any): string => state.BetBuddy.ui.createdPoolHash;

export const getPoolHash = (state: any): string => state.BetBuddy.ui.poolHash;

export const isCaptain = (state: any): boolean => {
    const auth = getAuth(state);
    const pool: Pool = getSelectedPool(state);
    const group = getGroupById(state, pool.groupId);
    return auth.user && group ? auth.user.id === group.ownerId : false;
};
