import React, { Component } from 'react';
import { connect } from 'react-redux';
import Receipt, { placeVinderPladsBetFromRecipt } from './Receipt';
import { addBet, resetBet, setDerbyLynBet, setPrevAmount } from 'features/BetSlip/state/actions';
import { withRouter } from 'react-router';
import { keepRaceIndexInState, setProduct, setRaceDay } from 'features/AISDataProvider/actions';
import { getDefaultRaceDay } from 'features/AISDataProvider';
import productSelector from 'common/selectors/productSelector';
import Modal from 'ui/ModalContent';
import { showPopup } from 'utils/navigation';
import { isMobile } from 'utils/platforms';

class ReceiptMobile extends Component {
    enabledResettingOfBet = true;

    componentDidMount() {
        this.props.history.listen(location => {
            if (location.pathname === '/mine-spil' && isMobile) {
                this.props.hideModal('RECEIPT');
            }
        });
        this.enabledResettingOfBet = true;
    }

    componentWillUnmount() {
        if (this.enabledResettingOfBet) {
            this.props.resetBet();
        }
    }

    placeBetHandler = (betSlip, date, trackCode, product, raceIndex, prevAmount) => {
        this.props.resetBet();
        this.enabledResettingOfBet = false;

        if (!this.props.selectedRaceDay) {
            this.props.setRaceDay(getDefaultRaceDay(this.props.raceDayData));
        }

        placeVinderPladsBetFromRecipt(
            this.props.addBet,
            this.props.setProduct,
            this.props.keepRaceNumberInState,
            betSlip,
            date,
            trackCode,
            product,
            raceIndex
        );

        this.props.setPrevAmount(prevAmount);
        this.props.hideModal('RECEIPT');
        showPopup('BETSLIP_MOBILE');
    };

    placeLynBetHandler = (date, trackCode, product) => {
        this.props.hideModal('RECEIPT');
        this.props.setDerbyLynBet(date, trackCode, product);
        this.props.history.push('/derby-lyn');
    };

    render() {
        return (
            <Modal topBarShown style={{ height: 'auto' }}>
                <Receipt
                    {...this.props.modalData}
                    hideModal={this.props.hideModal}
                    recipeMode={true}
                    placeBetHandler={this.placeBetHandler}
                    placeLynBetHandler={this.placeLynBetHandler}
                    product={this.props.product}
                />
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    raceDayData: state.AISDataProvider.raceDayData,
    selectedRaceDay: state.AISDataProvider.selectedRaceDay,
    product: productSelector(state),
});

const mapDispatchToProps = dispatch => ({
    resetBet: () => {
        dispatch(resetBet());
    },
    addBet: (date, trackId, productId, raceIndex, startNr) => {
        dispatch(addBet(date, trackId, productId, raceIndex, startNr, startNr));
    },
    setProduct: product => {
        dispatch(setProduct(product));
    },
    setRaceDay: raceDay => {
        dispatch(setRaceDay(raceDay));
    },
    keepRaceNumberInState: raceNumber => {
        dispatch(keepRaceIndexInState(raceNumber));
    },
    setPrevAmount: amount => {
        dispatch(setPrevAmount(amount));
    },
    setDerbyLynBet: (date, trackCode, product) => {
        dispatch(setDerbyLynBet(date, trackCode, product));
    },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReceiptMobile));
