import { get } from 'lodash';
import { getShopConfig } from 'common/api/TerminalAPI';
import { SHOP_CONFIG_PENDING, SHOP_CONFIG_SUCCESS, SHOP_CONFIG_ERROR } from './contstants';

export const getTerminalShopConfig = () => {
    return (dispatch) => {
        dispatch({
            type: SHOP_CONFIG_PENDING,
            payload: {},
        });

        getShopConfig()
            .then(({ response }) => {
                const successfulRes = get(response, 'data.config', null) && response.success;

                if (successfulRes) {
                    const shopData = get(response, 'data', null);
                    const playAndPayAllowed = get(shopData, 'config.playAndPayAllowed', false);
                    const sections = get(shopData, 'config.sections', []);
                    const shopId = get(shopData, 'shop_id', null);

                    dispatch({
                        type: SHOP_CONFIG_SUCCESS,
                        payload: {
                            playAndPayAllowed,
                            sections,
                            shopId,
                        },
                    });
                } else {
                    const errorMessage = get(
                        response,
                        'error_message',
                        'getTerminalShopConfig. Bad request'
                    );

                    dispatch({
                        type: SHOP_CONFIG_ERROR,
                        payload: errorMessage,
                    });
                }
            })
            .catch((error) => {
                console.error('getTerminalShopConfig', error);
            });
    };
};
