import getTexts from 'utils/localization';
const t = getTexts();

export const formatProductName = (productName, strictBet) => {
    if (strictBet) {
        return productName === 'GS75' ? 'GS7' : 'V6';
    } else {
        return productName;
    }
};

export const formatRaceNumber = (coupon, couponInfo) => {
    const raceNumber = coupon.raceNumber
        ? coupon.raceNumber
        : couponInfo[0]
            ? couponInfo[0].raceNumber
            : null;

    return raceNumber ? ` - ${t.RacingCard.race} ${raceNumber}` : '';
};
