export default class Driver {
    id = null;
    amateur = null;
    apprenticeAmateur = null;
    apprenticePro = null;
    initials = null;
    name = null;
    shortName = null;
    sport = null;
    surName = null;
    swedish = false;

    constructor() {
        return this;
    }

    static unserialize(data) {
        const driver = new Driver();

        try {
            driver.id = data.id;
            driver.amateur = data.amateur;
            driver.apprenticeAmateur = data.apprenticeAmateur;
            driver.apprenticePro = data.apprenticePro;
            driver.initials = data.initials;
            driver.name = data.name;
            driver.shortName = data.shortName;
            driver.sport = data.sport;
            driver.surName = data.surName;
            driver.swedish = data.swedish;
        } catch (error) {
            //@TODO Logger of errors
        }

        return driver;
    }
}
