import { useDispatch, useSelector } from 'react-redux';
import { couponDraftToBetSlip, deserializeRaces } from '../serializing';
import Betslip from 'common/DataObjects/Betslip';
import {
    getReserves,
    getReservesBy,
    isSpilklubSelectionsMode,
} from 'common/selectors/betSlipSelector';
import Bet from 'common/DataObjects/Bet';
import useSelections from 'features/BetSlip2/state/useSelections';
import { MERGE_BETSLIP, SET_BET_BUDDY_SELECTIONS_MODE } from 'features/BetSlip2/state/actions';
import {
    checkEmptyLegs,
    getCombinedTrackName,
    getPoolDetailsById,
    getProductByPool,
    getRaceDayByPool,
    getTrackCode,
    getTrackName,
} from '../selectors';
import Pool, { createNullPool } from '../model/Pool';
import { betsInitialState, reservesInitialState } from 'features/BetSlip2/state/reducer';
import betSlipSelector from 'common/selectors/betSlipSelector';
import CouponDraft from '../model/CouponDraft';
import { isVProduct as isV } from 'features/TrackPage/model/Product';

/**
 * This means kind of action that will be used in UI
 * to determine if user got back from editing a coupon
 * or from creating it.
 */
export enum SelectionModeTarget {
    'DISABLED' = 'DISABLED',
    'CREATE' = 'CREATE',
    'UPDATE' = 'UPDATE',
}

/**
 * Has the same return structure as features/BetSlip/useSelections
 * Can be used for BetSlip component that shows selections for any
 * kind of product, but takes the selection data from CouponDraft
 * object.
 *
 * @see features/BetSlip/useSelections
 * @param pool
 * @param couponDraft
 */
const useDraftSelections = (pool: Pool, couponDraft: CouponDraft) => {
    const dispatch = useDispatch();
    pool = pool || createNullPool();

    const poolDetails = useSelector(state => getPoolDetailsById(state, pool.internalPool.id));

    const minimumPicksValid = useSelector(() => checkEmptyLegs(couponDraft.legs));

    const trackCode = useSelector(state => getTrackCode(state, pool.internalPool.id));

    const trackName = useSelector(state => getTrackName(state, pool.internalPool.id));

    const raceDay = useSelector(state => getRaceDayByPool(state, pool.internalPool.id));

    const isMultitrackProduct = poolDetails.multiTrackId !== 0;

    const combinedTrackName = useSelector(state =>
        getCombinedTrackName(state, pool.internalPool.id)
    );

    const product = useSelector(state => getProductByPool(state, pool.internalPool.id));

    const betTableData = couponDraftToBetSlip(pool.externalPool, couponDraft.legs, trackCode);

    const betSlip = new Betslip(betTableData.betsByDates);

    const betTable = new Bet(betTableData.betsByDates);

    const races = deserializeRaces(poolDetails.races, poolDetails.tracks);

    const reserves = getReservesBy(
        {
            BetSlip: {
                ...betTableData,
                betBuddySelectionsMode: {
                    status: false,
                    externalPool: {},
                },
            },
        },
        {
            raceDay,
            product,
            track: { code: trackCode },
        }
    );

    const isSelectionsMode = useSelector(isSpilklubSelectionsMode);
    const editedBetSlip = useSelector(betSlipSelector);
    const editedReserves = useSelector(getReserves);

    const setBetBuddySelectionsMode = (
        status: boolean,
        target: SelectionModeTarget = SelectionModeTarget.UPDATE
    ) => {
        dispatch({
            type: SET_BET_BUDDY_SELECTIONS_MODE,
            payload: { status, externalPool: pool.externalPool, target },
        });
    };

    const hydrateBetSlipState = () =>
        dispatch({
            type: MERGE_BETSLIP,
            payload: {
                picks: betsInitialState.betsByDates,
                reserves: reservesInitialState.reservesByDates,
                betBuddy: isSelectionsMode ? editedBetSlip.betsByDates : betTableData.betsByDates,
                betBuddyReserves: isSelectionsMode ? editedReserves : betTableData.reservesByDates,
            },
        });

    const { serverTime } = useSelections();

    return {
        product,
        trackCode,
        trackName,
        isVProduct: isV(product.id),
        serverTime,
        raceDay,
        date: raceDay.raceDayDate,
        firstVRace: races[0],
        races,
        combinationsCount: couponDraft.combinationNumber,
        combinedTrackName,
        singleRaceBetPicks: betTable,
        betSlip,
        reserves,
        minimumPicksValid, // since the data comes from the server
        maximumPicksValid: true, // it was previously validated so we can assume it's valid
        isMultitrack: isMultitrackProduct,
        strictBet: couponDraft.strictBet,
        // the following properties are missing in original useSelections hook
        setBetBuddySelectionsMode,
        hydrateBetSlipState,
    };
};

export default useDraftSelections;
