import { getSelectedCoupon, getSelectedPool } from '../selectors';
import useSelections from '../../BetSlip/state/useSelections';
import useDraftSelections from './useDraftSelections';
import { addCouponAsync, updateCouponAsync } from '../actions';
import { useDispatch, useSelector } from 'react-redux';
import { serializeSelectionsToCouponDraft } from '../serializing';
import { isSpilklubSelectionsMode } from 'common/selectors/betSlipSelector';

const useCoupon = () => {
    const dispatch = useDispatch();
    const pool = useSelector(getSelectedPool);
    const coupon = useSelector(getSelectedCoupon);

    const isSelectionsMode = useSelector(isSpilklubSelectionsMode);
    const selections = useSelections();
    const draftSelections = useDraftSelections(pool, coupon);

    const updateCoupon = () => {
        dispatch(
            updateCouponAsync.request(
                serializeSelectionsToCouponDraft(
                    selections,
                    draftSelections,
                    coupon.combinationPrice,
                    isSelectionsMode,
                    pool,
                    coupon
                )
            )
        );
    };

    const addCoupon = (combinationPrice: number) => {
        dispatch(
            addCouponAsync.request(
                serializeSelectionsToCouponDraft(
                    selections,
                    draftSelections,
                    combinationPrice,
                    isSelectionsMode,
                    pool,
                    coupon
                )
            )
        );
    };

    return [updateCoupon, addCoupon];
};

export default useCoupon;
