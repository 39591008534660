import React from 'react';
import defaultImage from 'features/Login/assets/bg-default.png';
import styled from 'styled-components';

const HeaderWithBanner = styled.div`
    height: 100%;
    position: relative;
    width: 100%;
    text-align: center;
    display: flex;
    flex-flow: column;
    justify-content: space-around;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background:
        linear-gradient(transparent, transparent 20%, rgb(255, 255, 255) 54%) center top / contain
            no-repeat,
        url('${(props) => props.imgPath}') center top / 120% 60% rgb(255, 255, 255);
    background-repeat: no-repeat;
    @media (orientation: landscape) {
        background-size: cover;
    }
    @media (min-device-width: 700px) {
        background-size: cover;
    }
`;

const HeaderBanner = ({ children }) => {
    return <HeaderWithBanner imgPath={defaultImage}>{children}</HeaderWithBanner>;
};

export default HeaderBanner;
