import styled from 'styled-components';
import { BACKGROUNDS, COLORS } from 'themes';
import { CloseButton as ReceiptCloseBtn } from 'features/BetSlip2/components/Receipt/styled';
import { byPlatform } from 'utils/platforms';
import Wrapper from 'ui/Wrapper';
import { priority } from 'configs/layout';

const topLineHeight = '30px';
const popupWidth = byPlatform('300px', '350px', '260px');

const styles = {
    BetSlip: {
        TopBar: {
            height: '150px',
            minHeight: '150px',
            borderRadius: '0 0 65px 65px',
        },
        TopBarTextSm: {
            fontSize: '12px',
        },
        TopBarTextLg: {
            fontSize: '16px',
        },
        Background: {
            paddingBottom: 0,
            overflowX: 'hidden',
            overflowY: 'auto',
        },
        FullScreen: {
            height: 'auto',
        },
        StyledFillable: {
            height: 'auto',
            padding: byPlatform('20px 10px 0 10px', '0 10px 5px 10px'),
            background: 'none',
        },
        AmountBtn: {
            borderRadius: '17px',
            flex: '0 0 31%',
            margin: '6px 0',
            fontSize: '11px',
        },
        Card: {
            position: 'relative',
            width: '95%',
            left: '2.5%',
        },
        AnimatedButton: {
            width: '60%',
            height: '45px',
            lineHeight: '16px',
            fontSize: '12px',
            top: '-25px',
            position: 'relative',
        },
        ControlCol: {
            paddingLeft: '10px',
            paddingRight: '25px',
        },
        Label: {
            fontSize: '12px',
        },
    },
    Receipt: {
        PopupWrapper: {
            borderTopLeftRadius: '20px',
            overflowX: 'hidden',
            overflowY: 'auto',
        },
        FullScreen: {
            height: 'auto',
        },
        Popup: {
            width: '100%',
            padding: '30px 38px',
            transform: 'scale(0.9)',
            top: 0,
            marginBottom: '50px',
        },
        TopBar: {
            height: '150px',
            minHeight: '150px',
            borderRadius: '0 0 65px 65px',
        },
        TopBarTextSm: {
            fontSize: '12px',
        },
        TopBarTextLg: {
            fontSize: '16px',
        },
        GoLiveBtnWrapper: {
            height: 'auto',
        },
        GoLiveBtn: {
            margin: '20px auto',
        },
    },
};

const Popup = styled.div`
    width: ${popupWidth};
    background: ${({ fillBackground }) => {
        return fillBackground ? BACKGROUNDS.primary : COLORS.white;
    }};
    position: fixed;
    right: 230px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: 0 0 20px rgba(61, 61, 61, 0.6);
    bottom: 0;
    transform: ${({ isHidden, combinationsCount, receiptShown }) =>
        combinationsCount > 0 || receiptShown
            ? isHidden
                ? `translateY(calc(100% - 51px))`
                : `translateY(0)`
            : 'translateY(100%)'};

    transition: all 400ms ease-in-out;
    transition-delay: 100ms;
    z-index: ${priority.betSlipPopupDesktop};
    visibility: ${({ combinationsCount, receiptShown }) =>
        combinationsCount > 0 || receiptShown ? 'visible' : 'hidden'};
`;

const TerminalPopup = styled.div`
    margin-top: 40px;
    border-radius: 10px;
    transition: all 400ms ease-in-out;
    transition-delay: 100ms;
`;

const TopLine = styled.div`
    background: ${BACKGROUNDS.primary};
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    display: flex;
    border-bottom: 1px solid #309162;
    cursor: pointer;
`;

const borderRight = byPlatform('1px solid #38a972', '1px solid #38a972', 'none');
const CombinationsCounter = styled.div`
    line-height: ${topLineHeight};
    flex-grow: 3;
    border-right: ${borderRight};
    color: ${COLORS.white};
    text-align: center;
    text-transform: uppercase;
    font-size: 16px;
`;

const Toggler = styled.div`
    line-height: ${topLineHeight};
    width: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;

const ArrowDown = styled.div`
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 10px 7px 0 7px;
    border-color: #ffffff transparent transparent transparent;
    margin: 0 auto;
`;

const ArrowUp = styled.div`
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 7px 10px 7px;
    border-color: transparent transparent #ffffff transparent;
    margin: 0 auto;
`;

const Content = styled.div`
    overflow: hidden;
    height: auto;
`;

const CloseButton = styled(ReceiptCloseBtn)`
    left: calc(100% - 15px);
    top: -14px;
    background: #fff;
    box-shadow: 0 0 14px #3d3d3d;
    cursor: pointer;
    position: absolute;
    img {
        width: 12px;
        height: 12px;
        filter: brightness(1) invert(1);
    }
`;

const SmartLynWrapper = styled(Wrapper)`
    background: white;
`;

export {
    styles,
    Popup,
    TerminalPopup,
    TopLine,
    CombinationsCounter,
    Toggler,
    ArrowDown,
    ArrowUp,
    Content,
    CloseButton,
    SmartLynWrapper,
};
