import React from 'react';
import styled, { css } from 'styled-components';
import { PRIORITY } from 'features/Spilklub2/config';
import Fa from 'ui/Fa';
import Icon from 'ui/Icon';
import Text from 'ui/Text';
import { BACKGROUNDS, COLORS } from 'themes';
import Modal from 'ui/ModalContent';
import DefaultTooltip from 'ui/Tooltip';
import { getUrl } from 'utils/navigation';
import { ComponentType, UIComponentProps } from 'ui/types';

interface ZIndexMixinProps {
    priority?: number;
}
interface GametypesProps {
    gameType?: string;
}

export const getGameTypeBackground = (gameType: string) => {
    return getUrl(`/img/betbuddy_gametypes/${gameType.toUpperCase()}.png`);
};

export const zIndexMixin = css<ZIndexMixinProps>`
    z-index: ${({ priority }) =>
        priority ? PRIORITY.SCREEN_PRIORITY_BASE + priority : PRIORITY.SCREEN_PRIORITY_BASE};
`;

interface PositionMixinProps {
    absolute?: string | boolean;
    fixed?: string | boolean;
    relative?: string | boolean;
}

export const positionMixin = css<PositionMixinProps>`
    position: ${({ absolute, fixed, relative }) =>
        absolute ? 'absolute' : fixed ? 'fixed' : relative ? 'relative' : 'auto'};
`;

interface sidePositionMixin {
    leftSide?: string;
    rightSide?: string | boolean;
}

export const sidePositionMixin = css<sidePositionMixin>`
    left: ${({ leftSide }) => (leftSide ? '0' : 'auto')};
    right: ${({ rightSide }) => (rightSide ? 0 : 'auto')};
`;

interface ClickableCornerIconMixin {
    padding?: string;
    left?: string;
    right?: string;
}

export declare type ClickableCornerIconMixinProps = ClickableCornerIconMixin &
    ZIndexMixinProps &
    PositionMixinProps &
    sidePositionMixin;

export const clickableCornerIconMixin = css<ClickableCornerIconMixinProps>`
    padding: ${({ padding = '25px' }) => padding};
    cursor: pointer;
    ${zIndexMixin};
    ${positionMixin};
    ${sidePositionMixin};
`;

export const ScreenWrapper = styled(Modal)`
    height: auto;
    overflow-y: auto;
    overflow-x: hidden;
`;

interface IconProps {
    iclose?: boolean;
    color?: string;
    size?: string;
}

export const CloseButton = styled(
    (props: IconProps & UIComponentProps<HTMLImageElement> & ClickableCornerIconMixinProps) => (
        <Icon iclose color={'white'} size={'x05'} {...props} />
    )
)`
    ${clickableCornerIconMixin};
    margin-top: 4px;
`;

interface BackButtonProps {
    priority?: number;
}

export const BackButton = styled(Fa).attrs<BackButtonProps>({
    color: 'white',
    section: 'solid',
    svgName: 'angle-left',
})`
    ${clickableCornerIconMixin};
`;
// Subscribe screen
export const BackButtonBlack = styled(Fa).attrs<BackButtonProps>({
    color: 'black',
    section: 'solid',
    svgName: 'angle-left',
})`
    ${clickableCornerIconMixin};
    display: inline-block;
`;
export const ReadMoreButton = styled(Fa).attrs<BackButtonProps>({
    color: 'white',
    section: 'solid',
    svgName: 'angle-right',
})`
    ${clickableCornerIconMixin};
    padding: 0;
    margin-right: 15px;
`;
export const DeleteIcon = styled(Fa).attrs<BackButtonProps>({
    color: 'grey',
    section: 'solid',
    svgName: 'delete',
})`
    ${clickableCornerIconMixin};
    padding: 0;
    line-height: 33px;
`;
// Pool Dashboard

export const PoolWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    transform: translateY(-40px);
`;

export const PoolAmount = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 370px;
    height: 140px;
    border-radius: 45px;
    background-color: #fff;
    box-shadow: 0 0 35px #00000029;
    margin-bottom: 60px;
`;

export const PoolBalance = styled.p`
    margin: 0;
    text-transform: uppercase;
    font-size: 40px;
    color: #404757;
`;

export const PoolText = styled.p`
    margin: 0;
    text-transform: uppercase;
    font-size: 16px;
    color: ${COLORS.primaryText};
`;

export const BuyShareBlock = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    width: 285px;
    height: 48px;
    border-radius: 25px;
    background-color: #fff;
    box-shadow: 0 0 35px #00000029;
`;

export const ShareBalance = styled.p`
    margin: 0;
    font-size: 16px;
    color: ${COLORS.primaryText};
    font-weight: bold;
`;

export const ShareText = styled.p`
    margin: 0;
    font-size: 16px;
    color: ${COLORS.primaryText};
    margin-left: 15px;
`;

export const ShareIconBackground = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background: linear-gradient(#05a358, #34b474);
    color: ${COLORS.white};
    font-size: 24px;
    font-weight: bold;
`;

export const IconsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 50px;
    width: 100%;
    justify-content: space-around;
`;

export const GroupInfoBtn = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 284px;
    height: 86px;
    background-color: #fff;
    border-radius: 25px;
    box-shadow: 0 0 35px #00000029;
    cursor: pointer;
`;

export const GroupIcon = styled.div`
    position: relative;
`;

export const GroupCount = styled.div`
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background: black;
    text-align: center;
    position: absolute;
    font-size: 12px;
    line-height: 21px;
    font-weight: bold;
    color: #eceff5;
    top: -4px;
    left: -10px;
`;

export const GroupIconText = styled.p`
    margin: 0 0 0 13px;
    font-size: 16px;
    color: ${COLORS.primaryText};
    font-weight: bold;
    text-transform: uppercase;
`;

export const MainInfoContainer = styled.div`
    display: flex;
    position: relative;
    flex-direction: row;
    width: 340px;
    justify-content: space-around;
    margin-bottom: 35px;
`;

export const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const MainText = styled(Text)`
    font-size: 16px;
    padding: 0;
`;

export const MainInfo = styled<ComponentType<{ red?: boolean; bold?: boolean }>>(Text).attrs({
    as: 'div',
})`
    font-size: 26px;
    padding: 0;
    color: ${({ red }) => (red ? COLORS.red : COLORS.primaryText)};
`;

export const CenteredButton = styled.a<{
    active?: boolean;
    disabled?: boolean;
}>`
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 290px;
    height: 72px;
    line-height: 72px;
    text-align: center;
    border-radius: 36px;
    box-shadow: 0 0 35px #00000040;
    background: ${({ active, disabled }) =>
        disabled ? BACKGROUNDS.lightGrey : active ? COLORS.primary : BACKGROUNDS.accentAlt};
    color: ${({ active }) => (active ? COLORS.white : COLORS.grey)};
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 900;
    transition: background 300ms ease-in-out;
    margin: 0 auto;
`;

export const ProductCard = styled.div<GametypesProps>`
    width: 100%;
    position: relative;
    border-radius: 20px;
    box-shadow: 0 0 35px #00000040;
    padding: 15px 20px;
    box-sizing: border-box;
    background: url(${({ gameType = 'V75' }) => getGameTypeBackground(gameType)});
    background-size: cover;
    background-position: top right;
    margin-bottom: 30px;
`;

export const CouponHorseTooltip = styled(DefaultTooltip)`
    color: black;
    max-width: none;
    min-width: 130px;
    top: 41px;
    left: -50px;
`;

export const ResultHorseTooltip = styled(DefaultTooltip)`
    color: black;
    max-width: 147px;
    min-width: 130px;
    left: -50px;
    top: 37px;
`;

export const GroupTabsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

export const GroupTab = styled.div<{ selected: boolean }>`
    margin: 5px 15px;
    cursor: pointer;
    font-weight: 900;
    font-size: 18px;
    border-bottom: ${props => (props.selected ? `3px solid ${COLORS.accent}` : 0)};
    opacity: ${props => (props.selected ? 1 : 0.5)};
    transition: opacity 300ms ease-in-out;
`;
