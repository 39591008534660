import React from 'react';
import BetPickRow from './BetPickRow';

const UHorsePick = (props) => {
    const { race, singleRaceBetPicks } = props;
    const uHorseNumbers = singleRaceBetPicks.getUHorseNumbers();
    const pickedStarts = race.horses.filter((horse) => {
        return uHorseNumbers.indexOf(horse.startNr) !== -1;
    });

    return (
        <BetPickRow
            rowLabel="U"
            pickedStarts={pickedStarts}
            singleRaceBetPicks={singleRaceBetPicks}
        />
    );
};

export default UHorsePick;
