import React from 'react';
import { connect } from 'react-redux';
import MobileLogin from './MobileLogin';
import { login, pinLogin } from 'common/actions/authActions';
import NotificationConductor from 'common/conductors/NotificationConductor';
import Pin from 'features/Login/Pin';
import { isPinSet } from 'common/selectors/auth';
import { showModal } from 'common/actions/uiActions';
import { PIN_LENGTH } from 'configs/main';
import { withRouter } from 'react-router-dom';

import getTexts from 'utils/localization';
import { resetMitIdRedirectURL } from '../Signup/helpers';

const t = getTexts();

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            forceCommonLogin: false,
        };
    }

    loginWithPin = pin => {
        const { pinLogin } = this.props;

        pinLogin(pin).catch(error => {
            const attemptsReached = this.props.attemptsLeft === 0;
            const message = attemptsReached ? t.pinLogin.attemptsReached : error;
            NotificationConductor.error(message);

            if (attemptsReached) {
                this.switchToCommonLogin();
            }
        });
    };

    switchToCommonLogin = () => {
        this.setState({ forceCommonLogin: true });
    };

    onMobileLoginHandler = (
        userName,
        password,
        setLoading,
        onSuccess = () => {},
        onError = () => {}
    ) => {
        this.props.login(userName, password, setLoading, onSuccess, onError);
    };

    forceCommonLogin = () => {
        this.setState({ forceCommonLogin: true });
    };

    usualLoginShown = () => {
        const { isPinSet } = this.props;
        const { forceCommonLogin } = this.state;

        return forceCommonLogin || !isPinSet;
    };

    componentDidUpdate(prevProps, prevState) {
        const { auth, hideModal, disableCloseButton } = this.props;
        const { forceCommonLogin } = this.state;

        if (!prevState.forceCommonLogin && forceCommonLogin) {
            disableCloseButton();
        }

        if (auth.user) {
            hideModal('LOGIN');

            if (this.props.modalData && this.props.modalData.redirectOnAuth) {
                window.location = this.props.modalData.redirectOnAuth;
            }

            if (this.props.modalData && this.props.modalData.pushOnAuth) {
                this.props.history.push(this.props.modalData.pushOnAuth);
            }

            if (this.props.modalData && this.props.modalData.onAuthSuccess) {
                this.props.modalData.onAuthSuccess();
            }
        }

        if (auth.error && !prevProps.auth.error) {
            NotificationConductor.error(auth.error);
        }
    }

    closeHandler = () => {
        resetMitIdRedirectURL();
        this.props.close();
    };

    render() {
        const { attemptsLeft, pending } = this.props;

        return this.usualLoginShown() ? (
            <MobileLogin
                isPending={pending}
                error={this.props.auth.error}
                onLoginClick={this.onMobileLoginHandler}
                showForgotPassword={this.props.showForgotPassword}
                {...this.props}
                close={this.closeHandler}
            />
        ) : (
            <Pin
                onClose={this.forceCommonLogin}
                pinLength={PIN_LENGTH}
                onPinFullfiled={this.loginWithPin}
                key={attemptsLeft}
                loading={pending}
                showForgotPasswordButton
                forceCommonLogin={this.forceCommonLogin}
                {...this.props}
            />
        );
    }
}

const mapStateToProps = state => {
    return {
        auth: state.auth,
        isPinSet: isPinSet(state),
        attemptsLeft: state.auth.pin.attemptsLeft,
        pending: isPinSet(state) ? state.auth.pin.pending : state.auth.pending,
        pinError: state.auth.pin.error,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        login: (userName, password, setLoading, onSuccess = () => {}, onError = () => {}) => {
            dispatch(login(userName, password, setLoading, onSuccess, onError));
        },
        pinLogin: pin => {
            return dispatch(pinLogin(pin));
        },
        showForgotPassword: () => {
            dispatch(showModal('FORGOT_PASSWORD', 2));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
