import React from 'react';
import NavigationMenu from '../NavigationMenu';
import UserSessionAdapter from 'common/adapters/UserSession';
import Clock from './Clock';
import Build from './Build';
import {
    FilledWrapper,
    RightArea,
    StyledFlexWrapper,
    StyledHomeButtonContainer,
    Label,
    Value,
} from './styled';
import { ReactComponent as HomeIcon } from 'images/icons/desktop-sidebar/hjem.svg';

class FullWidthTopBar extends React.Component {
    render() {
        return (
            <FilledWrapper>
                <StyledFlexWrapper direction="row" align="space-between">
                    <StyledHomeButtonContainer
                        onClick={() => {
                            this.props.redirectToBet25();
                        }}
                    >
                        <HomeIcon />
                    </StyledHomeButtonContainer>

                    <NavigationMenu
                        onClickHandler={this.props.redirectToBet25}
                    />

                    <RightArea>
                        <Label>Terminal ID:</Label>
                        <Value>
                            {UserSessionAdapter.getTerminalId() || ' --- '}
                        </Value>

                        <Build />

                        <Clock />
                    </RightArea>
                </StyledFlexWrapper>
            </FilledWrapper>
        );
    }
}

export default FullWidthTopBar;
