import styled from 'styled-components';
import { COLORS } from 'themes';
import { Card, CardHeader as CardHeaderDef } from 'ui/Card';

const Table = styled(Card)``;

const TableContent = styled.div`
    display: grid;
`;

const TableHeader = styled(CardHeaderDef)`
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 30px;
    line-height: 30px;
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    @media (max-width: 370px) {
        font-size: 12px;
    }
    & * {
        display: inline !important;
    }
    width: auto;
    padding: 12px 15px 10px 15px;
`;

const TableColumn = styled.div`
    padding: ${({ pr = 5, pl = 5 }) => `0 ${pr}px 0 ${pl}px`};
    box-sizing: border-box;
    flex-basis: ${({ basis }) => basis || '5%'};
    flex-grow: ${({ grow = 0 }) => (typeof grow === 'number' ? grow : 1)};
    align-self: center;
    display: ${({ flex }) => (flex ? 'flex' : 'auto')};
    justify-content: ${({ center }) => (center ? 'center' : 'auto')};
`;

const TableRow = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 3px 0;
`;

const TableRowCheckered = styled(TableRow)`
    &:nth-of-type(2n) {
        background: rgb(235, 239, 246);
    }
`;

const TableSubHeader = styled(TableRow)`
    background: #333;
    color: ${COLORS.white};
    text-transform: capitalize;
    font-size: 10px;
    font-weight: 900;
    height: 18px;
    line-height: 18px;
`;

export {
    Table,
    TableContent,
    TableHeader,
    TableSubHeader,
    TableColumn,
    TableRow,
    TableRowCheckered,
};
