const shoeInfoSelector = (state) => {
    const keyValues = state.AISDataProvider.betInfoData.shoeInfoRace.shoeInfoRace.map(
        (raceShoeInfo) => [
            raceShoeInfo.raceNumber,
            new Map(
                raceShoeInfo.shoeInfoStart.shoeInfoStart.map(
                    (startShoeInfo) => [
                        startShoeInfo.startNumber,
                        {
                            front: startShoeInfo.shoeInfoCurrent.foreShoes,
                            back: startShoeInfo.shoeInfoCurrent.hindShoes,
                        },
                    ]
                )
            ),
        ]
    );

    return new Map(keyValues);
};

export default shoeInfoSelector;
