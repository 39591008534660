import React from 'react';

import useTrackPage from 'features/TrackPage/hooks/useTrackPage';
import { extractJackpot } from 'features/TrackPage/server/pool';
import useServerTimeQuery from 'features/EventBoard/hooks/useServerTimeQuery';

import { RaceCardHeaderContainer, HeaderCol, JackpotLabel } from './styled';
import RaceInfo from './RaceInfo';
import { thousandsDots } from 'utils/formatters';
import Countdown from 'ui/Countdown';
import { hasResultByRaceNr } from 'features/EventBoard/server/calendar';

const RaceCardHeader = () => {
    const { raceDay, product, productPool, isVProduct, race, racesFetched } = useTrackPage();

    const jackpot = extractJackpot(productPool);
    const showJackpot = isVProduct && jackpot > 0;

    const { serverTime } = useServerTimeQuery();

    const startDateTime = raceDay.raceDayDate + ' ' + race.postTime;
    const startDateTimeUTC = raceDay.raceDayDate + ' ' + race.postTimeUTC;

    const hasResult = hasResultByRaceNr(raceDay, race.raceNumber);

    return (
        <RaceCardHeaderContainer className="race-header">
            <HeaderCol className="flex">
                {product.name}{' '}
                {showJackpot && (
                    <JackpotLabel className="flex">
                        Jackpot overført: {thousandsDots(jackpot)} kr.
                    </JackpotLabel>
                )}
            </HeaderCol>
            <HeaderCol>
                <RaceInfo />
            </HeaderCol>
            <HeaderCol>
                <time>
                    <b className="text-bold">
                        {!racesFetched || race.postTimeUTC === null ? (
                            '--:--:--'
                        ) : hasResult ? null : (
                            <Countdown
                                time={startDateTime}
                                timeUTC={startDateTimeUTC}
                                serverTime={serverTime}
                                withDay
                            />
                        )}
                    </b>
                </time>
            </HeaderCol>
        </RaceCardHeaderContainer>
    );
};

export default RaceCardHeader;
