import { request } from 'utils/server';
import { useQuery } from '@tanstack/react-query';

const fetchClubs = async () => {
    const response = await request<any>('Spilklub', 'GET', '/focus/groups');

    if (!response.success) {
        throw new Error(response.errorMessage);
    }

    return response.data?.groups;
};

const fetchClubsForRaceDay = async (date?: string, trackId?: number) => {
    const response = await request<any>('Spilklub', 'GET', '/promo/group/pools', { date, trackId });
    if (!response.success) {
        throw new Error(response.errorMessage);
    }
    //@ts-ignore
    return response.data.map(pool => unserializePool(pool));
};

const unserializePool = (data: any) => {
    return {
        poolTemplateId: data.groupId,
        name: data.groupName,
        avatarUrl: data.avatarUrl,
        activePools: [data.product],
        settings: {
            sharePrice: data.sharePrice,
            sharesLimit: data.sharesLimit,
        },
        trackId: data.trackId,
        trackName: data.trackName,
        availableSharesNumber: data.availableSharesNumber,
        boughtSharesNumber: data.boughtSharesNumber,
        totalAmount: data.totalAmount,
    };
};

interface UseHomepageClubsQueryProps {
    date?: string;
    trackId?: number;
    isTrackMode?: boolean;
    enabled?: boolean;
}

const useClubsQuery = ({
    date,
    trackId,
    isTrackMode = false,
    enabled = true,
}: UseHomepageClubsQueryProps = {}) => {
    const queryKey =
        isTrackMode && date && trackId
            ? ['spilklub.focusGroupsByRaceDay', date, trackId]
            : ['spilklub.focusGroups'];

    const { status, data, error, isSuccess, isLoading } = useQuery({
        queryKey,
        queryFn: () => (isTrackMode ? fetchClubsForRaceDay(date, trackId) : fetchClubs()),
        staleTime: 60 * 10 * 1000,
        enabled: enabled && (!isTrackMode || !!(date && trackId)),
    });

    return { data: data ?? [], status, isSuccess, isLoading, error };
};

export default useClubsQuery;
