import React from 'react';
import styled from 'styled-components';
import { BACKGROUNDS } from 'themes/index.ts';
import useToggle from 'common/hooks/useToggle.ts';
import Arrow from 'ui/Arrow';
import { COLORS } from 'themes/index';

const StreamTypeContainerDef = styled.div`
    position: absolute;
    top: -50px;
`;

export const StreamTypeHeadDef = styled.div`
    background: ${BACKGROUNDS.lightGrey};
    border-radius: 10px;
    padding: 10px 15px;
    cursor: pointer;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
`;

const StrongTitleWithDelimiter = styled.div`
    font-weight: 900;
    display: inline-block;
    border-right: 1px solid #d3dbf1;
    padding-right: 15px;
`;

const StreamTypeList = styled.ul`
    background: ${BACKGROUNDS.lightGrey};
    position: absolute;
    z-index: 21;
    padding: 5px 0 5px 0;
    top: 30px;
    border-radius: 10px;
    transition: opacity 200ms ease-in-out;
    opacity: ${({ shown }) => (shown ? 1 : 0)};
    visibility: ${({ shown }) => (shown ? 'visible' : 'hidden')};
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
`;

const StreamType = styled.li`
    padding: 0 30px 0 20px;
    list-style-type: none;
    cursor: pointer;
    line-height: 30px;
    border-bottom: 1px solid #d3dbf1;
    &:last-of-type {
        border: none;
    }
`;

const Bullet = styled.span`
    color: #00a556;
    position: absolute;
    left: 10px;
`;

const StreamTypesDropdown = ({
    streamTypes,
    selectedStreamType,
    StreamTypeContainer = StreamTypeContainerDef,
    StreamTypeHead = StreamTypeHeadDef,
}) => {
    const [dropdownShown, toggleDropdown] = useToggle();

    return (
        <StreamTypeContainer>
            <StreamTypeHead
                onClick={toggleDropdown}
                onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
            >
                Kanal:{' '}
                <StrongTitleWithDelimiter>
                    {streamTypes[selectedStreamType].title}
                </StrongTitleWithDelimiter>
                <Arrow
                    direction={dropdownShown ? 'up' : 'down'}
                    style={{ margin: '0 0 0 15px' }}
                    color={COLORS.black}
                />
            </StreamTypeHead>
            <StreamTypeList shown={dropdownShown}>
                {Object.entries(streamTypes).map(([streamType, { title, streamTypeSetter }]) => {
                    return (
                        <StreamType
                            key={title}
                            onClick={() => {
                                streamTypeSetter();
                                toggleDropdown();
                            }}
                            active={streamType === selectedStreamType}
                        >
                            {streamType === selectedStreamType && <Bullet>{'\u2022'}</Bullet>}
                            {title}
                        </StreamType>
                    );
                })}
            </StreamTypeList>
        </StreamTypeContainer>
    );
};

export default StreamTypesDropdown;
