import React from 'react';
import { connect } from 'react-redux';
import CMSApi from 'common/api/CMSAPI';
import { StyledButton } from './styled';
import Wrapper from 'ui/Wrapper';
import NotificationConductor from 'common/conductors/NotificationConductor';
import { showModal } from 'common/actions/uiActions';
import Button from 'ui/controls/Button';
import { isMobile } from 'utils/platforms';
import getTexts from 'utils/localization';
import { openLogin } from 'utils/navigation';

const t = getTexts();

class SubscribeButton extends React.Component {
    constructor() {
        super();

        this.state = {
            buttonText: t.Article.subscribe.buttonText,
            buttonColor: { accent: true },
            userSubscribedOnCurrentCampaing: false,
        };
    }

    componentDidMount() {
        if (this.userSubscribedOnCurrentCampaing()) {
            this.setButtonToSuccessMode();
        }

        if (this.props.auth.user) {
            this.checkUserCampaignSubscribeStatus();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.auth.user !== this.props.auth.user && this.props.auth.user !== null) {
            if (this.userSubscribedOnCurrentCampaing()) {
                this.setButtonToSuccessMode();
            }

            this.checkUserCampaignSubscribeStatus();
        }
    }

    checkUserCampaignSubscribeStatus = () => {
        const { auth, article } = this.props;

        CMSApi.checkUserCampaignSubscribeStatus({
            client_id: auth.user.id,
            article_id: article.id,
        })
            .then((res) => {
                const successResponse = res && res.success;

                if (successResponse && res.data && res.data.alreadySubscribed) {
                    window.localStorage.setItem(
                        `subscribedOnCampaign-${article.id}${auth.user.id}`,
                        true
                    );

                    this.setButtonToSuccessMode();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    subscribeButtonHandler = () => {
        const { auth, showLoginModal } = this.props;

        if (auth.login.success) {
            this.addCampaignSubscriber();
        } else {
            showLoginModal();
        }
    };

    userSubscribedOnCurrentCampaing() {
        const userId = this.props.auth.user ? this.props.auth.user.id : null;
        const subscribedOnCurrentCampaing = window.localStorage.getItem(
            `subscribedOnCampaign-${this.props.article.id}${userId}`
        );

        return !!subscribedOnCurrentCampaing || this.state.userSubscribedOnCurrentCampaing;
    }

    setButtonToSuccessMode() {
        this.setState({
            buttonColor: { primary: true },
            buttonText: t.Article.subscribe.alreadySubsribedMessage,
            userSubscribedOnCurrentCampaing: true,
        });
    }

    addCampaignSubscriber() {
        if (this.userSubscribedOnCurrentCampaing()) return;

        const { auth, article } = this.props;

        CMSApi.addCampaignSubscriber({
            client_id: auth.user.id,
            article_id: article.id,
        })
            .then((res) => {
                const successResponse = res && res.success;

                if (successResponse) {
                    NotificationConductor.success(t.Article.subscribe.successMessage);

                    this.setButtonToSuccessMode();

                    window.localStorage.setItem(
                        `subscribedOnCampaign-${article.id}${auth.user.id}`,
                        true
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    render() {
        const { article, visibility } = this.props;

        return (
            <Wrapper margin="0px" padding="0px">
                {visibility === 'show' ? (
                    <StyledButton onClick={this.subscribeButtonHandler} {...this.state.buttonColor}>
                        {this.state.buttonText !== t.Article.subscribe.alreadySubsribedMessage &&
                        [242, 263].indexOf(article.id) > -1
                            ? 'Hent billet'
                            : this.state.buttonText}
                    </StyledButton>
                ) : null}

                {isMobile &&
                [242, 263].indexOf(article.id) > -1 &&
                this.userSubscribedOnCurrentCampaing() ? (
                    <Button
                        block
                        margin="10px auto"
                        lightGrey={true}
                        link={true}
                        style={{
                            textDecoration: 'underline',
                        }}
                        onClick={() => this.props.showQRCodeModal()}
                    >
                        Tryk her for at få din indgangsbillet
                    </Button>
                ) : null}
            </Wrapper>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

const mapDispatchToProps = (dispatch) => {
    return {
        showLoginModal: () => {
            dispatch(openLogin());
        },
        showQRCodeModal: () => {
            dispatch(showModal('QR_CODE_MODAL'));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(SubscribeButton);
