import React, { useRef } from 'react';

import { RaceLineNavigationButton } from 'ui/RaceNavigation';
import Text from 'ui/Text';

import { ScrollBar } from 'features/TrackPage/components/styled';

import useResultPage from 'features/ResultsNew/hooks/useResultPage';

import { byPlatform } from 'utils/platforms';

interface RaceButtonsProps {
    raceNumbers: Array<number>;
    onSelectRace: (race: number, idx: number) => void;
    selectedRace: string;
}

const RaceButtons = ({ raceNumbers, onSelectRace, selectedRace }: RaceButtonsProps) => {
    const { isVProduct } = useResultPage();
    const raceLineRef = useRef();
    const rowProps = { padding: '0', size: 'x1', weight: '900', color: 'inherit' };

    return (
        <div>
            <ScrollBar data-test-id="races-result" ref={byPlatform(raceLineRef, null)}>
                {raceNumbers.map((raceNumber, idx) => {
                    const raceOrderNumber = idx + 1;

                    return (
                        <RaceLineNavigationButton
                            key={idx}
                            onClick={() => onSelectRace(raceOrderNumber, idx)}
                            active={Number(selectedRace) === raceOrderNumber}
                        >
                            <Text {...rowProps}>{isVProduct ? 'Afd.' : 'Løb'}</Text>
                            <Text {...rowProps}>{isVProduct ? raceOrderNumber : raceNumber}</Text>
                        </RaceLineNavigationButton>
                    );
                })}
            </ScrollBar>
        </div>
    );
};

export default RaceButtons;
