import {
    SHOP_CONFIG_PENDING,
    SHOP_CONFIG_SUCCESS,
    SHOP_CONFIG_ERROR,
} from './contstants';

const initialState = {
    pending: false,
    fetched: false,
    data: {},
};

export default function shopConfig(state = initialState, action) {
    switch (action.type) {
        case SHOP_CONFIG_PENDING:
            return {
                ...state,
                pending: true,
                fetched: false,
                error: false,
            };
        case SHOP_CONFIG_SUCCESS:
            return {
                ...state,
                pending: false,
                fetched: true,
                data: action.payload,
                error: false,
            };
        case SHOP_CONFIG_ERROR:
            return {
                ...state,
                pending: false,
                fetched: false,
                data: {
                    playAndPayAllowed: false,
                    sections: {},
                },
                error: action.payload,
            };
        default:
            return state;
    }
}
