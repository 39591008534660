import React from 'react';
import styled from 'styled-components';
import removeIcon from 'images/icons/remove-pin-char.svg';
import { COLORS } from 'themes';

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    height: auto;
    align-items: center;
`;

export const Button = styled.div`
    flex-grow: 1;
    line-height: 65px;
    text-align: center;
    font-size: 24px;
    height: 65px;
    width: 30%;
    cursor: pointer;
    font-weight: 900;
    background: #eceff5;
    color: ${COLORS.black};
    box-shadow: none;
    border: 0.5px solid rgba(1, 1, 1, 0.05);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    touch-action: manipulation;
`;

const Keyboard = (props) => {
    const ButtonComponent = props.ButtonComponent || Button;
    const RowComponent = props.RowComponent || Row;
    const borderRadius = props.borderRadius || '0';

    return (
        <>
            <RowComponent className={'topmost'}>
                <ButtonComponent
                    onClick={props.onAddValue.bind(this, 1)}
                    style={{ borderTopLeftRadius: borderRadius }}
                >
                    1
                </ButtonComponent>
                <ButtonComponent onClick={props.onAddValue.bind(this, 2)}>
                    2
                </ButtonComponent>
                <ButtonComponent
                    onClick={props.onAddValue.bind(this, 3)}
                    style={{ borderTopRightRadius: borderRadius }}
                >
                    3
                </ButtonComponent>
            </RowComponent>
            <RowComponent>
                <ButtonComponent onClick={props.onAddValue.bind(this, 4)}>
                    4
                </ButtonComponent>
                <ButtonComponent onClick={props.onAddValue.bind(this, 5)}>
                    5
                </ButtonComponent>
                <ButtonComponent onClick={props.onAddValue.bind(this, 6)}>
                    6
                </ButtonComponent>
            </RowComponent>
            <RowComponent>
                <ButtonComponent onClick={props.onAddValue.bind(this, 7)}>
                    7
                </ButtonComponent>
                <ButtonComponent onClick={props.onAddValue.bind(this, 8)}>
                    8
                </ButtonComponent>
                <ButtonComponent onClick={props.onAddValue.bind(this, 9)}>
                    9
                </ButtonComponent>
            </RowComponent>
            <RowComponent className="right">
                <ButtonComponent
                    style={{ borderBottomLeftRadius: borderRadius }}
                />
                <ButtonComponent onClick={props.onAddValue.bind(this, 0)}>
                    0
                </ButtonComponent>
                <ButtonComponent
                    onClick={props.onRemove}
                    style={{ borderBottomRightRadius: borderRadius }}
                >
                    <img
                        src={removeIcon}
                        alt="remove"
                        style={{ width: '30px' }}
                    />
                </ButtonComponent>
            </RowComponent>
        </>
    );
};

export default Keyboard;
