import styled, { css } from 'styled-components';
import { byPlatform } from 'utils/platforms';
import { priority } from 'configs/layout';
import { COLORS } from 'themes/index';
import spinner from 'images/spinner.gif';
import Icon from 'ui/Icon';

const animationSpeed = 200;

const FixedVideoContainer = styled.div`
    position: fixed;
    bottom: 240px;
    z-index: ${priority.DGABar + 1};
    height: 146px;
    left: 5px;
    width: 425px;
`;

const FloatingStream = styled.div`
    position: absolute;
    transform: ${(props) => (props.transform ? props.transform : 'initial')};
    height: ${(props) => (props.height ? props.height : '239px')};
    width: ${(props) => (props.width ? props.width : '425px')};
    z-index: ${byPlatform(priority.liveStream, 20)};
    border-radius: ${(props) =>
        props.borderRadius ? props.borderRadius : '10px 10px'};
    box-shadow: rgba(0, 0, 0, 0.6) 0 0 20px;
    background-color: ${COLORS.black};
    background-image: url(${spinner});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50px;
    transition: all ${animationSpeed}ms ease-in-out;
`;

const CloseStream = styled(Icon)`
    position: absolute;
    z-index: 99;
    top: ${byPlatform('5px', '10px')};
    right: ${byPlatform('5px', '10px')};
    padding: 8px;
    height: 14px;
    width: 14px;
    border-radius: 5px;
    background: rgba(1, 1, 1, 0.7);
    cursor: pointer;
    transition: opacity 0.3s ease;
`;

const appearanceStyle = css`
    z-index: ${({ isConnected }) => (isConnected ? 1 : -1)};
    transition: opacity 0.3s ease-in-out;
`;
const CastStreamWrapper = styled.div`
    position: absolute;
    top: 0;

    ${({ isConnected }) =>
        isConnected &&
        css`
            width: calc(100% - 10px);
            height: calc(100% - 78px);
        `} &:before {
        ${appearanceStyle};
        content: ' ';
        display: block;
        position: relative;
        height: 100%;
        border-radius: 5px;
        top: 5px;
        width: 100%;
        background-color: black;
        opacity: ${({ isConnected }) => (isConnected ? 0.5 : 0)};
    }
`;

const PlayPauseButton = styled.div`
    ${appearanceStyle};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    opacity: ${({ isConnected }) => (isConnected ? 1 : 0)};

    ${({ isConnected }) =>
        isConnected &&
        css`
            height: 100%;
            width: 100%;
        `} & img {
        cursor: pointer;
    }
`;
const CastStream = styled(Icon)`
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 99;
    height: 30px;
    width: 30px;
    cursor: pointer;
`;

export {
    FixedVideoContainer,
    FloatingStream,
    CloseStream,
    CastStream,
    CastStreamWrapper,
    PlayPauseButton,
};
