import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card, CardContent, CardHeader } from 'ui/Card';
import Wrapper from '../../../ui/Wrapper';
import { byPlatform } from 'utils/platforms';
import Text from '../../../ui/Text';
import { TableColumn, TableRow, TableSubHeader } from 'ui/Table';
import { BACKGROUNDS, COLORS } from '../../../themes';
import ResultsREST from '../../Results/ResultsREST';
import moment from 'moment';
import DerbyVisionAPI from '../../../common/api/DerbyVisionAPI';
import { showModal } from 'common/actions/uiActions';
import { priority } from 'configs/layout';
import { connect } from 'react-redux';
import playIcon from '../../../images/icons/white-play.png';
import replaysThumb from '../../../images/stream/replays_thumbnail.jpg';
import Icon from 'ui/Icon';
import productSelector from 'common/selectors/productSelector';
import { getCountryCode, getSelectedDate } from 'common/selectors/raceDaySelector';
import trophyFirst from 'images/icons/trophy_1st.svg';
import trophySecond from 'images/icons/trophy_2nd.svg';
import trophyThird from 'images/icons/trophy_3rd.svg';

const POSITION_ICONS = [trophyFirst, trophySecond, trophyThird];

const ReplayWrapper = styled.div`
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    cursor: pointer;
    background: black url(${replaysThumb}) no-repeat center center;
    background-size: cover;

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        opacity: 0.8;
        background: linear-gradient(to bottom, transparent, #000);
    }
`;

const availableCountryReplays = ['DK', 'SE'];

const RaceCardReplays = ({
    disabledRaceNumbers,
    date,
    track,
    product,
    selectedRaceIndex,
    races,
    countryCode,
    showReplayPopup,
}) => {
    const [results, setResults] = useState({});
    const [show, setShow] = useState(false);
    const [winners, setWinners] = useState([]);
    const [replayUrl, setReplayUrl] = useState('');

    useEffect(() => {
        let isMounted = true;
        const resultTimeOut = setTimeout(() => {
            ResultsREST.fetchPoolResults(
                moment(date).format('YYYY-MM-DD'),
                track.id,
                product.id === 'VP' ? 'V' : product.id, // while V&P doesn't have results, show Vinder results instead
                track.id // ???
            ).then(data => {
                if (data.success && isMounted) {
                    setResults(data.data);
                }
            });
        }, 1000);
        return () => {
            clearTimeout(resultTimeOut);
            isMounted = false;
        };
    }, [date, product.id, track.id]);

    useEffect(() => {
        let isMounted = true;
        const raceNumber = races[selectedRaceIndex]?.raceNumber;
        if (Object.keys(results).length && disabledRaceNumbers.includes(raceNumber)) {
            setWinners(sortWinners(results[selectedRaceIndex]?.legs[0]?.winners).slice(0, 3));

            if (availableCountryReplays.includes(countryCode)) {
                DerbyVisionAPI.getReplayUrl({
                    countryCode,
                    date,
                    raceNumber,
                    trackCode: track.code,
                    trackId: track.id,
                })
                    .then(res => {
                        if (res.data.data.length && isMounted) {
                            setReplayUrl(res.data.data);
                        }
                    })
                    .catch(e => console.error(e));
            }
        } else {
            setShow(false);
        }

        return () => {
            isMounted = false;
        };
    }, [
        selectedRaceIndex,
        results,
        races,
        disabledRaceNumbers,
        countryCode,
        date,
        track.code,
        track.id,
    ]);

    useEffect(() => {
        setShow(winners?.length);
    }, [winners]);

    const onReplayClick = () => {
        showReplayPopup({
            replayLink: replayUrl,
            customPopup: true,
        });
    };

    const sortWinners = winners => {
        return winners
            .filter(item => parseInt(item.finishPos, 10) > 0)
            .sort((a, b) => (parseInt(a.finishPos, 10) > parseInt(b.finishPos, 10) ? 1 : -1));
    };

    const getOdds = (productId, winner) =>
        (winner[productId === 'P' ? 'pOdds' : 'vOdds'] / 100).toFixed(2);

    return show ? (
        <Wrapper padding="10px 20px">
            <Card>
                <CardHeader>
                    <Text size={byPlatform('12', '16', '16')} white bold padding="5px 0">
                        Replays & Top 3
                    </Text>
                </CardHeader>
                <TableSubHeader style={{ padding: '0 25px 0 35px' }}>
                    <TableRow style={{ flex: '1 1 auto' }}>
                        <TableColumn grow={0.05}>Plac.</TableColumn>
                        <TableColumn grow={0.8}>Heste</TableColumn>
                        {product.id === 'VP' ? (
                            <>
                                <TableColumn grow={0.2}>V-odds</TableColumn>
                                <TableColumn grow={0.2}>P-odds</TableColumn>
                            </>
                        ) : (
                            <TableColumn grow={0.2}>
                                {product.id === 'P' ? 'P-odds' : 'V-odds'}
                            </TableColumn>
                        )}
                    </TableRow>
                    {byPlatform(
                        null,
                        availableCountryReplays.includes(countryCode) && (
                            <TableColumn basis={'250px'} />
                        )
                    )}
                </TableSubHeader>
                <CardContent
                    style={{
                        display: 'flex',
                        padding: '10px 25px',
                        alignItems: byPlatform('unset', 'center'),
                        flexDirection: byPlatform('column', 'row'),
                    }}
                >
                    <Wrapper padding={'0'} style={{ flex: '1 1 auto' }}>
                        {winners.map((winner, index) => (
                            <Wrapper
                                padding="10px 0px 10px 10px"
                                key={index}
                                style={{
                                    borderRadius: 5,
                                    background: index % 2 ? COLORS.white : BACKGROUNDS.grey,
                                }}
                            >
                                <TableRow>
                                    <TableColumn grow={0.05}>
                                        <Icon
                                            src={POSITION_ICONS[parseInt(winner.finishPos) - 1]}
                                            size={'30'}
                                        />
                                    </TableColumn>
                                    <TableColumn grow={0.8}>
                                        <Text
                                            bold
                                            black
                                            padding="0"
                                            size="x07"
                                        >{`${winner.startPos}. ${winner.horse}`}</Text>
                                    </TableColumn>
                                    {product.id === 'VP' ? (
                                        <>
                                            <TableColumn grow={0.2}>
                                                <Text bold black padding="0" size="x07">
                                                    {getOdds('V', winner)}
                                                </Text>
                                            </TableColumn>
                                            <TableColumn grow={0.2}>
                                                <Text bold black padding="0" size="x07">
                                                    {getOdds('P', winner)}
                                                </Text>
                                            </TableColumn>
                                        </>
                                    ) : (
                                        <TableColumn grow={0.2}>
                                            <Text bold black padding="0" size="x07">
                                                {getOdds(product.id, winner)}
                                            </Text>
                                        </TableColumn>
                                    )}
                                </TableRow>
                            </Wrapper>
                        ))}
                    </Wrapper>
                    {availableCountryReplays.includes(countryCode) && (
                        <Wrapper
                            padding={'0'}
                            width={byPlatform('100%', 'auto')}
                            margin={byPlatform('20px auto 10px', '0 0 0 40px')}
                            maxWidth={byPlatform('100%', '210px')}
                            height={byPlatform('200px', '120px')}
                            style={{
                                flex: byPlatform('1 1 auto', '1 1 250px'),
                            }}
                        >
                            <ReplayWrapper onClick={onReplayClick}>
                                <Icon
                                    src={playIcon}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                                <Text
                                    size={byPlatform('x2', 'x07')}
                                    bold
                                    color={'#FFFFFF'}
                                    style={{
                                        position: 'absolute',
                                        bottom: byPlatform('30px', '10px'),
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >{`${product.name}, ${track.name} - Løb ${races[selectedRaceIndex]?.raceNumber}`}</Text>
                            </ReplayWrapper>
                        </Wrapper>
                    )}
                </CardContent>
            </Card>
        </Wrapper>
    ) : null;
};

export default connect(
    state => ({
        product: productSelector(state),
        date: getSelectedDate(state),
        countryCode: getCountryCode(state),
    }),
    dispatch => ({
        showReplayPopup: data => dispatch(showModal('REPLAY', priority.replays, data)),
    })
)(RaceCardReplays);
