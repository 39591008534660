import React, { Component } from 'react';
import { InputWrapper } from './Input';
import styled from 'styled-components';
import { ExpandableArrow } from 'ui/Arrow';
import { BodyWrapper } from 'ui/Accordion';
import { BACKGROUNDS, COLORS } from 'themes';

const dropDownMargin = `10px`;
const dropDownHeight = `30px`;
const dropDownBorderRadius = `25px`;
const dropDownLeftIndent = `25px`;
const optionPadding = `10px`;
const animationSpeedMs = `400ms`;
const optionTextAlign = `left`;

const getExpandedStyles = (props) => {
    return props.expanded
        ? `border-bottom-left-radius: 0;
           border-bottom-right-radius: 0;
           margin-bottom: 0;`
        : ``;
};

export const DropDownWrapper = styled.div`
    margin: ${dropDownMargin};
    height: calc(${(props) => props.height || dropDownHeight} + ${dropDownMargin} * 2);
`;

const SelectBox = styled(InputWrapper)`
    padding-left: ${dropDownLeftIndent};
    padding-right: ${dropDownLeftIndent};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 14px;
    transition: ${(props) => (props.expanded ? `none` : ` border-radius ${animationSpeedMs} ease`)};
    transition-delay: ${(props) => (props.expanded ? `0ms` : animationSpeedMs)};
    margin: 0;
    border-radius: ${(props) => {
        const borderRadius = props.rounded || dropDownBorderRadius;
        if (props.expanded) {
            return `${borderRadius} ${borderRadius} 0 0`;
        }
        return borderRadius;
    }};
    height: ${(props) => props.height || dropDownHeight};
    background: ${(props) => props.background || BACKGROUNDS.lightGrey};
    ${getExpandedStyles};
`;

const DropDownBody = styled(BodyWrapper)`
    opacity: 1;
`;

const OptionsList = styled.ul`
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 0;
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 10px;
    width: auto;
    background: ${(props) => props.background || BACKGROUNDS.lightGrey};
    border-bottom-left-radius: ${(props) => props.rounded || dropDownBorderRadius};
    border-bottom-right-radius: ${(props) => props.rounded || dropDownBorderRadius};
    cursor: pointer;
    position: relative;
    z-index: 99999;
    transition-delay: 0ms;
    text-align: ${optionTextAlign};
`;

const Option = styled.li`
    list-style-type: none;
    border-top: 1px solid #f2f2f2;
    padding: ${optionPadding};
    color: ${(props) => (props.selected ? COLORS.blue : COLORS.primaryText)};
    font-size: 14px;
    padding-left: ${dropDownLeftIndent};
    cursor: pointer;
    height: ${(props) => props.height || dropDownHeight};
`;

const Placeholder = styled.span`
    color: ${COLORS.placeholderText};
    font-weight: 900;
`;
const Selection = styled.span`
    color: ${COLORS.grey};
`;

class DropDown extends Component {
    constructor() {
        super();
        this.state = {
            expanded: false,
            value: '',
        };
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.collapse);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.collapse);
    }

    setWrapperRef = (node) => {
        this.wrapperRef = node;
    };

    toggle = () => {
        this.setState({ expanded: !this.state.expanded });
    };

    collapse = (e) => {
        const domNode = this.wrapperRef;
        if (domNode && !domNode.contains(e.target)) {
            this.setState({ expanded: false });
        }
    };

    select = (value) => {
        this.setState({ value, expanded: false });
        this.props.onChange && this.props.onChange(value);
    };

    onChange = (e) => {
        this.setState({ value: e.target.value, expanded: false });
        this.props.onChange && this.props.onChange(e.target.value);
    };

    renderOptions = () => {
        return this.props.options.map((option) => (
            <Option
                {...this.props}
                key={option}
                onClick={() => this.select(option)}
                selected={option === this.state.value}
            >
                {option === this.state.value && !this.props.disableIcon && '✔ '}
                {option}
            </Option>
        ));
    };

    render() {
        const DropDownWrapperComponent = this.props.DropDownWrapperComponent || DropDownWrapper;

        return (
            <DropDownWrapperComponent ref={this.setWrapperRef}>
                <SelectBox {...this.props} expanded={this.state.expanded} onClick={this.toggle}>
                    {this.state.value ? (
                        <Selection {...this.props}>{this.state.value}</Selection>
                    ) : (
                        <Placeholder>{this.props.placeholder || this.props.options[0]}</Placeholder>
                    )}
                    <ExpandableArrow expanded={this.state.expanded} />
                </SelectBox>
                <DropDownBody expanded={this.state.expanded}>
                    <OptionsList
                        rounded={this.props.rounded}
                        background={this.props.background}
                        expanded={this.state.expanded}
                    >
                        {this.renderOptions()}
                    </OptionsList>
                </DropDownBody>
            </DropDownWrapperComponent>
        );
    }
}

export default DropDown;
