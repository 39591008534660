import moment from 'moment';
import { PRODUCT_COLORS, PRODUCT_HOVER_COLORS, PRODUCTS_PRIORITIES } from 'configs/products';
import { COLORS } from 'features/EventBoard/theme';
import { ProductIds, ProductsJackpot, Shortcut } from 'features/EventBoard/server/calendar';
import { SERVER_DATE_FORMAT } from 'utils/date';

export const createProductShortcuts = (shortcuts: Shortcut[]) => {
    return shortcuts
        .map(shortcut => {
            return {
                ...shortcut,
                priority: PRODUCTS_PRIORITIES[shortcut?.product],
                color: PRODUCT_COLORS[shortcut?.product] || COLORS.defaultShortcutBg,
                hoverColor: PRODUCT_HOVER_COLORS[shortcut?.product] || COLORS.defaultShortcutBg,
                raceDate: moment(shortcut.raceDate).format(SERVER_DATE_FORMAT),
            };
        })
        .sort((a, b) => a.priority - b.priority);
};

export const sortProducts = (productIds: ProductIds = []) => {
    return productIds.sort((productIdA, productIdB) => {
        const priorityA = PRODUCTS_PRIORITIES[productIdA] || -1;
        const priorityB = PRODUCTS_PRIORITIES[productIdB] || -1;
        return priorityA - priorityB;
    });
};

export const getJackpotProducts = (productsWithJackpot: Array<ProductsJackpot>) => {
    return productsWithJackpot?.length ? productsWithJackpot.map(jackpot => jackpot.product) : [];
};
