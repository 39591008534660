import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import productSelector from 'common/selectors/productSelector';

const useCombinationPrice = () => {
    const selectedProduct = useSelector((state) => productSelector(state));

    const [combinationPrice, setCombinationPrice] = useState(
        selectedProduct.getBasicPrice()
    );

    useEffect(
        () => {
            setCombinationPrice(selectedProduct.getBasicPrice());
        },
        [selectedProduct.id]
    );

    return [combinationPrice, setCombinationPrice];
};

export default useCombinationPrice;
