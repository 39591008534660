import React, { Component } from 'react';
import Keyboard, { KeyboardButton } from 'react-screen-keyboard';
import './ViewPopup.css';
import 'react-screen-keyboard/src/Keyboard.css';
import { hideKeyboard } from 'ui/ScreenKeyboard/actions';
import { connect } from 'react-redux';

/**
 *  Component migrated from bet25 sports
 *  thus it is doesn't use styled-component and UI componentes from derby
 *  and contains deprected methods
 *  should be reworked
 */
class ViewKeyboardPopup extends Component {
    inputNode = null;
    callbackValue = '';

    constructor() {
        super();

        this.state = {
            inputNodeStatus: false,
        };
    }

    onCloseBtn = () => {
        this.props.hideKeyboard(this.callbackValue);
    };

    getValidValue() {
        let value = this.callbackValue;

        /* remove all leading zeros */
        value = value.replace(/\b0+/g, '');

        /* add zero if first char is comma */
        value = value.replace(/^,/g, '0,');

        /* leave just one comma in there more that one */
        var splitedValue = value.split(',');
        value = splitedValue.shift() + (splitedValue.length ? ',' + splitedValue.join('') : '');

        return value;
    }

    onClickKeyboardOK = () => {
        this.props.hideKeyboard(this.getValidValue());
    };

    onChangeInputHandler = (event) => {
        this.callbackValue = event.target.value;
    };

    getInputRef = (input) => {
        this.inputNode = input;

        if (!this.state.inputNodeStatus) {
            this.setState({ inputNodeStatus: true });
        }
    };

    render() {
        const DigitalLayout = {
            symbolsKeyValue: '123',
            layout: [['10', '20', '30', '50', '100', '200', '300', '500', '1000']],
        };
        const keyboardContent = (
            <section className="view-popup">
                <div className="view-popup-background" />
                <div className="view-keyboard-popup-wrapper without-symbol-btn">
                    <input type="text" onInput={this.onChangeInputHandler} ref={this.getInputRef} />

                    {this.state.inputNodeStatus ? (
                        <Keyboard
                            inputNode={this.inputNode}
                            rightButtons={[
                                <KeyboardButton
                                    key="2"
                                    onClick={this.onCloseBtn}
                                    value="Annuller"
                                    classes="keyboard-cancel-button"
                                />,
                                <KeyboardButton
                                    onClick={this.onClickKeyboardOK}
                                    value="OK"
                                    key="1"
                                    classes="keyboard-submit-button"
                                />,
                            ]}
                            layouts={[DigitalLayout]}
                        />
                    ) : null}
                </div>
            </section>
        );

        return keyboardContent;
    }
}

const mapStateToProps = () => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideKeyboard: (value) => {
            dispatch(hideKeyboard(value));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewKeyboardPopup);
