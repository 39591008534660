import React from 'react';
import { Col, Row } from 'react-grid-system';
import Wrapper from 'ui/Wrapper';
import Button from 'ui/controls/Button';
import Text from 'ui/Text';
import styled from 'styled-components';
import { BACKGROUNDS, COLORS } from 'themes';

const SelectionView = styled.div`
    width: 25px;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
    color: ${COLORS.white};
    background: ${BACKGROUNDS.primary};
    border-radius: 15px;
    font-weight: bold;
    text-align: center;
    margin: 5px 1px 0;
    display: inline-block;
`;

const LegPicks = ({
    index,
    selections,
    raceNumber,
    lockedLegs,
    toggleLegStatus,
}) => {
    const isLocked = lockedLegs.includes(raceNumber) && selections.length !== 0;

    return (
        <Wrapper padding="5px">
            <Row>
                <Col xs={2} style={{ margin: '0px', padding: '0px' }}>
                    <Text
                        size="12px"
                        grey
                        bold="500"
                        padding="9px 0px"
                        align="center"
                    >
                        {index}.
                    </Text>
                </Col>

                <Col
                    xs={7}
                    style={{
                        margin: '0px',
                        padding: '0px',
                    }}
                >
                    {selections.length > 0 ? (
                        selections.map((item, index) => (
                            <SelectionView key={index}>{item}</SelectionView>
                        ))
                    ) : (
                        <Text
                            size="12px"
                            grey
                            bold="500"
                            padding="9px 0px"
                            align="left"
                        >
                            -
                        </Text>
                    )}
                </Col>

                <Col
                    xs={3}
                    style={{
                        margin: '0px',
                        padding: '0px',
                        textAlign: 'right',
                    }}
                >
                    {!isLocked ? (
                        <Button
                            size="x05"
                            primary
                            onClick={() => toggleLegStatus(raceNumber)}
                            {...(selections.length === 0
                                ? { disabled: true }
                                : {})}
                        >
                            ÅBEN
                        </Button>
                    ) : (
                        <Button
                            red
                            size="x05"
                            onClick={() => toggleLegStatus(raceNumber)}
                        >
                            LUKKET
                        </Button>
                    )}
                </Col>
            </Row>
        </Wrapper>
    );
};

export default LegPicks;
