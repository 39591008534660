import RequestHandler from './RequestHandler';

const { post } = RequestHandler();

const ResultsREST = () => ({
    fetchTracks: (date) =>
        post('fetchTracks', {
            date: date.toString(),
        }),
    fetchPoolResults: (date, trackId, productId, actualTrack) =>
        post('fetchPoolResults', {
            date: date,
            trackId,
            poolType: productId,
            actualTrack,
        }),
});

export default ResultsREST();
