import { MutableRefObject, useEffect } from 'react';

const useClickAnywhere = <T>(
    handler: (e: Event) => void,
    ref?: MutableRefObject<T>
) => {
    useEffect(() => {
        const handleClickOutside = (event: Event) => {
            //@ts-ignore
            if (ref && ref.current && !ref.current.contains(event.target)) {
                handler(event);
            }
        };

        document.addEventListener(
            'click',
            ref ? handleClickOutside : handler,
            true
        );

        return () => {
            document.removeEventListener(
                'click',
                ref ? handleClickOutside : handler,
                true
            );
        };
    });
};

export default useClickAnywhere;
