import { useQuery } from '@tanstack/react-query';
import { request } from 'utils/server';
import { CalendarDataByDate } from 'features/EventBoard/server/calendar';

interface UseCalendarQueryProps {
    date: string;
    enabled?: boolean;
    useRefetchInterval?: boolean;
}

interface QueryParameters {
    date: string;
}

const fetchCalendar = async ({ date }: QueryParameters) => {
    const response = await request<CalendarDataByDate, QueryParameters>(
        'InfoService',
        'GET',
        '/calendar',
        { date }
    );

    if (!response.success) {
        throw new Error(response.errorMessage);
    }

    return response.data;
};

const initialData = {
    raceDays: [],
    shortCuts: [],
    fromDate: '',
    toDate: '',
    currentDate: '',
} as CalendarDataByDate;

const useCalendarQuery = ({
    date,
    enabled = true,
    useRefetchInterval = false,
}: UseCalendarQueryProps) => {
    const { status, data, error, isSuccess, isLoading, refetch } = useQuery<
        unknown,
        Error,
        CalendarDataByDate
    >({
        queryKey: ['calendar', date],
        queryFn: () => fetchCalendar({ date }),
        enabled,
        staleTime: 60 * 5 * 1000,
        refetchInterval: () => (useRefetchInterval ? 60 * 1000 : false),
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
    });

    return {
        status,
        data: data ?? initialData,
        error,
        isSuccess,
        isLoading,
        refetchCalendar: refetch,
    };
};

export default useCalendarQuery;
