import React from 'react';
import styled from 'styled-components';
import Spinner from 'ui/Spinner';
import Button from 'ui/controls/Button';
import { BACKGROUNDS } from 'themes';

const TransformWrapper = styled.div`
    width: auto;
    height: 50px;
    text-align: center;
    margin: 0px auto;
`;

const Label = styled.span`
    opacity: ${(props) => props.opacity};
    transition: opacity 0.2s;
    font-size: ${(props) => props.fontSize};
`;

const ExtendedButton = styled(Button)`
    transition-delay: 0.2s;
    transition: width 0.2s;
    margin: 0px;
    padding: 10px;
    line-height: 30px;
    border-radius: 25px;
    overflow: hidden;
    position: relative;
    &:disabled {
        background: ${BACKGROUNDS.grey};
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.75);
        opacity: 1;
    }
`;

export default class AnimatedButton extends React.Component {
    constructor() {
        super();

        this.state = {
            isPending: false,
        };
    }

    onClickHandler = () => {
        this.setState({
            isPending: true,
        });

        if (this.props.onClickHandler) {
            this.props.onClickHandler();
        }
    };

    resetStateAction = () => {
        this.setState({
            isPending: false,
        });
    };

    componentDidMount() {
        this.props.children(this.resetStateAction);
    }

    render() {
        const { TransformWrapperStyles = {} } = this.props;

        return (
            <TransformWrapper style={TransformWrapperStyles}>
                <ExtendedButton
                    width={!this.state.isPending ? '250px' : '52px'}
                    size="x2"
                    isPending={this.state.isPending}
                    {...this.props}
                    onClick={this.onClickHandler}
                >
                    {!this.state.isPending ? (
                        <Label
                            {...this.props.ui}
                            opacity={!this.state.isPending ? 1 : 0}
                        >
                            {this.props.label}
                        </Label>
                    ) : (
                        <Spinner black size={this.props.spinnerSize || 'x1'} />
                    )}
                </ExtendedButton>
            </TransformWrapper>
        );
    }
}
