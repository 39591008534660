import React, { useState } from 'react';
import BetSlipPopup from 'features/BetSlip/platforms/Desktop';
import { terminal } from 'configs/layout';
import uuid from 'uuid/v4';

const BetslipPopupContainer = () => {
    const [betslipKey, setBetslipKey] = useState(undefined);

    const resetTerminalBetslip = () => {
        setBetslipKey(uuid());
    };

    return (
        <div
            style={{
                position: 'absolute',
                top: '-40px',
                right: '25px',
                width: terminal.homepage.betslipWidth,
            }}
        >
            <BetSlipPopup key={betslipKey} onBetPerformed={resetTerminalBetslip} />
        </div>
    );
};

export default BetslipPopupContainer;
