import styled from 'styled-components';
import Button from 'ui/controls/Button';
import Icon from 'ui/Icon';
import Spinner from 'ui/Spinner';
import { BACKGROUNDS, COLORS } from 'themes';
import Text from 'ui/Text';
import Slick from 'react-slick';
import { BackButton } from '../../layouts/sections/OldDesktopSidebar/styled';

const Wrapper = styled.div`
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
`;

const BarcodeContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 85px;
    width: 90%;
    margin: auto;
    height: 182px;
    background-color: #fff;
    border-radius: 15px;
    border: solid 1px #00000040;
    box-shadow: 0 0 25px #00000040;
`;

const OutdatedBarcodeContainer = styled(BarcodeContainer)`
    height: 130px;
`;

const BarCodeText = styled.p`
    margin: 0;
    color: ${COLORS.grey};
    font-size: 16px;
    font-weight: bold;
`;

const OutdatedText = styled(BarCodeText)`
    width: 80%;
    text-align: center;
    margin-top: -15px;
    font-size: 15px;
`;

const OutdatedSubText = styled(BarCodeText)`
    font-size: 13px;
    text-align: center;
    margin-top: 2px;
`;

const TitlePad = styled.div`
    display: flex;
    justify-content: center;
    top: -46px;
    background: ${BACKGROUNDS.primary};
    position: relative;
    height: 298px;
    width: 100%;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
`;

const Title = styled.p`
    margin-top: 90px;
    font-size: 24px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
`;

const ShopListTitle = styled(Title)`
    margin-top: 110px;
`;

const StyledIcon = styled(Icon)`
    top: 70px;
    left: 20px;
    position: absolute;
`;

const ButtonContainer = styled.div`
    min-height: 200px;
    margin: 75px 0 35px 0;
    display: flex;
    flex-direction: column;
    text-align: center;
`;

const ButtonStyled = styled(Button)`
    width: 285px;
    height: 60px;
    border-radius: 35px;
`;

const ButtonText = styled.p`
    margin: 0;
    color: ${COLORS.grey};
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
`;

const SpinnerStyled = styled(Spinner)`
    position: absolute;
    top: 200px;
`;

const Image = styled.img`
    width: 280px;
    height: 99px !important;
    margin-bottom: 17px;
`;

const Wrapp = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: linear-gradient(#05a358, #34b474);
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 0px 20px 0 20px;
`;

const MainIcon = styled(Icon)`
    width: 274px;
    height: 274px;
    border-radius: 50%;
    box-shadow: 0 0 50px #00000040;
    margin-bottom: 22px;
`;

const MainText = styled.p`
    margin: 0 0 20px 0;
    color: #ffffff;
    font-size: 46px;
    line-height: 54px;
    text-transform: uppercase;
    text-align: center;
`;

const LinkText = styled.p`
    margin: 0;
    font-size: 26px;
    color: #8bf7c3;
    text-align: center;
`;

const SumTextContainer = styled.div`
    display: flex;
    text-transform: UPPERCASE;
    padding: 10px;
`;

const SumText = styled(Text)`
    font-weight: bold;
    color: ${COLORS.black};
`;

const ShopListHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${BACKGROUNDS.primary};
    position: relative;
    height: 70px;
    width: 100%;
`;

const SlickSlider = styled(Slick)`
    .slick-dots li {
        margin: 0 0 5px 0 !important;
    }

    .slick-dots li button:before {
        font-size: 12px !important;
    }

    .slick-dots li button:focus:before {
        opacity: 0.25;
    }

    .slick-dots li.slick-active button:focus:before {
        opacity: 1;
    }
`;

const BackBtn = styled(BackButton)`
    position: absolute;
    top: 20px;
    left: 15px;
`;

export {
    BackBtn,
    SlickSlider,
    Wrapper,
    BarcodeContainer,
    OutdatedBarcodeContainer,
    BarCodeText,
    OutdatedText,
    OutdatedSubText,
    TitlePad,
    Title,
    ShopListTitle,
    ButtonStyled,
    ButtonText,
    SpinnerStyled,
    Wrapp,
    Container,
    LinkText,
    MainText,
    MainIcon,
    Spinner,
    Image,
    StyledIcon,
    ButtonContainer,
    SumTextContainer,
    SumText,
    ShopListHeader,
};
