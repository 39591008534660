import React from 'react';
import { connect } from 'react-redux';
import UserSession from 'common/adapters/UserSession';
import { IS_TERMINAL } from 'utils/platforms';
import { StyledWrapper, StyledText, StyledButton } from './styled';
import { get } from 'lodash';
import getTexts from 'utils/localization';
import combinationsCountSelector from 'common/selectors/combinationsCountSelector';

const t = getTexts();

class PlayAndPay extends React.Component {
    proceedPlayAndPlay = () => {
        this.props.performSale();
    };

    buttonShouldBeShown = () => {
        const { combinationsCount } = this.props;

        const playAndPayAllowed = get(this.props.shopConfig, 'data.playAndPayAllowed', null);

        const allowedOnTerminal =
            IS_TERMINAL &&
            playAndPayAllowed &&
            UserSession.isLowAmount({}) &&
            !UserSession.isManagerEntrance() &&
            combinationsCount > 0;

        return allowedOnTerminal && this.props.conditionToShow;
    };

    render() {
        return (
            this.buttonShouldBeShown() && (
                <StyledWrapper styles={this.props.styles}>
                    <StyledButton onClick={this.proceedPlayAndPlay} styles={this.props.styles}>
                        <StyledText>{t.Terminal.PlayAndPay.button}</StyledText>
                    </StyledButton>
                </StyledWrapper>
            )
        );
    }
}
const mapStateToProps = (state) => {
    return {
        shopConfig: state.ShopConfig,
        combinationsCount: combinationsCountSelector(state),
    };
};

export default connect(mapStateToProps)(PlayAndPay);
