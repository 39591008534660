import React, { useMemo } from 'react';

// UI Components
import { Card, CardContent, CardHeader } from 'ui/Card';
import Icon from 'ui/Icon';
import Text from 'ui/Text';
import { TableColumn, TableRow, TableSubHeader } from 'ui/Table';
import Wrapper from 'ui/Wrapper';
import { ReplayWrapper } from './styled';

// Common Utilities and APIs
import usePoolResultsQueryForReplay from 'features/TrackPage/hooks/usePoolResultsQueryForReplay';
import useReplayQuery from 'features/TrackPage/hooks/useReplayQuery';
import useModals from 'common/hooks/useModals';
import { ProductId } from 'features/EventBoard/server/calendar';
import { WinnersProps } from 'features/ResultsNew/hooks/usePoolResultsQuery';

// Images
import playIcon from 'images/icons/white-play.png';
import trophyFirst from 'images/icons/trophy_1st.svg';
import trophySecond from 'images/icons/trophy_2nd.svg';
import trophyThird from 'images/icons/trophy_3rd.svg';

// Theme-related
import { BACKGROUNDS, COLORS } from 'themes';

// Platform-specific utilities
import { byPlatform } from 'utils/platforms';

// Layout Configuration
import { priority } from 'configs/layout';

const POSITION_ICONS = [trophyFirst, trophySecond, trophyThird];

const availableCountryReplays = ['DK', 'SE'];

const RaceCardReplays = () => {
    const { show: showModal } = useModals();

    // Retrieve necessary data using custom hooks
    const { results, winners, productId, productName, trackName, raceNumber } =
        usePoolResultsQueryForReplay();
    const { replayUrl, countryCode } = useReplayQuery(!!winners?.length);

    // Calculate 'show' value using useMemo to avoid re-renders
    const show = useMemo(() => {
        return !!results?.length && !!replayUrl && !!winners?.length;
    }, [results, replayUrl, winners]);

    // Function to handle replay click
    const onReplayClick = () => {
        showModal('REPLAY', priority.replays, {
            replayLink: replayUrl,
            customPopup: true,
        });
    };

    const getOdds = (productId: ProductId, winner: WinnersProps) => {
        return (winner[productId === 'P' ? 'pOdds' : 'vOdds'] / 100).toFixed(2);
    };

    return show ? (
        <Wrapper padding={'0'} margin={'20px 0 0'}>
            <Card>
                <CardHeader>
                    <Text size={byPlatform('12', '16', '16')} white bold padding="5px 0">
                        Replays & Top 3
                    </Text>
                </CardHeader>
                <TableSubHeader style={{ padding: '0 25px 0 35px' }}>
                    <TableRow style={{ flex: '1 1 auto' }}>
                        <TableColumn grow={0.05}>Plac.</TableColumn>
                        <TableColumn grow={0.8}>Heste</TableColumn>
                        {productId === 'VP' ? (
                            <>
                                <TableColumn grow={0.2}>V-odds</TableColumn>
                                <TableColumn grow={0.2}>P-odds</TableColumn>
                            </>
                        ) : (
                            <TableColumn grow={0.2}>
                                {productId === 'P' ? 'P-odds' : 'V-odds'}
                            </TableColumn>
                        )}
                    </TableRow>
                    {byPlatform(
                        null,
                        availableCountryReplays.includes(countryCode) && (
                            <TableColumn basis={'250px'} />
                        )
                    )}
                </TableSubHeader>
                <CardContent
                    style={{
                        display: 'flex',
                        padding: '10px 25px',
                        alignItems: byPlatform('unset', 'center'),
                        flexDirection: byPlatform('column', 'row'),
                    }}
                >
                    <Wrapper padding={'0'} style={{ flex: '1 1 auto' }}>
                        {winners.map((winner, index) => (
                            <Wrapper
                                padding="10px 0px 10px 10px"
                                key={index}
                                style={{
                                    borderRadius: 5,
                                    background: index % 2 ? COLORS.white : BACKGROUNDS.grey,
                                }}
                            >
                                <TableRow>
                                    <TableColumn grow={0.05}>
                                        <Icon
                                            src={POSITION_ICONS[parseInt(winner.finishPos) - 1]}
                                            size={'30'}
                                        />
                                    </TableColumn>
                                    <TableColumn grow={0.8}>
                                        <Text
                                            bold
                                            black
                                            padding="0"
                                            size="x07"
                                        >{`${winner.startPos}. ${winner.horse}`}</Text>
                                    </TableColumn>

                                    {productId === 'VP' ? (
                                        <>
                                            <TableColumn grow={0.2}>
                                                <Text bold black padding="0" size="x07">
                                                    {getOdds('V', winner)}
                                                </Text>
                                            </TableColumn>
                                            <TableColumn grow={0.2}>
                                                <Text bold black padding="0" size="x07">
                                                    {getOdds('P', winner)}
                                                </Text>
                                            </TableColumn>
                                        </>
                                    ) : (
                                        <TableColumn grow={0.2}>
                                            <Text bold black padding="0" size="x07">
                                                {getOdds(productId, winner)}
                                            </Text>
                                        </TableColumn>
                                    )}
                                </TableRow>
                            </Wrapper>
                        ))}
                    </Wrapper>
                    {availableCountryReplays.includes(countryCode) && (
                        <Wrapper
                            padding={'0'}
                            width={byPlatform('100%', 'auto')}
                            margin={byPlatform('20px auto 10px', '0 0 0 40px')}
                            maxWidth={byPlatform('100%', '210px')}
                            height={byPlatform('200px', '170px')}
                            style={{
                                flex: byPlatform('1 1 auto', '1 1 250px'),
                            }}
                        >
                            <ReplayWrapper onClick={onReplayClick}>
                                <Icon
                                    src={playIcon}
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                    }}
                                />
                                <Text
                                    size={byPlatform('x2', 'x07')}
                                    bold
                                    color={'#FFFFFF'}
                                    style={{
                                        position: 'absolute',
                                        bottom: byPlatform('30px', '10px'),
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {`${productName}, ${trackName} - Løb ${raceNumber}`}
                                </Text>
                            </ReplayWrapper>
                        </Wrapper>
                    )}
                </CardContent>
            </Card>
        </Wrapper>
    ) : null;
};

export default RaceCardReplays;
