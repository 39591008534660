import React, { useEffect } from 'react';
import { ContentHeight, Fillable, FullScreen } from 'ui/FullScreen';
import TopBar from 'ui/topbar';
import TopBarText from 'ui/topbar/Text';
import { ConfirmBtn, IconWithIndent } from 'features/UserArea/Withdraw/styled';
import Input from 'ui/controls/Input';
import Spinner from 'ui/Spinner';
import styled from 'styled-components';
import Text from 'ui/Text';
import getTexts from 'utils/localization';

const t = getTexts().userArea.voucher;
const e = getTexts().validation;
const nav = getTexts().userArea.nav;

const Wrapper = styled(Fillable)`
    padding: 30px;
`;

const StyledText = styled(Text)`
    padding: 10px;
`;

const MobileVoucher = (props) => {
    const { setSerial, setCode, confirm, serialValid, codeValid, pending, enableCloseButton } =
        props;

    useEffect(enableCloseButton, []);

    return (
        <FullScreen>
            <ContentHeight>
                <TopBar>
                    <TopBarText className="lg">
                        <IconWithIndent ivoucher size="x2" alt={nav.voucher} color="white" />
                        {nav.voucher}
                    </TopBarText>
                </TopBar>
            </ContentHeight>
            <Wrapper>
                <StyledText black className="text-bold">
                    {t.title}
                </StyledText>
                <StyledText>{t.description}</StyledText>
                <Input
                    placeholder={t.inputSerial}
                    onChange={setSerial}
                    validationError={serialValid ? '' : e.numeric}
                />
                <Input
                    placeholder={t.inputCode}
                    onChange={setCode}
                    validationError={codeValid ? '' : e.required}
                />
                <ConfirmBtn
                    accent
                    size="x3"
                    className="center uppercase block-center"
                    onClick={confirm}
                    disabled={!serialValid || !codeValid}
                >
                    {pending ? <Spinner black /> : t.button}
                </ConfirmBtn>
            </Wrapper>
        </FullScreen>
    );
};

export default MobileVoucher;
