import React from 'react';
import styled from 'styled-components';
import { TableHeader } from 'ui/Table';
import Text from 'ui/Text';
import Countdown from 'ui/Countdown';

const TextDiv = styled(Text).attrs({ as: 'div' })``;

const VRaceCardHeader = props => {
    return (
        <TableHeader>
            <div
                style={{
                    flex: '0 1 auto',
                }}
            >
                <TextDiv
                    white
                    size={18}
                    padding="0px"
                    bold
                    style={{
                        lineHeight: 'inherit',
                    }}
                    flex="0.1 1 auto"
                >
                    {props.product.name}
                </TextDiv>
            </div>
            <time>
                <b style={{ fontWeight: 900 }}>
                    {props.hasResult ? null : (
                        <Countdown
                            raceKey={props.race.trackCode + '-' + props.race.index}
                            time={props.from}
                            timeUTC={props.postTimeUTC}
                            serverTime={props.serverTime}
                            withDay
                        />
                    )}
                </b>
            </time>
        </TableHeader>
    );
};

export default VRaceCardHeader;
