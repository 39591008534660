import styled from 'styled-components';

const Flex = styled.div`
    display: flex;
    margin: ${(props) => (props.margin ? props.margin : '0')};
    padding: ${(props) => (props.padding ? props.padding : '0')};
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.height ? props.height : '100%')};
    flex-flow: ${(props) => (props.direction ? props.direction : 'row')};
    justify-content: ${(props) => (props.justify ? props.justify : 'center')};
    align-items: ${(props) => (props.align ? props.align : 'center')};
    background: ${(props) => (props.background ? props.background : 'none')};
    flex-grow: ${(props) => (props.grow ? props.grow : '0')};
    flex: ${(props) => (props.flex ? props.flex : '1 1 auto')};
    border: ${(props) => (props.border ? props.border : 'none')};
    border-top: ${(props) => (props.borderTop ? props.borderTop : 'none')};
    border-bottom: ${(props) =>
        props.borderBottom ? props.borderBottom : 'none'};
    border-left: ${(props) => (props.borderLeft ? props.borderLeft : 'none')};
    border-right: ${(props) =>
        props.borderRight ? props.borderRight : 'none'};
    font-size: ${(props) => (props.fontSize ? props.fontSize : 'inherit')};
    font-weight: ${(props) => props.fontWeight || 'inherit'};
    cursor: ${(props) => (props.cursor ? props.cursor : 'auto')};
    box-sizing: ${(props) => (props.boxSizing ? props.boxSizing : 'auto')};
`;

export default Flex;
