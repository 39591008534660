import {
    SUBTRACT_MOBILE_BET_COUNT,
    ADD_MOBILE_BET_COUNT,
    RESET_MOBILE_BET_COUNT,
    MOBILE_BET_INIT,
    SET_MOBILE_BET,
    RESET_MOBILE_BET,
    SET_MOBILE_BET_TO_STATE,
    SET_MOBILE_BET_TRACK_READY,
    SET_MOBILE_BET_PRODUCT_READY,
} from './constants';

export const setMobileBetTrackReady = () => {
    return (dispatch) => {
        dispatch({
            type: SET_MOBILE_BET_TRACK_READY,
            payload: true,
        });
    };
};

export const setMobileBetProductReady = () => {
    return (dispatch) => {
        dispatch({
            type: SET_MOBILE_BET_PRODUCT_READY,
            payload: true,
        });
    };
};

export const mobileBetWasSetToState = () => {
    return (dispatch) => {
        dispatch({
            type: SET_MOBILE_BET_TO_STATE,
            payload: true,
        });
    };
};

export const resetMobileBetData = (data) => {
    return (dispatch) => {
        dispatch({
            type: RESET_MOBILE_BET,
            payload: {},
        });
    };
};

export const setMobileBetData = (data) => {
    console.log('SetMobileBetData');
    return (dispatch) => {
        dispatch({
            type: SET_MOBILE_BET,
            payload: data,
        });
    };
};

export const setMobileBetInit = (status) => {
    console.log('MobileBetInit', status);
    return (dispatch) => {
        dispatch({
            type: MOBILE_BET_INIT,
            payload: status,
        });
    };
};

export const subtractMobileBetCount = () => {
    return (dispatch) => {
        dispatch({
            type: SUBTRACT_MOBILE_BET_COUNT,
            payload: 1,
        });
    };
};

export const addMobileBetCount = () => {
    return (dispatch) => {
        dispatch({
            type: ADD_MOBILE_BET_COUNT,
            payload: 1,
        });
    };
};

export const resetMobileBetCount = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_MOBILE_BET_COUNT,
        });
    };
};
