import React from 'react';

const MAX_RESERVES = 2;

const withManualReserves = (WrappedComponent) => {
    return (props) => {
        const {
            race,
            selectedProduct,
            selectedDate,
            trackCode,
            reserves,
            reserveModeEnabled,
            setReserveMode,
        } = props;

        const toggleReserveMode = () => setReserveMode();

        const addReserve = (startNr) => {
            const { addReserve, removeLastReserve } = props;

            if (reserves.length === MAX_RESERVES) {
                removeLastReserve(
                    selectedDate,
                    trackCode,
                    selectedProduct.id,
                    race.index
                );
            }

            addReserve(
                selectedDate,
                trackCode,
                selectedProduct.id,
                race.index,
                startNr
            );
        };

        const removeReserve = (startNr) => {
            const { removeReserve } = props;

            removeReserve(
                selectedDate,
                trackCode,
                selectedProduct.id,
                race.index,
                startNr
            );
        };

        return (
            <WrappedComponent
                {...props}
                reserveModeEnabled={reserveModeEnabled}
                toggleReserveMode={toggleReserveMode}
                addReserve={addReserve}
                removeReserve={removeReserve}
                raceReserves={reserves}
            />
        );
    };
};

export default withManualReserves;
