import React, { lazy, Suspense } from 'react';
import Wrapper from 'ui/Wrapper';
import Text from 'ui/Text';
import Spinner from 'ui/Spinner';
import Button from 'ui/controls/Button';
import BettingHistoryItem from './BettingHistoryItem';
import notFoundAnimation from './history-not-found-animation.json';

import getTexts from 'utils/localization';
import { byPlatform } from 'utils/platforms';
const t = getTexts();

const LottiePlayer = lazy(() => import('react-lottie-player/dist/LottiePlayerLight'));

export default props => {
    return (
        <Wrapper padding={byPlatform('10px 0', '10px')}>
            {props.data && props.data.length > 0 ? (
                props.data.map((item, index) => (
                    <BettingHistoryItem
                        key={index}
                        data={item}
                        mode={props.mode}
                        showModal={props.showModal}
                    />
                ))
            ) : !props.isPending ? (
                <>
                    <Suspense fallback={null}>
                        <LottiePlayer
                            loop
                            animationData={notFoundAnimation}
                            play
                            style={{ width: '100px', height: '100px', margin: '0 auto' }}
                        />
                    </Suspense>
                    <Text align="center" size="x1.5" color="gray">
                        {t.gamesNotFound}
                    </Text>
                </>
            ) : null}

            {props.isPending ? (
                <Wrapper height={'80vh'}>
                    <Spinner size="x2" />
                </Wrapper>
            ) : props.data.length > 0 && props.dataCounter > props.pageNamber * props.pageSize ? (
                <Button secondary size="x2" onClick={props.loadMore} block width="200px">
                    {t.loadMore}
                </Button>
            ) : null}
        </Wrapper>
    );
};
