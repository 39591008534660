import styled from 'styled-components';
import Button from 'ui/controls/Button';
import { BACKGROUNDS, COLORS } from 'themes';
import Mark from 'ui/Mark';
import Icon from 'ui/Icon';

const RateBtn = styled(Button)`
    width: 25%;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 6px;
    background: ${({ secondary, highlightActive }) =>
        secondary || !highlightActive ? '#eceff5' : BACKGROUNDS.primary};
    color: ${({ secondary, highlightActive }) =>
        secondary || !highlightActive ? '#333' : COLORS.white};
    border-radius: 14px;
    font-size: 14px;
    cursor: pointer;
`;

const confirmBtnWidth = 280;

const ConfirmBtn = styled(Button)`
    width: ${confirmBtnWidth}px;
    max-width: 90%;
    box-shadow: 0 0 36px #b5b5b5;
    background: ${BACKGROUNDS.accent};
    margin-top: auto;
    margin-bottom: 60px;
    display: block;
    border-radius: 30px;

    @media (max-height: 580px) {
        margin-bottom: 30px;
    }

    .mobile & {
        //Media queries don't work due to <iframe>
        margin-top: 40px;
    }
`;

const MessageTitle = styled.p`
    text-align: center;
    font-weight: bold;
`;

const Message = styled(Mark)`
    width: 68%;
    margin: 10px auto 30px auto;
    display: block;
`;

const CheckboxLine = styled.p`
    width: 68%;
    border-bottom: 1px solid #cacaca;
    margin: 20px auto;
    padding-bottom: 10px;
    font-size: 14px;
    overflow: auto;
    display: block;
    .agreement-text {
        float: left;
        display: block;
        width: 93%;
        margin: 0;
    }
    input[type='checkbox'] {
        margin: 0;
        float: right;
        display: block;
        width: 7%;
        height: 18px;
    }
`;

const IconWithIndent = styled(Icon)`
    margin-right: 10px;
`;

const PaymentTypeBtn = styled.div`
    border: 3px solid ${({ active }) => (active ? '#0daa13' : '#eceff6')};
    background: #eceff6;
    width: 100%;
    height: 55px;
    display: flex;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    & > img {
        width: 80px;
    }

    .mobile & {
        //Media queries don't work due to <iframe>
        border-radius: 12px;
        width: 280px;
        height: 52px;
        margin: 0 auto 14px;
    }
`;

const PaymentHeader = styled.div`
    width: 100%;
    height: 50px;
    background: ${COLORS.primary};
    color: ${COLORS.white};
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding-top: 14px;
    box-sizing: border-box;
`;

export {
    RateBtn,
    ConfirmBtn,
    MessageTitle,
    Message,
    CheckboxLine,
    IconWithIndent,
    PaymentTypeBtn,
    PaymentHeader,
};
