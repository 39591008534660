import React, { Component, Fragment } from 'react';
import { Col, Row } from 'react-grid-system';
import Label from 'ui/Label';
import { AmountBtn, Error, Input, InputWrapper } from '../styled';
import { numberWithCommasAndDots, thousandsDots } from 'utils/formatters';
import ButtonGroup from 'ui/controls/ButtonGroup';
import {
    COMBINATION_PRICE_SUGGESTIONS,
    DEFAULT_COMBINATION_PRICES,
    MAX_COUPON_AMOUNT,
    MIN_COMBINATION_PRICES,
    SMART_LYN_AMOUNTS,
} from 'configs/products';
import { byPlatform, IS_TERMINAL } from 'utils/platforms';
import { connect } from 'react-redux';
import { showKeyboard } from 'ui/ScreenKeyboard/actions';

import getTexts from 'utils/localization';
import { MAX_SMART_LYN_COUPON_AMOUNT } from 'configs/products';
const t = getTexts();

class BetForm extends Component {
    state = {
        buttonAmount: null,
    };

    applyCombinationPrice = (amount) => {
        if (!this.props.product.decimalsAllowed() || this.props.smartLynMode) {
            // remove all but numbers
            return this.props.applyCombinationPrice(amount.replace(/((?!\d+).)/g, ''));
        }

        // disallow / cut 0 if user starts typing other number than 0
        // so once it's not 0 at the start we remove it from the string
        if (amount.match(/^0\d/)) {
            amount = amount.replace(/^0/, '');
        }

        // leave only characters suitable for float,
        // this regex considers the case when typing is not complete (like "5.")
        const floatSubstring = amount.match(/[+-]?([0-9]+([.,][0-9]*)?|[.,][0-9]+)/g);
        if (floatSubstring) {
            amount = floatSubstring[0];
        }

        this.props.applyCombinationPrice(amount ? amount.replace(',', '.') : 0);
    };

    onCombinationPriceSelected = (amount) => {
        this.applyCombinationPrice(amount);
        this.setState({ buttonAmount: amount }, () => this.applyCombinationPrice(amount));
    };

    clearAmount = () => {
        this.setState({ buttonAmount: null });
        // it's important to have 0 as a possible value when
        // user starts typing float number. So -1 means no value.
        // this.renderPriceInput() uses it to render empty input.
        this.props.applyCombinationPrice(-1);
    };

    onPriceInputFocus = () => {
        this.clearAmount();

        /**TODO create decorator for method */
        if (IS_TERMINAL) {
            this.props.showKeyboard();
        }
    };

    setAmountDefaults = () => {
        const amount = this.props.smartLynMode
            ? SMART_LYN_AMOUNTS[0].toString()
            : `${DEFAULT_COMBINATION_PRICES[this.props.product.id]}`;
        this.props.applyCombinationPrice(amount);
        this.setState({ buttonAmount: amount });
    };

    componentDidUpdate(prevProps) {
        /**TODO create decorator for method */
        if (IS_TERMINAL) {
            let value = this.props.screenKeyboard.value;
            if (prevProps.screenKeyboard.value !== value && value !== '') {
                this.onCombinationPriceSelected(value);
            }
        }
        // setting the default amount when switching to smart lyn and back
        if (this.props.smartLynMode !== prevProps.smartLynMode) {
            this.setAmountDefaults();
        }
    }

    renderPriceInput = () => {
        const { amount } = this.props;

        return (
            <Fragment>
                <Input
                    style={{ boxShadow: 'none' }}
                    placeholder="indtast beløb"
                    width="100%"
                    paddingRight="5px"
                    paddingLeft="5px"
                    value={parseFloat(amount) > -1 ? amount : ''}
                    onChange={(e) => this.applyCombinationPrice(e.target.value)}
                    onFocus={this.onPriceInputFocus}
                />
                {/* This span is needed for pseudo selector */}
                <span />
            </Fragment>
        );
    };

    render() {
        const {
            combinationsCount,
            product,
            isCombinationPriceValid,
            combinationPriceError,
            styles = {},
            isCouponAmountExceed,
            minimumPicksValid,
            maximumPicksValid,
            allLegsLocked,
            amount,
            smartLynMode,
            spilKlub,
        } = this.props;

        const MIN_RATE = MIN_COMBINATION_PRICES[product.id];

        let combinationPriceValues = [];

        if (smartLynMode) {
            combinationPriceValues = SMART_LYN_AMOUNTS.map(
                (amount) => `${numberWithCommasAndDots(amount)} ${t.currency}`
            );
        } else {
            if (COMBINATION_PRICE_SUGGESTIONS[product.id]) {
                combinationPriceValues = COMBINATION_PRICE_SUGGESTIONS[product.id].map(
                    (amount) => `${numberWithCommasAndDots(amount)} ${t.currency}`
                );
            } else {
                combinationPriceValues = [...Array(4).keys()].map(
                    (value, index) =>
                        `${numberWithCommasAndDots(MIN_RATE * (index + 1))} ${t.currency}`
                );
                combinationPriceValues[4] = '25 ' + t.currency;
                combinationPriceValues[5] = '50 ' + t.currency;
            }
        }

        return (
            <Row style={{ paddingTop: '10px', paddingBottom: '10px' }} align="center">
                <Col
                    xs={12}
                    align="center"
                    style={{
                        padding: '0 10px 5px 10px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexWrap: 'wrap',
                        ...styles.ControlCol,
                    }}
                >
                    <ButtonGroup
                        values={combinationPriceValues}
                        key={amount}
                        renderButton={(value, props) => (
                            <AmountBtn
                                {...props}
                                secondary={value !== this.state.buttonAmount}
                                style={styles.AmountBtn}
                            >
                                {value}
                            </AmountBtn>
                        )}
                        onSelect={this.onCombinationPriceSelected}
                    />
                </Col>

                <Fragment>
                    {!smartLynMode && (
                        <Col
                            xs={byPlatform(5, 5)}
                            style={{ ...styles.ControlCol, paddingRight: 0 }}
                        >
                            <Label style={styles.Label}>Antal spil: {combinationsCount}</Label>
                        </Col>
                    )}
                    <Col
                        xs={byPlatform(smartLynMode ? 12 : 7, smartLynMode ? 12 : 7)}
                        style={{ ...styles.ControlCol, paddingRight: '10px' }}
                    >
                        <InputWrapper>{this.renderPriceInput()}</InputWrapper>
                    </Col>
                </Fragment>
                {!isCombinationPriceValid && <Error>{combinationPriceError}</Error>}
                {isCouponAmountExceed && (
                    <Error>{`> ${thousandsDots(
                        smartLynMode ? MAX_SMART_LYN_COUPON_AMOUNT : MAX_COUPON_AMOUNT
                    )}`}</Error>
                )}
                {!minimumPicksValid && !smartLynMode && (
                    <Error>
                        {product.isVButNotDouble() && !spilKlub
                            ? t.betSlip.minimumPicksErrorWithSmartLyn
                            : t.betSlip.minimumPicksError}
                    </Error>
                )}
                {!maximumPicksValid && !smartLynMode && (
                    <Error>{t.betSlip.maximumPicksError}</Error>
                )}
                {allLegsLocked && smartLynMode && <Error>{t.smartLyn.atLeast1LegUnlocked}</Error>}
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        screenKeyboard: state.ScreenKeyboard,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showKeyboard: () => {
            dispatch(showKeyboard());
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(BetForm);
