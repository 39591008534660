import React from 'react';
import styled from 'styled-components';
import Button from 'ui/controls/Button';
import { COLORS } from 'themes';

const StyledWrapper = styled.div`
    position: absolute;
    right: 10px;
`;
const OpretBtn = styled(Button)`
    color: #404757;
    border-radius: 20px;
    font-weight: 900;
    background: #ebcd32;
    padding: 0.8rem 30px;
    @media screen and (max-width: 968px) {
        display: none;
    }
`;
const LoginBtn = styled(Button)`
    padding: 0.8rem;
    border-radius: 5px;
    font-weight: 900;
    @media screen and (max-width: 968px) {
        font-size: 12px;
        color: #fff;
        font-weight: normal;
        background: rgb(1, 1, 1, 0.2) !important;
        border-radius: 5px;
        padding: 13px 25px;
    }
`;

const LoginArea = ({ signUp }) => {
    const logIn = () => {
        window.location.pathname = '/log-ind';
    };

    return (
        <StyledWrapper>
            <OpretBtn mr={2} size={'mdB'} uppercase onClick={() => signUp()}>
                Opret
            </OpretBtn>
            <LoginBtn
                variant={'danger'}
                uppercase
                size={'mdB'}
                style={{
                    background: COLORS.primary,
                }}
                onClick={logIn}
            >
                Log Ind
            </LoginBtn>
        </StyledWrapper>
    );
};

export default LoginArea;
