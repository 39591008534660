/**
 * Universal way to create multitrack codes instead of just 'Xa', considering date
 * To make it working properly across the dates.
 *
 * @param trackCode
 * @param date
 * @return {string}
 */
export function generateUniqueMultitrackTrackCode(trackCode = 'Xa', date = '') {
    return date ? `${trackCode}/${date}` : trackCode;
}

class MultipleTrackSetups {
    /**
     * The keys are the product ids.
     * The values are the {MultipleTrackSetup} instances.
     * @type {Object.<MultipleTrackSetup>}
     */
    data = {};

    /**
     *
     * @param  {Object}                 rawRaceDay    Raw race calendar item from AIS
     * @return {MultipleTrackSetups}
     */
    static unserialize(rawRaceDay) {
        const instance = new MultipleTrackSetups();
        rawRaceDay.multipleTrackPoolSetups.multipleTrackPoolSetup.forEach(
            (row) => {
                const trackIds = row.hostTrack.trackData.map(
                    (track) => track.track.code
                );
                const legs = row.legInfo.legInfos.map(
                    ({ legNr, raceNr, hostTrack }) =>
                        new Leg(legNr, raceNr, hostTrack.track.code)
                );
                instance.data[row.betType.code] = new MultipleTrackSetup(
                    trackIds,
                    legs,
                    row.trackId,
                    row.bettingRaceNumbers,
                    row.programNumber
                );
            }
        );
        return instance;
    }
}

export class MultipleTrackSetup {
    /**
     * @param {number[]} trackIds
     * @param {Leg[]}    legs
     */
    constructor(
        trackIds,
        legs,
        trackId,
        bettingRaceNumbers,
        programNumber,
        tracks
    ) {
        this.trackIds = trackIds;
        this.legs = legs;
        this.trackId = trackId;
        this.bettingRaceNumbers = bettingRaceNumbers;
        this.programNumber = programNumber;
        this.tracks = tracks;
    }
    /**
     * Track codes
     * @type {number[]}
     */
    trackIds = [];
    /**
     * @type {Leg[]}
     */
    legs = [];

    /**
     * Internal track id, won't be passed to ATG, needed for own back=end
     * @type {number}
     */
    trackId = 0;

    /**
     * Multitrack races have completely different numbers in the ATG
     * betting API. These race numbers are taken from ABI and attached to
     * AIS responses for us on our backend.
     *
     * @type {Array.<number>}
     */
    bettingRaceNumbers = [];

    /**
     * Program numbers are different for multitrack pool products.
     * So should not be taken from RaceDay in this case.
     * @type {number}
     */
    programNumber = 0;
}

export class Leg {
    /**
     * @param {number} legNr
     * @param {number} raceNr
     * @param {number} trackId
     */
    constructor(legNr, raceNr, trackId) {
        this.legNr = legNr;
        this.raceNr = raceNr;
        this.trackId = trackId;
    }
    /**
     * Order number of a leg in multiple track pool
     * @type {number}
     */
    legNr = 0;

    /**
     * Race number to refer in specific track
     * @type {number}
     */
    raceNr = 0;

    /**
     * @type {number}
     */
    trackId = 0;
}

export default MultipleTrackSetups;
