import React, { Suspense, Fragment, lazy } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Scene404 from '../scenes/Scene404';
import Desktop404 from '../scenes/Desktop404';
import News from '../scenes/News';
import NewsMobile from '../layouts/sections/OldDesktopSidebar/News';
import Campaigns from '../scenes/Campaigns';
import SingleCampaign from '../scenes/Campaigns/SingleCampaign';
import HowToPlay from '../scenes/HowToPlay';
import { ProductDetails } from '../scenes/HowToPlay';
import { Paths } from 'configs/user-area';
import { PRODUCT_IDS } from 'configs/products';
import { isMobile } from 'react-device-detect';
import VideoReplays from '../scenes/VideoReplays';
import ReplaysResult from '../scenes/ReplaysResult';
import { byPlatform, forDesktop, forMobile, getPlatform } from 'utils/platforms';
import { RACE_EVENT_PATH, RESULTS_EVENT_PATH } from 'utils/navigation';
import { withRouter } from 'react-router';
import DepositError from 'features/UserArea/Deposit/Error';
import DepositSuccess from 'features/UserArea/Deposit/Success';
import Home from '../features/Homepage/Home';
import BrandBook from '../scenes/BrandBook';
import BetBuddy from 'features/Spilklub/Mobile/EntryPage';
import GroupInfoScreen from '../features/Spilklub/Mobile/GroupInfoScreen';
import DerbyLyn from '../scenes/DerbyLyn';
import Experts from '../scenes/Experts';
import HowSpilKlubWorks from '../scenes/HowSpilKlubWorks';
import Unsubscribe from '../scenes/Unsubscribe';
import LiveStreamDesktop from '../scenes/LiveStreamDesktop';
import AmountForm from 'features/UserArea/Deposit/AmountForm';
import EventBoardPreloader from 'features/EventBoard/components/EventBoardPreloader.tsx';
import DesktopLogin from 'features/Login/DesktopLogin';
import { ROUTER_POPUPS } from 'configs/modals';
import TrackPage from '../features/TrackPage';
import { NEW_TRACK_PAGE } from 'configs/main';
import RestorePassword from '../features/Login/RestorePassword';
import ResultsNew from '../features/ResultsNew';
import ResultPage from '../features/ResultsNew/components/ResultPage';
import ClearBetslipCookie from '../features/BetSlip2/components/ClearBetslipCookie';
import History from '../scenes/History';

const Welcome = lazy(() => import('../scenes/Welcome'));
const FinalSelectionsScreen = lazy(() =>
    import('../features/Spilklub/Mobile/FinalSelectionsScreen')
);
const Promo = lazy(() => import('../layouts/Promo'));
const LiveCasino = lazy(() => import('../scenes/LiveCasino'));
const BatchBettingPage = lazy(() => import('../features/BatchBetting/page'));
const EventBoard = lazy(() => import('../features/EventBoard'));

//import { getRoutes } from 'utils/navigation';

class DKRouter extends React.Component {
    renderWithSuspense = (Component, routerProps = {}, FallbackComponent) => (
        <Suspense fallback={FallbackComponent ? <FallbackComponent /> : <p>Loading...</p>}>
            <Component {...routerProps} />
        </Suspense>
    );

    render() {
        const { location } = this.props;
        let background = location.state?.background;

        return (
            <Fragment>
                <Switch location={background || location}>
                    <Route
                        exact
                        path="/"
                        // since Home is wrapped with HOC wrapping it into
                        // the function fixes prop types warning
                        // @see https://github.com/ReactTraining/react-router/issues/4354
                        component={Home}
                    />
                    <Route
                        path="/homepage2"
                        component={props =>
                            this.renderWithSuspense(EventBoard, props, EventBoardPreloader)
                        }
                    />
                    <Route path={RACE_EVENT_PATH} component={NEW_TRACK_PAGE ? TrackPage : Home} />
                    <Route path="/hjem" component={Home} />
                    <Route path="/remove-selections" component={ClearBetslipCookie} />
                    <Route path="/mitid-verify/success/:type?" component={Home} />
                    <Route
                        path="/systemspil"
                        component={() => this.renderWithSuspense(BatchBettingPage)}
                    />
                    <Route path="/mitid-verify/success/:type?" component={Home} />
                    <Route path="/mitid-verify/error" component={Home} />
                    <Route path="/mitid-verify/confirm/:type" component={Home} />
                    <Route path="/mitid-verify/forgotpin" component={Home} />
                    <Route path="/promo/:page" component={() => this.renderWithSuspense(Promo)} />
                    <Route
                        path="/promo/velkommen"
                        component={() => this.renderWithSuspense(Promo)}
                    />
                    <Route path="/velkommen" component={() => this.renderWithSuspense(Welcome)} />
                    <Route path="/gruppe-oversigt" component={GroupInfoScreen} />
                    <Route path="/opret-konto" component={Home} />
                    {/* Fallback route for older links */}
                    <Route path="/opret" component={Home} />
                    <Route
                        path="/log-ind"
                        children={forDesktop(() => (
                            <DesktopLogin />
                        ))}
                        component={forMobile(Home)}
                    />
                    <Route
                        path="/mitid-verify/forgotpassword"
                        children={() => <RestorePassword />}
                    />
                    <Route path="/testing/log-ind" children={() => <Home />} />
                    {/* Other modals */}
                    <Route path="/deposit/error" component={DepositError} />
                    <Route path="/deposit/success" component={DepositSuccess} />
                    {Object.values(Paths).map((path, index) => (
                        <Route key={index} path={'/' + path.url} component={Home} />
                    ))}
                    {Object.values(PRODUCT_IDS).map((id, index) => (
                        <Route key={index} path={'/' + id} component={Home} />
                    ))}
                    <Route path="/mine-spil/:type?" component={History} />
                    <Route path="/derby-lyn" component={DerbyLyn} />
                    <Route
                        path="/live-casino"
                        exact
                        component={props => this.renderWithSuspense(LiveCasino, props)}
                    >
                        <Redirect to={`/live-casino/alle`} />
                    </Route>
                    <Route
                        path="/live-casino/:category"
                        component={props => this.renderWithSuspense(LiveCasino, props)}
                    />
                    <Route path="/live-stream" component={LiveStreamDesktop} />
                    <Route path="/deposit-test" component={AmountForm} />
                    <Route path="/nyheder/:link" component={SingleCampaign} />
                    <Route path="/25-nyt/:link" component={SingleCampaign} />
                    <Route path="/nyheder" component={Campaigns} />
                    <Route path="/25-nyt" component={Campaigns} />
                    <Route path="/25-nyt-old" component={byPlatform(NewsMobile, News)} />
                    <Route
                        path="/spk/:link"
                        component={byPlatform(
                            () => (
                                <BetBuddy
                                    sharedLink={true}
                                    fromRoute={true}
                                    onBurgerMenuClick={this.props.onBurgerMenuClick}
                                />
                            ),
                            Home
                        )}
                    />
                    <Route
                        path="/spil-klub25/pool/:poolId/edit-selections"
                        component={() => this.renderWithSuspense(FinalSelectionsScreen)}
                    />
                    <Route
                        path="/spil-klub25"
                        component={byPlatform(
                            () => (
                                <BetBuddy onBurgerMenuClick={this.props.onBurgerMenuClick} />
                            ),
                            () => (
                                <Home />
                            )
                        )}
                    />
                    <Route path="/brandbook" component={BrandBook} />
                    <Route path="/sadan-spiller-du/:productId" component={ProductDetails} />{' '}
                    <Route path="/spilklub25-køb-andele-til-eksperternes" component={Experts} />
                    <Route path="/sådan-fungerer-spilklub25" component={HowSpilKlubWorks} />
                    <Route path="/afmeld-nyhedsbrev" component={Unsubscribe} />
                    <Route path="/sadan-spiller-du" component={HowToPlay} />
                    <Route path={RESULTS_EVENT_PATH} component={ResultPage} />
                    {
                        <Route
                            path="/resultat"
                            exact
                            component={() => this.renderWithSuspense(ResultsNew)}
                        />
                    }
                    <Route
                        path="/replays"
                        component={byPlatform(VideoReplays, ReplaysResult, ReplaysResult)}
                    />
                    {/* Routes from the config file */}
                    {/*{getRoutes().map(({ path, component, current, id }) => (*/}
                    {/*<Route*/}
                    {/*key={id}*/}
                    {/*path={path}*/}
                    {/*component={component}*/}
                    {/*current={current}*/}
                    {/*/>*/}
                    {/*))}*/}
                    {/* Fallback route */}
                    <Route component={isMobile ? Scene404 : Desktop404} />
                </Switch>

                {/*{background && <Route path="/log-ind" children={() => <DesktopLogin />} />}*/}

                {background
                    ? Object.keys(ROUTER_POPUPS).map(popupId => {
                          if (!location.state[popupId]) return null;

                          const popupConfig = ROUTER_POPUPS[popupId];
                          const platforms = popupConfig.platforms;

                          if (platforms && !platforms.includes(getPlatform())) {
                              return null;
                          }

                          const { pathname, Component, defaultProps = {} } = popupConfig;
                          const props = location.state[popupId] || {};

                          return (
                              <Route
                                  key={popupId}
                                  path={pathname}
                                  children={() => (
                                      <Component {...defaultProps} popupId={popupId} {...props} />
                                  )}
                              />
                          );
                      })
                    : null}
            </Fragment>
        );
    }
}

export default React.memo(withRouter(DKRouter));
