import { DK_RESULT_REST_PATH } from 'configs/rest';
import axios from 'axios';
import qs from 'qs';
import findIndex from 'lodash/findIndex';
import uuid from 'uuid/v4';

class ActiveRequest {
    /**
     * UUID V4
     * @type {null}
     */
    id = null;
    /**
     * @type {string}
     */
    requestType = 'GET';
    /**
     * @type {string}
     */
    url = '/';
    /**
     * Axio / ES proposal CancelTokenSource instance
     * @type {CancelTokenSource}
     */
    source = null;
    /**
     * Unix timestamp
     * @type {number}
     */
    timestamp = 0;

    constructor(properties) {
        Object.assign(this, properties, { id: uuid() });
    }

    /**
     * @param  {string} url
     * @return {boolean}
     */
    satisfiesUrl = (url) => {
        return !!this.url.match(new RegExp(url));
    };
}

/**
 * @type {Array.<ActiveRequest>}
 */
const activeRequests = [];

function request(requestType, url, data, options = { axios: {} }) {
    let source = axios.CancelToken.source();
    const activeRequest = new ActiveRequest({
        requestType,
        url,
        source,
        timestamp: options.timestamp,
    });
    activeRequests.push(activeRequest);

    const axiosOptions = {
        method: requestType,
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        data: qs.stringify(data),
        url,
        cancelToken: source.token,
        ...options.axios,
    };

    if (options.cancelRequestsResolver) {
        cancelPreviousRequests(
            options.cancelRequestsResolver,
            activeRequest,
            options.timestamp
        );
    }

    return axios(axiosOptions)
        .catch((e) => {
            markRequestFinished(requestType, url, data);
            if (axios.isCancel(e)) {
                process.env.NODE_ENV !== 'production' &&
                    console.warn('Request canceled', requestType, url, data);
                return Promise.reject(e);
            } else {
                console.log('SOMETIMES', e);
                throw e;
            }
        })
        .then((res) => {
            markRequestFinished(requestType, url, data);
            return res.data;
        });
}

function cancelPreviousRequests(resolver, currentRequest) {
    activeRequests.forEach((request) => {
        // prevent cancelling of current request
        request.id !== currentRequest.id &&
            // specifying timestamp as an option helps not to
            // cancel multiple requests triggered with Promise.all
            // or Promise.race
            currentRequest.timestamp !== activeRequests.timestamp &&
            resolver(request);
    });
}

function markRequestFinished(requestType, url, data) {
    const requestIndex = findIndex(activeRequests);

    if (requestIndex === -1) {
        console.error(
            `Could not find appropriate request in registry of active requests: ${{
                requestType,
                url,
                data,
            }}`
        );
    }

    activeRequests.splice(requestIndex, 1);
}

const RequestHandler = () => {
    const post = (method, data, options) => {
        return request('post', DK_RESULT_REST_PATH + method, data, options);
    };

    const get = (method, data, options) => {
        return request('get', DK_RESULT_REST_PATH + method, data, options);
    };

    if (RequestHandler._instance) {
        return RequestHandler._instance;
    } else {
        RequestHandler._instance = { post, get };
    }

    return RequestHandler._instance;
};

export default RequestHandler;
