import React, { Component } from 'react';
import { ContentHeight, FullScreen } from 'ui/FullScreen';
import TopBar from 'ui/topbar';
import TopBarText from 'ui/topbar/Text';
import {
    BoldText,
    ButtonGroupContainer,
    Container,
    ExcludeBtn,
} from 'features/UserArea/SelfExclusion/styled';
import Text from 'ui/Text';
import Button from 'ui/controls/Button';
import {
    EXCLUSION_TYPE_24_H,
    EXCLUSION_TYPE_MONTH,
    EXCLUSION_TYPE_PERMANENT,
    EXCLUSION_TYPE_24_H_TEXT,
    EXCLUSION_TYPE_MONTH_TEXT,
    EXCLUSION_TYPE_PERMANENT_TEXT,
} from './index';

import getTexts from 'utils/localization';
import CurrentlyExcluded from 'features/UserArea/SelfExclusion/CurrentlyExcluded';
import Flex from 'ui/Flex';
import { byPlatform } from 'utils/platforms';
const t = getTexts();

const paragraphStyle = {
    margin: 0,
};
const spanStyle = {
    display: 'block',
    fontSize: '9px',
    fontWeight: '300',
};

class SelfExclusion extends Component {
    constructor() {
        super();
        this.state = {
            period: null,
            agreementAccepted: false,
            permanentAgreementAccepted: false,
        };
    }

    selectPeriod = (period) => {
        this.setState({
            period,
            agreementAccepted: false,
            permanentAgreementAccepted: false,
        });
    };

    toggleAgreement = () => {
        const { agreementAccepted, period } = this.state;
        !!period && this.setState({ agreementAccepted: !agreementAccepted });
    };

    togglePermanentAgreement = () => {
        const { permanentAgreementAccepted, period } = this.state;
        !!period &&
            this.setState({
                permanentAgreementAccepted: !permanentAgreementAccepted,
            });
    };

    confirm = () => {
        const { confirm } = this.props;
        const { period } = this.state;
        confirm && confirm(period);
    };

    disableSubmitBtn = () => {
        const { period, agreementAccepted, permanentAgreementAccepted } = this.state;

        return !(
            !!period &&
            agreementAccepted &&
            (period === EXCLUSION_TYPE_PERMANENT ? permanentAgreementAccepted : true)
        );
    };

    renderExclusionForm = () => {
        const { agreementAccepted, permanentAgreementAccepted } = this.state;

        return (
            <>
                <Container>
                    <Text bold size="x2">
                        {t.selfExclusion.rofusTitle}
                    </Text>
                    <Text size="x1">{t.selfExclusion.rofusNote}</Text>
                    <Text>
                        <a
                            href="https://www.spillemyndigheden.dk/rofus"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {t.selfExclusion.rofusLink}
                        </a>
                    </Text>
                    <Text weight={'700'}>{t.selfExclusion.periodText}</Text>
                    <ButtonGroupContainer>
                        <Button
                            size="x2"
                            secondary={this.state.period !== EXCLUSION_TYPE_24_H}
                            onClick={() => this.selectPeriod(EXCLUSION_TYPE_24_H)}
                        >
                            <p style={paragraphStyle}>
                                {EXCLUSION_TYPE_24_H}
                                <span style={spanStyle}>{EXCLUSION_TYPE_24_H_TEXT}</span>
                            </p>
                        </Button>
                        <Button
                            size="x2"
                            secondary={this.state.period !== EXCLUSION_TYPE_MONTH}
                            onClick={() => this.selectPeriod(EXCLUSION_TYPE_MONTH)}
                        >
                            <p style={paragraphStyle}>
                                {EXCLUSION_TYPE_MONTH}
                                <span style={spanStyle}>{EXCLUSION_TYPE_MONTH_TEXT}</span>
                            </p>
                        </Button>
                        <Button
                            size="x2"
                            secondary={this.state.period !== EXCLUSION_TYPE_PERMANENT}
                            onClick={() => this.selectPeriod(EXCLUSION_TYPE_PERMANENT)}
                        >
                            <p style={paragraphStyle}>
                                {EXCLUSION_TYPE_PERMANENT}
                                <span style={spanStyle}>{EXCLUSION_TYPE_PERMANENT_TEXT}</span>
                            </p>
                        </Button>
                    </ButtonGroupContainer>
                    <BoldText onClick={this.toggleAgreement}>
                        <input
                            type="checkbox"
                            className="clickable"
                            checked={agreementAccepted}
                            onChange={this.toggleAgreement}
                        />{' '}
                        {t.selfExclusion.checkboxText1}
                    </BoldText>

                    {this.state.period === EXCLUSION_TYPE_PERMANENT ? (
                        <BoldText onClick={this.togglePermanentAgreement}>
                            <input
                                type="checkbox"
                                className="clickable"
                                checked={permanentAgreementAccepted}
                                onChange={this.togglePermanentAgreement}
                            />
                            {t.selfExclusion.checkboxText2}
                        </BoldText>
                    ) : null}

                    {this.props.desktop ? (
                        <ExcludeBtn
                            size="x3"
                            disabled={this.disableSubmitBtn()}
                            onClick={this.confirm}
                        >
                            {t.selfExclusion.submit}
                        </ExcludeBtn>
                    ) : null}
                </Container>
                {!this.props.desktop ? (
                    <ContentHeight>
                        <ExcludeBtn
                            size="x3"
                            disabled={this.disableSubmitBtn()}
                            onClick={this.confirm}
                        >
                            {t.selfExclusion.submit}
                        </ExcludeBtn>
                    </ContentHeight>
                ) : null}
            </>
        );
    };

    renderCurrentStatus = () => (
        <Flex
            justify="center"
            align="center"
            height="auto"
            padding={byPlatform('10px 20px', '50px 0 0 0')}
            boxSizing="border-box"
        >
            <CurrentlyExcluded
                exclusionDate={this.props.exclusionDate}
                exclusionTime={this.props.exclusionTime}
            />
        </Flex>
    );

    render() {
        const { isExcluded } = this.props;
        return (
            <FullScreen>
                {!this.props.desktop ? (
                    <ContentHeight>
                        <TopBar>
                            <TopBarText className="lg">{t.selfExclusion.title}</TopBarText>
                        </TopBar>
                    </ContentHeight>
                ) : null}
                {!isExcluded ? this.renderExclusionForm() : this.renderCurrentStatus()}
            </FullScreen>
        );
    }
}

export default SelfExclusion;
