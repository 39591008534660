import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setPin } from 'common/actions/authActions';
import Pin from 'features/Login/Pin';
import NotificationConductor from 'common/conductors/NotificationConductor';
import getTexts from 'utils/localization';
import * as StyledLogin from 'features/Login/styled';
import { isMobile } from 'utils/platforms';
const t = getTexts();

const PIN_LENGTH = 4;

class PinModal extends Component {
    componentDidMount() {
        isMobile && this.props.enableCloseButton(<StyledLogin.CloseButton right />);
    }

    componentDidUpdate() {
        if (this.props.error) {
            NotificationConductor.error(this.props.error);
        }
    }

    onPinFullfiled = (pin) => {
        const { hideModal, setPin } = this.props;

        setPin(pin).then(() => {
            hideModal('PIN_SETTING');
            NotificationConductor.info(t.pin.success);
        });
    };

    render() {
        return (
            <Pin
                pinLength={PIN_LENGTH}
                onPinFullfiled={this.onPinFullfiled}
                loading={this.props.updating}
                shownAfterSignup={this.props.modalData && this.props.modalData.shownAfterSignup}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        updating: state.auth.pin.pending,
        error: state.auth.pin.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setPin: (pin) => {
            return dispatch(setPin(pin));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PinModal);
