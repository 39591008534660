import React, { useMemo } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';

import { byPlatform } from 'utils/platforms';
import getTexts from 'utils/localization';

import { QUICKPICK_ORDER } from 'configs/products';

import Wrapper from 'ui/Wrapper';
import Spinner from 'ui/Spinner';
import Text from 'ui/Text';
import FlexWrapper from 'ui/FlexWrapper';
import Icon from 'ui/Icon';

import QuickPickCard from 'features/QuickPick/QuickPickCard';
import useQuickPickQuery from 'features/QuickPick/hooks/useQuickPickQuery';
import useCalendarDate from 'features/EventBoard/hooks/useCalendarDate';

import { history, isDirectEventLink } from 'utils/navigation';
import { NEW_TRACK_PAGE } from 'configs/main';
import useTrackPage from '../TrackPage/hooks/useTrackPage';
import useAllPoolInformationQuery from '../EventBoard/hooks/useAllPoolInformationQuery';
import QuickPick from 'common/DataObjects/QuickPick';
import { getPoolList } from 'common/selectors/poolListSelector';

const t = getTexts();

const ITEMS_PER_PAGE = 3;

const OverviewQuickPick = ({ size = ITEMS_PER_PAGE, isTrackQuickPick = false, ...props }) => {
    const { data: allPoolInformationData } = useAllPoolInformationQuery({
        enabled: NEW_TRACK_PAGE,
    });
    // old track page still uses the redux state
    let poolList = useSelector(getPoolList);

    if (NEW_TRACK_PAGE) {
        poolList = allPoolInformationData.map(quickPickItem =>
            QuickPick.unserialize(quickPickItem)
        );
    }

    const { trackId, product: selectedProduct, raceDay } = useTrackPage();

    const { date: homepageDate } = useCalendarDate();

    const date = isTrackQuickPick ? raceDay.raceDayDate : homepageDate;

    const { quickPickData } = useQuickPickQuery({ trackId, date });

    const poolData = useMemo(() => {
        if (!isTrackQuickPick) {
            return poolList;
        } else if (quickPickData?.length) {
            return quickPickData;
        } else {
            return [];
        }
    }, [isTrackQuickPick, poolList, quickPickData]);

    const sortedData = useMemo(() => {
        if (poolData.length) {
            const orderIndices = new Map();
            QUICKPICK_ORDER.forEach((value, index) => {
                orderIndices.set(value, index);
            });

            return [...poolData]
                .filter(item => item.date?.includes(date))
                .sort((a, b) => {
                    // Custom sorting function
                    const indexA = orderIndices.get(a.product.id);
                    const indexB = orderIndices.get(b.product.id);

                    // If an element is not found in the order array, it should be sorted to the end
                    if (indexA === undefined && indexB === undefined) {
                        return 0; // Leave the order unchanged
                    } else if (indexA === undefined) {
                        return 1; // Move element 'a' to the end
                    } else if (indexB === undefined) {
                        return -1; // Move element 'b' to the end
                    } else {
                        return indexA - indexB;
                    }
                })
                .slice(0, size);
        } else {
            return [];
        }
    }, [poolData, date, size]);

    isTrackQuickPick = isTrackQuickPick || isDirectEventLink();

    const isPending = !isTrackQuickPick && !poolData.length;

    const productQuickPick = quickPickData?.find(
        ({ product }) => product?.id === selectedProduct.id
    );

    const shouldDisplayProductQuickPick = isTrackQuickPick && productQuickPick;

    if (shouldDisplayProductQuickPick) {
        return (
            <FlexWrapper
                padding="0px"
                direction={'row'}
                style={{
                    padding: NEW_TRACK_PAGE ? '0 0 30px' : '0 20px 30px',
                    boxSizing: 'border-box',
                }}
            >
                <QuickPickCard item={productQuickPick} singleItem />
            </FlexWrapper>
        );
    }

    return isPending || ((quickPickData || poolList.length) && sortedData.length) ? (
        <Wrapper padding={props.padding || byPlatform('0 15px 30px', '0')}>
            <Wrapper padding={byPlatform('10px 20px', '10px 30px')}>
                <FlexWrapper
                    align={'space-between'}
                    padding={'0'}
                    direction={'row'}
                    alignItems={'center'}
                >
                    <Text bold size={byPlatform('20', '32')} padding={'0'} height={'100%'}>
                        {t.QuickPick.homepage.title}
                    </Text>
                    <FlexWrapper
                        alignItems={'center'}
                        padding={'0'}
                        direction={'row'}
                        width={'auto'}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            history.push('/derby-lyn');
                        }}
                    >
                        <Text color={'#2CB16F'} size={byPlatform('x07', 'x1.5')} bold>
                            {t.QuickPick.homepage.allButton}
                        </Text>
                        <Icon
                            iarrowsmooth
                            rotation={90}
                            color={'green'}
                            size={'x025'}
                            margin={byPlatform('0 0 0 5px', '0 0 0 9px')}
                        />
                    </FlexWrapper>
                </FlexWrapper>
                <Text
                    size={byPlatform('x07', '20')}
                    dangerouslySetInnerHTML={{ __html: t.QuickPick.homepage.description }}
                />
            </Wrapper>
            {!isPending ? (
                <Wrapper padding={byPlatform('10px 20px', '10px 30px')}>
                    <div
                        style={{
                            gap: isMobile ? '10px' : '20px',
                            display: 'grid',
                            gridAutoFlow: 'column',
                            gridAutoColumns: '1fr',
                            gridTemplateColumns: `repeat(${byPlatform(2, 3)}, 1fr)`,
                        }}
                    >
                        {sortedData.map((item, index) => (
                            <QuickPickCard
                                small={byPlatform(sortedData.length > 2 ? 2 : sortedData.length, 3)}
                                key={index}
                                item={item}
                            />
                        ))}
                    </div>
                </Wrapper>
            ) : (
                <Spinner size="x3" secondary />
            )}
        </Wrapper>
    ) : null;
};

export default OverviewQuickPick;
