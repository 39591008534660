import React from 'react';
import Wrapper from 'ui/Wrapper';
import FlexWrapper from 'ui/FlexWrapper';
import Text from 'ui/Text';
import { Card, CardContent, CardFooter, CardHeader } from 'ui/Card';
import { byPlatform } from 'utils/platforms';
import moment from 'moment';
import WinnerRowHeader from './WinnerRowHeader';
import WinnerRow from './WinnerRow';
import { NOT_V_SIMILAR_PRODUCTS, PRODUCTS_WITHOUT_PODDS } from '../';
import Spinner from 'ui/Spinner';
import { PRODUCT_IDS } from 'configs/products';
import { numberWithCommasAndDots } from 'utils/formatters.js';
import styled from 'styled-components';
import { MergedPool } from 'common/DataObjects/PoolOverview';
import Alert from 'ui/Alert';

const FINISHED_HORSE = 1;
const DISQUALIFIED_HORSE = 2;
const SCRATCHED_HORSE = 3;

const FlexWrapperModified = styled(FlexWrapper)`
    & > * {
        flex: unset;
    }
`;

const ResultCard = ({
    index,
    product,
    track,
    date,
    result,
    winnersOnly = false,
    withoutWinners = false,
}) => {
    let isEmptyList = true;
    let counter = 0;

    const renderResultCard = () => {
        return result.legs
            .sort((a, b) => (a.leg > b.leg ? 1 : -1))
            .map((leg, legIndex) => {
                let sortedList = leg.winners
                    .filter(item => parseInt(item.finishPos, 10) > 0)
                    .sort((a, b) =>
                        parseInt(a.finishPos, 10) > parseInt(b.finishPos, 10) ? 1 : -1
                    );

                sortedList = sortedList.concat(
                    leg.winners
                        .filter(item => item.finishPos.toString() === '0')
                        .sort((a, b) => {
                            if (
                                a.distibution === FINISHED_HORSE &&
                                b.distibution !== FINISHED_HORSE
                            ) {
                                return -1;
                            } else if (
                                a.distibution !== FINISHED_HORSE &&
                                b.distibution === FINISHED_HORSE
                            ) {
                                return 1;
                            } else {
                                return 0;
                            }
                        })
                        .sort((a, b) => {
                            if (
                                a.distibution !== DISQUALIFIED_HORSE &&
                                b.distibution === DISQUALIFIED_HORSE
                            ) {
                                return 1;
                            } else if (
                                a.distibution === DISQUALIFIED_HORSE &&
                                b.distibution !== DISQUALIFIED_HORSE
                            ) {
                                return -1;
                            } else {
                                return 0;
                            }
                        })
                        .sort((a, b) => {
                            if (
                                a.distibution !== SCRATCHED_HORSE &&
                                b.distibution === SCRATCHED_HORSE
                            ) {
                                return -1;
                            } else if (
                                a.distibution === SCRATCHED_HORSE &&
                                b.distibution !== SCRATCHED_HORSE
                            ) {
                                return 1;
                            } else {
                                return 0;
                            }
                        })
                        .sort((a, b) => {
                            if (a.finishTime === '0.00,0' && b.finishTime !== '0.00,0') {
                                return 1;
                            } else if (a.finishTime !== '0.00,0' && b.finishTime === '0.00,0') {
                                return -1;
                            } else {
                                return 0;
                            }
                        })
                );

                if (winnersOnly) {
                    sortedList = sortedList.filter(item => item.winPos > 0);
                } else if (withoutWinners) {
                    sortedList = sortedList.filter(item => item.winPos === 0);
                }

                return sortedList.map((winner, index) => {
                    isEmptyList = false;
                    counter++;

                    return (
                        <WinnerRow
                            key={index}
                            index={counter}
                            winner={winner}
                            leg={leg}
                            product={product}
                        />
                    );
                });
            });
    };

    const renderRaceCancellationAlert = () => (
        <Alert
            title="Løb aflyst."
            type="info"
            closable
            styles={{ AlertContainer: { marginBottom: '25px' } }}
        >
            Alle indsatser vil blive refunderet.
        </Alert>
    );

    if (result && product && track) {
        const resultCardView = renderResultCard();

        const raceCancelled =
            result.legs.length === 1
                ? result.legs[0].cancelled === '1'
                : result.legs.some(leg => leg.cancelled === '1');

        return resultCardView ? (
            <Wrapper padding="20px 10px" white>
                {raceCancelled && !withoutWinners && renderRaceCancellationAlert()}
                <Card borderRadius="12px">
                    <CardHeader>
                        {!withoutWinners ? (
                            <FlexWrapper
                                direction="row"
                                width="auto"
                                align="space-between"
                                padding="0px 10px"
                            >
                                <Text
                                    size={byPlatform('12', '16', '16')}
                                    flex="unset"
                                    white
                                    bold
                                    short
                                >
                                    {byPlatform(
                                        product.id,
                                        product.name,
                                        product.name
                                    ).toUpperCase() +
                                        (NOT_V_SIMILAR_PRODUCTS.includes(product.id)
                                            ? ' - LØB ' + (index + 1)
                                            : '')}
                                </Text>

                                <Text
                                    size={byPlatform('12', '16', '16')}
                                    white
                                    flex="unset"
                                    align="center"
                                    short
                                >
                                    {track.name.toUpperCase()}
                                    {NOT_V_SIMILAR_PRODUCTS.includes(product.id)
                                        ? ' | ' + result.legs[0].distance + ' M'
                                        : null}
                                </Text>

                                <Text
                                    size={byPlatform('12', '16', '16')}
                                    white
                                    align="center"
                                    flex="unset"
                                    short
                                    padding={byPlatform('10px 5px', '10px', '10px')}
                                    style={{
                                        color: '#fff',
                                        background: '#D13435',
                                        borderRadius: '5px',
                                    }}
                                >
                                    Afsluttet {moment(date).format('DD MMMM YYYY')}
                                </Text>
                            </FlexWrapper>
                        ) : (
                            <FlexWrapper
                                direction="row"
                                width="auto"
                                align="space-between"
                                padding="0px 10px"
                            >
                                <Text
                                    size={byPlatform('12', '16', '16')}
                                    flex="unset"
                                    white
                                    bold
                                    short
                                >
                                    {/* @see https://ecosys.atlassian.net/browse/DER-1226 */}
                                    {[
                                        PRODUCT_IDS.V,
                                        PRODUCT_IDS.K,
                                        PRODUCT_IDS.TV,
                                        PRODUCT_IDS.T,
                                    ].includes(product.id)
                                        ? 'LØB ' + (index + 1)
                                        : product.name.toUpperCase() +
                                          (NOT_V_SIMILAR_PRODUCTS.includes(product.id)
                                              ? ' - LØB ' + (index + 1)
                                              : '')}
                                </Text>
                            </FlexWrapper>
                        )}
                    </CardHeader>
                    <CardContent
                        style={{
                            padding: '0px',
                            margin: '0px',
                        }}
                    >
                        <WinnerRowHeader product={product} />
                        {resultCardView}

                        {isEmptyList ? (
                            <Wrapper>
                                <Text align="center">
                                    Der er ingen afsluttede løb i øjeblikket.
                                </Text>
                            </Wrapper>
                        ) : null}
                    </CardContent>
                    {!withoutWinners ? (
                        <CardFooter style={{ background: '#ebeff687' }}>
                            {NOT_V_SIMILAR_PRODUCTS.includes(product.id) ? (
                                <FlexWrapperModified
                                    padding="0px 20px"
                                    direction="row"
                                    align="start"
                                >
                                    {result.legs[0].mergeInfo &&
                                    result.legs[0].mergeInfo.length > 0 ? (
                                        <Text width="130px" size={byPlatform('12px', 'x1', 'x1')}>
                                            <b>
                                                Samlet pulje
                                                <br />(
                                                {MergedPool.sort(result.legs[0].mergeInfo).join(
                                                    ' + '
                                                )}
                                                ):
                                            </b>
                                            <br />
                                            {numberWithCommasAndDots(result.turnover)} Kr.
                                        </Text>
                                    ) : (
                                        <Text width="100px" size={byPlatform('12px', 'x1', 'x1')}>
                                            Omsætning:
                                            <br />
                                            {numberWithCommasAndDots(result.turnover)} Kr.
                                        </Text>
                                    )}

                                    {PRODUCTS_WITHOUT_PODDS.includes(product.id) ? (
                                        <Text width="120px" size={byPlatform('12px', 'x1', 'x1')}>
                                            <b>Kombinationer:</b> <br />
                                            {result.legs.length > 0
                                                ? result.legs.map(leg =>
                                                      leg.winodds
                                                          ? leg.winodds.map((item, ind) =>
                                                                ind > 0
                                                                    ? ' / ' +
                                                                      item.result
                                                                          .split(',')
                                                                          .join('-')
                                                                    : item.result
                                                                          .split(',')
                                                                          .join('-')
                                                            )
                                                          : '0'
                                                  )
                                                : '0'}
                                        </Text>
                                    ) : null}

                                    {PRODUCTS_WITHOUT_PODDS.includes(product.id) ? (
                                        <Text width="100px" size={byPlatform('12px', 'x1', 'x1')}>
                                            <b>ODDS:</b> <br />
                                            {result.legs.length > 0
                                                ? result.legs.map(leg =>
                                                      leg.winodds
                                                          ? leg.winodds.map((item, ind) =>
                                                                ind === 0
                                                                    ? item.odds
                                                                    : ' / ' + item.odds
                                                            )
                                                          : '0'
                                                  )
                                                : '0'}
                                        </Text>
                                    ) : null}
                                </FlexWrapperModified>
                            ) : (
                                <Wrapper>
                                    <FlexWrapperModified
                                        padding="0px 20px"
                                        direction="row"
                                        align="start"
                                    >
                                        {result.payouts.reverse().map(po => (
                                            <Text
                                                width="80px"
                                                key={po.number}
                                                size={byPlatform('12px', 'x1', 'x1')}
                                            >
                                                <b>{po.number} rigtige:</b>
                                                <br />
                                                {numberWithCommasAndDots(po.payout)} Kr.
                                            </Text>
                                        ))}
                                    </FlexWrapperModified>

                                    <FlexWrapperModified
                                        align="start"
                                        padding="0px 20px"
                                        direction="row"
                                    >
                                        <Text width="100px" size={byPlatform('12px', 'x1', 'x1')}>
                                            <b>Omsætning:</b>
                                            <br />
                                            {numberWithCommasAndDots(result.turnover)} Kr.
                                        </Text>

                                        {product.id !== PRODUCT_IDS.DD &&
                                        product.id !== PRODUCT_IDS.LD ? (
                                            <Text
                                                width="100px"
                                                size={byPlatform('12px', 'x1', 'x1')}
                                            >
                                                <b>Systemer:</b>
                                                <br />
                                                {numberWithCommasAndDots(result.systemCount)}
                                            </Text>
                                        ) : null}

                                        {product.id === PRODUCT_IDS.DD ||
                                        product.id === PRODUCT_IDS.LD ? (
                                            <Text
                                                width="120px"
                                                size={byPlatform('12px', 'x1', 'x1')}
                                            >
                                                <b>Kombinationer:</b> <br />
                                                {result.legs.length > 0
                                                    ? result.legs[0].winodds &&
                                                      result.legs[0].winodds[0]
                                                        ? result.legs[0].winodds[0].result
                                                              .split(',')
                                                              .join('-')
                                                        : 0
                                                    : '0'}
                                            </Text>
                                        ) : null}

                                        {product.id === PRODUCT_IDS.DD ||
                                        product.id === PRODUCT_IDS.LD ? (
                                            <Text
                                                width="100px"
                                                size={byPlatform('12px', 'x1', 'x1')}
                                            >
                                                <b>ODDS:</b> <br />
                                                {result.legs.length > 0
                                                    ? result.legs[0].winodds &&
                                                      result.legs[0].winodds[0]
                                                        ? result.legs[0].winodds[0].odds
                                                        : 0
                                                    : '0'}
                                            </Text>
                                        ) : null}
                                    </FlexWrapperModified>
                                </Wrapper>
                            )}
                        </CardFooter>
                    ) : null}
                </Card>
            </Wrapper>
        ) : null;
    } else {
        return !result ? (
            <Wrapper>
                <Text align="center">Der er ingen afsluttede løb i øjeblikket.</Text>
            </Wrapper>
        ) : (
            <Spinner />
        );
    }
};

export default ResultCard;
