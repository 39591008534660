import React from 'react';
import { RacesOverWrapper } from './styled';
import Text from 'ui/Text';

const RacesOverLabel = () => {
    return (
        <RacesOverWrapper>
            <Text color={'#E54C4C'} size={'x1'} bold padding={'6px 8px'}>
                Løbet er aflyst
            </Text>
        </RacesOverWrapper>
    );
};

export default RacesOverLabel;
