import SmartLynBet from 'common/DataObjects/SmartLynBet';
import raceDaySelector from 'common/selectors/raceDaySelector';
import { getTrackFromRacingCard } from 'common/selectors/trackSelector';
import betSlipSelector from 'common/selectors/betSlipSelector';
import { isMultitrackProduct } from 'common/selectors/multipleTrackSetupsSelector';

/**
 * @param  {Object}      state
 * @return {SmartLynBet}
 */
const getSmartLynBet = (state) => {
    const date = raceDaySelector(state).date;
    const trackCode = getTrackFromRacingCard(state).code;
    const selectedProduct = state.AISDataProvider.selectedProduct;
    const races = state.AISDataProvider.racingCardData.races;
    const betSlip = betSlipSelector(state);
    const isMultitrack = isMultitrackProduct(state);
    const selectedRaceDay = state.AISDataProvider.selectedRaceDay;

    return SmartLynBet.unserialize(
        date,
        selectedProduct,
        trackCode,
        races,
        betSlip,
        isMultitrack
            ? selectedRaceDay.multipleTrackSetups[selectedProduct.id]
            : null
    );
};

export { getSmartLynBet };
