import React, { Component, Fragment } from 'react';
import Button from 'ui/controls/Button';

class ButtonGroup extends Component {
    constructor(props) {
        super();
        this.state = {
            selected: props.selected || [],
        };
    }

    select = (value) => {
        const { multiple, onSelect } = this.props;
        const { selected } = this.state;

        onSelect && onSelect(value);

        if (!multiple) {
            return this.setState({ selected: [value] });
        }

        if (this.isSelected(value)) {
            selected.splice(selected.indexOf(value), 1);
            this.setState({ selected });
        } else {
            this.setState({ selected: [...selected, value] });
        }
    };

    isSelected = (value) => {
        return this.state.selected.indexOf(value) !== -1;
    };

    render() {
        const { values, renderButton, size, selected } = this.props;
        return (
            <Fragment>
                {values.map((v, i) => {
                    const props = {
                        onClick: this.select.bind(null, v),
                        secondary: !this.isSelected(v),
                        key: i,
                        size,
                    };

                    const btn = (
                        <Button
                            {...props}
                            {...(selected === v ? { primary: true } : {})}
                        >
                            {v}
                        </Button>
                    );
                    return renderButton ? renderButton(v, props) : btn;
                })}
            </Fragment>
        );
    }
}

export default ButtonGroup;
