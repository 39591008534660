import { isEqual, isObject, transform } from 'lodash';
import moment from 'moment';

export function convertObjectToObject(FromObject, ToObject, fieldsMap = {}) {
    const NewObject = {};

    Object.keys(ToObject).map(key => {
        const fieldName = fieldsMap[key] !== undefined || key;

        NewObject[fieldName] = FromObject[fieldName];

        return key;
    });

    return NewObject;
}

export function fillObjectByConstructor(FromObject, TargetConstructor, fieldsMap = {}) {
    const TargetObject = new TargetConstructor();

    Object.keys(TargetObject).map(key => {
        const fieldName = fieldsMap[key] !== undefined || key;

        TargetObject[fieldName] = FromObject[fieldName];

        return key;
    });

    return TargetObject;
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
    function changes(object, base) {
        return transform(object, function (result, value, key) {
            if (!isEqual(value, base[key])) {
                result[key] =
                    isObject(value) && isObject(base[key]) ? changes(value, base[key]) : value;
            }
        });
    }
    return changes(object, base);
}

export function haveAnyDifference(objectA, objectB) {
    return (
        Object.keys(difference(objectA, objectB)).length > 0 ||
        Object.keys(difference(objectB, objectA)).length > 0
    );
}

export function haveDifference(objectA, objectB) {
    return Object.keys(difference(objectA, objectB)).length > 0;
}

/**
 * Normalizes keys in object (shallow)
 * so that special (UTF-8) characters
 * will be available as a key with [] literal.
 * For example, "Å" === "Å" can sometimes give false.
 * Same can be said about object keys:
 * const objectA = {"Å": "someValue"};
 * console.log(objectA["Å"]; // can return undefined
 *
 * @param object
 */
export function normalizeKeys(object) {
    const normalizedKeys = {};
    Object.keys(object).forEach(key => {
        normalizedKeys[convertToClosestASCII(key)] = object[key];
    });
    return normalizedKeys;
}

export function convertToClosestASCII(trackCode = '') {
    const combining = /[\u0300-\u036F]/g;

    return trackCode.normalize('NFKD').replace(combining, '');
}

/**
 *
 * @param  {Object}    object
 * @param  {Object}    keyNamesMap
 * @return {Object}                  An object with the new keys.
 *                                   Properties with no new keys specified
 *                                   will remain.
 */
export function renameKeys(object, keyNamesMap) {
    const withRenamedKeys = {};
    Object.keys(object).forEach(key => {
        const newKey = keyNamesMap.hasOwnProperty(key) ? keyNamesMap[key] : key;
        withRenamedKeys[newKey] = object[key];
    });
    return withRenamedKeys;
}

/**
 * Determines if the object has any keys
 * @param  {Object}  object
 * @return {boolean}
 */
export function empty(object) {
    return Object.keys(object).length === 0;
}

function upper(match, group0) {
    return group0.toUpperCase();
}

function normKey(key) {
    return key.replace(/_([a-z])/g, upper);
}

export function normalize(data) {
    if (Array.isArray(data)) {
        return data.map(normalize);
    }

    if (!data || typeof data !== 'object') {
        return data;
    }
    if (moment.isMoment(data)) {
        return data;
    }

    const ret = {};

    Object.entries(data).forEach(([key, val]) => {
        ret[normKey(key)] = normalize(val);
    });

    return ret;
}

export function parseNullString(input) {
    return input === 'null' ? null : input;
}
