import { create } from 'zustand';

interface TrioSelectionStore {
    trioMarksIndicator: Record<string, boolean>;
    toggleTrioState(row: number): void;
    resetTrioState(): void;
}

const useTrioColumnState = create<TrioSelectionStore>()(set => ({
    trioMarksIndicator: {},
    toggleTrioState: row =>
        set(state => ({
            trioMarksIndicator: {
                ...state.trioMarksIndicator,
                [row]: !state.trioMarksIndicator[row],
            },
        })),
    resetTrioState: () => {
        set(() => ({
            trioMarksIndicator: {},
        }));
    },
}));

export default useTrioColumnState;
