import React from 'react';
import styled from 'styled-components';
import { BACKGROUNDS } from '../../themes';
import { getBackgroundByProps } from 'utils/theme-helpers';
import PropTypes from 'prop-types';

const animationSpeed = 200;

const Container = styled.div`
    // 4px 2 margin values of Indicator (both sides)
    width: ${({ size, width }) => (width ? width : `calc(${size || '24px'} * 2)`)};
    height: ${({ size }) => `calc(${size || '24px'} + 4px)`};
    background: ${(props) =>
        props.checked === true
            ? getBackgroundByProps(props)
            : props.inactiveBackground || BACKGROUNDS.lightGrey};
    border-radius: ${({ size }) => `calc(${size || '24px'} + 4px)`};
    position: relative;
    margin: ${(props) => props.margin || '5px'};
    display: ${(props) => props.display || 'block'};
`;

const Indicator = styled.div`
    width: ${({ size }) => size || '24px'};
    height: ${({ size }) => size || '24px'};
    border-radius: ${({ size }) => size || '24px'};
    background: ${BACKGROUNDS.white};
    position: absolute;
    margin: 2px;
    // 4px 2 margin values of Indicator (both sides)
    left: ${(props) => (props.checked ? `calc(100% - ${props.size || '24px'} - 4px)` : '0px')};
    transition: all ${animationSpeed}ms linear;
    box-shadow: 2px 0 6px rgba(0, 0, 0, 0.4);
`;

class Switcher extends React.Component {
    constructor() {
        super();

        this.state = {
            checked: false,
        };
    }

    onChangeState() {
        const newState = !this.state.checked;

        this.setState({ checked: newState });

        setTimeout(() => {
            this.props.onChange(newState);
        }, animationSpeed);
    }

    UNSAFE_componentWillMount() {
        if (this.props.checked) {
            this.setState({ checked: true });
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.checked !== this.props.checked) {
            this.setState({ checked: this.props.checked });
        }
    }

    render() {
        return (
            <Container
                checked={this.props.checked !== undefined ? this.props.checked : this.state.checked}
                data-test-id={
                    this.props.checked !== undefined ? this.props.checked : this.state.checked
                }
                onClick={this.onChangeState.bind(this)}
                size={this.props.size}
                display={this.props.display}
                margin={this.props.margin}
                width={this.props.width}
                style={this.props.style}
                inactiveBackground={this.props.inactiveBackground}
            >
                <Indicator
                    checked={
                        this.props.checked !== undefined ? this.props.checked : this.state.checked
                    }
                    size={this.props.size}
                />
            </Container>
        );
    }
}

Switcher.propTypes = {
    onChange: PropTypes.func.isRequired,
};

export default Switcher;
