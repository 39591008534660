import React from 'react';
import { HeaderRow, GameHeaderCol, GameHeaderColCenter, SelectAllHeaderColumn } from './styled';
import getTexts from 'utils/localization';

const t = getTexts();

const VGameHeader = () => {
    return (
        <HeaderRow>
            <GameHeaderColCenter>{t.RacingCard.vRaceHeader.nr}</GameHeaderColCenter>
            <GameHeaderCol>{t.RacingCard.vRaceHeader.horse}</GameHeaderCol>
            <SelectAllHeaderColumn>{t.RacingCard.vRaceHeader.selectAll}</SelectAllHeaderColumn>
        </HeaderRow>
    );
};

export default VGameHeader;
