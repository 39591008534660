import CMSAPI from 'common/api/CMSAPI';
import { PRODUCTS_SYS_NAMES } from 'configs/products.ts';
import { normalize } from 'utils/object-helper';
import useHorseVideos from '../HorseVideos/useHorseVideos';
import { NEW_TRACK_PAGE } from '../../configs/main';
import { useQuery } from '@tanstack/react-query';

const serialize = (raceDay, product) =>
    NEW_TRACK_PAGE
        ? {
              track_code: raceDay.track.code,
              track_real_id: raceDay.trackId,
              product: PRODUCTS_SYS_NAMES[product.id],
              race_date: raceDay.raceDayDate,
          }
        : {
              track_code: raceDay.track.code,
              track_real_id: raceDay.track.id,
              product: PRODUCTS_SYS_NAMES[product.id],
              race_date: raceDay.date,
          };

const parseHorseTips = data => normalize(data);

const fetchSpilTipsFromCMS = async params => {
    const response = await CMSAPI.getHorseTips(params);

    return response.data;
};

const useSpilTipsQuery = ({ track_code, track_real_id, product, race_date }) => {
    const { status, data, error, isSuccess, isLoading } = useQuery({
        queryKey: ['spilTipsCms', track_code, track_real_id, product, race_date],
        queryFn: () => fetchSpilTipsFromCMS({ track_code, track_real_id, product, race_date }),
        staleTime: 60 * 10 * 1000,
        enabled: !!track_code && !!track_real_id && !!product && !!race_date,
    });

    return {
        status,
        data,
        error,
        isLoading,
        isSuccess,
    };
};

const useTipsFromCMS = ({ raceDay, product }) => {
    const { data } = useSpilTipsQuery(serialize(raceDay, product));

    const { videos } = useHorseVideos(serialize(raceDay, product));

    return {
        tips: data?.races ? parseHorseTips(data.races) : [],
        videos,
        trackName: raceDay.track.name,
        productId: product.id,
    };
};

export default useTipsFromCMS;
