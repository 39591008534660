import React from 'react';
import { isMobile } from 'react-device-detect';
import noop from 'lodash/noop';

import { onRaceDaySelected, onProductSelected } from 'features/EventBoard';
import { RaceDay } from 'features/EventBoard/server/calendar';
import TrackWeather from 'features/EventBoard/components/TrackWeather';
import TrackNameWithCountry from 'features/EventBoard/components/TrackNameWithCountry';
import RaceCountWithTime from 'features/EventBoard/components/RaceCountWithTime';
import TrackSelectionPreloader from 'features/EventBoard/components/TrackSelectionPreloader';
import { getWeatherAttributes } from 'features/EventBoard/utils/calendar';

import { Divider, TrackMiddleSection, TrackSelectionContainer } from './styled';
import { getJackpotProducts } from './Shortcuts/helpers';
import JackpotLabel from './JackpotLabel';
import RacesOverLabel from './RacesOverLabel';
import useServerTimeQuery from '../hooks/useServerTimeQuery';
import BettingProducts from './BettingProducts';

interface TrackSelectionProps {
    raceDays: RaceDay[];
    isLoading: boolean;
    onRaceDaySelected?: onRaceDaySelected;
    onProductSelected?: onProductSelected;
}

const TrackSelection = ({ isLoading, raceDays, onRaceDaySelected = noop }: TrackSelectionProps) => {
    const { serverTime } = useServerTimeQuery();

    if (isLoading) {
        return <TrackSelectionPreloader />;
    }

    return (
        <>
            {raceDays.map(raceDay => {
                const { tempCelsius, weatherCode, weatherDescription } =
                    getWeatherAttributes(raceDay);

                const isRacesOver = raceDay.racesInfo.every(
                    race => race.hasResult || race.canceled
                );

                return (
                    <TrackSelectionContainer
                        key={`${raceDay.raceDayDate}|${raceDay.trackId}`}
                        onClick={() => onRaceDaySelected(raceDay)}
                    >
                        {isMobile ? (
                            <>
                                <div className={'space-between center-v'} style={{ gap: 10 }}>
                                    <TrackNameWithCountry
                                        trackCode={raceDay.country.code}
                                        trackName={raceDay.trackName}
                                    />
                                    {raceDay.canceled && <RacesOverLabel />}
                                    {raceDay.productsWithJackpot && !raceDay.canceled && (
                                        <JackpotLabel
                                            productsId={getJackpotProducts(
                                                raceDay.productsWithJackpot
                                            )}
                                        />
                                    )}
                                    {raceDay?.weather && (
                                        <TrackWeather
                                            temperature={tempCelsius}
                                            weatherCode={weatherCode}
                                            weatherDescription={weatherDescription}
                                        />
                                    )}
                                </div>
                                <RaceCountWithTime
                                    raceDay={raceDay}
                                    isRacesOver={isRacesOver}
                                    canceled={raceDay.canceled}
                                    serverTime={serverTime}
                                />
                                <Divider />
                                <BettingProducts raceDay={raceDay} />
                            </>
                        ) : (
                            <>
                                <TrackNameWithCountry
                                    trackCode={raceDay.country.code}
                                    trackName={raceDay.trackName}
                                />
                                <TrackMiddleSection>
                                    <TrackWeather
                                        temperature={tempCelsius}
                                        weatherCode={weatherCode}
                                        weatherDescription={weatherDescription}
                                    />
                                    <BettingProducts raceDay={raceDay} />
                                </TrackMiddleSection>

                                <RaceCountWithTime
                                    raceDay={raceDay}
                                    isRacesOver={isRacesOver}
                                    canceled={raceDay.canceled}
                                    serverTime={serverTime}
                                />
                            </>
                        )}
                    </TrackSelectionContainer>
                );
            })}
        </>
    );
};

export default TrackSelection;
