import Wrapper from 'ui/Wrapper';
import styled from 'styled-components';
import blopLeft from 'images/dk/left_blob_web.png';
import blopRight from 'images/dk/right_blob_web.png';

const TerminalWrapper = styled(Wrapper)`
    background: url("${blopLeft}") left top no-repeat, url("${blopRight}") right top no-repeat,
    margin: 0;
    padding: 0;
    backgroundsize: 'contain';
`;

const HeaderWrapper = styled(Wrapper)`
    position: fixed;
    padding: 0;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
`;

const ContentWrapper = styled(Wrapper)`
    margin: 140px 0 0 0;
    padding: 0;
    filter: blur(${({ modalVisible }) => (modalVisible ? '5px' : '0px')});
`;

const RouterWrapper = styled(Wrapper)`
    position: 'absolute';
    padding: 0;
    margintop: '110px';
    width: '85%';
    left: '7.5%';
    right: '7.5%';
`;

export { TerminalWrapper, HeaderWrapper, ContentWrapper, RouterWrapper };
