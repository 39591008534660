import styled from 'styled-components';
import Text from 'ui/Text';
import { SelectionScreenContainer } from 'features/EventBoard/components/styled';
import { CalendarLabel } from 'ui/RaceNavigation';
import { byPlatform } from 'utils/platforms';

const breakpoints = {
    xs: byPlatform('386px', '675px'),
    sm: byPlatform('475px', '775px'),
    md: byPlatform('735px', '1024px'),
};

export const ResultStateLabel = styled(Text)`
    color: #b2b9c6;
`;
export const ResultTrackStateLabel = styled(Text)`
    font-size: 18px;
    text-align: right;
    font-weight: 900;
    max-width: 100px;
`;

export const SubSelectionScreenContainer = styled(SelectionScreenContainer)`
    margin: 0;
`;

export const ResultCalendarLabel = styled(CalendarLabel)`
    @media (max-width: ${breakpoints.xs}) {
        display: flex !important;
    }
    @media (max-width: ${breakpoints.sm}) {
        font-size: 18px;
    }
`;
