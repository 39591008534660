import { useDidUpdate } from 'rooks';
import { useDispatch } from 'react-redux';

import { history, showPopup } from 'utils/navigation';
import { PRODUCT_IDS } from 'configs/products';
import { isMobile } from 'utils/platforms';
import useTrackPage from './useTrackPage';
import { mergeBetslip, resetBet } from 'features/BetSlip/state/actions';

declare type RacePick<T> = Record<string, Record<string, Record<string, Record<number, T>>>>;

const useFillBetslipFromUrl = () => {
    const { calendarFetched, racesFetched, raceDay, productId, isVProduct, races, trackCode } =
        useTrackPage();

    const dispatch = useDispatch();

    const fillBetslipFromURL = () => {
        const url = new URL(window.location.href);
        const queryParamNames = Array.from(url.searchParams.keys());

        //Example href: ?leg1=2&leg2=6,7&leg3=1,2,3&leg4=1,2,3,4,5,6,7,8,9,10,11,12,13_1,2

        if (
            history.location.pathname.indexOf('spil/') > -1 &&
            queryParamNames.some(param => param.startsWith('leg')) &&
            isVProduct &&
            raceDay
        ) {
            const date = raceDay.raceDayDate;
            const picks: RacePick<any> = {
                [date]: {},
            };
            const reserves: RacePick<number[]> = {
                [date]: {},
            };

            // @ts-ignore
            for (let [key, legSelections] of url.searchParams.entries()) {
                const isLegParam = key.match(/^leg\d$/) !== null;

                if (!isLegParam) {
                    break;
                }
                const legNr = parseInt(key.replace('leg', ''), 10);
                const race = races[legNr - 1];

                if (!race || !race.saleOpen) {
                    break;
                }
                if (!picks[date][trackCode]) {
                    picks[date][trackCode] = {
                        [productId]: {},
                    };
                    reserves[date][trackCode] = {
                        [productId]: {},
                    };
                }
                picks[date][trackCode][productId][race.index] = {};
                reserves[date][trackCode][productId][race.index] = [];

                const [pickSelections, reserveSelections] = legSelections.split('_');
                pickSelections.split(',').forEach((startNrStr: string) => {
                    const startNr = parseInt(startNrStr, 10);
                    const start = race.horses[startNr - 1];
                    if (!start || start.vpPool.scratched) {
                        return;
                    }
                    picks[date][trackCode][productId][race.index][startNr] = [startNr];
                });

                ![PRODUCT_IDS.DD, PRODUCT_IDS.LD].includes(productId) &&
                    reserveSelections &&
                    reserveSelections
                        .split(',')
                        .slice(0, 2)
                        .forEach((startNr: number) => {
                            const start = race.horses[startNr - 1];
                            if (!start || start.vpPool.scratched) {
                                return;
                            }
                            startNr = parseInt(String(startNr), 10);
                            reserves[date][trackCode][productId][race.index].push(startNr);
                        });
            }

            history.replace(history.location.pathname);

            if (Object.keys(picks[date]).length > 0) {
                dispatch(resetBet());
                dispatch(mergeBetslip(picks, reserves));
                isMobile && showPopup('BETSLIP_MOBILE');
            }
        }
    };

    useDidUpdate(() => {
        if (calendarFetched && racesFetched) {
            fillBetslipFromURL();
        }
    }, [calendarFetched, racesFetched]);
};

export default useFillBetslipFromUrl;
