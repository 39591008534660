import React from 'react';
import { generatePath } from 'react-router-dom';

import { DOMAIN_PATH } from 'configs/main';

import useCampaignsQuery, { Campaign, SectionName } from './useCampaignsQuery';
import PageContainer from 'ui/PageContainer';

import {
    CampaignBadge,
    Delimiter,
    Grid,
    PreviewContainer,
    PreviewContent,
    PreviewTeaser,
    PreviewText,
    PreviewTitle,
    SectionTitle,
} from './styled';
import { byPlatform } from 'utils/platforms';
import { push } from 'utils/navigation';
import FadeIn from 'ui/animations/FadeIn';

export const getCampaignsBySection = (campaigns: Campaign[], sections: SectionName[]) =>
    campaigns.filter(campaign => sections.includes(campaign.section_name));

export const CampaignPreview = ({ campaign }: { campaign: Campaign }) => {
    const navigateToArticle = () => {
        const path = generatePath(`/25-nyt/:link`, { link: campaign.link });
        push(path);
    };

    return (
        <PreviewContainer onClick={navigateToArticle}>
            <img src={DOMAIN_PATH + campaign.image} alt={campaign.header} />

            <PreviewContent>
                <CampaignBadge campaign={campaign} />
                <PreviewTitle>{campaign.header}</PreviewTitle>

                {campaign.teaser ? (
                    <PreviewTeaser
                        dangerouslySetInnerHTML={{ __html: campaign.teaser }}
                    ></PreviewTeaser>
                ) : null}

                {campaign.teaser && campaign.rules ? <Delimiter /> : null}

                {campaign.rules ? (
                    <PreviewText dangerouslySetInnerHTML={{ __html: campaign.rules }}></PreviewText>
                ) : null}
            </PreviewContent>
        </PreviewContainer>
    );
};

const Campaigns = () => {
    const { data: campaigns } = useCampaignsQuery();

    const newsAndOffers = getCampaignsBySection(campaigns, [SectionName.offer, SectionName.news]);

    const otherBrandsCampaigns = getCampaignsBySection(campaigns, [SectionName.otherBrandCampaign]);

    return (
        <FadeIn>
            <PageContainer padding={byPlatform('0 16px', '30px')}>
                <SectionTitle>Tilbud & Nyheder</SectionTitle>

                <Grid>
                    {newsAndOffers.map(campaign => (
                        <CampaignPreview campaign={campaign} />
                    ))}
                </Grid>

                {otherBrandsCampaigns.length > 0 && (
                    <>
                        <Delimiter />

                        <SectionTitle>Øvrige kampagner</SectionTitle>

                        <Grid>
                            {otherBrandsCampaigns.map(campaign => (
                                <CampaignPreview campaign={campaign} />
                            ))}
                        </Grid>
                    </>
                )}
            </PageContainer>
        </FadeIn>
    );
};

export default Campaigns;
