export const SHOW_STREAM = 'SHOW_STREAM';
export const HIDE_STREAM = 'HIDE_STREAM';
export const START_STOP_CAST = 'START_STOP_CAST';

export const showStream = (payload) => {
    return {
        type: SHOW_STREAM,
        payload,
    };
};

export const hideStream = (payload) => {
    return {
        type: HIDE_STREAM,
        payload,
    };
};

export const startStopCast = (payload) => {
    return {
        type: START_STOP_CAST,
        payload,
    };
};
