import React from 'react';
import DesktopModalWrapper from 'ui/DesktopModalWrapper';
import { ModalContainer } from './uiComponents';
import Checkout from './Checkout';
import Checkin from './Checkin';
import Status from './Status';
import SystemStatus from './SystemStatus';
import UnpaidSale from './UnpaidSale';
import { MODAL_TYPES } from './contstants';

export default class ScanModal extends React.Component {
    constructor() {
        super();

        this.focusInput = React.createRef();
    }

    focus = () => {
        this.focusInput.current && this.focusInput.current.focus();
    };

    static defaultProps = {
        onPlaceholderClick: () => {},
    };

    componentDidMount() {
        this.updateBodyOverflowStyle();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.visibility !== this.props.visibility) {
            this.updateBodyOverflowStyle();
        }

        this.focus();
    }

    updateBodyOverflowStyle = () => {
        document.body.style.overflow = this.props.visibility ? 'hidden' : '';
    };

    modalContainerClickHandler = (event) => {
        if (event.target === event.currentTarget) {
            this.props.onPlaceholderClick();
        }
    };

    getCurrentModal = () => {
        switch (this.props.type) {
            case MODAL_TYPES.CHECK_OUT:
                return <Checkout />;
            case MODAL_TYPES.CHECK_IN:
                return <Checkin />;
            case MODAL_TYPES.STATUS:
                return (
                    <Status
                        data={this.props.data}
                        userSessionAdapter={this.props.userSessionAdapter}
                    />
                );
            case MODAL_TYPES.SYSTEM_STATUS:
                return (
                    <SystemStatus
                        data={this.props.data}
                        userSessionAdapter={this.props.userSessionAdapter}
                    />
                );
            case MODAL_TYPES.UNPAID_SALE:
                return <UnpaidSale data={this.props.data} />;
            default:
                return null;
        }
    };

    render() {
        return (
            <ModalContainer
                onClick={this.modalContainerClickHandler}
                visibility={this.props.visibility ? 'visible' : 'hidden'}
            >
                <DesktopModalWrapper
                    minHeight="700px"
                    height="auto"
                    width="480px"
                    color="white"
                >
                    {this.getCurrentModal()}

                    {/* hidden button to switch focus from buttons in raceRard */}
                    <button style={{ opacity: 0 }} ref={this.focusInput} />
                </DesktopModalWrapper>
            </ModalContainer>
        );
    }
}
