import { useEffect, useState } from 'react';
import {
    Expert,
    getExperts,
    getFAQContent,
    HowSpilklubWorksPage,
} from './server';

const useExpertsData = (
    fetchExpertsOnFirstRender = false,
    fetchPagesOnFirstRender = false
) => {
    const [banner, setBanner] = useState('');

    const [expertsList, setExpertsList] = useState<Expert[]>([]);
    const [expertsListPending, setExpertsListPending] = useState(false);
    const [expertsListError, setExpertsListError] = useState('');

    const [pageContent, setPageContent] = useState<HowSpilklubWorksPage>();
    const [pageContentPending, setPageContentPending] = useState(false);
    const [pageContentError, setPageContentError] = useState('');

    const getExpertsList = () => {
        setExpertsListPending(true);

        return getExperts()
            .then((response) => {
                if (!response.success) {
                    throw response.msg;
                }
                setBanner(response.data.banner);
                setExpertsList(response.data.content);
            })
            .catch((e) => {
                setExpertsListError(e.toString());
            })
            .finally(() => {
                setExpertsListPending(false);
            });
    };

    const getContentPages = () => {
        setPageContentPending(true);

        return getFAQContent()
            .then((response) => {
                if (!response.success) {
                    throw response.msg;
                }
                setBanner(response.data.banner);
                setPageContent(response.data.content);
            })
            .catch((e) => {
                setExpertsListError(e.toString());
            })
            .finally(() => {
                setPageContentPending(false);
            });
    };

    useEffect(() => {
        fetchExpertsOnFirstRender && getExpertsList();
        fetchPagesOnFirstRender && getContentPages();
    }, []);

    return {
        getExpertsList,
        expertsList,
        expertsListPending,
        expertsListError,
        getContentPages,
        pageContent,
        pageContentPending,
        pageContentError,
        banner,
    };
};

export default useExpertsData;
