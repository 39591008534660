import React, { ComponentType, FC, useEffect } from 'react';
import { ModalProps } from '../types';

const withTransparentBackground =
    <T extends ModalProps<D>, D>(WrappedComponent: ComponentType<T>): FC<T> =>
    (props: T) => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        useEffect(() => props.setBackground('none'), []);

        return <WrappedComponent {...props} />;
    };

export default withTransparentBackground;
