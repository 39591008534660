/**
 * Save data to cookies
 * @param {Object} object Key value pairs to save in cookies
 */
const saveLS = (object) => {
    for (let p in object) {
        if (object.hasOwnProperty(p)) {
            window.localStorage.setItem(p, object[p]);
        }
    }
};

const removeLS = (keys) => {
    if (Array.isArray(keys)) {
        keys.forEach((key) => localStorage.removeItem(key));
    } else {
        localStorage.removeItem(keys);
    }
};

const getLS = (key, isValid, defaultValue = null) => {
    const value = window.localStorage.getItem(key);

    if (isValid) {
        if (isValid(value)) {
            return value;
        } else {
            saveLS({ [key]: defaultValue });
            return defaultValue;
        }
    }

    return value || defaultValue;
};

export { getLS, saveLS, removeLS };
