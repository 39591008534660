import React from 'react';
import { Col, Row } from 'react-grid-system';

import Spinner from 'ui/Spinner';
import Headline from 'ui/Headline';
import Toolbar from 'ui/Toolbar';
import Wrapper from 'ui/Wrapper';
import FlexWrapper from 'ui/FlexWrapper';
import Arrow from 'ui/Arrow';
import Button from 'ui/controls/Button';

import getTexts from 'utils/localization';
import { isMobile, isDesktop } from 'utils/platforms';
import { history } from 'utils/navigation';

import { PRODUCTS_IMAGES } from 'configs/products';

import useCalendarDate from 'features/EventBoard/hooks/useCalendarDate';
import useCalendarQuery from 'features/EventBoard/hooks/useCalendarQuery';
import useShortcutState from 'features/EventBoard/hooks/useShortcutState';
import { createProductShortcuts } from 'features/EventBoard/components/Shortcuts/helpers';
const t = getTexts();

const ProductShortcuts = ({ modalData, hideModal, noTopbar }) => {
    const { date } = useCalendarDate();
    const { data, isLoading } = useCalendarQuery({ date });
    const { setShortcut, shortcut: usedShortcut } = useShortcutState();
    const handleSelectProduct = shortcut => {
        // if currently displayed event in background is the same one that a user selects
        // then an effect in useLegacyAdaptions will not push a new location, thus we should just close a spilklub popup
        if (
            isDesktop &&
            shortcut.product === usedShortcut?.product &&
            shortcut.raceDate === usedShortcut?.raceDate &&
            shortcut.trackId === usedShortcut?.trackId
        ) {
            history.location.pathname === '/spil-klub25'
                ? history.goBack()
                : history.push({
                      state: { ...history.location.state, SPILKLUB_DESKTOP: undefined },
                  });
        }

        setShortcut(shortcut);
        // mobile still uses old-fashioned modal
        isMobile && hideModal && hideModal('PRODUCTS_SHORTCUTS');
    };

    if (isLoading) {
        return <Spinner />;
    }

    const shortcuts = createProductShortcuts(data.shortCuts);

    return (
        <Wrapper padding="0">
            {!noTopbar && (
                <Toolbar
                    secondary
                    left={
                        <FlexWrapper padding="0px">
                            <Arrow
                                onClick={() => {
                                    if (isMobile) {
                                        modalData?.onClose && modalData.onClose();
                                        hideModal('PRODUCTS_SHORTCUTS');
                                    }
                                }}
                                size="10"
                                direction="left"
                            />
                        </FlexWrapper>
                    }
                    leftSize={3}
                    centerSize={6}
                    rightSize={2}
                    alignLeft="center"
                    center={
                        <Headline white size="x2" align="center">
                            {t.Shortcuts.selectGame}
                        </Headline>
                    }
                />
            )}

            <Wrapper>
                <Row>
                    {shortcuts.map(shortcut => (
                        <Col key={shortcut.product} xs={4}>
                            <Button
                                onClick={() => handleSelectProduct(shortcut)}
                                block
                                height="70px"
                                width="95%"
                                padding="5px"
                                bg={PRODUCTS_IMAGES[shortcut.product]}
                                size="x2"
                                style={{ fontSize: '18px' }}
                                data-test-id={shortcut.product}
                            />
                        </Col>
                    ))}
                </Row>
            </Wrapper>
        </Wrapper>
    );
};

export default ProductShortcuts;
