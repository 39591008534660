import React from 'react';
import Wrapper from 'ui/Wrapper';
import RaceRow from 'features/RacesCarusel/VRaceOverview/RaceRow';

const VGameCard = (props) => {
    const {
        raceCardData,
        raceCardData: {
            races,
            selectedProduct,
            selectedDate,
            betSlip,
            trackCode,
            selectedBets,
            betPicks,
            setReserveMode,
            reserveModeEnabled,
        },
        maximumRaceLength,
    } = props;
    return (
        <Wrapper padding="0">
            {raceCardData && races
                ? races.map((race, index) => {
                      return (
                          <RaceRow
                              setReserveMode={setReserveMode}
                              reserveModeEnabled={reserveModeEnabled}
                              betSlip={betSlip}
                              selectedBets={selectedBets}
                              key={index}
                              race={race}
                              raceStarted={props.raceStarted}
                              selectedProduct={selectedProduct}
                              betPicks={betPicks}
                              index={index}
                              trackCode={trackCode}
                              selectedDate={selectedDate}
                              onBetAdded={props.onBetAdded}
                              onBetRemoved={props.onBetRemoved}
                              maximumRaceLength={maximumRaceLength}
                          />
                      );
                  })
                : null}
        </Wrapper>
    );
};

export default VGameCard;
