import { create } from 'zustand';

interface ReservesModeStore {
    reservesMode: boolean;
    toggleReservesMode(): void;
}

const useReservesMode = create<ReservesModeStore>()(set => ({
    reservesMode: false,
    toggleReservesMode: () => set(state => ({ reservesMode: !state.reservesMode })),
}));

export default useReservesMode;
