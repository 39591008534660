import React, { Component } from 'react';
import BetButton from 'features/RacesCarusel/VRaceOverview/BetButton';
import withManualReserves from 'features/RaceCard/withManualReserves';
import SelectRowButton from 'features/RacesCarusel/VRaceOverview/SelectRowButton';
import styled from 'styled-components';
import { Col, Row } from 'features/RacesCarusel/VRaceOverview/styled';
import { getSelectedRaceReserves } from 'common/selectors/betSlipSelector';
import { connect } from 'react-redux';
import {
    addAllBets,
    addReserve,
    removeLastReserve,
    removeReserve,
    resetRaceBet,
} from 'features/BetSlip/state/actions';
import { BACKGROUNDS } from 'themes';

const ColCenter = styled(Col)`
    text-align: center;
`;

const StyledRow = styled(Row)`
    height: 45px;
    align-items: center;
    &:nth-child(even) {
        background: ${BACKGROUNDS.grey};
    }
`;

const RaceNumber = styled(ColCenter)`
    align-items: center;
    font-weight: 900;
    font-size: 16px;
    letter-spacing: 0;
    height: 100%;
    line-height: 45px;
`;

const StartsRow = styled(ColCenter)`
    display: flex;
    justify-content: flex-start;
    padding-left: 10px;
`;

@withManualReserves
class RaceRow extends Component {
    render() {
        const { race, selectedProduct, betPicks, index, maximumRaceLength } = this.props;
        return (
            <StyledRow>
                <RaceNumber grow={0.05}>
                    {selectedProduct.name} - {index + 1}
                </RaceNumber>
                <StartsRow grow={0.9}>
                    {race.starts
                        ? race.starts.map((start, ind) => {
                              return (
                                  <BetButton
                                      betSlip={this.props.betSlip}
                                      key={ind}
                                      start={start}
                                      date={this.props.selectedDate}
                                      race={race}
                                      product={selectedProduct}
                                      betValue={start.startNr}
                                      raceStarted={this.props.raceStarted}
                                      scratched={start.isScratched()}
                                      raceReserves={this.props.raceReserves}
                                      addReserve={this.props.addReserve}
                                      removeReserve={this.props.removeReserve}
                                      reserveNumber={
                                          this.props.raceReserves.indexOf(start.startNr) + 1
                                      }
                                      reserveModeEnabled={this.props.reserveModeEnabled}
                                      betPicks={betPicks}
                                      onBetAdded={this.props.onBetAdded}
                                      onBetRemoved={this.props.onBetRemoved}
                                      maximumRaceLength={maximumRaceLength}
                                  />
                              );
                          })
                        : null}
                </StartsRow>
                <ColCenter grow={0.1}>
                    <SelectRowButton
                        addAllBets={this.props.addAllBets}
                        resetRaceBet={this.props.resetRaceBet}
                        selectedBets={this.props.selectedBets}
                        product={selectedProduct}
                        date={this.props.selectedDate}
                        raceStarted={this.props.raceStarted}
                        raceReserves={this.props.raceReserves}
                        betPicks={betPicks}
                        onBetAdded={this.props.onBetAdded}
                        onBetRemoved={this.props.onBetRemoved}
                        betBuddySelectionsMode={this.props.betBuddySelectionsMode}
                        race={race}
                    />
                </ColCenter>
            </StyledRow>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        reserves: getSelectedRaceReserves(state, ownProps),
        betBuddySelectionsMode: state.BetSlip.betBuddySelectionsMode.status,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        addReserve: (date, trackId, productId, raceIndex, startNr) => {
            dispatch(addReserve(date, trackId, productId, raceIndex, startNr));
        },
        removeReserve: (date, trackId, productId, raceNr, startNr) => {
            dispatch(removeReserve(date, trackId, productId, raceNr, startNr));
        },
        removeLastReserve: (date, trackId, productId, raceNr, startNr) => {
            dispatch(removeLastReserve(date, trackId, productId, raceNr, startNr));
        },
        addAllBets: (date, trackId, productId, raceNr, betsByDates) => {
            dispatch(addAllBets(date, trackId, productId, raceNr, betsByDates));
        },
        resetRaceBet: (date, trackCode, productId, raceIndex) => {
            dispatch(resetRaceBet(date, trackCode, productId, raceIndex));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RaceRow);
