import React from 'react';
import Fa from 'ui/Fa';
import Text from 'ui/Text';
import {
    BackButtonArea,
    TrackBarBackground,
    TrackBarContainer,
    TrackNameArea,
} from 'features/EventBoard/components/TrackBar';
import { CountryImage, getCountryImage } from 'features/EventBoard/components/TrackNameWithCountry';

import useActiveTrack from 'features/ResultsNew/hooks/useActiveTrack';

import { history } from 'utils/navigation';
import { byPlatform } from 'utils/platforms';

const mobileFlagWidth = '17px';
const desktopFlagWidth = '20px';

const TrackBar = () => {
    const { activeTrack } = useActiveTrack();

    const countryCode = activeTrack?.track?.country || '';
    return (
        <TrackBarBackground>
            <TrackBarContainer>
                <BackButtonArea
                    onClick={() => {
                        history.push('/resultat');
                    }}
                >
                    <Fa color={'white'} size={'x1'} section={'solid'} svgName={'angle-left'} />
                    {byPlatform(null, <Text color={'white'}>Oversigt</Text>)}
                </BackButtonArea>

                <TrackNameArea>
                    {countryCode && (
                        <CountryImage
                            src={activeTrack ? getCountryImage(countryCode) : ''}
                            height={byPlatform(mobileFlagWidth, desktopFlagWidth)}
                        />
                    )}

                    {activeTrack?.track?.track?.domesticText}
                </TrackNameArea>
            </TrackBarContainer>
        </TrackBarBackground>
    );
};

export default TrackBar;
