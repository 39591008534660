import { Dispatch, useState } from 'react';
import useBuffer from 'common/hooks/useBuffer';

export declare type Screens = Record<string, any>;

declare type UseScreensOutput = [
    DisplayScreen,
    CloseScreen,
    Shown,
    Screens,
    CloseAll,
    Dispatch<string[]>,
];

export interface DisplayScreen {
    <T>(screenId: string | string[], data?: T | Record<string, any>): void;
}

export interface CloseScreen {
    (screenId: string): void;
}

export interface CloseAll {
    (): void;
}

export interface SetValues {
    (): void;
}

export interface Shown {
    (screenId?: string): boolean;
}

const useScreens = (initial: string[] = []): UseScreensOutput => {
    const {
        values: screens,
        remove,
        add,
        purge,
        setValues,
    } = useBuffer({
        initial,
    });

    const [data, setData] = useState<Record<string, any>>({});

    const displayScreen: DisplayScreen = (screenId, screenData = {}) => {
        add(screenId);

        if (Array.isArray(screenId)) {
            const update = screenId.map((screenId, i) => [
                screenId,
                Array.isArray(screenData) ? screenData[i] : {},
            ]);
            setData({
                ...data,
                ...Object.fromEntries(update),
            });
        } else {
            setData({
                ...data,
                [screenId]: {
                    ...screenData,
                    goBack: () => closeScreen(screenId),
                },
            });
        }
    };

    const closeScreen: CloseScreen = screenId => {
        const update = { ...data };
        delete update[screenId];
        setData(update);
        remove(screenId);
    };

    const closeAll: CloseAll = () => {
        setData({});
        purge();
    };

    const shown: Shown = (screenId?) =>
        screenId ? data.hasOwnProperty(screenId) : screens.length !== 0;

    return [displayScreen, closeScreen, shown, data, closeAll, setValues];
};

export default useScreens;
