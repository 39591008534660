import { get } from 'lodash';

const getLockedLegs = (state) => {
    return {
        mobileBetInit: get(state, 'MobileBet.initialization', false),
        lockedLegs: get(state, 'MobileBet.data.fetchedBetData.lockedLegs', []),
    };
};

export { getLockedLegs };
