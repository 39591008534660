import React from 'react';
import Wrapper from 'ui/Wrapper';
import Text from 'ui/Text';
import MoneyOutGif from './assets/money-out.gif';
import styled from 'styled-components';
import getTexts from 'utils/localization';

const t = getTexts();

const ScanImg = styled.img`
    width: 100%;
    height: auto;
    object-fit: contain;
    object-position: center;
`;

export default class Checkout extends React.Component {
    render() {
        return (
            <Wrapper
                style={{
                    overflow: 'hidden',
                    height: '350px',
                }}
            >
                <Text
                    align="center"
                    size="x3"
                    style={{
                        paddingBottom: '60px',
                    }}
                >
                    {t.Terminal.Checkout.modalTitle}
                </Text>
                <ScanImg src={MoneyOutGif} alt="print coupon" />
            </Wrapper>
        );
    }
}
