import React from 'react';
import styled, { css } from 'styled-components';
import { ComponentType } from 'ui/types';
import { BACKGROUNDS, COLORS } from 'themes/index';
import Hr from 'ui/Hr';
import CommonButton from 'ui/controls/Button';
import CommonInput, {
    InputWrapper as CommonInputWrapper,
    InputControl as CommonInputControl,
} from 'ui/controls/Input';
import { AdjustableComponent } from 'ui/types';
import { CommonCardWrapper } from '../components/Card';
import logo from 'images/betbuddy.png';
import Text from 'ui/Text';
import checkIcon from 'images/icons/check-sm.png';
import ScrollBar from 'ui/ScrollBar';
import Fa from 'ui/Fa';
import Tooltip from 'ui/Tooltip';
import { CenteredButton } from '../components/styled';
import { byPlatform, isDesktop } from 'utils/platforms';
import { getUrl } from 'utils/navigation';

export const styles = {
    BetSlipCard: {
        Card: {
            margin: '-70px 15px 0 15px',
            boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
            position: 'relative',
            width: 'auto',
            padding: '0 0 50px',
        },
    },
};

// mixins

export const lightShadowMixin = css`
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
`;

export interface CursorMixinProps {
    pointer?: boolean;
}

export const cursorMixin = css<CursorMixinProps>`
    cursor: ${({ pointer }) => (pointer ? 'pointer' : 'auto')};
`;

export const loadingKeyframesMixin = css<{ loading?: boolean }>`
    @keyframes cardLoading {
        from {
            opacity: 0.5;
        }

        to {
            opacity: 1;
        }
    }
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
    animation-duration: 400ms;
    animation-name: cardLoading;
`;

// Common

export const TriggerButton = styled(CommonButton)`
    height: 50px;
    padding: 5px 25px;
    position: fixed;
    right: 5px;
    border-radius: 10px;
    border-bottom-right-radius: 2px;
    margin: 0;
    bottom: 65px;
`;

interface AnimationWrapperProps {
    priority?: number;
    duration?: number;
    animationMixin?: any;
    mainMixin?: any;
    transparent?: boolean;
}

export const AnimationWrapper = styled.div<AnimationWrapperProps>`
    overflow-y: auto;
    background: ${({ transparent }) => (transparent ? 'transparent' : BACKGROUNDS.white)};
    height: 100%;
    ${({ animationMixin }) => animationMixin};
    ${({ mainMixin }) => mainMixin};
    -ms-overflow-style: none;
`;

export const FormNote = styled.p`
    font-size: 18px;
    font-weight: 900;
    text-align: center;
`;

export const TopBar = styled.div<AdjustableComponent>`
    display: flex;
    width: 100%;
    height: ${({ height }) => `${height}` || '200px'};
    border-radius: 0 0 30px 30px;
    position: relative;
    color: ${COLORS.white};
    text-align: center;
    justify-content: center;
    flex-direction: column;
    background: ${({ background }) => background};
    background-size: cover;
`;

export const ProductLogo = styled.img`
    width: 110px;
    height: 45px;
`;

interface InputProps {
    red?: boolean;
    margin?: string;
    hidePlaceholder?: boolean;
    align?: string;
    label?: string;
    disabled?: boolean;
    error?: string;
    topLabel?: string;
    after?: string;
}
export const Input = styled(CommonInput)<InputProps>`
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    border: 1px solid ${({ red = false }) => (red ? BACKGROUNDS.red : BACKGROUNDS.lightGrey)};
    margin: ${({ margin = '20px 10px' }) => margin};
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    // hide the default input component placeholder for the inputs having labels inside
    & > input::placeholder {
        visibility: ${({ hidePlaceholder }) => (!hidePlaceholder ? 'visible' : 'hidden')};
        font-weight: normal;
        width: 100%;
    }
    & > input {
        text-align: ${({ align = 'right' }) => align};
        max-width: ${({ label }) => (label ? '50%' : '100%')};
        width: 100%;
        font-weight: 900;
        font-size: 16px;
        color: ${COLORS.black};
        color: ${({ disabled }) => (disabled ? 'rgba(0,0,0,0.5)' : COLORS.black)};
        border: 1px solid black;
        margin: 0;
        padding-left: 0;
        padding-right: 0;

        @media screen and (max-width: 320px) {
            max-width: ${({ label }) => (label ? '40%' : '100%')};
        }
    }
    // placeholder will be visible always so it's done with a pseudo selector
    &::before {
        ${({ label, error }) =>
            error ? `content: '${error}'` : label ? `content: '${label}'` : ''};
        max-width: ${({ topLabel }) => (topLabel ? '90%' : '50%')};
        width: 100%;
        line-height: 30px;
        font-size: ${({ topLabel }) => (topLabel ? '10px' : '16px')};
        ${({ topLabel }) =>
            topLabel
                ? `
            position: absolute;
            top: -22px;
            left: 20px;
        `
                : ''};
        color: ${({ error }) => (error ? COLORS.red : COLORS.primaryText)};

        @media screen and (max-width: 320px) {
            max-width: ${({ topLabel }) => (topLabel ? '90%' : '60%')};
        }
    }
    // for things like ' kr.'
    &::after {
        ${({ after }) => (after ? `content: '${after}'` : '')};
        padding-left: 10px;
        font-weight: 900;
        font-size: 16px;
        line-height: 32px;
        color: ${COLORS.black};
    }
`;
export const InputWrapper = styled<any>(Input).attrs({
    as: CommonInputWrapper,
})``;

export const DropdownInputWrapper = styled<ComponentType>(InputWrapper)`
    position: relative; /* For positioning DropDownSelection as absolute */
    &:before {
        position: absolute;
        pointer-events: none;
    }
`;

interface InputControlProps {
    placeholder?: string;
    autoComplete?: string;
    value?: any;
    onChange?: any;
    ref?: any;
    disabled?: boolean;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    pattern?: string;
    inputMode?: string;
}

export const InputControl: React.FunctionComponent<InputControlProps> = styled(
    CommonInputControl
)<InputControlProps>``;

export const ConfirmButton = styled(CommonButton)`
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
    font-weight: 700;
    padding: 12px 10px;
    width: 200px;
    height: 50px;
    opacity: 1;
    text-transform: uppercase;
    border-radius: 25px;
    font-size: 16px;
    display: block;
    margin: 0 auto;
`;
interface DropDownProps {
    background?: string;
}
export const DropDown = styled.select<DropDownProps>`
    pointer-events: auto;
    color: ${COLORS.black};
    font-size: 16px;
    width: 100%;
    border: 0;
    background: ${({ background = BACKGROUNDS.lightGrey }) => background};
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    font-weight: 900;
    text-align-last: right;
    & > option {
        color: black;
    }
    &::-ms-expand {
        display: none;
    }
`;

export const ButtonDropDown = styled(DropDown)`
    background: #eceff5;
    color: #404757;
    margin: 15px auto;
`;

interface DropDownSelectionProps {
    align?: string;
    right?: number;
    left?: number;
    fw?: string;
}
export const DropDownSelection = styled.div<DropDownSelectionProps>`
    pointer-events: none;
    color: ${COLORS.black};
    font-weight: ${({ fw = '900' }) => fw};
    line-height: 30px;
    text-align: ${({ align = 'right' }) => align};
    position: absolute;
    right: ${({ right = '15px' }) => right};
    left: ${({ left = 'auto' }) => left};
`;

export const BetBuddyBar = styled.div<{ poolMode?: boolean }>`
    display: flex;
    position: relative;
    width: 100%;
    justify-content: ${({ poolMode }) => (!poolMode && isDesktop ? 'flex-end' : 'space-between')};
    align-items: center;
    background: #00a556;
    height: 60px;
    padding: ${({ poolMode }) => (poolMode && !isDesktop ? '10px' : 0)};
    box-sizing: border-box;
`;

export const BetBuddyLogo = styled.img.attrs({ src: logo })`
    width: 120px;
    position: absolute;
    left: calc(50% - 60px);
`;

export const CardWrapper = styled(CommonCardWrapper)`
    ${lightShadowMixin};
    border: 0;
    margin-top: 20px;
    cursor: pointer;
`;

export const Button = styled(CommonButton)<{
    className?: string;
    size?: string;
    children?: string;
}>``;

export const HugeButton = styled(Button)`
    border-radius: 30px;
    display: block;
    margin: 0 auto 20px auto;
    font-weight: bold;
    width: 90%;
    padding: 20px;
    box-sizing: border-box;
    font-size: 16px;
`;

export const AddPoolBtn: ComponentType = styled(Button)`
    border-radius: 10px;
    display: block;
    margin: ${byPlatform('0 auto 20px auto', '0 0 20px 0')};
    padding: 10px 40px;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: 600;
`;

export const AddPoolIcon = styled.span`
    background: ${BACKGROUNDS.white};
    color: ${COLORS.primary};
    width: 20px;
    border-radius: 20px;
    height: 20px;
    align-items: center;
    font-weight: 900;
    text-align: center;
    padding-left: 1px;
    margin-right: 5px;
    display: flex;
    box-sizing: border-box;
    font-size: 20px;
    float: left;
    position: relative;
    top: -1px;
    justify-content: center;
`;

// BetSlipScreen - Step 1

export const Delimiter = styled(Hr)`
    padding: 0 10px 10px;
    box-sizing: border-box;
`;

export const ConfirmBtn = styled(ConfirmButton)`
    position: relative;
    top: -25px;
`;

// GroupFormScreen - Step 2

export const TopBarBox = styled(TopBar)`
    padding: 50px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 320px) {
        padding: 50px 15px;
    }
`;

export const TopBarEvent = styled.div`
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 15px;
`;

export const TopBarEventLine = styled.p`
    margin: 3px 0;
    text-align: left;
`;

export const CriteriaForm = styled.form`
    padding: 0 30px;
    transform: translateY(-40px);

    @media screen and (max-width: 320px) {
        padding: 0 20px;
    }
`;

export const InputWithAvatar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 20px 10px;
`;

export const GroupNameInputWrapper = styled<ComponentType>(InputWrapper)`
    flex-grow: 1;
    margin: 0 10px 0 0;
`;

export const IncrementArea = styled.div`
    width: 100px;
    display: flex;
    font-weight: 900;
    font-size: 16px;
    line-height: 32px;
    justify-content: space-between;
    color: ${COLORS.black};
    align-items: center;
`;

export const IncrementBtn = styled.button<{ onClick?: any }>`
    font-family: Tahoma, sans-serif;
    width: 25px;
    height: 25px;
    border-radius: 25px;
    border: 2px solid #9198aa;
    color: #9198aa;
    text-align: center;
    font-size: 17px;
    font-weight: bold;
    padding: 0;
    line-height: 20px;
    background: none;
`;

export const PublicGroupTooltip = styled(Tooltip).attrs({
    arrowLocation: 'bottom',
})`
    top: -133px;
    left: -87px;
    width: 200px;
    color: ${COLORS.primaryText};
    font-weight: normal;
    &::before {
        left: calc(50% - 10px);
    }
`;

interface AvatarButtonProps {
    avatar?: string;
    onClick?: any;
}

export const AvatarButton = styled.div<AvatarButtonProps>`
    height: 50px;
    width: 50px;
    background: ${({ avatar }) => (avatar ? `url(${getUrl(avatar)})` : BACKGROUNDS.lightGrey)};
    border: 1px solid ${COLORS.lightGrey};
    border-radius: 50%;
    background-size: cover;
`;

export const AvatarSelection = styled(AvatarButton)<{ active?: boolean }>`
    margin-right: 10px;
    height: 70px;
    width: 70px;
    ${({ active }) =>
        active
            ? `
        &::before {
            @-webkit-keyframes appear {
                from {
                    height: 0;
                    width: 0;
                    transform: translateX(10px);
                }
                to {
                    width: 20px;
                    height: 20px;
                    transform: translateX(0);
                }
            }
            content: ' ';
            margin: 0;
            padding: 0;
            display: block;
            background: url(${checkIcon}) no-repeat;
            background-size: contain;
            width: 20px;
            height: 20px;
            top: -5px;
            right: -45px;
            position: relative;
            -webkit-animation: 300ms appear forwards;
        }
    `
            : ``};
`;

export const LockIcon = styled(Fa).attrs<{}>({
    svgName: 'lock',
    size: 'x05',
    color: 'grey',
})`
    line-height: 34px;
`;

// Avatar Modal

export const Avatar = styled.div<{ bg: string }>`
    background: ${({ bg }) => `url(${bg}) center center` || '#dedede'};
    background-size: cover;
    width: 70px;
    max-width: 100%;
    height: 70px;
    border-radius: 50%;
    margin: 5px auto;
    cursor: pointer;
`;

// Groups

export const GroupListScreen = styled.div<{ singleGroupMode: boolean }>`
    padding: ${({ singleGroupMode }) => (singleGroupMode ? `0 20px` : byPlatform('0 20px', 0))};
    max-width: 400px;
    margin: 0 auto;
`;

export const groupAvatarMixin = css`
    width: 150px;
    height: 150px;
    display: block;
    margin: -20px auto 0 auto;
    border-radius: 50%;
    ${lightShadowMixin};
    @media screen and (min-width: 900px) {
        margin: 20px auto 0 auto;
    }
`;

export const HugeGroupAvatar = styled.img`
    ${groupAvatarMixin};
`;

export const EditGroupDescriptionInput = styled.input.attrs({
    maxLength: 150,
})`
    background: #eceff5;
    padding: 15px;
    color: #404757;
    font-size: 18px;
    min-height: 50px;
    border: none;
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
    margin: 0 auto;
`;

export const GroupSmsWrapper = styled.div`
    margin: 5px 0 10px;
`;

export const GroupSmsSwitcher = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
`;

export const DefaultAvatar = styled.div`
    text-transform: uppercase;
    text-align: center;
    line-height: 150px;
    font-size: 65px;
    background: ${BACKGROUNDS.white};
    ${groupAvatarMixin};
`;

export const LeaveGroupBtn = styled(Button)`
    width: 100px;
    height: 40px;
    line-height: 40px;
    padding: 0;
    border-radius: 17px;
    text-transform: uppercase;
    font-weight: 900;
`;

interface SubscriptionButtonProps {
    isActive?: boolean;
}

export const MainButton = styled(Button)`
    display: block;
    border-radius: 40px;
    text-align: center;
    width: 290px;
    height: 65px;
    font-size: 22px;
    text-transform: uppercase;
    font-weight: bold;
    box-shadow: 0 0 35px #00000040;
`;

export const SubscriptionButton = styled(MainButton)<SubscriptionButtonProps>`
    background: ${({ isActive }) => (isActive ? COLORS.accent : COLORS.special.calm)};
    color: ${COLORS.black};
    margin: 15px auto;
`;

export const LeaveGroupButton = styled(MainButton)<SubscriptionButtonProps>`
    background: ${BACKGROUNDS.red};
    color: ${COLORS.white};
    margin: 25px auto 15px;
`;

// Subscribe
export const DeleteButton = styled.div`
    position: absolute;
    right: -8px;
    top: -8px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 30px;
    color: #9198aa;
    font-weight: bold;
    font-size: 21px;
    background: ${BACKGROUNDS.lightGrey};
`;

export const SubscribeWrapp = styled.div`
    position: relative;
    padding: 15px 25px 0;
`;

export const SwitcherContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 45px;
    padding-left: 15px;
`;

export const MainText = styled(Text)`
    max-width: 95%;
    margin: 0 auto 10px;
`;

export const TermsCons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    line-height: 15px;
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    background: ${COLORS.grey};
    font-size: 18px;
    font-weight: 900;
    color: ${COLORS.white};
`;

export const InputsContainer = styled.div`
    display: flex;
    justify-content: flex-start;
`;

export const AddAbonnementDropdown = styled(DropdownInputWrapper)`
    border: none;
    background: ${BACKGROUNDS.secondary};
    color: ${COLORS.white};
    margin: 20px auto;
    height: 45px;
    border-radius: 30px;
    max-width: 250px;
    font-size: 16px;
    > div {
        color: white;
        line-height: 45px;
        font-weight: 900;
    }
`;

export const PopupWrapper = styled.div`
    height: 100%;
    max-height: 900px;
    max-width: 600px;
    width: 100%;
    position: relative;
    margin: 0 auto;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.7);
`;

export const PopupNoteWrapper = styled.div`
    max-width: 80%;
    height: 200px;
    box-sizing: border-box;
    padding: 40px 30px;
    background: white;
    margin: 0 auto;
    display: block;
    border-radius: 20px;
    position: relative;
`;

export const PopupNoteButtonGroup = styled.div`
    position: absolute;
    bottom: -125px;
    left: 0;
    width: 100%;
`;

// Pool Dashboard
export const PoolTopBar = styled(TopBar)`
    box-sizing: border-box;
    display: flex;
    font-size: 24px;
    font-weight: 900;
    justify-content: flex-start;
    padding: 25px 50px 0;
`;

export const GroupName = styled.div`
    font-size: 17px;
    font-weight: normal;
`;

export const Selections = styled(CardWrapper)`
    display: block;
    margin: 0 35px 35px 35px;
    padding-bottom: 20px;
`;

export const AmountsCard = styled(CardWrapper)`
    display: block;
    margin: 0 35px 35px 35px;
    padding-bottom: 20px;
    color: ${COLORS.primaryText};
`;

export const AmountsCardRow = styled.div<{ bold?: boolean }>`
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
    font-weight: ${({ bold }) => (bold ? 900 : 500)};
`;

export const DraftMarker = styled.span`
    background: ${BACKGROUNDS.accentAlt};
    border-radius: 15px;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 900;
    padding: 6px;
    position: absolute;
    right: 15px;
    top: -10px;
`;

export const SelectionsHeader = styled.div`
    padding: 20px 30px;
`;

export const BetPickContainer = styled.div`
    & > div {
        margin: 0 !important;
        font-size: 13px;
    }
`;

export const PlayTogether = styled(Text)`
    display: inline-block;
    margin-right: 5px;
    font-size: 16px;
`;

export const CombinationsCount = styled.span`
    background: ${BACKGROUNDS.white};
    color: ${COLORS.primary};
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 10px;
`;

export const PriceCalculationLink = styled.a`
    color: ${COLORS.primaryText};
    display: block;
    margin: 15px auto 50px;
    text-align: center;
    font-weight: bold;
`;
export const SharesSelect = styled.select`
    width: 100%;
    position: absolute;
    margin: 0 auto;
    opacity: 0;
    height: 75px;
    bottom: 0;
    -webkit-appearance: menulist-button;
    cursor: pointer;
`;

export const AddCouponButton = styled(CenteredButton)`
    cursor: pointer;
    background: ${COLORS.black};
    color: ${COLORS.white};
    font-size: 18px;
    margin-bottom: 20px;
}
`;

// Group Scrollbar

export const GroupScrollbar = styled(ScrollBar)<{ dataFetched: boolean }>`
    transform: translateY(-40px);
    padding: 0 30px;
    margin: 45px 0 0 0;
    overflow-y: hidden;

    @media screen and (max-width: 320px) {
        padding: 0 20px;
    }

    @keyframes fadeInGroupScrollbar {
        from {
            height: 0;
            opacity: 0;
        }

        to {
            height: auto;
            opacity: 1;
        }
    }
    opacity: 0;
    animation-duration: 400ms;
    animation-name: ${({ dataFetched }) => (dataFetched ? 'fadeInGroupScrollbar' : 'none')};
    animation-timing-function: cubic-bezier(0.1, -0.6, 0.2, 0);
    animation-fill-mode: both;
    animation-direction: normal;
`;

export const GroupScrollbarItem = styled.div`
    display: inline-block;
    justify-content: flex-start;
    flex-direction: column;
`;

export const GroupScrollbarTitleVars = {
    FONT_SIZE: 12,
    LINE_HEIGHT: 1.4,
    LINES_TO_SHOW: 2,
};

export const GroupScrollbarTitle = styled.div`
    text-align: center;
    width: 70px;
    color: ${COLORS.primaryText};

    display: block; //Fallback for non-webkit
    display: -webkit-box;
    max-width: 70px;
    height: ${GroupScrollbarTitleVars.FONT_SIZE *
    GroupScrollbarTitleVars.LINE_HEIGHT *
    GroupScrollbarTitleVars.LINES_TO_SHOW}px; //Fallback for non-webkit
    margin: 10px 0 0;
    font-size: ${GroupScrollbarTitleVars.FONT_SIZE}px;
    line-height: ${GroupScrollbarTitleVars.LINE_HEIGHT};
    -webkit-line-clamp: ${GroupScrollbarTitleVars.LINES_TO_SHOW};
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    box-sizing: border-box;
`;

export const PoolListWrapper = styled.div``;
