import { MODALS } from 'features/Spilklub/config';

const getModalStatuses = (state) => {
    return Object.fromEntries(Object.values(state.ui.modals).map((m) => [m.id, m.visible]));
};

const getModals = (state) => {
    return state.ui.modals;
};

const isModalShown = (state, modalId) => {
    return state.ui.modals[modalId] && state.ui.modals[modalId].visible;
};

const getUnwrappedModalStatuses = (state) => {
    return Object.fromEntries(
        Object.values(state.ui.unwrappedModals).map((m) => [
            m.id,
            { visible: m.visible, data: m.data },
        ])
    );
};

const betBuddyShown = (state) => {
    return (
        state.ui.unwrappedModals[MODALS.BET_BUDDY_ENTRY] &&
        state.ui.unwrappedModals[MODALS.BET_BUDDY_ENTRY].visible
    );
};

export { getModalStatuses, getModals, isModalShown, getUnwrappedModalStatuses, betBuddyShown };
