import React from 'react';
import Toolbar from '../ui/Toolbar';
import Headline from '../ui/Headline';
import Button from '../ui/controls/Button';
import Wrapper from '../ui/Wrapper';
import Text from '../ui/Text';
import Icon from '../ui/Icon';
import pinLoginIcon from '../images/icons/pin-login.png';
import { Col, Row } from 'react-grid-system';
import { Card, CardContent, CardFooter, CardHeader } from '../ui/Card';
import Switcher from '../ui/controls/Switcher';
import Input from '../ui/controls/Input';
import Divider from '../ui/Divider';
import Accordion from '../ui/Accordion';
import AccordionDark from '../ui/AccordionDark';
import Spinner from '../ui/Spinner';
import Carousel from '../ui/Carousel';
import DotsIndicators from '../ui/DotsIndicators';
import styled from 'styled-components';
import Mark from 'ui/Mark';
import InputDropdown from 'ui/controls/DropDown';

const Slide = styled.div`
    order: ${(props) => props.order};
    background: pink;
    height: 100px;
    font-size: 60px;
    line-height: 100px;
    color: #fff;
    text-align: center;
`;

const BrandBook = () => (
    <div>
        <Headline>Headline Component</Headline>
        <Wrapper>
            <Headline size="x1">Property size="x1"</Headline>
            <Headline size="x2">Property size="x2"</Headline>
            <Headline size="x3">Property size="x3"</Headline>
            <Headline size="x4">Property size="x4"</Headline>
            <Headline size="x5">Property size="x5"</Headline>
            <Headline size="x6">Property size="x6"</Headline>
        </Wrapper>
        <Wrapper>
            <Headline primary="primary" size="3">
                Property primary
            </Headline>
            <Headline accent="accent" size="3">
                Property accent
            </Headline>
            <Headline secondary="secondary" size="3">
                Property secondary
            </Headline>
            <Headline background="primary">Property background primary</Headline>
        </Wrapper>
        <Headline>Text Component</Headline>
        <Wrapper>
            <Text size="x05">Property size="x05"</Text>
            <Text size="x1">Property size="x1"</Text>
            <Text size="x2">Property size="x2"</Text>
            <Text size="x3">Property size="x3"</Text>
            <Text size="x05" accent="accent">
                For setting different colors use next props:
            </Text>
            <Row>
                <Col>
                    <Text size="x05" primary="primary">
                        primary
                    </Text>
                </Col>
                <Col>
                    <Text size="x05" accent="accent">
                        accent
                    </Text>
                </Col>
                <Col>
                    <Text size="x05" secondary="secondary">
                        secondary
                    </Text>
                </Col>
            </Row>
        </Wrapper>
        <Headline>Toolbar Component</Headline>
        <Wrapper>
            <Toolbar left={'left={...}'} center={'center={...}'} right={'right={...}'} />
            <Text>Properties:</Text>
            <Row>
                <Col>
                    <Text accent="accent" size="x05">
                        Prop name
                    </Text>
                </Col>
                <Col>
                    <Text accent="accent" size="x05">
                        Prop value
                    </Text>
                </Col>
                <Col>
                    <Text accent="accent" size="x05">
                        Default value
                    </Text>
                </Col>
            </Row>
            <Divider grey="grey" />
            <Row>
                <Col>
                    <Text size="x05">left || center || right</Text>
                </Col>
                <Col>
                    <Text size="x05">Component</Text>
                </Col>
                <Col>
                    <Text size="x05">null</Text>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Text size="x05">alignLeft</Text>
                </Col>
                <Col>
                    <Text size="x05">start || center || end</Text>
                </Col>
                <Col>
                    <Text size="x05">start</Text>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Text size="x05">alignCenter</Text>
                </Col>
                <Col>
                    <Text size="x05">start || center || end</Text>
                </Col>
                <Col>
                    <Text size="x05">center</Text>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Text size="x05">alignRight</Text>
                </Col>
                <Col>
                    <Text size="x05">start || center || end</Text>
                </Col>
                <Col>
                    <Text size="x05">end</Text>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Text size="x05">leftSize</Text>
                </Col>
                <Col>
                    <Text size="x05">1...12</Text>
                </Col>
                <Col>
                    <Text size="x05">4</Text>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Text size="x05">centerSize</Text>
                </Col>
                <Col>
                    <Text size="x05">1...12</Text>
                </Col>
                <Col>
                    <Text size="x05">4</Text>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Text size="x05">rightSize</Text>
                </Col>
                <Col>
                    <Text size="x05">1...12</Text>
                </Col>
                <Col>
                    <Text size="x05">4</Text>
                </Col>
            </Row>
        </Wrapper>
        <Headline>Button Components</Headline>
        <Wrapper>
            <Text>There are buttons with different colour scheme:</Text>
            <Button primary="primary">Property "primary" (by default)</Button>
            <Button secondary="secondary">Property "secondary"</Button>
            <Button accent="accent">Property "accent"</Button>
            <Button blue="blue">Property "blue"</Button>
            <Button red="red">Property "red"</Button>
            <Button grey="grey">Property "grey"</Button>
            <Button blue="blue" disabled="disabled">
                Disabled
            </Button>
        </Wrapper>
        <Wrapper>
            <Text>There are buttons with different sizes:</Text>
            <Button size="x05">Property size="x05"</Button>
            <Button size="x1">Property size="x1" (by default)</Button>
            <Button size="x2">Property size="x2"</Button>
            <Button size="x3">Property size="x3"</Button>
        </Wrapper>
        <Headline>Input Components</Headline>
        <Wrapper>
            <Input placeholder="Field Name" sublabel="(some explanations about validation)" />
            <InputDropdown placeholder="DropDown name" options={['red', 'blue', 'yellow']} />
        </Wrapper>
        <Headline>Switcher Component</Headline>
        <Wrapper>
            <Row>
                <Col xs={8}>
                    <Text>Switcher Label</Text>
                </Col>
                <Col xs={4}>
                    <Switcher
                        checked={true}
                        onChange={(state) => console.log('Switcher state: ' + state)}
                    />
                </Col>
            </Row>
        </Wrapper>
        <Headline>Icon Components</Headline>
        <Wrapper>
            <Text>There are icons with different sizes:</Text>
            <Row>
                <Col>
                    <Text>Icon with size="x05":</Text>
                </Col>
                <Col>
                    <Icon size="x05" alt="alternative" src={pinLoginIcon} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Text>Icon with size="x1":</Text>
                </Col>
                <Col>
                    <Icon size="x1" alt="alternative" src={pinLoginIcon} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Text>Icon with size="x2":</Text>
                </Col>
                <Col>
                    <Icon size="x2" alt="alternative" src={pinLoginIcon} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Text>Icon with size="x3":</Text>
                </Col>
                <Col>
                    <Icon size="x3" alt="alternative" src={pinLoginIcon} />
                </Col>
            </Row>
        </Wrapper>
        <Headline>Card Component</Headline>
        <Wrapper>
            <Card>
                <CardHeader>Card Header</CardHeader>
                <CardContent>Card Content</CardContent>
                <CardFooter>Card Footer</CardFooter>
            </Card>
        </Wrapper>
        <Headline>Slider</Headline>
        {/*<Wrapper>*/}
        {/*<Text>Slider component with selectable items:</Text>*/}
        {/*<Slider movable="movable" count={8} />*/}
        {/*<Text>Some of the items can be disabled:</Text>*/}
        {/*<Slider movable="movable" count={8} disabled={[0, 1, 4]} />*/}
        {/*<Text>*/}
        {/*Without property*/}
        {/*<strong>movable</strong>*/}
        {/*users can't select slider items. In this case we can use slider*/}
        {/*as indicator only.*/}
        {/*</Text>*/}
        {/*<Slider movable={false} count={8} />*/}
        {/*</Wrapper>*/}

        <Headline>Spinner Component</Headline>
        <Wrapper>
            <Spinner grey size="x05" />
            <Spinner grey size="x1" />
            <Spinner grey size="x2" />
            <Spinner grey size="x3" />
        </Wrapper>

        <Headline>DotsIndicators Component</Headline>
        <Wrapper>
            <DotsIndicators activeDot={0} dotsNumber={4} />
        </Wrapper>

        <Headline>Alert Component</Headline>
        <Wrapper>
            <Mark accent>
                <strong>Alert accent.</strong> Fa 100 procent
            </Mark>
            <Mark primary>
                <strong>Alert primary.</strong> Fa 100 procent
            </Mark>
            <Mark grey>
                <strong>Alert grey.</strong> Fa 100 procent
            </Mark>
            <Mark blue>
                <strong>Alert blue.</strong> Fa 100 procent
            </Mark>
            <Mark red>
                <strong>Alert red.</strong> Fa 100 procent
            </Mark>
        </Wrapper>

        <Headline accent="accent">Examples of features</Headline>
        <Divider secondary="secondary" />

        <Headline>Accordeon</Headline>
        <Wrapper>
            <Accordion head={<h3> Accordeon Header</h3>}>
                <Wrapper>
                    <h3>Some content here</h3>
                    <Text size="x05" accent="accent">
                        Another content here
                    </Text>
                </Wrapper>
            </Accordion>
        </Wrapper>
        <Wrapper>
            <AccordionDark title={'Find spil'}>
                <Wrapper>
                    <Row>
                        <Col>
                            <Button size="x2">V75</Button>
                        </Col>
                        <Col>
                            <Button size="x2" blue="blue">
                                V86
                            </Button>
                        </Col>
                        <Col>
                            <Button size="x2" red="red">
                                V65
                            </Button>
                        </Col>
                    </Row>
                </Wrapper>
            </AccordionDark>
        </Wrapper>
        <Headline>CSS Utilities</Headline>
        <Wrapper>
            <Text size="x1" accent="accent" className="text-center">
                Centered text, class "text-center"
            </Text>
            <Text size="x1" accent="accent" className="text-right">
                Aligned to right, class "text-right"
            </Text>
            <Text size="x1" accent="accent" className="uppercase">
                Uppercased text, class "uppercase"
            </Text>
            <Button size="x2" accent="primary" className="full-width">
                Full width element, class "full-width"
            </Button>
        </Wrapper>

        <Headline>Carousel</Headline>

        <Wrapper>
            <Text>
                Carousel component is swipeable, but can be controlled by buttons or any kind of
                controls as well.
            </Text>
            <Text>
                Centered carousel (property <strong>centering=true</strong>)
            </Text>
            <Carousel numVisible={2} centering={true} animationDuration={800} slideWidth={80}>
                <Card>
                    <CardHeader>Card 1 Title</CardHeader>
                    <CardContent>Card 1 content</CardContent>
                    <CardFooter>Card 1 footer</CardFooter>
                </Card>
                <Card>
                    <CardHeader>Card 2 Title</CardHeader>
                    <CardContent>Card 2 content</CardContent>
                    <CardFooter>Card 2 footer</CardFooter>
                </Card>
                <Card>
                    <CardHeader>Card 3 Title</CardHeader>
                    <CardContent>Card 3 content</CardContent>
                    <CardFooter>Card 3 footer</CardFooter>
                </Card>
            </Carousel>

            <Text>
                It's possible to blur inactive slides within <strong>"blurInactive"</strong>{' '}
                property.
            </Text>
            <Text>
                We also can animate inactive slides when they are focused out. In order to do that,
                you should pass <strong>inactiveAnimationDuration</strong> property (ms).
            </Text>
            <Carousel
                numVisible={2}
                centering={true}
                animationDuration={400}
                inactiveAnimationDuration={300}
                slideWidth={80}
                blurInactive
            >
                <Card>
                    <CardHeader>Card 1 Title</CardHeader>
                    <CardContent>Card 1 content</CardContent>
                    <CardFooter>Card 1 footer</CardFooter>
                </Card>
                <Card>
                    <CardHeader>Card 2 Title</CardHeader>
                    <CardContent>Card 2 content</CardContent>
                    <CardFooter>Card 2 footer</CardFooter>
                </Card>
                <Card>
                    <CardHeader>Card 3 Title</CardHeader>
                    <CardContent>Card 3 content</CardContent>
                    <CardFooter>Card 3 footer</CardFooter>
                </Card>
            </Carousel>

            <Text>
                <strong>inactiveAnimationEffect</strong> property also receives an effect called
                "pushAdide".
            </Text>
            <Carousel
                numVisible={2}
                centering={true}
                animationDuration={400}
                inactiveAnimationDuration={300}
                inactiveAnimationEffect="pushAside"
                slideWidth={80}
                blurInactive
            >
                <Card>
                    <CardHeader>Card 1 Title</CardHeader>
                    <CardContent>Card 1 content</CardContent>
                    <CardFooter>Card 1 footer</CardFooter>
                </Card>
                <Card>
                    <CardHeader>Card 2 Title</CardHeader>
                    <CardContent>Card 2 content</CardContent>
                    <CardFooter>Card 2 footer</CardFooter>
                </Card>
                <Card>
                    <CardHeader>Card 3 Title</CardHeader>
                    <CardContent>Card 3 content</CardContent>
                    <CardFooter>Card 3 footer</CardFooter>
                </Card>
            </Carousel>

            <Text>
                Left aligned carousel (without property
                <strong>"centering"</strong>
                ).
            </Text>

            <Carousel
                numVisible={2}
                animationDuration={300}
                slideWidth={80}
                inactiveAnimationDuration={300}
            >
                <Slide>1</Slide>
                <Slide>2</Slide>
                <Slide>3</Slide>
                <Slide>4</Slide>
            </Carousel>

            <Text>
                Carousel can be used as a slider, for that purpose we can either set property{' '}
                <strong>numVisible="1"</strong> or <strong>slideWidth="100"</strong>
            </Text>
            <Text>
                Property <strong>animationDuration</strong> allows to set animation speed in
                miliseconds.
            </Text>
            <Text>
                Property <strong>transitionTimingFunction</strong> allows to set any existing css
                transition function.
            </Text>
            <Carousel
                slideWidth={100}
                animationDuration={200}
                transitionTimingFunction={'ease-in-out'}
            >
                <Slide>1</Slide>
                <Slide>2</Slide>
                <Slide>3</Slide>
                <Slide>4</Slide>
            </Carousel>
        </Wrapper>
    </div>
);

export default BrandBook;
