import { useState } from 'react';

const useFade = ({ destroy }) => {
    const [hiding, setHiding] = useState(false);

    const animateHide = () => {
        setHiding(true);
    };

    const onAnimationEnd = () => {
        if (hiding) {
            destroy();
        }
    };

    return { animateHide, onAnimationEnd, hiding };
};

export default useFade;
