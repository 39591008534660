import RaceDay from 'common/DataObjects/RaceDay';
import RacingCard from 'common/DataObjects/RacingCard';
import Track from 'common/DataObjects/Track';
import { isEmpty } from 'lodash';
import { get } from 'lodash';
import { generateUniqueMultitrackTrackCode } from 'common/DataObjects/MultipleTrackSetups';

const multitrackSetupPath = ['multipleTrackPoolSetups', 'multipleTrackPoolSetup'];

export default class Unserializer {
    json = null;

    constructor(json) {
        this.json = json;

        return this;
    }

    getRacesDay() {
        try {
            let tracks = { byCode: {}, list: [] };

            const raceDayData = this.json.raceDayInfos.raceDayInfos
                .map(item => {
                    const track = Track.unserialize(item.trackId, {
                        ...item.track,
                        name: item.trackName,
                    });
                    if (!tracks.byCode[item.track.code]) {
                        tracks.byCode[item.track.code] = track;
                    }
                    tracks.list.push(track);

                    const multitrackSetups = get(item, multitrackSetupPath, []);

                    if (!isEmpty(multitrackSetups)) {
                        multitrackSetups.forEach(({ track, trackId, multipleTrackName }) => {
                            const trackCode = generateUniqueMultitrackTrackCode(
                                track.code,
                                item.raceDayDate
                            );

                            const unserialized = Track.unserialize(trackId, {
                                ...track,
                                code: track.code,
                                name: multipleTrackName,
                                hostDate: item.raceDayDate,
                            });

                            if (!tracks.byCode[trackCode]) {
                                tracks.byCode[trackCode] = unserialized;
                                tracks.list.push(unserialized);
                            }
                        });
                    }

                    return RaceDay.unserialize(item, false);
                })
                .filter(raceDay => {
                    return raceDay.raceCardAvailable && raceDay.programNumber !== 0;
                });

            return { raceDayData, tracks };
        } catch (error) {
            console.log(error);
            return [];
        }
    }

    getRacingCard(trackPoolData) {
        try {
            return RacingCard.unserialize(this.json, trackPoolData);
        } catch (error) {
            console.log(`RacingCard deserialization error: ${error}`);
            return new RacingCard();
        }
    }
}
