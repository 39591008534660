import styled from 'styled-components';

const Row = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Col = styled.div`
    padding: ${({ pr = 5, pl = 5 }) => `0 ${pr}px 0 ${pl}px`};
    box-sizing: border-box;
    flex-basis: 5%;
    flex-grow: ${({ grow = 0 }) => (typeof grow === 'number' ? grow : 1)};
    align-self: center;
    display: ${({ flex }) => (flex ? 'flex' : 'auto')};
    justify-content: ${({ center }) => (center ? 'center' : 'auto')};
`;

export { Col, Row };
