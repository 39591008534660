import React from 'react';
import { useSelector } from 'react-redux';
import {
    Table,
    TableHeader,
    TableContent,
    TableSubHeader,
    SubHeaderCell,
    TableRow,
    TableCell,
    HorseNrBtn,
    ExpandBtn,
    ForecastTooltip,
    QuestionMark,
} from './styled';
import useToggle from 'common/hooks/useToggle.ts';
import { thousandsDots } from 'utils/formatters';
import Icon from 'ui/Icon';
import Text from 'ui/Text';
import productSelector from 'common/selectors/productSelector';
import { byPlatform } from 'utils/platforms';
import usePreventDefault from 'common/hooks/usePreventDefault';

/**
 * @param {Race}    raceDay
 */
const Forecast = ({ ongoingRace }) => {
    const racingCardPending = useSelector(state => state.AISDataProvider.racingCardPending);
    const trackPoolPending = useSelector(state => state.AISDataProvider.trackPoolPending);
    const raceDayPending = useSelector(state => state.AISDataProvider.raceDayPending);
    const product = useSelector(productSelector);

    const [shown, toggle, delayedShown] = useToggle(false, 500);
    const toggleFn = usePreventDefault(toggle, true);

    if (
        !ongoingRace ||
        raceDayPending ||
        racingCardPending ||
        trackPoolPending ||
        !product.isVProduct()
    ) {
        return null;
    }

    return (
        <>
            <Table>
                <TableHeader shown={delayedShown} onClick={toggleFn}>
                    <div>
                        <Text white size={'x1.5'} weight={'700'}>
                            {product.id} værdier efter {ongoingRace.index + 1}. afd.
                        </Text>
                        <QuestionMark
                            data-tip
                            data-place={'top'}
                            data-type={'dark'}
                            data-effect={'solid'}
                            data-for={'forecast-tooltip'}
                            onClick={e => {
                                e.stopPropagation();
                            }}
                        >
                            ?
                        </QuestionMark>
                    </div>
                    <ExpandBtn onClick={toggleFn}>
                        <Icon
                            iarrowsmooth
                            size="x025"
                            style={{
                                position: 'relative',
                                top: shown ? 0 : '-2px',
                                transition: 'all 200ms linear',
                            }}
                            rotation={shown ? 180 : undefined}
                        />
                    </ExpandBtn>
                </TableHeader>
                <TableContent shown={shown}>
                    <TableSubHeader>
                        <SubHeaderCell style={{ justifySelf: 'center' }}>Nr.</SubHeaderCell>
                        <SubHeaderCell>Hest</SubHeaderCell>
                        <SubHeaderCell>Værdi</SubHeaderCell>
                    </TableSubHeader>
                    {/* Content */}
                    {ongoingRace.starts.map((starts, i) => (
                        <TableRow key={i}>
                            <TableCell style={{ justifySelf: 'center' }}>
                                <HorseNrBtn>{i + 1}</HorseNrBtn>
                            </TableCell>
                            <TableCell style={{ fontWeight: 900 }}>
                                {starts?.horse?.horseNameAndNationality}
                            </TableCell>
                            <TableCell style={{ fontSize: '13px' }}>
                                {starts?.forecastPossibleValue
                                    ? thousandsDots(starts?.forecastPossibleValue) + ' KR.'
                                    : starts?.scratched
                                    ? 'Udgået'
                                    : '-'}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableContent>
            </Table>
            <ForecastTooltip id={'forecast-tooltip'} backgroundColor={'#333333'}>
                <Text white size={'x07'} width={byPlatform('290px', '320px')} condensed>
                    Denne prognose estimerer den potentielle udbetaling for dit V-spil baseret på
                    aktuelle odds og spilmønstre. Den hjælper dig med at forstå, hvor meget du
                    potentielt kan vinde, hvis dine valgte heste vinder deres løb.
                </Text>
                <Text white size={'x07'} width={byPlatform('290px', '320px')} condensed>
                    Hvis en hest er markeret med ‘*’, betyder det enten, at ingen spillere har
                    gættet alle resultater korrekt, eller at gevinstens værdi overstiger 200.000 kr.
                </Text>
            </ForecastTooltip>
        </>
    );
};

export default Forecast;
