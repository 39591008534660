import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import Button from 'ui/controls/Button';
import Swipeable from 'react-swipeable';

// numVisible - {number} Number of slides visible on screen. Will be ignored if slideWidth is specified
// centering - true|false
// animationDuration - {number} ms
// debug - true|false
// transitionTimingFunction - {string} linear|ease-in|steps(6, end)  @see https://developer.mozilla.org/ru/docs/Web/CSS/transition-timing-function
// slideWidth - {number} Width of a slide in percents. Will has higher priority that numVisible.
// spaceBetweenPx - {number}
// @TODO infinity - true|false

const spaceBetweenPxDefault = 20;

const inactiveSlidesAnimations = {
    pushAside: 'scale3d(0.9, 1, 1)',
    zoomOut: 'scaleY(0.9)',
};

const getSlideWidth = (props) => {
    if (props.slideWidth) {
        return props.slideWidth;
    } else {
        return 100 / props.numVisible;
    }
};

const alignContainer = (props) => {
    let offset;

    if (props.centering) {
        // offset = `${-(
        //     getSlideWidth(props) * props.position +
        //     getSlideWidth(props) / 2
        // )}%`;
        const slideWidth = getSlideWidth(props);

        offset = `${-(slideWidth * props.position - (100 - slideWidth) / 2)}%`;

        return `translateX(${offset})`;
    } else {
        offset = `${-(getSlideWidth(props) * props.position)}%`;

        return `translateX(${offset})`;
    }
};

const Container = styled.div`
    display: flex;
    justify-content: space-between;
    transition: ${(props) =>
        props.animation
            ? `transform ${
                  props.animationDuration
              }ms ${props.transitionTimingFunction || `linear`}`
            : `none`};
    transform: ${alignContainer};
`;

const Slide = styled.div`
    display: inline-block;
    flex: 1 0 calc(${getSlideWidth}% - ${(props) => props.spaceBetweenPx}px);
    margin: 0 ${(props) => props.spaceBetweenPx / 2}px;
    transform: ${(props) =>
        props.active ||
        !props.slidingAnimationComplete ||
        !props.inactiveAnimationDuration
            ? `none`
            : inactiveSlidesAnimations[
                  props.inactiveAnimationEffect || 'zoomOut'
              ]};
    transition: transform ${(props) => props.inactiveAnimationDuration}ms
        ease-in-out;
    filter: ${(props) =>
        props.active || !props.blurInactive ? `none` : `blur(2px)`};
`;

const NUMBER_OF_INITIAL_DUPLICATES = 2;
const FORWARD = 'FORWARD';
const BACK = 'BACK';

class Carousel extends Component {
    constructor(props) {
        super(props);

        const slides = [
            props.children[props.children.length - 1],
            ...props.children,
            props.children[0],
        ];

        this.state = {
            position: 1,
            slides,
            animation: true,
        };

        this.disableAnimationThrottling();
    }

    disableAnimationThrottling() {
        this.throttling = false;
    }

    enableAnimationThrottling() {
        this.throttling = true;
    }

    /**
     * Adds duplicate to the end of array in case of
     * forward sliding OR to the start of array
     * in case of back sliding respectively.
     * Returns new array.
     *
     * @param   {string} direction
     * @returns {Array}            array of new slides
     */
    addDuplicate(direction) {
        const slides = this.state.slides;

        const duplicateIndex =
            direction === FORWARD
                ? NUMBER_OF_INITIAL_DUPLICATES
                : slides.length - NUMBER_OF_INITIAL_DUPLICATES - 1;

        this.props.debug && console.log('Duplicated', duplicateIndex + 1);

        if (direction === FORWARD) {
            return [...slides, slides[duplicateIndex]];
        } else {
            return [slides[duplicateIndex], ...slides];
        }
    }

    /**
     * Removes duplicate that were created
     * before previous animation frame
     *
     * @param {string} direction
     */
    removeDuplicate(direction) {
        const rearranged = [...this.state.slides];

        if (direction === FORWARD) {
            rearranged.splice(0, 1);
        } else {
            rearranged.splice(-1, 1);
        }

        return rearranged;
    }

    switchSlide(direction) {
        if (this.throttling) {
            return;
        }

        this.dump('Before');

        direction === FORWARD ? this.slideForward() : this.slideBack();
    }

    slideForward() {
        this.setState(
            {
                slides: this.addDuplicate(FORWARD),
            },
            () => {
                this.animateSliding(FORWARD, () => {
                    this.dump('After');
                    this.setState({
                        slides: this.removeDuplicate(FORWARD),
                        position: this.state.position - 1,
                        animation: false,
                    });
                });
            }
        );
    }

    slideBack() {
        this.setState(
            {
                slides: this.addDuplicate(BACK),
                position: this.state.position + 1,
                animation: false,
            },
            () => {
                this.animateSliding(BACK, () => {
                    this.setState({ slides: this.removeDuplicate(BACK) }, () =>
                        this.dump('After')
                    );
                });
            }
        );
    }

    animateSliding(direction = FORWARD, callback) {
        const { position } = this.state;
        this.enableAnimationThrottling();
        setTimeout(() => {
            this.setState(
                {
                    position:
                        direction === FORWARD ? position + 1 : position - 1,
                    animation: true,
                },
                () => {
                    setTimeout(() => {
                        callback();
                        this.disableAnimationThrottling();
                    }, this.props.animationDuration);
                }
            );
        }, 0);
    }

    handleSwipe(isForward) {
        if (isForward) {
            this.switchSlide(FORWARD);
        } else {
            this.switchSlide(BACK);
        }
    }

    dump(marker) {
        this.props.debug &&
            console.log(
                marker,
                this.state.slides.map((item, index) => index + 1)
            );
    }

    renderSlides() {
        const slides = this.state.slides.map((item, index) => {
            return (
                <Slide
                    key={index}
                    numVisible={this.props.numVisible}
                    slideWidth={this.props.slideWidth}
                    animationDuration={this.props.animationDuration}
                    inactiveAnimationDuration={
                        this.props.inactiveAnimationDuration
                    }
                    inactiveAnimationEffect={this.props.inactiveAnimationEffect}
                    blurInactive={this.props.blurInactive}
                    active={index === this.state.position}
                    slidingAnimationComplete={!this.state.animation}
                    spaceBetweenPx={
                        this.props.spaceBetweenPx || spaceBetweenPxDefault
                    }
                >
                    {item}
                </Slide>
            );
        });

        return slides;
    }

    render() {
        return (
            <Fragment>
                <Swipeable
                    onSwipingLeft={this.handleSwipe.bind(this, true)}
                    onSwipingRight={this.handleSwipe.bind(this, false)}
                >
                    <Container
                        position={this.state.position}
                        active={this.state.active}
                        animation={this.state.animation}
                        numVisible={this.props.numVisible}
                        centering={this.props.centering}
                        animationDuration={this.props.animationDuration}
                        slideWidth={this.props.slideWidth}
                        transitionTimingFunction={
                            this.props.transitionTimingFunction
                        }
                        numChildren={this.props.children.length}
                    >
                        {this.renderSlides()}
                    </Container>
                </Swipeable>
                {this.props.enableButtons && (
                    <Fragment>
                        <Button
                            style={{ marginTop: '11px' }}
                            size="x1"
                            onClick={this.switchSlide.bind(this, BACK)}
                        >
                            Prev
                        </Button>
                        <Button
                            style={{ marginTop: '11px' }}
                            size="x1"
                            onClick={this.switchSlide.bind(this, FORWARD)}
                        >
                            Next
                        </Button>
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

export default Carousel;
