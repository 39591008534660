import React from 'react';

import SelectionScreen from 'features/EventBoard/components/SelectionScreen';
import { ProductId, RaceDay } from 'features/EventBoard/server/calendar';
import useSideEffects from './hooks/useSideEffects';

export type onRaceDaySelected = (raceDay: RaceDay) => void;
export type onProductSelected = (productId: ProductId) => void;

const EventBoard = () => {
    useSideEffects();

    return <SelectionScreen />;
};

export default React.memo(EventBoard);
