import React, { Component, Fragment } from 'react';
import Text from 'ui/Text';
import ButtonGroup from 'ui/controls/ButtonGroup';
import {
    CheckboxLine,
    Message,
    MessageTitle,
    RateBtn,
    PaymentTypeBtn,
} from 'features/UserArea/Withdraw/styled';
import UserConductor from 'common/conductors/UserConductor';
import { sprintf } from 'sprintf-js';
import NotificationConductor from 'common/conductors/NotificationConductor';
import { byPlatform, forMobile } from 'utils/platforms';
import {
    DIBS_ACCEPT_RETURN_URL,
    DIBS_CANCEL_RETURN_URL,
    DIBS_CLIENT_NAME,
    DIBS_ERROR_URL,
    DIBS_EXCHANGE_DK,
    DIBS_OPERATION_DEPOSIT,
    DIBS_PMNT,
    DIBS_SUCCESS_URL,
} from 'configs/dibs';
import { BACKGROUNDS } from 'themes/';
import { intercomReboot } from 'utils/trackers';
import getTexts from 'utils/localization';

import mobilePay from 'images/icons/mobilepay.svg';
import creditcards from 'images/dk/creditcards.svg';
import { Img } from '@it25syv/25syv-ui';
import { COLORS } from 'themes';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { byWebEnvFlag } from 'utils/env';
import { byWebEnv } from 'utils/env';
import { showModal } from 'common/actions/uiActions';
import { ApplePayBtn } from 'ui/ApplePay';
import ApplePayService, { merchantID } from 'features/UserArea/Deposit/ApplePay/ApplePayService';
import { DEPOSIT_1KR_USERS } from 'configs/main';
import { DK_AUTH_REST_PATH } from 'configs/rest';
import { getUser } from 'common/actions/authActions';
import { inIframe } from 'utils/DOM.ts';
import { getUrl } from 'utils/navigation';

import {
    Toolbar,
    PaymentButtonsContainer,
    PaymentButtonsTitleContainer,
    Input,
    ButtonGroupContainer,
} from 'features/UserArea/Deposit/styled';

import Button from 'ui/controls/Button';

const t = getTexts();

export const AMOUNT_REG_EX = new RegExp(' ' + t.currency, 'g');

let minRate = byWebEnv(100, 1, 100);
const MAX_RATE = 10000;
//const CENTS_RATIO = 100;

let depositAmountApplePay = 0;

class AmountForm extends Component {
    inputDirty = false;
    service = new ApplePayService();

    constructor() {
        super();
        this.state = {
            deposit: '',
            bonuses: '',
            selectedBonus: null,
            paymentFormUrl: '',
            paymentFormHtml: null,
            payType: 'card',
            confirmDisabled: false,
            maxDeposit: null,
        };
        this.inputRef = React.createRef();
        this.isInputValid = false;
        this.formRef = React.createRef();
    }

    componentDidMount() {
        const { enableCloseButton, setBackground, getUser } = this.props;
        enableCloseButton && enableCloseButton(null, COLORS.white, this.closeHandler);

        setBackground && setBackground(`${BACKGROUNDS.white}`);

        UserConductor.getDepositBonuses()
            .then(bonuses => this.setState({ bonuses }))
            .catch(err => {
                NotificationConductor.error(err.toString());
            });

        UserConductor.getDepositLimits().then(depositLimitDto =>
            this.setState({ maxDeposit: depositLimitDto.maxDeposit })
        );

        intercomReboot();

        getUser()
            .then(() => {
                const prodMinimumDeposit = DEPOSIT_1KR_USERS.includes(this.props.user?.id)
                    ? 1
                    : minRate;

                minRate = byWebEnvFlag(prodMinimumDeposit, 100, 100);
            })
            .catch(e => {
                console.log(e);
            });
    }

    componentDidUpdate() {
        depositAmountApplePay = parseInt(this.state.deposit, 10);
    }

    chooseDeposit = deposit => {
        this.setState({ deposit: deposit });
        this.isInputValid = true;
        this.inputDirty = false;
    };

    changeDeposit = e => {
        const deposit = e.target.value.replace(/[^0-9]+/g, '');
        this.isInputValid = deposit >= minRate && deposit <= MAX_RATE;

        if (this.isInputValid) {
            this.inputDirty = true;
        }

        this.setState(
            {
                deposit,
            },
            this.setCaretPosition
        );
    };

    confirm = () => {
        const { user } = this.props;

        this.setState({ confirmDisabled: true });

        if (!user || user?.id === 0) {
            window.parent.location.pathname = '/log-ind';
        } else if (user.isSelfExcluded()) {
            window.parent.dispatchEvent(new CustomEvent('SelfExclusionNote'));
        } else if (parseInt(this.state.deposit) > this.state.maxDeposit) {
            this.setState({ confirmDisabled: false });

            window.parent.dispatchEvent(
                new CustomEvent('DepositLimitExceed', {
                    detail: { maxDeposit: this.state.maxDeposit },
                })
            );
        } else {
            // submit the payment
            if (this.state.payType === 'applePay') {
                if (!depositAmountApplePay) {
                    depositAmountApplePay = minRate;
                }

                let validationURL = '';

                const session = new window.ApplePaySession(3, {
                    currencyCode: 'DKK',
                    countryCode: 'DK',
                    validationURL,
                    total: {
                        label: 'Bet25 deposit',
                        type: 'final',
                        amount: depositAmountApplePay,
                    },

                    merchantIdentifier: `merchant.${merchantID}`,

                    merchantCapabilities: ['supports3DS', 'supportsCredit', 'supportsDebit'],

                    supportedNetworks: ['amex', 'masterCard', 'visa'],
                });

                session.oncancel = () => this.setState({ confirmDisabled: false });

                session.onvalidatemerchant = async e => {
                    console.log(e.validationURL);
                    validationURL = e.validationURL;
                    this.service
                        .get(
                            `${DK_AUTH_REST_PATH}payment/dibs?op=applepayinit&merchant=${merchantID}&name=Derby25`
                        )
                        .then(res => {
                            if (res?.data?.merchantSessionIdentifier) {
                                session.completeMerchantValidation(res?.data);
                            } else {
                                this.setState({ confirmDisabled: false });
                                throw Error(
                                    `Can not get merchant session identifier from server verification endpoint response.`
                                );
                            }
                        });
                };

                session.onpaymentauthorized = e => {
                    let data = e?.payment?.token;

                    const paymentData = encodeURIComponent(JSON.stringify(data));

                    this.service
                        .get(
                            `${DK_AUTH_REST_PATH}payment/dibs?op=start&client=${DIBS_CLIENT_NAME}&exchcode=${DIBS_EXCHANGE_DK}&errorUrl=${DIBS_ERROR_URL}&amount=${
                                depositAmountApplePay * 100
                            }&input_amount=${depositAmountApplePay}&agree=on&acceptReturnUrl=${DIBS_ACCEPT_RETURN_URL}&cancelReturnUrl=${DIBS_CANCEL_RETURN_URL}&payType=applePay&payData=${paymentData}&pmnt=${DIBS_PMNT}`
                        )
                        .then(res => {
                            if (res.url) {
                                session.completePayment({
                                    status: window.ApplePaySession.STATUS_SUCCESS,
                                });

                                window.parent.dispatchEvent(new CustomEvent('ApplePaySuccess'));
                            } else {
                                session.completePayment({
                                    status: window.ApplePaySession.STATUS_FAILURE,
                                });

                                window.parent.dispatchEvent(
                                    new CustomEvent('ApplePayFailure', {
                                        detail: {
                                            error: res.error,
                                        },
                                    })
                                );
                            }

                            this.closeHandler();

                            return res;
                        })
                        .catch(error => {
                            console.log(error);

                            this.setState({ confirmDisabled: false });

                            session.completePayment({
                                status: window.ApplePaySession.STATUS_FAILURE,
                            });

                            window.parent.dispatchEvent(
                                new CustomEvent('ApplePayFailure', {
                                    detail: {
                                        error,
                                    },
                                })
                            );

                            return Promise.reject(
                                'Fejl - prøv igen og kontakt kundesupport hvis problemet fortsætter'
                            );
                        });
                };

                session.begin();
            } else {
                this.formRef.current.submit();
            }

            window.parent.hideCloseButton();
        }
    };

    isApplePayAvailable = () => window.ApplePaySession && window.ApplePaySession.canMakePayments();

    onApplePayClick = () => {
        this.selectPaymentType('applePay');
    };

    handleApplePay = () => {};

    closeHandler = () => {
        window.parent.closeModal();
    };

    setCaretPosition = () => {
        const length = this.state.deposit.length;

        if (this.inputRef.current.selectionStart > length) {
            this.inputRef.current.selectionStart = length;
            this.inputRef.current.selectionEnd = length;
        }
    };

    onFocusHandler = () => {
        const { deposit } = this.state;

        this.setState({ deposit: deposit.replace(AMOUNT_REG_EX, '') });
    };

    onBlurHandler = () => {
        const { deposit } = this.state;
        this.setState({ deposit: deposit + ' ' + t.currency });
    };

    selectBonus = bonusId => {
        if (bonusId === this.state.selectedBonus) {
            return this.setState({ selectedBonus: null });
        }
        this.setState({ selectedBonus: bonusId });
    };

    selectPaymentType = type => {
        this.setState({ payType: type });
    };

    renderBonuses = () => {
        const { bonuses, selectedBonus } = this.state;
        return bonuses.map(bonus => {
            return (
                <Fragment key={Math.random()}>
                    <Message accent>
                        <MessageTitle>
                            {sprintf(
                                t.userArea.deposit.bonusMessageLine1,
                                bonus.percent,
                                '%',
                                bonus.maxAmount,
                                bonus.minAmount
                            )}
                        </MessageTitle>
                        <p>{t.userArea.deposit.bonusMessageLine2}</p>
                    </Message>
                    <CheckboxLine onClick={this.selectBonus.bind(this, bonus.externalId)}>
                        <span className="agreement-text">{t.userArea.deposit.bonusAgreement}</span>
                        <input
                            type="checkbox"
                            checked={selectedBonus === bonus.externalId}
                            onChange={this.selectBonus.bind(this, bonus.externalId)}
                        />
                    </CheckboxLine>
                </Fragment>
            );
        });
    };

    onMobileModalClose = () => {
        if (inIframe()) {
            return (window.top.location.pathname = getUrl('/konto'));
        }

        window.location.pathname = getUrl('/konto');
    };

    render() {
        const { deposit, bonuses, selectedBonus, payType } = this.state;

        const amount = this.state.deposit.replace(AMOUNT_REG_EX, '') || 0;
        intercomReboot();

        return (
            <>
                {forMobile(
                    <Toolbar goBack={this.onMobileModalClose} close={this.onMobileModalClose} />
                )}

                <PaymentButtonsTitleContainer>
                    <Text grey>Vælg betalingsmetode:</Text>
                </PaymentButtonsTitleContainer>

                <PaymentButtonsContainer className="flex justify-evenly">
                    <div className="flex flex-col center-v flex-grow">
                        <PaymentTypeBtn
                            style={{ marginRight: '5px' }}
                            active={payType === 'card'}
                            onClick={() => this.selectPaymentType('card')}
                        >
                            <Img src={creditcards} height={byPlatform(null, '20px')} alt="" />
                        </PaymentTypeBtn>

                        <Text grey>Betalingskort</Text>
                    </div>

                    <div className="flex flex-col center-v flex-grow">
                        <PaymentTypeBtn
                            active={payType === 'mobilePay'}
                            onClick={() => this.selectPaymentType('mobilePay')}
                        >
                            <Img src={mobilePay} alt="MobilePay" />
                        </PaymentTypeBtn>

                        <Text grey>Mobilepay</Text>
                    </div>

                    {this.isApplePayAvailable() && (
                        <div className={`flex flex-col center-v ${byPlatform('', 'flex-grow')}`}>
                            <ApplePayBtn
                                active={payType === 'applePay'}
                                onClick={this.onApplePayClick}
                            />

                            <Text grey>Applepay</Text>
                        </div>
                    )}
                </PaymentButtonsContainer>

                <Input
                    value={deposit}
                    onChange={this.changeDeposit}
                    onFocus={this.onFocusHandler}
                    onBlur={this.onBlurHandler}
                    ref={this.inputRef}
                    placeholder="Indtast beløb"
                    className="text-center"
                />

                <Text className="text-center">
                    {`${t.userArea.deposit.minAmount} ${minRate} Kr.`}
                </Text>

                <ButtonGroupContainer>
                    <ButtonGroup
                        values={[
                            '200 ' + t.currency,
                            '400 ' + t.currency,
                            '600 ' + t.currency,
                            '800 ' + t.currency,
                            '1000 ' + t.currency,
                            '2000 ' + t.currency,
                        ]}
                        renderButton={(value, props) => (
                            <RateBtn {...props} highlightActive={!this.inputDirty}>
                                {value}
                            </RateBtn>
                        )}
                        onSelect={this.chooseDeposit}
                    />
                </ButtonGroupContainer>

                {bonuses.length > 0 && this.renderBonuses()}

                <Button
                    accent
                    size="x2"
                    className="center block-center"
                    onClick={this.confirm}
                    width={'200px'}
                    disabled={!this.isInputValid || this.state.confirmDisabled}
                >
                    {t.userArea.deposit.next}
                </Button>

                <form action={DK_AUTH_REST_PATH + 'payment/dibs'} ref={this.formRef}>
                    <input type="hidden" name="op" value={DIBS_OPERATION_DEPOSIT} />
                    <input type="hidden" name="client" value={DIBS_CLIENT_NAME} />
                    <input type="hidden" name="exchcode" value={DIBS_EXCHANGE_DK} />
                    <input type="hidden" name="acceptReturnUrl" value={DIBS_ACCEPT_RETURN_URL} />
                    <input type="hidden" name="cancelReturnUrl" value={DIBS_CANCEL_RETURN_URL} />
                    <input type="hidden" name="accepturl" value={DIBS_ACCEPT_RETURN_URL} />
                    <input type="hidden" name="callbackurl" value={DIBS_ACCEPT_RETURN_URL} />
                    <input type="hidden" name="successUrl" value={DIBS_SUCCESS_URL} />
                    <input type="hidden" name="errorUrl" value={DIBS_ERROR_URL} />
                    <input type="hidden" id="bonusId" name="bonusId" value={selectedBonus || ''} />
                    <input type="hidden" id="amount" name="amount" value={amount * 100} />
                    <input type="hidden" id="input_amount" name="input_amount" value={amount} />
                    <input type="hidden" id="rnd" name="rnd" value={Math.random()} />
                    <input type="hidden" id="pmnt" name="pmnt" value={DIBS_PMNT} />
                    <input type="hidden" id="pmnt" name="agree" value="on" />
                    <input type="hidden" id="payType" name="payType" value={payType} />
                </form>
            </>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.user,
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            showModal,
            getUser,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AmountForm);
