export default function raceStartsSelector(state) {
    const raceNumber = state.AISDataProvider.selectedRaceNumber;
    if (!state.AISDataProvider.racingCardData.races.length) {
        return [];
    }
    const race = state.AISDataProvider.racingCardData.races.find((race) => {
        return race.raceNumber === raceNumber;
    });
    return race ? race.starts : [];
}

export function excludeScratched(state) {
    return raceStartsSelector(state).filter((s) => !s.scratched);
}

export const getIds = (starts) => starts.map((s) => s.startNr);
