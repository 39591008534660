import React from 'react';
import PropTypes from 'prop-types';
import Bet from 'common/DataObjects/Bet';
import BetPickRow from './BetPickRow';
import BetPickTrio from './BetPickTrio';
import UHorsePick from './UHorsePick';
import { PRODUCT_IDS } from 'configs/products';
import Product from 'common/DataObjects/Product';
import Betslip from 'common/DataObjects/Betslip';
import EmptyBetPickRow from 'features/BetSlip/components/BetPick/EmptyBetPickRow';
import { getHorseCountsInRaces } from 'common/selectors/racesSelector';
import { useSelector } from 'react-redux';

const BetPick = (props) => {
    const {
        product,
        races,
        race,
        bet,
        betSlip,
        date,
        trackCode,
        isMultitrackProduct,
        reserves,
        placeholders = false,
        renderItem,
        ignoreHorseCounts,
    } = props;

    const horseCounts = useSelector(getHorseCountsInRaces);

    const isTwin = product.id === PRODUCT_IDS.TV;

    const renderMultilegPickedStarts = () => {
        return races.map((race, raceIndex) => {
            const betSlipInput = {
                date,
                trackCode,
                productId: product.id,
                raceIndex: race.index,
                // horse counts are not useful in some cases, where AISDataProvider is not used, e.g. Spilklub coupons
                horseCounts: ignoreHorseCounts ? undefined : horseCounts,
            };

            const rowLabel = isMultitrackProduct
                ? `${race.hostTrack?.code} / ${raceIndex + 1}`
                : product.isVProduct()
                ? raceIndex + 1
                : race.raceNumber;

            if (betSlip.hasCombinations(betSlipInput)) {
                const betTable = betSlip.getRaceSelection(betSlipInput);
                const bet = new Bet(betTable);
                const rowReserves = isMultitrackProduct
                    ? reserves[raceIndex]
                    : reserves[race.index];

                return (
                    <BetPickRow
                        key={`${trackCode}${raceIndex}`}
                        rowLabel={rowLabel}
                        bet={bet}
                        isMultitrackProduct={isMultitrackProduct}
                        pickedStarts={retrievePickedStarts(race.starts, bet)}
                        reserves={rowReserves}
                        product={product}
                        race={race}
                        placeholders={placeholders}
                        renderItem={renderItem}
                    />
                );
            }
            return placeholders ? (
                <EmptyBetPickRow
                    key={raceIndex}
                    raceIndex={raceIndex}
                    isMultitrackProduct={isMultitrackProduct}
                    race={race}
                    product={product}
                />
            ) : null;
        });
    };

    const renderSingleRowView = () => (
        <BetPickRow
            rowLabel={product.isTvilling() ? '1./2' : race.raceNumber}
            bet={bet}
            pickedStarts={excludeUHorses(retrievePickedStarts(race.starts, bet))}
            product={product}
            placeholders={placeholders}
        />
    );

    const retrievePickedStarts = (starts, bet) => {
        return starts.filter((start) => {
            return bet.isStartMarked(start.startNr);
        });
    };

    const retrievePickedStartsKomb = (starts, bet, place) => {
        return starts.filter((start) => {
            return bet.satisfies(start.startNr, place);
        });
    };

    const excludeUHorses = (starts) => {
        if (!isTwin) {
            return starts;
        }

        return starts.filter((start) => {
            return (
                bet.getPickedStartNumbers().indexOf(start.startNr) !== -1 &&
                bet.betTable[start.startNr].indexOf(start.startNr) !== -1
            );
        });
    };

    if (product.isVProduct()) {
        return renderMultilegPickedStarts();
    }

    switch (product.id) {
        case PRODUCT_IDS.T:
            return (
                <BetPickTrio
                    bet={bet}
                    pickedStarts={retrievePickedStarts(race.starts, bet)}
                    placeholders={placeholders}
                />
            );
        case PRODUCT_IDS.TV:
            return [renderSingleRowView(), <UHorsePick race={race} bet={bet} />];
        case PRODUCT_IDS.K:
            return [
                <BetPickRow
                    key={1}
                    rowLabel={1}
                    bet={bet}
                    pickedStarts={retrievePickedStartsKomb(race.starts, bet, 1)}
                    product={product}
                    placeholders={placeholders}
                />,
                <BetPickRow
                    key={2}
                    rowLabel={2}
                    bet={bet}
                    pickedStarts={retrievePickedStartsKomb(race.starts, bet, 2)}
                    product={product}
                />,
            ];
        default:
            return renderSingleRowView();
    }
};

BetPick.propTypes = {
    product: PropTypes.instanceOf(Product).isRequired,
    races: PropTypes.array.isRequired,
    race: PropTypes.any,
    bet: PropTypes.instanceOf(Bet).isRequired,
    betSlip: PropTypes.instanceOf(Betslip).isRequired,
    date: PropTypes.string.isRequired,
    trackCode: PropTypes.string,
    isMultitrackProduct: PropTypes.bool,
    reserves: PropTypes.any,
    combinationsCount: PropTypes.number,
    placeholders: PropTypes.bool,
    useSeparateMultitrackCodesForBetslip: PropTypes.bool,
    renderItem: PropTypes.func,
    ignoreHorseCounts: PropTypes.bool,
};

export default BetPick;
