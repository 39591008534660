import { useQuery } from '@tanstack/react-query';
import { request } from 'utils/server';
import { Amount } from '../../TrackPage/server/pool';
import { Label } from '../server/calendar';

export interface PoolInformationItem {
    danish: boolean;
    jackpot: Amount;
    product: Label;
    programNumber: number;
    startDate: string;
    track: Label;
    trackId: number;
    turnover: Amount;
}

interface UseAllPoolInformationQueryProps {
    enabled?: boolean;
}

const fetchAllPoolInformation = async () => {
    const response = await request<PoolInformationItem[]>(
        'InfoService',
        'GET',
        '/fetchAllPoolInformation'
    );

    if (!response.success) {
        throw new Error(response.errorMessage);
    }

    return response.data;
};

const useCalendarQuery = ({ enabled = true }: UseAllPoolInformationQueryProps = {}) => {
    const { status, data, error, isSuccess, isLoading, refetch } = useQuery<
        unknown,
        Error,
        PoolInformationItem[]
    >({
        queryKey: ['allPoolInformation'],
        queryFn: () => fetchAllPoolInformation(),
        enabled,
        staleTime: 60 * 5 * 1000,
        refetchOnReconnect: true,
    });

    return {
        status,
        data: data ?? [],
        error,
        isSuccess,
        isLoading,
        refetchCalendar: refetch,
    };
};

export default useCalendarQuery;
