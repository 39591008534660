import React from 'react';
import styled from 'styled-components';
import infoIcon from 'images/icons/exclamation_mark.svg';

const Wrapper = styled.section`
    height: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #edf1f8;
    border-radius: 5px;
    padding: 15px 30px;
    margin: 0;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 14px;
    img {
        margin-right: 10px;
    }
`;
const InfoMessage = ({ text }) => {
    return (
        <Wrapper>
            <img src={infoIcon} alt="icon" />
            {text}
        </Wrapper>
    );
};

export default InfoMessage;
