import React, { useEffect, useRef } from 'react';
import useUnwrappedModals from 'common/hooks/useUnwrappedModals';
import { MODALS } from '../config';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

import Screens from './Screens';
import useScreens from '../hooks/useScreens';

export interface BetBuddyEntryProps {
    sharedLink?: boolean;
}

const BetBuddyEntry = () => {
    const { isShown, hide, getData } = useUnwrappedModals();
    const rootModalShown = isShown(MODALS.BET_BUDDY_ENTRY);
    const rootModalData = getData(MODALS.BET_BUDDY_ENTRY);

    const modalRef = useRef<HTMLDivElement>(null);

    const [displayScreen, closeScreen, screenShown, screens, closeAll] = useScreens();

    useEffect(() => {
        if (rootModalShown) {
            displayScreen(rootModalData.showScreens);
        } else {
            closeAll();
        }
    }, [rootModalShown, rootModalData.showScreens]);

    return (
        <Screens
            screens={screens}
            closeScreen={closeScreen}
            closeAll={closeAll}
            displayScreen={displayScreen}
            screenShown={screenShown}
            onRootScreenClosed={() => {
                hide(MODALS.BET_BUDDY_ENTRY);
                enableBodyScroll(modalRef.current as HTMLElement);
            }}
            onRootScreenOpen={() => disableBodyScroll(modalRef.current as HTMLElement)}
        />
    );
};

export default BetBuddyEntry;
