import { useQuery } from '@tanstack/react-query';
import CMSApi from 'common/api/CMSAPI';

interface UseHighlightsQueryProps {
    area?: string;
    platform?: string;
    enabled?: boolean;
}

interface QueryParameters {
    area: string;
    platform: string;
}

const fetchHighlights = async ({ area, platform }: QueryParameters) => {
    return CMSApi.getHighlights(area, platform);
};

const initialData = {
    data: [],
    msg: '',
    server: [],
    success: false,
};

const useHighlightsQuery = ({
    area = 'login',
    platform = 'web',
    enabled = true,
}: UseHighlightsQueryProps) => {
    const { status, data, error, isSuccess, isLoading, refetch } = useQuery<unknown, Error>({
        queryKey: ['highlights'],
        queryFn: () => fetchHighlights({ area, platform }),
        staleTime: 60 * 10 * 1000,
        enabled,
    });

    return { status, highlights: data ?? initialData, error, isSuccess, isLoading, refetch };
};

export default useHighlightsQuery;
