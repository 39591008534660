import { connect } from 'react-redux';
import BlobPdf from './BlobPdf.jsx';
import CMSPdf from './CMSPdf.jsx';

const getSelectedDayData = AISProvider => {
    let data = { trackId: null, trackCode: null, date: null };
    let selectedRaceDay = AISProvider.selectedRaceDay;

    if (AISProvider) {
        let racingCard = AISProvider.racingCardData;
        selectedRaceDay = AISProvider.tracks && AISProvider.tracks.byCode[racingCard.trackCode];

        data.trackId = selectedRaceDay ? selectedRaceDay.id : null;
        data.date = racingCard.date;
        data.trackCode = racingCard.trackCode;
    }

    return data;
};

const mapStateToProps = state => ({
    AISDataProvider: state.AISDataProvider,
});

const mapDispatchToProps = dispatch => {
    return {
        getSelectedDayData: getSelectedDayData,
    };
};

const BlobPdfConnect = connect(mapStateToProps, mapDispatchToProps)(BlobPdf);

let CMSPdfConnect = connect(mapStateToProps, mapDispatchToProps)(CMSPdf);

export { BlobPdfConnect as BlobPdf, CMSPdfConnect as CMSPdf };
