import React from 'react';
import styled, { css } from 'styled-components';
import Text from 'ui/Text';
import { BACKGROUNDS, COLORS } from 'themes';
import { fadeInMixin } from 'ui/animations/FadeIn2';

export const CommonInputStyle = css`
    border: none !important;
    background: none;
    padding: 0px 15px;
    font-size: 14px;
    color: ${COLORS.primaryText};
    font-weight: 800;
    text-align: ${(props) => (!props.align ? 'left' : props.align)};
    &::placeholder {
        color: ${({ placeholderTextColor }) => placeholderTextColor || COLORS.placeholderText};
        opacity: 1;
        transition: opacity 0.3s ease-in-out;
        &:focus {
            opacity: 1;
            transition: opacity 0.3s ease-in-out;
        }
    }
    &:focus::placeholder {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        // -webkit-box-shadow: 0 0 0 30px #eceff5 inset !important; //@TODO use it to disable autocomplete input background
        -webkit-text-fill-color: ${COLORS.primaryText};
    }
`;

const InputControl = styled.input.attrs({
    autoComplete: 'off',
})`
    ${CommonInputStyle};
`;

const TextAreaControl = styled.textarea`
    ${CommonInputStyle};
    resize: none;
`;

const InputWrapper = styled.div`
    position: relative;
    width: ${({ width }) => (width ? width : 'auto')};
    height: ${(props) => (!props.height ? '30px' : props.height)};
    margin: ${(props) => (!props.margin ? '10px' : props.margin)};
    background: ${BACKGROUNDS.lightGrey};
    color: ${COLORS.primaryText};
    border-radius: ${(props) => (!props.radius ? '25px' : props.radius)};
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: ${({ padding = '10px' }) => padding};
    text-align: ${(props) => (!props.align ? 'left' : props.align)};
    border: ${(props) =>
        props.validationError && props.redBorder ? `1px solid ${COLORS.red}` : 'none'};
`;

const InputSubLabel = styled.small`
    font-size: 10px;
    color: ${(props) => (props.color ? props.color : COLORS.primaryText)};
    padding: 0px 15px 5px;
    text-align: ${(props) => (!props.align ? 'left' : props.align)};
    position: absolute;
    bottom: 0;
    display: inline-block;
    width: ${({ padding = '10px', margin = '10px' }) =>
        `calc(100% - (${padding} * 2) - (${margin} * 2))`};
`;

export const ErrorUnderInput = styled(Text).attrs({ color: 'red' })`
    ${fadeInMixin};
    font-size: 12px;
    margin: ${(props) => (!props.margin ? '0 10px' : props.margin)};
    padding: ${({ padding = '0 10px' }) => padding};
`;

const InputTopLabel = styled(InputSubLabel)`
    color: ${COLORS.primaryText};
    top: 7px;
    font-weight: 900;
    width: auto;
    height: 10px;
    padding-bottom: 0;
`;

const ShowPasswordButton = styled.span`
    color: ${COLORS.primaryText};
    cursor: pointer;
    font-size: 14px;
    position: absolute;
    right: 20px;
`;

class Input extends React.Component {
    constructor() {
        super();

        this.state = {
            subLabel: true,
            passwordShown: false,
        };
    }

    onInputFocused = () => {
        if (this.props.value === '') {
            this.setState({ subLabel: false });
        }

        if (this.props.onInputFocusedHandler) {
            this.props.onInputFocusedHandler();
        }
    };

    onInputBluered = () => {
        if (this.props.value === '') {
            this.setState({ subLabel: true });
        }

        if (this.props.inputBlueredHandler) {
            this.props.inputBlueredHandler();
        }
    };

    onInputValueChanged = (event) => {
        event.stopPropagation();
        const value = event.target.value;

        this.props.onChange && this.props.onChange(value);
    };

    renderShowPasswordButton = () => (
        <ShowPasswordButton
            onClick={() => this.setState({ passwordShown: !this.state.passwordShown })}
        >
            {this.state.passwordShown ? 'Skjul' : 'Vis'}
        </ShowPasswordButton>
    );

    renderSubLabel = () => {
        return !this.props.validationError ? (
            this.state.subLabel && this.props.sublabel ? (
                <InputSubLabel align={this.props.align}>{this.props.sublabel}</InputSubLabel>
            ) : null
        ) : (
            !this.props.showErrorUnderInput && (
                <InputSubLabel
                    color="red"
                    align={this.props.align}
                    inputPadding={this.props.padding}
                >
                    {this.props.validationError}
                </InputSubLabel>
            )
        );
    };

    render() {
        const inputProps = {
            ...this.props,
            // value: this.props.value,
            ref: this.props.inputRef,
            onChange: this.onInputValueChanged,
            onFocus: this.onInputFocused,
            onBlur: this.onInputBluered,
            placeholder: this.props.placeholder,
            align: this.props.align,
            type:
                this.props.type === 'password' && this.state.passwordShown
                    ? 'text'
                    : this.props.type,
        };

        const { value, topLabel, validationError } = this.props;

        const input =
            this.props.type === 'textarea' ? (
                <TextAreaControl
                    {...inputProps}
                    ref={this.props.inputRef ? this.props.inputRef : undefined}
                />
            ) : (
                <InputControl
                    {...inputProps}
                    ref={this.props.inputRef ? this.props.inputRef : undefined}
                />
            );

        const InputWrapperComponent = this.props.InputWrapperComponent || InputWrapper;

        return (
            <>
                <InputWrapperComponent {...this.props}>
                    {value && topLabel && !validationError && (
                        <InputTopLabel>{topLabel}</InputTopLabel>
                    )}
                    {input} {this.props.type === 'password' && this.renderShowPasswordButton()}
                    {this.renderSubLabel()}
                </InputWrapperComponent>
                {this.props.validationError && this.props.showErrorUnderInput && (
                    <ErrorUnderInput
                        align={this.props.align}
                        margin={this.props.margin}
                        padding={this.props.padding}
                    >
                        {this.props.validationError}
                    </ErrorUnderInput>
                )}
            </>
        );
    }
}

export default Input;
export { Input, InputWrapper, InputControl, InputSubLabel };
