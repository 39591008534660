class Scanner {
    scanningIsDone = (event, state) => {
        return event.keyCode === 13 && state.hashCode !== '';
    };

    getScannedChar = (event) => {
        return String.fromCharCode(event.which).trim();
    };

    getPrefixFromScannedHash = (hash) => {
        return typeof hash === 'string' && hash[0] ? hash[0] : '';
    };

    isVoucherScanned = (hash) => {
        return this.getPrefixFromScannedHash(hash) === 'V';
    };

    isSystemCouponScanned = (hash) => {
        return this.getPrefixFromScannedHash(hash) === 'S';
    };

    isCouponScanned = (hash) => {
        const hashPrefix = this.getPrefixFromScannedHash(hash);

        return ['T', 'D', 'C', 'W'].includes(hashPrefix);
    };

    isPlayAndPayCouponScanned = (hash) => {
        return this.getPrefixFromScannedHash(hash) === 'U';
    };

    isMobileBetHash = (hash) => {
        const id = parseInt(hash);

        return Number.isInteger(id) && Math.sign(id) === 1;
    };
}

export default new Scanner();
