import React, { ReactNode } from 'react';
import Button from './controls/Button';
import getTexts from 'utils/localization';
import Mark from './Mark';
const t = getTexts();

interface InlineErrorProps {
    children: ReactNode;
}

const InlineError = ({ children }: InlineErrorProps) => {
    const startChat = () => {
        window.Intercom('show');
    };

    return (
        // @ts-ignore
        <Mark grey padding={'25px'}>
            <strong>{children}</strong>

            <p>{t.support.contactSupport}</p>
            <Button accent size="x2" rounded width="200px" onClick={startChat}>
                {t.support.ctaBtn}
            </Button>
        </Mark>
    );
};

export default InlineError;
