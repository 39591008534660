import React, { Fragment } from 'react';
import Text from 'ui/Text';
import { byPlatform, isTerminal } from 'utils/platforms';
import { COLORS } from 'themes';

export const Jockey = ({ start }) => (
    <Text
        weight={900}
        color={byPlatform(COLORS.primaryText, COLORS.primaryText, COLORS.black)}
        short={true}
        padding="0px"
        size={byPlatform(12, 'x05', 16)}
        className={'capitalize'}
    >
        {start.driver.name ? start.driver.name : '--'}{' '}
        {start.driver.surName ? start.driver.surName : '--'}
    </Text>
);

const HorseWithJockey = (props) => (
    <Fragment>
        <Text
            weight={900}
            color={COLORS.black}
            short={true}
            strike={props.start.isScratched()}
            padding="0px"
            style={{ fontSize: '16px' }}
            className={'capitalize'}
        >
            {props.showStartNr && `${props.start.startNr}.`}{' '}
            {props.start.horse.horseNameAndNationality
                ? props.start.horse.horseNameAndNationality.toLowerCase()
                : '--'}
        </Text>
        {!isTerminal && <Jockey start={props.start} />}
    </Fragment>
);

export default HorseWithJockey;
