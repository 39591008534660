import axios from 'axios';
import qs from 'qs';
import omit from 'lodash/omit';
import {
    AIS_REST_PATH,
    BET_BUDDY_REST_PATH,
    BETTING_HISTORY_REST_PATH,
    DK_AUTH_REST_PATH,
    DK_RESULT_REST_PATH,
} from 'configs/rest';
import { trimSlashes } from 'utils/navigation';
import { getOrigin } from '../common/api/CMSAPI/RequestHandler';

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

type Module = 'InfoService' | 'History' | 'Spilklub' | 'CMS' | 'Results' | 'Punter';

interface ModuleConfig {
    headers: any;
    axiosParameters: any;
    baseUrl: string;
}

type ModuleMap = { [M in Module]: ModuleConfig };

const moduleSettings: ModuleMap = {
    InfoService: {
        headers: { 'content-type': 'application/x-www-form-urlencoded', 'X-API-Version': 2 },
        axiosParameters: {},
        baseUrl: AIS_REST_PATH,
    },
    History: {
        headers: {
            'Content-Type': 'application/json',
            'X-API-Version': 2,
        },
        axiosParameters: { withCredentials: true },
        baseUrl: BETTING_HISTORY_REST_PATH,
    },
    Spilklub: {
        headers: {},
        axiosParameters: { withCredentials: true },
        baseUrl: BET_BUDDY_REST_PATH,
    },
    Results: {
        headers: {},
        axiosParameters: {},
        baseUrl: DK_RESULT_REST_PATH,
    },
    CMS: {
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        axiosParameters: {},
        baseUrl: getOrigin(),
    },
    Punter: {
        headers: {},
        axiosParameters: { withCredentials: true },
        baseUrl: DK_AUTH_REST_PATH,
    },
};

interface Response<D> {
    success: boolean;
    errorMessage?: string;
    data: D;
}

export function request<D, P = {}>(
    module: Module,
    method: HttpMethod,
    url: string,
    params?: P
): Promise<Response<D>> {
    const moduleConfig = moduleSettings[module];
    const baseUrl = moduleConfig.baseUrl;

    const options = omit(
        {
            method,
            headers: moduleConfig.headers,
            data: qs.stringify(params), // body params
            url: baseUrl + (baseUrl.endsWith('/') ? trimSlashes(url) : url),
            params: params, // query string params
            ...(moduleConfig.axiosParameters || {}),
        },
        // use data (body) or params (query) accordingly to the method
        ['GET', 'DELETE'].includes(method) ? ['data'] : ['params']
    );

    return axios(options).then(res => res.data);
}
