import * as StyledPin from './styled';
import React from 'react';
import { FadeInSpan } from '../../../ui/animations/FadeIn2';

const Bullets = ({
    pinLength = 4,
    processingLength,
    BulletComponent = StyledPin.Bullet,
    complete,
}) => {
    const range = Array.from(Array(pinLength).keys());
    return range.map((index) =>
        complete ? (
            <FadeInSpan key={index}>
                <StyledPin.Bullet size="64px" processing className="active fade-in" />
            </FadeInSpan>
        ) : (
            <BulletComponent
                key={index}
                size="64px"
                processing={index === processingLength}
                className={index <= processingLength - 1 ? 'active' : ''}
            />
        )
    );
};

export default Bullets;
