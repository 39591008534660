import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ModalProps } from 'ui/types';
import Popup from 'ui/Popup';
import Player from './Player';
import useModals from '../../common/hooks/useModals';
import Icon from '../Icon';

interface PlayerPopupModalData {
    videoId: string;
    videoTitle: string;
    videoDescription?: string;
}

interface PlayerPopupProps extends ModalProps<PlayerPopupModalData> {}

const CloseIcon = styled(Icon).attrs({ iclose: true, color: 'white' })`
    width: 10px;
    height: 10px;
`;

const TextWrapper = styled.div`
    background: #fff;
    padding: 10px 30px;
`;

const VideoTitle = styled.h3`
    font-size: 20px;
    font-weight: 900;
`;

const VideoDescription = styled.p`
    font-size: 14px;
`;

const PlayerPopup = ({ modalData, setBackground }: PlayerPopupProps) => {
    useEffect(() => setBackground && setBackground('none'), []);

    const { videoTitle, videoDescription } = modalData;

    const { hide } = useModals();

    return (
        <Popup
            onClose={() => hide('YOUTUBE_PLAYER_POPUP_2')}
            CloseIconComponent={CloseIcon}
            // @ts-ignore
            CloseButtonComponent={null}
            styles={{
                PopupWrapper: {
                    width: '750px',
                    height: 'auto',
                    borderRadius: 0,
                },
            }}
        >
            <Player
                title={videoTitle}
                videoId={modalData.videoId}
                width={'750'}
                height={'422'}
                allowFullScreen={true}
            />
            {(videoTitle || videoDescription) && (
                <TextWrapper>
                    {videoTitle && <VideoTitle>{videoTitle}</VideoTitle>}
                    {videoDescription && <VideoDescription>{videoDescription}</VideoDescription>}
                </TextWrapper>
            )}
        </Popup>
    );
};

export default PlayerPopup;
