import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, CardHeader } from 'ui/Card';
import { Timer, RydCouponButton } from 'features/BetSlip/styled';
import Countdown from 'ui/Countdown';
import { Row, Col, Container } from 'react-grid-system';
import Icon from 'ui/Icon';
import SubHeader from './SubHeader';
import { COLORS } from 'themes';
import Product from 'common/DataObjects/Product';
import Race from 'common/DataObjects/Race';
import getTexts from 'utils/localization';
import { useDispatch } from 'react-redux';
import deleteIcon from 'features/RaceCard/assets/delete_derby.png';
import { Img } from '@it25syv/25syv-ui';

const t = getTexts();

//@TODO After merging the SmartLyn replace the original BetSlipCard with this one
const BetSlipCard2 = (props) => {
    const dispatch = useDispatch();
    const {
        combinedTrackName,
        product,
        race,
        serverTime,
        date,
        styles = { Card: {} },
        children,
    } = props;

    return !product || (!race && !product.isVProduct()) ? null : (
        <Card
            style={{
                ...styles.Card,
            }}
        >
            <CardHeader>
                <Container>
                    <Row>
                        <Col
                            xs={5}
                            style={{
                                paddingRight: 0,
                                paddingTop: '5px',
                                paddingLeft: '5px',
                            }}
                        >
                            <Icon itrack size="x05" color="white" />{' '}
                            <span className="text-bold uppercase">
                                {!product.isVProduct() && `${t.betSlip.track} ${race.raceNumber}, `}{' '}
                                {combinedTrackName}
                            </span>
                        </Col>
                        <Col xs={7} align="right" style={{ paddingLeft: 0, paddingRight: '5px' }}>
                            {!props.withoutDeleteButton && (
                                <RydCouponButton onClick={() => dispatch({ type: 'RESET_BET' })}>
                                    <Img
                                        style={{
                                            marginRight: '4px',
                                            height: '11px',
                                            width: '8px',
                                        }}
                                        src={deleteIcon}
                                    />
                                    RYD
                                </RydCouponButton>
                            )}
                            <Icon
                                itime
                                color="white"
                                size="x05"
                                style={{
                                    verticalAlign: 'sub',
                                    marginRight: '5px',
                                }}
                            />{' '}
                            {race && (
                                <Timer>
                                    <Countdown
                                        time={date + ' ' + race.postTime}
                                        timeUTC={date + ' ' + race.postTimeUTC}
                                        serverTime={serverTime}
                                        withDay
                                    />
                                </Timer>
                            )}
                        </Col>
                    </Row>
                </Container>
            </CardHeader>
            <SubHeader product={product} />
            <CardContent style={{ background: COLORS.white, padding: '0px 15px' }}>
                {children}
            </CardContent>
        </Card>
    );
};

BetSlipCard2.propTypes = {
    combinedTrackName: PropTypes.string,
    product: PropTypes.instanceOf(Product),
    race: PropTypes.instanceOf(Race),
    date: PropTypes.string,
    serverTime: PropTypes.string,
    styles: PropTypes.object,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default BetSlipCard2;
