import ui from './ui';
import auth from './auth';
import { combineReducers } from 'redux';
import { FeaturesReducer } from '../../configs/reducers';
import { StateType } from 'typesafe-actions';

export type RootState = StateType<ReturnType<typeof rootReducer>>;

const rootReducer = combineReducers({
    ui,
    auth,
    ...FeaturesReducer,
});

export default rootReducer;
