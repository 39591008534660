import { Col, Row } from 'react-grid-system';
import Text from 'ui/Text';
import React from 'react';
import getTexts from 'utils/localization';
import moment from 'moment';

const t = getTexts();

export const getMappedData = coupon => {
    return {
        ...coupon,
        trackInfo: coupon.trackInfo || coupon.track_info,
        couponId: coupon.couponId || coupon.coupon_id,
        couponInfo: coupon.couponInfo || coupon.coupon_info,
        resultInfo: coupon.resultInfo || coupon.result_info,
        winAmount: coupon.winAmount || coupon.win_amount,
        sessionId: coupon.sessionId || coupon.session_id,
        createdAt: coupon.createdAt || coupon.created_at,
        expiryTime: coupon.expiryTime || coupon.expiry_time,
        paidoutAt: coupon.paidoutAt || coupon.paidout_at,
        payoutId: coupon.payoutId || coupon.payout_id,
        saleAmount: coupon.saleAmount || coupon.sale_amount,
        saleId: coupon.saleId || coupon.sale_id,
        shopId: coupon.shopId || coupon.shop_id,
        trackCode: coupon.trackCode || coupon.track_code,
        transId: coupon.transId || coupon.trans_id,
        isTele: coupon.isTele || coupon.is_tele,
        isTest: coupon.isTest || coupon.is_test,
        cashoutType: coupon.cashoutType || coupon.cashout_type,
        slipData: coupon.slipData || coupon.slip_data,
    };
};

// eslint-disable-next-line unused-imports/no-unused-vars
export const getPositions = (slipData, status = '', isSystemBet = false) => {
    return slipData.map((item, index) => {
        const competitionLabel = [item.sport, item.competition].filter(item => item).join('/');

        const winnerTeam = ['1', '2'].includes(item.bet)
            ? item.eventName.split(' - ')[Number(item.bet) - 1]
            : item.bet && item.bet.toLowerCase() === 'x'
            ? 'Uafgjort'
            : item.bet;

        return (
            <Row key={index}>
                <Col
                    xs={6}
                    style={{
                        margin: '0px',
                        padding: '0px',
                    }}
                >
                    <Text bold={600} size="x1" padding="10px 10px 0">
                        {item.marketTypeName || item.marketName}
                        {' - '}
                        {winnerTeam}
                    </Text>
                    <Text bold={400} size="x1" secondary padding="0 10px 10px">
                        {item.eventName}
                        <br />
                        {competitionLabel}
                    </Text>
                </Col>
                <Col
                    xs={6}
                    style={{
                        margin: '0px',
                        padding: '0px',
                    }}
                >
                    <Text
                        bold={400}
                        size="x1"
                        align="right"
                        secondary
                        padding={isSystemBet ? '5px' : '10px'}
                        uppercase
                    >
                        {t.odds}: {item.odds}
                    </Text>
                </Col>
            </Row>
        );
    });
};

export const getFormattedCouponDate = createdAt => moment(createdAt).format('DD/MM/YY HH:MM');
