import React from 'react';
import FlexWrapper from 'ui/FlexWrapper';
import Wrapper from 'ui/Wrapper';
import Divider from 'ui/Divider';
import Text from 'ui/Text';
import ShoeInfo from 'features/TrackPage/components/RaceCard/Desktop/ShoeInfo.jsx';
import { thousandsDots } from 'utils/formatters';

import getTexts from 'utils/localization';
import { ShoeCol } from '../Desktop/styled';
const t = getTexts();

export function getStyledTextComponent(label, value, width) {
    return (
        <Wrapper width={width} padding="0px">
            <Text short={true} align="left" size="x05" padding="5px 5px 5px 0px">
                <strong style={{ fontWeight: 900 }}>{label ? label + ':' : ''}</strong> {value}
            </Text>
        </Wrapper>
    );
}

export const getCoach = start =>
    start && start.trainer && start.trainer.name && start.trainer.surName
        ? start.trainer.name + ' ' + start.trainer.surName
        : 'n/a';

export const getWeight = start =>
    start?.gallopStartInfo && start?.gallopStartInfo?.weight
        ? start.gallopStartInfo?.weight?.cardWeight
        : 'n/a';

export const getMoney = start =>
    start?.horseStat && start.horseStat.total?.earning?.sum
        ? thousandsDots(start.horseStat.total?.earning?.sum) +
          ' ' +
          start.horseStat.total?.earning?.currency
        : 'n/a';

export const getKRStart = start =>
    start.horseStat
        ? !start.horseStat.total?.earning?.sum || !start.horseStat.total?.nrOfStarts
            ? 0
            : thousandsDots(
                  (start.horseStat.total.earning.sum / start.horseStat.total.nrOfStarts).toFixed(0)
              )
        : 'n/a';

export const getStarter = start => {
    const stat = start.horseStat;

    if (!stat?.total) {
        return '-';
    }

    return stat
        ? stat.total?.nrOfStarts +
              ' ' +
              stat.total?.first +
              '-' +
              stat.total?.second +
              '-' +
              stat.total?.third
        : 'n/a';
};

export const getFormtal = start => start.gallopStartInfo?.rating || '-';

const getRecordValue = record => {
    return record.distance / parseFloat(record.time.substring(4, 8).replace(':', '.'));
};

/**
 * a = autostart (you also have "l" = linestart that happened some times a
 * year when the car with the wings is broken or if the track is loose
 * that the wheels just spin. for example.
 * If its only "M" or "K" the record is in volstart.
 *
 * M = medelrace 2140 meters,
 * K = kort 1609 or 1640 meters,
 * L = lång 2640 meters or longer
 *
 * The record time shown is the second and tenth of the time object. The minute figure is not shown.
 *
 * The record shown is the best record for the starting method to be used
 * in the race, regardless of the distance in the race.
 *
 * example result:
 * 13,7aM
 *
 * @return {string}
 */
export const getRecord = start => {
    const records = start.trotStartInfo.records.records;

    if (records.length === 0) {
        return '-';
    }

    const record = records.reduce((accumulatorRec, currentRec) => {
        return getRecordValue(currentRec) > getRecordValue(accumulatorRec)
            ? currentRec
            : accumulatorRec;
    }, records[0]);

    const time = record.time.substring(6, 11).replace('.', ',');
    const recordCode = record.recType.code.replace('A', 'a');

    return `${time}${recordCode}`;
};

const ShortStatistic = props => (
    <Wrapper padding="0px">
        <Divider grey margin="0px 0px 5px" />

        <FlexWrapper
            height="20px"
            width="auto"
            direction="row"
            align="space-between"
            padding="0px 15px"
        >
            {getStyledTextComponent(t.ShortStatistic.coach, getCoach(props.start), '40%')}
            {!props.gallop
                ? getStyledTextComponent(t.ShortStatistic.record, getRecord(props.start), '30%')
                : getStyledTextComponent(t.ShortStatistic.weight, getWeight(props.start), '30%')}
            {!props.gallop
                ? getStyledTextComponent(
                      t.ShortStatistic.shoe,
                      <ShoeCol>
                          <ShoeInfo
                              frontShoe={props.shoeInfo ? props.shoeInfo.front : null}
                              backShoe={props.shoeInfo ? props.shoeInfo.back : null}
                          />
                      </ShoeCol>,
                      '30%'
                  )
                : getStyledTextComponent('Formtal', getFormtal(props.start), '30%')}
        </FlexWrapper>

        <FlexWrapper
            height="20px"
            width="auto"
            direction="row"
            align="space-between"
            padding="0px 15px"
        >
            {getStyledTextComponent(t.ShortStatistic.money, getMoney(props.start), '40%')}
            {getStyledTextComponent(t.ShortStatistic.start, getKRStart(props.start), '30%')}
            {getStyledTextComponent(t.ShortStatistic.starter, getStarter(props.start), '30%')}
        </FlexWrapper>

        <Divider grey margin="5px 0px 0px" />
    </Wrapper>
);

export default ShortStatistic;
