import React from 'react';
import styled from 'styled-components';
import { COLORS } from 'themes';
import { sprintf } from 'sprintf-js';
import { byPlatform } from 'utils/platforms';
import getTexts from 'utils/localization';
const t = getTexts();

const PostPositionDelimiterC = styled.div`
    display: flex;
    justify-content: space-between;
    background: #333;
    color: ${COLORS.white};
    height: 13px;
    line-height: 13px;
    padding: 5px 0;
    padding-left: ${byPlatform('10px', '20px')};
    font-size: 14px;
    font-weight: 900;
`;

const PostPositionDelimiter = ({ isTrot, start, starts, productId }) => {
    const firstStart = starts[0];

    if (
        !isTrot ||
        !firstStart ||
        !firstStart.trotStartInfo ||
        start.trotStartInfo.postPosition !== 1 ||
        start.startNr === 1 ||
        Number.isNaN(start.trotStartInfo.distance - firstStart.trotStartInfo.distance) ||
        start.trotStartInfo.distance - firstStart.trotStartInfo.distance <= 0 ||
        !(start.trotStartInfo.distance - firstStart.trotStartInfo.distance)
    ) {
        return null;
    }

    return (
        <PostPositionDelimiterC productId={productId}>
            {sprintf(
                t.RacingCard.postPosition,
                start.trotStartInfo.distance - firstStart.trotStartInfo.distance
            )}
        </PostPositionDelimiterC>
    );
};

export default PostPositionDelimiter;
