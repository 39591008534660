import HistoryContainer, { HISTORY_MODE_FINISHED_BETS } from './HistoryContainer';

class FinishedBets extends HistoryContainer {
    pageSize = 10;

    componentDidMount() {
        this.setDataHandlerName('getFinishedBets');
        this.setMode(HISTORY_MODE_FINISHED_BETS);
        super.componentDidMount();
    }
}

export default FinishedBets;
