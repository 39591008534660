import News from '../layouts/sections/OldDesktopSidebar/News';
import NewsMobile from '../layouts/sections/OldDesktopSidebar/News';
import { byPlatform } from 'utils/platforms';

export default [
    {
        id: 'news',
        path: { DK: '/nyheder', EN: '/news' },
        component: byPlatform(NewsMobile, News),
    },
    {
        id: 'newsItem',
        path: { DK: '/nyheder/:link', EN: '/news/:link' },
        component: byPlatform(NewsMobile, News),
    },
];
