import { create } from 'zustand';

interface KombSelectionStore {
    kombMarksIndicator: Record<string, boolean>;
    toggleKombState(row: number): void;
    resetKombState(): void;
}

const useTrioColumnState = create<KombSelectionStore>()(set => ({
    kombMarksIndicator: {},
    toggleKombState: row =>
        set(state => ({
            kombMarksIndicator: {
                ...state.kombMarksIndicator,
                [row]: !state.kombMarksIndicator[row],
            },
        })),
    resetKombState: () => {
        set(() => ({
            kombMarksIndicator: {},
        }));
    },
}));

export default useTrioColumnState;
