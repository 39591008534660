import styled from 'styled-components';

const FeedbackWrapper = styled.div`
    position: static;
    width: auto;
    height: 150px;
    background: #fff;
`;

export { FeedbackWrapper };
