import { Component } from 'react';
import { connect } from 'react-redux';
import { showModal } from 'common/actions/uiActions';
import UserConductor from 'common/conductors/UserConductor';
import getTexts from 'utils/localization';
const t = getTexts();

class Success extends Component {
    constructor() {
        super();

        if (window.self !== window.top) {
            window.top.location.href = window.location.href;
        }
    }

    componentDidMount() {
        if (window.self === window.top) {
            const dateNow = new Date()
                .toLocaleDateString('en-GB')
                .replace(/\//g, '');
            UserConductor.isUserLoggedIn().then((res) => {
                if (res) {
                    let user = res;
                    let userDepositAmount = new URLSearchParams(
                        window.location.search
                    ).get('amount');
                    userDepositAmount = parseFloat(userDepositAmount);
                    UserConductor.getTransactionList('01011970', dateNow).then(
                        (res) => {
                            if (res.data) {
                                window.dataLayer.push({
                                    event: 'derby25.deposit',
                                    depositAmount: userDepositAmount,
                                    userId: user.id,
                                    depositCount: res.data.length
                                        ? res.data.length
                                        : 0,
                                    accountBalance: user.balance,
                                });
                            }
                        }
                    );
                }
            });
        }

        this.props.showModal('STATUS_OF_ACTION', 1, {
            success: true,
            title: t.userArea.deposit.success,
            redirectTo: this.props.lastVisitedRaceURL,
            history: this.props.history,
        });
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => ({
    lastVisitedRaceURL: state.ui.lastVisitedRaceURL,
});

const mapDispatchToProps = (dispatch) => ({
    showModal: (modalId, priority, data) => {
        dispatch(showModal(modalId, priority, data));
    },
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Success);
