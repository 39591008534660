import { useEffect, useState } from 'react';
import {
    ACCEPTABLE_FLOAT_POINTS,
    COMBINATION_PRICE_EVEN_NUMBERS_ONLY,
    MAX_COMBINATION_PRICES,
    MAX_COUPON_AMOUNT,
    MIN_COMBINATION_PRICES,
} from 'configs/products';
import getTexts from 'utils/localization';

const t = getTexts();

export interface ApplyCombinationPrice {
    (combinationPrice: number): void;
}

export interface BetAmount {
    combinationPrice: number;
    isCombinationPriceValid: boolean;
    isCouponAmountExceed: boolean;
    errorMessage: string;
    applyCombinationPrice: ApplyCombinationPrice;
}

const useAmount = (
    initialAmount: number,
    selections: any,
    onCombinationPriceSet?: (combinationPrice: number) => void
) => {
    const { product, combinationsCount } = selections;

    const [combinationPrice, setCombinationPrice] = useState(
        !initialAmount ? product.getBasicPrice() : initialAmount
    );
    const [isCombinationPriceValid, setCombinationPriceValid] = useState(true);
    const [errorMessage, setCombPriceError] = useState('');

    const [isCouponAmountExceed, setCouponAmountExceed] = useState(false);

    useEffect(
        () => {
            validateCouponAmount(combinationPrice);
        },
        [combinationsCount]
    );

    const applyCombinationPrice: ApplyCombinationPrice = (combinationPrice) => {
        const isValid = validateCombinationPrice(combinationPrice);
        const isCouponAmountValid = validateCouponAmount(combinationPrice);
        setCombinationPrice(combinationPrice);
        isValid &&
            isCouponAmountValid &&
            onCombinationPriceSet &&
            onCombinationPriceSet(combinationPrice);
    };

    const validateCouponAmount = (combinationPrice: number) => {
        const couponAmountExceed =
            combinationsCount * combinationPrice > MAX_COUPON_AMOUNT;
        setCouponAmountExceed(couponAmountExceed);
        return !couponAmountExceed;
    };

    const isFloat = (val: string) => /\./g.test(val);

    const isDecimalPointValid = (val: string) => {
        const acceptablePoints = ACCEPTABLE_FLOAT_POINTS[product.id];
        const regex = new RegExp(
            `^\\d+\\.(${acceptablePoints.join('|')})$`,
            'g'
        );
        return regex.test(val);
    };

    const isNumberEven = (val: number) => val % 2 === 0;

    const validateCombinationPrice = (combinationPrice: any) => {
        const MIN_RATE = MIN_COMBINATION_PRICES[product.id];
        const MAX_RATE = MAX_COMBINATION_PRICES[product.id];

        const incorectMinRate = MIN_RATE && combinationPrice < MIN_RATE;
        const incorectMaxRate = MAX_RATE && combinationPrice > MAX_RATE;
        const incorrectFloatAmount =
            isFloat(combinationPrice) && !isDecimalPointValid(combinationPrice);
        const incorrectByEvenNumberCheck =
            COMBINATION_PRICE_EVEN_NUMBERS_ONLY.includes(product.id) &&
            !isNumberEven(combinationPrice);

        if (incorrectByEvenNumberCheck) {
            setCombPriceError(t.betSlip.cpEvenNumbers);
        }

        if (incorectMinRate) {
            setCombPriceError(t.betSlip.cpLow);
        }

        if (incorectMaxRate) {
            setCombPriceError(t.betSlip.cpHigh);
        }

        if (incorrectFloatAmount) {
            setCombPriceError(
                `Tilladte decimaltal: ${ACCEPTABLE_FLOAT_POINTS[product.id].map(
                    (point) => `0.${point}`
                )}`
            );
        }

        const isPriceValid = !(
            incorectMinRate ||
            incorectMaxRate ||
            incorrectFloatAmount ||
            incorrectByEvenNumberCheck
        );

        setCombinationPriceValid(isPriceValid);

        return isPriceValid;
    };

    return {
        combinationPrice: product.decimalsAllowed()
            ? combinationPrice
            : parseInt(combinationPrice, 10),
        isCombinationPriceValid,
        isCouponAmountExceed,
        errorMessage,
        applyCombinationPrice,
    };
};

export default useAmount;
