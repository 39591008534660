import { useState } from 'react';
import { useDispatch } from 'react-redux';
import CMSAPI from 'common/api/CMSAPI';
import { getUser } from 'common/actions/authActions';
import useAuthEffect from 'common/hooks/useAuthEffect';
import { dateFromString } from 'utils/date.ts';
import NotificationConductor from 'common/conductors/NotificationConductor';

const COMMON_SPILID_ERROR = 'Kan ikke oprette et spil-id-kort. Kontakt venligst vores support.';

const addBirthYearToCard = (spilIdCard, birthdate) => ({
    ...spilIdCard,
    year: dateFromString(birthdate).year(),
});

const useSpilIdCard = ({ isRetailPinSet, retailPin }) => {
    const dispatch = useDispatch();

    const getAccountInformation = () => dispatch(getUser());

    const [spilIdCard, setSpilIdCard] = useState({});

    const [pending, setPending] = useState(false);
    const [failed, setFailed] = useState(false);

    const [fullName, setFullName] = useState('');
    const [address, setAddress] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [city, setCity] = useState('');

    async function fetchData() {
        try {
            setPending(true);

            const user = await getAccountInformation();

            const fullName = `${user?.firstName} ${user?.lastName}`;
            const address = `${user.address}, ${user.zip}`;

            setFullName(fullName);
            setAddress(address);
            setZipCode(user.zip);
            setBirthdate(user.birthDate);
            setCity(user.city);

            const { success, data } = await CMSAPI.getSpilId({
                token: user.retailHash,
                userId: user.id,
                pin: retailPin,
                app: 'bet25',
            });

            if (success) {
                const spilIdCard = addBirthYearToCard(data, user.birthDate);
                setSpilIdCard(spilIdCard);
            } else {
                const response = await CMSAPI.createSpilId({
                    token: user.retailHash,
                    userId: user.id,
                    app: 'bet25',
                    fullName,
                });

                if (response.success) {
                    const response = await CMSAPI.getSpilId({
                        token: user.retailHash,
                        userId: user.id,
                        pin: retailPin,
                        app: 'bet25',
                    });

                    if (!response.success) {
                        setFailed(true);
                        return NotificationConductor.error(COMMON_SPILID_ERROR);
                    }

                    const spilIdCard = addBirthYearToCard(response.data, user.birthDate);

                    setSpilIdCard(spilIdCard);
                }
            }
        } catch (e) {
            console.log(e);
        } finally {
            setPending(false);
        }
    }

    useAuthEffect(fetchData, { enabled: isRetailPinSet });

    return {
        pending,
        failed,
        spilIdCard,
        fullName,
        address,
        zipCode,
        birthdate,
        city,
    };
};

export default useSpilIdCard;
