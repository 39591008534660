import React, { useEffect, useState } from 'react';
import Wrapper from 'ui/Wrapper';
import FlexWrapper from 'ui/FlexWrapper';
import Text from 'ui/Text';
import Divider from 'ui/Divider';
import ShoeInfo from 'features/TrackPage/components/RaceCard/Desktop/ShoeInfo.jsx';

import moment from 'moment';

import dateIcon from './icons/date.svg';
import distanceIcon from './icons/distance.svg';
import placeIcon from './icons/place.svg';
import speedIcon from './icons/speed.svg';
import surfaceIcon from './icons/grass.svg';

import jockeyIcon from './icons/jockey.svg';
import oddsIcon from './icons/odds.svg';
import shoeIcon from './icons/shoe.svg';
import weightIcon from './icons/weight.svg';
import playIcon from './icons/play.svg';
import replayIcon from './icons/replay.svg';
import Icon from 'ui/Icon';
import DerbyVisionAPI from 'common/api/DerbyVisionAPI';
import { connect } from 'react-redux';
import { showModal } from 'common/actions/uiActions';
import { priority } from 'configs/layout';

const getCellView = (children, index) => (
    <Wrapper key={index} padding="0px" width="80px">
        {children}
    </Wrapper>
);

const getIconView = (icon, label, index) =>
    getCellView(
        <Wrapper align="center" padding="0px">
            <div>
                <img src={icon} height="40px" alt={label} />
            </div>
            <div>
                <Text align="center" size="x05" white>
                    {label}
                </Text>
            </div>
        </Wrapper>,
        index
    );

const getTextView = (text, index) =>
    getCellView(
        <Text bold white size="x05" align="center" padding="0">
            {text}
        </Text>,
        index
    );

const getTextWithSubLabelView = (text, label, index) =>
    getCellView(
        <div>
            <Text align="center" padding="0" block bold white size="x05">
                {text}
            </Text>
            <Text align="center" padding="0" block white size="x05">
                {label}
            </Text>
        </div>,
        index
    );

const HeaderPart = props => (
    <FlexWrapper width="auto" direction="row" align="space-between">
        {props.slide === 0
            ? [
                  getIconView(dateIcon, 'Dato/bane', 0),
                  getIconView(distanceIcon, 'Start nr./distance', 1),
                  getIconView(placeIcon, 'Placering', 2),
                  getIconView(replayIcon, 'Gense løb', 3),
              ]
            : [
                  !props.gallop
                      ? getIconView(speedIcon, 'Tid', 0)
                      : getIconView(surfaceIcon, 'Underlag', 0),
                  getIconView(jockeyIcon, 'Kusk', 1),
                  getIconView(oddsIcon, 'Odds', 2),
                  !props.gallop
                      ? getIconView(shoeIcon, 'Sko', 3)
                      : getIconView(weightIcon, 'Vægt', 3),
              ]}
    </FlexWrapper>
);

const getItemView = (item, slide, index, isGallop, replayLink, showReplayPopup) =>
    slide === 0 ? (
        <FlexWrapper
            key={index}
            width="auto"
            direction="row"
            align="space-between"
            alignItems={'center'}
            style={{ minHeight: '44px' }}
        >
            {[
                getTextWithSubLabelView(
                    moment(item.raceKey.date).format('DD.MM.Y'),
                    item.raceKey.track.domesticText,
                    0
                ),
                getTextView(item.startNr + '/' + item.circumstances.distance, 1),
                getTextView(
                    item.placeInfo.place > 0
                        ? `Nr. ${item.placeInfo.place}`
                        : item.placeInfo.disqualified
                        ? 'd'
                        : 0,
                    2
                ),
                <Wrapper key={index} padding="0px" width="80px">
                    <span
                        style={{
                            margin: '0 auto',
                            display: 'block',
                            width: '24px',
                        }}
                        onClick={
                            replayLink
                                ? () => {
                                      showReplayPopup({ replayLink });
                                  }
                                : undefined
                        }
                    >
                        <Icon
                            src={playIcon}
                            style={{
                                opacity: replayLink ? 1 : 0.5,
                            }}
                        />
                    </span>
                </Wrapper>,
            ]}
        </FlexWrapper>
    ) : (
        <FlexWrapper
            key={index}
            width="auto"
            direction="row"
            align="space-between"
            alignItems={'center'}
            style={{ minHeight: '44px' }}
        >
            {[
                getTextView(
                    !isGallop ? item.formattedTime : item.gallopInfo.trackSurface.domesticText,
                    3
                ),
                getTextView(item.driver.name + ' ' + item.driver.surName, 0),
                getTextView(
                    item.odds > 0 ? parseFloat(item.odds / 10).toFixed(2) : <span>--</span>,
                    1
                ),
                !isGallop
                    ? getTextView(
                          item.circumstances && item.circumstances.shoeInfo ? (
                              <ShoeInfo
                                  frontShoe={item.circumstances.shoeInfo.foreShoes}
                                  backShoe={item.circumstances.shoeInfo.hindShoes}
                              />
                          ) : (
                              <span>--</span>
                          ),
                          2
                      )
                    : getTextView(item.gallopInfo.weight, 2),
            ]}
        </FlexWrapper>
    );

const renderStats = (slide, data = [], isGallop, replayLinks, showReplayPopup) =>
    data.map((item, index) => {
        return getItemView(
            item,
            slide,
            item.raceKey.date + item.raceKey.raceNr + item.raceKey.track.code,
            isGallop,
            replayLinks && replayLinks[index] ? replayLinks[index] : undefined,
            showReplayPopup
        );
    });

const StatisticList = ({ slide, data, gallop, showReplayPopup }) => {
    const [replayLinks, setReplayLinks] = useState([]);

    useEffect(() => {
        slide === 0 && DerbyVisionAPI.getUrl(data).then(replayLinks => setReplayLinks(replayLinks));
    }, [data, slide]);

    return (
        <Wrapper>
            <HeaderPart slide={slide} gallop={gallop} />
            <Divider white />
            {renderStats(slide, data, gallop, replayLinks, showReplayPopup)}
        </Wrapper>
    );
};

export default connect(null, dispatch => ({
    showReplayPopup: data => dispatch(showModal('REPLAY', priority.replays, data)),
}))(StatisticList);
