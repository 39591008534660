import { post } from './RequestHandler';

export function getUnpaidSale(hash) {
    return post('/cafe/rest/unpaid-sale/get-unpaid-sale', { hash });
}

export function newUnpaidSale(data) {
    return post('/cafe/rest/unpaid-sale/new-unpaid-sale', data, 'json');
}

export function getShopConfig() {
    return post('/cafe/rest/shop/get-config', {});
}

export function getBuild() {
    return post('/cafe/info/version', {});
}

export function getSession(sessionId) {
    return post('/cafe/rest/terminal/get-session', {
        session_id: sessionId,
        terminal_id: window.localStorage.getItem('terminalId'),
        hostname: window.localStorage.getItem('printerHost'),
    });
}

export const checkinCms = (terminalId, voucherHash) => {
    return post('/cafe/rest/terminal/check-in', {
        terminal_id: terminalId,
        hash: voucherHash,
        hostname: window.localStorage.getItem('printerHost'),
    });
};

export const applyVoucherCms = (sessionId, voucherHash) => {
    return post('/cafe/rest/voucher/apply-voucher', {
        session_id: sessionId,
        hash: voucherHash,
        hostname: window.localStorage.getItem('printerHost'),
    });
};

export const checkinByCouponCms = (terminalId, couponHash) => {
    return post('/cafe/rest/terminal/check-in-by-coupon', {
        terminal_id: terminalId,
        hash: couponHash,
        hostname: window.localStorage.getItem('printerHost'),
    });
};

export const checkinBySystemCouponCms = (terminalId, couponHash) => {
    return post('/cafe/rest/terminal/check-in-by-sale', {
        terminal_id: terminalId,
        hash: couponHash,
        hostname: window.localStorage.getItem('printerHost'),
    });
};

export const applyCouponCms = (sessionlId, couponHash) => {
    return post(getCouponEndpoint(couponHash) + '/apply-coupon', {
        session_id: sessionlId,
        hash: couponHash,
        hostname: window.localStorage.getItem('printerHost'),
    });
};
export const applySystemCouponCms = (sessionlId, couponHash) => {
    return post(getCouponEndpoint(couponHash) + '/apply-sale', {
        session_id: sessionlId,
        hash: couponHash,
        hostname: window.localStorage.getItem('printerHost'),
    });
};

export const getCouponStatusCms = (couponHash) => {
    return post(getCouponEndpoint(couponHash) + '/get-coupon', {
        hash: couponHash,
        hostname: window.localStorage.getItem('printerHost'),
    });
};

export const getSystemCouponStatusCms = (couponHash) => {
    return post(getCouponEndpoint(couponHash) + '/fetch-sale', {
        hash: couponHash,
        hostname: window.localStorage.getItem('printerHost'),
    });
};

export const checkoutCms = (terminalId, sessionId) => {
    return post('/cafe/rest/terminal/check-out', {
        terminal_id: terminalId,
        session_id: sessionId,
        hostname: window.localStorage.getItem('printerHost'),
    });
};

function getCouponEndpoint(couponHash) {
    const links = {
        T: '/cafe/rest/trident',
        C: '/cafe/rest/coupon',
        W: '/cafe/rest/virtual-coupon',
        D: '/cafe/rest/derby',
        S: '/cafe/rest/sale',
    };

    let firstChar = couponHash ? couponHash[0] : '';

    return links[firstChar] ? links[firstChar] : '';
}
