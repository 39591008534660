import React from 'react';
import styled from 'styled-components';
import Headline from 'ui/Headline';
import StatisticSlider from './StatisticSlider';
import bg1 from './backgrounds/1.png';
import bg2 from './backgrounds/2.png';
import bg3 from './backgrounds/3.png';
import bg4 from './backgrounds/4.png';
import bg5 from './backgrounds/5.png';
import bg6 from './backgrounds/6.png';
import bg7 from './backgrounds/7.png';
import bg8 from './backgrounds/8.png';
import bg9 from './backgrounds/9.png';
import bg10 from './backgrounds/10.png';
import bg11 from './backgrounds/11.png';
import bg12 from './backgrounds/12.png';
import bg13 from './backgrounds/13.png';
import bg14 from './backgrounds/14.png';
import bg15 from './backgrounds/15.png';
import bg16 from './backgrounds/16.png';
import bg17 from './backgrounds/17.png';
import bg18 from './backgrounds/18.png';
import bg19 from './backgrounds/19.png';
import bg20 from './backgrounds/20.png';
import bg21 from './backgrounds/21.png';
import bg22 from './backgrounds/22.png';
import bg23 from './backgrounds/23.png';
import bg24 from './backgrounds/24.png';
import bg25 from './backgrounds/25.png';
import bg26 from './backgrounds/26.png';
import bg27 from './backgrounds/27.png';
import KUTE from 'kute.js';
import { CloseButton, CloseButtonArea, PopupWrapper, Overlay } from 'ui/Popup';

const bgs = [
    bg1,
    bg2,
    bg3,
    bg4,
    bg5,
    bg6,
    bg7,
    bg8,
    bg9,
    bg10,
    bg11,
    bg12,
    bg13,
    bg14,
    bg15,
    bg16,
    bg17,
    bg18,
    bg19,
    bg20,
    bg21,
    bg22,
    bg23,
    bg24,
    bg25,
    bg26,
    bg27,
];

const TransperentBackgroundAnimated = styled(Overlay)`
    opacity: 0; // will be changed during the animation
    background: none;
`;

const Popup = styled(PopupWrapper)`
    width: 100%;
    height: 100%;
    border-radius: 0;
    padding: 20px;
    box-sizing: border-box;
    overflow: hidden;
`;

class GameStatistic extends React.Component {
    modalRef = React.createRef();
    rotation = null;
    opacityRev = null;
    rotation = null;
    opacityRev = null;
    animationDuration = 500;
    random = Math.floor(Math.random() * 13) + 1;
    bodyScrollPosition = 0;

    state = {
        animationFinished: false,
    };

    componentDidMount() {
        this.bodyScrollPosition = window.scrollY;
        this.props.setBackground('none');

        this.rotation = KUTE.fromTo(
            this.modalRef.current,
            { rotateY: 160 },
            { rotateY: 0 },
            {
                perspective: 300,
                duration: this.animationDuration,
            }
        );
        this.opacity = KUTE.fromTo(
            this.modalRef.current,
            { opacity: 0 },
            { opacity: 1 },
            {
                duration: this.animationDuration,
                complete: () => this.setState({ animationFinished: true }),
            }
        );
        this.rotation.start();
        this.opacity.start();
        this.rotationRev = KUTE.fromTo(
            this.modalRef.current,
            { rotateY: 0 },
            { rotateY: 160 },
            { perspective: 300, duration: this.animationDuration }
        );
        this.opacityRev = KUTE.fromTo(
            this.modalRef.current,
            { opacity: 1 },
            { opacity: 0 },
            {
                duration: this.animationDuration,
                complete: this.props.close,
            }
        );
    }

    componentWillUnmount() {
        this.modalRef = null;
    }

    onClose = () => {
        document.getElementById('root').classList.remove('without-scroll');
        window.document.body.style.position = 'static';
        window.scrollTo(0, this.bodyScrollPosition);
        this.rotationRev.start();
        this.opacityRev.start();
    };

    render() {
        const { modalData } = this.props;

        return (
            <TransperentBackgroundAnimated ref={this.modalRef}>
                <Popup bg={`url('${bgs[this.random]}')`}>
                    <CloseButtonArea onClick={this.onClose}>
                        <CloseButton>x</CloseButton>
                    </CloseButtonArea>
                    <Headline size="x3" white align="center">
                        {modalData.start.startNr}.{' '}
                        {modalData.start.horse ? modalData.start.horse.horseNameAndNationality : ''}
                    </Headline>
                    {this.state.animationFinished ? (
                        <StatisticSlider
                            gallop={modalData.race.trotRaceInfo.startMethod === null}
                            data={
                                modalData.start.horseStat
                                    ? modalData.start.horseStat.pastPerformances.resultRows
                                    : []
                            }
                        />
                    ) : null}
                </Popup>
            </TransperentBackgroundAnimated>
        );
    }
}

export default GameStatistic;
