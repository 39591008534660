import styled from 'styled-components';
import { BACKGROUNDS, COLORS } from 'themes';
import { Row } from 'react-grid-system';
import { byPlatform } from 'utils/platforms';
import { Li } from 'ui/List';

const size = byPlatform(26, 21, 26);

const Pick = styled(Li)`
    background: ${(props) =>
        props.scratched ? BACKGROUNDS.lightGrey : BACKGROUNDS.primary};
    text-decoration: ${(props) => (props.scratched ? 'line-through' : 'none')};
    color: ${(props) => (props.scratched ? COLORS.primaryText : COLORS.white)};
    border-radius: ${size / 2}px;
    line-height: ${size}px;
    width: ${size}px;
    height: ${size}px;
    text-align: center;
    padding: 0;
    margin-right: ${byPlatform('5px', '2px', '5px')};
    margin-top: ${byPlatform('5px', '2px', '5px')};
    cursor: pointer;
    font-weight: ${byPlatform(800, 700, 800)};
    font-size: ${byPlatform('14px', '11px', '14px')};
    display: inline-block;
`;

const Reserve = styled(Pick)`
    background: ${BACKGROUNDS.blue};
    position: relative;
`;

const ReserveNumber = styled.span`
    display: block;
    position: absolute;
    background: ${BACKGROUNDS.accentAlt};
    font-size: 6px;
    height: 10px;
    width: 10px;
    line-height: 10px;
    border-radius: 10px;
    right: -2px;
    top: -2px;
    color: ${COLORS.black};
`;

const StyledRow = styled(Row).attrs({ align: 'center' })`
    width: auto;
    margin-left: 0;
`;

export { Pick, StyledRow as Row, Reserve, ReserveNumber };
