import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { logout } from 'common/actions/authActions';
import Popup, { PopupWrapper as PopupWrapperDef, CloseButton as CloseButtonDef } from 'ui/Popup';
import useModals from 'common/hooks/useModals';
import PermanentlyExcluded from './PermanentlyExcluded';
import CurrentlyExcluded from './CurrentlyExcluded';
import { byPlatform } from 'utils/platforms';

const PopupWrapper = styled(PopupWrapperDef)`
    border-radius: 7px;
    min-height: 0;
    width: auto;
    box-sizing: border-box;
    overflow: hidden;
    height: ${byPlatform('100%', 'auto')};
`;

const CloseButton = styled(CloseButtonDef)`
    z-index: 1;
`;

const SelfExcludedModal = ({ modalData }) => {
    const { hide } = useModals();
    const dispatch = useDispatch();

    return (
        <Popup
            PopupWrapperComponent={PopupWrapper}
            CloseButtonComponent={CloseButton}
            onClose={() => {
                hide('SELF_EXCLUDED_MODAL');
                dispatch(logout());
            }}
        >
            {modalData?.permanent ? <PermanentlyExcluded isModal /> : <CurrentlyExcluded isModal />}
        </Popup>
    );
};

export default SelfExcludedModal;
